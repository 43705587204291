import { useState } from "react";

import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import dayjs from "@eisox/dayjs";
import { Drawer, DropdownMenu, Radio, SearchInput, Typography } from "@eisox/design-system";
import { HistoryIcon, OptionIcon, UpdateIcon, ValveIcon } from "@eisox/icons";
import { useBem, useKeyPress } from "@eisox/tools";
import { calcRodStroke, valveVersionHandler } from "@eisox/valves";

import type { houseLoader } from "~/UI";
import { DropdownDetails } from "~/UI/layouts/ListDrawer/components/DropdownDetails/DropdownDetails";
import type { ValvesWithProblem } from "~/UI/screens/House";
import { MoveValves } from "~/UI/screens/Plans/components/Actions";
import { FORMAT_DATE } from "~/constants/timeConstants";
import { BatteryHistoryPopup, MecaHistoryPopup } from "~/features";
import { useFilteredItems } from "~/hooks/useFilteredItems";
import { usePermissionsContext } from "~/providers";
import { idLoaderHouse } from "~/routes/utils";
import { getValveProblemTranslation } from "~/utils";

import type { DataInformationProps } from "../components/DataInformation";
import { DataInformation } from "../components/DataInformation";
import { ItemInfo } from "../components/ItemInfo";
import { ReplacePopUp } from "../components/ReplacePopUp";
import { EditValveDrawer } from "./EditValveDrawer";
import { RelaunchLearning } from "./RelaunchLearning";

import styles from "./ValveListDrawer.module.scss";

interface ValvesListDrawerProps {
  open?: boolean;
  onClose?: () => void;
  valves: ValvesWithProblem[];
  choosenValveId?: string;
}

type SortFilterBy = ("gatewayName" | "uid" | "mac") & keyof ValvesWithProblem;

/**
 * @todo: Migrate, delete useRouteLoaderData => recreate stories
 */
export const ValveListDrawer: React.FC<ValvesListDrawerProps> = ({ open = false, onClose, valves, choosenValveId }) => {
  const { formatMessage } = useIntl();
  const { heatingNetworks, rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;
  const { permissions } = usePermissionsContext("ValveListDrawer");

  useKeyPress({ code: "ArrowUp" }, () => onKeyPress("up"));
  useKeyPress({ code: "ArrowDown" }, () => onKeyPress("down"));

  const bem = useBem(styles);
  const valvesListDrawerStyle = bem("valves-list-drawer");
  const valvesInfoStyle = bem("valve-info");
  const searchContainerStyle = bem("search-container");
  const dropdownStyle = bem("dropdown");

  const [sortBy, setSortBy] = useState<SortFilterBy>("uid");
  const [search, setSearch] = useState<string>("");
  const [editValveDrawerOpen, setEditValveDrawerOpen] = useState<boolean>(false);
  const [replaceValvePopupOpen, setReplaceValvePopupOpen] = useState<boolean>(false);
  const [moveValvePopupOpen, setMoveValvePopupOpen] = useState<boolean>(false);
  const [relaunchLearningPopupOpen, setRelaunchLearningPopupOpen] = useState<boolean>(false);
  const [mecaHistoryPopupOpen, setMecaHistoryPopupOpen] = useState<boolean>(false);
  const [batteryHistoryPopupOpen, setBatteryHistoryPopupOpen] = useState<boolean>(false);
  const [selectedValveId, setSelectedValveId] = useState<string | undefined>(choosenValveId ?? valves[0]?.id);

  const valvesToDisplay = useFilteredItems<ValvesWithProblem>(
    valves,
    search,
    [sortBy],
    [{ key: "gatewayName" }, { key: "mac" }, { key: "uid" }],
  );

  // when delete valve
  if (!valves.find(v => v.id === selectedValveId) && valves.length > 0) {
    setSelectedValveId(valves[0]?.id);
  } // when delete last valve
  else if (valves.length === 0) {
    onClose?.();
  }

  const selectedValve = valves.find(valve => valve.id === selectedValveId);
  const { problemStatus } = selectedValve ?? {};
  const translation = selectedValve && getValveProblemTranslation(selectedValve);
  const hnValve = heatingNetworks.find(hn => hn.id === selectedValve?.heatingNetworkId);

  const onKeyPress = (key: string) => {
    const currentIndex = valvesToDisplay.findIndex(v => v.id === selectedValve?.id);
    const nextIndex = key === "up" ? currentIndex - 1 : currentIndex + 1;
    if (nextIndex >= 0 && nextIndex < valvesToDisplay.length) {
      const nextValve = valvesToDisplay[nextIndex];
      setSelectedValveId(nextValve.id);
    }
  };

  const valveInformations: DataInformationProps[] = [
    {
      label: formatMessage({ id: "drawer.listDrawer.valvesDrawer.detailValve.swOffset" }),
      value: `${selectedValve?.stateData.swOffset !== undefined ? `${selectedValve.stateData.swOffset > 0 ? "+" : ""}${selectedValve.stateData.swOffset}` : "-"}°C`,
    },
    {
      label: formatMessage({ id: "drawer.listDrawer.valvesDrawer.detailValve.version" }),
      value: `${valveVersionHandler(selectedValve?.hardwareVersion ?? "-")} (${selectedValve?.softwareVersion ?? "-"})`,
    },
    {
      label: (
        <>
          <p>{formatMessage({ id: "drawer.listDrawer.valvesDrawer.detailValve.rodStroke" })}</p>
          {selectedValve?.mac && (
            <>
              {permissions.history?.valve.meca?.read && (
                <HistoryIcon className={valvesInfoStyle("icon")} onClick={() => setMecaHistoryPopupOpen(true)} />
              )}
              {selectedValve.gatewayMac && (
                <UpdateIcon className={valvesInfoStyle("icon")} onClick={() => setRelaunchLearningPopupOpen(true)} />
              )}
            </>
          )}
        </>
      ),
      value: (
        <div>
          <p>
            {selectedValve?.stateData.openPos !== undefined && selectedValve.stateData.closePos !== undefined
              ? calcRodStroke(selectedValve.stateData.openPos, selectedValve.stateData.closePos)
              : "-"}{" "}
            mm ({selectedValve?.stateData.openPos ?? "-"}-{selectedValve?.stateData.closePos ?? "-"}/1630)
          </p>
          {selectedValve?.stateData.posUpdatedAt && (
            <p>{dayjs(selectedValve.stateData.posUpdatedAt).format(FORMAT_DATE.valveTige)}</p>
          )}
        </div>
      ),
    },
    {
      label: formatMessage({ id: "drawer.listDrawer.valvesDrawer.detailValve.openning" }),
      value: `${selectedValve?.closing !== undefined ? 100 - selectedValve.closing : "-"}%`,
    },
    {
      label: formatMessage({ id: "drawer.listDrawer.valvesDrawer.detailValve.signal" }),
      value: `${selectedValve?.rssi ?? "-"} dBm (LQI: ${selectedValve?.lqi ?? "-"}/255)`,
    },
    {
      label: formatMessage({ id: "drawer.listDrawer.valvesDrawer.detailValve.heatingNetwork" }),
      value: (
        <Typography>
          {hnValve?.parentName ? `${hnValve.parentName}/` : ""}
          {hnValve?.name ?? "-"}
        </Typography>
      ),
      auth: permissions.valve?.heatingNetworkId?.read && !!hnValve,
    },
    {
      label: (
        <>
          <p>{formatMessage({ id: "drawer.listDrawer.valvesDrawer.detailValve.battery" })}</p>
          {permissions.history?.valve.battery?.read && selectedValve?.mac && (
            <HistoryIcon className={valvesInfoStyle("icon")} onClick={() => setBatteryHistoryPopupOpen(true)} />
          )}
        </>
      ),
      value: (
        <div>
          {selectedValve?.stateData.batLowDate && (
            <p>
              {formatMessage(
                { id: "valves.datLowDate" },
                { d: dayjs(selectedValve.stateData.batLowDate).format(FORMAT_DATE.valveLowBattery) },
              )}
            </p>
          )}
          {(selectedValve?.stateData.batZ !== undefined || selectedValve?.stateData.batZUpdatedAt) && (
            <p>
              (
              {formatMessage(
                { id: "valves.batZ" },
                {
                  b: selectedValve.stateData.batZ ?? "-",
                  d: selectedValve.stateData.batZUpdatedAt
                    ? dayjs(selectedValve.stateData.batZUpdatedAt).format(FORMAT_DATE.valveBatUpdate)
                    : "-",
                },
              )}
              )
            </p>
          )}
          {permissions.valve?.stateData?.dateBat?.read && selectedValve?.stateData.dateBat && (
            <p>
              {formatMessage(
                { id: "valves.dateBat" },
                { d: dayjs(selectedValve.stateData.dateBat).format(FORMAT_DATE.valveBatChanged) },
              )}
            </p>
          )}
        </div>
      ),
    },
  ];

  const sortFilterValvesBy: SortFilterBy[] = ["gatewayName", "uid", "mac"];

  const edit = {
    isAuthorized:
      permissions.valve?.gatewayId?.update ??
      permissions.valve?.heatingNetworkId?.read ??
      permissions.valve?.heatingNetworkId?.update ??
      permissions.valve?.plan?.update ??
      permissions.valve?.delete,
    onClick: () => setEditValveDrawerOpen(true),
    disabled: !selectedValve,
  };

  const move = {
    isAuthorized: permissions.valve?.plan?.update,
    onClick: () => setMoveValvePopupOpen(true),
    disabled: !selectedValve,
  };

  const replace = {
    isAuthorized: permissions.valve?.mac?.update,
    onClick: () => setReplaceValvePopupOpen(true),
    disabled: !selectedValve?.mac,
  };

  return (
    <Drawer
      title={formatMessage({ id: "drawer.listDrawer.valvesDrawer.title" })}
      open={open}
      onOpenChange={() => {
        setSearch("");
        onClose?.();
      }}
    >
      <>
        <div className={valvesListDrawerStyle()}>
          {valves.length >= 5 && !choosenValveId && (
            <div className={searchContainerStyle()}>
              <SearchInput
                iconPosition="left"
                placeholder={sortFilterValvesBy
                  .map(s => formatMessage({ id: `drawer.listDrawer.valvesDrawer.sortFilterBy.${s}` }))
                  .join(", ")}
                value={search}
                onChange={value => setSearch(value)}
                className={searchContainerStyle("search")}
              />
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <OptionIcon style={{ height: 20, width: 20, cursor: "pointer" }} />
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="end" sideOffset={10} style={{ zIndex: 100 }}>
                  <DropdownMenu.RadioGroup value={sortBy} onValueChange={value => setSortBy(value as SortFilterBy)}>
                    {sortFilterValvesBy.map((s, i) => (
                      <DropdownMenu.RadioItem key={i} value={s}>
                        <DropdownMenu.ItemIndicator asChild forceMount>
                          <Radio style={{ marginRight: 10 }} checked={sortBy === s} />
                        </DropdownMenu.ItemIndicator>
                        <p>{formatMessage({ id: `drawer.listDrawer.valvesDrawer.sortFilterBy.${s}` })}</p>
                      </DropdownMenu.RadioItem>
                    ))}
                  </DropdownMenu.RadioGroup>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>
          )}
          {!choosenValveId && (
            <div className={valvesInfoStyle()}>
              {valvesToDisplay.map((v, i) => {
                const selected = v.id === selectedValve?.id;
                return (
                  <ItemInfo
                    error={v.problemStatus}
                    selected={selected}
                    label={`${v.uid ?? "-"} - ${v.mac ?? "-"}`}
                    subLabel={`${v.gatewayName ?? "-"}`}
                    icon={<ValveIcon style={{ width: 25, height: 25 }} />}
                    key={i}
                    onClick={() => setSelectedValveId(v.id)}
                    className={valvesInfoStyle("valve")}
                  />
                );
              })}
            </div>
          )}

          <DropdownDetails
            full={!!choosenValveId}
            header={
              <ItemInfo
                error={problemStatus}
                label={`${selectedValve?.uid ?? "-"} - ${selectedValve?.mac ?? "-"}`}
                subLabel={`${selectedValve?.gatewayName || "-"} ${choosenValveId ? `- ${rooms.find(r => r.id === selectedValve?.roomId)?.name ?? "-"}` : ""}`}
                icon={<ValveIcon style={{ width: 25, height: 25 }} />}
              />
            }
            childrenClassName={valvesInfoStyle("dropdown")}
            lastCom={selectedValve?.updatedAt}
            errors={permissions.valve?.error && translation?.errors ? translation.errors : []}
            warnings={permissions.valve?.warning && translation?.warnings ? translation.warnings : []}
            move={move}
            replace={replace}
            edit={edit}
          >
            {valveInformations.map((e, i) => (
              <DataInformation {...e} key={i} />
            ))}
          </DropdownDetails>
        </div>

        {editValveDrawerOpen && selectedValve && (
          <EditValveDrawer
            open={editValveDrawerOpen}
            onClose={() => setEditValveDrawerOpen(false)}
            valve={selectedValve}
          />
        )}

        {replaceValvePopupOpen && selectedValve && selectedValve.mac && (
          <ReplacePopUp
            open={replaceValvePopupOpen}
            onClose={() => setReplaceValvePopupOpen(false)}
            isForReplaceBox={false}
            mac={selectedValve.mac}
            id={selectedValve.id}
          />
        )}

        {moveValvePopupOpen && (
          <MoveValves
            open={moveValvePopupOpen}
            onClose={() => setMoveValvePopupOpen(false)}
            initialSelectedValveId={selectedValveId}
          />
        )}

        {relaunchLearningPopupOpen && selectedValve?.mac && selectedValve.gatewayId && (
          <RelaunchLearning
            mac={selectedValve.mac}
            gatewayId={selectedValve.gatewayId}
            open={relaunchLearningPopupOpen}
            onClose={() => setRelaunchLearningPopupOpen(false)}
          />
        )}

        {mecaHistoryPopupOpen && selectedValve?.mac && (
          <MecaHistoryPopup mac={selectedValve.mac} open onOpenChange={setMecaHistoryPopupOpen} />
        )}

        {batteryHistoryPopupOpen && selectedValve?.mac && (
          <BatteryHistoryPopup mac={selectedValve.mac} open onOpenChange={setBatteryHistoryPopupOpen} />
        )}
      </>
    </Drawer>
  );
};
