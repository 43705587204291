import { isRoomFrostFree } from "@eisox/rooms";

import type { RoomsWithProblem } from "~/UI/screens/House";

import type { ParametersType } from "..";
import type { SelectorsType } from "../layouts/RoomsSelects";

export const filterRooms = (
  rooms: RoomsWithProblem[],
  filters: SelectorsType & { parameters?: ParametersType },
): RoomsWithProblem[] => {
  // if no filters are selected, return no rooms
  if (
    filters.types.length === 0 &&
    filters.plans.length === 0 &&
    filters.groups.length === 0 &&
    (!filters.parameters ||
      (filters.parameters.buttonSettings === null &&
        filters.parameters.precomfortRangeGeneration === null &&
        filters.parameters.unusualAbsenceDetection === null &&
        filters.parameters.comfortTemperature === undefined &&
        filters.parameters.nightTemperature === undefined &&
        filters.parameters.frostFreeMode === null))
  ) {
    return [];
  }

  let filteredRooms = [...rooms];
  if (filters.types.length > 0) {
    filteredRooms = filteredRooms.filter(r => filters.types.includes(r.roomType));
  }
  if (filters.plans.length > 0) {
    filteredRooms = filteredRooms.filter(r => r.plan?.planId && filters.plans.includes(r.plan.planId));
  }
  if (filters.groups.length > 0) {
    filteredRooms = filteredRooms.filter(r => r.groupNames?.some(groupName => filters.groups.includes(groupName)));
  }
  if (filters.parameters) {
    const {
      buttonSettings,
      precomfortRangeGeneration,
      unusualAbsenceDetection,
      comfortTemperature,
      nightTemperature,
      frostFreeMode,
    } = filters.parameters;
    if (buttonSettings !== null) filteredRooms = filteredRooms.filter(r => r.isSwitchEnabled === buttonSettings);
    if (frostFreeMode !== null)
      filteredRooms = filteredRooms.filter(r => isRoomFrostFree({ ...r }).isFrostFree === frostFreeMode);
    if (precomfortRangeGeneration !== null)
      filteredRooms = filteredRooms.filter(r => r.isPreComfRoom === precomfortRangeGeneration);
    if (unusualAbsenceDetection !== null)
      filteredRooms = filteredRooms.filter(r => r.isAutoPrecomf === unusualAbsenceDetection);
    if (comfortTemperature) filteredRooms = filteredRooms.filter(r => r.comfortTemperature === comfortTemperature);
    if (nightTemperature) filteredRooms = filteredRooms.filter(r => r.nightTemperature === nightTemperature);
  }
  return filteredRooms;
};

export type SelectFiltersType = {
  search: string;
} & SelectorsType;

export const filterRoomsWithTextSearch = (
  rooms: RoomsWithProblem[],
  filters: SelectFiltersType,
): RoomsWithProblem[] => {
  let filteredRooms = rooms;
  if (filters.search) {
    filteredRooms = filteredRooms.filter(
      r =>
        r.name?.toLowerCase().includes(filters.search.toLowerCase()) ||
        r.plan?.planName?.toLowerCase().includes(filters.search.toLowerCase()) ||
        r.roomType.toLowerCase().includes(filters.search.toLowerCase()),
    );
  }
  filteredRooms = filterRooms(filteredRooms, { ...filters });

  return filteredRooms;
};
