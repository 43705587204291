import type { ConfigType } from "@eisox/dayjs";
import { VALVE_ERRORS_ENUM, getRoomProblem } from "@eisox/problems-handler";
import type { IValve } from "@eisox/valves";
import { VALVE_HARDWARE_VERSION_ENUM } from "@eisox/valves";

import type { RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import type { GroupDto } from "~/UI/screens/Settings/pages/Rooms";
import type { Gateway, House, Plan, Room } from "~/apiV2";

import { getValvesByRoomId, isValveHardwareIsUpper } from "../Valve/utils";

/**
 * The function checks if a room has valves with a hardware version of "V5".
 * @param {string} roomId - A string representing the ID of a room.
 * @param {ValvesWithProblem[]} valves
 * @returns a boolean value.
 */
export const isRoomHasValvesV5 = (roomId: string, valves: ValvesWithProblem[]) => {
  const valvesOfTheRoom = getValvesByRoomId(roomId, valves);
  return valvesOfTheRoom.some(v => isValveHardwareIsUpper(v.hardwareVersion, VALVE_HARDWARE_VERSION_ENUM.V5));
};

export interface RoomHistoryData {
  temperature?: number;
  swOffset?: number;
  correction?: number;
  humidity?: number;
  airQuality?: number;
  light?: number;
  opening?: number;
}

export const getRoomHistoryData = (room: Room, valves: ValvesWithProblem[]): RoomHistoryData => {
  const roomValves = getValvesByRoomId(room.id, valves);
  const valvesConnected = roomValves.filter(v => !v.errors.includes(VALVE_ERRORS_ENUM.DISCONNECTED));

  const {
    temperature,
    valvesRaisingTemperatureCount,
    swOffset,
    valvesRaisingSwOffsetCount,
    correction,
    valvesRaisingCorrectionCount,
    humidity,
    valvesRaisingHumidityCount,
    airQuality,
    valvesRaisingAirQualityCount,
    airQualityV5,
    valvesRaisingAirQualityV5Count,
    light,
    valvesRaisingLightCount,
    opening,
    valvesRaisingOpeningCount,
  } = valvesConnected.reduce(
    (
      acc: {
        temperature?: number;
        valvesRaisingTemperatureCount: number;
        swOffset?: number;
        valvesRaisingSwOffsetCount: number;
        correction?: number;
        valvesRaisingCorrectionCount: number;
        humidity?: number;
        valvesRaisingHumidityCount: number;
        airQuality?: number;
        valvesRaisingAirQualityCount: number;
        airQualityV5?: number;
        valvesRaisingAirQualityV5Count: number;
        light?: number;
        valvesRaisingLightCount: number;
        opening?: number;
        valvesRaisingOpeningCount: number;
      },
      valve,
    ) => {
      const { isSwitchEnabled } = room;
      const { sensors, stateData, hardwareVersion, closing, correction } = valve;
      const { swOffset } = stateData || {};
      const { temperature, humidity, indoorAirQuality, light } = sensors || {};

      const isValveError = valve.problemStatus === "error";

      if (temperature !== undefined) {
        acc.temperature = (acc.temperature ?? 0) + temperature;
        acc.valvesRaisingTemperatureCount++;
      }
      if (swOffset !== undefined && isSwitchEnabled) {
        acc.swOffset = (acc.swOffset ?? 0) + swOffset;
        acc.valvesRaisingSwOffsetCount++;
      }
      if (correction !== undefined && !isValveError) {
        acc.correction = (acc.correction ?? 0) + correction;
        acc.valvesRaisingCorrectionCount++;
      }
      if (humidity !== undefined) {
        acc.humidity = (acc.humidity ?? 0) + humidity;
        acc.valvesRaisingHumidityCount++;
      }
      if (indoorAirQuality !== undefined) {
        if (isValveHardwareIsUpper(hardwareVersion, VALVE_HARDWARE_VERSION_ENUM.V5)) {
          acc.airQualityV5 = (acc.airQualityV5 ?? 0) + indoorAirQuality;
          acc.valvesRaisingAirQualityV5Count++;
        } else {
          acc.airQuality = (acc.airQuality ?? 0) + indoorAirQuality;
          acc.valvesRaisingAirQualityCount++;
        }
      }
      if (light !== undefined) {
        acc.light = (acc.light ?? 0) + light;
        acc.valvesRaisingLightCount++;
      }
      if (closing !== undefined) {
        acc.opening = (acc.opening ?? 0) + 100 - closing;
        acc.valvesRaisingOpeningCount++;
      }
      return acc;
    },
    {
      temperature: undefined,
      valvesRaisingTemperatureCount: 0,
      swOffset: undefined,
      valvesRaisingSwOffsetCount: 0,
      correction: undefined,
      valvesRaisingCorrectionCount: 0,
      humidity: undefined,
      valvesRaisingHumidityCount: 0,
      airQuality: undefined,
      valvesRaisingAirQualityCount: 0,
      airQualityV5: undefined,
      valvesRaisingAirQualityV5Count: 0,
      light: undefined,
      valvesRaisingLightCount: 0,
      opening: undefined,
      valvesRaisingOpeningCount: 0,
    },
  );

  const round = (value: number) => Math.round(value * 10) / 10;

  return {
    temperature: temperature !== undefined ? round(temperature / valvesRaisingTemperatureCount) : undefined,
    swOffset: swOffset !== undefined ? round(swOffset / valvesRaisingSwOffsetCount) : undefined,
    correction: correction !== undefined ? round(correction / valvesRaisingCorrectionCount) : undefined,
    humidity: humidity !== undefined ? round(humidity / valvesRaisingHumidityCount) : undefined,
    airQuality:
      airQualityV5 !== undefined
        ? round(airQualityV5 / valvesRaisingAirQualityV5Count)
        : airQuality !== undefined
          ? round(airQuality / valvesRaisingAirQualityCount)
          : undefined,
    light: light !== undefined ? round(light / valvesRaisingLightCount) : undefined,
    opening: opening !== undefined ? round(opening / valvesRaisingOpeningCount) : undefined,
  };
};

export const getRoomsGroups = (rooms: Room[]): GroupDto[] =>
  rooms
    .reduce((acc, room) => {
      room.groupNames.forEach(groupName => {
        const existingGroup = acc.find(item => item.name === groupName);
        if (existingGroup) {
          room.id && existingGroup.rooms.push(room.id);
        } else {
          room.id && acc.push({ name: groupName, rooms: [room.id] });
        }
      });
      return acc;
    }, [] as GroupDto[])
    .sort((a, b) => a.name.localeCompare(b.name));

/**
 * Calculates the average comfort temperature for a list of rooms.
 *
 * @param rooms - An array of rooms with problem information.
 * @returns The average comfort temperature.
 */
export const calculateAverageComfort = (rooms: RoomsWithProblem[]) =>
  rooms.length
    ? Math.round(
        (rooms.reduce(
          (total, { comfortTemperature, swOffset = 0 }) =>
            total + comfortTemperature + (isNaN(swOffset) ? 0 : swOffset),
          0,
        ) /
          rooms.length) *
          10,
      ) / 10
    : 0;

export const roomsToRoomsWithProblem = (
  rooms: Room[],
  plans: Plan[],
  valves: ValvesWithProblem[],
  gateways: Gateway[],
  house: House,
  date?: ConfigType,
): RoomsWithProblem[] =>
  rooms.map(r => {
    const valvesOfTheRoom = getValvesByRoomId(r.id, valves);
    const roomData = getRoomHistoryData(r, valves);
    const planName = plans.find(p => p.id === r.plan.planId)!.name;
    const room = { ...r, ...roomData };

    const { problemStatus, errors, warnings } = getRoomProblem(
      room,
      // ! FIX THIS IN CORE PACKAGE
      { ...house, abscenceTemperature: house.absenceTemperature },
      valvesOfTheRoom as unknown as IValve[],
      gateways,
      date,
    );
    return {
      ...r,
      ...roomData,
      plan: { ...r.plan, planName },
      problemStatus,
      errors,
      warnings,
    };
  });
