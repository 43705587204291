import type { Socket } from "socket.io-client";

import { BOILERROOM_TIMEOUTS } from "~/constants";
import { deepArrayCompare } from "~/features/BoilerRooms/helpers";
import type { EmitEvents, ListenEvents } from "~/hooks";
import type {
  BoilerRoom,
  BoilerroomDataRes,
  CurrentHeatingCurveHslope,
  RawData,
  RawDataResV2,
  ResetDataRes,
} from "~/socketio/types";

enum Event {
  GET_DATA = "GET_DATA",
  GET_RAW_DATA = "GET_RAW_DATA",
  GET_RAW_DATA_V2 = "GET_RAW_DATA_V2",
  RESET_DATA = "RESET_DATA",
  UPDATE_DATA = "UPDATE_DATA",
  GET_CURRENT_HEATING_CURVE_HSLOPE = "GET_CURRENT_HEATING_CURVE_HSLOPE",
}

type EmitProps =
  | { event: Event.GET_DATA; payload: { type: "getDataBoilerRoomInit" | "getDataBoilerRoom" } }
  | { event: Event.GET_DATA; payload: { type: "hnInit"; heatingNetworkId: string } }
  | { event: Event.GET_RAW_DATA; payload: undefined }
  | { event: Event.GET_RAW_DATA_V2; payload: undefined }
  | { event: Event.RESET_DATA; payload: string[] }
  | { event: Event.UPDATE_DATA; payload: { boilerRooms: BoilerRoom[] } }
  | {
      event: Event.GET_CURRENT_HEATING_CURVE_HSLOPE;
      payload: { heatingNetworkId: string; externalTemperature: number };
    };

interface ResponseTypes {
  [Event.GET_DATA]: BoilerroomDataRes;
  [Event.RESET_DATA]: ResetDataRes;
  [Event.UPDATE_DATA]: BoilerroomDataRes;
  [Event.GET_RAW_DATA]: RawData[];
  [Event.GET_RAW_DATA_V2]: RawDataResV2;
  [Event.GET_CURRENT_HEATING_CURVE_HSLOPE]: CurrentHeatingCurveHslope;
}

interface SocketRequestProps<T extends Event> {
  socket: Socket;
  event: T;
  payload: EmitProps["payload"];
  timeout: number;
  validateResponse?: (response: ResponseTypes[T]) => boolean;
}

const ERROR = "ERROR";

const socketRequest = <T extends Event>({
  socket,
  event,
  payload,
  timeout,
  validateResponse,
}: SocketRequestProps<T>): Promise<ResponseTypes[T]> => {
  return new Promise((resolve, reject) => {
    const responseEvent = event === Event.GET_RAW_DATA_V2 ? "GET_RAW_DATA_RES_V2" : `${event}_RES`;

    const timer = setTimeout(() => {
      socket.off(responseEvent, onResponse);
      reject(new Error("Request timeout"));
    }, timeout);

    const onResponse = (response: ResponseTypes[T]) => {
      if (!validateResponse || validateResponse(response)) {
        clearTimeout(timer);
        socket.off(responseEvent, onResponse);
        socket.off(ERROR, onError);
        resolve(response);
      }
    };

    const onError = (error: string) => {
      clearTimeout(timer);
      socket.off(responseEvent, onResponse);
      socket.off(ERROR, onError);
      reject(new Error(error));
    };

    socket.on(responseEvent, onResponse);
    socket.on(ERROR, onError);
    socket.emit(event, payload);
  });
};

interface UpdateSocketRequestProps {
  socket: Socket<ListenEvents, EmitEvents>;
  boilerRooms: BoilerRoom[];
}

const updateSocketRequest = ({ socket, boilerRooms }: UpdateSocketRequestProps) => {
  return socketRequest<Event.UPDATE_DATA>({
    socket,
    event: Event.UPDATE_DATA,
    payload: { boilerRooms },
    timeout: BOILERROOM_TIMEOUTS.UPDATE_DATA,
    validateResponse: response => {
      return !!response.boilerRooms && deepArrayCompare(response.boilerRooms, boilerRooms);
    },
  });
};

interface ResetSocketRequestProps {
  socket: Socket;
  modules: string[];
}

const resetSocketRequest = ({ socket, modules }: ResetSocketRequestProps) => {
  return socketRequest<Event.RESET_DATA>({
    socket,
    event: Event.RESET_DATA,
    payload: modules,
    timeout: BOILERROOM_TIMEOUTS.RESET_DATA,
  });
};

const getRawDataV2 = (socket: Socket, moduleId: string) => {
  return socketRequest<Event.GET_RAW_DATA_V2>({
    socket,
    event: Event.GET_RAW_DATA_V2,
    payload: undefined,
    timeout: BOILERROOM_TIMEOUTS.GET_RAW_DATA,
    validateResponse: response => {
      return response.rawData?.some(rd => rd.moduleId === moduleId) ?? false;
    },
  });
};

const getCurrentHeatingCurveHslope = (
  socket: Socket,
  { externalTemperature, heatingNetworkId }: { externalTemperature: number; heatingNetworkId: string },
) => {
  return socketRequest<Event.GET_CURRENT_HEATING_CURVE_HSLOPE>({
    socket,
    event: Event.GET_CURRENT_HEATING_CURVE_HSLOPE,
    payload: { externalTemperature, heatingNetworkId },
    timeout: BOILERROOM_TIMEOUTS.UPDATE_DATA,
  });
};

export { Event, getCurrentHeatingCurveHslope, getRawDataV2, resetSocketRequest, updateSocketRequest };
