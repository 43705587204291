import { useTranslation } from "react-i18next";

import { ActionButtonV2, UnProtectedRouteResponsiveCard } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";

import type { InvalidToken, RecoverAccountRequestParams } from "~/apiV2";
import { env } from "~/configuration";

import styles from "./ExpiredToken.module.scss";

interface PageProps {
  type: InvalidToken;
  email: string;
  submit: (params: RecoverAccountRequestParams) => void;
}

const ExpiredTokenPage: React.FC<PageProps> = ({ type, email, submit }) => {
  const { t } = useTranslation();

  return (
    <UnProtectedRouteResponsiveCard version={env.VERSION}>
      <div className={styles.expiredToken}>
        <p className={styles.expiredToken__text}>{t("expiredToken.text", { context: type })}</p>
        <p className={styles.expiredToken__email}>{email}</p>
        <ActionButtonV2 rounded onClick={() => submit({ email })}>
          {t("expiredToken.generate")}
          <ArrowRightIcon />
        </ActionButtonV2>
      </div>
    </UnProtectedRouteResponsiveCard>
  );
};

export { ExpiredTokenPage };
