import type { LoaderFunctionArgs } from "react-router-dom";
import { redirect } from "react-router-dom";

import dayjs from "@eisox/dayjs";
import type {
  GatewayErrors,
  GatewayWarnings,
  RoomErrors,
  RoomTempProblems,
  RoomWarnings,
  ValveErrors,
  ValveWarnings,
} from "@eisox/problems-handler";
import type { QueryClient } from "@tanstack/react-query";

import type { Gateway, HeatingNetwork, House, Module, Room, UserRole, Valve } from "~/apiV2";
import { NotFoundException, queries } from "~/apiV2";
import { routeToHouses } from "~/routes/utils";
import { useUserStore } from "~/stores";
import type { RoomHistoryData } from "~/utils";
import {
  gatewaysToGatewaysWithProblem,
  getRoomsGroups,
  roomsToRoomsWithProblem,
  valvesToValvesWithProblem,
} from "~/utils";

import type { GroupDto } from "../Settings/pages/Rooms";

export type GatewaysWithProblem = Status<GatewayErrors, GatewayWarnings> &
  Omit<Gateway, "meshs"> & {
    plan: Gateway["plan"] & { planName: string };
    meshs: (Gateway["meshs"][number] & { name: string })[];
  };

export type ValvesWithProblem = Status<ValveErrors, ValveWarnings> &
  Valve & {
    gatewayName: string;
    gatewayMac?: string;
    gatewaySoftwareVersion?: string;
    roomName: string;
    plan: Valve["plan"] & { planName: string };
  };

export type RoomsWithProblem = Status<RoomErrors | RoomTempProblems, RoomWarnings | RoomTempProblems> &
  RoomHistoryData &
  Room & { plan: Room["plan"] & { planName: string } };

export type HouseType = House & {
  frostFreeTemperature: number;
  absenceTemperature: number;
  preComfortTemperature: number;
};

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const houseId = params.houseId!;

    // house
    let house: HouseType;

    try {
      house = await queryClient.fetchQuery({ ...queries.house.house(houseId), staleTime: 0 });
    } catch (error) {
      if (error instanceof NotFoundException) return redirect(routeToHouses());
      throw error;
    }

    if (house.suspendedAt && dayjs(house.suspendedAt).isBefore()) {
      const roles: UserRole = useUserStore.getState().roles;
      if (!roles.installer?.includes(house._id)) return redirect(`${routeToHouses()}?suspended=${house._id}`);
    }

    const date = dayjs();

    const plansResponsePromise = queryClient.fetchQuery({ ...queries.plan.house(houseId), staleTime: 0 });
    const roomsResponsePromise = queryClient.fetchQuery({ ...queries.room.house(houseId), staleTime: 0 });
    const gatewaysResponsePromise = queryClient.fetchQuery({ ...queries.gateway.house(houseId), staleTime: 0 });
    const valvesResponsePromise = queryClient.fetchQuery({ ...queries.valve.house(houseId), staleTime: 0 });
    const meshsResponsePromise = queryClient.fetchQuery({ ...queries.mesh.house(houseId), staleTime: 0 });
    const boilerroomsResponsePromise = queryClient.fetchQuery({ ...queries.boilerRoom.house(houseId), staleTime: 0 });

    const [plansResponse, roomsResponse, gatewaysResponse, valvesResponse, meshsResponse, boilerroomPosResponse] =
      await Promise.all([
        plansResponsePromise,
        roomsResponsePromise,
        gatewaysResponsePromise,
        valvesResponsePromise,
        meshsResponsePromise,
        boilerroomsResponsePromise,
      ]);

    // gateways
    const gateways: GatewaysWithProblem[] = gatewaysToGatewaysWithProblem(
      gatewaysResponse,
      plansResponse,
      meshsResponse,
      date,
    );

    // vavles
    const valves: ValvesWithProblem[] = valvesToValvesWithProblem(
      valvesResponse,
      plansResponse,
      gatewaysResponse,
      roomsResponse,
      date,
    );

    // rooms
    const rooms: RoomsWithProblem[] = roomsToRoomsWithProblem(
      roomsResponse,
      plansResponse,
      valves,
      gatewaysResponse,
      house,
      date,
    );

    // groups
    const groups: GroupDto[] = getRoomsGroups(rooms);

    // modules
    let modules: Module[] = [];
    let heatingNetworks: HeatingNetwork[] = [];
    if (boilerroomPosResponse.length > 0) {
      modules = await queryClient.fetchQuery({ ...queries.module.house(houseId), staleTime: 0 });
      heatingNetworks = await queryClient.fetchQuery({ ...queries.heatingNetwork.house(houseId), staleTime: 0 });
    }

    return {
      house,
      plans: plansResponse,
      rooms,
      groups,
      gateways,
      valves,
      boilerroomPos: boilerroomPosResponse,
      meshs: meshsResponse,
      modules,
      heatingNetworks,
      date,
    };
  };
