import { useEffect, useState } from "react";

import { isEqual } from "lodash";
import { Outlet, useParams } from "react-router-dom";

import { useIsFetching, useIsMutating } from "@tanstack/react-query";

import { LoadingPopup } from "~/UI/components";
import { queries } from "~/apiV2";

export const Root = () => {
  const { houseId } = useParams();

  const isFetching = useIsFetching({
    predicate: query =>
      query.queryKey !== queries.news.all.queryKey &&
      !isEqual(query.queryKey, queries.meteo.house(houseId ?? "").queryKey) &&
      !isEqual(query.queryKey, queries.planning.stat(houseId ?? "").queryKey),
  });
  const isMutating = useIsMutating();

  const [displayFetchingLoader, setDisplayFetchingLoader] = useState(false);
  const displayMutatingLoader = isMutating > 0;

  const type = displayMutatingLoader ? "mutating" : "fetching";

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayFetchingLoader(isFetching > 0);
    }, 500);

    if (isFetching === 0) {
      setDisplayFetchingLoader(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isFetching]);

  return (
    <>
      <Outlet />
      <LoadingPopup open={displayFetchingLoader || displayMutatingLoader} type={type} />
    </>
  );
};
