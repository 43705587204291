/* -------------------------------------------------------------------------------------------------
 * Breadcrumb
 * -----------------------------------------------------------------------------------------------*/
import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import { ChevronRightIcon } from "@eisox/icons";
import { Slot } from "@radix-ui/react-slot";

import styles from "./Breadcrumb.module.scss";

type BreadcrumbProps = React.ComponentPropsWithRef<"nav">;

const Root = forwardRef<HTMLElement, BreadcrumbProps>((props, forwardedRef) => (
  <nav {...props} ref={forwardedRef} aria-label="breadcrumb" />
));

Root.displayName = "Breadcrumb";

/* -------------------------------------------------------------------------------------------------
 * List
 * -----------------------------------------------------------------------------------------------*/

type BreadcrumbListProps = React.ComponentPropsWithRef<"ol">;

const List = forwardRef<HTMLOListElement, BreadcrumbListProps>(({ className, ...props }, forwardedRef) => (
  <ol {...props} ref={forwardedRef} className={cx(styles.list, className)} />
));

List.displayName = "BreadcrumbList";

/* -------------------------------------------------------------------------------------------------
 * Item
 * -----------------------------------------------------------------------------------------------*/

type BreadcrumbItemProps = React.ComponentPropsWithRef<"li">;

const Item = forwardRef<HTMLLIElement, BreadcrumbItemProps>(({ className, ...props }, forwardedRef) => (
  <li {...props} ref={forwardedRef} className={cx(styles.item, className)} />
));

Item.displayName = "BreadcrumbItem";

/* -------------------------------------------------------------------------------------------------
 * Link
 * -----------------------------------------------------------------------------------------------*/

interface BreadcrumbLinkProps extends React.ComponentPropsWithRef<"a"> {
  asChild?: boolean;
}

const Link = forwardRef<HTMLAnchorElement, BreadcrumbLinkProps>(({ asChild, className, ...props }, forwardedRef) => {
  const Comp = asChild ? Slot : "a";
  return <Comp {...props} ref={forwardedRef} className={cx(styles.link, className)} />;
});

Link.displayName = "BreadcrumbLink";

/* -------------------------------------------------------------------------------------------------
 * Page
 * -----------------------------------------------------------------------------------------------*/

interface BreadcrumbPageProps extends React.ComponentPropsWithRef<"span"> {
  asChild?: boolean;
}

const Page = forwardRef<HTMLSpanElement, BreadcrumbPageProps>(({ className, asChild, ...props }, forwardedRef) => {
  const Comp = asChild ? Slot : "span";
  return (
    <Comp
      {...props}
      ref={forwardedRef}
      className={cx(styles.page, className)}
      role="link"
      aria-disabled="true"
      aria-current="page"
    />
  );
});

Page.displayName = "BreadcrumbPage";

/* -------------------------------------------------------------------------------------------------
 * Separator
 * -----------------------------------------------------------------------------------------------*/

type BreadcrumbSeparatorProps = React.ComponentPropsWithRef<"li">;

const Separator = forwardRef<HTMLLIElement, BreadcrumbSeparatorProps>(
  ({ className, children, ...props }, forwardedRef) => (
    <li {...props} ref={forwardedRef} className={cx(styles.separator, className)} role="presentation" aria-hidden>
      {children ?? <ChevronRightIcon />}
    </li>
  ),
);

Separator.displayName = "BreadcrumbSeparator";

export { Item, Link, List, Page, Root, Separator };
export type {
  BreadcrumbItemProps,
  BreadcrumbLinkProps,
  BreadcrumbListProps,
  BreadcrumbPageProps,
  BreadcrumbProps,
  BreadcrumbSeparatorProps,
};
