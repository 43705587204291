import { useIntl } from "react-intl";

import { SearchInput } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import type { SelectorsType } from "..";
import { RoomsSelects } from "..";
import type { SelectFiltersType } from "../..";

import styles from "./SelectFilters.module.scss";

interface SelectFiltersProps {
  groups: { name: string; rooms: string[] }[];
  state: SelectFiltersType;
  handleSearch: (value: string) => void;
  handleChangeSelector: (selectors: SelectorsType) => void;
}

export const SelectFilters: React.FC<SelectFiltersProps> = ({ state, handleSearch, handleChangeSelector }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const selectFiltersStyle = bem("select-filters");
  const selectorsStyle = bem("selectors");

  return (
    <div className={selectFiltersStyle()}>
      <SearchInput
        value={state.search}
        onChange={handleSearch}
        placeholder={formatMessage({ id: "settings.content.menu.rooms.groups.select.filters.search.placeholder" })}
      />
      <div className={selectorsStyle()}>
        <RoomsSelects
          filters={{ plans: state.plans, groups: state.groups, types: state.types }}
          onChange={selectors => handleChangeSelector(selectors)}
        />
      </div>
    </div>
  );
};
