import { forwardRef } from "react";

import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { CrossIcon } from "@eisox/icons";
import * as RadixToast from "@radix-ui/react-toast";

import styles from "./Toast.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Provider
 * -----------------------------------------------------------------------------------------------*/

type ProviderProps = RadixToast.ToastProviderProps;

const Provider = RadixToast.Provider;

Provider.displayName = "ToastProvider";

/* -------------------------------------------------------------------------------------------------
 * Viewport
 * -----------------------------------------------------------------------------------------------*/

type ViewportProps = RadixToast.ToastViewportProps;

const Viewport = forwardRef<HTMLOListElement, ViewportProps>((props, ref) => {
  return <RadixToast.Viewport {...props} ref={ref} className={styles.viewport} />;
});

Viewport.displayName = "ToastViewport";

/* -------------------------------------------------------------------------------------------------
 * Toast
 * -----------------------------------------------------------------------------------------------*/

const toastVariants = cva(styles.toast, {
  variants: {
    variant: {
      success: styles.toast_variant_success,
      error: styles.toast_variant_error,
      warning: styles.toast_variant_warning,
    },
  },
  defaultVariants: {
    variant: "warning",
  },
});

interface ToastProps extends RadixToast.ToastProps, VariantProps<typeof toastVariants> {}

const Root = forwardRef<HTMLLIElement, ToastProps>(({ variant, className, ...props }, ref) => {
  return <RadixToast.Root {...props} ref={ref} className={toastVariants({ variant, className })} type="background" />;
});

Root.displayName = "Toast";

/* -------------------------------------------------------------------------------------------------
 * Title
 * -----------------------------------------------------------------------------------------------*/

type TitleProps = RadixToast.ToastTitleProps;

const Title = forwardRef<HTMLHeadingElement, TitleProps>((props, ref) => (
  <RadixToast.Title {...props} ref={ref} className={styles.title} />
));

Title.displayName = "ToastTitle";

/* -------------------------------------------------------------------------------------------------
 * Description
 * -----------------------------------------------------------------------------------------------*/

type DescriptionProps = RadixToast.ToastDescriptionProps;

const Description = forwardRef<HTMLParagraphElement, DescriptionProps>((props, ref) => (
  <RadixToast.Description {...props} ref={ref} className={styles.description} />
));

Description.displayName = "ToastDescription";

/* -------------------------------------------------------------------------------------------------
 * Action
 * -----------------------------------------------------------------------------------------------*/

type ActionProps = RadixToast.ToastActionProps;

const Action = forwardRef<HTMLButtonElement, ActionProps>((props, ref) => (
  <RadixToast.Action {...props} ref={ref} className={styles.action} />
));

Action.displayName = "ToastAction";

/* -------------------------------------------------------------------------------------------------
 * Close
 * -----------------------------------------------------------------------------------------------*/

type CloseProps = Omit<RadixToast.ToastCloseProps, "asChild">;

const Close = forwardRef<HTMLButtonElement, CloseProps>((props, ref) => (
  <RadixToast.Close {...props} ref={ref} className={styles.close} asChild>
    <CrossIcon />
  </RadixToast.Close>
));

Close.displayName = "ToastClose";

export { Action, Close, Description, Provider, Root, Title, Viewport };
export type { ActionProps, CloseProps, DescriptionProps, ProviderProps, TitleProps, ToastProps, ViewportProps };
