import i18next from "i18next";
import type { LoaderFunctionArgs } from "react-router-dom";
import { redirect } from "react-router-dom";
import { toast } from "react-toastify";

import type { QueryClient } from "@tanstack/react-query";

import { InvalidToken, TokenStatus, queries } from "~/apiV2";
import { routeToEnrollAccount, routeToExpiredToken, routeToSignIn, routeToUsedToken } from "~/routes/utils";

const loader =
  (queryClient: QueryClient) =>
  async ({ params, request }: LoaderFunctionArgs) => {
    if (!params.token) return redirect(routeToSignIn());

    const status = await queryClient.ensureQueryData(queries.user.tokenStatus(params.token));

    const type = request.url.includes(routeToEnrollAccount(params.token))
      ? InvalidToken.ACCOUNT
      : InvalidToken.PASSWORD;

    if (status === TokenStatus.EXPIRED) return redirect(routeToExpiredToken(params.token, type));
    if (status === TokenStatus.ALREADY_USED) return redirect(routeToUsedToken(params.token, type));
    if (status === TokenStatus.INVALID) {
      toast.error(i18next.t("token.expired"));
      return redirect(routeToSignIn());
    }
    return { token: params.token };
  };

export { loader };
