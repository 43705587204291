import * as React from "react";

import { IconProps } from "./types";

export const StopIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M8 1.5C4.13438 1.5 1 4.63438 1 8.5C1 12.3656 4.13438 15.5 8 15.5C11.8656 15.5 15 12.3656 15 8.5C15 4.63438 11.8656 1.5 8 1.5ZM8 14.3125C4.79063 14.3125 2.1875 11.7094 2.1875 8.5C2.1875 7.10938 2.67656 5.83125 3.49219 4.83125L11.6687 13.0078C10.6687 13.8234 9.39062 14.3125 8 14.3125ZM12.5078 12.1687L4.33125 3.99219C5.33125 3.17656 6.60938 2.6875 8 2.6875C11.2094 2.6875 13.8125 5.29063 13.8125 8.5C13.8125 9.89062 13.3234 11.1687 12.5078 12.1687Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default StopIcon;
