import * as React from "react";

import { IconProps } from "./types";

export const UpdateIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M13.4531 2.56023L14.291 5.90777L10.9396 5.07089L11.7775 4.234C9.70176 2.16061 6.31277 2.16061 4.23707 4.234C2.16135 6.30738 2.16135 9.69258 4.23707 11.766C6.31277 13.8394 9.70177 13.8394 11.7775 11.766C12.8051 10.7395 13.322 9.4842 13.3224 8.13054L14.5 8.13095C14.5004 9.79092 13.8498 11.3697 12.6153 12.6028C10.083 15.1324 5.93156 15.1324 3.39924 12.6028C0.866919 10.0734 0.86692 5.9266 3.39924 3.39712C5.93156 0.867628 10.083 0.867628 12.6153 3.39712L13.4531 2.56023Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default UpdateIcon;
