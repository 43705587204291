var boxsWithInternet = [];
var allBoxs = [];
var boxsWithNoMeshResp = [];

const CARACTER_TO_ADD = "=";
const LENGTH_MAX = 15;

let nodeResult = {};
let routeResult = {};

function createHopes(node, hopes, usedNodes = []) {
  try {
    if (usedNodes.indexOf(node.source) != -1) {
      return hopes;
    }
  } catch (error) {
    usedNodes = [];
  }
  if (node.source === node.destination) {
    return hopes;
  }
  if (boxsWithInternet[node.source] != undefined) {
    for (let nodesIndex = 0; nodesIndex < boxsWithInternet[node.source].nodes.length; nodesIndex++) {
      const path = boxsWithInternet[node.source].nodes[nodesIndex];
      if (path.destination === node.destination) {
        if (path.destination === path.source) {
          hopes.push({
            destination: path.source,
            source: node.source,
            sourceName: node.sourceName,
            destinationName: path.sourceName,
          });
          return hopes;
        }
        hopes.push({
          destination: path.source,
          source: node.source,
          sourceName: node.sourceName,
          destinationName: path.sourceName,
        });
        if (boxsWithInternet[path.destination] === undefined) {
          return hopes;
        } else {
          try {
            usedNodes.push(node.source);
          } catch (error) {
            usedNodes = [];
          }
          return createHopes(path, hopes, usedNodes);
        }
      }
    }
  }
  return hopes;
}

function removeMacFromBoxsWithNoMeshResp(mac) {
  if (boxsWithNoMeshResp.indexOf(mac) === -1) {
    return;
  }
  boxsWithNoMeshResp.splice(boxsWithNoMeshResp.indexOf(mac), 1);
}

function getSignlalFromNeighbors(neighboros, macNeighbor) {
  for (let neighborIndex = 0; neighborIndex < neighboros.length; neighborIndex++) {
    const neighbor = neighboros[neighborIndex];
    if (neighbor.mac === macNeighbor) {
      return neighbor.signal;
    }
  }
}

function addNamesToNodes(boxMacMesh) {
  if (!boxsWithInternet[boxMacMesh].nodes) {
    return;
  }
  for (let nodeIndex = 0; nodeIndex < boxsWithInternet[boxMacMesh].nodes.length; nodeIndex++) {
    const node = boxsWithInternet[boxMacMesh].nodes[nodeIndex];
    if (node.sourceName === undefined) {
      if (boxsWithInternet[node.source] != undefined) {
        node.sourceName = boxsWithInternet[node.source].gatewayName;
      }
    }
    if (node.destinationName === undefined) {
      if (boxsWithInternet[node.destination] != undefined) {
        node.destinationName = boxsWithInternet[node.destination].gatewayName;
      }
    }
    // boxsWithInternet
    boxsWithInternet[boxMacMesh].nodes[nodeIndex] = node;
  }
}

function addHopesToNodes(boxMacMesh) {
  for (let nodesIndex = 0; nodesIndex < boxsWithInternet[boxMacMesh].nodes.length; nodesIndex++) {
    const node = boxsWithInternet[boxMacMesh].nodes[nodesIndex];
    var source = {};
    if (node.source != node.destination && boxsWithInternet[node.source] != undefined) {
      if (true) {
        boxsWithInternet[boxMacMesh].nodes[nodesIndex].routes = createHopes(node, [], []);
      }
    }
  }
}

function createBoxs(box, idMesh) {
  if (
    box != undefined &&
    box.nodes != undefined &&
    box.nodes.length > 0 &&
    allBoxs[box.nodes[0].destination] == undefined
  ) {
    return;
  }
  if (
    box != undefined &&
    box.nodes != undefined &&
    box.nodes.length > 0 &&
    allBoxs[box.nodes[0].destination].meshId != idMesh
  ) {
    return;
  }
  // boxsWithInternet
  boxsWithInternet[box.macMesh] = box;
  var caracterToAdd = "";
  if (boxsWithInternet[box.macMesh].gatewayName != undefined) {
    for (let index = 0; index < LENGTH_MAX - boxsWithInternet[box.macMesh].gatewayName.length; index++) {
      caracterToAdd += CARACTER_TO_ADD;
    }
    boxsWithInternet[box.macMesh].gatewayName += caracterToAdd;
  }
  for (var boxMacMesh in boxsWithInternet) {
    addNamesToNodes(boxMacMesh);
    addHopesToNodes(boxMacMesh);
    addSignalToNodes(boxMacMesh);
  }
}

function addSignalToNodes(boxMacMesh) {
  for (let nodeIndex = 0; nodeIndex < boxsWithInternet[boxMacMesh].nodes.length; nodeIndex++) {
    const node = boxsWithInternet[boxMacMesh].nodes[nodeIndex];
    if (node.destination === node.source) {
      node.signal = getSignlalFromNeighbors(boxsWithInternet[boxMacMesh].neighbors, node.destination);
    } else {
      if (node.routes != undefined) {
        for (let routeIndex = 0; routeIndex < node.routes.length; routeIndex++) {
          const route = node.routes[routeIndex];
          route.signal = getSignlalFromNeighbors(boxsWithInternet[route.source].neighbors, route.destination);
        }
      }
    }
  }
}

function showErrors() {
  document.getElementById("connected").innerHTML = "BOX non connectée(s) à Internet <br>";
  if (Object.keys(boxsWithInternet).length > 0) {
    document.getElementById("connected").style.display = "none";
  }
  document.getElementById("noResp").innerHTML = "BOX non connectée(s) à Internet <br>";
  document.getElementById("noResp").hidden = true;
  for (const boxMacMesh in allBoxs) {
    if (boxsWithInternet[boxMacMesh] === undefined) {
      document.getElementById("noResp").hidden = false;
      document.getElementById("noResp").innerHTML +=
        "box name: " +
        allBoxs[boxMacMesh].gatewayName +
        " mac gateway : " +
        allBoxs[boxMacMesh].mac +
        " mac mesh : " +
        allBoxs[boxMacMesh].macMesh +
        "<br>";
    }
  }
  document.getElementById("noMesh").innerHTML = "BOX absente(s) du réseau Mesh : <br>";
  document.getElementById("noMesh").hidden = true;
  boxsWithNoMeshResp.forEach(boxWithNoMeshResp => {
    document.getElementById("noMesh").hidden = false;
    document.getElementById("noMesh").innerHTML +=
      "box name: " +
      allBoxs[boxWithNoMeshResp].gatewayName +
      " mac gateway : " +
      allBoxs[boxWithNoMeshResp].mac +
      " mac mesh : " +
      allBoxs[boxWithNoMeshResp].macMesh +
      "<br>";
  });
}

function clear() {
  boxsWithInternet = [];
  allBoxs = [];
  boxsWithNoMeshResp = [];
  document.getElementById("path").innerHTML = "";
  document.getElementById("noResp").innerHTML = "";
  document.getElementById("noMesh").innerHTML = "";
}

function getNameFromMacMesh(macMesh) {
  var caracterToAdd = "";
  var caracterToAdd = "";
  if (allBoxs[macMesh] != undefined && allBoxs[macMesh].gatewayName != undefined) {
    for (let index = 0; index < LENGTH_MAX - allBoxs[macMesh].gatewayName.length; index++) {
      caracterToAdd += CARACTER_TO_ADD;
    }
  }
  if (allBoxs[macMesh] != undefined) {
    var name = allBoxs[macMesh].gatewayName + caracterToAdd || allBoxs[macMesh].mac;
    return name;
  }
  return "box not in data base " + macMesh;
}

function showNeighbors(mac) {
  boxsWithInternet[mac].neighbors.forEach(neighbor => {
    if (allBoxs[neighbor.mac] != undefined) {
      document.getElementById("path").innerHTML += allBoxs[neighbor.mac].gatewayName + " " + neighbor.signal + "<br>";
    }
  });
}

function showDirectLink(node, boxMacMesh) {
  nodeResult.destinationName = getNameFromMacMesh(node.destination);
  nodeResult.sourceName = getNameFromMacMesh(node.source);
  nodeResult.signal = node.signal;
  nodeResult.pathQuality = node.pathQuality;
  if (nodeResult.signal === undefined) {
    //nodeResult.signal=undefined
    //return;
  }
  if (boxsWithInternet[node.destination] === undefined) {
    document.getElementById("path").innerHTML +=
      boxsWithInternet[boxMacMesh].gatewayName +
      "======" +
      nodeResult.signal +
      "=====>" +
      "<font color='red'>" +
      nodeResult.sourceName +
      "</font>" +
      " path quality: " +
      nodeResult.pathQuality +
      "<br>";
  } else {
    document.getElementById("path").innerHTML +=
      boxsWithInternet[boxMacMesh].gatewayName +
      "======" +
      nodeResult.signal +
      "=====>" +
      nodeResult.sourceName +
      " path quality: " +
      nodeResult.pathQuality +
      "<br>";
  }
}

function hasLoop(array) {
  for (let index = 0; index < Math.trunc(array.length / 2) + 1; index++) {
    const element = array[index];
    for (let index2 = index + 1; index2 < array.length; index2++) {
      const element2 = array[index2];
      if (element.destination === element2.source || element.source === element2.destination) {
        return true;
      }
    }
  }
  return false;
}

function showRoutes(node, boxMacMesh) {
  if (boxsWithInternet[boxMacMesh] === undefined) {
    return;
  }
  nodeResult.destinationName = getNameFromMacMesh(node.destination);
  nodeResult.sourceName = getNameFromMacMesh(node.source);
  nodeResult.signal = getSignlalFromNeighbors(boxsWithInternet[boxMacMesh].neighbors, node.source);
  // if (nodeResult.signal === undefined) {
  //   return;
  // }
  nodeResult.pathQuality = node.pathQuality;
  if (node.routes === undefined) {
    document.getElementById("path").innerHTML +=
      boxsWithInternet[boxMacMesh].gatewayName +
      "======" +
      nodeResult.signal +
      "=====>" +
      "<font color='red'>" +
      nodeResult.sourceName +
      "==Chemin incomplet==>" +
      nodeResult.destinationName +
      " path quality: " +
      nodeResult.pathQuality +
      "</font><br>";
  }
  if (node.routes != undefined) {
    document.getElementById("path").innerHTML +=
      boxsWithInternet[boxMacMesh].gatewayName + "======" + nodeResult.signal + "=====>" + nodeResult.sourceName;
    for (let routeIndex = 0; routeIndex < node.routes.length; routeIndex++) {
      const route = node.routes[routeIndex];
      routeResult = {};
      routeResult.signal = route.signal;
      routeResult.destinationName = getNameFromMacMesh(route.destination);
      if (boxsWithInternet[route.destination] != undefined) {
        document.getElementById("path").innerHTML +=
          "======" + routeResult.signal + "=====>" + routeResult.destinationName;
      } else {
        document.getElementById("path").innerHTML +=
          "======" + routeResult.signal + "=====>" + "<font color='red'>" + routeResult.destinationName + "</font>";
      }
    }
    if (
      node.routes != undefined &&
      node.routes[node.routes.length - 1] != undefined &&
      node.routes[node.routes.length - 1].destination != node.destination
    ) {
      if (hasLoop(node.routes)) {
        document.getElementById("path").innerHTML +=
          "<font color='red'>======Boucle detectée=====" +
          getNameFromMacMesh(node.destination) +
          " path quality: " +
          nodeResult.pathQuality +
          "<br></font>";
      } else {
        document.getElementById("path").innerHTML +=
          "<font color='red'>======Chemin incomplet=====" +
          getNameFromMacMesh(node.destination) +
          " path quality: " +
          nodeResult.pathQuality +
          "<br></font>";
      }
    } else {
      document.getElementById("path").innerHTML += " path quality: " + nodeResult.pathQuality + "<br>";
    }
  }
}

export function showInfo(idMesh, macGatewayMesh, socket, socketNameSpace) {
  socketNameSpace.on(idMesh + "RESPONSE", function (data) {
    if (data != undefined && data.action === "clear") {
      clear();
      data.gateways.forEach(gateway => {
        if (gateway.meshs != undefined) {
          gateway.meshs.forEach(mesh => {
            if (mesh.id == idMesh) {
              gateway.macMesh = mesh.mac;
              gateway.meshId = mesh.id;
              allBoxs[mesh.mac] = gateway;
              boxsWithNoMeshResp.push(mesh.mac);
            }
          });
        }
      });
    }
    if (data.idHouse != undefined) {
      delete data.idHouse;
      // boxsWithInternet
      const gateway = allBoxs[data.macMesh];
      const macMeshs = gateway.macMesh.split(",");
      if (data.paths) {
        data.nodes = data.paths;
      }
      delete data.paths;
      delete data.lastUpdate;
      macMeshs.forEach(macMesh => {
        boxsWithInternet[macMesh] = data;
      });
      showErrors();
      createBoxs(data, idMesh);

      document.getElementById("path").innerHTML = new Date() + "<br>";
      for (let boxMacMesh in boxsWithInternet) {
        if (boxMacMesh.indexOf(",") != -1) {
          boxMacMesh = macGatewayMesh;
        }
        boxsWithInternet[boxMacMesh].nodes.sort(function (node1, node2) {
          if (boxsWithInternet[node1.destination] === undefined && boxsWithInternet[node2.destination] === undefined) {
            return false;
          }
          if (node1.destinationName != undefined && node2.destinationName != undefined) {
            return node1.destinationName > node2.destinationName;
          }
          if (node1.destinationName === undefined) {
            return false;
          }
          return true;
        });
        if (boxMacMesh === macGatewayMesh) {
          document.getElementById("path").innerHTML +=
            "Box Informations box name : " +
            allBoxs[boxMacMesh].gatewayName +
            " mac gateway : " +
            allBoxs[boxMacMesh].mac +
            " mac mesh : " +
            allBoxs[boxMacMesh].macMesh +
            "<br>";
          document.getElementById("path").innerHTML += "Paths informations <br>";
        }

        for (let nodeIndex = 0; nodeIndex < boxsWithInternet[boxMacMesh].nodes.length; nodeIndex++) {
          const node = boxsWithInternet[boxMacMesh].nodes[nodeIndex];
          removeMacFromBoxsWithNoMeshResp(node.source);
          removeMacFromBoxsWithNoMeshResp(node.destination);
          nodeResult = {};
          if (boxMacMesh === macGatewayMesh) {
            document.getElementById("path").innerHTML += "<br>";
          }
          if (node.source === node.destination && boxMacMesh === macGatewayMesh) {
            if (allBoxs[node.destination] != undefined) {
              if (boxsWithInternet[node.destination] === undefined) {
                document.getElementById("path").innerHTML +=
                  "<b><font color='red'>" + allBoxs[node.destination].gatewayName + "</b></font><br>";
              } else {
                document.getElementById("path").innerHTML += "<b>" + allBoxs[node.destination].gatewayName + "</b><br>";
                showNeighbors(node.destination);
                document.getElementById("path").innerHTML += "<b>path to gateway</b><br>";
              }
            }
            showDirectLink(node, boxMacMesh);
            if (boxsWithInternet[node.destination] != undefined) {
              for (let nodeIndex2 = 0; nodeIndex2 < boxsWithInternet[node.source].nodes.length; nodeIndex2++) {
                const node2 = boxsWithInternet[node.source].nodes[nodeIndex2];
                if (boxMacMesh === node2.destination) {
                  if (node2.destination === node2.source) {
                    showDirectLink(node2, node.destination);
                  } else {
                    showRoutes(node2, node.destination);
                  }
                  break;
                }
              }
            }
          } else {
            if (allBoxs[node.destination] != undefined) {
              if (boxMacMesh === macGatewayMesh) {
                if (boxsWithInternet[node.destination] === undefined) {
                  document.getElementById("path").innerHTML +=
                    "<b><font color='red'>" + allBoxs[node.destination].gatewayName + "</b></font><br>";
                } else {
                  document.getElementById("path").innerHTML +=
                    "<b>" + allBoxs[node.destination].gatewayName + "</b><br>";
                  showNeighbors(node.destination);
                  document.getElementById("path").innerHTML += "<b>path to gateway</b><br>";
                }
                showRoutes(node, boxMacMesh);
                if (boxsWithInternet[node.destination] != undefined) {
                  for (let nodeIndex2 = 0; nodeIndex2 < boxsWithInternet[node.destination].nodes.length; nodeIndex2++) {
                    const node2 = boxsWithInternet[node.destination].nodes[nodeIndex2];
                    if (boxMacMesh === node2.destination) {
                      if (node2.source === node2.destination) {
                        showDirectLink(node2, node.destination);
                      } else {
                        showRoutes(node2, node.destination);
                      }
                      break;
                    }
                  }
                }
              }
            } else {
              /*    console.log(
                    "allBoxs[node.destination] undefined",
                    node.destination
                  );*/
            }
          }
        }
      }
    }
    showErrors();
  });
}
