import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { DELETE_METHOD, GET_METHOD, PATCH_METHOD, POST_METHOD } from "../constants/fetchConstants";
import { internalFetchByUrl } from "../helpers/communication/FetchApi";
import { meshRouteConcat, routeHouses } from "../routesStatic/routesExpress";

const ServerUrl = env.SERVER_URL;

export function updateMesh(idHouse, idMesh, name) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const body = {};
  if (name !== undefined && name !== null) {
    body.name = name;
  }
  if (Object.keys(body).length !== 0) {
    const RouteEditMesh = ServerUrl + routeHouses + "/" + idHouse + meshRouteConcat + idMesh;
    fetchingResponse = internalFetchByUrl(RouteEditMesh, PATCH_METHOD, customHeaders, body);
  }

  return fetchingResponse;
}

export function createMeshs(idHouse, names) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const body = {};
  if (names !== undefined && names !== null && names.length !== 0) {
    body.names = names;
  }
  if (Object.keys(body).length !== 0) {
    const RouteEditMesh = ServerUrl + routeHouses + "/" + idHouse + meshRouteConcat;
    fetchingResponse = internalFetchByUrl(RouteEditMesh, POST_METHOD, customHeaders, body);
  }

  return fetchingResponse;
}

export function deleteMesh(idHouse, idMesh) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteDelMesh = ServerUrl + routeHouses + "/" + idHouse + meshRouteConcat + idMesh;
  fetchingResponse = internalFetchByUrl(RouteDelMesh, DELETE_METHOD, customHeaders);
  return fetchingResponse;
}
