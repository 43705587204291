import { cx } from "class-variance-authority";
import type { FieldErrors, UseFormRegister } from "react-hook-form";

import type { GroupedValves, Valve } from "../../types";
import { GroupField } from "../../types";
import { Group } from "../Group";
import { Item } from "../Item";

import styles from "./List.module.scss";

interface ListProps {
  groupedValves: Record<string, Omit<GroupedValves[string][number], "room" | "plan" | "gateway">[]>;
  register?: UseFormRegister<Record<string, number>>;
  errors?: FieldErrors<Record<string, number>>;
  selectedItemId?: string;
  onItemClick?: (itemId: string) => void;
  itemSelectionCallback?: VoidFunction;
}

const List: React.FC<ListProps> = ({
  groupedValves,
  register,
  errors,
  selectedItemId,
  onItemClick,
  itemSelectionCallback,
}) => {
  const groups = Object.keys(groupedValves) as GroupField[];

  const itemRefCallback = (node: HTMLDivElement | null, id: string) => {
    if (node && selectedItemId === id) {
      setTimeout(() => {
        node.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
  };

  const itemProps = (valve: Omit<Valve, "plan" | "room" | "gateway">) => ({
    ref: (node: HTMLDivElement | null) => itemRefCallback(node, valve.id),
    key: valve.id,
    id: valve.id,
    uid: valve.uid,
    mac: valve.mac,
    hslope: valve.hslope,
    maxOpening: valve.maxOpening,
    softwareVersion: valve.softwareVersion,
    gatewaySoftwareVersion: valve.gatewaySoftwareVersion,
    register: register,
    error: errors?.[valve.id],
    selected: selectedItemId === valve.id,
    onClick: () => onItemClick?.(valve.id),
    onAnimationEnd: itemSelectionCallback,
  });

  if (groups.every(group => group === GroupField.noGrouping)) {
    return (
      <div className={cx(styles.list)}>
        {groupedValves[GroupField.noGrouping].map(valve => (
          <Item {...itemProps(valve)} />
        ))}
      </div>
    );
  }

  return (
    <div className={styles.list}>
      {Object.entries(groupedValves)
        .sort(([groupA], [groupB]) => groupA.localeCompare(groupB))
        .map(([group, valves]) => (
          <Group.Root key={group} defaultOpen>
            <Group.Trigger>{group}</Group.Trigger>
            <Group.Content className={styles.list__content}>
              {valves.map(valve => (
                <Item {...itemProps(valve)} />
              ))}
            </Group.Content>
          </Group.Root>
        ))}
    </div>
  );
};

export { List };
