import { useState } from "react";

import { LatLng } from "leaflet";
import { useIntl } from "react-intl";

import { ActionButton, Modal } from "@eisox/design-system";
import { ArrowRightIcon, LocationIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { useQuery } from "@tanstack/react-query";

import type { MarkerProps } from "~/UI/components";
import { Map } from "~/UI/components";
import { SearchLocation } from "~/UI/layouts";
import type { Location } from "~/apiV2";
import { queries } from "~/apiV2";

import styles from "./PositionDialog.module.scss";

interface PositionDialogV2 {
  position: {
    longitude: number;
    latitude: number;
  };
  handleChangePosition: (latitude: number, longitude: number, displayedAddress: string) => void;
}

export const PositionDialog: React.FC<PositionDialogV2> = ({ position, handleChangePosition }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const positionDialogStyle = bem("position-dialog");

  const initialPosition: LatLng = new LatLng(position.latitude, position.longitude);

  const [housePosition, setHousePosition] = useState<LatLng>(initialPosition);

  const { data: houseAddress } = useQuery({
    ...queries.mapQuest.reverse({ lat: housePosition.lat, lng: housePosition.lng }),
    enabled: !!housePosition,
    select: data => {
      const location = data.results[0].locations[0];
      return [location.street, location.adminArea5, location.postalCode].filter(Boolean).join(", ");
    },
  });

  const positions: MarkerProps[] = [
    { position: housePosition, draggable: true, onChangePosition: latLng => setHousePosition(latLng) },
  ];

  const handleChangeLocation = (location: Location) => {
    setHousePosition(new LatLng(location.latLng.lat, location.latLng.lng));
  };

  const onSubmit = () => {
    handleChangePosition(housePosition.lat, housePosition.lng, houseAddress ?? "");
  };

  return (
    <Modal.Content className={positionDialogStyle()}>
      <Modal.Header
        title={formatMessage({ id: "houses.house.position.dialog.title" })}
        subtitle={formatMessage({ id: "houses.house.position.dialog.subtitle" })}
        icon={<LocationIcon />}
      >
        <Modal.Close>
          <ActionButton variant="cancel" text={formatMessage({ id: "houses.house.position.dialog.cancel" })} />
        </Modal.Close>
        <Modal.Close>
          <ActionButton
            rounded
            text={formatMessage({ id: "houses.house.position.dialog.save" })}
            icon={<ArrowRightIcon />}
            onClick={onSubmit}
            disabled={!housePosition}
          />
        </Modal.Close>
      </Modal.Header>
      <SearchLocation className={positionDialogStyle("search-location")} onChange={l => handleChangeLocation(l)} />
      <Map
        className={positionDialogStyle("map")}
        positions={positions}
        onMapClick={position => setHousePosition(position)}
      />
    </Modal.Content>
  );
};
