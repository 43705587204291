import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { DELETE_METHOD, GET_METHOD, PATCH_METHOD, POST_METHOD, PUT_METHOD } from "../constants/fetchConstants";
import { internalFetchByUrl } from "../helpers/communication/FetchApi";
import { gatewayRouteConcat, installRouteContact, notifyRouteConcat, routeHouses } from "../routesStatic/routesExpress";

const ServerUrl = env.SERVER_URL;

export function editGateways(gateways, idHouse) {
  let fetchingResponse = {};
  const body = {};
  if (gateways !== null && gateways !== undefined && gateways.length !== 0) {
    body.gateways = gateways;
  }

  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteEditGateway = ServerUrl + routeHouses + "/" + idHouse + gatewayRouteConcat;
  if (body.length !== 0) {
    fetchingResponse = internalFetchByUrl(RouteEditGateway, PATCH_METHOD, customHeaders, body);
  }
  return fetchingResponse;
}

export function removeGateway(idGateway, idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteEditGateway = ServerUrl + routeHouses + "/" + idHouse + gatewayRouteConcat + idGateway;
  fetchingResponse = internalFetchByUrl(RouteEditGateway, DELETE_METHOD, customHeaders);
  return fetchingResponse;
}

export function notifyGateway(idGateway, idHouse, notificationName, meshId, macValve, task, arg, expireAt) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const body = {
    notificationName: notificationName,
  };
  if (typeof idGateway === "string") {
    body.gatewayId = idGateway;
  }
  if (Array.isArray(idGateway)) {
    body.gatewayIds = idGateway;
  }
  if (meshId !== undefined && meshId !== null) {
    body.meshId = meshId;
  }
  if (macValve !== undefined && macValve !== null) {
    body.macValve = macValve;
  }
  if (task !== undefined && task !== null) {
    body.task = task;
  }
  if (arg !== undefined && arg !== null && arg.length !== 0) {
    body.arg = arg;
  }
  if (expireAt !== undefined && expireAt !== null) {
    body.expireAt = expireAt;
  }
  const RouteNotifyGateway = ServerUrl + routeHouses + notifyRouteConcat + idHouse;
  fetchingResponse = internalFetchByUrl(RouteNotifyGateway, POST_METHOD, customHeaders, body);
  return fetchingResponse;
}

export function addGateways(body, idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };

  const RouteAddGateway = ServerUrl + installRouteContact + idHouse;
  if (Object.keys(body).length !== 0) {
    fetchingResponse = internalFetchByUrl(RouteAddGateway, POST_METHOD, customHeaders, body);
  }
  return fetchingResponse;
}

export function preinstallGateways(idHouse, gateways) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RoutePreinstallGateway = ServerUrl + routeHouses + "/" + idHouse + gatewayRouteConcat + "presInstall";
  fetchingResponse = internalFetchByUrl(RoutePreinstallGateway, PUT_METHOD, customHeaders, gateways);
  return fetchingResponse;
}
