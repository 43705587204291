import { ValvesMessageValvesStatus } from "@eisox/webapp-api-specification";
import { faker } from "@faker-js/faker";

import type { ValvesWithProblem } from "~/UI/screens/House";
import type { Valve } from "~/apiV2";

import { Builder } from "../utils";

export class ValveBuilder extends Builder<Valve> {
  constructor() {
    super({
      id: faker.database.mongodbObjectId(),
      uid: faker.number.int({ min: 1, max: 1000 }),
      gatewayId: faker.database.mongodbObjectId(),
      roomId: faker.database.mongodbObjectId(),
      status: ValvesMessageValvesStatus.toAutoInstall,
      mecaProblems: [],
      stateData: {},
      plan: {
        planId: faker.database.mongodbObjectId(),
        x: faker.number.int({ min: 10, max: 90 }),
        y: faker.number.int({ min: 10, max: 90 }),
      },
    });
  }
}

export class ValveWithProblemBuilder extends Builder<ValvesWithProblem> {
  constructor() {
    const valve = new ValveBuilder().build();
    super({
      ...valve,
      gatewayName: faker.company.name(),
      gatewayMac: faker.internet.mac(),
      roomName: faker.lorem.word(),
      plan: {
        ...valve.plan,
        planName: faker.lorem.word(),
      },
      errors: [],
      warnings: [],
      problemStatus: undefined,
    });
  }
}
