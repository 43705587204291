import type { PropsWithChildren } from "react";
import { useState } from "react";

import { createContext } from "@eisox/tools";

import type { DataType, DisplaySection } from "~/stores";
import { useUserPreferenceStore } from "~/stores";

const [PlansContext, usePlansContext] = createContext<{
  selectedDataSelector: DataType;
  selectedDisplaySelector: DisplaySection[];
  handleChangeDataSelector: (dataSelector: DataType) => void;
  handleChangeDisplaySelector: (displaySelector: DisplaySection) => void;
}>("PlansContext");

const PlansProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const data = useUserPreferenceStore.use.plan().data;
  const display = useUserPreferenceStore.use.plan().display;
  const addPlanData = useUserPreferenceStore.use.addPlanData();
  const addPlanDisplay = useUserPreferenceStore.use.addPlanDisplay();

  const [selectedDataSelector, setSelectedDataSelector] = useState<DataType>(data);
  const [selectedDisplaySelector, setSelectedDisplaySelector] = useState<DisplaySection[]>(display);

  const handleChangeDataSelector = (dataSelector: DataType) => {
    const newData = addPlanData(dataSelector);
    setSelectedDataSelector(newData);
  };

  const handleChangeDisplaySelector = (displaySelector: DisplaySection) => {
    const newData = addPlanDisplay(displaySelector);
    setSelectedDisplaySelector(newData);
  };

  const value = {
    selectedDataSelector,
    selectedDisplaySelector,
    handleChangeDataSelector,
    handleChangeDisplaySelector,
  };

  return <PlansContext {...value}>{children}</PlansContext>;
};

export { PlansProvider, usePlansContext };
