import type { RoomRoomType } from "@eisox/webapp-api-specification";

import type { RoomsWithProblem } from "~/UI/screens/House";
import { Mode } from "~/UI/screens/Settings/pages/FrostFree";

export const frostFreeMode = (rooms: RoomsWithProblem[]): Mode | undefined => {
  if (rooms.length > 0) {
    if (rooms.every(r => r.isFrostFree)) return Mode.ON;
    if (rooms.every(r => !r.isFrostFree)) return Mode.OFF;
  }
  return undefined;
};

export const unusualAbsenceDetectionOn = (rooms: RoomsWithProblem[]): boolean | null => {
  const unsualAbsenceDetection = rooms[0]?.isAutoPrecomf;
  return rooms.length > 0 && rooms.every(r => r.isAutoPrecomf === unsualAbsenceDetection)
    ? unsualAbsenceDetection
    : null;
};

export const preComfortTimeslotGenerationOn = (rooms: RoomsWithProblem[]): boolean | null => {
  const preComfortTimeslotGeneration = !!rooms[0]?.isPreComfRoom;
  return rooms.length > 0 && rooms.every(r => r.isPreComfRoom === preComfortTimeslotGeneration)
    ? preComfortTimeslotGeneration
    : null;
};

export const getComfortTemperature = (rooms: RoomsWithProblem[]): number | undefined => {
  const comfortTemperature = rooms[0]?.comfortTemperature;
  return rooms.length > 0 && rooms.every(r => r.comfortTemperature === comfortTemperature)
    ? comfortTemperature
    : undefined;
};

export const getNightTemperature = (rooms: RoomsWithProblem[]): number | undefined => {
  const nightTemperature = rooms[0]?.nightTemperature;
  return rooms.length > 0 && rooms.every(r => r.nightTemperature === nightTemperature) ? nightTemperature : undefined;
};

export const isSwitchEnabled = (rooms: RoomsWithProblem[]): boolean | null => {
  const isSwitchEnabled = rooms[0]?.isSwitchEnabled;
  return rooms.length > 0 && rooms.every(r => r.isSwitchEnabled === isSwitchEnabled) ? isSwitchEnabled : null;
};

export const getType = (rooms: RoomsWithProblem[]): RoomRoomType | undefined => {
  const type = rooms[0]?.roomType;
  return rooms.length > 0 && rooms.every(r => r.roomType === type) ? type : undefined;
};
