import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import * as yup from "yup";

import dayjs from "@eisox/dayjs";
import { Circle } from "@eisox/design-system";
import { PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { PeriodInterval } from "../PeriodInterval";

import styles from "./AddPeriod.module.scss";

const DATE_FORMAT = "YYYY-MM-DD";

const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    period: yup
      .object({
        begin: yup
          .string()
          .required(formatMessage({ id: "settings.content.addPeriod.startDateMandatory" }))
          .test("is-valid-date", formatMessage({ id: "settings.content.addPeriod.invalidDateFormat" }), value =>
            dayjs(value, DATE_FORMAT).isValid(),
          ),
        end: yup
          .string()
          .required(formatMessage({ id: "settings.content.addPeriod.endDateMandatory" }))
          .test("is-valid-date", formatMessage({ id: "settings.content.addPeriod.invalidDateFormat" }), value =>
            dayjs(value, DATE_FORMAT).isValid(),
          ),
      })
      .test("isBeginBeforeEnd", formatMessage({ id: "settings.content.addPeriod.startDateAfterEndDate" }), value => {
        const { begin, end } = value;
        return begin === end || dayjs(begin, DATE_FORMAT).isBefore(dayjs(end, DATE_FORMAT));
      }),
  });

type AddPeriodType = yup.InferType<ReturnType<typeof schema>>;

interface AddPeriodProps {
  onAddPeriod: (period: { begin: string; end: string }) => void;
}

export const AddPeriod: React.FC<AddPeriodProps> = ({ onAddPeriod }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const addPeriodStyle = bem("add-period");

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm<AddPeriodType>({
    resolver: yupResolver(schema(formatMessage)),
  });

  const handleAddPeriod = handleSubmit(data => {
    const { period } = data;
    onAddPeriod({ begin: period.begin.replace(/\//g, "-"), end: period.end.replace(/\//g, "-") });
    reset({ period: { begin: undefined, end: undefined } });
  });

  return (
    <div className={addPeriodStyle()}>
      <Controller
        name="period"
        control={control}
        render={({ field: { onChange, value } }) => (
          <PeriodInterval
            begin={value?.begin ? dayjs(value.begin, DATE_FORMAT) : undefined}
            end={value?.end ? dayjs(value.end, DATE_FORMAT) : undefined}
            onChangeBegin={begin => onChange({ begin: begin?.format(DATE_FORMAT), end: value?.end || undefined })}
            onChangeEnd={end => onChange({ begin: value?.begin || undefined, end: end?.format(DATE_FORMAT) })}
          />
        )}
      />
      <Circle
        className={addPeriodStyle("button", { disabled: !isDirty })}
        size={20}
        selected
        onClick={handleAddPeriod}
        disabled={!isDirty}
      >
        <PlusIcon style={{ width: 15, height: 15, color: "white" }} />
      </Circle>
      {errors.period && <span className={addPeriodStyle("error")}>{errors.period.message}</span>}
    </div>
  );
};
