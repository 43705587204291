import type { RawData, RawDataV2, TypeRawData } from "~/socketio/types";

interface DetailsVariablesData {
  name?: string | number;
  value?: string | number;
  instance?: number;
  type?: TypeRawData;
}

interface DetailsVariablesType {
  moduleId: string;
  data: DetailsVariablesData[];
}

const transformRawDataRes = (rawDataRes: RawDataV2[]): DetailsVariablesType[] => {
  return rawDataRes.map(r => {
    return {
      ...r,
      data: transformRawDatas(r.data),
    };
  });
};

const transformRawDatas = (rawData: RawData[]): DetailsVariablesData[] => {
  const resultMap = new Map<string, DetailsVariablesData>();

  for (const { instance, type, property, value } of rawData) {
    const key = `${instance}_${type}`;
    let entry = resultMap.get(key);

    if (!entry) {
      entry = { instance, type };
      resultMap.set(key, entry);
    }

    if (property === "objectName") {
      entry.name = value;
    } else if (property === "presentValue") {
      entry.value = value;
    }
  }

  const resultData: DetailsVariablesData[] = [];
  for (const { name, value, instance, type } of resultMap.values()) {
    resultData.push({ name, value, instance, type });
  }

  return resultData;
};
export { transformRawDataRes };
export type { DetailsVariablesType, DetailsVariablesData };
