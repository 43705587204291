export const blueColor = "#1100fa";
export const darkBlueColor = "#122138";
export const whiteColor = "#ffffff";
export const darkerGrayColor = "#d0d0d0";
export const restartValveColor = "#0070c0";
export const verifyGatewayColor = "#ffeb9c";
export const reviewValveColor = "#00b050";
export const changeBatteryColor = "#ffc000";
export const replaceValveColor = "#ff0000";
export const replaceGatewayColor = "#ff0000";
export const unclogCheckRadioTransmissionColor = "#ffc7ce";
export const toInstallColor = "#ffff00";

export const colors = {
  blueColor,
  darkBlueColor,
  whiteColor,
  darkerGrayColor,
  restartValveColor,
  verifyGatewayColor,
  reviewValveColor,
  changeBatteryColor,
  replaceValveColor,
  replaceGatewayColor,
  unclogCheckRadioTransmissionColor,
  toInstallColor,
};
