import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  ActionButtonV2,
  ButtonV2,
  PasswordInput,
  TextInput,
  UnProtectedRouteResponsiveCard,
} from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { FieldContainer } from "~/UI";
import type { LoginRequestBody } from "~/apiV2";
import { env } from "~/configuration";

import styles from "./SignIn.module.scss";

export type LoginSchema = z.infer<typeof schema>;
const schema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

interface PageProps {
  email?: string;
  submit: (body: LoginRequestBody) => void;
  onClickRecoverPassword?: () => void;
}

const SignInPage: React.FC<PageProps> = ({ email = "", submit, onClickRecoverPassword }) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm<LoginSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email,
    },
  });

  const onSubmit = handleSubmit(data => submit({ ...data, email: data.email.toLocaleLowerCase().trim() }));

  return (
    <UnProtectedRouteResponsiveCard className={styles.container} version={env.VERSION}>
      <form className={styles.signIn} onSubmit={onSubmit}>
        <TextInput
          {...register("email")}
          label={t("signin.email.label")}
          placeholder={t("signin.email.placeholder")}
          error={errors.email}
        />
        <FieldContainer label={t("signin.password.label")} error={errors.password}>
          <PasswordInput {...register("password")} placeholder={t("signin.password.placeholder")} />
        </FieldContainer>

        <ActionButtonV2 type="submit" rounded disabled={!isDirty}>
          {t("signin.login")}
          <ArrowRightIcon />
        </ActionButtonV2>
        <ButtonV2 onClick={onClickRecoverPassword}>{t("signin.activateAccountPasswordForgotten")}</ButtonV2>
      </form>
    </UnProtectedRouteResponsiveCard>
  );
};

export { SignInPage };
