import React from "react";

import clsx from "clsx";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Dialog } from "@eisox/design-system";
import { BoilerRoomIcon, NetworkIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { routeToBoilerroom } from "~/routes/utils";
import { BoilerromLayout } from "~/stores";

import { HeatingNetworks } from "../../layouts/HeatingNetworks";
import type { Substation as SubstationType } from "../../types/boiler-room";
import { Card } from "../Card";
import { Temperature } from "../Temperature";
import { Temperatures } from "../Temperatures";

import styles from "./SubstationsPopup.module.scss";

interface SubstationProps extends SubstationType {
  houseId: string;
  className?: string;
}

const Substation: React.FC<SubstationProps> = ({
  houseId,
  id,
  name,
  startingTemperature,
  returnTemperature,
  className,
}) => {
  const bem = useBem(styles);
  const substationStyle = bem("substation");

  return (
    <div className={clsx(substationStyle(), className)}>
      <Link className={substationStyle("link")} to={routeToBoilerroom(houseId, id)}>
        <Card className={substationStyle("card")} title={name ?? "-"} icon={<NetworkIcon />} orientation="up" />
      </Link>
      <Temperatures startingTemperature={startingTemperature} returnTemperature={returnTemperature} />
    </div>
  );
};

interface SubstationsPopupProps {
  houseId: string;
  boilerroomName?: string;
  networkName?: string;
  startingTemperature?: number;
  returnTemperature?: number;
  substations: SubstationType[];
  children?: React.ReactNode;
}

export const SubstationsPopup: React.FC<SubstationsPopupProps> = ({
  houseId,
  boilerroomName,
  networkName,
  startingTemperature,
  returnTemperature,
  substations,
  children,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const substationsPopupStyle = bem("substations-popup");
  const contentStyle = bem("content");

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content
        className={substationsPopupStyle()}
        title={formatMessage({ id: "boilerroom.substations" }, { sub: substations.length })}
      >
        <div className={contentStyle()}>
          <Dialog.Close asChild>
            <Card
              className={contentStyle("boilerroom")}
              title={boilerroomName ?? "-"}
              subtitle={networkName}
              icon={<BoilerRoomIcon className={substationsPopupStyle("boilerroom")} />}
            />
          </Dialog.Close>
          <div className={contentStyle("temperatures")}>
            {startingTemperature !== undefined ? (
              <Temperature type={"starting"} value={startingTemperature} orientation="vertical" />
            ) : (
              <div className={contentStyle("temperature")} />
            )}
            {returnTemperature !== undefined ? (
              <Temperature type={"returning"} value={returnTemperature} orientation="vertical" />
            ) : (
              <div className={contentStyle("temperature")} />
            )}
          </div>
          <HeatingNetworks layout={BoilerromLayout.PARALLEL}>
            {substations.map((s, i) => (
              <Substation key={i} houseId={houseId} {...s} />
            ))}
          </HeatingNetworks>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
