import { useRef } from "react";

import { useControllableState } from "@eisox/tools";
import { useVirtualizer } from "@tanstack/react-virtual";

import type { GatewaysWithProblem, HouseType, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";

import { getItems } from "../../helpers";
import { Item } from "../Item";

import styles from "./List.module.scss";

interface ListProps {
  houseId: string;
  displayedItems: "gateways" | "valves" | "rooms";
  items: GatewaysWithProblem[] | ValvesWithProblem[] | RoomsWithProblem[];
  gateways: GatewaysWithProblem[];
  rooms: RoomsWithProblem[];
  house: HouseType;
  onClickOnPlan: (planId: string) => void;
  onClickOnRoom: (planId: string, roomId: string) => void;
  onDeleteRoom?: VoidFunction;
  preReplace?: boolean;
  selectedItems?: string[];
  onSelectionChange?: (items: string[]) => void;
}

const List: React.FC<ListProps> = ({
  houseId,
  displayedItems,
  items: itemsProp,
  gateways,
  rooms,
  house,
  onClickOnPlan,
  onClickOnRoom,
  onDeleteRoom,
  preReplace = false,
  selectedItems: selectedItemsProp,
  onSelectionChange,
}) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const items = getItems(itemsProp, displayedItems, house);

  const [selectedItems = [], setSelectedItems] = useControllableState({
    prop: selectedItemsProp,
    onChange: onSelectionChange,
    defaultProp: [],
  });

  const count = items.length;
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 50,
  });

  const virtualItems = virtualizer.getVirtualItems();

  const handleSelectionChange = (item: GatewaysWithProblem | ValvesWithProblem) => {
    setSelectedItems(prevSelectedItems => {
      const newSelectedItems = prevSelectedItems?.includes(item.id!)
        ? prevSelectedItems.filter(id => id !== item.id)
        : [...(prevSelectedItems ?? []), item.id!];
      return newSelectedItems;
    });
  };

  return (
    <div ref={parentRef} className={styles.list}>
      <div style={{ height: virtualizer.getTotalSize(), width: "100%", position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
          }}
        >
          {virtualItems.map(virtualRow => (
            <div
              key={virtualRow.key}
              ref={virtualizer.measureElement}
              style={{ marginBottom: virtualRow.index === count - 1 ? 0 : 20 }}
            >
              <Item
                key={`item-${virtualRow.index}`}
                houseId={houseId}
                type={displayedItems}
                name={items[virtualRow.index].name}
                errors={items[virtualRow.index].errors}
                warnings={items[virtualRow.index].warnings}
                item={items[virtualRow.index].item}
                gateways={gateways}
                rooms={rooms}
                onClickOnPlan={onClickOnPlan}
                onClickOnRoom={onClickOnRoom}
                onDeleteRoom={onDeleteRoom}
                preReplace={preReplace}
                selected={selectedItems.includes(items[virtualRow.index].item.id!)}
                onSelectionChange={handleSelectionChange}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { List };
export type { ListProps };
