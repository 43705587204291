import type { GatewaysMessageGatewaysInner } from "@eisox/backend_webapp_api";
import { GATEWAY_ERRORS_ENUM, GATEWAY_WARNINGS_ENUM } from "@eisox/problems-handler";

import type { GatewaysWithProblem } from "~/UI/screens/House";
import { FORMAT_DATE } from "~/constants/timeConstants";
import { intl } from "~/i18n";

import { formatedDate } from "../timeUtils";

type StatusHandlers<T extends string> = Record<T, (g: GatewaysWithProblem, showDate: boolean) => string>;

/* ERRORS */
export const GATEWAYS_ERRORS_TRANSLATION: StatusHandlers<GATEWAY_ERRORS_ENUM> = {
  [GATEWAY_ERRORS_ENUM.DISCONNECTED]: (g: GatewaysWithProblem, showDate: boolean) =>
    intl.formatMessage(
      { id: "gateways.errors.disconnected" },
      { s: showDate, d: g.lastGatewayActivity ? formatedDate(FORMAT_DATE.boxProbleme, g.lastGatewayActivity) : "-" },
    ),
  [GATEWAY_ERRORS_ENUM.BORDER_ROUTER_STATUS]: () => intl.formatMessage({ id: "gateways.errors.borderRouterStatus" }),
};

/* WARNINGS */
export const GATEWAYS_WARNINGS_TRANSLATION: StatusHandlers<GATEWAY_WARNINGS_ENUM> = {
  [GATEWAY_WARNINGS_ENUM.NOT_INSTALL]: () => intl.formatMessage({ id: "gateways.warnings.notInstall" }),
  [GATEWAY_WARNINGS_ENUM.DISCONNECTED_WARNING]: (g: GatewaysMessageGatewaysInner, showDate: boolean) =>
    intl.formatMessage(
      { id: "gateways.warnings.disconnected" },
      { s: showDate, d: g.lastGatewayActivity ? formatedDate(FORMAT_DATE.boxProbleme, g.lastGatewayActivity) : "-" },
    ),
};

/**
 * Retrieves the translated problem messages for a given gateway.
 * @param gateway - The gateway object containing errors and warnings.
 * @returns An object containing the translated error and warning messages.
 */
export const getGatewayProblemTranslation = (gateway: GatewaysWithProblem) => {
  const errors = gateway.errors.map(error => GATEWAYS_ERRORS_TRANSLATION[error](gateway, true));
  const warnings = gateway.warnings.map(warning => GATEWAYS_WARNINGS_TRANSLATION[warning](gateway, true));
  return { errors, warnings };
};
