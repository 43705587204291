import { forwardRef } from "react";

import clsx from "clsx";

import { Circle } from "@eisox/design-system";
import { ValveIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./Valve.module.scss";

interface ValveProps extends React.ComponentPropsWithRef<"div"> {
  nbValve: number;
  className?: string;
}

export const Valve = forwardRef<HTMLDivElement, ValveProps>(({ nbValve, className, ...props }, forwardedRef) => {
  const bem = useBem(styles);
  const valveStyle = bem("valve");

  return (
    <div {...props} ref={forwardedRef} className={clsx(valveStyle(), className)}>
      <p className={valveStyle("number")}>{`(${nbValve})`}</p>
      <Circle className={valveStyle("circle")}>
        <ValveIcon className={valveStyle("icon")} />
      </Circle>
    </div>
  );
});
