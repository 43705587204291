import type { RouteObject } from "react-router-dom";

import type { QueryClient } from "@tanstack/react-query";

import { RedirectResources, boilerroomHistoryAction } from "~/UI";
import { action as histovalvesStatsAction } from "~/UI/screens/HistovalvesStats";
import { history, histovalvesStats } from "~/routes/routes";
import { routeToConsumption } from "~/routes/utils";

import { route as housesRoute } from "./houses/route";
import { route as houseRoute } from "./houses_.$houseId/route";
import { loader } from "./loader";

export const route = (queryClient: QueryClient): RouteObject => ({
  id: "authenticated",
  loader: loader(queryClient),
  // TODO : TO DELETE WHEN REPLACED BY MUTATION
  shouldRevalidate: ({ currentParams, formAction, defaultShouldRevalidate }) =>
    formAction === histovalvesStats ||
    formAction === `/history/houses/${currentParams.houseId}/modules` ||
    (currentParams.houseId && formAction?.split("?")[0] === routeToConsumption(currentParams.houseId))
      ? false
      : defaultShouldRevalidate,
  // ! TODO : TO DELETE WHEN REPLACED BY MUTATION
  children: [
    { ...housesRoute(queryClient) },
    { ...houseRoute(queryClient) },
    // TODO : TO DELETE WHEN REPLACED BY MUTATION
    {
      path: history,
      children: [
        {
          path: "/history/houses/:houseId/modules",
          action: boilerroomHistoryAction,
        },
      ],
    },
    {
      path: histovalvesStats,
      action: histovalvesStatsAction,
    },
    {
      path: "/resources/*",
      element: <RedirectResources />,
    },
    // ! TO DELETE WHEN REPLACED BY MUTATION
  ],
});
