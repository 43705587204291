import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import { Refresh } from "../Refresh";

import styles from "./Resume.module.scss";

interface ResumeProps {
  valves: number;
  valveErrors: number;
  gateways: number;
  gatewayErrors: number;
  updatedAt: string;
  onRefresh: () => void;
}

const Resume: React.FC<ResumeProps> = props => {
  const { gateways, gatewayErrors, valves, valveErrors, updatedAt, onRefresh } = props;
  return (
    <div className={styles.resume}>
      <Item item="gateway" total={gateways} errors={gatewayErrors} />
      <Item item="valve" total={valves} errors={valveErrors} />
      <Refresh updatedAt={updatedAt} onClick={onRefresh} />
    </div>
  );
};

interface ItemProps {
  item: "valve" | "gateway";
  total: number;
  errors: number;
}

const Item: React.FC<ItemProps> = ({ item, total, errors }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.item}>
      <span>{t(`maintenance.list.header.${item}`, { t: total })}</span>
      <span className={cx(styles.item__indicator, errors > 0 && styles.item__indicator_error)}>
        {errors > 0 && errors}
      </span>
    </div>
  );
};

export { Resume };
export type { ResumeProps };
