import React from "react";

import type { RouteObject } from "react-router-dom";
import { Navigate, useParams, useRouteLoaderData } from "react-router-dom";

import type { loader } from "~/UI/screens/House";
import { getBoilerRoomsFromModules } from "~/features/BoilerRooms";
import { ModeContextProvider } from "~/features/BoilerRooms/providers/ModeProvider";
import { BoilerRoomPage } from "~/pages";
import { boilerrooms, variableBoilerroomId } from "~/routes/routes";
import { idLoaderHouse, routeToBoilerroom } from "~/routes/utils";

import { route as networkRoute } from "./network.$networkId";

const Index: React.FC = () => {
  const { houseId, boilerroomId } = useParams() as { houseId: string; boilerroomId?: string };

  const { boilerroomPos } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof loader>>;

  if (!boilerroomId) {
    const boilerRooms = getBoilerRoomsFromModules(boilerroomPos);
    if (boilerRooms.length === 0) throw new Error("No boiler rooms found");

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return <Navigate to={routeToBoilerroom(houseId, boilerRooms[0].id!)} replace />;
  }

  return <ModeContextProvider />;
};

export const route: RouteObject = {
  path: boilerrooms,
  element: <Index />,
  children: [
    {
      path: variableBoilerroomId,
      element: <BoilerRoomPage />,
    },
    networkRoute,
  ],
};
