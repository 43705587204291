import * as yup from "yup";

import { validateValveMac } from "@eisox/valves";

import { intl } from "~/i18n";

export const schemaErrors = (existingSerialNumber: String[], macRequired: boolean = false) =>
  yup.object({
    mac: yup.string().when([], (_, schema) =>
      macRequired
        ? schema
            .test("macFormat", intl.formatMessage({ id: "plan.actions.add.valves.serialNumber.matches" }), value =>
              validateValveMac(value ?? ""),
            )
            .test(
              "isUnique",
              intl.formatMessage({ id: "plan.actions.add.valves.serialNumber.isUnique" }),
              value => !!value && !existingSerialNumber.includes(value),
            )
            .required(intl.formatMessage({ id: "error.required" }))
        : schema,
    ),
  });
