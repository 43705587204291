import { ActionFunctionArgs } from "react-router-dom";

import dayjs from "@eisox/dayjs";

import { postPlanningById, putPlanning } from "~/api/planning";
import { getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { API } from "~/types/API";

import { PlanningSettingDto } from "../Settings";

export const action = async ({ params, request }: ActionFunctionArgs) => {
  const { houseId } = params;
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.POST:
      const updates = JSON.parse(formData.get("json") as string);
      return postPlanningById(params.houseId, updates);
    case API.HTTP_METHOD.PUT:
      const plannings: PlanningSettingDto = JSON.parse(formData.get("json") as string);

      const patchResponse = (await putPlanning(houseId, plannings)) as any;
      const patchStatus = getHttpStatusByFetchStatus(patchResponse.type) as unknown as API.HTTP_STATUS;
      return {
        date: dayjs(),
        restError: patchResponse.type,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [{ status: patchStatus, message: patchResponse.result.message }],
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
