import { t } from "i18next";

import { z } from "@eisox/zod";

import type { RolesInHouses } from "~/apiV2";
import { UserRole } from "~/utils/User";

import { isEmailExistingInAnotherHouse, isEmailUnique } from "./users";

const addUserSchema = (existingEmails: string[], rolesInHouses: RolesInHouses) =>
  z
    .object({
      email: z
        .string()
        .email()
        .refine(email => isEmailUnique(existingEmails, email), t("users.add.email.alreadyExist")),
      confirmEmail: z.string().email(),
      role: z.nativeEnum(UserRole),
      houseIds: z.array(z.string()),
    })
    .superRefine((data, ctx) => {
      if (data.email !== data.confirmEmail) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("error.differentEmail"),
          path: ["confirmEmail"],
        });
      }
    })
    .superRefine((data, ctx) => {
      if (isEmailExistingInAnotherHouse(data.email, data.houseIds, rolesInHouses)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t("users.add.email.inAnotherHouse"),
          path: ["houseIds"],
        });
      }
    });

type AddUserSchemaType = z.infer<ReturnType<typeof addUserSchema>>;

const addRoleSchema = z.object({
  houseId: z.string(),
  role: z.nativeEnum(UserRole),
});

type AddRoleSchemaType = z.infer<typeof addRoleSchema>;

export { addRoleSchema, addUserSchema };
export type { AddRoleSchemaType, AddUserSchemaType };
