import dayjs from "@eisox/dayjs";
import { PlanningDay, PlanningPlanningType } from "@eisox/webapp-api-specification";
import { faker } from "@faker-js/faker";

import type { Planning } from "~/apiV2";

import { Builder } from "../utils";

export class PlanningBuilder extends Builder<Planning> {
  constructor() {
    const start = dayjs()
      .hour(faker.number.int({ min: 0, max: 23 }))
      .minute(faker.number.int({ min: 0, max: 59 }));

    const end = start.add(faker.number.int({ min: 1, max: 1440 }), "minute");

    super({
      id: faker.database.mongodbObjectId(),
      day: faker.helpers.enumValue(PlanningDay),
      hourEnd: end.format("HH:mm"),
      hourStart: start.format("HH:mm"),
      roomId: faker.database.mongodbObjectId(),
      userDefined: faker.datatype.boolean(),
      planningType: faker.helpers.enumValue(PlanningPlanningType),
    });
  }
}

export const plannings = [
  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.friday)
    .with("hourStart", "00:00")
    .with("hourEnd", "08:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.friday)
    .with("hourStart", "08:00")
    .with("hourEnd", "19:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.comfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.friday)
    .with("hourStart", "19:00")
    .with("hourEnd", "00:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.monday)
    .with("hourStart", "00:00")
    .with("hourEnd", "08:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.monday)
    .with("hourStart", "08:00")
    .with("hourEnd", "19:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.comfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.monday)
    .with("hourStart", "19:00")
    .with("hourEnd", "00:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.saturday)
    .with("hourStart", "00:00")
    .with("hourEnd", "00:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.absence)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.sunday)
    .with("hourStart", "00:00")
    .with("hourEnd", "00:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.absence)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.thursday)
    .with("hourStart", "00:00")
    .with("hourEnd", "08:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.thursday)
    .with("hourStart", "08:00")
    .with("hourEnd", "19:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.comfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.thursday)
    .with("hourStart", "19:00")
    .with("hourEnd", "00:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.tuesday)
    .with("hourStart", "00:00")
    .with("hourEnd", "08:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.tuesday)
    .with("hourStart", "08:00")
    .with("hourEnd", "19:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.comfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.tuesday)
    .with("hourStart", "19:00")
    .with("hourEnd", "00:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.wednesday)
    .with("hourStart", "00:00")
    .with("hourEnd", "08:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.wednesday)
    .with("hourStart", "08:00")
    .with("hourEnd", "19:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.comfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.wednesday)
    .with("hourStart", "19:00")
    .with("hourEnd", "00:00")
    .with("userDefined", false)
    .with("planningType", PlanningPlanningType.preComfort)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.monday)
    .with("hourStart", "01:00")
    .with("hourEnd", "07:00")
    .with("userDefined", true)
    .with("planningType", PlanningPlanningType.absence)
    .build(),

  new PlanningBuilder()
    .with("roomId", "64fb10e56deef7c2637b24ac")
    .with("day", PlanningDay.monday)
    .with("hourStart", "13:00")
    .with("hourEnd", "17:00")
    .with("userDefined", true)
    .with("planningType", PlanningPlanningType.absence)
    .build(),
];
