import { useMemo, useRef, useState } from "react";

import { groupBy } from "lodash";

import dayjs from "@eisox/dayjs";
import { SelectV2 as Select, Slider } from "@eisox/design-system";

import styles from "./HistorySlider.module.scss";

interface HistorySliderProps {
  sortedDates: string[];
  date?: string;
  onDateCommit?: (value: string) => void;
}

const HistorySliderV2: React.FC<HistorySliderProps> = ({ date: dateProp, onDateCommit, sortedDates }) => {
  const datesGroupedByDay = useMemo(() => groupBy(sortedDates, d => dayjs(d).format("DD/MM/YYYY")), [sortedDates]);
  const mappedToTimestamp = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(datesGroupedByDay).map(([day, dateList]) => [day, dateList.map(date => dayjs(date).valueOf())]),
      ),
    [datesGroupedByDay],
  );

  const dateOptions = useMemo(
    () =>
      Object.entries(mappedToTimestamp).map(([day, _dates]) => ({
        name: dayjs(day, "DD/MM/YYYY").format("ddd DD MMMM"),
        value: day,
      })),
    [datesGroupedByDay],
  );

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const [day, setDay] = useState(Object.keys(datesGroupedByDay)[0]);
  const [date, setDate] = useState(dateProp ?? dayjs(mappedToTimestamp[day][0]).toISOString());

  const handleDayChange = (value: string | string[]) => {
    if (typeof value !== "string") return;
    const dates = mappedToTimestamp[value];
    const closestTime = dayjs(findClosestTime(dates, date)).toISOString();
    setDay(value as string);
    setDate(closestTime);
    onDateCommit?.(closestTime);
  };

  const handleHourChange = (value?: number) => {
    if (!value) return;
    setDate(dayjs(value).toISOString());
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onDateCommit?.(dayjs(value).toISOString());
    }, 500);
  };

  const min = dayjs(day, "DD/MM/YYYY").startOf("day").valueOf();
  const max = dayjs(day, "DD/MM/YYYY")
    .set("hour", 23)
    .set("minute", 45)
    .set("second", 0)
    .set("millisecond", 0)
    .valueOf();

  return (
    <div className={styles.historySlider}>
      <Select
        classNames={{ trigger: styles.historySlider__select }}
        options={dateOptions}
        value={day}
        onChange={handleDayChange}
      />
      <p className={styles.historySlider__date}>{dayjs(date).format("HH:mm")}</p>
      <p className={styles.historySlider__legend}>00:00</p>
      <Slider
        className={styles.historySlider__slider}
        displayButtons={false}
        value={dayjs(date).valueOf()}
        onValueChange={handleHourChange}
        min={min}
        max={max}
        marks={mappedToTimestamp[day]}
        valueLabelFormat={value => dayjs(value).format("HH:mm")}
      />
      <p className={styles.historySlider__legend}>23:45</p>
    </div>
  );
};

const findClosestTime = (dates: number[], targetDate: string): number => {
  const targetTime = dayjs(targetDate).format("HH:mm");

  return dates.reduce((closest, current) => {
    const currentTime = dayjs(current).format("HH:mm");

    const diffCurrent = Math.abs(dayjs(`2000-01-01 ${currentTime}`).diff(dayjs(`2000-01-01 ${targetTime}`), "minute"));
    const diffClosest = Math.abs(
      dayjs(`2000-01-01 ${dayjs(closest).format("HH:mm")}`).diff(dayjs(`2000-01-01 ${targetTime}`), "minute"),
    );

    return diffCurrent < diffClosest ? current : closest;
  }, dates[0]);
};

export { HistorySliderV2 };
export type { HistorySliderProps };
