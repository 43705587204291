import { useEffect, useRef, useState } from "react";

import type { DefaultEventMap, Socket } from "socket.io-client";
import socketIOClient from "socket.io-client";

import { env } from "~/configuration";
import type { Event } from "~/socketio/requests";
import type { BoilerroomDataRes, RawDataResV2, ResetDataRes } from "~/socketio/types";

interface SocketIOProps {
  token: string;
  houseId: string;
  userId: string;
}

export interface GetDataBoilerRoomPayload {
  type: "getDataBoilerRoomInit" | "getDataBoilerRoom";
}

export interface GetDataHnInitPayload {
  type: "hnInit";
  heatingNetworkId: string;
}

type GetDataPayload = GetDataBoilerRoomPayload | GetDataHnInitPayload;
export interface EmitEvents {
  [Event.GET_DATA]: (payload: GetDataPayload) => void;
  [Event.GET_RAW_DATA_V2]: () => void;
  [Event.RESET_DATA]: (modules: string[]) => void;
  [Event.UPDATE_DATA]: (updates: BoilerroomDataRes) => void;
}

export interface ListenEvents extends DefaultEventMap {
  GET_DATA_RES: (data: BoilerroomDataRes) => void;
  GET_RAW_DATA_V2_RES: (data: RawDataResV2) => void;
  UPDATE_DATA_RES: (data: BoilerroomDataRes) => void;
  RESET_DATA_RES: (data: ResetDataRes) => void;
}

const useSocketIO = ({ token, houseId, userId }: SocketIOProps) => {
  const socketRef = useRef<Socket<ListenEvents, EmitEvents> | null>(null);

  const [isConnected, setIsConnected] = useState(false);
  const [connectionError, setConnectionError] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const socket = socketIOClient<ListenEvents, EmitEvents>(
      `${env.WEBSOCKET_URL}/${houseId}/module/eclypseBoilerRoom`,
      {
        query: {
          token: token,
          userId: userId,
        },
        transports: ["websocket"],
      },
    );
    socketRef.current = socket;

    const onConnect = () => {
      // Force getDataBoilerRoomInit after 100ms to be sure that socket io connection is ok
      timeoutId = setTimeout(() => setIsConnected(true), 100);
    };

    const onConnectionError = () => setConnectionError(true);

    socket.on("connect", onConnect);
    socket.on("connect_error", onConnectionError);

    return () => {
      socket.off("connect", onConnect);
      socket.off("connect_error", onConnectionError);

      clearTimeout(timeoutId);
      socket.disconnect();
      socketRef.current = null;
    };
  }, [token, houseId, userId]);

  return {
    socket: socketRef.current,
    isConnected,
    connectionError,
  };
};

export { useSocketIO };
