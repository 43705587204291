import { maintenance } from "~/routes/routes";

import Layout from "./_layout";
import AdvancedMaintenance from "./advanced";
import Maintenance from "./index";

export const route = {
  path: maintenance,
  element: <Layout />,
  children: [
    {
      index: true,
      element: <Maintenance />,
    },
    { path: "advanced", element: <AdvancedMaintenance /> },
  ],
};
