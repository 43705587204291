import { Circle, PlanV2 as Plan } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { useInterventionPlanTool } from "~/features/InterventionPlanTool/providers";

import styles from "./Label.module.scss";

interface LabelProps {
  type: "gateways" | "valves";
  isSolved: boolean;
  messages: string[];
  id: string;
  x: number;
  y: number;
  index: number;
}

const Label: React.FC<LabelProps> = ({ type, id, x, y, isSolved, messages, index }) => {
  const bem = useBem(styles);
  const labelStyle = bem("label");

  const { setState } = useInterventionPlanTool();

  const updateNotes = <T extends "gateways" | "valves">(type: T, id: string, x: number, y: number) => {
    setState(draft => {
      draft[type][index] = {
        ...draft[type][index],
        notes: draft[type][index].notes.map(note => (note.id === id ? { ...note, x, y } : note)),
      };
    });
  };

  return (
    <Plan.Item position={{ x, y }} onPositionEnd={({ x, y }) => updateNotes(type, id, x, y)} draggable>
      <div className={labelStyle()}>
        {isSolved && <Circle size={10} className={labelStyle("solved")} />}
        {messages.join(", ")}
      </div>
    </Plan.Item>
  );
};

export { Label };
