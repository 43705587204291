export const calculateClickPercentage = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
  const image = e.currentTarget;
  const rect = image.getBoundingClientRect();

  // Click coordinates relative to image
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;

  // Image size
  const width = rect.width;
  const height = rect.height;

  // Calculating percentages
  const xPercentage = (x / width) * 100;
  const yPercentage = (y / height) * 100;

  return { x: xPercentage, y: yPercentage };
};
