export function checkZoomBounds(
  zoom: number,
  minScale: number,
  maxScale: number,
  zoomPadding: number,
  enablePadding: boolean,
): number {
  const scalePadding = enablePadding ? zoomPadding : 0;
  const minScaleWithPadding = minScale - scalePadding;

  if (!Number.isNaN(maxScale) && zoom >= maxScale) return maxScale;
  if (!Number.isNaN(minScale) && zoom > minScaleWithPadding) return zoom - minScaleWithPadding;
  if (!Number.isNaN(minScale) && zoom <= minScaleWithPadding) return minScaleWithPadding;
  return zoom;
}
