import { useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ActionButtonV2 as ActionButton, Modal } from "@eisox/design-system";
import { ValveIcon } from "@eisox/icons";
import { useMutation } from "@tanstack/react-query";

import { updateGateways, updateValves } from "~/apiV2";

import type { PageProps } from "../Page";
import { Page } from "../Page";

import styles from "./PreReplaceDialog.module.scss";

interface PreReplaceDialogProps
  extends Modal.RootProps,
    Omit<PageProps, "preReplace" | "selectedItems" | "onSelectionChange" | "onDisplayedItemsChange"> {
  onPreReplace: VoidFunction;
}

const PreReplaceDialog: React.FC<PreReplaceDialogProps> = ({
  open,
  onOpenChange,
  house,
  gateways,
  valves,
  rooms,
  udpatedAt: updatedAtProp,
  onRefresh,
  onClickOnPlan,
  role,
  onClickOnRoom,
  onPreReplace,
}) => {
  const { t } = useTranslation();

  const [displayedItems, setDisplayedItems] = useState<"gateways" | "valves">("valves");
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const body = selectedItems.map(i => ({ id: i, isToPreReplace: true }));

  const mutationFn = {
    gateways: () => updateGateways(house._id!, { gateways: body }),
    valves: () => updateValves(house._id!, { valves: body }),
  }[displayedItems];

  const { mutate: preReplaceItem } = useMutation({
    mutationFn: mutationFn,
    onSuccess: () => {
      onPreReplace();
      onRefresh();
    },
    onError: () => toast.error(t("error.unKnowError.message")),
  });

  const handleClickButton = () => preReplaceItem();

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Content className={styles.preReplaceDialog}>
        <Modal.Header
          title={t("advancedMaintenance.preReplaceDialog.title")}
          subtitle={t("advancedMaintenance.preReplaceDialog.subtitle")}
          icon={<ValveIcon />}
        >
          <Modal.Close>
            <ActionButton variant="cancel">{t("advancedMaintenance.preReplaceDialog.cancel")}</ActionButton>
          </Modal.Close>
          <ActionButton onClick={handleClickButton} disabled={selectedItems.length === 0}>
            {t("advancedMaintenance.preReplaceDialog.save", { n: selectedItems.length })}
          </ActionButton>
        </Modal.Header>
        <Page
          className={styles.preReplaceDialog__content}
          role={role}
          house={house}
          valves={valves}
          gateways={gateways}
          rooms={rooms}
          udpatedAt={updatedAtProp}
          onRefresh={onRefresh}
          onClickOnPlan={onClickOnPlan}
          onClickOnRoom={onClickOnRoom}
          preReplace={true}
          selectedItems={selectedItems}
          onSelectionChange={setSelectedItems}
          onDisplayedItemsChange={setDisplayedItems}
        />
      </Modal.Content>
    </Modal.Root>
  );
};

export { PreReplaceDialog };
export type { PreReplaceDialogProps };
