import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";
import type { PlanningsStat } from "../types";

const getPlannings = async (houseId: string, roomId: string) => {
  const { data, error } = await client.GET("/houses/{houseId}/plannings/rooms/{roomId}", {
    params: { path: { houseId, roomId } },
  });
  if (error) throw new Error(error.message ?? "Error fetching plannings");
  return data?.message ?? { plannings: [] };
};

const getPlanningsStat = async (houseId: string): Promise<PlanningsStat> => {
  const { data, error } = await client.GET("/houses/{houseId}/planningsStat", {
    params: { path: { houseId } },
  });
  if (error) throw new Error(error);
  return data?.message ?? ([] as PlanningsStat);
};

export const planning = createQueryKeys("planning", {
  room: (houseId: string, roomId: string) => ({
    queryKey: [houseId, roomId],
    queryFn: () => getPlannings(houseId, roomId),
  }),
  stat: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getPlanningsStat(houseId),
  }),
});
