import { useState } from "react";

import { StepOne } from "../StepOne";
import { StepTwo } from "../StepTwo";

export const AddHouse: React.FC = () => {
  const [houseId, setHouseId] = useState<string>();

  return houseId ? (
    <StepTwo houseId={houseId} />
  ) : (
    <StepOne
      nextStep={(houseId: string) => {
        setHouseId(houseId);
      }}
    />
  );
};
