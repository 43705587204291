import { useEffect, useMemo, useRef, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import type { UnitTypeShort } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import { ActionButtonV2, DatePicker, Modal, TextField, Typography } from "@eisox/design-system";
import { ArrowRightIcon, ChevronDownIcon, ChevronUpIcon, CrossIcon, PointChartIcon } from "@eisox/icons";
import { PostManualBalancingHistorySort } from "@eisox/webapp-api-specification";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import type { ColumnDef, ColumnSort, OnChangeFn, SortingState } from "@tanstack/react-table";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import { FieldContainer } from "~/UI";
import type { HslopeHistory } from "~/apiV2";
import { queries } from "~/apiV2";
import { HISTORY } from "~/constants/appConstantV2";

import styles from "./HslopeHistoryPopup.module.scss";

interface HslopeHistoryPopupProps {
  mac: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const schema = z.object({
  dateRange: z.object({
    from: z.date(),
    to: z.date(),
  }),
});

type FormValues = z.infer<typeof schema>;

const HslopeHistoryPopup: React.FC<HslopeHistoryPopupProps> = ({ mac, open, onOpenChange }) => {
  const { t } = useTranslation();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [range, setRange] = useState<FormValues["dateRange"]>({
    from: dayjs().subtract(2, "years").toDate(),
    to: dayjs().toDate(),
  });
  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: PostManualBalancingHistorySort.createdAt, desc: true }]);
  const sort = sorting.map(sort => sort.id as PostManualBalancingHistorySort);
  const desc = sorting.filter(sort => sort.desc).map(sort => sort.id as PostManualBalancingHistorySort);

  const { handleSubmit, control } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      dateRange: {
        from: dayjs()
          .subtract(parseInt(HISTORY.HSLOPE.initial[0]), HISTORY.HSLOPE.initial[1] as UnitTypeShort)
          .toDate(),
        to: dayjs().toDate(),
      },
    },
  });

  // @ts-expect-error : type error because of initialPageParam of type never instead of number
  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isError } = useInfiniteQuery({
    ...queries.history.hslope(range.from.toISOString(), range.to.toISOString(), mac, sort, desc),
    initialPageParam: 1,
    getNextPageParam: lastPage => lastPage.meta.nextPage,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    retry: false,
    select: data => ({
      ...data,
      pages: data.pages.map(p => ({
        ...p,
        data: p.data.map(d => ({
          ...d,
          hslope: d.hslopeMinC,
        })),
      })),
    }),
  });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const flatData = useMemo(() => data?.pages.flatMap(page => page.data) ?? [], [data]);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const totalDBRowCount = data?.pages?.[0]?.meta?.total ?? flatData.length;

  const rowVirtualizer = useVirtualizer({
    count: totalDBRowCount,
    estimateSize: () => 21,
    getScrollElement: () => tableContainerRef.current,
    overscan: 5,
  });

  const columns = useMemo<ColumnDef<HslopeHistory>[]>(
    () => [
      {
        accessorKey: "createdAt",
        header: t("drawer.listDrawer.historyPopup.hslope.table.date"),
        cell: info => dayjs(info.getValue() as string).format("L LTS"),
      },
      {
        accessorKey: "maxOpening",
        header: t("drawer.listDrawer.historyPopup.hslope.table.maxOpening"),
      },
      {
        accessorKey: "hslope",
        header: t("drawer.listDrawer.historyPopup.hslope.table.hslope"),
      },
    ],
    [],
  );

  const table = useReactTable({
    data: flatData,
    columns: columns,
    state: { sorting },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    onSortingChange: setSorting,
    enableSortingRemoval: false,
  });

  //scroll to top of table when sorting changes
  const handleSortingChange: OnChangeFn<SortingState> = updater => {
    setSorting(updater);
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex(0);
    }
  };

  const onSubmit = (data: FormValues) => {
    setRange(data.dateRange);
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex(0);
    }
  };

  //since this table option is derived from table row model state, we're using the table.setOptions utility
  table.setOptions(prev => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }));

  useEffect(() => {
    if (isError) toast.error(t("error.unKnowError.message"));
  }, [isError, t]);

  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();
    if (!lastItem) return;
    if (lastItem.index >= flatData.length - 1 && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, fetchNextPage, flatData.length, isFetchingNextPage, rowVirtualizer.getVirtualItems()]);

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Content>
        <Modal.Header title={t("drawer.listDrawer.historyPopup.hslope.title")} subtitle={mac} icon={<PointChartIcon />}>
          <Modal.Close asChild>
            <CrossIcon className={styles.close} />
          </Modal.Close>
        </Modal.Header>

        <div className={styles.form}>
          <Controller
            name="dateRange"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DatePicker.Root
                mode="range"
                format="DD/MM/YYYY"
                value={value}
                onChange={onChange}
                captionLayout="dropdown"
                disabled={{
                  before: dayjs()
                    .subtract(parseInt(HISTORY.HSLOPE.limit[0]), HISTORY.HSLOPE.limit[1] as UnitTypeShort)
                    .toDate(),
                  after: dayjs().toDate(),
                }}
              >
                <DatePicker.Trigger asChild>
                  {value => (
                    <FieldContainer label="Date">
                      <TextField.Root value={value} />
                    </FieldContainer>
                  )}
                </DatePicker.Trigger>
                <DatePicker.Content asChild style={{ zIndex: 9999 }}>
                  <DatePicker.Calendar />
                </DatePicker.Content>
              </DatePicker.Root>
            )}
          />
          <ActionButtonV2 type="submit" onClick={() => handleSubmit(onSubmit)()}>
            <ArrowRightIcon />
          </ActionButtonV2>
        </div>
        <div className={styles.table}>
          {table.getHeaderGroups().map(headerGroup => (
            <div key={headerGroup.id} className={styles.header}>
              {headerGroup.headers.map(header => (
                <div key={header.id} className={styles.header__cell} onClick={header.column.getToggleSortingHandler()}>
                  <Typography>{flexRender(header.column.columnDef.header, header.getContext())}</Typography>
                  {{
                    asc: <ChevronDownIcon className={styles.header__arrow} />,
                    desc: <ChevronUpIcon className={styles.header__arrow} />,
                  }[header.column.getIsSorted() as string] ?? null}
                </div>
              ))}
            </div>
          ))}
          <div
            ref={tableContainerRef}
            style={{
              height: `200px`,
              width: `100%`,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div
              style={{
                height: `${rowVirtualizer.getTotalSize()}px`,
                width: "100%",
                position: "relative",
              }}
            >
              {rowVirtualizer.getVirtualItems().map(virtualRow => {
                const isLoaderRow = virtualRow.index > flatData.length - 1;
                const row = table.getRowModel().rows[virtualRow.index];
                return (
                  <div
                    key={virtualRow.index}
                    className={styles.body__row}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: `${virtualRow.size}px`,
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    {isLoaderRow ? (
                      hasNextPage ? (
                        <Typography>{t("drawer.listDrawer.historyPopup.hslope.table.loadingMore")}</Typography>
                      ) : (
                        <Typography>{t("drawer.listDrawer.historyPopup.hslope.table.nothingMoreToLoad")}</Typography>
                      )
                    ) : (
                      row
                        .getVisibleCells()
                        .map(cell => (
                          <Typography key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </Typography>
                        ))
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};

export { HslopeHistoryPopup };
export type { HslopeHistoryPopupProps };
