import { fetchToCurl } from "fetch-to-curl";
import { toast } from "react-toastify";

import { env } from "~/configuration/envConfig";
import {
  BAD_REQUEST,
  FETCH_ERROR,
  FETCH_TIMOUT,
  FORBIDDEN,
  HTTPS_STATUS_CODES,
  INTERNAL_ERROR,
  MULTIPLE_RESPONSES,
  NOT_FOUND,
  USER_NOT_LOGGED_FETCH,
  getHttpStatusByFetchStatus,
} from "~/constants/fetchConstants";
import { intl } from "~/i18n";

import styles from "./FecthApi.module.scss";

const TIMEOUT_FETCH = 10000;

const UNAUTHORIZED_ROLE = "unauthorized role";
const EMPTY_BOILER = "empty boiler";

const ERROR_MESSAGE = {
  socketError: {
    [UNAUTHORIZED_ROLE]: {
      title: "error.socketError.roomError.unauthorizedRole.title",
      message: "error.socketError.roomError.unauthorizedRole.message",
    },

    [EMPTY_BOILER]: {
      title: "error.socketError.emptyBoiler.title",
      message: "error.socketError.emptyBoiler.message",
    },
  },
  serverError: {
    [FETCH_ERROR]: {
      title: "error.errorCom",
      message: "error.serverError.FetchError.message",
    },
    [MULTIPLE_RESPONSES]: {
      title: "error.errorCom",
      message: "error.serverError.MultipleResponses.message",
    },
    [BAD_REQUEST]: {
      title: "error.errorCom",
      message: "error.serverError.BadRequest.message",
    },
    [FORBIDDEN]: {
      title: "error.errorCom",
      message: "error.serverError.Forbidden.message",
    },
    [NOT_FOUND]: {
      title: "error.errorCom",
      message: "error.serverError.NotFound.message",
    },
    [INTERNAL_ERROR]: {
      title: "error.errorCom",
      message: "error.serverError.InternalServerError.message",
    },
    [FETCH_TIMOUT]: {
      title: "error.errorCom",
      message: "error.serverError.Timeout.message",
    },
  },
  unKnowError: {
    title: "error.unKnowError.title",
    message: "error.unKnowError.message",
  },
};

export async function internalFetchByUrl(url, method, headers, body) {
  const StandardHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  const isForUpload = body instanceof FormData;
  const requestHeaders = isForUpload ? { ...headers } : { ...StandardHeaders, ...headers };
  const Body = body !== undefined && !isForUpload ? JSON.stringify(body) : body;

  const timeoutPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      const returnedObject = {
        type: FETCH_TIMOUT,
        isAuthorized: true,
        result: "timeout",
      };
      resolve(returnedObject);
    }, TIMEOUT_FETCH);
  });

  const requestPromise = new Promise((dispatch, reject) => {
    fetch(url, {
      method: method,
      headers: requestHeaders,
      body: Body,
    })
      .then(async response => {
        const contentType = response.headers.get("Content-Type");
        const isImg = contentType === "image/png";
        let Result = null;

        // upgrade test : const Result = isImg ? URL.createObjectURL(await response.blob()) : await response.json();
        if (isImg) {
          const blob = await response.blob();
          Result = URL.createObjectURL(blob);
        } else {
          const res = await response.json();
          Result = res;
        }

        const status = response.status;
        const type = HTTPS_STATUS_CODES[status];

        return {
          type,
          result: Result,
          isAuthorized: type !== USER_NOT_LOGGED_FETCH,
        };
      })
      .catch(error => {
        return {
          type: HTTPS_STATUS_CODES.error,
          result: error.message,
          isAuthorized: true,
        };
      })
      .then(function (response) {
        dispatch(response);
      });
  });

  const resultPromise = Promise.race([timeoutPromise, requestPromise]);

  resultPromise.then(response => {
    if (response.type === FETCH_TIMOUT || response.type === INTERNAL_ERROR || response.type === FETCH_ERROR) {
      const emailBody = `REQUEST
${fetchToCurl(url, { method: method, headers: requestHeaders, body: Body })}

RESPONSE
status: ${getHttpStatusByFetchStatus(response.type)}
body: ${JSON.stringify(response.result)}`;
      toast(
        <span>
          {intl.formatMessage({ id: ERROR_MESSAGE.serverError[response.type].message })}{" "}
          <a
            className={styles.toast__link}
            href={`mailto:${env.SUPPORT_MAIL}?subject=${intl.formatMessage({
              id: "errorPopup.mail.subject",
            })}&body=${encodeURIComponent(emailBody)}`}
          >
            {intl.formatMessage({ id: "errorPopup.sendDetails" }, { a: env.SUPPORT_MAIL ?? "" })}
          </a>
        </span>,
        {
          autoClose: false,
          type: "error",
        },
      );
    }
  });

  return resultPromise;
}
