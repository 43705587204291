import { forwardRef } from "react";

import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { $enum } from "ts-enum-util";

import { RoundIcon } from "@eisox/design-system";
import { ValveIcon } from "@eisox/icons";

import { ValveProblemType } from "~/features/InterventionPlanTool";
import { upperCaseToCamelCase } from "~/utils/stringUtils";

import styles from "./Valve.module.scss";

const valveVariants = cva(styles.valve, {
  variants: {
    resolution: $enum(ValveProblemType)
      .getKeys()
      .map(upperCaseToCamelCase)
      .reduce(
        (acc, key) => {
          acc[key] = styles[`valve_${key}`];
          return acc;
        },
        {} as Record<string, string>,
      ),
    unclogging: {
      true: styles.valve_unclogging,
    },
  },
});

interface ValveProps extends Required<VariantProps<typeof valveVariants>> {
  size?: number;
}

const Valve = forwardRef<HTMLDivElement, ValveProps>(({ resolution, unclogging = false, size = 20 }, forwardedRed) => (
  <RoundIcon
    ref={forwardedRed}
    size={size}
    className={valveVariants({ resolution: resolution && upperCaseToCamelCase(resolution), unclogging })}
  >
    <ValveIcon />
  </RoundIcon>
));

export { Valve };
export type { ValveProps };
