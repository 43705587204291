import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect, useState } from "react";

import type { Plan } from "~/apiV2";

import { getItemsWithProblem } from "../../helpers";
import type { ItemProps } from "../../types";
import { Group } from "../Group";
import { Item } from "../Item";

import styles from "./List.module.scss";

interface ListProps {
  items: Record<string, ItemProps<"valve" | "gateway">[]>;
  plans: Plan[];
  selectedItem?: string;
  setSelectedItem: Dispatch<SetStateAction<string | undefined>>;
  onItemClick: (item: ItemProps<"valve" | "gateway">) => void;
}

const List: React.FC<ListProps> = ({ items, plans, selectedItem, setSelectedItem, onItemClick }) => {
  const [open, setOpen] = useState<Set<string>>(new Set([...plans.map(p => p.id)]));
  const [selectedItemForFlash, setSelectedItemForFlash] = useState<string | undefined>(undefined);

  const allItems = Object.values(items).flat();

  // we only display items with problem(s) in list
  const itemsWithProblem = getItemsWithProblem(items);

  const handleOpenChange = useCallback(
    (planId: string) => {
      const newOpen = new Set(open);
      if (newOpen.has(planId)) {
        newOpen.delete(planId);
      } else {
        newOpen.add(planId);
      }
      setOpen(newOpen);
    },
    [open],
  );

  const itemRefCallback = (node: HTMLDivElement | null, id: string) => {
    if (node && selectedItem === id) {
      setTimeout(() => {
        node.scrollIntoView({ behavior: "smooth", block: "center" });
        setSelectedItemForFlash(id);
      }, 300);
      setSelectedItem(undefined);
    }
  };

  useEffect(() => {
    const selectedItemInList = allItems.find(i => i.id === selectedItem);
    const selectedItemPlanId = selectedItemInList?.plan.id;
    if (selectedItemPlanId && !!selectedItemInList.resolution) {
      if (!open.has(selectedItemPlanId)) {
        handleOpenChange(selectedItemPlanId);
      }
    }
  }, [allItems, handleOpenChange, open, selectedItem]);

  return (
    <div className={styles.content}>
      {Object.entries(itemsWithProblem).map(([planId, items], index) => {
        const planName = plans.find(p => p.id === planId)?.name;
        if (!planName) return null;
        const errors = items.reduce((acc, item) => (item.errors.length > 0 ? acc + 1 : acc), 0);
        const gateways = items.filter(item => item.type === "gateway");
        const valves = items.filter(item => item.type === "valve");
        return (
          <Group.Root key={`list-${index}`} open={open.has(planId)} onOpenChange={() => handleOpenChange(planId)}>
            <Group.Trigger className={styles.list__group} name={planName} error={errors} />
            <Group.Content className={styles.list__items}>
              {gateways.map(g => (
                <Item
                  {...g}
                  ref={ref => itemRefCallback(ref, g.id)}
                  key={`list-item-gateway-${g.uid}`}
                  selected={g.id === selectedItemForFlash}
                  onClick={() => onItemClick(g)}
                  onAnimationEnd={() => setSelectedItemForFlash(undefined)}
                />
              ))}
              {valves.map(v => (
                <Item
                  {...v}
                  ref={ref => itemRefCallback(ref, v.id)}
                  key={`list-item-valve-${v.uid}`}
                  selected={v.id === selectedItemForFlash}
                  onClick={() => onItemClick(v)}
                  onAnimationEnd={() => setSelectedItemForFlash(undefined)}
                />
              ))}
            </Group.Content>
          </Group.Root>
        );
      })}
    </div>
  );
};

List.displayName = "Maintenance.List";

export { List };
export type { ListProps };
