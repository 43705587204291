import { ActionFunctionArgs } from "react-router-dom";

import { PatchGatewaysGatewaysInner, PostGatewaysGatewaysInner } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { addGateways, editGateways } from "~/api/gateway";
import { getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { API } from "~/types/API";

export const action = async ({
  params,
  request,
}: ActionFunctionArgs): Promise<API.Response<string | null> | Response> => {
  const { houseId } = params;
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.POST:
      const gateways: PostGatewaysGatewaysInner[] = JSON.parse(formData.get("json") as string);
      const postResponse = (await addGateways(gateways, houseId)) as any;
      const postStatus = getHttpStatusByFetchStatus(postResponse.type) as unknown as API.HTTP_STATUS;
      return {
        date: dayjs(),
        restError: postResponse.type,
        url: request.url,
        method: API.HTTP_METHOD.POST,
        body: formData.get("json") as string,
        message: postResponse.result.message.map((m: any) => ({ status: postStatus, message: { ...m } })),
      };
    case API.HTTP_METHOD.PATCH:
      const updates: PatchGatewaysGatewaysInner[] = JSON.parse(formData.get("json") as string);
      const patchResponse = (await editGateways(updates, houseId)) as any;
      return {
        date: dayjs(),
        restError: patchResponse.type,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [...patchResponse.result.message],
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
