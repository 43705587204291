import type { ActionFunctionArgs } from "react-router-dom";

import dayjs from "@eisox/dayjs";

import type { BoilerRoomHistoryBody } from "~/api/boilerRoomHistory";
import { getBoilerRoomHistory } from "~/api/boilerRoomHistory";
import type { RestError } from "~/constants";
import { getHttpStatusByFetchStatus } from "~/constants";
import type { BoilerRoom } from "~/socketio/types";
import { API } from "~/types/API";

export interface BoilerroomHistoryResponseType {
  message: {
    moduleIds?: string[];
    boilerRooms?: BoilerRoom[];
    createdAt?: string;
  }[];
}

export const action = async ({
  request,
  params,
}: ActionFunctionArgs): Promise<API.Response<BoilerroomHistoryResponseType | null>> => {
  const { houseId } = params as { houseId: string };
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.POST:
      const body: BoilerRoomHistoryBody = JSON.parse(formData.get("json") as string);
      const postResponse = await getBoilerRoomHistory(houseId, body);
      const postStatus = getHttpStatusByFetchStatus(postResponse.type) as unknown as API.HTTP_STATUS;
      return {
        date: dayjs(),
        restError: postResponse.type as RestError,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [{ status: postStatus, message: postResponse.result }],
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
