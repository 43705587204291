import * as React from "react";

import { IconProps } from "./types";

export const BuildingIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M5.29167 4.47917C5.29167 4.69466 5.20607 4.90132 5.0537 5.0537C4.90132 5.20607 4.69466 5.29167 4.47917 5.29167C4.26368 5.29167 4.05702 5.20607 3.90465 5.0537C3.75227 4.90132 3.66667 4.69466 3.66667 4.47917C3.66667 4.26368 3.75227 4.05702 3.90465 3.90465C4.05702 3.75227 4.26368 3.66667 4.47917 3.66667C4.69466 3.66667 4.90132 3.75227 5.0537 3.90465C5.20607 4.05702 5.29167 4.26368 5.29167 4.47917Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M4.47917 8.54167C4.58587 8.54167 4.69153 8.52066 4.7901 8.47982C4.88868 8.43899 4.97825 8.37914 5.0537 8.3037C5.12914 8.22825 5.18899 8.13868 5.22982 8.0401C5.27066 7.94153 5.29167 7.83587 5.29167 7.72917C5.29167 7.62247 5.27066 7.51682 5.22982 7.41824C5.18899 7.31966 5.12914 7.2301 5.0537 7.15465C4.97825 7.0792 4.88868 7.01935 4.7901 6.97852C4.69153 6.93769 4.58587 6.91667 4.47917 6.91667C4.26368 6.91667 4.05702 7.00227 3.90465 7.15465C3.75227 7.30702 3.66667 7.51368 3.66667 7.72917C3.66667 7.94466 3.75227 8.15132 3.90465 8.3037C4.05702 8.45607 4.26368 8.54167 4.47917 8.54167V8.54167Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M7.72917 5.29167C7.94466 5.29167 8.15132 5.20607 8.3037 5.0537C8.45607 4.90132 8.54167 4.69466 8.54167 4.47917C8.54167 4.26368 8.45607 4.05702 8.3037 3.90465C8.15132 3.75227 7.94466 3.66667 7.72917 3.66667C7.51368 3.66667 7.30702 3.75227 7.15465 3.90465C7.00227 4.05702 6.91667 4.26368 6.91667 4.47917C6.91667 4.69466 7.00227 4.90132 7.15465 5.0537C7.30702 5.20607 7.51368 5.29167 7.72917 5.29167Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M8.54167 7.72917C8.54167 7.94466 8.45607 8.15132 8.3037 8.3037C8.15132 8.45607 7.94466 8.54167 7.72917 8.54167C7.51368 8.54167 7.30702 8.45607 7.15465 8.3037C7.00227 8.15132 6.91667 7.94466 6.91667 7.72917C6.91667 7.51368 7.00227 7.30702 7.15465 7.15465C7.30702 7.00227 7.51368 6.91667 7.72917 6.91667C7.94466 6.91667 8.15132 7.00227 8.3037 7.15465C8.45607 7.30702 8.54167 7.51368 8.54167 7.72917Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M10.9792 8.54167C11.0859 8.54167 11.1915 8.52066 11.2901 8.47982C11.3887 8.43899 11.4782 8.37914 11.5537 8.3037C11.6291 8.22825 11.689 8.13868 11.7298 8.0401C11.7707 7.94153 11.7917 7.83587 11.7917 7.72917C11.7917 7.62247 11.7707 7.51682 11.7298 7.41824C11.689 7.31966 11.6291 7.2301 11.5537 7.15465C11.4782 7.0792 11.3887 7.01935 11.2901 6.97852C11.1915 6.93769 11.0859 6.91667 10.9792 6.91667C10.7637 6.91667 10.557 7.00227 10.4046 7.15465C10.2523 7.30702 10.1667 7.51368 10.1667 7.72917C10.1667 7.94466 10.2523 8.15132 10.4046 8.3037C10.557 8.45607 10.7637 8.54167 10.9792 8.54167V8.54167Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M2.04167 14.5C1.89801 14.5 1.76023 14.4429 1.65865 14.3414C1.55707 14.2398 1.5 14.102 1.5 13.9583V3.125C1.5 2.69402 1.6712 2.2807 1.97595 1.97595C2.2807 1.6712 2.69402 1.5 3.125 1.5H9.625C10.056 1.5 10.4693 1.6712 10.774 1.97595C11.0788 2.2807 11.25 2.69402 11.25 3.125V4.75H12.875C13.306 4.75 13.7193 4.9212 14.024 5.22595C14.3288 5.5307 14.5 5.94402 14.5 6.375V13.9583C14.5 14.102 14.4429 14.2398 14.3414 14.3414C14.2398 14.4429 14.102 14.5 13.9583 14.5H2.04167ZM2.58333 3.125V13.4167H4.75V10.7083C4.75 10.5647 4.80707 10.4269 4.90865 10.3253C5.01023 10.2237 5.14801 10.1667 5.29167 10.1667H10.7083C10.852 10.1667 10.9898 10.2237 11.0914 10.3253C11.1929 10.4269 11.25 10.5647 11.25 10.7083V13.4167H13.4167V6.375C13.4167 6.23134 13.3596 6.09357 13.258 5.99198C13.1564 5.8904 13.0187 5.83333 12.875 5.83333H10.7083C10.5647 5.83333 10.4269 5.77627 10.3253 5.67468C10.2237 5.5731 10.1667 5.43533 10.1667 5.29167V3.125C10.1667 2.98134 10.1096 2.84357 10.008 2.74198C9.90643 2.6404 9.76866 2.58333 9.625 2.58333H3.125C2.98134 2.58333 2.84357 2.6404 2.74198 2.74198C2.6404 2.84357 2.58333 2.98134 2.58333 3.125ZM10.1667 11.25H8.54167V13.4167H10.1667V11.25ZM7.45833 11.25H5.83333V13.4167H7.45833V11.25Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default BuildingIcon;
