import type { RouteObject } from "react-router-dom";

import { enrollAccount } from "~/routes/routes";

import { EnrollAccount } from ".";

const route: RouteObject = {
  path: enrollAccount,
  element: <EnrollAccount />,
};

export { route };
