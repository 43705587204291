import * as yup from "yup";

import dayjs from "@eisox/dayjs";

import { intl } from "~/i18n";

export type SchemaType = yup.InferType<typeof schema>;

export const equipmentSchema = yup.object().shape({
  id: yup.string().required(intl.formatMessage({ id: "error.required" })),
  name: yup.string().required(intl.formatMessage({ id: "error.required" })),
  min: yup
    .number()
    .required(intl.formatMessage({ id: "error.required" }))
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .test(
      "is-less-than-max",
      intl.formatMessage({ id: "interventionPlanTool.export.errors.equipments.equipmentsMin" }),
      function (value) {
        const { max } = this.parent;
        return value <= max;
      },
    ),
  max: yup
    .number()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .required(intl.formatMessage({ id: "error.required" })),
  canEditName: yup.boolean().required(intl.formatMessage({ id: "error.required" })),
});

export const schema = yup.object().shape({
  houseName: yup.string().required(intl.formatMessage({ id: "error.required" })),
  houseId: yup.string().required(intl.formatMessage({ id: "error.required" })),
  adress: yup.string(),
  planifiedAt: yup.object().shape({
    start: yup
      .string()
      .test(
        "is-less-than-or-equal-end",
        intl.formatMessage({ id: "interventionPlanTool.export.errors.planifiedAt.startLowerThanEnd" }),
        function (value) {
          const { end } = this.parent;
          return value ? dayjs(value).isSameOrBefore(dayjs(end)) : true;
        },
      ),
    end: yup.string(),
  }),
  equipments: yup
    .array()
    .of(equipmentSchema)
    .required(intl.formatMessage({ id: "error.required" }))
    .min(1, intl.formatMessage({ id: "interventionPlanTool.export.errors.equipments.min" })),
  operatingTime: yup.number().required(intl.formatMessage({ id: "error.required" })),
  commentsIntervention: yup.string(),
  contact: yup.object().shape({
    id: yup.string().required(intl.formatMessage({ id: "error.required" })),
    firstName: yup.string().required(intl.formatMessage({ id: "error.required" })),
    lastName: yup.string().required(intl.formatMessage({ id: "error.required" })),
    phoneNumber: yup.string().required(intl.formatMessage({ id: "error.required" })),
    email: yup.string().email(intl.formatMessage({ id: "error.email" })),
  }),
  editAt: yup.string().required(intl.formatMessage({ id: "error.required" })),
  isForEisoxProvider: yup.boolean().required(intl.formatMessage({ id: "error.required" })),
  plans: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(intl.formatMessage({ id: "error.required" })),
        planId: yup.string().required(intl.formatMessage({ id: "error.required" })),
        name: yup.string().required(intl.formatMessage({ id: "error.required" })),
        image: yup.string().required(intl.formatMessage({ id: "error.required" })),
      }),
    )
    .required(intl.formatMessage({ id: "error.required" }))
    .min(1, intl.formatMessage({ id: "interventionPlanTool.export.errors.plans.min" })),
  version: yup.string().required(intl.formatMessage({ id: "error.required" })),
});
