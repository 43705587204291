/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StatusCodes } from "http-status-codes";

import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";
import type { Mesh } from "../types";

const getMeshs = async (houseId: string): Promise<Mesh[]> => {
  const { data, error, response } = await client.GET("/houses/{houseId}/meshs", {
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) return [];
  else if (error || !data?.message?.meshs) throw new Error(error ?? "Error fetching meshs");

  return data.message.meshs.map(m => ({
    ...m,
    id: m.id!,
    name: m.name!,
  }));
};

export const mesh = createQueryKeys("mesh", {
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getMeshs(houseId),
  }),
});
