import type { RouteObject } from "react-router-dom";

import { resetPassword } from "~/routes/routes";

import { ResetPassword } from ".";

const route: RouteObject = {
  path: resetPassword,
  element: <ResetPassword />,
};

export { route };
