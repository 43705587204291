import { useIntl } from "react-intl";

import { ActionButton, Dialog } from "@eisox/design-system";
import { ArrowRightIcon, CalendarIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { UnusualAbsenceScheme } from "../../components";

import styles from "./UnusualAbsencePopup.module.scss";

interface UnusualAbsencePopupProps {
  open: boolean;
  onClose?: VoidFunction;
  absenceTemperature: number;
  preComfortTemperature?: number;
  comfortTemperature?: number;
}

export const UnusualAbsencePopup: React.FC<UnusualAbsencePopupProps> = ({
  open,
  onClose,
  absenceTemperature,
  preComfortTemperature,
  comfortTemperature,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const unsualAbsencePopupStyle = bem("unsual-absence-popup");

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose && onClose()}>
      <Dialog.Content
        className={unsualAbsencePopupStyle()}
        title={formatMessage({
          id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.popup.title",
        })}
        icon={<CalendarIcon />}
      >
        <p className={unsualAbsencePopupStyle("text")}>
          {formatMessage({ id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.popup.text" })}
        </p>
        <UnusualAbsenceScheme
          absenceTemperature={absenceTemperature}
          preComfortTemperature={preComfortTemperature}
          comfortTemperature={comfortTemperature}
        />
        <ActionButton
          className={unsualAbsencePopupStyle("button")}
          text={formatMessage({
            id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.popup.close",
          })}
          icon={<ArrowRightIcon />}
          onClick={onClose}
          rounded
        />
      </Dialog.Content>
    </Dialog.Root>
  );
};
