import clsx from "clsx";
import { useIntl } from "react-intl";

import { Circle } from "@eisox/design-system";
import { ThreeWayValveIcon, TwoWayValveIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./TwoThreeWayValve.module.scss";

interface TwoThreeWayValveProps {
  numberOfValve?: 2 | 3;
  orientation?: "vertical" | "horizontal";
  opening?: number;
  manual?: boolean;
  error?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const TwoThreeWayValve: React.FC<TwoThreeWayValveProps> = ({
  numberOfValve = 3,
  orientation = "vertical",
  opening,
  manual = false,
  error = false,
  onClick,
  className,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const threeWayValveStyle = bem("three-way-valve");

  const horizontal = orientation === "horizontal";

  return (
    <div className={clsx(threeWayValveStyle({ horizontal: horizontal }), className)} onClick={onClick}>
      <div className={threeWayValveStyle("opening", { clickable: !!onClick, horizontal: horizontal })}>
        <p>{`${
          manual
            ? `${formatMessage({ id: "boilerRoom.scheme.threeWayValve.setpointTemperature.manual" })}${
                !horizontal ? " - " : ""
              }`
            : ""
        } ${opening?.toFixed(1) ?? "-"}%`}</p>
      </div>
      <Circle
        className={threeWayValveStyle("valve", { clickable: !!onClick })}
        size={45}
        selected
        error={error ? "error" : undefined}
      >
        {numberOfValve === 3 ? (
          <ThreeWayValveIcon className={threeWayValveStyle("logo", { horizontal: horizontal })} />
        ) : (
          <TwoWayValveIcon
            className={threeWayValveStyle("logo", { "two-way-horizontal": horizontal, "two-way": true })}
          />
        )}
      </Circle>
    </div>
  );
};
