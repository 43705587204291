import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActionButtonV2, TextInput, UnProtectedRouteResponsiveCard } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";

import type { RecoverAccountRequestParams } from "~/apiV2";
import { env } from "~/configuration";

import styles from "./RecoverAccount.module.scss";

export type RecoverAccountSchema = z.infer<typeof schema>;
const schema = z.object({
  email: z.string().email(),
});

interface PageProps {
  submit: (body: RecoverAccountRequestParams) => void;
  onCancel?: () => void;
}

const RecoverAccountPage: React.FC<PageProps> = ({ submit, onCancel }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<RecoverAccountSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = handleSubmit(data => submit(data));

  return (
    <UnProtectedRouteResponsiveCard version={env.VERSION}>
      <form className={styles.recoverAccount} onSubmit={onSubmit}>
        <TextInput
          {...register("email")}
          className={styles.recoverAccount__input}
          name="email"
          type="email"
          error={errors.email}
          label={t("account.recoverAccountInstruction")}
          placeholder={t("account.emailInstruction")}
        />
        <div className={styles.recoverAccount__buttons}>
          <ActionButtonV2 type="submit" disabled={!isDirty} rounded>
            {t("account.sendLink")}
            <ArrowRightIcon />
          </ActionButtonV2>
          <ActionButtonV2 variant="cancel" onClick={onCancel}>
            {t("account.backToLogin")}
          </ActionButtonV2>
        </div>
      </form>
    </UnProtectedRouteResponsiveCard>
  );
};

export { RecoverAccountPage };
