import { useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { RoundIcon } from "@eisox/design-system";
import { QuestionMarkIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import type { PlanningsStat } from "~/apiV2";

import styles from "./TablePlanningsStat.module.scss";

interface TooltipContentProps {
  comfortPercents: number;
}

const TooltipContent: React.FC<TooltipContentProps> = ({ comfortPercents }) => {
  const { t } = useTranslation();

  const bem = useBem(styles);
  const tooltipContentStyle = bem("tooltip-content");

  const comfortHours = useMemo(() => Math.round((comfortPercents * 24) / 100), [comfortPercents]);
  const absenceHours = useMemo(() => 24 - comfortHours, [comfortHours]);

  return (
    <div className={tooltipContentStyle()}>
      <p className={tooltipContentStyle("timeslot", { type: "comfort" })}>
        {t("plannings.comfort")}
        <span>{comfortHours}h</span>
      </p>
      <p className={tooltipContentStyle("timeslot", { type: "absence" })}>
        {t("plannings.absence")}
        <span>{absenceHours}h</span>
      </p>
    </div>
  );
};

TooltipContent.displayName = "TablePlanningsStatTooltipContent";

interface DayProps {
  comfort: number;
  roomsNumber: number;
}

const Day: React.FC<DayProps> = ({ comfort, roomsNumber }) => {
  const bem = useBem(styles);
  const dayStyle = bem("day");

  const comfortPercents = Math.round(((comfort / roomsNumber) * 100) / 96);

  return (
    <Tooltip content={<TooltipContent comfortPercents={comfortPercents} />} position="left">
      <div className={dayStyle()}>
        <div className={dayStyle("comfort")} style={{ height: `${comfortPercents}%` }}></div>
      </div>
    </Tooltip>
  );
};

Day.displayName = "TablePlanningsStateDay";

interface WeeKProps {
  data: PlanningsStat;
}

const Week: React.FC<WeeKProps> = ({ data }) => {
  const bem = useBem(styles);
  const weekStyle = bem("week");
  const hoursStyle = bem("hours");
  const daysStyle = bem("days");
  const gridLinesSyle = bem("grid-lines");
  const daysLabelStyle = bem("days-label");

  const shiftWeekDays = useCallback((days: NonNullable<PlanningsStat["result"]>) => {
    // days begin by sunday
    const sunday = days[0];
    return [...days.slice(1), sunday];
  }, []);

  const days = useMemo(() => shiftWeekDays(data.result ?? []), [data.result, shiftWeekDays]);
  const getDayAbbreviation = useCallback((day: number) => dayjs().day(day).format("dd")[0], []);

  return (
    <div className={weekStyle()}>
      {/* Hours */}
      <div className={hoursStyle()}>
        {[24, 18, 12, 6, 0].map((h, i) => (
          <div key={`${h}-${i}`} className={hoursStyle("hour")}>{`${h}h`}</div>
        ))}
      </div>
      {/* days */}
      <div className={daysStyle()}>
        {/* days */}
        {days.map((r, i) => (
          <Day key={`${r.absence ?? r.comfort}-${i}`} comfort={r.comfort ?? 0} roomsNumber={data.nbrRooms ?? 0} />
        ))}
        {/* gridLinesStyle */}
        <div className={gridLinesSyle()}>
          {Array.from({ length: 5 }).map((_, i) => (
            <div key={`grid-line-${i}`} className={gridLinesSyle("line")} />
          ))}
        </div>
      </div>
      <div className={daysLabelStyle()}>
        {Array.from({ length: 7 }).map((_, i) => (
          <div key={`day-${i}`} className={daysLabelStyle("day")}>
            {getDayAbbreviation(i + 1)}
          </div>
        ))}
      </div>
    </div>
  );
};

interface TablePlanningsStateProps {
  data: PlanningsStat;
}

export const TablePlanningsStat: React.FC<TablePlanningsStateProps> = ({ data }) => {
  const { t } = useTranslation();

  const bem = useBem(styles);
  const tablePlanningsStatStyle = bem("table-plannings-stat");

  return (
    <div className={tablePlanningsStatStyle()}>
      <div className={tablePlanningsStatStyle("header")}>
        <h3 className={tablePlanningsStatStyle("title")}>{t("dashboard.averagePlannings")}</h3>
        <Tooltip
          content={<p className={tablePlanningsStatStyle("tooltip")}>{t("dashboard.averagePlanningExplication")}</p>}
        >
          <RoundIcon size={26} backgroundColor="gray" iconColor="darkGray">
            <QuestionMarkIcon style={{ width: 10, height: 10 }} />
          </RoundIcon>
        </Tooltip>
      </div>
      <Week data={data} />
    </div>
  );
};

TablePlanningsStat.displayName = "TablePlanningsStat";
