import * as React from "react";

import { IconProps } from "./types";

export const BarChartIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M14.3646 12.6979H2.71875V2.13542C2.71875 2.06093 2.65781 2 2.58333 2H1.63542C1.56094 2 1.5 2.06093 1.5 2.13542V13.7813C1.5 13.8557 1.56094 13.9167 1.63542 13.9167H14.3646C14.439 13.9167 14.5 13.8557 14.5 13.7813V12.8333C14.5 12.7589 14.439 12.6979 14.3646 12.6979ZM4.20833 11.3438H5.15625C5.23073 11.3438 5.29167 11.2828 5.29167 11.2083V8.77083C5.29167 8.69637 5.23073 8.63542 5.15625 8.63542H4.20833C4.13385 8.63542 4.07292 8.69637 4.07292 8.77083V11.2083C4.07292 11.2828 4.13385 11.3438 4.20833 11.3438ZM6.78125 11.3438H7.72917C7.80364 11.3438 7.86458 11.2828 7.86458 11.2083V5.79167C7.86458 5.71718 7.80364 5.65625 7.72917 5.65625H6.78125C6.70677 5.65625 6.64583 5.71718 6.64583 5.79167V11.2083C6.64583 11.2828 6.70677 11.3438 6.78125 11.3438ZM9.35417 11.3438H10.3021C10.3765 11.3438 10.4375 11.2828 10.4375 11.2083V7.11198C10.4375 7.0375 10.3765 6.97656 10.3021 6.97656H9.35417C9.27971 6.97656 9.21875 7.0375 9.21875 7.11198V11.2083C9.21875 11.2828 9.27971 11.3438 9.35417 11.3438ZM11.9271 11.3438H12.875C12.9495 11.3438 13.0104 11.2828 13.0104 11.2083V4.4375C13.0104 4.36302 12.9495 4.30208 12.875 4.30208H11.9271C11.8526 4.30208 11.7917 4.36302 11.7917 4.4375V11.2083C11.7917 11.2828 11.8526 11.3438 11.9271 11.3438Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default BarChartIcon;
