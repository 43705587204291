import type { HousesHouseIdBoilerroomsPosGet200ResponseMessageInner } from "@eisox/backend_webapp_api";

export const concatBoilerroomNamesFromModule = (module: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner) => {
  return (
    module.boilerRooms
      ?.map(br => br.name)
      .filter(name => name !== undefined)
      .sort((a, b) => a.localeCompare(b))
      .join(", ") ?? ""
  );
};

export const filterObject = (
  obj: Record<string, any>,
  keys: string[],
  alwaysKeepKeys: string[],
): Record<string, any> | null => {
  if (Array.isArray(obj)) {
    // Filtrer les éléments du tableau de manière récursive
    const filteredArray = obj
      .map(item => filterObject(item, keys, alwaysKeepKeys))
      .filter(item => item !== null && item !== undefined);
    // Ne conserver que les éléments non vides
    return filteredArray.length > 0 ? filteredArray : null;
  } else if (obj !== null && typeof obj === "object") {
    const filteredObj: Record<string, any> = {};
    let hasRelevantKeys = false;
    let hasRequestedKeys = false;

    for (const key in obj) {
      if (keys.includes(key)) {
        filteredObj[key] = obj[key]; // Conserver la valeur de la clé demandée
        hasRelevantKeys = true;
        hasRequestedKeys = true;
      } else if (typeof obj[key] === "object") {
        const filteredChild = filterObject(obj[key], keys, alwaysKeepKeys);
        if (
          filteredChild !== null &&
          (Array.isArray(filteredChild) ? filteredChild.length > 0 : Object.keys(filteredChild).length > 0)
        ) {
          filteredObj[key] = filteredChild;
          hasRelevantKeys = true;
          if (Object.keys(obj).some(k => keys.includes(k) || alwaysKeepKeys.includes(k))) {
            hasRequestedKeys = true;
          }
        }
      }
    }

    // Ajouter les clés à conserver si des clés demandées sont présentes ou sur le chemin
    if (hasRequestedKeys) {
      alwaysKeepKeys.forEach(key => {
        if (obj.hasOwnProperty(key)) {
          filteredObj[key] = obj[key];
          hasRelevantKeys = true;
        }
      });
    }

    return hasRelevantKeys ? filteredObj : null;
  } else {
    return null;
  }
};
