import { useState } from "react";

import { cx } from "class-variance-authority";

import type { Dayjs } from "@eisox/dayjs";

import type { GatewaysWithProblem, HouseType, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import type { WebappRolePermission } from "~/utils";

import {
  getDisplayedGateways,
  getDisplayedRooms,
  getDisplayedValves,
  getErrorTotal,
  getSortOptions,
  getWarningTotal,
} from "../../helpers";
import { Filters } from "../Filters";
import { Header } from "../Header";
import { List } from "../List";

import styles from "./Page.module.scss";

interface PageProps {
  house: HouseType;
  valves: ValvesWithProblem[];
  gateways: GatewaysWithProblem[];
  rooms: RoomsWithProblem[];
  udpatedAt: Dayjs;
  onRefresh: VoidFunction;
  role?: WebappRolePermission;
  onClickOnPlan: (planId: string) => void;
  onClickOnRoom: (planId: string, roomId: string) => void;
  preReplace?: boolean;
  selectedItems?: string[];
  onSelectionChange?: (items: string[]) => void;
  onDisplayedItemsChange?: (items: "gateways" | "valves") => void;
  className?: string;
}

const Page: React.FC<PageProps> = ({
  house,
  gateways,
  valves,
  rooms,
  udpatedAt: updatedAtProp,
  onRefresh,
  onClickOnPlan,
  onClickOnRoom,
  preReplace = false,
  selectedItems,
  onSelectionChange,
  role,
  onDisplayedItemsChange,
  className,
}) => {
  const updatedAt = updatedAtProp.format("LT");

  const [displayedItems, setDisplayedItems] = useState<"gateways" | "valves" | "rooms">("valves");
  const [search, setSearch] = useState<string>("");
  const [filters, setFilters] = useState<string[]>([]);
  const [sort, setSort] = useState<string>(getSortOptions(displayedItems)[0]?.value);

  const items = {
    gateways: getDisplayedGateways(gateways, search, filters, sort),
    valves: getDisplayedValves(valves, search, filters, sort),
    rooms: getDisplayedRooms(rooms, search, filters),
  }[displayedItems];

  const resultsTotal = items.length;
  const gatewaysErrors = getErrorTotal(gateways);
  const gatewaysWarnings = getWarningTotal(gateways);
  const valvesErrors = getErrorTotal(valves);
  const valvesWarnings = getWarningTotal(valves);
  const roomsErrors = getErrorTotal(rooms);
  const roomsWarnings = getWarningTotal(rooms);

  const handleDisplayedItemsChange = (items: "gateways" | "valves" | "rooms") => {
    setDisplayedItems(items);
    setSort(getSortOptions(items)[0]?.value);
    setSearch("");
    setFilters([]);
    if (["gateways", "valves"].includes(items)) onDisplayedItemsChange?.(items as "gateways" | "valves");
  };

  return (
    <div className={cx(styles.page, className)}>
      <Header
        items={items}
        filters={filters}
        role={role}
        gatewaysErrors={gatewaysErrors}
        gatewaysWarning={gatewaysWarnings}
        valvesErrors={valvesErrors}
        valvesWarning={valvesWarnings}
        roomsErrors={roomsErrors}
        roomsWarning={roomsWarnings}
        updatedAt={updatedAt}
        displayedItems={displayedItems}
        onDisplayedItemsChange={handleDisplayedItemsChange}
        onRefresh={onRefresh}
        preReplace={preReplace}
      />
      <Filters
        displayedItems={displayedItems}
        search={search}
        onSearchChange={setSearch}
        filters={filters}
        onFiltersChange={setFilters}
        sort={sort}
        onSortChange={setSort}
        total={resultsTotal}
      />
      <List
        houseId={house._id!}
        displayedItems={displayedItems}
        items={items}
        rooms={rooms}
        gateways={gateways}
        house={house}
        onClickOnPlan={onClickOnPlan}
        onClickOnRoom={onClickOnRoom}
        onDeleteRoom={onRefresh}
        preReplace={preReplace}
        selectedItems={selectedItems}
        onSelectionChange={onSelectionChange}
      />
    </div>
  );
};

export { Page };
export type { PageProps };
