import { StatusCodes } from "http-status-codes";
import { $enum } from "ts-enum-util";

import type { PostManualBalancingHistorySort } from "@eisox/webapp-api-specification";
import {
  PostBatteryHistoryFields,
  PostManualBalancingHistoryFields,
  PostMecaHistoryFields,
} from "@eisox/webapp-api-specification";
import type {
  PathsHistoryHousesHouseIdModulesModuleIdHeatingNetworksHeatingNetworkIdHeatingCurvesPostParametersQueryDesc as HeatingCurveHistoryDesc,
  PathsHistoryHousesHouseIdModulesModuleIdHeatingNetworksHeatingNetworkIdHeatingCurvesPostParametersQuerySort as HeatingCurveHistorySort,
  PathsHistoryHousesHouseIdModulesModuleIdHeatingNetworksHeatingNetworkIdHslopesPostParametersQueryDesc as HeatingCurveHslopeHistoryDesc,
  PathsHistoryHousesHouseIdModulesModuleIdHeatingNetworksHeatingNetworkIdHslopesPostParametersQuerySort as HeatingCurveHslopeHistorySort,
} from "@eisox/webapp-api-specification";
import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";
import { getModuleHistory } from "../mutations";
import type {
  HeatingCurveHistory,
  HeatingCurveHistoryRequestBody,
  HeatingCurveHslopeHistory,
  HeatingCurveHslopeHistoryRequestBody,
  ModuleHistoryRequestBody,
} from "../types";

const getBatteryHistory = async (from: string, to: string, mac: string) => {
  const { data, error } = await client.POST("/history/battery", {
    body: { startDate: from, endDate: to, valves: [mac], fields: Object.values(PostBatteryHistoryFields) },
  });
  if (error) throw new Error(error.message);
  return data?.message ?? [];
};

const getMecaHistory = async (from: string, to: string, mac: string) => {
  const { data, error } = await client.POST("/history/meca", {
    body: { startDate: from, endDate: to, valves: [mac], fields: Object.values(PostMecaHistoryFields) },
  });
  if (error) throw new Error(error.message);
  return data?.message ?? [];
};

const getDefectHistory = async (from: string, to: string, houseId: string) => {
  const { error, data, response } = await client.POST("/history/houses/{houseId}/modules/deffects", {
    body: { startDate: from, endDate: to },
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) return [];

  if (error) throw new Error((error as { message?: string }).message);

  return data?.message ?? [];
};

const getHslopeHistory = async (
  from: string,
  to: string,
  mac: string,
  pageNumber: number,
  sort: PostManualBalancingHistorySort[],
  desc: PostManualBalancingHistorySort[],
) => {
  const { data, error, response } = await client.POST("/history/manualBalancing", {
    params: {
      query: {
        pageNumber: pageNumber.toString(),
        pageSize: "20",
        sort,
        desc,
      },
    },
    body: {
      startDate: from,
      endDate: to,
      valvesMac: [mac],
      fields: $enum(PostManualBalancingHistoryFields).getValues(),
    },
  });
  if (error) throw new Error(error.message);
  const [_rangeStart, rangeEnd, size] =
    response.headers
      .get("Content-Range")
      ?.match(/(\d+)-(\d+)\/(\d+)/)
      ?.slice(1)
      .map(Number) ?? [];
  return {
    data: data?.message ?? [],
    meta: {
      total: size,
      nextPage: rangeEnd < size ? pageNumber + 1 : undefined,
    },
  };
};

const getLastModuleHistory = async (houseId: string) => {
  const { data, error } = await client.GET("/history/houses/{houseId}/modules/lastHistory", {
    params: { path: { houseId } },
  });
  if (error) throw Error(error.message);
  if (!data?.message) throw new Error();
  return data.message;
};

const getHeatingCurveHistory = async (
  houseId: string,
  moduleId: string,
  heatingNetworkId: string,
  pageNumber: number,
  sort: HeatingCurveHistorySort[],
  desc: HeatingCurveHistoryDesc[],
  body: HeatingCurveHistoryRequestBody,
): Promise<{
  data: HeatingCurveHistory[];
  meta: {
    total: number;
    nextPage?: number;
  };
}> => {
  const { data, error, response } = await client.POST(
    "/history/houses/{houseId}/modules/{moduleId}/heatingNetworks/{heatingNetworkId}/heatingCurves",
    {
      params: {
        path: {
          houseId,
          moduleId,
          heatingNetworkId,
        },
        query: {
          pageNumber: pageNumber.toString(),
          pageSize: "20",
          sort,
          desc,
        },
      },
      body,
    },
  );
  if (error) throw new Error(error.message);
  const [_rangeStart, rangeEnd, size] =
    response.headers
      .get("Content-Range")
      ?.match(/(\d+)-(\d+)\/(\d+)/)
      ?.slice(1)
      .map(Number) ?? [];
  return {
    data: data?.message ?? [],
    meta: {
      total: size,
      nextPage: rangeEnd < size ? pageNumber + 1 : undefined,
    },
  };
};

const getHeatingCurveHslopeHistory = async (
  houseId: string,
  moduleId: string,
  heatingNetworkId: string,
  pageNumber: number,
  sort: HeatingCurveHslopeHistorySort[],
  desc: HeatingCurveHslopeHistoryDesc[],
  body: HeatingCurveHslopeHistoryRequestBody,
): Promise<{
  data: HeatingCurveHslopeHistory[];
  meta: {
    total: number;
    nextPage?: number;
  };
}> => {
  const { data, error, response } = await client.POST(
    "/history/houses/{houseId}/modules/{moduleId}/heatingNetworks/{heatingNetworkId}/hslopes",
    {
      params: {
        path: {
          houseId,
          moduleId,
          heatingNetworkId,
        },
        query: {
          pageNumber: pageNumber.toString(),
          pageSize: "10",
          sort,
          desc,
        },
      },
      body,
    },
  );
  if (error) throw new Error(error.message);
  const [_rangeStart, rangeEnd, size] =
    response.headers
      .get("Content-Range")
      ?.match(/(\d+)-(\d+)\/(\d+)/)
      ?.slice(1)
      .map(Number) ?? [];
  return {
    data: data?.message ?? [],
    meta: {
      total: size,
      nextPage: rangeEnd < size ? pageNumber + 1 : undefined,
    },
  };
};

export const history = createQueryKeys("history", {
  battery: (from: string, to: string, mac: string) => ({
    queryKey: [from, to, mac],
    queryFn: () => getBatteryHistory(from, to, mac),
  }),
  meca: (from: string, to: string, mac: string) => ({
    queryKey: [from, to, mac],
    queryFn: () => getMecaHistory(from, to, mac),
  }),
  defect: (from: string, to: string, houseId: string) => ({
    queryKey: [from, to, houseId],
    queryFn: () => getDefectHistory(from, to, houseId),
  }),
  hslope: (
    from: string,
    to: string,
    mac: string,
    sort: PostManualBalancingHistorySort[],
    desc: PostManualBalancingHistorySort[],
  ) => ({
    queryKey: [from, to, mac, sort, desc],
    queryFn: ({ pageParam = 1 }) => getHslopeHistory(from, to, mac, pageParam as number, sort, desc),
  }),
  module: (moduleId: string, body: ModuleHistoryRequestBody) => ({
    queryKey: [moduleId, body],
    queryFn: () => getModuleHistory(moduleId, body),
  }),
  lastModule: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getLastModuleHistory(houseId),
  }),
  heatingCurveHistory: (
    houseId: string,
    moduleId: string,
    heatingNetworkId: string,
    sort: HeatingCurveHistorySort[],
    desc: HeatingCurveHistoryDesc[],
    body: HeatingCurveHistoryRequestBody,
  ) => ({
    queryKey: [houseId, moduleId, heatingNetworkId, sort, desc, body],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      getHeatingCurveHistory(houseId, moduleId, heatingNetworkId, pageParam, sort, desc, body),
  }),
  heatingCurveHslopeHistory: (
    houseId: string,
    moduleId: string,
    heatingNetworkId: string,
    sort: HeatingCurveHslopeHistorySort[],
    desc: HeatingCurveHslopeHistoryDesc[],
    body: HeatingCurveHslopeHistoryRequestBody,
  ) => ({
    queryKey: [houseId, moduleId, heatingNetworkId, sort, desc, body],
    queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
      getHeatingCurveHslopeHistory(houseId, moduleId, heatingNetworkId, pageParam, sort, desc, body),
  }),
});
