export const version = "/api/v3/";
export const routeHouses = version + "houses";
export const gatewayRouteConcat = "/gateways/";
export const valvesRouteConcat = "/valves/";
export const valvesRoomsRouteConcat = "/valves/rooms/";
export const planningsRouteConcat = "/plannings/";
export const roomsRouteConcat = "/rooms/";
export const planRoute = "/plans/";
export const profileRoute = version + "houseRoomsHeatingProfiles/";
export const usersRoleRoute = version + "users/";
export const usersRoleRouteExtension = "/roles/";
export const rolesInHousesRoute = version + "user/rolesInHouses/";
export const creatorsRoute = version + "users/roles/creators/";
export const histoValvesRoute = version + "histovalvesStats/";
export const positionsHousesExtension = "/pos";
export const statusHousesExtension = "/status";
export const weatherRoute = "meteo/weather/";
export const planningsStatRoute = "/planningsStat";
export const meshRouteConcat = "/meshs/";
export const notifyRouteConcat = "/notify/";
export const installRouteContact = version + "install/";
export const routeBoilerRoom = "/boilerroom/";
export const routeBoilerRooms = version + "boilerrooms/";
export const modulesRoute = version + "modules";
export const modulesRouteConcat = "/modules/";
export const boilerRoomModuleConcat = "/boilerrooms/";
export const boilerRoomsPos = "/boilerroomsPos/";
export const routeBoilerConcat = "/boilers/";
export const routeHeatingNetworkConcat = "/heatingnetworks/";
export const heatingNetworkRouteConcat = "/heatingNetworks";
export const routeUsersConcat = "/users/";
export const routeModuleGatewayConcat = "/gateways/modules/";
export const routeUserConcat = version + "user/";
