import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import type { InterventionPlanState } from "~/features/InterventionPlanTool";
import { intl } from "~/i18n";

import { darkBlueColor, replaceValveColor } from "../color";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { orientation, size } from "../constant";
import valveExplain from "../img/valve_explain.png";
import warning from "../img/warning.png";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    padding: "10 20",
  },
  left: {
    flex: 5,
    display: "flex",
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  seperator: {
    width: 1,
    backgroundColor: darkBlueColor,
    height: "100%",
  },
  valveImage: {
    width: 150,
  },
  valveWarning: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 10,
    fontSize: 9,
    border: `1px solid ${darkBlueColor}`,
    borderRadius: 15,
    padding: 10,
    flex: 7,
  },
  warningSerial: {
    fontWeight: 600,
  },
  warningIcone: {
    width: 50,
  },
  right: {
    flex: 5,
    display: "flex",
    flexDirection: "column",
    gap: 30,
    alignItems: "center",
  },
  warningReplaceValve: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 10,
    fontSize: 9,
    border: `1px solid ${darkBlueColor}`,
    borderRadius: 15,
    padding: 10,
    color: replaceValveColor,
    width: "65%",
  },
  chronologieContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    width: "100%",
  },
  chronologieTitle: {
    color: darkBlueColor,
    fontSize: 16,
    textDecoration: "underline",
    fontWeight: 600,
  },
  chronologie: {
    fontSize: 10,
  },
});

export const Page4: React.FC<InterventionPlanState> = ({ isForEisoxProvider, commentsIntervention }) => {
  return (
    <Page size={size} orientation={orientation}>
      <Header title="Brief d’intervention 3/3" />
      <View style={styles.container}>
        <View style={styles.left}>
          <Image src={valveExplain} style={styles.valveImage} />
          <View style={styles.valveWarning}>
            <Image src={warning} style={styles.warningIcone} />
            <Text style={styles.warningSerial}>
              {intl.formatMessage({ id: "interventionPlanTool.pdf.page4.warningSerial" })}
            </Text>
            <Text>{intl.formatMessage({ id: "interventionPlanTool.pdf.page4.correctSerial" })}</Text>
          </View>
        </View>
        <View style={styles.seperator} />
        <View style={styles.right}>
          <View style={styles.warningReplaceValve}>
            <Image src={warning} style={styles.warningIcone} />
            <Text style={styles.warningSerial}>
              {intl.formatMessage({
                id: `interventionPlanTool.pdf.page4.${
                  isForEisoxProvider ? "replaceValveForEisoxClient" : "replaceValveForOtherClient"
                }`,
              })}
            </Text>
          </View>
          {commentsIntervention!.length > 0 && (
            <View style={styles.chronologieContainer}>
              <Text style={styles.chronologieTitle}>
                {intl.formatMessage({ id: "interventionPlanTool.pdf.page4.comments" })}
              </Text>
              <Text style={styles.chronologie}>{commentsIntervention}</Text>
            </View>
          )}
        </View>
      </View>
      <Footer />
    </Page>
  );
};
