import React from "react";

import { cx } from "class-variance-authority";

import { TableV2 as Table, Typography } from "@eisox/design-system";
import { ChevronDownIcon, ChevronUpIcon } from "@eisox/icons";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { TableOptions } from "@tanstack/react-table";

import type { User } from "../../helpers";

import styles from "./UserTable.module.scss";

interface UserTableProps {
  options: Omit<
    TableOptions<User>,
    "getCoreRowModel" | "getSortedRowModel" | "getExpandedRowModel" | "getPaginationRowModel" | "getSubRows"
  >;
}

const UserTable: React.FC<UserTableProps> = ({ options }) => {
  const table = useReactTable<User>({
    ...options,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  return (
    <Table.Container>
      <Table.Root className={styles.table}>
        {table.getHeaderGroups().map(headergroup => (
          <>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col width={80} />
              <col />
              <col />
              <col />
              <col width={170} />
              <col width={50} />
            </colgroup>
            <Table.Header key={headergroup.id} className={styles.header}>
              <Table.Row>
                {headergroup.headers.map(header => (
                  <Table.Head key={header.id} onClick={header.column.getToggleSortingHandler()}>
                    <div
                      className={cx(styles.header__head, header.column.getCanSort() && styles.header__head_sortable)}
                    >
                      <Typography>{flexRender(header.column.columnDef.header, header.getContext())}</Typography>
                      {{
                        asc: <ChevronUpIcon />,
                        desc: <ChevronDownIcon />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </Table.Head>
                ))}
              </Table.Row>
            </Table.Header>
          </>
        ))}
        <Table.Body>
          {table.getRowModel().rows.map(row => (
            <React.Fragment key={row.id}>
              <Table.Row className={styles.row}>
                {row.getVisibleCells().map(cell => (
                  <Table.Cell key={cell.id} className={styles.cell}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
              <Table.Row className={styles.divider} />
            </React.Fragment>
          ))}
        </Table.Body>
      </Table.Root>
    </Table.Container>
  );
};

export { UserTable as Table };
export type { UserTableProps as TableProps };
