import type { ChangeEvent } from "react";
import { Fragment } from "react";

import { cx } from "class-variance-authority";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TextField } from "@eisox/design-system";
import { SparklesIcon } from "@eisox/icons";

import type { HeatingCurveSchemaType } from "../../helpers";
import { HistoryDialog } from "../HistoryDialog";

import styles from "./HeatingCurve.module.scss";

interface HeatingCurveProps {
  auto: boolean;
  houseId: string;
  moduleId: string;
  heatingNetworkId: string;
  heatingNetworkName: string;
}

const HeatingCurve: React.FC<HeatingCurveProps> = ({
  auto,
  houseId,
  moduleId,
  heatingNetworkId,
  heatingNetworkName,
}) => {
  const { t } = useTranslation();

  const { control } = useFormContext<HeatingCurveSchemaType>();
  const { fields, update } = useFieldArray({ control: control, name: "heatingCurve" });

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    const value = e.target.value;
    update(index, { ...fields[index], con: parseInt(value) });
  };

  return (
    <div className={cx(styles.heatingCurve, auto && styles.heatingCurve_auto)}>
      <p className={styles.heatingCurve__thead}>
        {t("network.content.heatingCurve.dialog.heatingCurve.externalTemperature")}
      </p>
      <p className={styles.heatingCurve__thead}>{t("network.content.heatingCurve.dialog.heatingCurve.setpoint")}</p>
      {auto && (
        <p className={cx(styles.heatingCurve__thead, styles.heatingCurve__thead_auto)}>
          <SparklesIcon />
          {t("network.content.heatingCurve.dialog.heatingCurve.auto")}
        </p>
      )}
      {fields.map(({ ext, con, offset }, i) => (
        <Fragment key={i}>
          <p className={styles.heatingCurve__ext}>{ext}°C</p>
          <div className={styles.heatingCurve__con}>
            <TextField.Root
              type="number"
              className={styles.heatingCurve__input}
              value={con}
              onChange={e => handleChangeValue(e, i)}
            />
            °C
          </div>
          {auto && (
            <div className={styles.heatingCurve__auto}>
              {con + (offset ?? 0)}°C
              <HistoryDialog
                houseId={houseId}
                moduleId={moduleId}
                heatingNetworkId={heatingNetworkId}
                heatingNetworkName={heatingNetworkName}
                externalTemperature={ext}
                currentSetpointTemperautre={con}
                currentOffsetTemperature={offset}
                tExtOptions={fields.map(f => f.ext)}
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export { HeatingCurve };
