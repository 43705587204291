import type { ValvesMessageValvesInner } from "@eisox/backend_webapp_api";
import { VALVE_ERRORS_ENUM, VALVE_WARNINGS_ENUM } from "@eisox/problems-handler";
import { MECA_PROBLEMS_ENUM } from "@eisox/valves";

import type { ValvesWithProblem } from "~/UI/screens/House";
import { FORMAT_DATE } from "~/constants/timeConstants";
import { intl } from "~/i18n";

import { formatedDate } from "../timeUtils";

type StatusHandlers<T extends string> = Record<T, (v: ValvesWithProblem, showDate: boolean) => string>;

/* ERRORS */
const formatProblemMessage = (
  v: ValvesMessageValvesInner,
  id: string,
  showDate: boolean,
  problemName?: MECA_PROBLEMS_ENUM,
) => {
  const date = problemName
    ? formatedDate(FORMAT_DATE.valveProbleme, v.mecaProblems?.find(mp => mp.name === problemName)?.updatedAt)
    : formatedDate(FORMAT_DATE.valveProbleme, v.updatedAt);

  return intl.formatMessage({ id }, { s: showDate, d: date });
};

export const VALVE_ERRORS_TRANSLATION: StatusHandlers<VALVE_ERRORS_ENUM | MECA_PROBLEMS_ENUM> = {
  [VALVE_ERRORS_ENUM.LONG_TIME_DISCONNECTED]: (v: ValvesMessageValvesInner, showDate: boolean) =>
    formatProblemMessage(v, "valves.errors.longTimeDisconnected", showDate),
  [VALVE_ERRORS_ENUM.DISCONNECTED]: (v: ValvesMessageValvesInner, showDate: boolean) =>
    formatProblemMessage(v, "valves.errors.disconnected", showDate),
  [VALVE_ERRORS_ENUM.DISCONNECTED_GATEWAY]: () => intl.formatMessage({ id: "valves.errors.disconnectedGateway" }),
  [VALVE_ERRORS_ENUM.LOW_BATTERY]: () => intl.formatMessage({ id: "valves.errors.lowBattery" }),
  [MECA_PROBLEMS_ENUM.MOTOR_PROBLEM]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.motorProblem", showDate, MECA_PROBLEMS_ENUM.MOTOR_PROBLEM),
  [MECA_PROBLEMS_ENUM.ENCODER_PROBLEM]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.encoderProblem", showDate, MECA_PROBLEMS_ENUM.ENCODER_PROBLEM),
  [MECA_PROBLEMS_ENUM.INA_PROBLEM]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.inaProblem", showDate, MECA_PROBLEMS_ENUM.INA_PROBLEM),
  [MECA_PROBLEMS_ENUM.PLUGGED_FALSE]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.pluggedFalse", showDate, MECA_PROBLEMS_ENUM.PLUGGED_FALSE),
  [MECA_PROBLEMS_ENUM.VALVE_IS_SEIZED_UP]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.valveIsSeizedUp", showDate, MECA_PROBLEMS_ENUM.VALVE_IS_SEIZED_UP),
  [MECA_PROBLEMS_ENUM.NO_MOVE_AT_LOW_SPEED]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.noMoveAtLowSpeed", showDate, MECA_PROBLEMS_ENUM.NO_MOVE_AT_LOW_SPEED),
  [MECA_PROBLEMS_ENUM.NO_MAX_INT_CLOSE]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.noMaxIntClose", showDate, MECA_PROBLEMS_ENUM.NO_MAX_INT_CLOSE),
  [MECA_PROBLEMS_ENUM.NO_MAX_INT]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.noMaxInt", showDate, MECA_PROBLEMS_ENUM.NO_MAX_INT),
  [MECA_PROBLEMS_ENUM.UNSCREWED]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.unscrewed", showDate, MECA_PROBLEMS_ENUM.UNSCREWED),
  [MECA_PROBLEMS_ENUM.FAIL_TO_CLOSE]: (v: ValvesMessageValvesInner, showDate = false) =>
    formatProblemMessage(v, "valves.errors.failToClose", showDate, MECA_PROBLEMS_ENUM.FAIL_TO_CLOSE),
};

/* WARNINGS */
export const VALVE_WARNINGS_TRANSLATION: StatusHandlers<VALVE_WARNINGS_ENUM> = {
  [VALVE_WARNINGS_ENUM.BAD_RSSI]: () => intl.formatMessage({ id: "valves.warnings.badRssi" }),
  [VALVE_WARNINGS_ENUM.BAD_LQI]: () => intl.formatMessage({ id: "valves.warnings.badLqi" }),
  [VALVE_WARNINGS_ENUM.LOW_ROW_STROKE]: () => intl.formatMessage({ id: "valves.warnings.lowRowStroke" }),
  [VALVE_WARNINGS_ENUM.NO_LEARNING]: () => intl.formatMessage({ id: "valves.warnings.noLearning" }),
  [VALVE_WARNINGS_ENUM.NOT_ACTIVATED]: () => intl.formatMessage({ id: "valves.warnings.notActivated" }),
  [VALVE_WARNINGS_ENUM.DISCONNECTED_WARNING]: (v: ValvesMessageValvesInner, showDate: boolean) =>
    intl.formatMessage(
      { id: "valves.warnings.disconnected" },
      { s: showDate, d: formatedDate(FORMAT_DATE.valveProbleme, v.updatedAt) },
    ),
  [VALVE_WARNINGS_ENUM.LOW_BATTERY_WARNING]: () => intl.formatMessage({ id: "valves.warnings.lowBattery" }),
  [VALVE_WARNINGS_ENUM.NOT_INSTALL]: () => intl.formatMessage({ id: "valves.warnings.notInstall" }),
};

/**
 * Retrieves the translations for the errors and warnings of a valve.
 * @param valve - The valve object containing the errors and warnings.
 * @returns An object containing the translated errors and warnings.
 */
export const getValveProblemTranslation = (valve: ValvesWithProblem) => {
  const errors = valve.errors.map(error => VALVE_ERRORS_TRANSLATION[error](valve, true));
  const warnings = valve.warnings.map(warning => VALVE_WARNINGS_TRANSLATION[warning](valve, true));
  return { errors, warnings };
};
