import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { POST_METHOD } from "../../constants/fetchConstants";
import { internalFetchByUrl } from "../../helpers/communication/FetchApi";
import { histoValvesRoute } from "../../routesStatic/routesExpress";

export function getHistoValvesData(listMacValves, startDate, endDate, fields) {
  let fetchingResponse = {};
  const body = {};
  if (fields !== undefined && fields !== null && fields.length !== 0) {
    body.fields = fields;
  }
  if (startDate !== undefined && startDate !== null) {
    body.startDate = startDate;
  }
  if (endDate !== undefined && endDate !== null) {
    body.endDate = endDate;
  }
  if (listMacValves !== undefined && listMacValves !== null && listMacValves.length !== 0) {
    body.valves = listMacValves;
  }
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteGetHistoValves = env.SERVER_URL + histoValvesRoute;
  if (body.length !== 0) {
    fetchingResponse = internalFetchByUrl(RouteGetHistoValves, POST_METHOD, customHeaders, body);
  }
  return fetchingResponse;
}
