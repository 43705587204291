import type { HeatingNetwork as HeatingNetworkType } from "~/socketio/types";
import { BoilerromLayout } from "~/stores";

import { HeatingNetworks as HeatingNetworksLayout } from "../../layouts/HeatingNetworks";
import { HeatingNetwork } from "../HeatingNetwork/HeatingNetwork";

const NAME = "HeatingNetworks";

interface HeatingNetworksProps {
  layout: BoilerromLayout;
  heatingNetworks: HeatingNetworkType[];
}

export const HeatingNetworks: React.FC<HeatingNetworksProps> = ({ layout, heatingNetworks }) => (
  <HeatingNetworksLayout layout={layout}>
    {heatingNetworks.map((hn, i) => {
      const orientation = layout === BoilerromLayout.TREE && i % 2 !== 0 ? "down" : "up";
      return <HeatingNetwork key={i} {...hn} orientation={orientation} />;
    })}
  </HeatingNetworksLayout>
);

HeatingNetworks.displayName = NAME;
