import { useParams, useRevalidator, useRouteLoaderData } from "react-router-dom";

import type { houseLoader } from "~/UI";
import { Page } from "~/UI/components";
import { Page as MaintenancePage } from "~/features/Maintenance";
import { usePermissionsContext } from "~/providers";
import { idLoaderHouse } from "~/routes/utils";

const Maintenance: React.FC = () => {
  const { houseId } = useParams() as { houseId: string };
  const { gateways, plans, valves, date } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    ReturnType<typeof houseLoader>
  >;
  const { revalidate } = useRevalidator();

  const { permissions } = usePermissionsContext("maintenance");

  const handleRefresh = () => revalidate();

  if (!permissions.page?.maintenance) throw new Error("Unauthorized");

  return (
    <Page.Content>
      <MaintenancePage
        valves={valves}
        gateways={gateways}
        houseId={houseId}
        plans={plans}
        updatedAt={date}
        onRefresh={handleRefresh}
      />
    </Page.Content>
  );
};

export default Maintenance;
