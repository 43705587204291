import { useCallback, useEffect, useMemo, useState } from "react";

import { ReactZoomPanPinchContentRef } from "react-zoom-pan-pinch";

export const useInitImageScale = (
  container: HTMLDivElement | null,
  wrapperRef: React.RefObject<ReactZoomPanPinchContentRef>,
  src?: string,
) => {
  const [loading, setLoading] = useState(true);
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  const [imageNaturalSize, setImageNaturalSize] = useState({
    width: 0,
    height: 0,
  });

  const imageScale = useMemo(() => {
    if (
      containerSize.width === 0 ||
      containerSize.height === 0 ||
      imageNaturalSize.width === 0 ||
      imageNaturalSize.height === 0
    )
      return 0;
    const scale = Math.min(
      containerSize.width / imageNaturalSize.width,
      containerSize.height / imageNaturalSize.height,
    );
    wrapperRef && wrapperRef.current?.centerView(scale);
    return scale;
  }, [containerSize.width, containerSize.height, imageNaturalSize.width, imageNaturalSize.height]);

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect();
      setContainerSize({
        width: rect.width,
        height: rect.height,
      });
    } else {
      setContainerSize({
        width: 0,
        height: 0,
      });
    }
  }, [container]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      handleResize();
    });
    if (container) {
      resizeObserver.observe(container);
    }
    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
      resizeObserver.disconnect();
    };
  }, [container]);

  const handleImageOnLoad = (image: HTMLImageElement) => {
    setLoading(false);
    setImageNaturalSize({
      width: image.naturalWidth,
      height: image.naturalHeight,
    });
  };

  useEffect(() => {
    setLoading(true);
    if (src) {
      const image = new Image();
      image.onload = () => handleImageOnLoad(image);
      image.src = src;
    }
  }, [src]);

  return { imageScale, loading, containerSize, imageNaturalSize };
};
