import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { PostHistoBoxIntervalEnum } from "@eisox/backend_webapp_api";
import { z } from "@eisox/zod";

import { createSelectors, validateKeyWithSchema } from "~/utils/Zustand";

import { BoilerromLayout, DataType, DisplaySection } from "./type";
import type { UserPreferencAction, UserPreferenceState } from "./type";

const initalState: UserPreferenceState = {
  plan: { data: DataType.TEMPERATURE, display: [DisplaySection.BOILERS, DisplaySection.ROOMS] },
  boilerroom: { layout: BoilerromLayout.TREE },
  maintenance: { advanced: false },
  connectionGatewayHistory: { mode: PostHistoBoxIntervalEnum.Day },
  sidebarCollapsed: false,
};

const validatorSchema = z.object({
  plan: z.object({
    data: z.nativeEnum(DataType),
    display: z.array(z.nativeEnum(DisplaySection)),
  }),
  boilerroom: z.object({
    layout: z.nativeEnum(BoilerromLayout),
  }),
  maintenance: z.object({
    advanced: z.boolean(),
  }),
  connectionGatewayHistory: z.object({
    mode: z.nativeEnum(PostHistoBoxIntervalEnum),
  }),
  sidebarCollapsed: z.boolean(),
});

const useUserPreferenceStoreBase = create<UserPreferenceState & UserPreferencAction>()(
  persist(
    (set, get) => ({
      ...initalState,
      addPlanData: data => {
        const newData = { ...get().plan, data };
        set({ plan: newData });
        return newData.data;
      },
      setBoilerroomLayout: layout => set({ boilerroom: { layout } }),
      addPlanDisplay: display => {
        const newData = {
          ...get().plan,
          display: get().plan.display.includes(display)
            ? get().plan.display.filter(d => d !== display)
            : [...get().plan.display, display],
        };
        set({ plan: newData });
        return newData.display;
      },
      setMaintenanceAdvanced: advanced => set({ maintenance: { advanced } }),
      setConnectionGatewayHistoryMode: mode => set({ connectionGatewayHistory: { mode } }),
      toggleSidebar: collapsed => set({ sidebarCollapsed: collapsed }),
    }),
    {
      storage: createJSONStorage(() => localStorage, {
        reviver: (key, value) => {
          if (key in initalState) return validateKeyWithSchema(validatorSchema, initalState, key, value);
          return value;
        },
      }),
      name: "user-preference",
      version: 1,
    },
  ),
);

const useUserPreferenceStore = createSelectors(useUserPreferenceStoreBase);

export { useUserPreferenceStore };
