import clsx from "clsx";
import { useIntl } from "react-intl";

import { Tooltip } from "~/UI/components";
import { DisplayedHour, getBottom, getTop } from "~/utils/planningUtils";

import { ClickablePlanning } from "../..";

import styles from "./Timeslot.module.scss";

const ToolTipContent: React.FC<ClickablePlanning> = ({
  hourStart,
  hourEnd,
  planningType,
  userDefined,
  temperature,
}) => {
  const { formatMessage } = useIntl();

  const allDay: boolean = hourStart === "00:00" && hourEnd === "00:00";

  return (
    <>
      <p
        className={clsx(
          styles.timeslot__tooltip__type,
          styles[`timeslot__tooltip__type--${planningType}`],
          userDefined && styles[`timeslot__tooltip__type--${planningType}--userDefined`],
        )}
      >
        {formatMessage({ id: `plannings.${planningType}` })} {temperature && <span>{temperature}°C</span>}
      </p>
      <p className={styles.timeslot__tooltip__hours}>
        {allDay
          ? formatMessage({ id: "plannings.allDay" })
          : `${hourStart?.replace(":", "h")} - ${hourEnd?.replace(":", "h")}`}
      </p>
    </>
  );
};

type TimeslotProps = ClickablePlanning & {
  displayHours?: DisplayedHour;
};

export const Timeslot: React.FC<TimeslotProps> = ({ displayHours = DisplayedHour.NONE, ...props }) => (
  <Tooltip content={<ToolTipContent {...props} />} position="left" keepHoverOpen={false}>
    <div
      className={clsx(
        styles.timeslot,
        props.onClick && styles["timeslot--clickable"],
        styles[`timeslot--${props.planningType}`],
        props.userDefined && styles[`timeslot--${props.planningType}--userDefined`],
      )}
      style={{ top: `${getTop(props.hourStart)}%`, bottom: `${getBottom(props.hourEnd)}%` }}
      onClick={() => props.onClick && props.onClick({ ...(props as any) })}
    >
      {(displayHours === DisplayedHour.BOTH || displayHours === DisplayedHour.START) && (
        <p className={clsx(styles.timeslot__hour, styles["timeslot__hour--start"])}>
          {props.hourStart?.replace(":", "h")}
        </p>
      )}
      {(displayHours === DisplayedHour.BOTH || displayHours === DisplayedHour.END) && (
        <p className={clsx(styles.timeslot__hour, styles["timeslot__hour--end"])}>{props.hourEnd?.replace(":", "h")}</p>
      )}
    </div>
  </Tooltip>
);
