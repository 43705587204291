import { useEffect, useState } from "react";

import { MagnifyingGlassIcon } from "@eisox/icons";
import { useControllableState } from "@eisox/tools";

import type { TextInputProps } from "../TextInput";
import { TextInput } from "../TextInput";

export interface SearchInputProps extends Omit<TextInputProps, "value" | "onChange"> {
  timeout?: number;
  value?: string;
  onChange?: (value: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ value, onChange, timeout = 500, ...props }) => {
  const [inputValue, setInputValue] = useControllableState<string>({
    prop: value,
    onChange: onChange,
  });
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value;
    setInputValue(value);

    if (typingTimeout) clearTimeout(typingTimeout);

    const timeoutId = setTimeout(() => onChange?.(value), timeout);

    setTypingTimeout(timeoutId);
  };

  useEffect(() => {
    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [typingTimeout]);

  return (
    <TextInput
      {...props}
      icon={<MagnifyingGlassIcon />}
      onChange={handleInputChange}
      value={inputValue}
      iconPosition="left"
    />
  );
};
