import clsx from "clsx";
import { FieldError, Merge } from "react-hook-form";

import { useBem } from "@eisox/tools";

import styles from "./Textarea.module.scss";

export interface TextAreaProps extends HTMLProps<"textarea"> {
  label?: string;
  error?: Merge<FieldError, (FieldError | undefined)[]> | FieldError | undefined;
}

export const Textarea: React.FC<TextAreaProps> = ({ className, error, label, ...props }) => {
  const bem = useBem(styles);
  const TextareaStyle = bem("textarea");
  return (
    <label className={clsx(TextareaStyle({ error: !!error }), className)}>
      {label && <span>{label}</span>}
      <textarea {...props} />
      {error?.message && <p className={TextareaStyle("error")}>{error.message}</p>}
    </label>
  );
};
