import * as React from "react";

import type { IconProps } from "./types";

export const OptionIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <ellipse
        cx="8"
        cy="2.66666"
        rx="2"
        ry="2"
        transform="rotate(180 8 2.66666)"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <circle cx="8" cy="8" r="2" transform="rotate(180 8 8)" fill={color} fillRule="evenodd" clipRule="evenodd" />
      <circle
        cx="8"
        cy="13.3333"
        r="2"
        transform="rotate(180 8 13.3333)"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default OptionIcon;
