import * as React from "react";

import { IconProps } from "./types";

export const LocationIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M8.00427 1.5C5.46547 1.5 3.4 3.55024 3.4 6.07032C3.4 6.92177 3.63761 7.7527 4.08729 8.47358L7.74217 14.32C7.81218 14.432 7.93551 14.5 8.06818 14.5C8.06918 14.5 8.07019 14.5 8.07123 14.5C8.20504 14.499 8.32862 14.4288 8.39734 14.3149L11.9591 8.41185C12.384 7.70617 12.6085 6.89649 12.6085 6.07032C12.6085 3.55024 10.543 1.5 8.00427 1.5ZM11.3005 8.02079L8.06237 13.3875L4.73951 8.07226C4.36521 7.47222 4.16226 6.77998 4.16226 6.07032C4.16226 3.97026 5.8886 2.25664 8.00427 2.25664C10.1199 2.25664 11.8437 3.97026 11.8437 6.07032C11.8437 6.75894 11.6541 7.4335 11.3005 8.02079Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M8.00426 3.78507C6.73486 3.78507 5.70213 4.8102 5.70213 6.07023C5.70213 7.32222 6.71806 8.3554 8.00426 8.3554C9.30632 8.3554 10.3064 7.30847 10.3064 6.07023C10.3064 4.8102 9.27362 3.78507 8.00426 3.78507ZM8.00426 7.59873C7.15356 7.59873 6.46438 6.91238 6.46438 6.07023C6.46438 5.23021 7.15799 4.54172 8.00426 4.54172C8.85052 4.54172 9.54158 5.23021 9.54158 6.07023C9.54158 6.90007 8.86842 7.59873 8.00426 7.59873Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default LocationIcon;
