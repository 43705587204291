import * as React from "react";

import { IconProps } from "./types";

export const MinusIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path d="M1.5 8H14.5" stroke={color} strokeLinecap="round" fillRule="evenodd" clipRule="evenodd" />
    </svg>
  ),
);

export default MinusIcon;
