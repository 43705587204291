import * as React from "react";

import { IconProps } from "./types";

export const ShiftIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M10.5 14.5001H5.49996C5.23482 14.4998 4.98062 14.3944 4.79313 14.2069C4.60565 14.0194 4.50021 13.7652 4.49994 13.5001V8.50001H1.9999C1.90102 8.49999 1.80437 8.47065 1.72217 8.41571C1.63996 8.36076 1.57589 8.28268 1.53806 8.19133C1.50022 8.09998 1.49032 7.99946 1.5096 7.90248C1.52889 7.8055 1.57649 7.71642 1.6464 7.64649L7.64649 1.6464C7.74026 1.55266 7.86742 1.5 8 1.5C8.13258 1.5 8.25974 1.55266 8.35351 1.6464L14.3536 7.64649C14.4235 7.71642 14.4711 7.8055 14.4904 7.90248C14.5097 7.99946 14.4998 8.09998 14.4619 8.19133C14.4241 8.28268 14.36 8.36076 14.2778 8.41571C14.1956 8.47065 14.099 8.49999 14.0001 8.50001H11.5001V13.5001C11.4997 13.7652 11.3942 14.0193 11.2067 14.2068C11.0193 14.3942 10.7651 14.4997 10.5 14.5001ZM3.20692 7.49999H5.49996V13.5001H10.5V7.49999H12.7931L8 2.70691L3.20692 7.49999Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default ShiftIcon;
