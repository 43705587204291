import { forwardRef } from "react";

import clsx from "clsx";
import { useIntl } from "react-intl";

import { TargetIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import { ValveCommand } from "~/socketio/types";

import styles from "./SetpointTemperature.module.scss";

interface SetpointTemperatureProps extends React.ComponentPropsWithRef<"div"> {
  setpointTemperature?: number;
  command?: ValveCommand;
  className?: string;
}

export const SetpointTemperature = forwardRef<HTMLDivElement, SetpointTemperatureProps>(
  ({ setpointTemperature, command = ValveCommand.AUTO, className, ...props }, forwardedRef) => {
    const { formatMessage } = useIntl();

    const bem = useBem(styles);
    const setpointTemperatureStyle = bem("setpoint-temperature");

    return (
      <Tooltip
        keepHoverOpen={false}
        content={formatMessage({ id: `boilerroom.setPointTemperature.tooltip.${command}` })}
      >
        <div
          {...props}
          ref={forwardedRef}
          className={clsx(setpointTemperatureStyle({ clickable: !!props.onClick }), className)}
        >
          <TargetIcon />
          {`${setpointTemperature?.toFixed(1) ?? "-"}°C`}
        </div>
      </Tooltip>
    );
  },
);
