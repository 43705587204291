import clsx from "clsx";

import { useBem } from "@eisox/tools";

import styles from "./Temperature.module.scss";

interface TemperatureProps {
  type: "starting" | "returning";
  orientation?: "horizontal" | "vertical";
  value?: number;
  className?: string;
}

export const Temperature: React.FC<TemperatureProps> = ({ type, orientation = "horizontal", value, className }) => {
  const bem = useBem(styles);
  const temperatureStyle = bem("temperature");

  return <p className={clsx(temperatureStyle({ type, orientation }), className)}>{`${value?.toFixed(1) ?? "-"}°C`}</p>;
};
