import { useMemo, useState } from "react";

import { cx } from "class-variance-authority";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ActionButtonV2 as ActionButton,
  ButtonV2 as Button,
  DrawerV2 as Drawer,
  SelectV2 as Select,
  TextField,
  Typography,
} from "@eisox/design-system";
import { MagnifyingGlassIcon, PlusIcon } from "@eisox/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FieldContainer } from "~/UI";
import type { Creators, RolesInHouses, UpdateRolesRequestBody } from "~/apiV2";
import { queries, udapteRoles } from "~/apiV2";
import { routeToDashboard } from "~/routes/utils";
import { UserRole } from "~/utils";

import { addUserSchema, getUpdateRoleRequestBody } from "../../helpers";
import type { AddUserSchemaType } from "../../helpers";
import { OwnerChangeDialog } from "../OwnerChangeDialog";

import styles from "./AddUserDrawer.module.scss";

interface AddUserDrawer {
  boilerRoom: boolean;
  houseId: string;
  connectedUserRole: UserRole;
  rolesInHouse: RolesInHouses;
  creators: Creators;
}

const AddUserDrawer: React.FC<AddUserDrawer> = ({ boilerRoom, houseId, connectedUserRole, rolesInHouse, creators }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate: addUser } = useMutation({
    mutationFn: ({ email, body }: { email: string; body: UpdateRolesRequestBody }) => udapteRoles(email, body),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queries.user.roles.queryKey }),
        queryClient.invalidateQueries({ queryKey: queries.user.rolesInHouses.queryKey }),
        queryClient.invalidateQueries({ queryKey: queries.user.houseUsers(houseId).queryKey }),
      ]);
      if (ownerChangeDialogOpen) navigate(routeToDashboard(houseId));
      setOwnerChangeDialogOpen(false);
      handleOpenChange(false);
    },
    onError: () => toast.error(t("error.unKnowError.message")),
  });

  const existingEmails = useMemo(() => {
    const house = rolesInHouse.find(h => h._id === houseId);
    if (!house?.users) return [];
    return house.users.flatMap(u => u.emails?.[0]?.address ?? []);
  }, [houseId, rolesInHouse]);

  const {
    control,
    formState: { errors, isDirty },

    register,
    handleSubmit,
    reset,
    watch,
  } = useForm<AddUserSchemaType>({
    resolver: zodResolver(addUserSchema(existingEmails, rolesInHouse)),
    defaultValues: {
      houseIds: [],
      email: "",
      confirmEmail: "",
    },
  });

  const email = watch("email");
  const role = watch("role");

  const [open, setOpen] = useState(false);
  const [ownerChangeDialogOpen, setOwnerChangeDialogOpen] = useState(false);
  const [search, setSearch] = useState("");

  const userRoleOptions = useMemo(() => {
    const userRoleOptions = [UserRole.MANAGER, UserRole.VISITOR];
    if (
      connectedUserRole === UserRole.OWNER ||
      (connectedUserRole === UserRole.INSTALLER &&
        !rolesInHouse.find(r => r._id === houseId)?.users?.some(u => u.roles?.owner?.includes(houseId)))
    ) {
      userRoleOptions.push(UserRole.OWNER);
    }
    if (creators.find(c => c.email?.toUpperCase() === email.toUpperCase())) {
      userRoleOptions.push(UserRole.INSTALLER);
    }
    return userRoleOptions.map(o => ({ value: o, name: t(`users.role.${o}`) }));
  }, [connectedUserRole, creators, email, houseId, rolesInHouse, t]);

  const houseIdsOptions = useMemo(
    () =>
      rolesInHouse
        .filter(r => r._id !== houseId && r.houseName?.toLowerCase().includes(search.toLowerCase()))
        .sort((a, b) => a.houseName!.localeCompare(b.houseName!))
        .map(r => ({ value: r._id!, name: r.houseName! })),
    [houseId, rolesInHouse, search],
  );

  const handleOpenChange = (open: boolean) => {
    if (!open) reset();
    setOpen(open);
  };

  const onSubmit = (data: AddUserSchemaType) => {
    if (data.role === UserRole.OWNER && !ownerChangeDialogOpen && connectedUserRole === UserRole.OWNER) {
      setOwnerChangeDialogOpen(true);
      return;
    }
    const email = data.email;
    const body: UpdateRolesRequestBody = getUpdateRoleRequestBody({ [data.role]: true }, [...data.houseIds, houseId]);
    addUser({ email, body });
  };

  return (
    <>
      <Drawer.Root open={open} onOpenChange={handleOpenChange}>
        <Drawer.Trigger asChild>
          <Button>
            {t("users.add.button")}
            <PlusIcon />
          </Button>
        </Drawer.Trigger>
        <Drawer.Content>
          <Drawer.Header>{t("users.add.title")}</Drawer.Header>
          <form className={styles.addUserDrawer} onSubmit={handleSubmit(onSubmit)}>
            <FieldContainer label={t("users.add.email.label")} error={errors.email}>
              <TextField.Root {...register("email")} placeholder={t("users.add.email.placeholder")} />
            </FieldContainer>
            <FieldContainer label={t("users.add.confirmEmail.label")} error={errors.confirmEmail}>
              <TextField.Root {...register("confirmEmail")} placeholder={t("users.add.confirmEmail.placeholder")} />
            </FieldContainer>
            <div className={styles.addUserDrawer__role}>
              <FieldContainer label={t("users.add.role.label")} error={errors.role}>
                <Controller
                  control={control}
                  name="role"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      placeholder={t("users.add.role.placeholder")}
                      options={userRoleOptions}
                      value={value}
                      onChange={onChange}
                      renderValue={value => (
                        <Typography>
                          {t(
                            value
                              ? value === UserRole.MANAGER_BOILERROOM
                                ? "users.role.manager"
                                : `users.role.${value as string}`
                              : "users.add.role.placeholder",
                          )}
                        </Typography>
                      )}
                    />
                  )}
                />
              </FieldContainer>
              {boilerRoom && (
                <FieldContainer label="option" style={{ label: { color: "transparent" } }}>
                  <Controller
                    control={control}
                    name="role"
                    render={({ field: { value, onChange } }) => (
                      <Select
                        placeholder={t("users.add.role.options.placeholder")}
                        options={[{ value: UserRole.MANAGER_BOILERROOM, name: t("users.role.managerBoilerRoom") }]}
                        value={[value]}
                        onChange={value =>
                          onChange(
                            Array.isArray(value) && value.length ? UserRole.MANAGER_BOILERROOM : UserRole.MANAGER,
                          )
                        }
                        renderValue={value => (
                          <Typography
                            className={cx(
                              styles.addUserDrawer__option,
                              !value?.includes(UserRole.MANAGER_BOILERROOM) && styles.addUserDrawer__option_placeholder,
                            )}
                          >
                            {t(
                              Array.isArray(value) && (value[0] as UserRole) === UserRole.MANAGER_BOILERROOM
                                ? "users.role.managerBoilerRoom"
                                : "users.add.role.options.placeholder",
                            )}
                          </Typography>
                        )}
                        disabled={!(role === UserRole.MANAGER || role === UserRole.MANAGER_BOILERROOM)}
                      />
                    )}
                  />
                </FieldContainer>
              )}
            </div>
            <FieldContainer
              classNames={{ container: styles.otherBuildings }}
              label={t("users.add.otherBuildings.label")}
              error={errors.houseIds}
            >
              <Controller
                control={control}
                name="houseIds"
                render={({ field: { value, onChange } }) => (
                  <Select
                    placeholder={t("users.add.otherBuildings.placeholder")}
                    options={houseIdsOptions}
                    value={value}
                    onChange={onChange}
                    unscrollableContent={
                      <TextField.Root
                        placeholder={t("users.add.search.placeholder")}
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                      >
                        <TextField.Slot>
                          <MagnifyingGlassIcon />
                        </TextField.Slot>
                      </TextField.Root>
                    }
                  />
                )}
              />
            </FieldContainer>
            <ActionButton className={styles.addUserDrawer__button} rounded type="submit" disabled={!isDirty}>
              {t("users.add.save")} <PlusIcon />
            </ActionButton>
          </form>
        </Drawer.Content>
      </Drawer.Root>
      {ownerChangeDialogOpen && (
        <OwnerChangeDialog open onOpenChange={setOwnerChangeDialogOpen} onSubmit={() => handleSubmit(onSubmit)()} />
      )}
    </>
  );
};

export { AddUserDrawer };
