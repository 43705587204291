import type React from "react";

import { useIntl } from "react-intl";

import { ButtonV2 } from "@eisox/design-system";
import { PlusIcon } from "@eisox/icons";

import type { HousePosition } from "../../helpers";
import { Combobox } from "../Combobox";

import styles from "./Header.module.scss";

interface HeaderProps {
  isCreator: boolean;
  housePositions: HousePosition[];
  onComboboxChange: (housePositions: HousePosition[]) => void;
  onAddHouseButtonClick: VoidFunction;
}

export const Header: React.FC<HeaderProps> = ({
  isCreator,
  housePositions,
  onComboboxChange,
  onAddHouseButtonClick,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.header}>
      <Combobox housePositions={housePositions} onChange={onComboboxChange} />
      {isCreator && (
        <ButtonV2 className={styles.header__button} onClick={onAddHouseButtonClick}>
          {formatMessage({ id: "houses.add" })}
          <PlusIcon />
        </ButtonV2>
      )}
    </div>
  );
};
