import { useState } from "react";

import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import type { HousesHouseIdBoilerroomsPosGet200ResponseMessageInner, Module } from "@eisox/backend_webapp_api";
import { PostModuleTypeEnum } from "@eisox/backend_webapp_api";
import { ActionButtonV2, ButtonV2, Modal, Radio, Typography } from "@eisox/design-system";
import { ArrowRightIcon, BracesIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { GatewaysWithProblem } from "~/UI/screens/House";
import { routeToBoilerrooms } from "~/routes/utils";

import { useBoilerRoomRealTimeProviderContext } from "../../providers";

import styles from "./ResetVariablesDialog.module.scss";

const NAME = "ResetVariablesDialog";

interface ResetVariablesDialogProps {
  houseId: string;
  boilerroomPos: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[];
  gateways: GatewaysWithProblem[];
  modules: Module[];
  children?: React.ReactNode;
}

const ResetVariablesDialog: React.FC<ResetVariablesDialogProps> = ({
  houseId,
  boilerroomPos,
  gateways,
  modules,
  children,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const resetVariablesDialogStyle = bem("reset-variables-dialog");

  const navigate = useNavigate();

  const { useResetVariables } = useBoilerRoomRealTimeProviderContext(NAME);
  const { mutate } = useResetVariables({
    onSuccess: () => {
      handleOpenChange(false);
      navigate(routeToBoilerrooms(houseId));
    },
  });

  const [open, setOpen] = useState(false);
  const [selectedGateway, setSelectedGateway] = useState<GatewaysWithProblem>();

  const handleClickGateway = (e: React.MouseEvent<HTMLButtonElement>, gateway: GatewaysWithProblem) => {
    e.preventDefault();
    setSelectedGateway(gateway);
  };

  const handleInitModules = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const modulesToReset = modules.filter(
      m => m.gatewayId === selectedGateway?.id && m.type === PostModuleTypeEnum.EclypseBoilerRoom,
    );
    const modulesToResetIds = modulesToReset.map(m => m.id) as string[];
    mutate(modulesToResetIds);
  };

  const name = (gateway: GatewaysWithProblem): string => {
    const associatedModules = modules.filter(m => m.gatewayId === gateway.id);
    const boilerroomNames = associatedModules
      .map(am =>
        formatMessage(
          { id: "boilerRoom.header.actions.resetVariables.on" },
          {
            bn: boilerroomPos
              .find(b => b.moduleId === am.id)
              ?.boilerRooms?.map(br => br.name)
              .join(", "),
            ip: am.url,
          },
        ),
      )
      .join(", ");
    return `${gateway.gatewayName} (${boilerroomNames})`;
  };

  const handleOpenChange = (open: boolean) => {
    setSelectedGateway(undefined);
    setOpen(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={resetVariablesDialogStyle()}>
        <Modal.Header
          title={formatMessage({ id: "boilerRoom.header.actions.resetVariables.title" })}
          subtitle={formatMessage({ id: "boilerRoom.header.actions.resetVariables.subtitle" })}
          icon={<BracesIcon />}
        >
          <Modal.Close>
            <ActionButtonV2 variant="cancel">
              {formatMessage({ id: "boilerRoom.header.actions.resetVariables.cancel" })}
            </ActionButtonV2>
          </Modal.Close>
          <ActionButtonV2 rounded disabled={!selectedGateway} onClick={handleInitModules}>
            {formatMessage({ id: "boilerRoom.header.actions.resetVariables.init" })}
            <ArrowRightIcon />
          </ActionButtonV2>
        </Modal.Header>
        <p className={resetVariablesDialogStyle("note")}>
          {formatMessage({ id: "boilerRoom.header.actions.resetVariables.note" })}
        </p>
        <h4 className={resetVariablesDialogStyle("title")}>
          {formatMessage({ id: "boilerRoom.header.actions.resetVariables.gateway" })}
        </h4>
        <div className={resetVariablesDialogStyle("modules")}>
          {gateways
            .filter(g => modules.map(m => m.gatewayId).includes(g.id))
            .map((gateway, index) => (
              <ButtonV2
                key={index}
                className={resetVariablesDialogStyle("module", { selected: selectedGateway?.id === gateway.id })}
                onClick={e => handleClickGateway(e, gateway)}
              >
                <Radio checked={selectedGateway?.id === gateway.id} />
                <Typography>{name(gateway)}</Typography>
              </ButtonV2>
            ))}
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};

ResetVariablesDialog.displayName = NAME;

export { ResetVariablesDialog };
export type { ResetVariablesDialogProps };
