import React from "react";

import { useIntl } from "react-intl";
import type { TooltipProps } from "recharts";
import {
  Bar,
  CartesianGrid,
  Legend,
  BarChart as RCBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import type { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";

import { chart } from "@eisox/colors";
import type { Dayjs } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import { Card, Typography } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { View } from "../../types";

import styles from "./BarChart.module.scss";

const getPrevHour = (view: View, currentDate: Dayjs): string | false => {
  if (view === View.HOUR) {
    const prevHour = currentDate.subtract(15, "minutes");
    if (prevHour.isSame(currentDate, "day")) {
      return prevHour.format("HH[h]mm");
    }
  }
  return false;
};

const getStringDate = (view: View, date: string, dates: string[]): string => {
  switch (view) {
    case View.HOUR:
      return `${dates[0]}T${date}`;
    case View.DAY:
      return `${dates[0]}-${date}`;
    case View.MONTH:
      return dayjs(`${date} ${dates[0]}`, "MMM YYYY").format("YYYY-MM");
    case View.YEAR:
      return date;
  }
};

const getDisplayedDate = (formatMessage: FormatMessageFn, date: string, view: View, dates: string[]): string => {
  if (dates.length > 1) return date;
  const stringDate = getStringDate(view, date, dates);
  const currentDate = dayjs(stringDate);
  switch (view) {
    case View.HOUR:
    case View.DAY:
      const hour = view === View.DAY ? false : currentDate.format("HH[h]mm");
      const prevHour = getPrevHour(view, currentDate);
      if (currentDate.isToday()) {
        return `${formatMessage({ id: "consumption.barChart.tooltip.date.today" }, { h1: prevHour, h2: hour })}`;
      } else if (currentDate.isYesterday()) {
        return `${formatMessage({ id: "consumption.barChart.tooltip.date.yesterday" }, { h1: prevHour, h2: hour })}`;
      } else {
        return `${formatMessage({ id: "consumption.barChart.tooltip.date.other" }, { date: currentDate.format("DD/MM/YYYY"), h1: prevHour, h2: hour })}`;
      }
    case View.MONTH:
      const formatedDate = currentDate.format("MMM YYYY");
      return formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);
    case View.YEAR:
      return currentDate.format("YYYY");
  }
};

const CustomTooltip = ({
  payload,
  label,
  view,
  dates,
}: TooltipProps<ValueType, NameType> & { view: View; dates: string[] }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const tooltipStyle = bem("tooltip");
  const valueStyle = bem("value");

  if (!payload || payload.length <= 0) return null;

  const displayedDate = getDisplayedDate(formatMessage, label, view, dates);

  return (
    <Card className={tooltipStyle()}>
      <p className={tooltipStyle("date")}>{displayedDate}</p>
      {payload.map(p => (
        <div className={valueStyle()}>
          <div className={valueStyle("legend")}>
            <span className={valueStyle("color")} style={{ backgroundColor: p.color }} />
            <Typography className={valueStyle("label")}>{formatMessage({ id: `${p.dataKey}` })}</Typography>
          </div>
          <Typography className={valueStyle("value")}>{`${p.value} ${p.unit}`}</Typography>
        </div>
      ))}
    </Card>
  );
};

const CustomLegend = ({
  payload,
  layout,
  title,
}: {
  payload: Payload<ValueType, NameType>[];
  layout: "vertical" | "horizontal";
  title?: string;
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const legendStyle = bem("legend");
  const labelStyle = bem("label");

  return (
    <div className={legendStyle({ layout })}>
      {payload.map(p => (
        <div className={labelStyle()}>
          <span className={labelStyle("color")} style={{ backgroundColor: p.color }} />
          <Typography className={labelStyle("text")}>
            {`${formatMessage({ id: `${p.dataKey}` })} ${p.payload.unit ? `(${p.payload.unit})` : ""}`}
          </Typography>
        </div>
      ))}
      {title && <Typography className={legendStyle("title")}>{title}</Typography>}
    </div>
  );
};

interface BarChartProps {
  data: Record<string, number | string>[];
  unitAxesMapping: Record<string, string[]>;
  view: View;
  dates: string[];
  title?: string;
}

const BarChart: React.FC<BarChartProps> = ({ data, unitAxesMapping, view, dates, title }) => {
  const gray = "#f3f3f9";
  const darkBlueColor = "#122138";
  const axisStyle = { fontWeight: 400, fill: darkBlueColor };
  const keys = Object.values(unitAxesMapping).flat();
  const defaultColors = Object.values(chart);
  const colors: Record<string, string> = keys.reduce((acc, k, i) => ({ ...acc, [k]: defaultColors[i] }), {});

  return (
    <ResponsiveContainer>
      <RCBarChart data={data}>
        <Tooltip content={<CustomTooltip view={view} dates={dates} />} />
        {/* @ts-ignore : Payload is absent from type definition */}
        <Legend content={<CustomLegend title={title} />} layout="vertical" />
        <CartesianGrid vertical={false} horizontal stroke={gray} />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tickMargin={16}
          style={axisStyle}
          padding={{ left: 40, right: 10 }}
        />
        {Object.entries(unitAxesMapping).map(([unit, value], index) => (
          <React.Fragment key={index}>
            <YAxis
              yAxisId={unit}
              orientation={index % 2 === 0 ? "left" : "right"}
              axisLine={false}
              tickLine={false}
              tick={{ fill: colors[value[0]] }}
              domain={[0, "dataMax"]}
            />
            {value.map(v => (
              <Bar unit={unit} yAxisId={unit} dataKey={v} fill={colors[v]} />
            ))}
          </React.Fragment>
        ))}
      </RCBarChart>
    </ResponsiveContainer>
  );
};

export { BarChart };
