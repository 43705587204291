import { ActionFunctionArgs } from "react-router-dom";

import { PostModule, PostModuleByHouseId200ResponseMessage } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { createModule } from "~/api/modules";
import { getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { API } from "~/types/API";

export const action = async ({
  request,
  params,
}: ActionFunctionArgs): Promise<API.Response<PostModuleByHouseId200ResponseMessage | null> | Response> => {
  const { houseId } = params as { houseId: string };
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.POST:
      const body: PostModule = JSON.parse(formData.get("json") as string);
      const postReponse: any = await createModule(houseId, body);
      const postStatus = getHttpStatusByFetchStatus(postReponse.type) as unknown as API.HTTP_STATUS;
      return {
        date: dayjs(),
        restError: postReponse.type,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [{ status: postStatus, message: postReponse.result?.message }],
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
