import { useEffect, useState } from "react";

import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";

import { Dropdown, Radio, Select } from "@eisox/design-system";
import { OptionIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { PlanV2 } from "~/UI";
import type { ItemType } from "~/UI/layouts/PlanV2/components";
import type { houseLoader } from "~/UI/screens";
import { getPlan } from "~/api/plan";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";

import styles from "./Plan.module.scss";

export interface ItemOnPlan extends ItemType {
  planId?: string;
}

export type PlanFilters = Record<
  string,
  {
    selected: boolean;
    onClick: VoidFunction;
  }
>;

interface PlanProps {
  currentItemId?: string;
  items: ItemOnPlan[];
  filters?: PlanFilters;
  onChange: (items: ItemType[]) => void;
}

export const Plan: React.FC<PlanProps> = ({ currentItemId, items, filters, onChange }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const planStyle = bem("plan");
  const controlStyle = bem("control");
  const dropdownMenuStyle = bem("dropdown-menu");

  const { plans } = useRouteLoaderData("house") as LoaderData<ReturnType<typeof houseLoader>>;
  const { houseId } = useParams() as { houseId: string };

  const currentItemPlanId = items.find(i => i.id === currentItemId)?.planId;

  const [plan, setPlan] = useState(plans.find(p => p.id === currentItemPlanId) ?? plans[0]);
  const [imgUrl, setImgUrl] = useState<string>("");

  useEffect(() => {
    const newPlan = plans.find(p => p.id === currentItemPlanId);
    newPlan && setPlan(newPlan);
  }, [currentItemPlanId]);

  useEffect(() => {
    // update plan image when plan change
    getPlan(plan.id, houseId).then(response => response.type === SUCCESS_FETCH && setImgUrl(response.result.message));
  }, [plan.id]);

  const handleChangePlan = (value: string) => {
    const newPlan = plans.find(p => p.id === value);
    newPlan && setPlan(newPlan);
  };

  const planItems: ItemType[] = items.filter(i => i.planId === plan.id);

  const menu: React.ReactElement[] = filters ? [] : [];

  return (
    <div className={planStyle()}>
      <h2 className={planStyle("title")}>{formatMessage({ id: "plan.actions.move.plan.level" })}</h2>
      <div className={controlStyle()}>
        <Select
          triggerClassName={controlStyle("select")}
          value={plan.id}
          options={plans.map(p => {
            return {
              name: p.name ?? "--",
              value: p.id!,
            };
          })}
          renderValue={(value?: string) => <p>{plans.find(p => p.id === value)?.name}</p>}
          onChange={handleChangePlan}
        />
        {filters && (
          <Dropdown.Root>
            <Dropdown.Trigger asChild>
              <OptionIcon className={controlStyle("trigger")} />
            </Dropdown.Trigger>
            <Dropdown.Content align="end" sideOffset={10} style={{ zIndex: 2000 }}>
              <p className={dropdownMenuStyle("title")}>
                {formatMessage({ id: "plan.actions.move.plan.filters.title" })}
              </p>
              {...Object.entries(filters).map(([v, { selected, onClick }], i) => (
                <div key={i} className={dropdownMenuStyle("item", { selected: selected })} onClick={() => onClick()}>
                  <Radio multiple checked={selected} />
                  <p>{formatMessage({ id: `plan.actions.move.plan.filters.${v}` })}</p>
                </div>
              ))}
            </Dropdown.Content>
          </Dropdown.Root>
        )}
      </div>
      <PlanV2 className={planStyle("plan")} planUrl={imgUrl} items={planItems} onChange={onChange} />
    </div>
  );
};
