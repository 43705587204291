import * as React from "react";

import { IconProps } from "./types";

export const LeafIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M10.1473 7.56888C10.1023 7.52102 10.0482 7.4825 9.98827 7.45554C9.92837 7.42857 9.86368 7.41367 9.79802 7.41169C9.73228 7.40972 9.66686 7.4207 9.60538 7.44402C9.54393 7.46735 9.48767 7.50255 9.43982 7.54761C7.13684 9.71703 4.82604 11.0369 2.10675 11.7098C1.73986 10.7089 1.70734 9.60725 2.02418 8.53255C2.44455 7.10659 3.42355 5.86424 4.85451 4.93998C6.13189 4.11488 7.40864 3.9 8.6438 3.69232C10.2021 3.43021 11.674 3.18249 13.0787 1.69523C13.2351 1.53071 13.4481 1.46471 13.6517 1.5182C13.8844 1.58075 14.0405 1.76466 14.1152 2.06869C14.2904 2.78025 14.1777 4.77453 13.6851 6.87983C12.8941 10.2622 11.6318 11.9738 10.7119 12.8142C9.52334 13.8992 7.97133 14.5 6.42902 14.5C6.13831 14.5005 5.84797 14.4792 5.56044 14.4363C4.25585 14.2386 3.16988 13.5833 2.55778 12.6299C5.35682 11.9018 7.74801 10.5171 10.127 8.27732C10.1749 8.23219 10.2135 8.17804 10.2405 8.11798C10.2674 8.05793 10.2823 7.99313 10.2842 7.92732C10.2861 7.86151 10.2749 7.79597 10.2515 7.73446C10.228 7.67296 10.1926 7.61669 10.1473 7.56888Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default LeafIcon;
