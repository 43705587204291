import * as React from "react";

import type { IconProps } from "./types";

export const TargetIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M8.5 10.5C9.60457 10.5 10.5 9.60457 10.5 8.5C10.5 7.39543 9.60457 6.5 8.5 6.5C7.39543 6.5 6.5 7.39543 6.5 8.5C6.5 9.60457 7.39543 10.5 8.5 10.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M9.16668 3.21267V1.83334H7.83334V3.21267C6.66012 3.36224 5.56978 3.89716 4.73347 4.73347C3.89717 5.56977 3.36225 6.66012 3.21268 7.83334H1.83334V9.16667H3.21268C3.36214 10.3399 3.89702 11.4303 4.73335 12.2667C5.56968 13.103 6.66008 13.6379 7.83334 13.7873V15.1667H9.16668V13.7873C10.3399 13.6379 11.4303 13.103 12.2667 12.2667C13.103 11.4303 13.6379 10.3399 13.7873 9.16667H15.1667V7.83334H13.7873C13.6378 6.66012 13.1029 5.56977 12.2665 4.73347C11.4302 3.89716 10.3399 3.36224 9.16668 3.21267ZM8.50001 12.5C6.29401 12.5 4.50001 10.706 4.50001 8.5C4.50001 6.294 6.29401 4.5 8.50001 4.5C10.706 4.5 12.5 6.294 12.5 8.5C12.5 10.706 10.706 12.5 8.50001 12.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default TargetIcon;
