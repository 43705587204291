import { merge } from "lodash";

import type { Permission } from "..";
import { ownerPermissions } from "./owner";

export const installerPermissions: Permission = merge<Permission, Permission>(
  {
    valve: {
      delete: true,
      gatewayId: { update: true },
      plan: { update: true },
      heatingNetworkId: { update: true, create: true },
      stateData: {
        dateBat: { read: true },
      },
    },
    history: {
      valve: {
        histoValve: {
          hygrometry: { read: true },
          pressure: { read: true },
          gazRaw: { read: true },
          soundCounter: { read: true },
          rssi: { read: true },
          lqi: { read: true },
          bvoc: { read: true },
        },
        battery: {
          read: true,
        },
        meca: {
          read: true,
        },
      },
      gateway: {
        lastGatewayActivity: {
          read: true,
        },
      },
    },
    gateway: {
      delete: true,
      plan: { update: true },
      brModRf: { read: true, update: true },
      isInternetGateway: { update: true },
      borderRouterStatus: { read: true },
      meshs: { read: true },
    },
    plan: {
      create: true,
      delete: true,
    },
    room: {
      update: true,
      warning: true,
      delete: true,
      plan: { update: true },
    },
    module: {
      create: true,
      update: true,
      delete: true,
      read: true,
      boilerroom: {
        update: true,
        variableData: {
          read: true,
          update: true,
        },
        plan: { update: true },
      },
    },
    house: {
      delete: false,
    },
  },
  ownerPermissions,
);
