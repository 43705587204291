import { produce } from "immer";
import { utils, writeFile } from "xlsx";

import type { HistovalvesStatsPost200ResponseMessageInner } from "@eisox/backend_webapp_api";
import { VALVE_HARDWARE_VERSION_ENUM } from "@eisox/valves";
import { PostHistovalvesStatsFields } from "@eisox/webapp-api-specification";

import type { ValvesWithProblem } from "~/UI/screens/House";
import type { Permission, WebappRolePermission } from "~/utils/User";
import {
  isValveHardwareIsUpper,
  openning,
  transformCorrectedTemp,
  transformHumidity,
  transformLight,
  transformTemperature,
} from "~/utils/Valve";

export const DATA_TYPE = Object.values(PostHistovalvesStatsFields).filter(
  v =>
    ![
      "isSwOffsetUsed",
      "swOffset",
      "IRCounter",
      "voltage",
      "soh",
      "rebootCounter",
      "anticipation",
      "currentPos",
    ].includes(v),
);

type PartialChartDataType = Partial<Record<PostHistovalvesStatsFields, number>>;

export type ChartDataType = PartialChartDataType & { date: number };

const convertData = (
  data?: HistovalvesStatsPost200ResponseMessageInner[],
  keys?: PostHistovalvesStatsFields[],
): ChartDataType[] => {
  return (
    data?.flatMap(
      d =>
        d.results?.buckets?.map(bucket => {
          const chartData: ChartDataType & { date: number } = { date: d.key! };

          Object.entries(PostHistovalvesStatsFields).forEach(([_, fieldName]) => {
            //@ts-ignore
            if (keys?.includes(fieldName) && bucket[fieldName as keyof typeof bucket]?.value !== undefined) {
              //@ts-ignore
              chartData[fieldName as PostHistovalvesStatsFields] = bucket[fieldName as keyof typeof bucket]?.value;
            }
          });

          return chartData;
        }) ?? [],
    ) ?? []
  );
};

export const transformData = (
  data?: HistovalvesStatsPost200ResponseMessageInner[],
  keys?: PostHistovalvesStatsFields[],
  role?: WebappRolePermission,
): ChartDataType[] => {
  const convertedData = convertData(data, keys);

  return produce(convertedData, draft => {
    draft.forEach(item => {
      // temperature
      [
        PostHistovalvesStatsFields.temperature,
        PostHistovalvesStatsFields.externalTemp,
        PostHistovalvesStatsFields.instructionTemp,
      ].forEach(key => {
        if (item[key] !== undefined && typeof item[key] === "number") {
          item[key] = transformTemperature(item[key]);
        }
      });
      // correctedTemp
      if (
        item[PostHistovalvesStatsFields.correctedTemp] !== undefined &&
        typeof item[PostHistovalvesStatsFields.correctedTemp] === "number"
      ) {
        item[PostHistovalvesStatsFields.correctedTemp] = transformCorrectedTemp(
          item[PostHistovalvesStatsFields.correctedTemp],
          role,
        );
      }
      // humidity
      if (
        item[PostHistovalvesStatsFields.humidity] !== undefined &&
        typeof item[PostHistovalvesStatsFields.humidity] === "number"
      ) {
        item[PostHistovalvesStatsFields.humidity] = transformHumidity(item[PostHistovalvesStatsFields.humidity]);
      }
      // light
      if (
        item[PostHistovalvesStatsFields.light] !== undefined &&
        typeof item[PostHistovalvesStatsFields.light] === "number"
      ) {
        item[PostHistovalvesStatsFields.light] = transformLight(item[PostHistovalvesStatsFields.light]);
      }
      // openning
      if (
        item[PostHistovalvesStatsFields.closing] !== undefined &&
        typeof item[PostHistovalvesStatsFields.closing] === "number"
      ) {
        item[PostHistovalvesStatsFields.closing] = openning(item[PostHistovalvesStatsFields.closing]);
      }

      // round values
      Object.entries(item).forEach(([key, value]) => {
        if (typeof value === "number") {
          item[key as PostHistovalvesStatsFields] = parseFloat(value.toFixed(1));
        }
      });
    });
  });
};

export const formatUnit = (key: PostHistovalvesStatsFields): string => {
  switch (key) {
    case PostHistovalvesStatsFields.temperature:
    case PostHistovalvesStatsFields.correctedTemp:
    case PostHistovalvesStatsFields.instructionTemp:
    case PostHistovalvesStatsFields.externalTemp:
      return "°C";
    case PostHistovalvesStatsFields.hygrometry:
      return "g/m3";
    case PostHistovalvesStatsFields.closing:
    case PostHistovalvesStatsFields.humidity:
      return "%";
    case PostHistovalvesStatsFields.pressure:
      return "hPa";
    case PostHistovalvesStatsFields.light:
      return "Lux";
    case PostHistovalvesStatsFields.gazRaw:
    case PostHistovalvesStatsFields.batZ:
      return "Ohms";
    case PostHistovalvesStatsFields.indoorAirQuality:
      return "Index (0-500)";
    case PostHistovalvesStatsFields.soundCounter:
      return "Compteur";
    case PostHistovalvesStatsFields.rssi:
      return "dBm";
    case PostHistovalvesStatsFields.lqi:
      return "Index 0-255";
    case PostHistovalvesStatsFields.co2:
    case PostHistovalvesStatsFields.bvoc:
      return "ppm";
    default:
      return "";
  }
};

export const getUnitAxesMapping = (keys: PostHistovalvesStatsFields[]) => {
  const unitAxesMapping: Record<string, string[]> = {};
  keys.forEach(key => {
    const unit = formatUnit(key);
    if (!unitAxesMapping[unit]) {
      unitAxesMapping[unit] = [];
    }
    unitAxesMapping[unit].push(key);
  });

  return unitAxesMapping;
};

export const exportData = (data: Record<string, string | number>[], filename: string, format: "csv" | "xlsx") => {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, filename);
  writeFile(workbook, `${filename}.${format}`);
};

export const getDataTypeByPermission = (permissions: Permission, mac: string, valves: ValvesWithProblem[]) => {
  return DATA_TYPE.filter(dataType => {
    switch (dataType) {
      //! DELETE THIS AFTER BACK END DELETE THIS FIELD
      case PostHistovalvesStatsFields.batZ:
      case PostHistovalvesStatsFields.siaq:
        return false;
      case PostHistovalvesStatsFields.co2:
        return isValveHardwareIsUpper(
          valves.find(v => v.hardwareVersion && v.mac === mac)?.hardwareVersion,
          VALVE_HARDWARE_VERSION_ENUM.V5,
        );
      case PostHistovalvesStatsFields.hygrometry:
        return permissions.history?.valve.histoValve?.hygrometry?.read;
      case PostHistovalvesStatsFields.pressure:
        return permissions.history?.valve.histoValve?.pressure?.read;
      case PostHistovalvesStatsFields.gazRaw:
        return permissions.history?.valve.histoValve?.gazRaw?.read;
      case PostHistovalvesStatsFields.soundCounter:
        return permissions.history?.valve.histoValve?.soundCounter?.read;
      case PostHistovalvesStatsFields.rssi:
        return permissions.history?.valve.histoValve?.rssi?.read;
      case PostHistovalvesStatsFields.lqi:
        return permissions.history?.valve.histoValve?.lqi?.read;
      case PostHistovalvesStatsFields.bvoc:
        return permissions.history?.valve.histoValve?.bvoc?.read;
      default:
        return true;
    }
  });
};
