import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { darkBlueColor, whiteColor } from "../color";

interface HeaderProps {
  title: string;
}

const styles = StyleSheet.create({
  header: {
    width: "100%",
    backgroundColor: darkBlueColor,
    padding: "10 20",
    display: "flex",
    gap: 10,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    color: whiteColor,
  },
  beforeElement: {
    width: 5,
    height: "100%",
    backgroundColor: whiteColor,
  },
  title: {
    fontSize: 28,
    fontWeight: 600,
  },
});

export const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <View style={styles.header}>
      <View style={styles.beforeElement} />
      <Text style={styles.title}>{title}</Text>
    </View>
  );
};
