import type {
  HousesNotifyIdHousePost200Response,
  LastGatewayActivity200Response,
  PostHistoBox,
  PostNotif,
} from "@eisox/backend_webapp_api";

import { env } from "~/configuration";
import { POST_METHOD } from "~/constants";
import { internalFetchByUrl } from "~/helpers/communication/FetchApi";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { notifyRouteConcat, routeHouses } from "~/routesStatic/routesExpress";
import { useUserStore } from "~/stores";

const SERVER_URL = env.SERVER_URL;

export async function notifyGateway(
  houseId: string,
  body: PostNotif,
): Promise<FetchResponse<HousesNotifyIdHousePost200Response>> {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const routeToNotifyGateway = SERVER_URL + routeHouses + notifyRouteConcat + houseId;
  return internalFetchByUrl(routeToNotifyGateway, POST_METHOD, customHeaders, body);
}

export const lastGatewayActivityHistory = async (
  body: PostHistoBox,
): Promise<FetchResponse<LastGatewayActivity200Response>> => {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const route = `${SERVER_URL}/api/v3/history/lastGatewayActivity`;
  return internalFetchByUrl(route, POST_METHOD, customHeaders, body);
};
