import { useCallback, useEffect } from "react";

type OptionalConfig = Pick<KeyboardEvent, "altKey" | "ctrlKey" | "shiftKey">;

interface ShortcutConfig extends Partial<OptionalConfig> {
  code: KeyboardEvent["code"];
  shortcutTarget?: HTMLElement | null;
}

type ShortcutAction = (e: KeyboardEvent) => void;

export const useKeyPress = (targetKeys: ShortcutConfig, callback: ShortcutAction) => {
  // FIX: Si deux instance pour la meme shortcutTarget sont declaré, la derniere prend le dessus
  const targetElement = targetKeys.shortcutTarget || document;

  const eventHandler = useCallback(
    (e: KeyboardEvent) => {
      e.stopPropagation();
      const { code, ctrlKey, altKey, shiftKey } = e;
      const {
        code: targetCode = "",
        ctrlKey: targetCtrlKey = false,
        altKey: targetAltKey = false,
        shiftKey: targetShiftKey = false,
      } = targetKeys;

      if (targetCode !== code) return;
      if (targetCtrlKey && !ctrlKey) return;
      if (targetShiftKey && !shiftKey) return;
      if (targetAltKey && !altKey) return;

      callback(e);
    },
    [callback, targetKeys],
  );

  useEffect(() => {
    targetElement.addEventListener("keydown", eventHandler as EventListenerOrEventListenerObject);
    return () => targetElement.removeEventListener("keydown", eventHandler as EventListenerOrEventListenerObject);
  }, [targetElement, eventHandler]);
};
