import { startTransition, useMemo, useState } from "react";

import clsx from "clsx";
import { matchSorter } from "match-sorter";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRevalidator } from "react-router-dom";

import type {
  BoilerRoomsPosInner,
  HousesHouseIdBoilerroomsPosGet200ResponseMessageInner,
} from "@eisox/backend_webapp_api";
import { ActionButtonV2, Combobox, PlanV2 as DSPlan, Modal, SelectV2 as Select } from "@eisox/design-system";
import { BinIcon, CrossIcon, PlusIcon, TargetIcon, TickIcon } from "@eisox/icons";
import { useControllableState } from "@eisox/tools";
import { useMutation, useQuery } from "@tanstack/react-query";

import { FieldContainer } from "~/UI";
import { Boilerroom } from "~/UI/components/Boilerroom";
import { queries, updateBoilerRoom } from "~/apiV2";

import styles from "./PositioningDialog.module.scss";

interface PositioningDialogProps {
  houseId: string;
  boilerRoomsPos: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[];
  plans: { id: string; name: string }[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  defaultOpen?: boolean;
}

const PositioningDialog: React.FC<PositioningDialogProps> = ({
  houseId,
  boilerRoomsPos,
  plans,
  open: openProp,
  onOpenChange,
  defaultOpen,
}) => {
  const { formatMessage } = useIntl();

  const boilerRooms = useMemo(
    () => boilerRoomsPos.flatMap(br => br.boilerRooms).filter(br => br !== undefined),
    [boilerRoomsPos],
  );

  const [open, setOpen] = useControllableState({ prop: openProp, defaultProp: defaultOpen, onChange: onOpenChange });

  const [plan, setPlan] = useState(plans[0].id);
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const { revalidate } = useRevalidator();
  const { data: planFile } = useQuery(queries.plan.plan(houseId, plan));

  const { mutate } = useMutation({
    mutationFn: updateBoilerRoom,
    onSuccess: () => {
      setOpen(false);
      revalidate();
    },
  });

  const {
    formState: { isDirty, dirtyFields },
    handleSubmit,
    watch,
    setValue,
  } = useForm<{ boilerRooms: BoilerRoomsPosInner[] }>({
    values: {
      boilerRooms,
    },
  });

  const currentBoilerRooms = watch("boilerRooms");

  const selectedBoilerRoomIndex = currentBoilerRooms.findIndex(br => br.id === selectedValue);

  const matches = useMemo(
    () =>
      matchSorter(currentBoilerRooms, searchValue, { keys: ["name"] }).filter(b => b.id && b.name) as (Omit<
        BoilerRoomsPosInner,
        "id" | "name"
      > & { id: string; name: string })[],
    [currentBoilerRooms, searchValue],
  );

  const handleClickOnItem = (id: string) => {
    setSelectedValue(id);
    const boilerRoom = currentBoilerRooms.find(br => br.id === id);
    if (boilerRoom?.plan?.planId && boilerRoom.plan.planId !== plan) {
      setPlan(boilerRoom.plan.planId);
    }
  };

  const handleClickOnPlan = ({ x, y }: DSPlan.Point) => {
    if (selectedBoilerRoomIndex === -1) return;
    setValue(`boilerRooms.${selectedBoilerRoomIndex}.plan`, { planId: plan, x, y }, { shouldDirty: true });
  };

  const handleDeletePosition = (id: string) => {
    const index = currentBoilerRooms.findIndex(br => br.id === id);
    if (index === -1) return;
    setValue(`boilerRooms.${index}.plan`, undefined, { shouldDirty: true });
  };

  const onSubmit = (data: { boilerRooms: BoilerRoomsPosInner[] }) => {
    data.boilerRooms.forEach((br, i) => {
      if (!dirtyFields.boilerRooms?.[i]?.plan) return;
      const moduleId = boilerRoomsPos.find(bpos => bpos.boilerRooms?.find(b => b.id === br.id))?.moduleId;
      if (!moduleId || !br.id) return;
      mutate({ moduleId, boilerRoomId: br.id, body: { plan: br.plan } });
    });
  };

  const boilerRoomOnPlan = currentBoilerRooms.filter(
    b => b.id && b.plan?.planId && b.plan.x && b.plan.y && b.plan.planId === plan,
  ) as (Omit<BoilerRoomsPosInner, "id" | "plan"> & { id: string; plan: { id: string; x: number; y: number } })[];

  return (
    <Modal.Root open={open} onOpenChange={setOpen}>
      <Modal.Content className={styles.positioningDialog}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ height: "100%", width: "100%" }}>
          <Modal.Header
            title={formatMessage({ id: "boilerRoom.dialog.positioning.title" })}
            subtitle={formatMessage({ id: "boilerRoom.dialog.positioning.subtitle" })}
            icon={<TargetIcon />}
          >
            <Modal.Close asChild>
              <ActionButtonV2 variant="cancel" type="button">
                {formatMessage({ id: "boilerRoom.dialog.positioning.cancel" })}
              </ActionButtonV2>
            </Modal.Close>
            <ActionButtonV2 type="submit" disabled={!isDirty}>
              {formatMessage({ id: "boilerRoom.dialog.positioning.add" })}
              <PlusIcon />
            </ActionButtonV2>
          </Modal.Header>
          <div className={styles.positioningDialog__content}>
            <div className={styles.left}>
              <h3 className={styles.left__title}>
                {formatMessage({ id: "boilerRoom.dialog.positioning.boilerRoomsSubStations" })}
              </h3>
              <Combobox.Root
                setValue={value => startTransition(() => setSearchValue(value))}
                selectedValue={selectedValue}
                setSelectedValue={value => setSelectedValue(value as string)}
              >
                <Combobox.Input
                  placeholder={formatMessage({ id: "boilerRoom.dialog.positioning.search" })}
                  autoSelect
                />
                <Combobox.List className={styles.left__list} alwaysVisible>
                  {matches.length ? (
                    matches.map(match => {
                      const originalBoilerRoom = boilerRooms.find(br => br.id === match.id);
                      const added = !originalBoilerRoom?.plan && match.plan;
                      return (
                        <Combobox.Item
                          key={match.id}
                          className={clsx(styles.left__item, selectedValue === match.id && styles.left__item_selected)}
                          value={match.id}
                          setValueOnClick={false}
                          onClick={() => handleClickOnItem(match.id)}
                        >
                          <p>{match.name}</p>
                          {match.plan ? (
                            <TickIcon className={clsx(styles.left__icon, styles.left__icon_color_green)} />
                          ) : (
                            <CrossIcon className={clsx(styles.left__icon, styles.left__icon_color_red)} />
                          )}
                          {added && <BinIcon onClick={() => handleDeletePosition(match.id)} />}
                        </Combobox.Item>
                      );
                    })
                  ) : (
                    <div>{formatMessage({ id: "boilerRoom.dialog.positioning.noResult" })}</div>
                  )}
                </Combobox.List>
              </Combobox.Root>
            </div>
            <div className={styles.right}>
              <FieldContainer label={formatMessage({ id: "selectablePlan.level.label" })}>
                <Select
                  classNames={{ trigger: styles.select }}
                  options={plans.map(p => ({ value: p.id, name: p.name }))}
                  value={plan}
                  onChange={value => setPlan(value as string)}
                />
              </FieldContainer>
              <DSPlan.Root onClickOnPlan={handleClickOnPlan}>
                <DSPlan.Controls className={styles.controls} />
                <DSPlan.Wrapper plan={planFile} className={styles.plan}>
                  {boilerRoomOnPlan.map(b => {
                    const index = currentBoilerRooms.findIndex(br => br.id === b.id);
                    if (selectedValue === b.id) console.log(`${b.name} selected`);
                    return (
                      <DSPlan.Item
                        key={b.id}
                        asChild
                        draggable
                        position={{ x: b.plan.x, y: b.plan.y }}
                        onPositionChange={({ x, y }) =>
                          setValue(`boilerRooms.${index}.plan`, { planId: plan, x, y }, { shouldDirty: true })
                        }
                        onClick={() => setSelectedValue(b.id)}
                      >
                        <Boilerroom selected={selectedValue === b.id} />
                      </DSPlan.Item>
                    );
                  })}
                </DSPlan.Wrapper>
              </DSPlan.Root>
            </div>
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};

export { PositioningDialog };
