import { useFieldArray, useForm } from "react-hook-form";
import { useParams, useRevalidator, useRouteLoaderData } from "react-router-dom";

import { useMutation } from "@tanstack/react-query";

import type { houseLoader } from "~/UI";
import type { ItemType } from "~/UI/layouts/PlanV2/components";
import type { UpdateBoilerRoomParams } from "~/apiV2";
import { updateBoilerRoom } from "~/apiV2";
import { useAction } from "~/hooks/useAction";
import { idLoaderHouse, routeToGateways, routeToRoom, routeToUpdateBoilerroomPos, routeToValves } from "~/routes/utils";
import { API } from "~/types/API";

export interface RessourceWithPlan {
  id?: string;
  plan?: { planId?: string; x?: number; y?: number };
  [key: string]: any;
}

export const useMoving = (
  elementsToMove: RessourceWithPlan[],
  ressource: "valve" | "gateway" | "room" | "boilerroom",
  onClose: VoidFunction,
) => {
  const { boilerroomPos } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;
  const { houseId } = useParams() as { houseId: string; planId: string };

  const { revalidate } = useRevalidator();

  const { mutate: moveBoilerRoom } = useMutation({
    mutationFn: ({ moduleId, boilerRoomId, body }: UpdateBoilerRoomParams) =>
      updateBoilerRoom({ moduleId, boilerRoomId, body }),
    onSuccess: () => {
      revalidate();
      onClose();
    },
  });

  const { Form, submit } = useAction({
    onSuccess: () => onClose(),
  });

  const {
    control,
    formState: { isDirty, dirtyFields },
    handleSubmit,
  } = useForm({
    mode: "onChange",
    values: {
      fields: elementsToMove,
    },
  });
  const { fields, update } = useFieldArray({ control: control, name: "fields", keyName: "fieldId" });

  const onChange: (items: ItemType[]) => void = (items: ItemType[]) => {
    items.forEach(i => {
      if (i !== undefined) {
        const f = fields.find(f => f.id === i.id);
        if (f?.plan?.x !== i.x || f.plan.y !== i.y) {
          update(
            fields.findIndex(f => f.id === i.id),
            { ...f, plan: { planId: f?.plan?.planId, x: i.x, y: i.y } },
          );
        }
      }
    });
  };

  const submitRessource = {
    valve: (updatedValves: RessourceWithPlan[]) => submit(updatedValves, API.HTTP_METHOD.PATCH, routeToValves(houseId)),
    gateway: (updatedGateways: RessourceWithPlan[]) =>
      submit(updatedGateways, API.HTTP_METHOD.PATCH, routeToGateways(houseId)),
    room: (updatedRooms: RessourceWithPlan[]) =>
      updatedRooms.forEach(
        r =>
          r.id &&
          r.plan?.planId &&
          submit({ plan: r.plan }, API.HTTP_METHOD.PATCH, routeToRoom(houseId, r.plan.planId, r.id)),
      ),
    boilerroom: (updatedBoilerrooms: RessourceWithPlan[]) =>
      updatedBoilerrooms.forEach(ub => {
        const moduleId = boilerroomPos.find(m => m.boilerRooms?.map(b => b.id).includes(ub.id))?.moduleId;
        if (ub.id && moduleId) moveBoilerRoom({ moduleId, boilerRoomId: ub.id, body: { plan: ub.plan } });
      }),
  };

  const onSubmit = handleSubmit(() => {
    const updatedValves: RessourceWithPlan[] = [];
    dirtyFields.fields?.forEach((df, i) => {
      if (df.plan?.x || df.plan?.y || df.plan?.planId || df.roomId || df.gatewayId) {
        let updatedRessource: RessourceWithPlan = { id: fields[i].id };
        updatedRessource = { ...updatedRessource, plan: fields[i].plan };
        if (ressource === "valve") {
          updatedRessource = { ...updatedRessource, roomId: fields[i].roomId, gatewayId: fields[i].gatewayId };
        }
        updatedValves.push(updatedRessource);
      }
    });
    submitRessource[ressource](updatedValves);
  });

  return {
    fields,
    isDirty,
    update,
    onChange,
    Form,
    onSubmit,
  };
};
