import { cloneDeep } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import type { ErrorType, Option } from "@eisox/design-system";
import { ActionButton, Alert, Dialog, Select } from "@eisox/design-system";
import { ArrowRightIcon, CalendarIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { useBoilerRoomRealTimeProviderContext } from "~/features/BoilerRooms";
import type { Planning, Planning as PlanningType } from "~/socketio/types";
import {
  DAYS,
  convertTimeRangeToPlanningArray,
  convertTimeslotsToTimeRange,
  getActualDay,
  getHoursEnd,
  getHoursStart,
} from "~/utils/planningUtils";

import styles from "./ThermalShockTimeslotPopup.module.scss";

const NAME = "ThermalShockTimeslotPopup";

interface ThermalShockTimeslotPopupType {
  dayStart: DAYS;
  hourStart: string;
  dayEnd: DAYS;
  hourEnd: string;
}

interface ThermalShockTimeslotPopupProps {
  open: boolean;
  onClose: VoidFunction;
  planning?: PlanningType;
  weekPlannings?: PlanningType[];
}

export const ThermalShockTimeslotPopup: React.FC<ThermalShockTimeslotPopupProps> = ({
  open,
  onClose,
  planning,
  weekPlannings,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const thermalShockTimeslotPopupStyle = bem("thermal-shock-timeslot-popup");
  const contentStyle = bem("content");
  const footerStyle = bem("footer");

  const { boilerroomId, networkId } = useParams() as { boilerroomId: string; networkId: string };

  const planningToUpdate =
    planning && weekPlannings
      ? convertTimeslotsToTimeRange(
          planning,
          weekPlannings.filter(p => p.userDefined),
        )
      : undefined;

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
  } = useForm<ThermalShockTimeslotPopupType>({
    defaultValues: {
      dayStart: planningToUpdate?.current.dayStart ?? getActualDay(),
      hourStart: planningToUpdate?.current.hourStart,
      dayEnd: planningToUpdate?.current.dayEnd ?? getActualDay(),
      hourEnd: planningToUpdate?.current.hourEnd,
    },
  });

  const { dayStart, hourStart, dayEnd, hourEnd } = watch();

  const { useUpdateBoilerRoom } = useBoilerRoomRealTimeProviderContext(NAME);

  const { mutate } = useUpdateBoilerRoom({
    onSuccess: () => onClose(),
  });

  const onSubmit = (data: ThermalShockTimeslotPopupType) => {
    const plannings = convertTimeRangeToPlanningArray(
      data.dayStart,
      data.hourStart,
      data.dayEnd,
      data.hourEnd,
      "thermalShock",
    ) as Planning[];
    const planningPayload = cloneDeep(plannings);
    planningPayload.forEach(p => {
      p.type = p.planningType;
      delete p.planningType;
    });
    mutate([{ id: boilerroomId, heatingNetworks: [{ id: networkId, plannings: planningPayload }] }]);
  };

  const renderValue = (field: "hour" | "day") => (value?: string, _?: ErrorType) => (
    <p>
      {field === "day"
        ? formatMessage({
            id: value
              ? `plannings.${value}.long`
              : `network.content.planning.popup.thermalShockTimeslot.inputs.day.placeholder`,
          })
        : value
          ? value
          : formatMessage({ id: "network.content.planning.popup.thermalShockTimeslot.inputs.hour.placeholder" })}
    </p>
  );

  const renderOption = (option: Option, _: boolean) => <p>{option.name}</p>;

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose()}>
      <Dialog.Content
        className={thermalShockTimeslotPopupStyle()}
        title={formatMessage({ id: "network.content.planning.popup.thermalShockTimeslot.title" })}
        icon={<CalendarIcon />}
      >
        <div className={contentStyle()}>
          <div className={contentStyle("inputs")}>
            <h2 className={contentStyle("text", { type: "label" })}>
              {formatMessage({ id: "network.content.planning.popup.thermalShockTimeslot.inputs.start" })}
            </h2>
            <Controller
              control={control}
              name="dayStart"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  options={Object.values(DAYS).map(d => ({
                    value: d,
                    name: formatMessage({ id: `plannings.${d}.long` }),
                  }))}
                  renderValue={renderValue("day")}
                  renderOption={renderOption}
                  onChange={(value: string) => {
                    onChange(value);
                    setValue("dayEnd", value as DAYS);
                  }}
                  error={errors.dayStart}
                />
              )}
            />
            <Controller
              control={control}
              name="hourStart"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  options={getHoursStart(hourEnd, dayStart, dayEnd).map(h => ({
                    value: h,
                    name: h.replace(":", "h"),
                  }))}
                  renderValue={renderValue("hour")}
                  renderOption={renderOption}
                  onChange={(value: string) => onChange(value)}
                  error={errors.hourStart}
                />
              )}
            />
            <h2 className={contentStyle("text", { type: "label" })}>
              {formatMessage({ id: "network.content.planning.popup.thermalShockTimeslot.inputs.end" })}
            </h2>
            <Controller
              control={control}
              name="dayEnd"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  options={Object.values(DAYS).map(d => ({
                    value: d,
                    name: formatMessage({ id: `plannings.${d}.long` }),
                  }))}
                  renderValue={renderValue("day")}
                  renderOption={renderOption}
                  onChange={(value: string) => onChange(value)}
                  error={errors.dayEnd}
                  disabled
                />
              )}
            />
            <Controller
              control={control}
              name="hourEnd"
              rules={{ required: true }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  options={getHoursEnd(hourStart, dayStart, dayEnd).map(h => ({
                    value: h,
                    name: h.replace(":", "h"),
                  }))}
                  renderValue={renderValue("hour")}
                  renderOption={renderOption}
                  onChange={(value: string) => onChange(value)}
                  error={errors.hourEnd}
                />
              )}
            />
          </div>
          <div className={contentStyle("warning")}>
            <h2 className={contentStyle("text", { type: "title" })}>
              {formatMessage({ id: "network.content.planning.popup.thermalShockTimeslot.warning.title" })}
            </h2>
            <p className={contentStyle("text", { type: "text" })}>
              {formatMessage({ id: "network.content.planning.popup.thermalShockTimeslot.warning.text" })}
            </p>
          </div>
          {dayStart === dayEnd && hourStart && hourEnd && hourStart === hourEnd && (
            <Alert className={contentStyle("alert")}>
              <p>{formatMessage({ id: "network.content.planning.popup.thermalShockTimeslot.alert" })}</p>
            </Alert>
          )}
          <div className={footerStyle()}>
            <ActionButton
              text={formatMessage({ id: "network.content.planning.popup.thermalShockTimeslot.save" })}
              icon={<ArrowRightIcon />}
              onClick={() => handleSubmit(onSubmit)()}
              disabled={!isDirty}
              rounded
            />
            <ActionButton
              variant="cancel"
              text={formatMessage({ id: "network.content.planning.popup.thermalShockTimeslot.cancel" })}
              onClick={() => onClose()}
            />
          </div>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

ThermalShockTimeslotPopup.displayName = NAME;
