import type { z } from "@eisox/zod";

import { MODE_ENV } from "~/constants/appConstantV2";

import { envSchema } from "./schema";

export type EnvSchemaType = z.infer<ReturnType<typeof envSchema>>;

// In dev mode, a function is called to check the env variables if there is an error then a console.error is .
// In other environments, a separate command validates the env and causes the pipeline to fail if it is invalid.
export const env = Object.fromEntries(
  Object.entries(import.meta.env).map(([key, value]) => [key.replace(/^VITE_/, ""), value]),
) as EnvSchemaType & ImportMetaEnv;

export const validateEnvConfig = async () => {
  const { success, error } = await envSchema(env.MODE).safeParseAsync(env);
  if (!success && env.MODE !== MODE_ENV.PROD) {
    Object.entries(error.format()).forEach(([key, value]) => {
      if (Array.isArray(value) || value._errors.length > 0) {
        console.error(`VITE_${key}: ${(Array.isArray(value) ? value : value._errors).join(", ")}`);
      }
    });
  }
  if (env.MODE !== MODE_ENV.PROD) console.info(`Defined env is ${env.MODE}`);
};
