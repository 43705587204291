import { forwardRef } from "react";

import type { VariantProps } from "class-variance-authority";
import { cva, cx } from "class-variance-authority";

import * as RadixDialog from "@radix-ui/react-dialog";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";

import styles from "./Sheet.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Sheet
 * -----------------------------------------------------------------------------------------------*/

type SheetProps = RadixDialog.DialogProps;

const Root = RadixDialog.Root;

Root.displayName = "Sheet";

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/

type TriggerProps = RadixDialog.DialogTriggerProps;

const Trigger = RadixDialog.Trigger;

Trigger.displayName = "SheetTrigger";

/* -------------------------------------------------------------------------------------------------
 * Overlay
 * -----------------------------------------------------------------------------------------------*/

type PortalProps = RadixDialog.DialogPortalProps;

const Portal = RadixDialog.Portal;

Portal.displayName = "SheetPortal";

/* -------------------------------------------------------------------------------------------------
 * Overlay
 * -----------------------------------------------------------------------------------------------*/

type OverlayProps = RadixDialog.DialogOverlayProps;

const Overlay = forwardRef<HTMLDivElement, OverlayProps>((props, forwardedRef) => {
  const { className, ...overlayProps } = props;
  return <RadixDialog.Overlay {...overlayProps} ref={forwardedRef} className={cx(styles.overlay, className)} />;
});

Overlay.displayName = "SheetOverlay";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/

const sheetVariants = cva(styles.sheet, {
  variants: {
    side: {
      top: styles.sheet_top,
      right: styles.sheet_right,
      bottom: styles.sheet_bottom,
      left: styles.sheet_left,
    },
  },
  defaultVariants: {
    side: "right",
  },
});

interface ContentProps extends RadixDialog.DialogContentProps, VariantProps<typeof sheetVariants> {}

const Content = forwardRef<HTMLDivElement, ContentProps>((props, forwardedRef) => {
  const { className, side, children, ...contentProps } = props;
  return (
    <RadixDialog.Content
      {...contentProps}
      ref={forwardedRef}
      className={sheetVariants({ side, className })}
      aria-describedby={undefined}
    >
      <VisuallyHidden.Root>
        <RadixDialog.Title />
      </VisuallyHidden.Root>
      {children}
    </RadixDialog.Content>
  );
});

Content.displayName = "SheetContent";

/* -------------------------------------------------------------------------------------------------
 * Close
 * -----------------------------------------------------------------------------------------------*/

type CloseProps = RadixDialog.DialogCloseProps;

const Close = RadixDialog.Close;

Close.displayName = "SheetClose";

export { Close, Content, Overlay, Portal, Root, Trigger };
export type { CloseProps, ContentProps, OverlayProps, PortalProps, SheetProps, TriggerProps };
