import type { PropsWithChildren } from "react";
import { useState } from "react";

import { useIntl } from "react-intl";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

import { ActionButtonV2, ButtonV2, Dialog } from "@eisox/design-system";
import { ArrowRightIcon, BracesIcon, CrossIcon, NutIcon, WarningIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { Typography } from "@mui/material";

import type { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/providers";
import { idLoaderHouse, routeToModulesSettings } from "~/routes/utils";
import { concatBoilerroomNamesFromModule } from "~/utils";

import type { ErrorMessageFormated } from "../../types";
import { ResetVariablesDialog } from "../ResetVariablesDialog";
import { VariableDetails } from "../VariableDetails";

import styles from "./ErrorDialog.module.scss";

interface ErrorDialogProps extends PropsWithChildren {
  errors: ErrorMessageFormated[];
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({ errors, children }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const errorPopupStyle = bem("errorPopup");
  const contentStyle = bem("content");
  const actionsStyle = bem("actions");
  const messagesStyle = bem("messages");

  const { houseId } = useParams() as { houseId: string };
  const navigate = useNavigate();
  const { boilerroomPos, modules, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    ReturnType<typeof houseLoader>
  >;

  const { permissions } = usePermissionsContext("ErrorDialog");

  const [open, setOpen] = useState(false);
  const [displayVariables, setDisplayVariables] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content
        className={errorPopupStyle()}
        title={formatMessage({ id: "boilerRoom.popup.error.title" })}
        icon={<WarningIcon color="#fa0000" />}
      >
        <div className={contentStyle()}>
          <div className={contentStyle("left")}>
            <div className={actionsStyle()}>
              <p>
                {formatMessage({
                  id: "boilerRoom.popup.error.actions.title",
                })}
              </p>
              <ButtonV2 className={actionsStyle("button")} onClick={() => navigate(routeToModulesSettings(houseId))}>
                <NutIcon />
                <p>{formatMessage({ id: "boilerRoom.popup.error.actions.button.gestion" })}</p>
                <ArrowRightIcon />
              </ButtonV2>
              <ResetVariablesDialog
                houseId={houseId}
                boilerroomPos={boilerroomPos}
                gateways={gateways}
                modules={modules}
              >
                <ButtonV2 className={actionsStyle("button")}>
                  <BracesIcon />
                  <p>{formatMessage({ id: "boilerRoom.popup.error.actions.button.resetVariables" })}</p>
                  <ArrowRightIcon />
                </ButtonV2>
              </ResetVariablesDialog>
              {permissions.module?.boilerroom?.variableData?.read && (
                <ButtonV2
                  className={actionsStyle("button", { active: displayVariables })}
                  onClick={() => setDisplayVariables(!displayVariables)}
                >
                  <BracesIcon />
                  <p>{formatMessage({ id: "boilerRoom.popup.error.actions.button.getDetailsVariables" })}</p>
                  {displayVariables ? <CrossIcon style={{ width: 10, height: 10 }} /> : <ArrowRightIcon />}
                </ButtonV2>
              )}
            </div>
            <div className={messagesStyle()}>
              {errors.map((e, i) => {
                return (
                  <div key={i}>
                    <>
                      <p className={messagesStyle("date")}>{e.date}</p>
                      {e.messages ? (
                        e.messages.map((error, i) => {
                          const moduleId = error.moduleId;
                          const boilerRoomPos = boilerroomPos.find(m => m.moduleId === moduleId);
                          const ip = modules.find(m => m.id === moduleId)?.url;
                          const boilerroomNames = boilerRoomPos && concatBoilerroomNamesFromModule(boilerRoomPos);
                          return (
                            <>
                              <Typography className={messagesStyle("boilerRoom")}>
                                {ip} ({boilerroomNames})
                              </Typography>
                              <p
                                key={i}
                                className={messagesStyle("error")}
                              >{`"${error.msg}","type":"${error.type}"`}</p>
                            </>
                          );
                        })
                      ) : (
                        <p>
                          {formatMessage({
                            id: "boilerRoom.popup.error.noError",
                          })}
                        </p>
                      )}
                    </>
                  </div>
                );
              })}
            </div>
          </div>
          {displayVariables && (
            <div className={contentStyle("right")}>
              <VariableDetails />
            </div>
          )}
        </div>
        <Dialog.Close asChild>
          <ActionButtonV2 variant="cancel">{formatMessage({ id: "boilerRoom.popup.error.close" })}</ActionButtonV2>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export { ErrorDialog };
export type { ErrorDialogProps };
