import { forwardRef, useState } from "react";

import { useIntl } from "react-intl";

import { Radio, Typography } from "@eisox/design-system";
import { ChevronDownIcon, ChevronRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import type { BoilerRoom } from "~/socketio/types";

import styles from "./DataSelector.module.scss";

const UNDISPLAYED_KEYS = [
  "id",
  "objectName",
  "type",
  "objectNameParent",
  "ambientSensorIds",
  "heatingCurves",
  "idValves",
];

const isSelected = (path: string, paths: string[]): boolean => {
  return paths.some(selectedPath => selectedPath.startsWith(path));
};

const remapObjectWithSortedKeys = (obj: Record<string, any>, formatMessage: FormatMessageFn): Record<string, any> => {
  return Object.keys(obj)
    .map(key => ({
      key,
      translation: formatMessage({ id: `boilerRoom.history.detailedHistory.keys.${key}`, defaultMessage: key }),
    }))
    .sort((a, b) => a.translation.localeCompare(b.translation))
    .reduce(
      (acc, { key }) => {
        acc[key] = obj[key];
        return acc;
      },
      {} as Record<string, any>,
    );
};

interface RecursiveDropdownProps {
  obj: Record<string, any>;
  currentPath: string;
  paths: string[];
  onChange: (paths: string[]) => void;
}

const RecursiveDropdown: React.FC<RecursiveDropdownProps> = ({ obj, currentPath, paths, onChange }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const dataSelectorStyle = bem("data-selector");

  const sortedObject = remapObjectWithSortedKeys(obj, formatMessage);

  return (
    <>
      {Object.entries(sortedObject).map(([key, value]) =>
        typeof value === "object" && value !== null && !UNDISPLAYED_KEYS.includes(key) ? (
          <DropdownMenu.Sub key={key}>
            <DropdownMenu.SubTrigger
              className={dataSelectorStyle("sub-trigger", {
                selected: isSelected(`${currentPath}${value.name ? `[${value.id}]` : `.${key}`}`, paths),
              })}
            >
              {value.name || formatMessage({ id: `boilerRoom.history.detailedHistory.keys.${key}` })}
              <ChevronRightIcon />
            </DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent
              className={dataSelectorStyle("content")}
              sideOffset={6}
              alignOffset={-5}
              collisionPadding={{ top: 100, bottom: 100, right: 20, left: 20 }}
            >
              <RecursiveDropdown
                obj={value}
                currentPath={`${currentPath}${value.id ? (value.name ? `[${value.id}]` : `.${key}`) : `.${key}`}`}
                paths={paths}
                onChange={onChange}
              />
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
        ) : (
          !UNDISPLAYED_KEYS.includes(key) &&
          key !== "name" && (
            <DropdownMenu.CheckboxItem
              key={key}
              checked={paths.includes(`${currentPath}.${key}`)}
              onSelect={e => e.preventDefault()}
              onCheckedChange={() => {
                currentPath = `${currentPath}.${key}`;
                onChange(
                  paths.includes(currentPath) ? paths.filter(path => path !== currentPath) : [...paths, currentPath],
                );
              }}
              className={dataSelectorStyle("sub-trigger", {
                checkbox: true,
                selected: paths.includes(`${currentPath}.${key}`),
              })}
              disabled={paths.length >= 6 && !paths.includes(`${currentPath}.${key}`)}
            >
              <Radio checked={paths.includes(`${currentPath}.${key}`)} multiple disabled={paths.length >= 6} />
              {formatMessage({ id: `boilerRoom.history.detailedHistory.keys.${key}` })}
            </DropdownMenu.CheckboxItem>
          )
        ),
      )}
    </>
  );
};

interface DataSelectorProps {
  boilerrooms: BoilerRoom[];
  paths: string[];
  onChange: (paths: string[]) => void;
  disabled?: boolean;
}

export const DataSelector: React.FC<DataSelectorProps> = forwardRef<HTMLButtonElement, DataSelectorProps>(
  ({ boilerrooms, paths, onChange, disabled = false }, ref) => {
    const { formatMessage } = useIntl();

    const bem = useBem(styles);
    const dataSelectorStyle = bem("data-selector");

    const [open, setOpen] = useState(false);

    return (
      <DropdownMenu.Root open={open} onOpenChange={setOpen}>
        <DropdownMenu.Trigger
          asChild
          onBlur={(event: React.FocusEvent<HTMLButtonElement>) => {
            const element = event.relatedTarget;
            (element as HTMLElement).focus();
          }}
        >
          <button ref={ref} className={dataSelectorStyle("trigger", { disabled })} disabled={disabled}>
            <Typography>
              {formatMessage(
                { id: "boilerRoom.history.detailedHistory.boilerroomsSubStations" },
                { paths: paths.length },
              )}
            </Typography>
            <ChevronDownIcon />
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            className={dataSelectorStyle("content")}
            align="start"
            sideOffset={5}
            collisionPadding={{ top: 100, bottom: 100, right: 20, left: 20 }}
          >
            {boilerrooms
              .sort((a, b) => a.name!.localeCompare(b.name!))
              .map((boilerRoom, index) => (
                <DropdownMenu.Sub key={index}>
                  <DropdownMenu.SubTrigger
                    className={dataSelectorStyle("sub-trigger", {
                      selected: isSelected(`boilerRooms[${boilerRoom.id}]`, paths),
                    })}
                  >
                    {boilerRoom.name} <ChevronRightIcon />
                  </DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent
                    className={dataSelectorStyle("content")}
                    sideOffset={6}
                    alignOffset={-5}
                    collisionPadding={{ top: 100, bottom: 100, right: 20, left: 20 }}
                  >
                    <RecursiveDropdown
                      obj={boilerRoom}
                      currentPath={`boilerRooms[${boilerRoom.id}]`}
                      paths={paths}
                      onChange={onChange}
                    />
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
              ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    );
  },
);
