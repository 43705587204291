import { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { cloneDeep, uniqueId } from "lodash";
import { useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import type * as Yup from "yup";

import { ActionButton, Modal } from "@eisox/design-system";
import { GatewayIcon, PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { Gateway } from "~/UI/components";
import type { ItemType } from "~/UI/layouts/PlanV2/components";
import type { houseLoader } from "~/UI/screens";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToGateways, routeToPreinstallGateways } from "~/routes/utils";
import { API } from "~/types/API";

import { macSchema } from "../../Add/Gateways/utils";
import { ConfirmCancelPopup, Plan } from "../components";
import { getItemBiggerUid, getItemBiggerUidWithMacOrValvesInstalled } from "../utils";
import { offsetUid, reOrderItemsUid, schemaUid } from "../utils/uid";
import { GatewayForm } from "./GatewayForm";
import {
  gatewaysMessageInnerArrayToPreinstallGatewaysDto,
  getGatewayName,
  prefixId,
  replaceGatewayName,
} from "./utils";
import { schemaErrors } from "./utils/schema";

import styles from "./Gateways.module.scss";

/* It's for type GatewayFormDto */
const baseSchema = schemaErrors();
const uidSchema = schemaUid(0, []);

export type GatewayFormDto = Yup.InferType<typeof baseSchema> & Yup.InferType<typeof uidSchema> & { mac?: string };

export interface PreinstallGatewaysDto {
  gateways: GatewayFormDto[];
}

export const Gateways: React.FC<{ open: boolean; onClose: VoidFunction; className?: string; toAdd?: boolean }> = ({
  open,
  onClose,
  toAdd = false,
  className,
}) => {
  const { formatMessage } = useIntl();

  const { houseId, planId } = useParams() as { houseId: string; planId: string };

  const { plans, gateways, valves } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;

  const existingSerialNumber: string[] = gateways.filter(g => g.mac).flatMap(g => g.mac!);
  const nextUid = useMemo(
    () => (toAdd ? getItemBiggerUid(gateways) : getItemBiggerUidWithMacOrValvesInstalled(gateways, valves)) + 1,
    [],
  );
  const initalState = useMemo(
    () =>
      toAdd
        ? { gateways: [] }
        : gatewaysMessageInnerArrayToPreinstallGatewaysDto(gateways.filter(g => g.uid! > nextUid - 1)),
    [],
  );

  const { Form, submit } = useAction({
    onSuccess: () => onClose && onClose(),
  });

  /* GLOBAL FORM */
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
  } = useForm<PreinstallGatewaysDto>({
    values: initalState,
  });

  const { fields, update, append, replace } = useFieldArray({ control, name: "gateways", keyName: "fieldId" });

  existingSerialNumber.push(...fields.filter(f => !!f.mac).map(f => f.mac!));

  /* GETAWAY FORM */
  const formGateway = useForm<GatewayFormDto>({
    defaultValues: {},
    resolver: yupResolver(
      schemaErrors().concat(schemaUid(nextUid, fields)).concat(macSchema(existingSerialNumber, toAdd)),
    ),
  });

  const currentGateway: Partial<GatewayFormDto> = formGateway.watch();
  const isCurrentGatewayIsOnPlan = !!fields.find(f => f.id === currentGateway.id);

  const currentGatewayIndex = existingSerialNumber.findIndex(sn => sn === currentGateway.mac);
  currentGatewayIndex &&
    isCurrentGatewayIsOnPlan &&
    !formGateway.formState.dirtyFields.mac &&
    existingSerialNumber.splice(currentGatewayIndex, 1);

  const bem = useBem(styles);
  const containerStyle = bem("container");
  const contentStyle = bem("content");

  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false);

  useEffect(() => {
    const newFields = offsetUid(initalState.gateways, nextUid);
    replace(newFields);
    const lastGateway = newFields.find(f => f.id === newFields[newFields.length - 1].id);
    const newGateway = {
      id: uniqueId(prefixId),
      uid: lastGateway?.uid ? lastGateway.uid + 1 : nextUid,
      isInternetGateway: false,
      plan: { planId },
      isWifiEnabled: true,
    };
    formGateway.reset({
      ...newGateway,
      gatewayName: getGatewayName(newGateway, plans),
    });
  }, []);

  const disabledGatewaysItems: ItemType[] = useMemo(
    () =>
      gateways
        .filter(g => g.plan?.x && g.plan.y && g.plan.planId === currentGateway.plan?.planId && g.uid! < nextUid)
        .map(g => ({
          id: g.id!,
          x: g.plan?.x!,
          y: g.plan?.y!,
          disabled: true,
          renderItem: () => <Gateway {...g} problemStatus={undefined} displayUid disabled />,
        })),
    [currentGateway.plan?.planId],
  );

  const selectablesGatewaysItems: ItemType[] = fields
    .filter(g => g.plan.planId === currentGateway.plan?.planId && g.plan.x && g.plan.y)
    .map(g => {
      const disabled = isCurrentGatewayIsOnPlan && formGateway.formState.isDirty && g.id !== currentGateway.id;
      return {
        id: g.id,
        x: g.plan.x,
        y: g.plan.y,
        draggable: formGateway.formState.isDirty ? g.id === currentGateway.id : true,
        disabled,
        renderItem: () => (
          <Gateway
            {...g}
            problemStatus={undefined}
            className={contentStyle("gateway")}
            displayTooltip={false}
            displayUid
            selected={g.id === currentGateway.id}
            disabled={disabled}
            onClick={() => g.id !== currentGateway.id && formGateway.reset(g)}
          />
        ),
      };
    });

  const onSubmit = handleSubmit(() => {
    const body = toAdd
      ? {
          gateways: fields.map(({ id, fieldId, ...rest }) => ({ ...rest })),
        }
      : {
          startUid: nextUid,
          ...(fields.length > 0 && {
            gateways: fields.map(({ id, fieldId, ...rest }) => {
              const gatewayValves = valves.filter(v => v.gatewayId === id);
              return {
                ...rest,
                ...(gatewayValves.length > 0 && {
                  valves: gatewayValves.map(({ uid, heatingNetworkId, roomId, status, plan, ...rest }) => ({
                    uid,
                    heatingNetworkId,
                    roomId,
                    status,
                    plan,
                  })),
                }),
              };
            }),
          }),
        };
    submit(
      body,
      toAdd ? API.HTTP_METHOD.POST : API.HTTP_METHOD.PUT,
      toAdd ? routeToGateways(houseId) : routeToPreinstallGateways(houseId),
    );
  });

  const setToNextGateway = (gateway: GatewayFormDto) => {
    const newGateway = {
      id: uniqueId(prefixId),
      uid: gateway.uid + 1,
      plan: { planId: gateway.plan.planId },
      isInternetGateway: false,
      isWifiEnabled: !!gateway.isWifiEnabled,
      meshs: gateway.meshs,
    };
    formGateway.reset({
      ...newGateway,
      gatewayName: getGatewayName(newGateway, plans), // it's for new uid + 1 update because name is based on uid,
      ...(toAdd && { mac: "" }),
    });
  };

  const handleAddGateway = (data: GatewayFormDto) => {
    append(data);
    setToNextGateway(data);
  };

  const handleUpdateGateway = (data: GatewayFormDto) => {
    let updateFields = cloneDeep(fields);
    const index = fields.findIndex(f => f.id === data.id);
    const gateway = fields[index];

    updateFields[index] = { ...updateFields[index], ...data, uid: gateway.uid };

    if (formGateway.formState.dirtyFields.uid) {
      const fieldsUpdate = reOrderItemsUid(updateFields, gateway.uid, data.uid);
      fieldsUpdate && (updateFields = fieldsUpdate);
    }

    replace(updateFields);
    setToNextGateway({ ...data, uid: updateFields[updateFields.length - 1].uid });
  };

  const handleDeleteGateway = (data: GatewayFormDto) => {
    const index = fields.findIndex(f => f.id === data.id);
    const copyArray = cloneDeep(fields);
    copyArray.splice(index, 1);
    copyArray.forEach(f => {
      if (f.uid > fields[index].uid) {
        f.gatewayName = replaceGatewayName(f, f.uid - 1);
        f.uid--;
      }
    });
    replace(copyArray);
    setToNextGateway(copyArray[copyArray.length - 1]);
  };

  const handleClickOnPlan = (x: number, y: number) => {
    if (isCurrentGatewayIsOnPlan) {
      if (formGateway.formState.isDirty)
        toast.warn(formatMessage({ id: "plan.actions.preinstall.gateways.warn.changeButHaveModifications" }));
      else setToNextGateway(fields[fields.length - 1]);
    } else {
      formGateway.setValue("plan", { ...currentGateway.plan!, x, y });
      formGateway.handleSubmit(handleAddGateway)();
    }
  };

  const handleOnChange = (items: ItemType[]) => {
    items.forEach(i => {
      const f = fields.find(f => f.id === i.id);
      if (f && (f.plan.x !== i.x || f.plan.y !== i.y)) {
        update(
          fields.findIndex(f => f.id === i.id),
          { ...f, plan: { planId: f.plan.planId, x: i.x, y: i.y } },
        );
      }
    });
  };

  return (
    <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
      <Modal.Content className={clsx(containerStyle(), className)}>
        <Form onSubmit={onSubmit}>
          <Modal.Header
            title={formatMessage({ id: `plan.actions.${toAdd ? "add" : "preinstall"}.gateways.title` })}
            subtitle={formatMessage({ id: "plan.actions.preinstall.gateways.subtitle" })}
            icon={<GatewayIcon />}
          >
            <ActionButton
              variant="cancel"
              type="button"
              text={formatMessage({ id: "plan.actions.preinstall.gateways.cancel" })}
              onClick={e => {
                e.preventDefault();
                if (isDirty) setConfirmCancelPopup(true);
                else onClose && onClose();
              }}
            />
            <ActionButton
              rounded
              text={formatMessage({ id: "plan.actions.preinstall.gateways.save" })}
              icon={<PencilIcon />}
              disabled={!isDirty || Object.keys(errors).length > 0}
              type="submit"
            />
          </Modal.Header>
        </Form>

        <div className={contentStyle()}>
          <GatewayForm
            isCurrentGatewayIsOnPlan={isCurrentGatewayIsOnPlan}
            className={contentStyle("gateway-form")}
            form={formGateway}
            handleUpdateGateway={handleUpdateGateway}
            handleSetToNextGateway={() => setToNextGateway(fields[fields.length - 1])}
            handleDeleteGateway={handleDeleteGateway}
            toAdd={toAdd}
          />
          <div className={contentStyle("plan")}>
            {currentGateway.plan?.planId && (
              <Plan
                items={[...disabledGatewaysItems, ...selectablesGatewaysItems]}
                planId={currentGateway.plan.planId}
                onClickPlan={handleClickOnPlan}
                onChangeOnPlan={handleOnChange}
              />
            )}
          </div>
        </div>

        <ConfirmCancelPopup
          open={confirmCancelPopup}
          onClose={() => setConfirmCancelPopup(false)}
          onValidate={onClose}
        />
      </Modal.Content>
    </Modal.Root>
  );
};
