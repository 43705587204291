import dayjs from "@eisox/dayjs";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import type { InterventionPlanState } from "~/features/InterventionPlanTool";
import { intl } from "~/i18n";

import { whiteColor } from "../color";
import { orientation, size } from "../constant";
import bg from "../img/bg.png";
import logo from "../img/logo_white.png";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: whiteColor,
    gap: 25,
    fontSize: 16,
  },
  bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  logo: {
    width: 350,
  },
  information: {
    width: "100%",
    textAlign: "center",
  },
  houseName: {
    fontWeight: 600,
  },
  maintenance: {
    fontWeight: 600,
    width: "100%",
    textAlign: "center",
  },
});

export const PagePresentation: React.FC<InterventionPlanState> = ({ houseName, adress, planifiedAt }) => {
  const formatStart = planifiedAt.start && dayjs(planifiedAt.start).format("DD/MM/YYYY");
  const formatEnd = planifiedAt.end && dayjs(planifiedAt.end).format("DD/MM/YYYY");
  return (
    <Page size={size} orientation={orientation}>
      <View style={styles.container}>
        <Image src={bg} style={styles.bg} />
        <Image src={logo} style={styles.logo} />
        <View style={styles.information}>
          <Text style={styles.houseName}>
            {intl.formatMessage({ id: "interventionPlanTool.pdf.pagePresentation.houseName" }, { houseName })}
          </Text>
          {adress.length > 0 && <Text>{adress}</Text>}
        </View>
        <View style={styles.maintenance}>
          <Text>{intl.formatMessage({ id: "interventionPlanTool.pdf.pagePresentation.planIntervention" })}</Text>
          {formatStart && formatEnd && (
            <Text>
              {intl.formatMessage(
                { id: "interventionPlanTool.pdf.pagePresentation.planifiedAt" },
                {
                  d: formatStart === formatEnd ? formatStart : `${formatStart} - ${formatEnd}`,
                },
              )}
            </Text>
          )}
        </View>
      </View>
    </Page>
  );
};
