import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import type { HousesHouseIdBoilerroomsPosGet200ResponseMessageInner } from "@eisox/backend_webapp_api";
import { ButtonV2 as Button, DropdownMenu, Typography } from "@eisox/design-system";
import { ChevronDownIcon, TargetIcon } from "@eisox/icons";

import { FieldContainer } from "~/UI/components";
import { routeToBoilerroom } from "~/routes/utils";
import type { BoilerRoom } from "~/socketio/types";

import { findFarthestAncestor, findNearestAncestor, getBoilerRoomsFromModules } from "../../helpers";
import { Indicators } from "../Indicators";
import { PrimaryIndicators } from "../PrimaryIndicators";

import styles from "./RightColumn.module.scss";

const NAME = "RightColumn";

interface RightColumnProps {
  houseId: string;
  boilerRoomId: string;
  boilerRooms: BoilerRoom[];
  boilerRoomPos: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[];
  className?: string;
}

const RightColumn: React.FC<RightColumnProps> = ({ houseId, boilerRoomId, boilerRooms, boilerRoomPos, className }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const parentBoilerRooms = boilerRooms.filter(b => !b.objectNameParent);
  const modulesBoilerRooms = getBoilerRoomsFromModules(boilerRoomPos);

  const currentBoilerRoom = boilerRooms.find(b => b.id === boilerRoomId);

  const substations = boilerRooms.filter(b => b.objectNameParent);

  const parentBoilerRoomOptions = modulesBoilerRooms.filter(b => !substations.map(s => s.id).includes(b.id!));
  const currentParentBoilerRoom = findFarthestAncestor(boilerRoomId, boilerRooms);
  const currentParentBoilerRoomId = currentParentBoilerRoom?.id ?? boilerRoomId;
  const currentParentBoilerRoomName =
    currentParentBoilerRoom?.name ?? modulesBoilerRooms.find(b => b.id === boilerRoomId)?.name;

  const substation = currentParentBoilerRoomId === currentBoilerRoom?.id ? undefined : currentBoilerRoom;

  const handleSelectBoilerRoom = (value: string) => navigate(routeToBoilerroom(houseId, value));

  const getParentSubstationName = (id: string) => {
    const substation = substations.find(s => s.id === id);
    return substation?.objectNameParent ? findNearestAncestor(boilerRooms, substation)?.name : undefined;
  };

  return (
    <div className={cx(styles.rightColumn, className)}>
      {parentBoilerRoomOptions.length > 1 ? (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger asChild className={styles.dropdownMenu__trigger}>
            <Button>
              {currentParentBoilerRoomName} <ChevronDownIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className={styles.dropdownMenu__content} sideOffset={5}>
            {parentBoilerRoomOptions.map(o => (
              <DropdownMenu.Item
                key={o.id}
                className={cx(
                  styles.dropdownMenu__item,
                  currentParentBoilerRoomId === o.id && styles.dropdownMenu__item_active,
                )}
                onSelect={() => handleSelectBoilerRoom(o.id!)}
              >
                {o.name}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      ) : currentParentBoilerRoomName !== undefined ? (
        <Link to={routeToBoilerroom(houseId, currentParentBoilerRoomId)} className={styles.rightColumn__link}>
          {currentParentBoilerRoomName}
          {currentParentBoilerRoomId !== boilerRoomId && <TargetIcon />}
        </Link>
      ) : null}
      <div className={styles.rightColumn__indicators}>
        <PrimaryIndicators houseId={houseId} boilerRooms={parentBoilerRooms} />
        {currentBoilerRoom && parentBoilerRooms.includes(currentBoilerRoom) && (
          <Indicators boilerRoom={currentBoilerRoom} />
        )}
        {substations.length > 0 && (
          <FieldContainer label={t("boilerRoom.rightColumn.substations.title")}>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger
                asChild
                className={cx(
                  styles.dropdownMenu__trigger,
                  !substation?.name && styles.dropdownMenu__trigger_placeholder,
                )}
              >
                <Button>
                  {substation?.name ?? t("boilerRoom.rightColumn.substations.dropdown.trigger")}
                  <ChevronDownIcon />
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content className={styles.dropdownMenu__content} sideOffset={5}>
                {substations.map(s => (
                  <DropdownMenu.Item
                    key={s.id}
                    className={cx(styles.dropdownMenu__item, s.id === boilerRoomId && styles.dropdownMenu__item_active)}
                    onSelect={() => handleSelectBoilerRoom(s.id)}
                  >
                    <Typography>
                      {s.name} <span>{getParentSubstationName(s.id)}</span>
                    </Typography>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </FieldContainer>
        )}
        {currentBoilerRoom && currentBoilerRoom.id !== currentParentBoilerRoomId && (
          <Indicators boilerRoom={currentBoilerRoom} />
        )}
      </div>
    </div>
  );
};

RightColumn.displayName = NAME;

export { RightColumn };
export type { RightColumnProps };
