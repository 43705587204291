/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StatusCodes } from "http-status-codes";

import { HouseMessageType } from "@eisox/webapp-api-specification";
import { createQueryKeys } from "@lukemorales/query-key-factory";

import { DEFAULT_TEMPERATURES } from "~/constants";
import { transformTemperature } from "~/utils";

import { client } from "../api";
import { NotFoundException } from "../exceptions";
import type { House } from "../types";

const getHousePositions = async () => {
  const { data, error } = await client.GET("/houses/pos");
  // @ts-expect-error : error in open-api specification
  if (error) throw new Error(error);
  return data?.message ?? [];
};

const getHouse = async (houseId: string): Promise<House> => {
  const { data, error, response } = await client.GET(`/houses/{houseId}`, {
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) throw new NotFoundException(error?.message);
  if (error || !data?.message) throw new Error(error?.message ?? "Error fetching house");

  const house = data.message;

  return {
    ...house,
    _id: house._id!,
    houseName: house.houseName!,
    latitude: house.latitude!,
    longitude: house.longitude!,
    type: data.message.type ?? HouseMessageType.professional,
    absenceTemperature: house.absenceTemperature
      ? transformTemperature(house.absenceTemperature)
      : DEFAULT_TEMPERATURES.ABSENCE,
    frostFreeTemperature: house.frostFreeTemperature
      ? transformTemperature(house.frostFreeTemperature)
      : DEFAULT_TEMPERATURES.FROSTFREE,
    preComfortTemperature: house.preComfortTemperature
      ? transformTemperature(house.preComfortTemperature)
      : DEFAULT_TEMPERATURES.PRECOMFORT,
    isRemoteAccess: house.isRemoteAccess ?? false,
    isFrostFree: house.isFrostFree ?? false,
  };
};

export const house = createQueryKeys("house", {
  pos: {
    queryKey: null,
    queryFn: getHousePositions,
  },
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getHouse(houseId),
  }),
});
