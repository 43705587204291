import * as React from "react";

import { IconProps } from "./types";

export const WarningIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M7.98038 9.5139C8.26903 9.5139 8.51099 9.7531 8.51099 10.0384C8.51099 10.3237 8.26903 10.5628 7.98038 10.5628C7.70234 10.5628 7.44981 10.3237 7.46254 10.051C7.44981 9.75099 7.68961 9.5139 7.98038 9.5139Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M8.12701 5.8621C8.37417 5.92935 8.5279 6.14296 8.5279 6.40205C8.51544 6.5583 8.50504 6.71652 8.49258 6.87279C8.45726 7.46808 8.42195 8.05156 8.38663 8.64691C8.37417 8.84863 8.2101 8.99499 7.9982 8.99499C7.78635 8.99499 7.62017 8.83871 7.60977 8.63505C7.60977 8.51241 7.60977 8.39964 7.59731 8.27506C7.57445 7.89334 7.54954 7.51163 7.52668 7.12992C7.51422 6.88265 7.49142 6.63544 7.4789 6.38821C7.4789 6.29921 7.49142 6.22009 7.52668 6.14098C7.63263 5.91946 7.87979 5.80672 8.12701 5.8621Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.3759 11.6282L8.79255 1.95759C8.62713 1.67106 8.33089 1.5 7.99999 1.5C7.66914 1.5 7.37285 1.67106 7.20742 1.95759L1.62408 11.6282C1.45864 11.9147 1.45864 12.2569 1.62408 12.5434C1.7895 12.8299 2.08579 13.001 2.41665 13.001H13.5833C13.9142 13.001 14.2105 12.8299 14.3759 12.5434C14.5414 12.2569 14.5414 11.9147 14.3759 11.6282ZM13.7154 12.1621C13.699 12.1905 13.66 12.2384 13.5834 12.2384H2.41665C2.34003 12.2384 2.30097 12.1906 2.28457 12.1621C2.26814 12.1336 2.24626 12.0759 2.28457 12.0096L7.86788 2.33891C7.90623 2.27256 7.96711 2.26263 7.99999 2.26263C8.03287 2.26263 8.09375 2.27254 8.13205 2.33891L13.7154 12.0096C13.7538 12.0759 13.7319 12.1336 13.7154 12.1621Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default WarningIcon;
