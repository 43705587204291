/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StatusCodes } from "http-status-codes";

import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";
import type { BoilerRoom, BoilerRoomModule } from "../types";

const getBoilerRooms = async (houseId: string): Promise<BoilerRoomModule[]> => {
  const { data, error, response } = await client.GET("/houses/{houseId}/boilerroomsPos", {
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) return [];
  else if (error || !data?.message) throw new Error(error ?? "Error fetching boiler rooms");

  return data.message.map(m => ({
    ...m,
    _id: m._id!,
    moduleId: m.moduleId!,
    boilerRooms:
      m.boilerRooms?.map(br => ({
        ...br,
        id: br.id!,
        name: br.name!,
        plan: br.plan as BoilerRoom["plan"],
      })) ?? [],
  }));
};

export const boilerRoom = createQueryKeys("boilerRoom", {
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getBoilerRooms(houseId),
  }),
});
