export const variableHouseId = ":houseId";
export const variablePlanId = ":planId";
export const variableRoomId = ":roomId";
export const variableBoilerroomId = ":boilerroomId";
export const variableNetworkId = ":networkId";
export const variableModuleId = ":moduleId";
export const variablePlanningId = ":planningId";
export const variablesGatewayId = ":gatewayId";
export const variablesValveId = ":valveId";
export const variableToken = ":token";

export const app = "/app";
export const houses = `${app}/houses`;
export const house = `${houses}/${variableHouseId}`;
export const dashboard = `${house}/dashboard`;
export const plans = `${house}/plans`;
export const plan = `${plans}/${variablePlanId}`;
export const rooms = `${plan}/rooms`;
export const room = `${rooms}/${variableRoomId}`;
export const boilerrooms = `${house}/boilerrooms`;
export const boilerroom = `${boilerrooms}/${variableBoilerroomId}`;
export const revalidateBoilerroom = `${boilerrooms}/revalidate`;
export const gtb = `${house}/gtb`;
export const consumption = `${house}/consumption`;
export const networks = `${boilerroom}/networks/${variableNetworkId}`;
export const gateways = `${house}/gateways`;
export const modules = `${gateways}/modules`;
export const module = `${modules}/${variableModuleId}`;
export const users = `${house}/users`;
export const maintenance = `${house}/maintenance`;
export const resources = `${house}/resources`;
export const settings = `${house}/settings`;
export const housesSettings = `${settings}/houses`;
export const generalSettings = `${housesSettings}/general`;
export const temperaturesSettigns = `${housesSettings}/temperatures`;
export const frostFreeSettings = `${housesSettings}/frost-free`;
export const seasonSettings = `${housesSettings}/season`;
export const remoteAccessSettings = `${housesSettings}/remote-access`;
export const gtbSettings = `${settings}/gtb`;
export const modulesSettings = `${gtbSettings}/modules`;
export const moduleSettings = `${modulesSettings}/${variableModuleId}`;
export const roomsSettings = `${settings}/rooms`;
export const roomFrostFreeSettings = `${roomsSettings}/frost-free`;
export const functionSettings = `${roomsSettings}/function`;
export const planningSettings = `${roomsSettings}/planning`;
export const groupsSettings = `${roomsSettings}/groups`;
export const meshs = `${house}/meshs`;

export const plannings = `${house}/plannings`;
export const planning = `${plannings}/${variablePlanningId}`;

export const roomsAction = `${house}/rooms`;
export const updateBoilerroomPos = `${house}/modules/${variableModuleId}/boilerrooms/${variableBoilerroomId}`;

export const gateway = `${house}/gateways/${variablesGatewayId}`;
export const valves = `${house}/valves`;
export const valve = `${house}/valves/${variablesValveId}`;

export const preinstall = `${house}/preinstall`;
export const preinstallValves = `${preinstall}/valves`;
export const preinstallGateways = `${preinstall}/gateways`;

export const histovalvesStats = "/histovalvesStats";

export const notify = `${house}/notify`;

export const history = "/history";

export const signIn = "/sign-in";
export const recoverAccount = "/recover-account";
export const enrollAccount = `/enroll-account/${variableToken}`;
export const resetPassword = `/reset-password/${variableToken}`;
export const usedToken = "/used-token";
export const expiredToken = "/expired-token";
