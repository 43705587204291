import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import { Temperature } from "../Temperature";

import styles from "./Tank.module.scss";

interface TankProps {
  orientation?: "up" | "down";
  tankSanitaryHotWaterTemperature?: number;
}

export const Tank: React.FC<TankProps> = ({ orientation = "up", tankSanitaryHotWaterTemperature }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.tank}>
      <div className={cx(styles.tank__ecs, styles[`tank__ecs_orientation_${orientation}`])} />
      <div className={cx(styles.tank__network, styles[`tank__network_orientation_${orientation}`])} />
      <div className={styles.balloon}>
        <span className={styles.balloon__title}>{t("boilerroom.balloon")}</span>
        <Temperature type={"starting"} value={tankSanitaryHotWaterTemperature} />
      </div>
    </div>
  );
};
