import { useState } from "react";

import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import * as yup from "yup";

import type { HousesHouseIdPlansPost200Response, PlansMessageInner } from "@eisox/backend_webapp_api";
import { Button, Card, Circle, FileInput, RoundIcon, TextInput } from "@eisox/design-system";
import { ArrowRightIcon, CrossIcon, ImageIcon, PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { WHITE_PLAN_EISOX } from "~/constants";
import { useAction } from "~/hooks";
import { routeToPlans } from "~/routes/utils";
import { API } from "~/types/API";

import styles from "./PlanInput.module.scss";

const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    name: yup.string().required(formatMessage({ id: "error.required" })),
    plan: yup.mixed(),
  });

type AddPlanType = yup.InferType<ReturnType<typeof schema>>;

interface PlanInputProps {
  houseId: string;
  onAddPlan: (plan: PlansMessageInner) => void;
}

export const PlanInput: React.FC<PlanInputProps> = ({ houseId, onAddPlan }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const addInputStyle = bem("add-input");
  const cardStyle = bem("card");
  const instructionsStyle = bem("instructions");
  const imageStyle = bem("image");

  const [hovered, setHovered] = useState(false);

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { isDirty, errors },
    setValue,
    reset,
  } = useForm<AddPlanType>({
    resolver: yupResolver(schema(formatMessage)),
    defaultValues: {
      name: "",
    },
  });

  const { name } = watch();

  const { submit } = useAction<HousesHouseIdPlansPost200Response>({
    convertFormDataToJson: false,
    onSuccess: response => {
      onAddPlan({ id: response?.message?.planId, name });
      reset();
    },
  });

  const handleHover = () => setHovered(prevState => !prevState);

  const onSubmit = (data: AddPlanType) => {
    const body = new FormData();
    body.append("name", data.name);
    if (data.plan) {
      body.append("image", data.plan);
    } else {
      body.append(
        "image",
        new Blob([Uint8Array.from(atob(WHITE_PLAN_EISOX.split(",")[1]), c => c.charCodeAt(0))], {
          type: WHITE_PLAN_EISOX.split(",")[0].split(":")[1].split(";")[0],
        }),
      );
    }
    submit(body, API.HTTP_METHOD.POST, routeToPlans(houseId));
  };

  return (
    <div className={addInputStyle()}>
      <Card className={cardStyle()}>
        <TextInput
          {...register("name")}
          className={cardStyle("text-input")}
          placeholder={formatMessage({ id: "planInput.levelName" })}
          error={errors.name}
        />

        <Controller
          control={control}
          name="plan"
          render={({ field: { value, onChange } }) => (
            <>
              <h3 className={cardStyle("title")}>{formatMessage({ id: "planInput.title" })}</h3>
              {value === undefined ? (
                <>
                  <div className={instructionsStyle()}>
                    <RoundIcon iconColor="darkBlue">
                      <ImageIcon className={instructionsStyle("image")} />
                    </RoundIcon>
                    <p>
                      {formatMessage(
                        { id: "planInput.instructions" },
                        {
                          l: (
                            <a href="https://pdf2jpg.net/fr/" target="_blank" className={instructionsStyle("link")}>
                              {formatMessage({ id: "planInput.convert" })}
                              <ArrowRightIcon />
                            </a>
                          ),
                        },
                      )}
                    </p>
                  </div>
                  <FileInput
                    className={clsx(cardStyle("file-input"))}
                    placeholder={formatMessage({ id: "planInput.placeholder" })}
                    accept="image/png, image/jpeg"
                    onChange={event => onChange(event.target.files?.[0])}
                    multiple={false}
                  />
                </>
              ) : (
                <div className={imageStyle()} onMouseEnter={handleHover} onMouseLeave={handleHover}>
                  <img className={imageStyle("image")} src={URL.createObjectURL(value)} alt="added plan image" />
                  <div className={imageStyle("blanket", { hovered })} />
                  <Circle
                    size={30}
                    className={imageStyle("delete", { hovered })}
                    onClick={() => setValue("plan", undefined)}
                  >
                    <CrossIcon />
                  </Circle>
                </div>
              )}
            </>
          )}
        />
      </Card>
      <Button
        className={addInputStyle("button", { disabled: !isDirty })}
        text={formatMessage({ id: "planInput.add" })}
        icon={<PlusIcon className={addInputStyle("plus-icon", { disabled: !isDirty })} />}
        onClick={handleSubmit(onSubmit)}
        disabled={!isDirty}
      />
    </div>
  );
};
