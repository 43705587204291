import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import styles from "./Room.module.scss";

type RoomProps = React.ComponentPropsWithRef<"div">;

const Room = forwardRef<HTMLDivElement, RoomProps>(({ className, ...props }, forwardedRef) => (
  <div {...props} ref={forwardedRef} className={cx(styles.room, className)} />
));

Room.displayName = "ManualBalancing.Room";

export { Room };
export type { RoomProps };
