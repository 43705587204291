import { useState } from "react";

import { useIntl } from "react-intl";

import { ActionButtonV2 as ActionButton, Modal } from "@eisox/design-system";
import { ArrowRightIcon, NetworkIcon, PlusIcon } from "@eisox/icons";
import { useBem, useControllableState } from "@eisox/tools";

import { useBoilerRoomContext } from "~/features/BoilerRooms";
import type { HeatingNetwork } from "~/socketio/types";

import { ActionType, NetworkContext, useNetworkReducer } from "../../providers";
import { NetworkForm } from "../NetworkForm";
import type { HeatingNetworkWithBoilerroomName } from "../NetworksDialog";

import styles from "./RoomsDialog.module.scss";

const NAME = "RoomsDialog";

interface RoomsDialogProps {
  heatingNetworkToConfigure: HeatingNetwork[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  defaultOpen?: boolean;
}

export const RoomsDialog: React.FC<RoomsDialogProps> = ({
  heatingNetworkToConfigure,
  open: openProp,
  onOpenChange,
  defaultOpen,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const roomsDialogStyle = bem("rooms-dialog");
  const headerStyle = bem("header");
  const content = bem("content");

  const { boilerRooms: boilerrooms, useUpdateBoilerRoom } = useBoilerRoomContext(NAME);

  const { state: networksState, dispatch: dispatchNetworkContext } = useNetworkReducer(boilerrooms);

  const heatingNetworks: HeatingNetworkWithBoilerroomName[] = heatingNetworkToConfigure.map(hn => {
    return {
      ...hn,
      boilerroomName: boilerrooms?.find(b => b.heatingNetworks?.map(hn => hn.id).includes(hn.id))?.name,
    };
  });

  const [open, setOpen] = useControllableState({ prop: openProp, onChange: onOpenChange, defaultProp: defaultOpen });
  const [step, setStep] = useState(1);
  const [currentHeatingNetwork, setCurrentHeatingNetwork] = useState(heatingNetworks[step - 1]);

  const { mutate } = useUpdateBoilerRoom({ onSuccess: () => handleOpenChange(false) });

  const lastStep = step === heatingNetworks.length;

  const initValvesAndAmbientSensorsToEmptyArrayIfNotSet = () => {
    const actualHN = networksState.transformedState
      .flatMap(b => b.heatingNetworks)
      .find(hn => hn?.id === currentHeatingNetwork.id);
    if (!actualHN?.idValves) {
      dispatchNetworkContext({
        type: ActionType.UPDATE_ID_VALVES,
        payload: { networkId: currentHeatingNetwork.id, ids: [] },
      });
      dispatchNetworkContext({
        type: ActionType.UPDATE_AMBIENT_SENSOR_IDS,
        payload: { networkId: currentHeatingNetwork.id, ids: [] },
      });
    }
  };

  const handleChangeStep = (step: number) => {
    initValvesAndAmbientSensorsToEmptyArrayIfNotSet();
    setStep(step);
    setCurrentHeatingNetwork(heatingNetworks[step - 1]);
  };

  const handleAddConfiguration = () => {
    initValvesAndAmbientSensorsToEmptyArrayIfNotSet();
    mutate(networksState.transformedState);
  };

  const handleOpenChange = (open: boolean) => {
    dispatchNetworkContext({ type: ActionType.RESET });
    setOpen(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Content className={roomsDialogStyle()}>
        <Modal.Header
          title={formatMessage({ id: "boilerRoom.dialog.rooms.header.title" })}
          subtitle={formatMessage({ id: "boilerRoom.dialog.rooms.header.subtitle" })}
          icon={<NetworkIcon />}
        >
          <div className={headerStyle()}>
            <p className={headerStyle("step")}>
              {formatMessage({ id: "boilerRoom.dialog.rooms.header.step" }, { r: step, t: heatingNetworks.length })}
            </p>
            {step > 1 ? (
              <ActionButton
                variant="cancel"
                onClick={() => {
                  if (step === 1) {
                    setOpen(false);
                  } else {
                    handleChangeStep(step - 1);
                  }
                }}
              >
                {formatMessage({ id: "boilerRoom.dialog.rooms.header.back" })}
              </ActionButton>
            ) : (
              <Modal.Close asChild>
                <ActionButton variant="cancel">
                  {formatMessage({ id: "boilerRoom.dialog.rooms.header.cancel" })}
                </ActionButton>
              </Modal.Close>
            )}
            <ActionButton rounded onClick={lastStep ? handleAddConfiguration : () => handleChangeStep(step + 1)}>
              {formatMessage({
                id: lastStep ? "boilerRoom.dialog.rooms.header.add" : "boilerRoom.dialog.rooms.header.next",
              })}
              {lastStep ? <PlusIcon /> : <ArrowRightIcon />}
            </ActionButton>
          </div>
        </Modal.Header>
        <NetworkContext.Provider value={{ state: networksState, dispatch: dispatchNetworkContext }}>
          <NetworkForm className={content()} heatingNetwork={currentHeatingNetwork} />
        </NetworkContext.Provider>
      </Modal.Content>
    </Modal.Root>
  );
};
