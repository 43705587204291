import { useBem } from "@eisox/tools";

import styles from "./DataInformation.module.scss";

export interface DataInformationProps {
  label: string | React.ReactNode;
  value?: string | React.ReactNode;
  auth?: boolean;
}

export const DataInformation: React.FC<DataInformationProps & React.HTMLAttributes<HTMLDivElement>> = ({
  label,
  value,
  auth = true,
  ...props
}) => {
  const bem = useBem(styles);
  const dataInformationStyle = bem("data-information");

  return (
    auth && (
      <div {...props} className={dataInformationStyle()}>
        <div className={dataInformationStyle("label")}>{label}</div>
        <div className={dataInformationStyle("value")}>{value || "-"}</div>
      </div>
    )
  );
};
