import { useTranslation } from "react-i18next";

import { ActionButtonV2, UnProtectedRouteResponsiveCard } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";

import { InvalidToken } from "~/apiV2";
import type { RecoverAccountRequestParams } from "~/apiV2";
import { env } from "~/configuration";

import styles from "./UsedToken.module.scss";

interface PageProps {
  type: InvalidToken;
  email: string;
  submit: (params: RecoverAccountRequestParams) => void;
  onLogin: () => void;
}

const UsedTokenPage: React.FC<PageProps> = ({ email, type, submit, onLogin }) => {
  const { t } = useTranslation();

  return (
    <UnProtectedRouteResponsiveCard version={env.VERSION}>
      <div className={styles.usedToken}>
        <p className={styles.usedToken__text}>{t("usedToken.text", { context: type })}</p>
        <div className={styles.usedToken__buttons}>
          {type === InvalidToken.ACCOUNT && (
            <ActionButtonV2 rounded onClick={() => submit({ email })}>
              {t("usedToken.resetPassword")}
              <ArrowRightIcon />
            </ActionButtonV2>
          )}
          <ActionButtonV2 rounded onClick={onLogin}>
            {t("usedToken.login")}
            <ArrowRightIcon />
          </ActionButtonV2>
        </div>
      </div>
    </UnProtectedRouteResponsiveCard>
  );
};

export { UsedTokenPage };
