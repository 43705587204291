import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import dayjs from "@eisox/dayjs";
import { createContext } from "@eisox/tools";
import { PostModuleHistoryFields } from "@eisox/webapp-api-specification";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { loader } from "~/UI/screens/House";
import { queries } from "~/apiV2";
import { idLoaderHouse } from "~/routes/utils";
import type { BoilerRoom, BoilerroomDataRes } from "~/socketio/types";

import { convertAndSortDates, getModuleId, getSameGatewayModuleIds } from "../helpers";
import { BoilerRoomProvider } from "./BoilerRoomProvider";

interface BoilerRoomHistoryContextValue {
  dates: string[];
  onDateChange: (date: string) => void;
}

const NAME = "History";

const [BoilerRoomHistoryProvider, useBoilerRoomHistoryProvider] = createContext<BoilerRoomHistoryContextValue>(NAME);

interface HistoryProviderProps {
  houseId: string;
  boilerRoomId: string;
}

const HistoryProvider: React.FC<HistoryProviderProps> = ({ houseId, boilerRoomId }) => {
  const { t } = useTranslation();

  const { modules, boilerroomPos } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof loader>>;

  const [data, setData] = useState<BoilerroomDataRes>();

  const moduleId = useMemo(() => getModuleId(boilerRoomId, boilerroomPos), [boilerRoomId, boilerroomPos]);
  const sameGatewayModuleIds = useMemo(
    () => getSameGatewayModuleIds(boilerRoomId, modules, boilerroomPos),
    [boilerRoomId, modules, boilerroomPos],
  );

  const [date, setDate] = useState<string>();

  const startDate = useMemo(() => dayjs().subtract(30, "d").startOf("day").toISOString(), []);
  const endDate = useMemo(() => dayjs().toISOString(), []);

  const queryClient = useQueryClient();

  const { data: dates } = useQuery({
    ...queries.history.module(houseId, {
      ranges: [{ startDate, endDate }],
      fields: [PostModuleHistoryFields.createdAt, PostModuleHistoryFields.moduleIds],
      moduleIds: sameGatewayModuleIds,
    }),
    select: data => {
      const dates = convertAndSortDates(data).get(moduleId);
      return {
        dates,
        mostRecentDate: dates?.[0] ?? null,
      };
    },
  });

  const handleChangeDate = (date: string) => {
    setDate(date);
  };

  useEffect(() => {
    if (dates?.mostRecentDate) setDate(dates.mostRecentDate);
  }, [dates?.mostRecentDate]);

  useEffect(() => {
    const getBoilerRoomData = async () => {
      try {
        const data = await queryClient.fetchQuery({
          ...queries.history.module(houseId, {
            ranges: [{ startDate: date, endDate: date }],
            moduleIds: [moduleId],
          }),
        });
        setData({ date: data[0].createdAt!, boilerRooms: data[0].boilerRooms! as BoilerRoom[] });
      } catch (error) {
        toast.error(t("error.unKnowError.message"));
      }
    };

    if (date) void getBoilerRoomData();
  }, [houseId, moduleId, queryClient, date]);

  /**
   ** @description This hook is used to update the boiler room in history mode.
   * It will throw an error because we can't update boiler room in history mode
   * @returns a mutation function that throws an error because we can't update boiler room in history mode
   */
  const useUpdateBoilerRoom = () => {
    return useMutation({
      mutationFn: (_boilerRoom: BoilerRoom[]) => {
        return Promise.reject(new Error("Can't update boiler room in history mode"));
      },
    });
  };

  return (
    <BoilerRoomHistoryProvider dates={dates?.dates ?? []} onDateChange={handleChangeDate}>
      <BoilerRoomProvider
        houseId={houseId}
        boilerRoomId={boilerRoomId}
        boilerRooms={data?.boilerRooms}
        useUpdateBoilerRoom={useUpdateBoilerRoom}
        history={true}
        date={date}
      />
    </BoilerRoomHistoryProvider>
  );
};

export { HistoryProvider, useBoilerRoomHistoryProvider };
export type { HistoryProviderProps };
