import { ActionFunctionArgs } from "react-router-dom";

import dayjs from "@eisox/dayjs";

import { createMeshs, deleteMesh, updateMesh } from "~/api/mesh";
import { RestError } from "~/constants";
import { SUCCESS_FETCH, getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { FetchResponse } from "~/helpers/communication/fetchType";
import { API } from "~/types/API";

import { PutMeshType } from "../Plans/components";

export const action = async ({ request, params }: ActionFunctionArgs): Promise<API.Response<{} | null>> => {
  const { houseId } = params as { houseId: string };
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.PUT:
      const body: PutMeshType = JSON.parse(formData.get("json") as string);

      const meshsToDelete = body.meshs.filter(m => m.deleted);
      const meshsToUpdate = body.meshs.filter(m => m.updated);
      const meshsToCreate = body.meshs.filter(m => m.new);

      const deletePromises = meshsToDelete.map(mtd => deleteMesh(houseId, mtd.meshId));
      const updatePromises = meshsToUpdate.map(mtu => updateMesh(houseId, mtu.meshId, mtu.name));
      const createPromise = createMeshs(
        houseId,
        meshsToCreate.map(mtc => mtc.name),
      );

      const results = (await Promise.all([...deletePromises, ...updatePromises, createPromise])) as unknown as Array<
        FetchResponse<string>
      >;

      const message = [
        {
          status: results.every(r => r.type === SUCCESS_FETCH) ? API.HTTP_STATUS.OK : API.HTTP_STATUS.MULTI_STATUS,
          message: results.map(r => ({
            status: getHttpStatusByFetchStatus(r.type) as API.HTTP_STATUS,
            message: r.result,
          })),
        },
      ];

      return {
        date: dayjs(),
        restError: message[0].status === API.HTTP_STATUS.MULTI_STATUS ? RestError.MULTIPLE_RESPONSES : undefined,
        url: request.url,
        method: API.HTTP_METHOD.POST,
        body: formData.get("json") as string,
        message,
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
