import type { HouseMessage } from "@eisox/backend_webapp_api";
import { isRoomFrostFree } from "@eisox/rooms";

import type { RoomsWithProblem } from "~/UI/screens/House";
import type { FormType } from "~/UI/screens/Settings/pages/FrostFree";
import { Mode } from "~/UI/screens/Settings/pages/FrostFree";

export const getInitialState = (house: HouseMessage, isFrostFreePeriodsAllowed: boolean): FormType => ({
  mode: house.isFrostFree ? Mode.ON : isFrostFreePeriodsAllowed ? Mode.PERIODS : Mode.OFF,
  frostFreeTemperature: house.frostFreeTemperature,
  setpointStartFrostFree: house.setpointStartFrostFree,
  setpointStartFrostFreeAmb: house.setpointStartFrostFreeAmb,
  temporisationFrostFreeMode: house.temporisationFrostFreeMode,
  reducedFFTemperature: house.reducedFFTemperature,
  frostFreePeriods: house.frostFreePeriods
    ? house.frostFreePeriods.map(p => ({ begin: p.begin!, end: p.end!, added: false, removed: false }))
    : [],
});

export const getFrostFreeRoomsNumber = (room: RoomsWithProblem[]): number =>
  room.filter(r => isRoomFrostFree({ ...r }).isFrostFree).length;
