export const colorVariable = {
  primaryColor: "#1100FA",
  primaryColorDark: "#0B01C1",
  secondaryColor: "#122138",
  tertiaryColor: "#FFFFFF",
  disabledColor: "#D0D0D0",
  quaternaryColor: "#828282",
  quinaryColor: "#F3F3F9",
  dangerColor: "#FA0000",
  dangerColorDark: "#C70000",
  warningColor: "#FA7800",
  infoColor: "#00AFFA",
};
