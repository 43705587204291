import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import type { Option } from "@eisox/design-system";
import { ActionButton, Modal, Radio, Select, Switch, TextInput } from "@eisox/design-system";
import { BoilerRoomIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import Warning from "~/assets/svg/boilerroomWarning.svg?react";
import Commutator from "~/assets/svg/commutator.svg?react";
import type { Boiler } from "~/socketio/types";
import { Command, CommandMan, Speed } from "~/socketio/types";
import { getBoilerState } from "~/utils/schemeUtils";

import { useBoilerRoomContext } from "../../providers";

import styles from "./BoilerDialog.module.scss";

const NAME = "BoilerDialog";

interface BoilerDialogProps {
  boiler: Boiler;
  children?: React.ReactNode;
}

export const BoilerDialog: React.FC<BoilerDialogProps> = ({ boiler, children }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const boilerDialogStyle = bem("boiler-dialog");
  const contentStyle = bem("content");
  const commandStyle = bem("command");
  const stateStyle = bem("state");

  const { currentBoilerRoom, useUpdateBoilerRoom, history } = useBoilerRoomContext(NAME);

  const [open, setOpen] = useState(false);

  const state = getBoilerState(boiler.state, boiler.commandAutomate, boiler.commandMan, boiler.defect);
  const mismatchError = state.state === "mismatch";

  const { mutate } = useUpdateBoilerRoom({ onSuccess: () => handleOpenChange(false) });

  const {
    control,
    register,
    watch,
    formState: { isDirty, dirtyFields, errors },
    handleSubmit,
    reset,
  } = useForm<Boiler>({
    defaultValues: {
      name: boiler.name,
      command: boiler.command,
    },
  });

  const command = watch("command");

  const onSubmit = (data: Boiler) => {
    const body: Boiler = { id: boiler.id };
    if (dirtyFields.name) body.name = data.name;
    if (dirtyFields.command) body.command = data.command;
    mutate([{ id: currentBoilerRoom!.id, boilers: [body] }]);
  };

  const handleOpenChange = (open: boolean) => {
    reset({ name: boiler.name, command: boiler.command });
    setOpen(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={boilerDialogStyle()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            title={boiler.name ?? formatMessage({ id: "boilerRoom.dialog.boiler.boiler" })}
            subtitle={formatMessage({ id: "boilerRoom.dialog.boiler.subtitle" })}
            icon={<BoilerRoomIcon />}
          >
            <Modal.Close asChild>
              <ActionButton
                className={boilerDialogStyle("cancel-button")}
                variant="cancel"
                text={formatMessage({ id: "boilerRoom.dialog.boiler.cancel" })}
              />
            </Modal.Close>
            <ActionButton
              type="submit"
              rounded
              text={formatMessage({ id: "boilerRoom.dialog.boiler.save" })}
              disabled={!isDirty || history}
            />
          </Modal.Header>
          <div className={contentStyle()}>
            <label className={contentStyle("label")}>
              {formatMessage({ id: "boilerRoom.dialog.boiler.name" })}
              <TextInput
                {...register("name", { required: true })}
                error={errors.name}
                className={contentStyle("text-input")}
                disabled={history}
              />
            </label>
            {boiler.command && (
              <p className={contentStyle("label")}>
                {formatMessage({ id: "boilerRoom.dialog.boiler.command.label" })}
                <div className={commandStyle()}>
                  {boiler.commandMan === CommandMan.MANUAL ? (
                    <p className={commandStyle("label")}>
                      {formatMessage({ id: "boilerRoom.dialog.boiler.manual" })}
                      <Tooltip content={formatMessage({ id: "boilerRoom.dialog.boiler.commutator" })}>
                        <Commutator className={commandStyle("commutator")} />
                      </Tooltip>
                    </p>
                  ) : (
                    <>
                      <Controller
                        control={control}
                        name="command"
                        render={({ field: { value, onChange } }) => (
                          <Select
                            triggerClassName={commandStyle("select")}
                            value={value?.toString()}
                            options={[
                              {
                                value: Command.AUTO.toString(),
                                name: formatMessage({ id: "boilerRoom.dialog.boiler.auto" }),
                              },
                              {
                                value: Command.OFF.toString(),
                                name: formatMessage({ id: "boilerRoom.dialog.boiler.forcing" }),
                              },
                            ]}
                            renderOption={(option: Option) => <p>{option.name}</p>}
                            renderValue={(value?: string) => (
                              <p>
                                {formatMessage({
                                  id: `boilerRoom.dialog.boiler.${
                                    value && (parseInt(value) as Command) === Command.AUTO ? "auto" : "forcing"
                                  }`,
                                })}
                              </p>
                            )}
                            onChange={(value?: string) => value && onChange(parseInt(value))}
                            disabled={history}
                          />
                        )}
                      />
                      {command !== Command.AUTO && (
                        <>
                          <label className={commandStyle("label")}>
                            <Controller
                              control={control}
                              name="command"
                              render={({ field: { value, onChange } }) => {
                                const checked = !!value && [Command.NORMAL, Command.FAST].includes(value);
                                return (
                                  <Switch
                                    checked={checked}
                                    onCheckedChange={checked => onChange(checked ? Command.NORMAL : Command.OFF)}
                                    disabled={history}
                                  />
                                );
                              }}
                            />
                            {formatMessage({ id: `boilerRoom.dialog.boiler.state.${command === Command.OFF ? 0 : 1}` })}
                          </label>
                          {boiler.speed && boiler.speed !== Speed.UNCONTROLLABLE && (
                            <div className={commandStyle("radio-group")}>
                              <label className={commandStyle("label")}>
                                <Controller
                                  control={control}
                                  name="command"
                                  render={({ field: { value, onChange } }) => (
                                    <Radio
                                      checked={value === Command.NORMAL}
                                      value={Command.NORMAL}
                                      onChange={e => onChange(e.target.value ? Command.NORMAL : Command.FAST)}
                                      disabled={history}
                                    />
                                  )}
                                />
                                {formatMessage({ id: "boilerRoom.dialog.boiler.command.2" })}
                              </label>
                              <label className={commandStyle("label")}>
                                <Controller
                                  control={control}
                                  name="command"
                                  render={({ field: { value, onChange } }) => (
                                    <Radio
                                      checked={value === Command.FAST}
                                      value={Command.FAST}
                                      onChange={e => onChange(e.target.value ? Command.FAST : Command.NORMAL)}
                                      disabled={history}
                                    />
                                  )}
                                />
                                {formatMessage({ id: "boilerRoom.dialog.boiler.command.3" })}
                              </label>
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </p>
            )}
            <p className={contentStyle("label")}>
              {formatMessage({ id: "boilerRoom.dialog.boiler.state.label" })}
              <div className={stateStyle()}>
                {!mismatchError && state.state !== null && (
                  <p className={stateStyle("value")}>
                    {formatMessage({ id: `boilerRoom.dialog.boiler.state.${state.state}` })}
                  </p>
                )}
                {boiler.defect && (
                  <p className={stateStyle("defect")}>{formatMessage({ id: "boilerRoom.dialog.boiler.defect" })}</p>
                )}
                {mismatchError && (
                  <Tooltip content={mismatchError && formatMessage({ id: "boilerRoom.dialog.boiler.mismatch" })}>
                    <div className={stateStyle("error")} />
                  </Tooltip>
                )}
                {state.error && (
                  <Tooltip content={state.error}>
                    <Warning className={stateStyle("warning")} />
                  </Tooltip>
                )}
                {boiler.speed && boiler.speed !== Speed.UNCONTROLLABLE && (
                  <p className={stateStyle("value")}>
                    {formatMessage({ id: `boilerRoom.dialog.boiler.speed.${boiler.speed}` })}
                  </p>
                )}
              </div>
            </p>
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
