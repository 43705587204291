import type { FieldNamesMarkedBoolean } from "react-hook-form";

import type { PatchRoom } from "@eisox/backend_webapp_api";
import { PatchRoomRoomTypeEnum } from "@eisox/backend_webapp_api";

import { DEFAULT_TEMPERATURES } from "~/constants";
import type { Permission } from "~/utils";

import type { RoomsWithProblem } from "../../House";
import type { FormType } from "../components";

export const isPatchRoomRoomTypeEnum = (value: any): value is PatchRoomRoomTypeEnum => {
  return Object.values(PatchRoomRoomTypeEnum).includes(value);
};

export const roomToPatchRoomMapper = (room: RoomsWithProblem): FormType => {
  return {
    ...room,
    name: room.name!,
    comfortTemperature: room.comfortTemperature,
    nightTemperature: room.nightTemperature,
    roomType: isPatchRoomRoomTypeEnum(room.roomType) ? room.roomType : PatchRoomRoomTypeEnum.Office,
    frostFreePeriods: room.frostFreePeriods
      ? room.frostFreePeriods.map(ffp => ({ begin: ffp.begin!, end: ffp.end!, added: false, removed: false }))
      : [],
  };
};

export const getUpdatedData = (
  patchRoomDto: FormType,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<PatchRoom>>>,
): PatchRoom => {
  const body: PatchRoom = {};
  if (dirtyFields.name) body.name = patchRoomDto.name;
  if (dirtyFields.roomType) body.roomType = patchRoomDto.roomType as PatchRoomRoomTypeEnum;
  if (dirtyFields.comfortTemperature)
    body.comfortTemperature = patchRoomDto.comfortTemperature
      ? patchRoomDto.comfortTemperature * 100
      : DEFAULT_TEMPERATURES.COMFORT * 100;
  if (dirtyFields.nightTemperature)
    body.nightTemperature = patchRoomDto.nightTemperature
      ? patchRoomDto.nightTemperature * 100
      : DEFAULT_TEMPERATURES.NIGHT * 100;
  if (dirtyFields.isSwitchEnabled) body.isSwitchEnabled = patchRoomDto.isSwitchEnabled;
  if (dirtyFields.isFrostFree) body.isFrostFree = patchRoomDto.isFrostFree;
  if (dirtyFields.isPreComfRoom) body.isPreComfRoom = patchRoomDto.isPreComfRoom;
  if (dirtyFields.isAutoPrecomf) body.isAutoPrecomf = patchRoomDto.isAutoPrecomf;
  if (dirtyFields.frostFreePeriods)
    // delete react hook form id
    body.frostFreePeriods = patchRoomDto.frostFreePeriods
      .filter(ffp => !ffp.removed)
      .map(period => ({ begin: period.begin, end: period.end }));
  return body;
};

export const hasPermissionRoomData = (permissions: Permission) =>
  permissions.valve?.stateData?.autoConf?.read &&
  permissions.valve.stateData.autoPreConf?.read &&
  permissions.valve.stateData.anticipation?.read &&
  permissions.valve.stateData.openWin?.read;
