import { useTranslation } from "react-i18next";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import { useMutation } from "@tanstack/react-query";

import { recoverAccount } from "~/apiV2";
import { ExpiredTokenPage } from "~/pages";
import { routeToSignIn } from "~/routes/utils";

import type { loader } from "../loader";
import { idInvalidTokenLoader } from "../route";

const ExpiredToken = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { type, email } = useRouteLoaderData(idInvalidTokenLoader) as LoaderData<typeof loader>;

  const { mutate } = useMutation({
    mutationFn: recoverAccount,
    onSuccess: () => {
      toast.success(t("account.mailSent"));
      navigate(routeToSignIn());
    },
  });

  return <ExpiredTokenPage email={email} type={type} submit={mutate} />;
};

export { ExpiredToken };
