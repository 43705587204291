import * as React from "react";

import { IconProps } from "./types";

export const SunIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M7.99972 10.6427C6.54236 10.6427 5.35669 9.45712 5.35669 7.99971C5.35669 6.54233 6.54236 5.35668 7.99972 5.35668C9.45706 5.35668 10.6427 6.54235 10.6427 7.99971C10.6427 9.45705 9.45706 10.6427 7.99972 10.6427ZM7.99972 6.11837C6.96235 6.11837 6.11838 6.96235 6.11838 7.99971C6.11838 9.0371 6.96233 9.88103 7.99972 9.88103C9.03711 9.88103 9.88104 9.0371 9.88104 7.99971C9.88104 6.96235 9.03711 6.11837 7.99972 6.11837Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M7.99974 4.44699C7.78942 4.44699 7.6189 4.27647 7.6189 4.06614V1.88084C7.6189 1.67052 7.78942 1.5 7.99974 1.5C8.21007 1.5 8.38056 1.67052 8.38056 1.88084V4.06614C8.38056 4.27647 8.21007 4.44699 7.99974 4.44699Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M5.21862 5.59944C5.12115 5.59944 5.02368 5.56227 4.94934 5.4879L3.40406 3.94264C3.25533 3.79391 3.25533 3.55279 3.40406 3.40405C3.55276 3.25533 3.79392 3.25533 3.94264 3.40405L5.48791 4.94929C5.63663 5.09801 5.63663 5.33914 5.48791 5.48788C5.41356 5.56224 5.31609 5.59944 5.21862 5.59944Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M4.06614 8.38056H1.88084C1.67052 8.38056 1.5 8.21007 1.5 7.99974C1.5 7.78942 1.67052 7.6189 1.88084 7.6189H4.06614C4.27647 7.6189 4.44699 7.78942 4.44699 7.99974C4.44699 8.21007 4.27647 8.38056 4.06614 8.38056Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M3.67334 12.7072C3.57587 12.7072 3.4784 12.6701 3.40406 12.5957C3.25533 12.4469 3.25533 12.2059 3.40406 12.0571L4.94932 10.5119C5.09804 10.3632 5.3392 10.3632 5.48791 10.5119C5.63664 10.6606 5.63664 10.9017 5.48791 11.0505L3.94262 12.5957C3.86828 12.6701 3.77081 12.7072 3.67334 12.7072Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M7.99974 14.4994C7.78942 14.4994 7.6189 14.3289 7.6189 14.1185V11.9333C7.6189 11.7229 7.78942 11.5524 7.99974 11.5524C8.21007 11.5524 8.38056 11.7229 8.38056 11.9333V14.1185C8.38056 14.3289 8.21007 14.4994 7.99974 14.4994Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M12.3264 12.7072C12.2289 12.7072 12.1315 12.6701 12.0571 12.5957L10.5119 11.0505C10.3632 10.9017 10.3632 10.6606 10.5119 10.5119C10.6606 10.3632 10.9018 10.3632 11.0505 10.5119L12.5957 12.0571C12.7444 12.2059 12.7444 12.447 12.5957 12.5957C12.5214 12.6701 12.4239 12.7072 12.3264 12.7072Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.1192 8.38056H11.9338C11.7235 8.38056 11.553 8.21007 11.553 7.99974C11.553 7.78942 11.7235 7.6189 11.9338 7.6189H14.1192C14.3295 7.6189 14.5 7.78942 14.5 7.99974C14.5 8.21007 14.3295 8.38056 14.1192 8.38056Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M10.7812 5.59942C10.6837 5.59942 10.5863 5.56225 10.5119 5.48786C10.3632 5.33913 10.3632 5.098 10.5119 4.94927L12.0571 3.40404C12.2059 3.25535 12.447 3.25531 12.5957 3.40404C12.7444 3.55277 12.7444 3.7939 12.5957 3.94262L11.0505 5.48786C10.9761 5.56222 10.8786 5.59942 10.7812 5.59942Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default SunIcon;
