import { Trans, useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { primary } from "@eisox/colors";
import { Typography } from "@eisox/design-system";
import { BinIcon } from "@eisox/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { DeleteDialog } from "~/UI";
import type { UpdateRolesRequestBody } from "~/apiV2";
import { queries, udapteRoles } from "~/apiV2";

import { getUpdateRoleRequestBody } from "../../helpers";
import type { User } from "../../helpers";

import styles from "./DeleteUserDialog.module.scss";

interface DeleteUserDialogProps {
  user: User;
  houseId: string;
  houseName: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const DeleteUserDialog: React.FC<DeleteUserDialogProps> = ({ user, houseId, houseName, open, onOpenChange }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const { mutate: deleteUser } = useMutation({
    mutationFn: ({ email, body }: { email: string; body: UpdateRolesRequestBody }) => udapteRoles(email, body),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queries.user.rolesInHouses.queryKey }),
        queryClient.invalidateQueries({ queryKey: queries.user.houseUsers(houseId).queryKey }),
      ]);
      onOpenChange(false);
    },
    onError: () => toast.error(t("error.unKnowError.message")),
  });

  const userName = user.firstName || user.lastName ? [user.firstName, user.lastName].join(" ") : user.email;

  const handleDelete = () => {
    const email = user.email;
    const role = user.roles[0];
    const body: UpdateRolesRequestBody = getUpdateRoleRequestBody({ [role]: false }, [houseId]);
    deleteUser({ email, body });
  };

  return (
    <DeleteDialog.Root open={open} onOpenChange={onOpenChange}>
      <DeleteDialog.Content icon={<BinIcon style={{ color: primary.red }} />} onDelete={handleDelete}>
        <Typography className={styles.deleteDialog__description}>
          <Trans
            i18nKey={"users.delete.title"}
            values={{ u: userName, h: houseName }}
            components={{ bold: <span /> }}
          />
        </Typography>
        <DeleteDialog.Validator />
      </DeleteDialog.Content>
    </DeleteDialog.Root>
  );
};

export { DeleteUserDialog };
export type { DeleteUserDialogProps };
