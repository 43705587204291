import { forwardRef } from "react";

import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import type { ButtonProps } from "../ButtonV2";
import { Button } from "../ButtonV2";

import styles from "./ActionButton.module.scss";

const actionButtonVariants = cva(styles.actionButton, {
  variants: {
    variant: {
      primary: styles.actionButton_variant_primary,
      danger: styles.actionButton_variant_danger,
      cancel: styles.actionButton_variant_cancel,
    },
    rounded: {
      true: styles.actionButton_rounded,
    },
  },
  defaultVariants: {
    variant: "primary",
    rounded: false,
  },
});

interface ActionButtonProps extends ButtonProps, VariantProps<typeof actionButtonVariants> {}

const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ variant, rounded, className, ...props }, forwardedRef) => (
    <Button {...props} ref={forwardedRef} className={actionButtonVariants({ variant, rounded, className })} />
  ),
);

ActionButton.displayName = "ActionButton";

export default ActionButton;
export { ActionButton };
export type { ActionButtonProps };
