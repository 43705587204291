import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useMutation } from "@tanstack/react-query";

import { recoverAccount } from "~/apiV2";
import { RecoverAccountPage } from "~/pages";
import { routeToSignIn } from "~/routes/utils";

const RecoverAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: recoverAccount,
    onSuccess: _ => {
      toast.success(t("account.mailSent"));
      navigate(routeToSignIn());
    },
  });

  return <RecoverAccountPage submit={mutate} onCancel={() => navigate(routeToSignIn())} />;
};

export { RecoverAccount };
