import type { LoaderFunctionArgs } from "react-router-dom";

import type { QueryClient } from "@tanstack/react-query";

import { queries } from "~/apiV2";

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const { houseId } = params as { houseId: string };

    await Promise.all([
      queryClient.ensureQueryData(queries.user.rolesInHouses),
      queryClient.ensureQueryData(queries.user.houseUsers(houseId)),
      queryClient.ensureQueryData(queries.user.creators),
    ]);

    return { houseId };
  };
