import type { GatewaysWithProblem, ValvesWithProblem } from "~/UI/screens/House";

/*
 * For the following enums, CAUTION they are used to automatically define
 * the lexicon in the pdf, color and traduction for intervention plan tool.
 * If an addition or modification is made,
 * the color names in colors.ts, the translations in interventionPlanTool.pdf.lexique for the pdf
 * And the name of $elements in ItemIcon component style, the translation in interventionPlanTool.plan.lexique.valve/gateway
 * must also be modified.
 */

export enum ValveProblemType {
  CHANGE_BATTERY = "CHANGE_BATTERY",
  RESTART_VALVE = "RESTART_VALVE",
  REVIEW_VALVE = "REVIEW_VALVE",
  UNCLOG_CHECK_RADIO_TRANSMISSION = "UNCLOG_CHECK_RADIO_TRANSMISSION",
  UNCLOG_CHANGE_BODY = "UNCLOG_CHANGE_BODY",
  VERIFY_GATEWAY = "VERIFY_GATEWAY", // same as ProblemTypeGateway.VERIFY_GATEWAY
  REPLACE_VALVE = "REPLACE_VALVE",
  TO_INSTALL = "TO_INSTALL",
}

export enum GatewayProblemType {
  VERIFY_GATEWAY = "VERIFY_GATEWAY",
  REPLACE_GATEWAY = "REPLACE_GATEWAY",
}

export enum InterventionPlanToolStatus {
  REFRESH = "REFRESH",
  IDLE = "IDLE",
}

export interface Contact {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface Equipment {
  id: string;
  name: string;
  canEditName: boolean;
  min: number;
  max: number;
}

export interface Note {
  id: string;
  x: number;
  y: number;
  messages: string[];
}

export interface ProblemInstance<T> {
  type?: T;
  isForcing: boolean;
  isSolved: boolean;
}

export interface Plan {
  id: string;
  planId: string;
  name: string;
  image: string;
}

export interface ValveInterventionTools extends ValvesWithProblem {
  problemInstance: ProblemInstance<ValveProblemType>;
  unclogging: boolean;
  notes: Note[];
}

export interface GatewayInterventionTools extends GatewaysWithProblem {
  problemInstance: ProblemInstance<GatewayProblemType>;
  notes: Note[];
}

export interface InterventionPlanState {
  houseName: string;
  houseId: string;
  adress: string;
  planifiedAt: {
    start?: string;
    end?: string;
  };
  editAt: string;
  contact: Contact;
  isForEisoxProvider: boolean;
  operatingTime: number;
  commentsIntervention?: string;
  equipments: Equipment[];
  valves: ValveInterventionTools[];
  gateways: GatewayInterventionTools[];
  plans: Plan[];
  version: string;
}
