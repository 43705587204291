import { Fragment, forwardRef, useCallback, useState } from "react";

import clsx from "clsx";
import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import dayjs from "@eisox/dayjs";
import { Circle } from "@eisox/design-system";
import {
  BarChartIcon,
  NutIcon,
  SunIcon,
  TapIcon,
  TargetIcon,
  ThermometerIcon,
  ValveIcon,
  WaterDropIcon,
  WindIcon,
} from "@eisox/icons";
import { useBem } from "@eisox/tools";
import {
  VALVE_HARDWARE_VERSION_ENUM,
  getAirQualityThreshold,
  getAirQualityV5Threshold,
  getHumidityThreshold,
  getLightThreshold,
  getOpenningThreshold,
  getTemperatureThreshold,
} from "@eisox/valves";

import { Tooltip } from "~/UI/components";
import type { houseLoader } from "~/UI/screens";
import type { ValvesWithProblem } from "~/UI/screens/House";
import { hasPermissionRoomData } from "~/UI/screens/Room/utils";
import { getRoomData } from "~/UI/screens/Room/utils/getRoomData";
import { ValveHistoryDialog } from "~/features/ValveHistory";
import { usePermissionsContext } from "~/providers";
import { idLoaderHouse } from "~/routes/utils";
import { isValveHardwareIsUpper } from "~/utils";
import { kebabCaseToCamelCase } from "~/utils/stringUtils";

import { ValveListDrawer } from "../ListDrawer";

import styles from "./Valve.module.scss";

interface SensorProps {
  value?: string | number;
  unity?: string;
  icon: React.ReactElement;
  color?: string;
}

const Sensor: React.FC<SensorProps> = ({ value, unity, icon, color }) => {
  const bem = useBem(styles);
  const sensorStyle = bem("sensor");

  return (
    <div className={sensorStyle()}>
      <Circle size={50} className={sensorStyle("icon", { color: color ?? "gray" })}>
        {icon}
      </Circle>
      <p className={sensorStyle("value")}>
        {value ?? "--"}
        {unity && <span className={sensorStyle("unity")}>{unity}</span>}
      </p>
    </div>
  );
};

/* uid, mac, updatedAt, sensors, closing, hardwareVersion */

interface ValveTooltipProps
  extends Pick<
    ValvesWithProblem,
    | "uid"
    | "mac"
    | "updatedAt"
    | "sensors"
    | "closing"
    | "hardwareVersion"
    | "stateData"
    | "roomId"
    | "correctedTemp"
    | "problemStatus"
  > {
  openHistoryDialog: VoidFunction;
  openValveListDrawer: VoidFunction;
}

const ValveTooltipContent: React.FC<ValveTooltipProps> = ({ openHistoryDialog, openValveListDrawer, ...valve }) => {
  const { formatMessage } = useIntl();

  const { permissions } = usePermissionsContext("ValveTooltipContent");

  const { rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;
  const currentRoom = rooms.find(r => r.id === valve.roomId);
  const roomData = currentRoom && getRoomData(currentRoom, undefined, valve);

  const { uid, mac, updatedAt, sensors, closing, hardwareVersion } = valve;

  const bem = useBem(styles);
  const tooltipContentStyle = bem("tooltip-content");

  const temperature = sensors?.temperature ?? undefined;
  const humidity = sensors?.humidity ?? undefined;
  const airQuality = sensors?.indoorAirQuality ?? undefined;
  const light = sensors?.light ?? undefined;
  const correctedTemp = valve.correctedTemp ?? undefined;

  const temperatureThreshold = temperature !== undefined ? getTemperatureThreshold(temperature) : undefined;
  const humidityThreshold = humidity !== undefined ? getHumidityThreshold(humidity) : undefined;
  const airQualityThreshold =
    airQuality !== undefined
      ? isValveHardwareIsUpper(hardwareVersion, VALVE_HARDWARE_VERSION_ENUM.V5)
        ? getAirQualityV5Threshold(airQuality)
        : getAirQualityThreshold(airQuality)
      : undefined;
  const lightThreshold = light !== undefined ? getLightThreshold(light) : undefined;
  const openningThreshold = closing !== undefined ? getOpenningThreshold(closing) : undefined;

  const data: Record<
    string,
    {
      icon: React.ReactElement;
      value?: string;
      unity?: string;
      color?: string;
    }
  > = {
    temperature: {
      icon: <ThermometerIcon />,
      value: temperature !== undefined ? temperature.toFixed(1) : undefined,
      unity: "°C",
      color: temperatureThreshold ? `temperature-${temperatureThreshold}` : undefined,
    },
    humidity: {
      icon: <WaterDropIcon />,
      value: humidity !== undefined ? humidity.toFixed(1) : undefined,
      unity: "%",
      color: humidityThreshold ? `humidity-${humidityThreshold}` : undefined,
    },
    airQuality: {
      icon: <WindIcon />,
      value: airQualityThreshold && formatMessage({ id: `airQuality.${kebabCaseToCamelCase(airQualityThreshold)}` }),
      color: airQualityThreshold ? `air-quality-${airQualityThreshold}` : undefined,
    },
    light: {
      icon: <SunIcon />,
      value: light !== undefined ? light.toFixed(1) : undefined,
      unity: "lux",
      color: lightThreshold ? `light-${lightThreshold}` : undefined,
    },
    openning: {
      icon: <TapIcon />,
      value: closing !== undefined ? (100 - closing).toFixed(1) : undefined,
      unity: "%",
      color: openningThreshold ? `openning-${openningThreshold}` : undefined,
    },
    correctedTemp: {
      icon: <TargetIcon width={30} height={30} />,
      value: correctedTemp !== undefined ? correctedTemp.toFixed(1) : undefined,
      unity: "°C",
      color: correctedTemp ? "corrected-temp" : undefined,
    },
  };

  const buttons = [
    {
      icon: <NutIcon />,
      onClick: openValveListDrawer,
      auth: permissions.valve?.read,
    },
    {
      icon: <BarChartIcon />,
      onClick: openHistoryDialog,
      auth: mac && permissions.history?.valve.histoValve?.read,
    },
  ];

  return (
    <div className={tooltipContentStyle()}>
      <h2 className={tooltipContentStyle("title", { variant: "uid-mac" })}>
        {`${uid ?? "--"} - ${mac ?? "--"}`}
        <span className={tooltipContentStyle("title", { variant: "date" })}>{`(${
          updatedAt ? dayjs(updatedAt).format("DD/MM/YYYY - HH:mm") : "--"
        })`}</span>
        {buttons.map(
          b =>
            b.auth && (
              <Circle size={25} className={tooltipContentStyle("buttons")} onClick={b.onClick}>
                {b.icon}
              </Circle>
            ),
        )}
        {valve.problemStatus && <div className={tooltipContentStyle("error", { variant: valve.problemStatus })} />}
      </h2>
      <div className={tooltipContentStyle("sensors")}>
        {Object.entries(data).map(([key, value]) => (
          <Sensor key={key} icon={value.icon} value={value.value} unity={value.unity} color={value.color} />
        ))}
      </div>
      {hasPermissionRoomData(permissions) &&
        roomData &&
        Object.values(roomData).some(v => v.selected && !v.disabled) && (
          <div className={tooltipContentStyle("roomData")}>
            {Object.values(roomData).map(
              (v, index) => v.selected && !v.disabled && <Fragment key={index}>{v.icon}</Fragment>,
            )}
          </div>
        )}
    </div>
  );
};

interface ValveProps
  extends Pick<
    ValvesWithProblem,
    | "id"
    | "uid"
    | "mac"
    | "updatedAt"
    | "sensors"
    | "closing"
    | "hardwareVersion"
    | "stateData"
    | "roomId"
    | "correctedTemp"
    | "problemStatus"
  > {
  selected?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
  displayUid?: boolean;
  displayDetails?: boolean;
  size?: number;
}

const Valve = forwardRef<HTMLDivElement, ValveProps>(
  (
    {
      selected = false,
      disabled = false,
      onClick,
      onMouseDown,
      className,
      displayUid = false,
      displayDetails = false,
      size = 20,
      ...valve
    },
    ref,
  ) => {
    const bem = useBem(styles);
    const valveStyle = bem("valve");
    const { permissions } = usePermissionsContext("Valve");

    const { valves } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;

    const [historyOpen, setHistoryOpen] = useState(false);
    const [valvesDrawerOpen, setValvesDrawerOpen] = useState(false);

    const openHistoryDialog = useCallback(() => setHistoryOpen(true), []);
    const openValveDrawer = useCallback(() => setValvesDrawerOpen(true), []);

    const problemStatusPermission =
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      (permissions.valve?.error && valve.problemStatus === "error") ||
      (permissions.valve?.warning && valve.problemStatus === "warning");

    return (
      <>
        <Tooltip
          content={
            displayDetails && (
              <ValveTooltipContent
                openHistoryDialog={openHistoryDialog}
                openValveListDrawer={openValveDrawer}
                {...valve}
              />
            )
          }
        >
          <Circle
            className={clsx(valveStyle(), className)}
            size={size}
            selected={selected}
            disabled={disabled}
            error={problemStatusPermission ? valve.problemStatus : undefined}
            onClick={onClick}
            onMouseDown={onMouseDown}
            ref={ref}
          >
            {displayUid && valve.uid ? valve.uid : <ValveIcon style={{ width: "70%", height: "70%" }} />}
          </Circle>
        </Tooltip>

        {historyOpen && (
          <ValveHistoryDialog open onOpenChange={open => setHistoryOpen(open)} valves={valves} valveId={valve.id} />
        )}

        {valvesDrawerOpen && (
          <ValveListDrawer open onClose={() => setValvesDrawerOpen(false)} valves={valves} choosenValveId={valve.id} />
        )}
      </>
    );
  },
);

Valve.displayName = "Valve";

export { Valve };
