import { useState } from "react";

import type { PlansMessageInner } from "@eisox/backend_webapp_api";
import { useBem } from "@eisox/tools";

import { PlanInput } from "../PlanInput";
import { PlanCard } from "./components";

import styles from "./AddPlan.module.scss";

interface AddPlanProps {
  houseId: string;
}

export const AddPlan: React.FC<AddPlanProps> = ({ houseId }) => {
  const bem = useBem(styles);
  const addPlanStyle = bem("add-plan");

  const [expanded, setExpanded] = useState<string>();
  const [addedPlans, setAddedPlan] = useState<PlansMessageInner[]>([]);

  const handleAddPlan = (plan: PlansMessageInner) => setAddedPlan(prevState => [...prevState, plan]);

  const handleDeletePlan = (plan: PlansMessageInner) => {
    setAddedPlan(prevState => prevState.filter(p => p.id !== plan.id));
  };

  return (
    <div className={addPlanStyle()}>
      {addedPlans.map((p, i) => (
        <PlanCard
          key={i}
          id={p.id!}
          name={p.name!}
          houseId={houseId}
          expanded={expanded === p.id}
          onClick={() => (expanded === p.id ? setExpanded(undefined) : setExpanded(p.id))}
          onDelete={() => handleDeletePlan(p)}
        />
      ))}
      <PlanInput houseId={houseId} onAddPlan={handleAddPlan} />
    </div>
  );
};
