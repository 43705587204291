import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActionButtonV2 as ActionButton, Modal, TextField } from "@eisox/design-system";
import { ArrowRightIcon, NetworkIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { FieldContainer } from "~/UI";
import { useBoilerRoomRealTimeProviderContext } from "~/features/BoilerRooms";
import type { BoilerRoom, HeatingNetwork } from "~/socketio/types";

import styles from "./SetpointTemperatureECSDialog.module.scss";

const NAME = "SetpointTemperatureECSDialog";

interface SetpointTemperatureECSDialogProps {
  boilerRoomId: string;
  network: HeatingNetwork;
  children: React.ReactNode;
}

export const SetpointTemperatureECSDialog: React.FC<SetpointTemperatureECSDialogProps> = ({
  boilerRoomId,
  network,
  children,
}) => {
  const { t } = useTranslation();

  const bem = useBem(styles);
  const setpointTemperatureECSDialogStyle = bem("setpoint-temperature-ecs-dialog");
  const contentStyle = bem("content");

  const [open, setOpen] = useState(false);

  const { useUpdateBoilerRoom } = useBoilerRoomRealTimeProviderContext(NAME);

  const { mutate } = useUpdateBoilerRoom({
    onSuccess: () => {
      reset();
      setOpen(false);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm<Pick<HeatingNetwork, "setpointTemperatureEcs">>({
    resolver: zodResolver(
      z.object({
        setpointTemperatureEcs: z.preprocess(val => (val === "" ? undefined : Number(val)), z.number()),
      }),
    ),
    values: {
      setpointTemperatureEcs: network.setpointTemperatureEcs,
    },
  });

  const onSubmit = (data: Pick<HeatingNetwork, "setpointTemperatureEcs">) => {
    const updates: BoilerRoom = {
      id: boilerRoomId,
      heatingNetworks: [{ id: network.id, setpointTemperatureEcs: data.setpointTemperatureEcs }],
    };
    mutate([updates]);
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) reset();
    setOpen(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={setpointTemperatureECSDialogStyle()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            title={t("network.content.heatingCurve.dialog.setpointTemperatureECS.title")}
            subtitle={t("network.content.heatingCurve.dialog.setpointTemperatureECS.subtitle")}
            icon={<NetworkIcon />}
          >
            <Modal.Close asChild>
              <ActionButton variant="cancel">
                {t("network.content.heatingCurve.dialog.setpointTemperatureECS.cancel")}
              </ActionButton>
            </Modal.Close>
            <ActionButton type="submit" disabled={!isDirty} rounded>
              {t("network.content.heatingCurve.dialog.setpointTemperatureECS.save")} <ArrowRightIcon />
            </ActionButton>
          </Modal.Header>
          <div className={contentStyle()}>
            <FieldContainer
              label={t("network.content.heatingCurve.dialog.setpointTemperatureECS.setpoint")}
              error={errors.setpointTemperatureEcs}
            >
              <div className={contentStyle("input")}>
                <TextField.Root type="number" {...register("setpointTemperatureEcs")} />
                <span className={contentStyle("temperature")}>°C</span>
              </div>
            </FieldContainer>
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
