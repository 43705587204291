import { uniqueId } from "lodash";
import { Controller, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import type * as yup from "yup";

import dayjs from "@eisox/dayjs";
import { DateInput, Select, Switch, TextInput } from "@eisox/design-system";
import { BinIcon, PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { DateTimePickerInput, Textarea } from "~/UI/components";

import { useInterventionPlanTool } from "../../../../providers";
import { CONTACTS } from "../../utils";
import type { SchemaType } from "../../utils/schema";
import { equipmentSchema } from "../../utils/schema";
import { DropdownPlan } from "./DropdownPlan";

import styles from "./DialogExportContent.module.scss";

export const DialogExportContent: React.FC = () => {
  const { formatMessage } = useIntl();
  const { setState, renamePlans } = useInterventionPlanTool();

  const bem = useBem(styles);

  const dialogExportStyle = bem("dialog-export");
  const equipmentsStyle = bem("equipments");
  const durationStyle = bem("duration");

  // GLOBAL FORM
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<SchemaType>();

  const operatingTime = watch("operatingTime");
  const equipments = useFieldArray({ control, name: "equipments" });
  const plans = useFieldArray({ control, name: "plans" });

  // EQUIPMENT FORM FOR ADD EQUIPMENT
  const equipmentForm = useForm<yup.InferType<typeof equipmentSchema>>({
    resolver: yupResolver(equipmentSchema),
    defaultValues: {
      id: uniqueId(),
      name: "",
      min: 0,
      max: 0,
      canEditName: true,
    },
  });

  const handleAddEquipment = equipmentForm.handleSubmit(data => {
    equipments.append(data);
    equipmentForm.reset();
    setState(draft => {
      draft.equipments.push(data);
    });
  });

  const handleDeleteEquipment = (index: number) => {
    equipments.remove(index);
    setState(draft => {
      draft.equipments.splice(index, 1);
    });
  };

  const handleDeletePlan = (index: number) => {
    plans.remove(index);
    setState(draft => {
      draft.plans.splice(index, 1);
    });
    renamePlans();
  };

  return (
    <div className={dialogExportStyle()}>
      <Controller
        control={control}
        name="houseName"
        render={({ field: { onChange, value } }) => (
          <TextInput
            label={formatMessage({ id: "interventionPlanTool.export.houseName" })}
            className={dialogExportStyle("houseName")}
            value={value}
            type="text"
            error={errors.houseName}
            onChange={e => {
              onChange(e);
              setState(draft => {
                draft.houseName = e.target.value;
              });
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="adress"
        render={({ field: { onChange, value } }) => (
          <TextInput
            label={formatMessage({ id: "interventionPlanTool.export.adresse.label" })}
            placeholder={formatMessage({ id: "interventionPlanTool.export.adresse.placeholder" })}
            className={dialogExportStyle("adress")}
            value={value}
            type="text"
            error={errors.adress}
            onChange={e => {
              onChange(e);
              setState(draft => {
                draft.adress = e.target.value;
              });
            }}
          />
        )}
      />

      <Controller
        control={control}
        name="planifiedAt.start"
        render={({ field: { onChange, value } }) => (
          <label className={dialogExportStyle("planifiedAtStart")}>
            {formatMessage({ id: "interventionPlanTool.export.planifiedAt.start" })}
            <DateInput
              format="DD/MM/YYYY"
              value={value ? dayjs(value) : undefined}
              onChange={v => {
                onChange(dayjs(v).isValid() ? v : undefined);
                setState(draft => {
                  draft.planifiedAt.start = dayjs(v).isValid() ? v?.toISOString() : undefined;
                });
              }}
            />
            <p className={dialogExportStyle("error")}>{errors.planifiedAt?.start?.message}</p>
          </label>
        )}
      />

      <Controller
        control={control}
        name="planifiedAt.end"
        render={({ field: { onChange, value } }) => (
          <label className={dialogExportStyle("planifiedAtEnd")}>
            {formatMessage({ id: "interventionPlanTool.export.planifiedAt.end" })}
            <DateInput
              format="DD/MM/YYYY"
              value={value ? dayjs(value) : undefined}
              onChange={v => {
                onChange(dayjs(v).isValid() ? v : undefined);
                setState(draft => {
                  draft.planifiedAt.end = dayjs(v).isValid() ? v?.toISOString() : undefined;
                });
              }}
            />
            <p className={dialogExportStyle("error")}>{errors.planifiedAt?.end?.message}</p>
          </label>
        )}
      />

      <Controller
        control={control}
        name="version"
        render={({ field: { onChange, value } }) => (
          <TextInput
            label={formatMessage({ id: "interventionPlanTool.export.version" })}
            className={dialogExportStyle("version")}
            value={value}
            type="number"
            error={errors.version}
            onChange={e => {
              onChange(e);
              setState(draft => {
                draft.version = e.target.value;
              });
            }}
          />
        )}
      />

      <div className={equipmentsStyle()}>
        <label>{formatMessage({ id: "interventionPlanTool.export.equipements.label" })}</label>
        <div className={equipmentsStyle("container")}>
          <p className={dialogExportStyle("error")}>{errors.equipments?.message}</p>
          {equipments.fields.map((equipment, i) => {
            return (
              <div key={equipment.id} className={equipmentsStyle("equipment")}>
                {equipment.canEditName ? (
                  <Controller
                    control={control}
                    name={`equipments.${i}.name`}
                    render={({ field: { onChange, value } }) => (
                      <TextInput
                        value={value}
                        onChange={e => {
                          onChange(e);
                          setState(draft => {
                            draft.equipments[i].name = e.target.value;
                          });
                        }}
                        error={errors.equipments?.[i]?.name}
                      />
                    )}
                  />
                ) : (
                  <p>{equipment.name}</p>
                )}

                <Controller
                  control={control}
                  name={`equipments.${i}.min`}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      onChange={e => {
                        onChange(e);
                        e.target.value.length > 0 &&
                          setState(draft => {
                            draft.equipments[i].min = parseInt(e.target.value);
                          });
                      }}
                      type="number"
                      error={errors.equipments?.[i]?.min}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={`equipments.${i}.max`}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      value={value}
                      onChange={e => {
                        onChange(e);
                        e.target.value.length > 0 &&
                          setState(draft => {
                            draft.equipments[i].max = parseInt(e.target.value);
                          });
                      }}
                      type="number"
                      error={errors.equipments?.[i]?.max}
                    />
                  )}
                />
                <BinIcon className={equipmentsStyle("delete")} onClick={() => handleDeleteEquipment(i)} />
              </div>
            );
          })}
          <div className={equipmentsStyle("equipment")}>
            <TextInput
              {...equipmentForm.register("name")}
              error={equipmentForm.formState.errors.name}
              placeholder={formatMessage({ id: "interventionPlanTool.export.equipements.name" })}
              type="text"
            />
            <TextInput
              {...equipmentForm.register("min")}
              error={equipmentForm.formState.errors.min}
              placeholder={formatMessage({ id: "interventionPlanTool.export.equipements.min" })}
              type="number"
            />
            <TextInput
              {...equipmentForm.register("max")}
              error={equipmentForm.formState.errors.max}
              placeholder={formatMessage({ id: "interventionPlanTool.export.equipements.max" })}
              type="number"
            />
            <PlusIcon className={equipmentsStyle("add")} onClick={handleAddEquipment} />
          </div>
        </div>
      </div>
      <div className={durationStyle()}>
        <label>{formatMessage({ id: "interventionPlanTool.export.duration.label" })}</label>
        <p>
          {formatMessage({ id: "interventionPlanTool.export.duration.calculate" })}:{" "}
          {dayjs.duration(operatingTime, "minutes").format("H[h]mm")}
        </p>
        <div className={durationStyle("to-export")}>
          <label>
            {formatMessage({ id: "interventionPlanTool.export.duration.toExport" })}:{" "}
            <Controller
              control={control}
              name="operatingTime"
              render={({ field: { value, onChange } }) => (
                <TextInput
                  value={value}
                  error={errors.operatingTime}
                  onChange={e => {
                    onChange(e);
                    setState(draft => {
                      draft.operatingTime = parseInt(e.target.value);
                    });
                  }}
                  type="number"
                />
              )}
            />
          </label>
        </div>
      </div>
      <div className={dialogExportStyle("comments")}>
        <Controller
          control={control}
          name="commentsIntervention"
          render={({ field: { onChange, value } }) => (
            <Textarea
              value={value}
              label={formatMessage({ id: "interventionPlanTool.export.commentsIntervention" })}
              onChange={e => {
                onChange(e);
                setState(draft => {
                  draft.commentsIntervention = e.target.value;
                });
              }}
              error={errors.commentsIntervention}
            />
          )}
        />
      </div>
      <Controller
        control={control}
        name="contact"
        render={({ field: { onChange, value } }) => (
          <Select
            label={formatMessage({ id: "interventionPlanTool.export.contact" })}
            className={dialogExportStyle("contact")}
            options={CONTACTS.map(c => ({ name: `${c.firstName} ${c.lastName.toUpperCase()}`, value: c.id }))}
            onChange={value => {
              const contact = CONTACTS.find(c => c.id === value)!;
              onChange(contact);
              setState(draft => {
                draft.contact = contact;
              });
            }}
            renderValue={value => {
              const contact = CONTACTS.find(c => c.id === value)!;
              return (
                <p>
                  {contact.firstName} {contact.lastName.toUpperCase()}
                </p>
              );
            }}
            value={value.id}
            multiple={false}
          />
        )}
      />

      <Controller
        control={control}
        name="editAt"
        render={({ field: { onChange, value } }) => (
          <DateTimePickerInput
            label={formatMessage({ id: "interventionPlanTool.export.editAt" })}
            className={dialogExportStyle("editAt")}
            disableFuture
            format="DD/MM/YYYY"
            value={dayjs(value)}
            onChange={date => {
              if (date) {
                onChange(date.toISOString());
                setState(draft => {
                  draft.editAt = date.toISOString();
                });
              }
            }}
          />
        )}
      />

      <label className={dialogExportStyle("eisox-provider")}>
        {formatMessage({ id: "interventionPlanTool.export.isForEisoxProvider" })}
        <Controller
          control={control}
          name="isForEisoxProvider"
          render={({ field: { value, onChange } }) => (
            <Switch
              checked={value}
              onCheckedChange={checked => {
                onChange(checked);
                setState(draft => {
                  draft.isForEisoxProvider = checked!;
                });
              }}
            />
          )}
        />
      </label>

      <div className={dialogExportStyle("plans")}>
        <label>{formatMessage({ id: "interventionPlanTool.export.plans" })}</label>
        {plans.fields.map((plan, i) => (
          <DropdownPlan key={plan.id} plan={plan} handleDelete={() => handleDeletePlan(i)} />
        ))}
        <p className={dialogExportStyle("error")}>{errors.plans?.message}</p>
      </div>
    </div>
  );
};
