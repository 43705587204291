import { Outlet } from "react-router-dom";

import { createContext } from "@eisox/tools";

import type { BoilerRoom, Error } from "~/socketio/types";

import type { UseUpdateBoilerRoomType } from "./RealTimeProvider";

const NAME = "BoilerRoomProvider";

interface BoilerRoomPageValueProps {
  houseId: string;
  currentBoilerRoom?: BoilerRoom;
  boilerRooms?: BoilerRoom[];
  errors?: Error[];
  useUpdateBoilerRoom: UseUpdateBoilerRoomType;
  history: boolean;
  date?: string;
}

const [BoilerRoomPageContextProvider, useBoilerRoomContext] = createContext<BoilerRoomPageValueProps>(NAME);

/**
 * Hook to get the current boiler room context when boiler room is loaded.
 * @param name - The name of the context to use.
 * @returns The current boiler room context.
 */
const useBoilerRoomContextWithCurrentBoilerRoom = (name: string) => {
  const context = useBoilerRoomContext(name);
  if (!context.currentBoilerRoom) {
    throw new Error("No current boiler room");
  }
  return context as Omit<BoilerRoomPageValueProps, "currentBoilerRoom"> & { currentBoilerRoom: BoilerRoom };
};

interface BoilerRoomProviderProps {
  houseId: string;
  boilerRoomId: string;
  boilerRooms?: BoilerRoom[];
  errors?: Error[];
  useUpdateBoilerRoom: UseUpdateBoilerRoomType;
  history: boolean;
  date?: string;
}

export const BoilerRoomProvider: React.FC<BoilerRoomProviderProps> = ({
  houseId,
  boilerRoomId,
  boilerRooms,
  errors,
  useUpdateBoilerRoom,
  history,
  date,
}) => {
  const currentBoilerRoom = boilerRooms?.find(boilerRoom => boilerRoom.id === boilerRoomId);

  return (
    <BoilerRoomPageContextProvider
      houseId={houseId}
      currentBoilerRoom={currentBoilerRoom}
      boilerRooms={boilerRooms}
      errors={errors}
      useUpdateBoilerRoom={useUpdateBoilerRoom}
      history={history}
      date={date}
    >
      <Outlet />
    </BoilerRoomPageContextProvider>
  );
};

export { useBoilerRoomContext, useBoilerRoomContextWithCurrentBoilerRoom };
export type { BoilerRoomPageValueProps };
