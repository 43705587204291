import { useMemo } from "react";

import { useBem } from "@eisox/tools";

import type { Boiler as BoilerType } from "~/socketio/types";
import { getBoilerState } from "~/utils/schemeUtils";

import { BoilerDialog } from "../BoilerDialog";
import { MultiWayValve } from "../MultiWayValve/MultiWayValve";
import { NetworkDialog } from "../NetworkDialog";
import { Pumps } from "../Pumps";
import { Temperature } from "../Temperature";
import { BoilerCard } from "./BoilerCard";

import styles from "./Boiler.module.scss";

const NAME = "boiler";

export interface BoilerProps {
  boiler: BoilerType;
}

export const Boiler: React.FC<BoilerProps> = ({ boiler }) => {
  const bem = useBem(styles);
  const boilerStyle = bem("boiler");

  const { id, name, pumps, twoWaysValve } = boiler;

  const { state, commandAutomate, commandMan, startingTemperature, returnTemperature, defect } = boiler;

  const boilerState = useMemo(
    () => getBoilerState(state, commandAutomate, commandMan, defect),
    [state, commandAutomate, commandMan, defect],
  );

  const mismatchError = boilerState.state === "mismatch";

  return (
    <>
      <div className={boilerStyle()}>
        {boiler.pumps ? (
          <NetworkDialog dialogTitle={name ?? "-"} boilerId={id} pumps={pumps} threeWaysValve={twoWaysValve}>
            <Pumps {...boiler.pumps} vertical />
          </NetworkDialog>
        ) : (
          <div />
        )}
        {boiler.twoWaysValve ? (
          <NetworkDialog dialogTitle={name ?? "-"} boilerId={id} pumps={pumps} threeWaysValve={twoWaysValve}>
            <MultiWayValve {...boiler.twoWaysValve} type={2} />
          </NetworkDialog>
        ) : (
          <div />
        )}
        {returnTemperature ? (
          <Temperature
            className={boilerStyle("temperature")}
            type={"starting"}
            orientation="vertical"
            value={returnTemperature}
          />
        ) : (
          <div />
        )}
        <BoilerDialog boiler={boiler}>
          <BoilerCard {...boiler} mismatchError={mismatchError} boilerState={boilerState} />
        </BoilerDialog>
        {startingTemperature ? (
          <Temperature
            className={boilerStyle("temperature")}
            type={"starting"}
            orientation="vertical"
            value={startingTemperature}
          />
        ) : (
          <div />
        )}
      </div>
    </>
  );
};

Boiler.displayName = NAME;
