import dayjs from "@eisox/dayjs";
import type { Option } from "@eisox/design-system";

import type { BoilerRoom } from "~/socketio/types";
import { filterObject } from "~/utils/boilerroomUtils";
import { generateAllHours } from "~/utils/planningUtils";

import { View } from "./types";

/**
 * @returns 90 days from today in the format of "DD MMM"
 * @example
 * [
 *   { name: "01 Jan", value: "2024-01-01" },
 *   { name: "02 Jan", value: "2024-01-02" },
 *   { name: "03 Jan", value: "2024-01-03" },
 *   ...
 * ]
 */
const generateDayOptions = (): Option[] => {
  return Array.from({ length: 90 }, (_, i) => i).map(i => {
    const date = dayjs().subtract(i, "day");
    return {
      name: date.format("DD MMM"),
      value: date.format("YYYY-MM-DD"),
    };
  });
};

/**
 *
 * @param startMonth 2024-06
 * @returns  months from current month to startMonth in the format of "MMM YYYY"
 * @example
 * [
 *   { name: "Aug 2024", value: "2024-08" },
 *   { name: "Jul 2024", value: "2024-07" },
 *   { name: "Jun 2024", value: "2024-06" },
 *   ...
 * ]
 */
const generateMonthOptions = (startMonth = "2024-06"): Option[] => {
  const currentMonth = dayjs().format("YYYY-MM");
  const start = dayjs(startMonth);
  const end = dayjs(currentMonth);
  const diff = end.diff(start, "month");
  return Array.from({ length: diff + 1 }, (_, i) => end.subtract(i, "month")).map(month => ({
    name: month.format("MMMM YYYY"),
    value: month.format("YYYY-MM"),
  }));
};

/**
 * @param startYear 2024
 * @returns years from startYear to current year in the format of "YYYY"
 * @example
 * [
 *   { name: "2026", value: "2026" },
 *   { name: "2025", value: "2025" },
 *   { name: "2024", value: "2024" },
 *   ...
 * ]
 */
const generateYearOptions = (startYear = 2024): Option[] => {
  const currentYear = dayjs().year();
  return Array.from({ length: currentYear - startYear + 1 }, (_, i) => currentYear - i).map(year => ({
    name: year.toString(),
    value: year.toString(),
  }));
};

/**
 * @param view View.HOUR | View.DAY | View.MONTH | View.YEAR
 * @param start start date in the format of "YYYY-MM-DD" | "YYYY-MM" | "YYYY"
 * @returns options for the given view
 */
const getOptions = (view: View, start?: string): Option[] => {
  switch (view) {
    case View.HOUR:
      return generateDayOptions();
    case View.DAY:
      return generateMonthOptions(start);
    case View.MONTH:
    case View.YEAR:
      const startYear = !isNaN(Number(start)) ? Number(start) : undefined;
      return generateYearOptions(startYear);
    default:
      return generateDayOptions();
  }
};

const getDateRangeWithPrevRange = (
  view: View,
  defaultComparison: string[],
): { startDate: string; endDate: string }[] => {
  switch (view) {
    case View.HOUR:
      const days = defaultComparison.map(date => dayjs(date));
      return days.map(day => ({
        startDate: day.startOf("day").subtract(15, "minute").toISOString(),
        endDate: day.endOf("day").toISOString(),
      }));
    case View.DAY:
      const months = defaultComparison.map(date => dayjs(date));
      return months.map(month => ({
        startDate: month.subtract(1, "day").format("YYYY-MM-DD"),
        endDate: month.endOf("month").format("YYYY-MM-DD"),
      }));
    case View.MONTH:
    case View.YEAR:
      const years = defaultComparison.map(date => dayjs(date));
      return years.map(year => ({
        startDate: year.subtract(1, "month").format("YYYY-MM"),
        endDate: year.endOf("year").format("YYYY-MM"),
      }));

    default:
      return [];
  }
};

const getCurrentConsumption = (data: Record<string, number>[]) => {
  const sortedDataByDates = data.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
  return sortedDataByDates.reduce((acc: Record<string, number>[], item, index) => {
    if (index > 0) {
      const prevItem = sortedDataByDates[index - 1];
      const newItem = { ...item };
      Object.entries(item).forEach(([key, value]) => {
        if (key !== "date") newItem[key] = value - prevItem[key];
      });
      acc.push(newItem);
    }
    return acc;
  }, []);
};

const getYearConsumption = (data: Record<string, number>[]) => {
  return Object.values(
    data.reduce<Record<string, Record<string, number>>>((acc, item) => {
      const year = dayjs(item.date).year();
      if (!acc[year]) {
        acc[year] = { date: dayjs(year.toString(), "YYYY").valueOf() };
      }
      Object.keys(item).forEach(key => {
        if (key !== "date") {
          acc[year][key] = (acc[year][key] || 0) + item[key];
        }
      });
      return acc;
    }, {}),
  );
};

const getCategoryByView = (view: View) => {
  switch (view) {
    case View.HOUR:
      return "HH:mm";
    case View.DAY:
      return "DD";
    case View.MONTH:
      return "MMM";
    case View.YEAR:
      return "YYYY";
  }
};

const getKeyByView = (view: View) => {
  switch (view) {
    case View.HOUR:
      return "DD/MM";
    case View.DAY:
      return "MM/YYYY";
    case View.MONTH:
      return "YYYY";
    case View.YEAR:
      return "YYYY";
  }
};

const roundToNearestQuarterHour = (date: number) => {
  const dayjsDate = dayjs(date);
  const minutes = dayjsDate.minute();
  const roundedMinutes = Math.round(minutes / 15) * 15;

  return dayjsDate.minute(roundedMinutes).second(0).millisecond(0).valueOf();
};

const getXAxisTicks = (view: View): string[] => {
  switch (view) {
    case View.HOUR:
      return generateAllHours();
    case View.DAY:
      return Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, "0"));
    case View.MONTH:
      return Array.from({ length: 12 }, (_, i) => dayjs().month(i).format("MMM"));
    case View.YEAR:
      const currentYear = dayjs().year();
      return Array.from({ length: 20 }, (_, i) => (currentYear - 19 + i).toString());
    default:
      return [];
  }
};

const getConsumption = (data: Record<string, number>[], view: View, comparison: boolean) => {
  let consumption: Record<string, string | number>[] = [];
  consumption = getCurrentConsumption(data);
  if (view === View.YEAR) {
    consumption = getYearConsumption(consumption as Record<string, number>[]);
  }

  const format = getCategoryByView(view);

  consumption = consumption.map(c => {
    const date = dayjs(view === View.HOUR ? roundToNearestQuarterHour(c.date as number) : c.date).format(format);
    const newEntries: Record<string, string | number> = {};
    for (const key in c) {
      if (key !== "date") {
        newEntries[comparison ? dayjs(c.date).format(getKeyByView(view)) : key] = c[key];
      } else {
        newEntries[key] = date;
      }
    }
    return newEntries;
  });

  consumption = Object.values(
    consumption.reduce(
      (acc, curr) => {
        if (!acc[curr.date]) {
          acc[curr.date] = { date: curr.date as string };
        }
        Object.keys(curr).forEach(key => {
          if (key !== "date") {
            acc[curr.date][key] = curr[key];
          }
        });
        return acc;
      },
      {} as Record<
        string,
        {
          date: string;
          [key: string]: any;
        }
      >,
    ),
  );

  const xticks = getXAxisTicks(view);
  consumption = xticks.map(tick => {
    const existingItem = consumption.find(item => item.date === tick);
    if (existingItem) {
      return existingItem;
    } else {
      return { date: tick };
    }
  });

  return consumption;
};

const getKeysForUnit = (view: View, comparison: string[]): string[] => {
  switch (view) {
    case View.HOUR:
      return comparison.map(c => dayjs(c).format("DD/MM"));
    case View.DAY:
      return comparison.map(c => dayjs(c).format("MM/YYYY"));
    case View.MONTH:
      return comparison;
    case View.YEAR:
      return comparison;
    default:
      return [];
  }
};

const getUnitAxesMappingWithComparison = (
  getUnitAxesMapping: Record<string, string[]>,
  view: View,
  comparison: string[],
) => {
  const newMapping = { ...getUnitAxesMapping };
  Object.keys(newMapping).forEach(key => {
    newMapping[key] = getKeysForUnit(view, comparison);
  });
  return newMapping;
};

const filterConsumptionScheme = (scheme: BoilerRoom[]): BoilerRoom[] => {
  const filteredScheme = filterObject(
    scheme,
    ["calorieConso", "elecConso", "woodConso", "woodLevel", "woodLowLevel", "gazConso"],
    ["id", "name"],
  );
  return filteredScheme === null ? [] : (filteredScheme as BoilerRoom[]);
};

export {
  filterConsumptionScheme,
  getConsumption,
  getDateRangeWithPrevRange,
  getOptions,
  getUnitAxesMappingWithComparison,
};
