import { useIntl } from "react-intl";

import { ActionButton } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import type { Boiler as BoilerType } from "~/socketio/types";
import type { BoilerromLayout } from "~/stores";
import { isOnlyOnePump } from "~/utils";

import { Boilers as BoilersLayout } from "../../layouts";
import { useBoilerRoomContextWithCurrentBoilerRoom } from "../../providers";
import { Boiler } from "../Boiler";
import { CascadeDialog } from "../CascadeDialog";
import { NetworkDialog } from "../NetworkDialog";
import { Pumps } from "../Pumps";

import styles from "./Boilers.module.scss";

const NAME = "boilers";

interface BoilersProps {
  layout: BoilerromLayout;
  boilers: BoilerType[];
}

export const Boilers: React.FC<BoilersProps> = ({ layout, boilers }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const boilersStyle = bem("boilers");

  const { currentBoilerRoom } = useBoilerRoomContextWithCurrentBoilerRoom(NAME);
  const { lossTempDelta, recyclePumps } = currentBoilerRoom;

  return (
    <>
      <div className={boilersStyle({ layout, one: boilers.length === 1 })}>
        {lossTempDelta !== undefined && (
          <CascadeDialog>
            <ActionButton
              className={boilersStyle("primary-button")}
              text={formatMessage({ id: "boilerroom.primary" })}
            />
          </CascadeDialog>
        )}
        <BoilersLayout layout={layout}>
          {boilers.map((b, i) => (
            <Boiler key={i} boiler={b} />
          ))}
        </BoilersLayout>
        <div className={boilersStyle("starting", { layout })}>
          {recyclePumps && (
            <NetworkDialog dialogTitle={currentBoilerRoom.name ?? "-"} pumps={recyclePumps}>
              <Pumps
                className={boilersStyle("recycle-pumps", { doublePump: !isOnlyOnePump(recyclePumps) })}
                vertical
                {...recyclePumps}
              />
            </NetworkDialog>
          )}
        </div>
      </div>
    </>
  );
};
