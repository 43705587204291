import React, { useState } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import type { PlansMessageInner } from "@eisox/backend_webapp_api";
import {
  ActionButtonV2 as ActionButton,
  Loader,
  Modal,
  PlanV2 as Plan,
  SelectV2 as Select,
  Typography,
} from "@eisox/design-system";
import { RoomIcon } from "@eisox/icons";
import { useQuery } from "@tanstack/react-query";

import { RoomFC } from "~/UI/components";
import { Week } from "~/UI/layouts";
import type { RoomsWithProblem } from "~/UI/screens/House";
import { SelectFilters } from "~/UI/screens/Settings";
import { queries } from "~/apiV2";

import { useFilterRooms } from "../../../../hooks";

import styles from "./PlanningSelectorDialog.module.scss";

interface PlanningSelectorDialogProps extends Modal.RootProps {
  houseId: string;
  rooms: RoomsWithProblem[];
  plans: PlansMessageInner[];
  selectedRoomId?: string;
  onSelectedRoomChange: (roomId: string) => void;
}

const PlanningSelectorDialog: React.FC<PlanningSelectorDialogProps> = ({
  houseId,
  rooms,
  plans,
  selectedRoomId: selectedRoomIdProp,
  onSelectedRoomChange,
  ...props
}) => {
  const { t } = useTranslation();

  const { roomOptions, filters, handleSearch, handleChangeSelector } = useFilterRooms({ rooms });

  const [selectedRoomId, setSelectedRoomId] = useState<string | undefined>(selectedRoomIdProp);
  const selectedRoom = rooms.find(room => room.id === selectedRoomId);
  const [selectedPlanId, setSelectedPlanId] = useState<string>(selectedRoom?.plan?.planId ?? plans[0].id!);

  const { data, isLoading } = useQuery({
    ...queries.planning.room(houseId, selectedRoomId!),
    enabled: !!selectedRoomId,
  });
  const { data: plan } = useQuery({
    ...queries.plan.plan(houseId, selectedPlanId),
  });

  const handleClickSelect = () => selectedRoomId && onSelectedRoomChange(selectedRoomId);
  const handleClickRoom = (id: string) => setSelectedRoomId(prev => (prev === id ? undefined : id));
  const handleChangePlan = (id: string | string[]) => setSelectedPlanId(id as string);

  const selectPlanOptions = plans.map(p => ({ value: p.id!, name: p.name! }));
  const plannings = data?.plannings?.filter(p => p.userDefined) ?? [];
  const roomsOnPlan = rooms.filter(r => r.plan?.planId === selectedPlanId);

  return (
    <Modal.Root {...props}>
      <Modal.Content className={styles.planningSelectorDialog}>
        <Modal.Header
          title={t("settings.content.menu.rooms.planning.dialog.title")}
          subtitle={t("settings.content.menu.rooms.planning.dialog.subtitle")}
          icon={<RoomIcon />}
        >
          <Modal.Close asChild>
            <ActionButton variant="cancel">{t("settings.content.menu.rooms.planning.dialog.cancel")}</ActionButton>
          </Modal.Close>
          <Modal.Close asChild>
            <ActionButton disabled={!selectedRoomId} onClick={handleClickSelect}>
              {t("settings.content.menu.rooms.planning.dialog.select")}
            </ActionButton>
          </Modal.Close>
        </Modal.Header>
        <div className={styles.content}>
          <div className={styles.selectors}>
            <h3 className={styles.selectors__title}>{t("settings.content.menu.rooms.planning.dialog.rooms")}</h3>
            <SelectFilters
              groups={[]}
              state={filters}
              handleSearch={handleSearch}
              handleChangeSelector={handleChangeSelector}
            />
            <div className={styles.rooms}>
              {roomOptions.map((r, i) => (
                <Typography
                  key={i}
                  className={cx(styles.rooms__room, selectedRoomId === r.id && styles.rooms__room_selected)}
                  onClick={() => handleClickRoom(r.id!)}
                >
                  {r.name}
                  <span>{r.plan?.planName}</span>
                </Typography>
              ))}
            </div>
          </div>
          <div className={styles.display}>
            {selectedRoomId ? (
              isLoading ? (
                <Loader className={styles.loader} />
              ) : (
                <Week plannings={plannings} />
              )
            ) : (
              <>
                <Select
                  classNames={{ trigger: styles.select }}
                  options={selectPlanOptions}
                  value={selectedPlanId}
                  onChange={handleChangePlan}
                />
                <Plan.Root>
                  <div className={styles.plan}>
                    <Plan.Controls className={styles.plan__controls} />
                    <Plan.Wrapper className={styles.plan__wrapper} plan={plan}>
                      {roomsOnPlan.map(r => (
                        <Plan.Item position={{ x: r.plan?.x!, y: r.plan?.y! }}>
                          <RoomFC name={r.name ?? "--"} onClick={() => handleClickRoom(r.id!)} />
                        </Plan.Item>
                      ))}
                    </Plan.Wrapper>
                  </div>
                </Plan.Root>
              </>
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};

export { PlanningSelectorDialog };
export type { PlanningSelectorDialogProps };
