import * as React from "react";

import { IconProps } from "./types";

export const PastIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M8.0004 1.5L5.5004 3L8.0004 4.5V3.5C10.4779 3.5 12.5004 5.5225 12.5004 8C12.5004 10.4775 10.4779 12.5 8.0004 12.5C5.5229 12.5 3.5004 10.4775 3.5004 8C3.5004 6.7735 3.9419 5.715 4.7504 4.906L4.0474 4.2035C3.0554 5.195 2.5004 6.5265 2.5004 8C2.5004 11.0225 4.9779 13.5 8.0004 13.5C11.0229 13.5 13.5004 11.0225 13.5004 8C13.5004 4.9775 11.0229 2.5 8.0004 2.5V1.5ZM6.9534 4.7815L6.0474 5.219L7.3129 7.71901C7.27458 7.80793 7.25337 7.90321 7.2504 8V8.03152L5.6409 9.64001L6.3604 10.36L7.9699 8.75049H8.0004C8.1993 8.75049 8.39007 8.67145 8.53073 8.53085C8.67139 8.39019 8.75037 8.19942 8.75037 8.00052C8.75037 7.6555 8.52092 7.369 8.20339 7.2815L6.9534 4.7815Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default PastIcon;
