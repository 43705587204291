import * as React from "react";

import { IconProps } from "./types";

export const DataIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M8.00011 6.70007C11.1306 6.70007 14.5002 5.88626 14.5002 4.10004C14.5002 2.31381 11.1306 1.5 8.00011 1.5C4.86966 1.5 1.5 2.31381 1.5 4.10004C1.5 5.88626 4.86966 6.70007 8.00011 6.70007ZM8.00011 2.80002C11.4127 2.80002 13.2002 3.76593 13.2002 4.10004C13.2002 4.43414 11.4127 5.40005 8.00011 5.40005C4.58755 5.40005 2.80002 4.43414 2.80002 4.10004C2.80002 3.76593 4.58755 2.80002 8.00011 2.80002Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M8.00011 14.4998C11.1306 14.4998 14.5002 13.686 14.5002 11.8998V4.09965C14.5002 3.74084 14.209 3.44964 13.8502 3.44964C13.4914 3.44964 13.2002 3.74084 13.2002 4.09965V11.8998C13.2002 12.2339 11.4127 13.1998 8.00011 13.1998C4.58755 13.1998 2.80002 12.2339 2.80002 11.8998V4.09965C2.80002 3.74084 2.50882 3.44964 2.15001 3.44964C1.79121 3.44964 1.5 3.74084 1.5 4.09965V11.8998C1.5 13.686 4.86966 14.4998 8.00011 14.4998Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M8.00011 10.6003C11.1306 10.6003 14.5002 9.7865 14.5002 8.00028C14.5002 7.64148 14.209 7.35027 13.8502 7.35027C13.4914 7.35027 13.2002 7.64148 13.2002 8.00028C13.2002 8.33439 11.4127 9.3003 8.00011 9.3003C4.58755 9.3003 2.80002 8.33439 2.80002 8.00028C2.80002 7.64148 2.50882 7.35027 2.15001 7.35027C1.79121 7.35027 1.5 7.64148 1.5 8.00028C1.5 9.7865 4.86966 10.6003 8.00011 10.6003Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default DataIcon;
