import { useState } from "react";

import { flattenItems } from "~/utils/planUtils";

import type { ItemType } from "../components/Items";

interface Rectangle {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

export const useContainerSelection = (
  rectangleSelection: boolean,
  container: HTMLDivElement | null,
  itemsRefs: Record<string, HTMLDivElement | null>,
  items: ItemType[],
  onChange?: (items: ItemType[]) => void,
  selectionFunction?: (items: ItemType[], selectedItems: ItemType[]) => ItemType[],
) => {
  const [isDragging, setIsDragging] = useState(false);
  const [rectangle, setRectangle] = useState({ startX: 0, startY: 0, endX: 0, endY: 0 });

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.shiftKey && rectangleSelection) {
      setIsDragging(true);
      const bounds = event.currentTarget.getBoundingClientRect();
      setRectangle({
        startX: event.clientX - bounds.left,
        startY: event.clientY - bounds.top,
        endX: event.clientX - bounds.left,
        endY: event.clientY - bounds.top,
      });
    }
  };

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging && rectangleSelection) {
      const bounds = event.currentTarget.getBoundingClientRect();
      setRectangle(prevState => ({
        startX: prevState.startX,
        startY: prevState.startY,
        endX: event.clientX - bounds.left,
        endY: event.clientY - bounds.top,
      }));
    }
  };

  const handleMouseUp = (_: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging && rectangleSelection) {
      setIsDragging(false);
      selectItemsInRect({
        left: Math.min(rectangle.startX, rectangle.endX),
        top: Math.min(rectangle.startY, rectangle.endY),
        right: Math.max(rectangle.startX, rectangle.endX),
        bottom: Math.max(rectangle.startY, rectangle.endY),
      });
    }
  };

  const selectItemsInRect = (rectangle: Rectangle) => {
    const itemsInRect: ItemType[] =
      flattenItems(items).filter(i => {
        const itemRef = itemsRefs[i.id];
        return itemRef && isItemInRect(itemRef.getBoundingClientRect(), rectangle);
      }) ?? [];
    const selectedItems = selectionFunction?.(items, itemsInRect);
    selectedItems && onChange && onChange(selectedItems);
  };

  const isItemInRect = (item: DOMRect, rect: Rectangle) => {
    const cardDOMRect = container?.getBoundingClientRect();
    if (!cardDOMRect) return false;
    const itemLeft = item.left - cardDOMRect.left;
    const itemRight = item.right - cardDOMRect.left;
    const itemTop = item.top - cardDOMRect.top;
    const itemBottom = item.bottom - cardDOMRect.top;

    const rectLeft = rect.left;
    const rectRight = rect.right;
    const rectTop = rect.top;
    const rectBottom = rect.bottom;

    const horizontalOverlap = itemRight >= rectLeft && itemLeft <= rectRight;
    const verticalOverlap = itemBottom >= rectTop && itemTop <= rectBottom;

    return horizontalOverlap && verticalOverlap;
  };

  return {
    isDragging,
    rectangle,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  };
};
