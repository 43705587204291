class Builder<T> {
  private target: T = {} as T;

  constructor(initial?: T) {
    if (initial) {
      this.target = initial;
    }
  }

  with<K extends keyof T>(key: K, value: T[K] | ((current: T[K], target: T) => T[K])): this {
    if (typeof value === "function") {
      const currentValue = this.target[key];
      this.target[key] = (value as (current: T[K], target: T) => T[K])(currentValue, this.target);
    } else {
      this.target[key] = value;
    }
    return this;
  }

  build(): T {
    return this.target;
  }
}

export { Builder };
