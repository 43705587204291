import { useState } from "react";

import { Dialog } from "@eisox/design-system";
import { BinIcon, ChevronDownIcon, ChevronUpIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { Plan } from "../../../../../providers";

import styles from "./DropdownPlan.module.scss";

interface DropdownPlanProps {
  plan: Plan;
  handleDelete: VoidFunction;
}

export const DropdownPlan: React.FC<DropdownPlanProps> = ({ plan, handleDelete }) => {
  const bem = useBem(styles);
  const dropdownPlanStyle = bem("dropdown-plan");

  const [expanded, setExpanded] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);

  return (
    <div className={dropdownPlanStyle()}>
      <div className={dropdownPlanStyle("header")}>
        <div className={dropdownPlanStyle("name")} onClick={() => setExpanded(prev => !prev)}>
          <p>{plan.name}</p>
          {expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </div>
        <BinIcon className={dropdownPlanStyle("delete")} onClick={handleDelete} />
      </div>
      {expanded && <img src={plan.image} alt={plan.name} onClick={() => setPreviewImage(true)} />}
      <Dialog.Root open={previewImage} onOpenChange={open => !open && setPreviewImage(false)}>
        <Dialog.Content>
          <img src={plan.image} alt={plan.name} className={dropdownPlanStyle("preview-image")} />
        </Dialog.Content>
      </Dialog.Root>
    </div>
  );
};
