import { mergeQueryKeys } from "@lukemorales/query-key-factory";

import { auth } from "./auth";
import { boilerRoom } from "./boilerRoom";
import { gateway } from "./gateway";
import { heatingNetwork } from "./heatingNetwork";
import { history } from "./history";
import { house } from "./house";
import { mapQuest } from "./mapQuest";
import { meteo } from "./meteo";
import { mesh } from "./mesh";
import { module } from "./module";
import { news } from "./news";
import { plan } from "./plan";
import { planning } from "./planning";
import { room } from "./room";
import { user } from "./user";
import { valve } from "./valve";

export const queries = mergeQueryKeys(
  auth,
  boilerRoom,
  gateway,
  heatingNetwork,
  history,
  house,
  mapQuest,
  meteo,
  mesh,
  module,
  news,
  plan,
  planning,
  room,
  user,
  valve,
);
