import { useState } from "react";

import { useForm } from "react-hook-form";

import { Modal } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import type { HeatingNetwork, Pumps, Valve } from "~/socketio/types";
import { getDirtyValues } from "~/utils/formUtils";
import { isOnlyOnePump } from "~/utils/schemeUtils";

import { useBoilerRoomContextWithCurrentBoilerRoom } from "../../providers";
import { Header, PumpsPermutation } from "./components";
import { MultiWaysValveState } from "./components/MultiWayValveState/MultiWaysValveState";
import { PumpControlState } from "./components/PumpControlState";

import styles from "./NetworkDialog.module.scss";

const NAME = "NetworkDialog";

interface CommonNetworkDialogV2Props {
  dialogTitle: string;
  pumps?: Pumps;
  permutationDay?: number;
  permutationHour?: number;
  children?: React.ReactNode;
}

interface HeatingNetworkDialogProps extends CommonNetworkDialogV2Props {
  networkId?: string;
  boilerId?: never;
  recyclePumpsECS?: Pumps;
  threeWaysValve?: Valve;
}

interface BoilerNetworkDialogProps extends CommonNetworkDialogV2Props {
  networkId?: never;
  boilerId?: string;
  recyclePumpsECS?: Pumps;
  threeWaysValve?: Valve;
}

interface RecyclePumpNetworkDialogProps extends CommonNetworkDialogV2Props {
  networkId?: never;
  boilerId?: never;
  recyclePumpsECS?: never;
  threeWaysValve?: never;
}

type NetworkDialogProps = HeatingNetworkDialogProps | BoilerNetworkDialogProps | RecyclePumpNetworkDialogProps;

export const NetworkDialog: React.FC<NetworkDialogProps> = ({
  dialogTitle,
  networkId,
  boilerId,
  pumps,
  permutationDay,
  permutationHour,
  threeWaysValve,
  recyclePumpsECS,
  children,
}) => {
  const bem = useBem(styles);
  const networkDialogStyle = bem("network-dialog");

  const { currentBoilerRoom, useUpdateBoilerRoom, history } = useBoilerRoomContextWithCurrentBoilerRoom(NAME);
  const boilerroomId = currentBoilerRoom.id;

  const [open, setOpen] = useState(false);

  const { mutate } = useUpdateBoilerRoom({ onSuccess: () => handleOpenChange(false) });

  const {
    control,
    formState: { isDirty, dirtyFields },
    handleSubmit,
    reset,
  } = useForm<Omit<HeatingNetwork, "id">>({
    mode: "onChange",
    defaultValues: {
      threeWaysValve: threeWaysValve,
      pumps: pumps,
      recyclePumpsECS: recyclePumpsECS,
    },
  });

  const onSubmit = (data: Omit<HeatingNetwork, "id">) => {
    let dirtyValues = getDirtyValues(dirtyFields, data as any) as unknown as {
      pumps?: Pumps;
      threeWaysValve?: Valve;
      recyclePumpsECS?: Pumps;
      twoWaysValve?: Valve;
    };
    if (networkId || boilerId) {
      if (dirtyValues.threeWaysValve)
        dirtyValues.threeWaysValve = { ...dirtyValues.threeWaysValve, id: threeWaysValve?.id! };
      if (dirtyValues.pumps) dirtyValues.pumps = { ...dirtyValues.pumps, id: pumps?.id! };
      if (dirtyValues.recyclePumpsECS)
        dirtyValues.recyclePumpsECS = { ...dirtyValues.recyclePumpsECS, id: recyclePumpsECS?.id! };
      if (networkId) {
        mutate([{ id: boilerroomId, heatingNetworks: [{ id: networkId, ...dirtyValues }] }]);
      } else {
        if (dirtyValues.threeWaysValve) {
          dirtyValues = { ...dirtyValues, twoWaysValve: dirtyValues.threeWaysValve };
          delete dirtyValues.threeWaysValve;
        }
        mutate([{ id: boilerroomId, boilers: [{ id: boilerId!, ...dirtyValues }] }]);
      }
    } else {
      mutate([{ id: boilerroomId, recyclePumps: { ...dirtyValues.pumps, id: data.pumps?.id! } }]);
    }
  };

  const handleOpenChange = (open: boolean) => {
    reset({ threeWaysValve, pumps, recyclePumpsECS });
    setOpen(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={networkDialogStyle()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Header title={dialogTitle} network={!!networkId} isDirty={isDirty} history={history} />
          <div className={networkDialogStyle("content")}>
            {threeWaysValve && (
              <MultiWaysValveState
                twoWaysValve={!!boilerId}
                control={control}
                valve={threeWaysValve}
                history={history}
              />
            )}
            {pumps && <PumpControlState type="pumps" control={control} pumps={pumps} history={history} />}
            {recyclePumpsECS && (
              <PumpControlState type="recyclePumpsECS" control={control} pumps={recyclePumpsECS} history={history} />
            )}
            {((pumps && !isOnlyOnePump(pumps)) || (recyclePumpsECS && !isOnlyOnePump(recyclePumpsECS))) && (
              <PumpsPermutation permutationDay={permutationDay} permutationHour={permutationHour} />
            )}
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
