import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import type { InterventionPlanState } from "~/features/InterventionPlanTool";
import { intl } from "~/i18n";

import { darkBlueColor } from "../color";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Lexique } from "../components/Lexique";
import { Table } from "../components/Table";
import { orientation, size } from "../constant";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    padding: "10 20",
  },
  titleSection: {
    color: darkBlueColor,
    fontSize: 16,
    textDecoration: "underline",
    fontWeight: 600,
  },
  left: {
    flex: 5,
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  materialContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  infoContainer: {
    border: `1px solid ${darkBlueColor}`,
    borderRadius: 15,
    padding: 10,
    display: "flex",
    flexDirection: "column",
    fontSize: 9,
    width: "100%",
    gap: 5,
  },
  right: {
    flex: 5,
    display: "flex",
    flexDirection: "row",
    gap: 5,
  },
  lexiqueContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    flex: 6,
  },
  infoLexique: {
    fontSize: 8,
    flex: 4,
  },
});

export const Page3: React.FC<InterventionPlanState> = ({ equipments }) => {
  return (
    <Page size={size} orientation={orientation}>
      <Header title="Brief d’intervention 2/3" />
      <View style={styles.container}>
        <View style={styles.left}>
          <View style={styles.materialContainer}>
            <Text style={styles.titleSection}>
              {intl.formatMessage({ id: "interventionPlanTool.pdf.page3.materialsForIntervention" })} :
            </Text>
            <Table
              data={equipments}
              columns={[
                {
                  field: "name",
                  headerName: `${intl.formatMessage({
                    id: "interventionPlanTool.pdf.page3.table.materialsForIntervention",
                  })} *`,
                },
                {
                  field: "min",
                  headerName: intl.formatMessage({ id: "interventionPlanTool.pdf.page3.table.min" }),
                },
                {
                  field: "max",
                  headerName: intl.formatMessage({ id: "interventionPlanTool.pdf.page3.table.max" }),
                },
              ]}
              minWidthCols={[120, 100, 100]}
              style={{ fontSize: 14 }}
            />
          </View>
          <View style={styles.infoContainer}>
            <Text>*{intl.formatMessage({ id: "interventionPlanTool.pdf.page3.infoMaterial" })}</Text>
            <Text>{intl.formatMessage({ id: "interventionPlanTool.pdf.page3.infoContact" })}</Text>
          </View>
        </View>
        <View style={styles.right}>
          <View style={styles.lexiqueContainer}>
            <Text style={styles.titleSection}>
              {intl.formatMessage({ id: "interventionPlanTool.pdf.page3.lexique" })}* :
            </Text>
            <Lexique />
          </View>
          <Text style={styles.infoLexique}>
            *{intl.formatMessage({ id: "interventionPlanTool.pdf.page3.infoLexique" })}
          </Text>
        </View>
      </View>
      <Footer />
    </Page>
  );
};
