import { PropsWithChildren } from "react";

import { Circle } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import styles from "./IconRoomDataLayer.module.scss";

interface IconRoomDataLayerProps extends PropsWithChildren {
  selected?: boolean;
  disabled?: boolean;
  size?: number;
}

export const IconRoomDataLayer: React.FC<IconRoomDataLayerProps> = ({
  selected = false,
  children,
  disabled = false,
  size,
}) => {
  const bem = useBem(styles);
  const iconRoomDataLayerStyle = bem("icon-room-data-layer");

  return (
    <Circle className={iconRoomDataLayerStyle({ selected: selected, disabled: disabled })} size={size}>
      {children}
    </Circle>
  );
};
