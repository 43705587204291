import * as React from "react";

import { IconProps } from "./types";

export const WindIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M7.28727 9.88313H1.89912C1.67871 9.88313 1.5 10.0718 1.5 10.3045C1.5 10.5371 1.67871 10.7258 1.89912 10.7258H7.28727C7.84 10.7258 8.28504 11.1983 8.28504 11.779C8.28504 12.7206 7.20497 13.1811 6.58152 12.5236C6.42556 12.3592 6.17287 12.3593 6.01708 12.5239C5.8613 12.6886 5.86143 12.9553 6.01736 13.1197C6.35681 13.4777 6.80779 13.6748 7.28727 13.6748C8.27763 13.6748 9.0833 12.8243 9.0833 11.779C9.0833 10.7308 8.27943 9.88313 7.28727 9.88313Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1.89722 5.54999H5.86943C6.74712 5.54999 7.45832 4.82382 7.45832 3.925C7.45832 3.02896 6.74555 2.3 5.86943 2.3C5.44473 2.3 5.04567 2.4691 4.74579 2.77615C4.59075 2.93487 4.5909 3.1921 4.74611 3.35066C4.90133 3.50925 5.15284 3.5091 5.30786 3.35033C5.45766 3.19697 5.65709 3.1125 5.86943 3.1125C6.58103 3.1125 6.92644 3.99352 6.431 4.49966C6.2812 4.65302 6.08177 4.7375 5.86943 4.7375H1.89722C1.67786 4.7375 1.5 4.91939 1.5 5.14374C1.5 5.36809 1.67786 5.54999 1.89722 5.54999Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M12.1887 8.23622C13.4717 8.0736 14.5 6.95084 14.5 5.54961C14.5 4.05678 13.3154 2.84228 11.8594 2.84228C11.1539 2.84228 10.4908 3.12397 9.9922 3.63545C9.83357 3.79815 9.83364 4.06188 9.99234 4.22448C10.151 4.38707 10.4083 4.38702 10.5669 4.22432C10.912 3.87029 11.371 3.67531 11.8594 3.67531C12.8674 3.67531 13.6875 4.51611 13.6875 5.54961C13.6875 6.58501 12.8699 7.42391 11.8594 7.42391H1.90625C1.6819 7.42391 1.5 7.61041 1.5 7.84043C1.5 8.07045 1.6819 8.25694 1.90625 8.25694C12.7759 8.25694 12.0924 8.26845 12.1887 8.23622Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default WindIcon;
