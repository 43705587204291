import { faker } from "@faker-js/faker";

import type { Plan } from "~/apiV2";

import { Builder } from "../utils";

export class PlanBuilder extends Builder<Plan> {
  constructor() {
    super({
      id: faker.database.mongodbObjectId(),
      name: faker.lorem.words(3),
    });
  }
}
