import { useMemo, useState } from "react";

import { orderBy } from "lodash";
import { useIntl } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";

import dayjs from "@eisox/dayjs";
import { Drawer } from "@eisox/design-system";
import { useIsMobile } from "@eisox/tools";
import { RolesMessageRolesDefault } from "@eisox/webapp-api-specification";
import { useQuery, useSuspenseQueries, useSuspenseQuery } from "@tanstack/react-query";

import { MobileUserDropdown, Page } from "~/UI/components";
import { queries } from "~/apiV2";
import { AddHouse } from "~/features/addHouse";
import type { HousePosition } from "~/features/houses";
import { Header, Map, Sidebar, SuspendedAccountDialog, getHousePositions } from "~/features/houses";
import { routeToDashboard, routeToMaintenance } from "~/routes/utils";

const Houses: React.FC = () => {
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const suspendedHouseParam = searchParams.get("suspended");

  const { data: news, isLoading } = useQuery(queries.news.all);
  const { data: houses } = useSuspenseQuery(queries.house.pos);
  const [{ data: info }, { data: roles }] = useSuspenseQueries({ queries: [queries.user.me, queries.user.roles] });

  const housePositions = useMemo(() => orderBy(getHousePositions(houses), "houseName"), [houses]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [displayedHouses, setDisplayedHouses] = useState<HousePosition[]>(housePositions);

  const handlePositionClick = (position: HousePosition) => {
    if (position.suspended && !roles.installer?.includes(position.id)) {
      setSearchParams({ suspended: position.id });
    } else {
      if (isMobile) {
        navigate(routeToMaintenance(position.id));
      } else {
        navigate(routeToDashboard(position.id));
      }
    }
  };

  const onOpenSuspendedAccountDialogChange = () => {
    if (suspendedHouseParam !== null) {
      searchParams.delete("suspended");
      setSearchParams(searchParams);
    }
  };

  const isCreator = !!roles.default?.includes(RolesMessageRolesDefault.creator);
  const isAdmin = !!suspendedHouseParam && !!roles.owner?.includes(suspendedHouseParam);
  const canaAccessPreprod = !!roles.installer;
  const displayChristmasHat = roles.installer && dayjs().month() === 11;

  return (
    <>
      <Page.Root>
        <Sidebar
          firstName={info.firstName}
          lastName={info.lastName}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          email={info.email!}
          company={info.company}
          phone={info.phone}
          news={news ?? []}
          isLoading={isLoading}
          lastNewsId={info.lastNewsId}
          canAccessPreprod={canaAccessPreprod}
          displayChristmasHat={displayChristmasHat}
        />
        <Page.Main rightIcon={<MobileUserDropdown />}>
          <Page.Header>
            <Page.Title>{formatMessage({ id: "houses.title" })}</Page.Title>
            <Header
              isCreator={isCreator}
              housePositions={housePositions}
              onComboboxChange={setDisplayedHouses}
              onAddHouseButtonClick={() => setDrawerOpen(true)}
            />
          </Page.Header>
          <Page.Content>
            <Map
              housePositions={housePositions}
              housePositionsForZoom={displayedHouses}
              onPositionClick={handlePositionClick}
            />
          </Page.Content>
        </Page.Main>
      </Page.Root>
      <Drawer
        title={formatMessage({ id: "houses.house.title" })}
        open={drawerOpen}
        onOpenChange={setDrawerOpen}
        style={{ zIndex: 1300 }}
        overlay={{ style: { zIndex: 1300 } }}
      >
        <AddHouse />
      </Drawer>
      <SuspendedAccountDialog
        open={!!suspendedHouseParam}
        onOpenChange={onOpenSuspendedAccountDialogChange}
        isAdmin={isAdmin}
      />
    </>
  );
};

export { Houses };
