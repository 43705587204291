import { t } from "i18next";

import dayjs from "@eisox/dayjs";

import type { LastModuleHistory, ModuleHistory } from "~/apiV2";

const getMostRecentDate = (lastModuleHistroy: LastModuleHistory[]) =>
  lastModuleHistroy.reduce((acc, curr) => {
    const currentDate = dayjs(curr.createdAt);
    return currentDate.isAfter(acc) ? currentDate.toISOString() : acc;
  }, dayjs(0).toISOString());

interface BoilerRoomItem {
  id: string;
  fields: string[];
}

interface BoilerRoomObject {
  [key: string]: BoilerRoomItem[] | BoilerRoomObject;
}

const getBoilerRoomObject = (paths: string[]): BoilerRoomObject => {
  const result: BoilerRoomObject = {};

  paths.forEach(path => {
    const parts = path.split(".");
    let currentLevel: BoilerRoomObject | BoilerRoomItem | BoilerRoomItem[] = result;

    parts.forEach((part, index) => {
      const match = /([a-zA-Z]+)\[([^\][]+)\]/.exec(part);
      const isLastPart = index === parts.length - 1;

      if (match) {
        const [, key, id] = match;
        if (!Array.isArray((currentLevel as BoilerRoomObject)[key])) {
          (currentLevel as BoilerRoomObject)[key] = [];
        }

        const array = (currentLevel as BoilerRoomObject)[key] as BoilerRoomItem[];
        let item = array.find(item => item.id === id);

        if (!item) {
          item = { id, fields: ["id", "name"] };
          array.push(item);
        }
        currentLevel = item;
      } else {
        if (isLastPart) {
          if (!("fields" in currentLevel)) {
            (currentLevel as unknown as BoilerRoomItem).fields = [];
          }
          (currentLevel as BoilerRoomItem).fields.push(part);
        } else {
          if (!(part in (currentLevel as BoilerRoomObject))) {
            (currentLevel as BoilerRoomObject)[part] = {};
          }
          currentLevel = (currentLevel as BoilerRoomObject)[part];
        }
      }
    });
  });

  return result;
};

const formatUnit = (key: string): string => {
  if (key.includes("Temperature")) return "°C";

  switch (key) {
    case "gazConso":
      return "m3";
    case "calorieConso":
    case "elecConso":
      return "KWh";
    case "woodConso":
      return "T";
    case "flowVolume": {
      return "m3/h";
    }
    case "forcing":
    case "opening":
      return "%";
    default:
      return "";
  }
};

const extractNamesFromPath = (
  boilerRooms: ModuleHistory["boilerRooms"],
  path: string,
): { key: string; unit: string } => {
  const pathElements = path.split(".").map(segment => {
    const match = /([a-zA-Z]+)\[([^\]]+)\]/.exec(segment);
    return { key: match ? match[1] : segment, id: match ? match[2] : null };
  });

  const result: Record<string, string> = {};
  let currentObject: any = { boilerRooms };

  for (const element of pathElements) {
    if (element.id) {
      const array = currentObject[element.key] as { id: string; name?: string }[];
      if (!array || !Array.isArray(array)) {
        break;
      }
      currentObject = array.find(item => item.id === element.id);
      if (!currentObject) {
        break;
      }
      result[element.key] = currentObject.name || "";
    } else {
      currentObject = currentObject[element.key];
      result[element.key] = "";
    }
  }

  const translations: Record<string, string> = Object.entries(result).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      } else {
        acc[key] = t(`boilerRoom.history.detailedHistory.keys.${key}`);
      }
      return acc;
    },
    {} as Record<string, string>,
  );

  const key = Object.values(translations).join(" > ");
  const unit = formatUnit(pathElements[pathElements.length - 1].key);

  return { key, unit };
};

const getValueFromPath = (boilerRooms: { boilerRooms: ModuleHistory["boilerRooms"] }, path: string) => {
  const pathSegments = path.split(".");

  let current: any = boilerRooms;

  for (const segment of pathSegments) {
    const match = /(\w+)\[([^\]]+)\]/.exec(segment);

    if (match) {
      const [, property, id] = match;
      if (Array.isArray(current[property])) {
        current = current[property].find((item: any) => item.id === id);
      } else {
        return undefined;
      }
    } else {
      if (current.hasOwnProperty(segment)) {
        current = current[segment];
      } else {
        return undefined;
      }
    }
  }

  return current;
};

const getDataFromHistoryResponse = (
  paths: string[],
  response: ModuleHistory[],
  boilerRoomSchema: ModuleHistory["boilerRooms"],
) =>
  response
    .sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)))
    .map(m => {
      const data: Record<string, number> = {};
      paths.forEach(path => {
        const value = getValueFromPath({ boilerRooms: m.boilerRooms ?? [] }, path) as boolean | number | undefined;
        const transformedValue =
          value !== undefined
            ? typeof value === "boolean"
              ? Number(value)
              : Number.parseFloat(value.toFixed(1))
            : undefined;
        if (transformedValue !== undefined) {
          data[extractNamesFromPath(boilerRoomSchema ?? [], path).key] = transformedValue;
        }
      });
      return { date: dayjs(m.createdAt).valueOf(), ...data };
    });

const getUnitAxesMapping = (paths: string[], objectSchema: ModuleHistory["boilerRooms"]): Record<string, string[]> => {
  const namesAndUnits = paths.map(path => extractNamesFromPath(objectSchema, path));

  const unitAxesMapping: Record<string, string[]> = {};
  namesAndUnits.forEach(({ key, unit }) => {
    if (!unitAxesMapping[unit]) {
      unitAxesMapping[unit] = [];
    }
    unitAxesMapping[unit].push(key);
  });

  return unitAxesMapping;
};

export {
  extractNamesFromPath,
  getBoilerRoomObject,
  getDataFromHistoryResponse,
  getMostRecentDate,
  getUnitAxesMapping,
  getValueFromPath,
};
