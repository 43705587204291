import { StatusCodes } from "http-status-codes";

import { client } from "../api";
import type {
  CheckValidationCodeRequestBody,
  CheckValidationCodeResponseBody,
  EnrollAccountRequestBody,
  EnrollAccountRequestParams,
  LoginRequestBody,
  LoginResponseBody,
  RecoverAccountRequestParams,
  RecoverAccountResponseBody,
  ResetPasswordRequestBody,
  ResetPasswordRequestParams,
  UpdateRolesRequestBody,
  UpdateUserHouseRequestBody,
  ValidationPhoneNumberResponseBody,
} from "../types";

const updateUser = async (body: UpdateUserHouseRequestBody) => {
  const { data, error } = await client.PATCH("/user/", { body });
  if (error || !data?.message) throw new Error(error);
  return data.message;
};

const enrollAccount = async ({ token, ...body }: EnrollAccountRequestParams & EnrollAccountRequestBody) => {
  const { data, error } = await client.PATCH("/users/{token}/activateaccount", { body, params: { path: { token } } });
  if (error || !data?.data) throw new Error(error);
  return data.data;
};

const resetPassword = async ({ token, ...body }: ResetPasswordRequestBody & ResetPasswordRequestParams) => {
  const { error, data } = await client.PATCH("/users/{token}/resetPassword", { body, params: { path: { token } } });
  if (error || !data?.message) throw new Error(error);
  return data.message;
};

const login = async (body: LoginRequestBody): Promise<LoginResponseBody["data"]> => {
  const { data, error, response } = await client.POST("/login/", { body });
  if ((response.status as StatusCodes) === StatusCodes.UNAUTHORIZED) throw new Error("unauthorized");
  if (error || !data?.data) throw new Error(error);
  return data.data;
};

const recoverAccount = async (params: RecoverAccountRequestParams): Promise<RecoverAccountResponseBody["message"]> => {
  const { data, error } = await client.POST("/users/{email}/resetPassword", {
    params: { path: { email: params.email } },
  });
  if (error || !data?.message) throw new Error(error?.message);
  return data.message;
};

const sendValidationPhoneNumber = async (): Promise<ValidationPhoneNumberResponseBody["message"]> => {
  //@ts-expect-error - Wait update doc
  const { data, error } = await client.POST("/users/validationPhoneNumber");
  if (error || !data?.message) throw new Error(error);
  return data.message;
};

const checkValidationCode = async (
  body: CheckValidationCodeRequestBody,
): Promise<CheckValidationCodeResponseBody["message"]> => {
  //@ts-expect-error - Wait update doc
  const { data, error } = await client.POST("/users/checkValidationCode", { body });
  if (error || !data?.message) throw new Error(error);
  return data.message;
};

const udapteRoles = async (email: string, body: UpdateRolesRequestBody) => {
  const { data, error } = await client.PATCH("/users/{email}/roles/", { params: { path: { email } }, body });
  if (error || !data?.message) throw new Error(error?.message);
  return data.message;
};

export {
  checkValidationCode,
  enrollAccount,
  login,
  recoverAccount,
  resetPassword,
  sendValidationPhoneNumber,
  udapteRoles,
  updateUser,
};
