import dayjs, { Dayjs } from "@eisox/dayjs";

export const getLastNDays = (nbDays: number): Dayjs[] => {
  const datesOfLastNDays: Dayjs[] = [];
  const today = dayjs();
  for (let i = 0; i < nbDays; i++) {
    datesOfLastNDays.push(today.subtract(i, "day"));
  }
  return datesOfLastNDays;
};

export const getLastQuarter = () =>
  dayjs()
    .minute(Math.floor(dayjs().minute() / 15) * 15)
    .startOf("minute");
