import { forwardRef } from "react";

import clsx from "clsx";

import { useBem } from "@eisox/tools";

import styles from "./styles.module.scss";

export interface CircleProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  selected?: boolean;
  disabled?: boolean;
  size?: number;
  error?: "error" | "warning" | "on" | "off" | "unknown";
}

export const Circle = forwardRef<HTMLDivElement, CircleProps>(
  (
    { selected = false, disabled = false, size = 40, children, error = undefined, onClick, className, ...props },
    ref,
  ) => {
    const bem = useBem(styles);
    const circleStyle = bem("circle");

    const r = size / 2;
    const x = r + r * Math.cos(45 * (Math.PI / 180));
    const y = r - r * Math.sin(45 * (Math.PI / 180));

    const errorSize = size / 4;
    const errorBorder = size / 30;

    return (
      <div
        {...props}
        ref={ref}
        style={{ ...props.style, width: size, height: size }}
        className={clsx(circleStyle({ selected: selected, disabled: disabled }), className)}
        onClick={e => !disabled && onClick && onClick(e)}
      >
        {children}
        {error && (
          <div
            className={circleStyle("error", { variant: error })}
            style={{
              width: errorSize,
              height: errorSize,
              borderWidth: errorBorder,
              position: "absolute",
              top: `${y}px`,
              left: `${x}px`,
            }}
          />
        )}
      </div>
    );
  },
);
