import { Fragment, useMemo } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import type { PostNotif } from "@eisox/backend_webapp_api";
import type { ManipulateType } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import type { Option } from "@eisox/design-system";
import { ActionButton, Select } from "@eisox/design-system";
import { BinIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import type { houseLoader } from "~/UI/screens";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToNotify } from "~/routes/utils";
import { API } from "~/types/API";
import { addTime, isExpiredDate } from "~/utils";

import styles from "./RemoteAccessV2.module.scss";

const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    gateways: yup
      .array(yup.string().required())
      .min(1, formatMessage({ id: "settings.content.menu.houses.remoteAccessV2.gateway.error" }))
      .required(),
    expireAt: yup
      .string()
      .required(formatMessage({ id: "settings.content.menu.houses.remoteAccessV2.modifiedDuration.error" })),
  });

type RemoteAccessFormType = yup.InferType<ReturnType<typeof schema>>;

export const RemoteAccessV2: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const remoteAccessStyle = bem("remote-access");
  const selectsStyle = bem("selects");
  const listStyle = bem("list");
  const gatewaysStyle = bem("gateways");

  const { houseId } = useParams() as { houseId: string };
  const { gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;
  const gatewayOptions: Option[] = useMemo(
    () => gateways.map(gateway => ({ name: gateway.gatewayName!, value: gateway.id! })),
    [gateways],
  );

  const durationOptions: Option[] = [
    {
      value: "30 minutes",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.minute" }, { m: 30 }),
    },
    {
      value: "1 hour",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.hour" }, { h: 1 }),
    },
    {
      value: "6 hours",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.hour" }, { h: 6 }),
    },
    {
      value: "24 hours",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.hour" }, { h: 24 }),
    },
    {
      value: "48 hours",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.hour" }, { h: 48 }),
    },
    {
      value: "7 days",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.day" }, { d: 7 }),
    },
    {
      value: "1 month",
      name: formatMessage({ id: "drawer.listDrawer.editGatewayDrawer.remoteAccess.selectDuration.month" }, { m: 1 }),
    },
  ];

  const { state, submit } = useAction({
    onSuccess: () => {
      reset({ gateways: [], expireAt: "" });
      toast(formatMessage({ id: "settings.content.menu.houses.remoteAccessV2.success" }), { type: "success" });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, errors },
    reset,
  } = useForm<RemoteAccessFormType>({
    resolver: yupResolver(schema(formatMessage)),
    defaultValues: {
      gateways: [],
      expireAt: "",
    },
  });

  const transformDuration = (value: string) =>
    addTime(parseInt(value.split(" ")[0]), value.split(" ")[1] as ManipulateType);

  const onSubmit = (data: RemoteAccessFormType) => {
    const body: PostNotif = {
      gatewayIds: data.gateways,
      expireAt: dayjs(transformDuration(data.expireAt)).toISOString(),
      notificationName: "ENABLE_SSH",
    };
    submit(body, API.HTTP_METHOD.POST, routeToNotify(houseId));
  };

  const handleDeleteRemoteAccess = (gatewayId: string) => {
    const body: PostNotif = {
      gatewayIds: [gatewayId],
      expireAt: undefined,
      notificationName: "DISABLE_SSH",
    };
    submit(body, API.HTTP_METHOD.POST, routeToNotify(houseId));
  };

  return (
    <form className={remoteAccessStyle()} onSubmit={handleSubmit(onSubmit)}>
      <div className={selectsStyle()}>
        <Controller
          control={control}
          name="gateways"
          render={({ field: { value, onChange } }) => (
            <Select
              label={formatMessage({ id: "settings.content.menu.houses.remoteAccessV2.gateway.label" })}
              multiple
              value={value}
              options={gatewayOptions}
              renderValue={gateways => (
                <p>
                  {gateways && gateways.length > 0
                    ? gateways.map(g => gatewayOptions.find(o => o.value === g)?.name).join(", ")
                    : formatMessage({ id: "settings.content.menu.houses.remoteAccessV2.gateway.placeholder" })}
                </p>
              )}
              onChange={onChange}
              displaySelectAllButton
              error={errors.gateways}
            />
          )}
        />
        <Controller
          control={control}
          name="expireAt"
          render={({ field: { value, onChange } }) => (
            <>
              <Select
                label={formatMessage({ id: "settings.content.menu.houses.remoteAccessV2.modifiedDuration.label" })}
                multiple={false}
                value={value}
                options={durationOptions}
                renderValue={value => <p>{durationOptions.find(v => v.value === value)?.name || "---"}</p>}
                onChange={onChange}
                error={errors.expireAt}
              />
              {value && (
                <p className={selectsStyle("expiration-date")}>
                  {formatMessage(
                    { id: "settings.content.menu.houses.remoteAccessV2.modifiedDuration.value" },
                    {
                      v: transformDuration(value).format("DD/MM/YYYY HH:mm:ss"),
                    },
                  )}
                </p>
              )}
            </>
          )}
        />
      </div>
      <div className={listStyle()}>
        <h3 className={remoteAccessStyle("title")}>
          {formatMessage({ id: "settings.content.menu.houses.remoteAccessV2.active" })}
        </h3>
        <div className={gatewaysStyle()}>
          {gateways
            .filter(g => !!g.expireAt && !isExpiredDate(g.expireAt))
            .map((g, i) => (
              <Fragment key={i}>
                <p>{g.gatewayName}</p>
                <p className={gatewaysStyle("expiry-date")}>{dayjs(g.expireAt).format("DD/MM/YYYY HH:mm:ss")}</p>
                <BinIcon className={gatewaysStyle("bin")} onClick={() => handleDeleteRemoteAccess(g.id!)} />
              </Fragment>
            ))}
        </div>
      </div>
      <ActionButton
        type="submit"
        className={remoteAccessStyle("submit-button")}
        text={formatMessage({ id: "settings.content.menu.houses.remoteAccessV2.save" })}
        disabled={!isDirty || ["submitting", "loading"].includes(state)}
      />
    </form>
  );
};
