import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import { useControllableState } from "@eisox/tools";
import * as RPToggleGroup from "@radix-ui/react-toggle-group";

import styles from "./ToggleGroup.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
const ROOT_NAME = "ToggleGroup";

interface RootProps extends Omit<RPToggleGroup.ToggleGroupSingleProps, "type"> {
  rounded?: boolean;
}

const Root = forwardRef<HTMLDivElement, RootProps>(
  (
    { defaultValue, value: valueProp, onValueChange, disabled, rounded = true, className, children, ...props },
    forwardedRef,
  ) => {
    const [value, setValue] = useControllableState({
      prop: valueProp,
      onChange: onValueChange,
      defaultProp: defaultValue,
    });

    const handleValueChange = (value?: string) => {
      if (value) setValue(value);
    };

    return (
      <RPToggleGroup.Root
        {...props}
        type="single"
        ref={forwardedRef}
        asChild={false}
        className={cx(styles.toggleGroup, rounded && styles.toggleGroup_rounded, className)}
        value={value}
        onValueChange={handleValueChange}
        disabled={disabled}
      >
        {children}
        <div className={cx(styles.highlight)} data-disabled={disabled} />
      </RPToggleGroup.Root>
    );
  },
);
Root.displayName = ROOT_NAME;

/* -------------------------------------------------------------------------------------------------
 * Item
 * -----------------------------------------------------------------------------------------------*/
const ITEM_NAME = "ToggleGroupItem";

type ItemProps = Omit<RPToggleGroup.ToggleGroupItemProps, "type">;

const Item = forwardRef<HTMLButtonElement, ItemProps>(({ className, children, ...props }, forwardedRef) => {
  return (
    <RPToggleGroup.Item {...props} ref={forwardedRef} asChild={false} className={cx(styles.item, className)}>
      <span className={styles.label}>
        <span className={styles.labelActive}>{children}</span>
        <span className={styles.labelInactive}>{children}</span>
      </span>
    </RPToggleGroup.Item>
  );
});

Item.displayName = ITEM_NAME;

export { Item, Root };
export type { ItemProps, RootProps };
