import clsx from "clsx";
import type { SubmitHandler, UseFormReturn } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import type { PlansMessageInner } from "@eisox/backend_webapp_api";
import type { Option } from "@eisox/design-system";
import { ActionButton, Button, SelectV2 as Select, TextInput } from "@eisox/design-system";
import { PencilIcon, PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { houseLoader } from "~/UI";
import type { GatewaysWithProblem, RoomsWithProblem } from "~/UI/screens/House";
import type { HeatingNetwork } from "~/apiV2";
import { idLoaderHouse } from "~/routes/utils";

import type { ValveFormDto } from "../Valves";

import styles from "./ValveForm.module.scss";

interface ValveFormProps extends HTMLProps<"div"> {
  isCurrentValveIsOnPlan: boolean;
  heatingNetworksRadiator: HeatingNetwork[];
  form: UseFormReturn<ValveFormDto, any, ValveFormDto>;
  handleUpdateValve: SubmitHandler<ValveFormDto>;
  handleSetToNextValve: () => void;
  handleDeleteValve: (v: ValveFormDto) => void;
  toAdd: boolean;
}

export const ValveForm: React.FC<ValveFormProps> = ({
  isCurrentValveIsOnPlan,
  heatingNetworksRadiator,
  form,
  className,
  handleUpdateValve,
  handleSetToNextValve,
  handleDeleteValve,
  toAdd,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const { plans, rooms, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = form;

  const currentValve = watch();

  const bem = useBem(styles);
  const containerStyle = bem("container");
  const buttonContainerStyle = bem("button-container");

  const options = (array: (PlansMessageInner | RoomsWithProblem | GatewaysWithProblem)[]): Option[] => {
    return array.map(e => {
      return {
        name:
          ("parentName" in e && "name" in e && `${e.parentName} / ${e.name}`) ||
          ("name" in e && e.name) ||
          ("gatewayName" in e && e.gatewayName) ||
          "--",
        value: e.id!,
      };
    });
  };

  return (
    <div {...props} className={clsx(containerStyle(), className)}>
      <h3>
        {formatMessage({
          id: `plan.actions.preinstall.valves.valveForm.title.${isCurrentValveIsOnPlan ? "editValve" : "newValve"}`,
        })}
      </h3>

      <Controller
        control={control}
        name="plan.planId"
        render={({ field: { onChange, value } }) => (
          <Select
            label={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.level.label" })}
            options={options(plans)}
            value={value}
            onChange={value => {
              setValue("roomId", undefined);
              onChange(value);
            }}
            error={errors.plan?.planId}
          />
        )}
      />

      <TextInput
        {...register("uid")}
        label={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.number" })}
        error={errors.uid}
        type="number"
      />

      {toAdd && (
        <Controller
          name="mac"
          control={control}
          render={({ field: { value, onChange } }) => (
            <TextInput
              label={formatMessage({
                id: "plan.actions.add.valves.serialNumber.label",
              })}
              placeholder={formatMessage({ id: "plan.actions.add.valves.serialNumber.placeholder" })}
              value={value}
              onChange={e => onChange(e.target.value.toUpperCase())}
              error={errors.mac}
              type={"string"}
            />
          )}
        />
      )}

      <Controller
        control={control}
        name="roomId"
        render={({ field: { onChange, value } }) => (
          <Select
            label={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.room.label" })}
            placeholder={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.room.placeholder" })}
            options={options(rooms)}
            value={value}
            onChange={onChange}
            error={errors.roomId}
          />
        )}
      />

      <Controller
        control={control}
        name="gatewayId"
        render={({ field: { onChange, value } }) => (
          <Select
            label={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.gateway.label" })}
            placeholder={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.gateway.placeholder" })}
            value={value}
            options={options(gateways)}
            onChange={onChange}
            error={errors.gatewayId}
          />
        )}
      />

      {heatingNetworksRadiator.length > 0 && (
        <Controller
          control={control}
          name="heatingNetworkId"
          render={({ field: { onChange, value } }) => (
            <Select
              label={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.heatingNetwork.label" })}
              placeholder={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.heatingNetwork.placeholder" })}
              value={value}
              options={options(heatingNetworksRadiator)}
              onChange={onChange}
              error={errors.heatingNetworkId}
            />
          )}
        />
      )}

      {isCurrentValveIsOnPlan && (
        <div className={buttonContainerStyle()}>
          <Button
            text={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.delete" })}
            className={buttonContainerStyle({ type: "delete" })}
            onClick={() => handleDeleteValve(currentValve)}
          />
          <div className={buttonContainerStyle("edit")}>
            {isDirty && (
              <Button
                text={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.cancel" })}
                className={buttonContainerStyle({ type: "cancel" })}
                onClick={() => reset()}
              />
            )}
            <ActionButton
              text={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.edit" })}
              onClick={() => handleSubmit(handleUpdateValve)()}
              variant="primary"
              disabled={!isDirty}
              icon={<PencilIcon />}
            />
          </div>
        </div>
      )}

      <div className={containerStyle("infoNewValve", { disabled: isDirty })}>
        {isCurrentValveIsOnPlan ? (
          <Button
            text={formatMessage({ id: "plan.actions.preinstall.valves.valveForm.newValve" })}
            icon={<PlusIcon />}
            disabled={isDirty}
            onClick={handleSetToNextValve}
          />
        ) : (
          <span>{formatMessage({ id: "plan.actions.preinstall.valves.valveForm.infoPlanAddNewValve" })}</span>
        )}
      </div>
    </div>
  );
};
