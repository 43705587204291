import { ValveErrors, ValveWarnings } from "@eisox/problems-handler";

import {
  ProblemValveByError,
  ProblemValveIsUnclogging,
} from "~/features/InterventionPlanTool/components/InterventionPlanTool/utils";

const getResolutionFromValveProblems = (problem: (ValveErrors | ValveWarnings)[]) =>
  (Object.keys(ProblemValveByError) as (keyof typeof ProblemValveByError)[]).find(resolution =>
    ProblemValveByError[resolution].some(p => problem.includes(p)),
  );

const unglog = (problem: (ValveErrors | ValveWarnings)[]): boolean =>
  ProblemValveIsUnclogging.some(p => problem.includes(p));

export { getResolutionFromValveProblems, unglog };
