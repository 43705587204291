import { useEffect, useRef, useState } from "react";

export const useTextOverflowEllipsisDetection = <T extends HTMLElement>() => {
  const ref = useRef<T | null>(null);
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  useEffect(() => {
    const checkTextOverflow = () => {
      if (ref.current) {
        const element = ref.current;
        setIsTruncated(
          element.scrollWidth > element.clientWidth || // Débordement horizontal
            element.scrollHeight > element.clientHeight, // Débordement vertical
        );
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      checkTextOverflow();
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return { ref, isTruncated };
};
