import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import * as Separator from "@radix-ui/react-separator";

import styles from "./Divider.module.scss";

type DividerProps = Separator.SeparatorProps;

const Divider = forwardRef<HTMLHRElement, DividerProps>(({ className, ...props }, forwardedRef) => {
  return <Separator.Root {...props} ref={forwardedRef} className={cx(styles.divider, className)} />;
});

Divider.displayName = "Divider";

export { Divider };
export type { DividerProps };
