import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { PostHistovalvesStatsFields } from "@eisox/webapp-api-specification";
import { z } from "@eisox/zod";

import type { BoilerRoom } from "~/socketio/types";
import { createSelectors, validateKeyWithSchema } from "~/utils/Zustand";

import type { HistoryAction, HistoryState } from "./type";

const initalState: HistoryState = {
  boilerromPath: {},
  consumption: {},
  valve: [
    PostHistovalvesStatsFields.temperature,
    PostHistovalvesStatsFields.closing,
    PostHistovalvesStatsFields.instructionTemp,
    PostHistovalvesStatsFields.correctedTemp,
  ],
};

const validatorSchema = z.object({
  valve: z.array(z.nativeEnum(PostHistovalvesStatsFields)),
});

const useHistoryStoreBase = create<HistoryState & HistoryAction>()(
  persist(
    set => ({
      ...initalState,
      addBoilerromPath: (boilerromPath: Record<string, string[]>) =>
        set(state => ({ boilerromPath: { ...state.boilerromPath, ...boilerromPath } })),
      addConsumption: (consumption: Record<string, BoilerRoom[]>) =>
        set(state => ({ consumption: { ...state.consumption, ...consumption } })),
      setValves: valves => set({ valve: valves }),
    }),
    {
      name: "history",
      storage: createJSONStorage(() => sessionStorage, {
        reviver: (key, value) => validateKeyWithSchema(validatorSchema, initalState, key, value),
      }),
      version: 1,
    },
  ),
);

const useHistoryStore = createSelectors(useHistoryStoreBase);

export { useHistoryStore };
