import { ActionFunctionArgs, redirect } from "react-router-dom";

import { PatchModule } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { loading } from "~/UI/layouts/LoadingPopup";
import { deleteModule, updateModule } from "~/api/modules";
import { SUCCESS_FETCH, getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { routeToModulesSettings } from "~/routes/utils";
import { API } from "~/types/API";

export const action = async ({
  request,
  params,
}: ActionFunctionArgs): Promise<API.Response<string | null> | Response> => {
  const { houseId } = params as { houseId: string };
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.PATCH:
      const updates: PatchModule = JSON.parse(formData.get("json") as string);
      const patchResponse: any = await updateModule(params.houseId, params.moduleId, updates);
      const patchStatus = getHttpStatusByFetchStatus(patchResponse.type) as unknown as API.HTTP_STATUS;
      return {
        date: dayjs(),
        restError: patchResponse.type,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [{ status: patchStatus, message: patchResponse.result }],
      };
    case API.HTTP_METHOD.DELETE:
      const deleteResponse: any = await deleteModule(params.houseId, params.moduleId);
      if (deleteResponse.type !== SUCCESS_FETCH) {
        const deleteStatus = getHttpStatusByFetchStatus(patchResponse.type) as unknown as API.HTTP_STATUS;
        return {
          date: dayjs(),
          restError: deleteResponse.type,
          url: request.url,
          method: API.HTTP_METHOD.DELETE,
          message: [{ status: deleteStatus, message: deleteResponse.result }],
        };
      }
      loading(false);
      return redirect(routeToModulesSettings(houseId));
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
