import { faker } from "@faker-js/faker";

import type { BoilerRoom, BoilerRoomModule } from "~/apiV2";

import { Builder } from "../utils";

export class RestBoilerRoomBuilder extends Builder<BoilerRoom> {
  constructor() {
    super({
      id: faker.database.mongodbObjectId(),
      name: faker.lorem.words(),
      plan: faker.helpers.maybe(
        () => ({
          planId: faker.database.mongodbObjectId(),
          x: faker.number.int({ min: 10, max: 90 }),
          y: faker.number.int({ min: 10, max: 90 }),
        }),
        { probability: 0.85 },
      ),
    });
  }
}

export class RestBoilerRoomModuleBuilder extends Builder<BoilerRoomModule> {
  constructor() {
    super({
      _id: faker.database.mongodbObjectId(),
      moduleId: faker.database.mongodbObjectId(),
      boilerRooms: Array.from({ length: faker.number.int({ min: 1, max: 10 }) }, () =>
        new RestBoilerRoomBuilder().build(),
      ),
    });
  }
}
