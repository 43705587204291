import { useEffect, useRef, useState } from "react";

import { Card } from "@eisox/design-system";
import { BinIcon, ChevronDownIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { getPlan } from "~/api/plan";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import { useAction } from "~/hooks";
import { routeToPlan } from "~/routes/utils";
import { API } from "~/types/API";

import styles from "./PlanCard.module.scss";

interface PlanCardProps {
  id: string;
  name: string;
  houseId: string;
  expanded: boolean;
  onClick: VoidFunction;
  onDelete: VoidFunction;
}

export const PlanCard: React.FC<PlanCardProps> = ({ id, name, houseId, expanded, onClick, onDelete }) => {
  const bem = useBem(styles);
  const planCardStyle = bem("plan-card");
  const triggerStyle = bem("trigger");
  const imageStyle = bem("image");

  const imgRef = useRef<HTMLImageElement>(null);

  const INITIAL_HEIGHT = 57;
  const IMAGE_MARGIN_PADDING = 40;

  const [imgUrl, setImgUrl] = useState<string>();
  const [height, setHeight] = useState<number>(INITIAL_HEIGHT);

  useEffect(() => {
    getPlan(id, houseId).then(response => response.type === SUCCESS_FETCH && setImgUrl(response.result.message));
  }, []);

  useEffect(() => {
    imgRef.current && setHeight(INITIAL_HEIGHT + imgRef.current.height + IMAGE_MARGIN_PADDING);
  }, [imgRef.current?.height]);

  const { submit } = useAction({
    onSuccess: () => onDelete(),
  });

  return (
    <Card className={planCardStyle()} style={{ height: expanded ? height : INITIAL_HEIGHT }}>
      <div
        className={triggerStyle()}
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        <h3 className={triggerStyle("title")}>{name}</h3>
        <ChevronDownIcon className={triggerStyle("dropdown", { expanded })} />
        <BinIcon
          className={triggerStyle("delete-button")}
          onClick={e => {
            e.preventDefault();
            submit(null, API.HTTP_METHOD.DELETE, routeToPlan(houseId, id));
            onDelete();
          }}
        />
      </div>
      <div className={imageStyle()}>
        <img ref={imgRef} className={imageStyle("image")} src={imgUrl} alt={`${name} plan`} />
      </div>
    </Card>
  );
};
