import { cx } from "class-variance-authority";
import { Trans, useTranslation } from "react-i18next";

import { DrawerV2 } from "@eisox/design-system";

import type { GatewaysWithProblem } from "~/UI/screens/House";
import { Card } from "~/features/HeatingNetworks/components";
import type { BoilerRoom, HeatingCurve } from "~/socketio/types";

import { Chart } from "../Chart";
import { CopyDialog } from "../CopyDialog";
import { HeatingCurveDialog } from "../HeatingCurveDialog";

import styles from "./HeatingCurveCard.module.scss";

interface HeatingCurveCardProps {
  houseId: string;
  moduleId: string;
  boilerRoomId: string;
  heatingNetworkId: string;
  heatingNetworkName: string;
  heatingCurveAuto: boolean;
  gateways: GatewaysWithProblem[];
  maxOffsetAdaptation?: number;
  reducedTemperatureAbs?: number;
  heatingCurve: HeatingCurve[];
  externalTemperature?: number;
  setpointTemperature?: number;
  targetHslope?: number;
  boilerRooms: BoilerRoom[];
  className?: string;
}

const HeatingCurveCard: React.FC<HeatingCurveCardProps> = ({
  houseId,
  moduleId,
  boilerRoomId,
  heatingNetworkId,
  heatingNetworkName,
  heatingCurveAuto,
  gateways,
  maxOffsetAdaptation,
  reducedTemperatureAbs,
  heatingCurve,
  externalTemperature,
  setpointTemperature,
  targetHslope,
  boilerRooms,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Card.Root className={cx(styles.heatingCurveCard, className)}>
      <Card.Header title={t("network.content.heatingCurve.title")} className={styles.header}>
        <CopyDialog
          heatingNetworkId={heatingNetworkId}
          heatingNetworkName={heatingNetworkName}
          heatingCurve={heatingCurve}
          boilerRooms={boilerRooms}
        />
        <HeatingCurveDialog
          houseId={houseId}
          moduleId={moduleId}
          boilerRoomId={boilerRoomId}
          heatingNetworkId={heatingNetworkId}
          heatingNetworkName={heatingNetworkName}
          gateways={gateways}
          isAutoHeatingCurves={heatingCurveAuto}
          targetHslope={targetHslope}
          maxOffsetAdaptation={maxOffsetAdaptation}
          heatingCurve={heatingCurve}
        />
        <div className={cx(styles.header__legend, styles.header__legend_manual)}>
          {t("network.content.heatingCurve.chart.tooltip.manual")}
        </div>
        {heatingCurveAuto && (
          <div className={cx(styles.header__legend, styles.header__legend_auto)}>
            {t(t("network.content.heatingCurve.chart.tooltip.auto"))}
          </div>
        )}
        {heatingCurveAuto && (
          <p className={styles.header__maxOffsetAdaptation}>
            {t("network.content.heatingCurve.autoMode", { t: maxOffsetAdaptation })}
          </p>
        )}
      </Card.Header>
      <div className={styles.content}>
        <p>
          <Trans
            i18nKey={"network.content.heatingCurve.text"}
            values={{ t: reducedTemperatureAbs }}
            components={[<DrawerV2.Trigger className={styles.content__link} />]}
          />
        </p>
        <Chart
          className={styles.content__heatingCurve}
          auto={heatingCurveAuto}
          points={heatingCurve}
          externalTemperature={externalTemperature}
          setPointTemperature={setpointTemperature}
        />
      </div>
    </Card.Root>
  );
};

export { HeatingCurveCard };
export type { HeatingCurveCardProps };
