import * as yup from "yup";

import { PostModuleOperatorProtocolTypeEnum, PostModuleTypeEnum } from "@eisox/backend_webapp_api";

export const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    url: yup.string().required(formatMessage({ id: "error.emptyField" })),
    port: yup.number().required(),
    userName: yup.string().required(formatMessage({ id: "error.emptyField" })),
    password: yup.string().required(formatMessage({ id: "error.emptyField" })),
    type: yup.mixed<PostModuleTypeEnum>().oneOf(Object.values(PostModuleTypeEnum)).required(),
    operatorPort: yup.number().required(),
    operatorProtocolType: yup
      .mixed<PostModuleOperatorProtocolTypeEnum>()
      .oneOf(Object.values(PostModuleOperatorProtocolTypeEnum))
      .required(),
    gatewayId: yup.string().required(formatMessage({ id: "error.emptyField" })),
  });

export type AddBoilerroomType = yup.InferType<ReturnType<typeof schema>>;
