import React, { forwardRef } from "react";

import { cx } from "class-variance-authority";

import type { HeadingProps } from "@eisox/design-system";
import { Eisox, Heading, Sidebar } from "@eisox/design-system";

import { useUserPreferenceStore } from "~/stores";

import styles from "./Page.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Page
 * -----------------------------------------------------------------------------------------------*/

type PageProps = React.ComponentPropsWithRef<"div">;

export const Page = forwardRef<HTMLDivElement, PageProps>((props, forwardedRef) => {
  const { className, children, ...pageProps } = props;

  const sidebarCollapsed = useUserPreferenceStore.use.sidebarCollapsed();
  const toggleSidebar = useUserPreferenceStore.use.toggleSidebar();

  return (
    <div {...pageProps} ref={forwardedRef} className={cx(styles.page, className)}>
      <Sidebar.Provider collapsed={sidebarCollapsed} onCollapsedChange={toggleSidebar} defaultOpen={false}>
        {children}
      </Sidebar.Provider>
    </div>
  );
});

Page.displayName = "Page";

/* -------------------------------------------------------------------------------------------------
 * Main
 * -----------------------------------------------------------------------------------------------*/

interface MainProps extends React.ComponentPropsWithRef<"div"> {
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

const Main = React.forwardRef<HTMLDivElement, MainProps>((props, forwardedref) => {
  const { leftIcon, rightIcon, className, children, ...authenticatedProps } = props;

  return (
    <main {...authenticatedProps} ref={forwardedref} className={cx(styles.main, className)}>
      <div className={styles.mobileHeader}>
        {/* Need that other page are refactored to be displayed on mobile */}
        {/* <Sidebar.Trigger>
          <MenuIcon className={styles.mobileHeader__icon} />
        </Sidebar.Trigger> */}
        {leftIcon}
        <Eisox className={styles.mobileHeader__eisox} />
        {rightIcon}
      </div>
      {children}
    </main>
  );
});

Main.displayName = "Page.Main";

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/

type HeaderProps = React.ComponentPropsWithRef<"div">;

const Header = React.forwardRef<HTMLDivElement, HeaderProps>((props, forwardedref) => {
  const { children, className, ...headerProps } = props;
  return (
    <div {...headerProps} ref={forwardedref} className={cx(styles.header, className)}>
      {children}
    </div>
  );
});

Header.displayName = "PageHeader";

/* -------------------------------------------------------------------------------------------------
 * Title
 * -----------------------------------------------------------------------------------------------*/

type TitleProps = HeadingProps;

const Title = React.forwardRef<HTMLHeadingElement, TitleProps>((props, forwardedref) => (
  <Heading {...props} ref={forwardedref} className={cx(styles.title, props.className)} />
));

Title.displayName = "Title";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/

type ContentProps = React.ComponentPropsWithRef<"div">;

const Content = React.forwardRef<HTMLDivElement, ContentProps>((props, forwardedref) => {
  const { className, ...contentProps } = props;
  return <div {...contentProps} ref={forwardedref} className={cx(styles.content, className)} />;
});

Content.displayName = "PageContent";

const Root = Page;

export { Content, Header, Main, Root, Title };
export type { ContentProps, HeaderProps, MainProps, TitleProps };
