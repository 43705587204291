import type {
  HouseMessage,
  HousesHouseIdBoilerroomsPosGet200ResponseMessageInner,
} from "@eisox/backend_webapp_api/dist/models";
import type { Dayjs } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";

import { HOUSE_MAXIMUM_DURATION_PRESENCE_DETECTION } from "~/constants";

export const canAccessBoilerroom = (boilerroomPos: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[]): boolean =>
  !!(boilerroomPos.length !== 0 && boilerroomPos.some(module => module.boilerRooms && module.boilerRooms.length !== 0));

/**
 * Checks if presence was recently detected in a house.
 * @param house - The house object to check.
 * @param date - The current date.
 * @returns A boolean indicating whether presence was recently detected.
 */
export const isPresenceRecentlyDetected = (house: HouseMessage, date: Dayjs) =>
  !!house.lastPresenceDetected &&
  dayjs(date).diff(dayjs(house.lastPresenceDetected), "millisecond") <= HOUSE_MAXIMUM_DURATION_PRESENCE_DETECTION;
