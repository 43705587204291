import { merge } from "lodash";

import type { Permission } from "..";
import { managerBoilerRoomPermissions } from "./managerBoilerRoom";

export const ownerPermissions: Permission = merge<Permission, Permission>(
  {
    gateway: {
      gatewayName: { update: true },
      expireAt: { update: true },
    },
    page: {
      users: true,
    },
    house: {
      delete: true,
      preComfortTemperature: { update: true },
      absenceTemperature: { update: true },
      frostFreeTemperature: { update: true },
      summerModePeriod: { update: true },
      houseName: { update: true },
      longitude: { update: true },
      isRemoteAccess: { update: true }, // same as expireAt in gateway because remote access is linked to gateway,
    },
  },
  managerBoilerRoomPermissions,
);
