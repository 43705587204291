import { ActionFunctionArgs } from "react-router-dom";

import dayjs from "@eisox/dayjs";

import { deleteValveById, editValves } from "~/api/valve";
import { getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { FetchResponse } from "~/helpers/communication/fetchType";
import { API } from "~/types/API";

export const action = async ({ params, request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const updates = JSON.parse(formData.get("json") as string);

  const { valveId, houseId } = params;

  switch (request.method) {
    case API.HTTP_METHOD.PATCH:
      const { mac } = updates;
      const patchResponse = (await editValves(houseId, [
        {
          id: valveId,
          ...(mac && { mac }),
        },
      ])) as FetchResponse<{
        message: { statusCode: number; message: string; houseId: string }[];
      }>;
      return {
        date: dayjs(),
        restError: patchResponse.type,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: patchResponse.result.message,
      };

    case API.HTTP_METHOD.DELETE:
      const deleteResponse = (await deleteValveById(valveId, houseId)) as FetchResponse<string>;
      const deleteStatus = getHttpStatusByFetchStatus(deleteResponse.type) as unknown as API.HTTP_STATUS;
      return {
        date: dayjs(),
        restError: deleteResponse.type,
        url: request.url,
        method: API.HTTP_METHOD.DELETE,
        body: formData.get("json") as string,
        message: [{ status: deleteStatus, message: deleteResponse.result }],
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
