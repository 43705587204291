import * as React from "react";

import { IconProps } from "./types";

export const UserIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M13.99 12.109C13.6639 11.3793 13.1907 10.7165 12.5966 10.1574C12.0045 9.59676 11.303 9.14974 10.5309 8.84111C10.5239 8.83786 10.517 8.83619 10.5101 8.83294C11.5871 8.098 12.2872 6.90089 12.2872 5.55026C12.2872 3.31282 10.3683 1.5 8.00002 1.5C5.63168 1.5 3.7128 3.31282 3.7128 5.55026C3.7128 6.90089 4.41293 8.098 5.48992 8.83453C5.483 8.83786 5.47609 8.83945 5.46918 8.8427C4.69471 9.1514 3.99976 9.59401 3.40336 10.159C2.80991 10.7186 2.33675 11.3813 2.01001 12.1107C1.68903 12.8248 1.51592 13.5904 1.50004 14.3661C1.49958 14.3835 1.50282 14.4009 1.50956 14.4171C1.5163 14.4334 1.52642 14.4481 1.53931 14.4606C1.55219 14.4731 1.5676 14.483 1.5846 14.4898C1.60161 14.4965 1.61988 14.5 1.63834 14.5H2.67557C2.75163 14.5 2.81214 14.4428 2.81387 14.3727C2.84844 13.1118 3.38435 11.9311 4.33168 11.0361C5.31186 10.11 6.61359 9.60052 8.00002 9.60052C9.38642 9.60052 10.6881 10.11 11.6684 11.0361C12.6157 11.9311 13.1516 13.1118 13.1862 14.3727C13.1879 14.4445 13.2484 14.5 13.3245 14.5H14.3617C14.3802 14.5 14.3985 14.4965 14.4154 14.4898C14.4324 14.483 14.4479 14.4731 14.4607 14.4606C14.4736 14.4481 14.4837 14.4334 14.4905 14.4171C14.4972 14.4009 14.5004 14.3835 14.5 14.3661C14.4827 13.5854 14.3116 12.826 13.99 12.109ZM8.00002 8.35931C7.20654 8.35931 6.45973 8.06697 5.8979 7.5362C5.33607 7.00541 5.02662 6.29988 5.02662 5.55026C5.02662 4.80063 5.33607 4.0951 5.8979 3.56433C6.45973 3.03354 7.20654 2.74121 8.00002 2.74121C8.79347 2.74121 9.54032 3.03354 10.1021 3.56433C10.664 4.0951 10.9734 4.80063 10.9734 5.55026C10.9734 6.29988 10.664 7.00541 10.1021 7.5362C9.54032 8.06697 8.79347 8.35931 8.00002 8.35931Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default UserIcon;
