import { cloneDeep, isEmpty } from "lodash";

import type {
  HouseMessage,
  PlanningDayEnum,
  PlanningPlanningTypeEnum,
  Planning as RestPlanning,
} from "@eisox/backend_webapp_api";
import { PostPlanningInnerDayEnum } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import type { ClickablePlanning } from "~/UI/layouts/Week";
import type { RoomsWithProblem } from "~/UI/screens/House";

//TODO Revoir type de planning faire un type pour les plannings HN et un type pour les plannings normales

export type PlanningType = PlanningPlanningTypeEnum | "occupation" | "inoccupation" | "thermalShock";

export interface Planning extends Omit<RestPlanning, "planningType"> {
  planningType?: PlanningType;
}

// refers to the schedule in the backend, so if you change the day in the backend, you have to change the days displayed in the Week component.
export enum DAYS {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export const getTop = (hourStart?: string) => {
  const today = dayjs()
    .hour(parseInt(hourStart?.split(":")[0]!))
    .minute(parseInt(hourStart?.split(":")[1]!));
  return Math.round(((today.hour() * 60 + today.minute()) / (24 * 60)) * 100);
};

export const getBottom = (hourEnd?: string) => {
  const transformedHour = hourEnd === "00:00" ? "23:59" : hourEnd;
  return 100 - getTop(transformedHour);
};

const timeToMinutes = (time: string, end = false): number => {
  const hour = end ? (time === "00:00" ? "23:59" : time) : time;
  const [hours, minutes] = hour.split(":").map(Number);
  return hours * 60 + minutes;
};

const isLessThan3Quarter = (hourEnd: string, hourStart: string): boolean => {
  return timeToMinutes(hourEnd, true) - timeToMinutes(hourStart) < 45;
};

export enum DisplayedHour {
  NONE = "none",
  START = "start",
  END = "end",
  BOTH = "both",
}

export const displayHour = (timeslots: Planning[], index: number): DisplayedHour => {
  if (timeslots[index].userDefined) {
    if (timeslots[index].hourStart === "00:00" && timeslots[index].hourEnd === "00:00") return DisplayedHour.NONE;
    if (timeslots[index].hourStart === "00:00") return DisplayedHour.END;
    if (timeslots[index].hourEnd === "00:00") return DisplayedHour.START;
    return DisplayedHour.BOTH;
  }
  let displayedHour: DisplayedHour = DisplayedHour.NONE;
  // Toujours afficher l'heure de fin sauf si heure de fin est minuit ou que < à 45min
  if (
    timeslots[index].hourEnd !== "00:00" &&
    !isLessThan3Quarter(timeslots[index].hourEnd!, timeslots[index].hourStart!)
  )
    displayedHour = DisplayedHour.END;
  // On affiche l'heure de départ si la plage horaire précédente dure au moins 45min et que l'heure de début n'est pas minuit
  if (
    timeslots[index].hourStart !== "00:00" &&
    timeslots[index - 1] &&
    !isLessThan3Quarter(timeslots[index - 1].hourEnd!, timeslots[index - 1].hourStart!)
  ) {
    if (displayedHour === DisplayedHour.END) return DisplayedHour.BOTH;
    displayedHour = DisplayedHour.START;
  }
  return displayedHour;
};

export const mergeTimePeriods = (timePeriods: ClickablePlanning[]): ClickablePlanning[] => {
  const timePeriodCopy = cloneDeep(timePeriods);
  // Trier les périodes par heure de début
  timePeriodCopy.sort((a, b) => {
    return timeToMinutes(a.hourStart!) - timeToMinutes(b.hourStart!);
  });

  const mergedPeriods: ClickablePlanning[] = [];

  timePeriodCopy.forEach((p, index) => {
    if (index === 0) {
      mergedPeriods.push(p);
    } else {
      if (timeToMinutes(p.hourStart!) < timeToMinutes(mergedPeriods[mergedPeriods.length - 1].hourEnd!, true)) {
        //Chevauchement
        if (p.userDefined) {
          if (timeToMinutes(p.hourEnd!, true) < timeToMinutes(mergedPeriods[mergedPeriods.length - 1].hourEnd!, true)) {
            const newTimeslot = { ...mergedPeriods[mergedPeriods.length - 1], hourStart: p.hourEnd };
            if (timeToMinutes(p.hourStart!) > timeToMinutes(mergedPeriods[mergedPeriods.length - 1].hourStart!)) {
              mergedPeriods[mergedPeriods.length - 1].hourEnd = p.hourStart;
            } else {
              mergedPeriods.splice(mergedPeriods.length - 1, 1);
            }
            mergedPeriods.push(p);
            mergedPeriods.push(newTimeslot);
          } else {
            if (mergedPeriods[mergedPeriods.length - 1].hourStart !== p.hourStart) {
              mergedPeriods[mergedPeriods.length - 1].hourEnd = p.hourStart;
              mergedPeriods.push(p);
            } else {
              mergedPeriods.splice(mergedPeriods.length - 1, 1);
              mergedPeriods.push(p);
            }
          }
        } else {
          if (timeToMinutes(p.hourEnd!, true) > timeToMinutes(mergedPeriods[mergedPeriods.length - 1].hourEnd!, true)) {
            mergedPeriods.push({ ...p, hourStart: mergedPeriods[mergedPeriods.length - 1].hourEnd });
          }
        }
      } else {
        mergedPeriods.push(p);
      }
    }
  });

  return mergedPeriods;
};

export const getHour = (hour: string, endHour = false) => {
  return hour === "00:00" && endHour
    ? dayjs().add(1, "day").hour(0).minute(0).second(0).millisecond(0)
    : dayjs()
        .hour(parseInt(hour.split(":")[0]))
        .minute(parseInt(hour.split(":")[1]))
        .second(0)
        .millisecond(0);
};

export const generateAllHours = (): string[] => {
  const hours: string[] = [];

  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m += 15) {
      const hour = `${h.toString().padStart(2, "0")}:${m.toString().padStart(2, "0")}`;
      hours.push(hour);
    }
  }

  return hours;
};

export const getHoursStart = (otherHour?: string, dayStart?: DAYS, dayEnd?: DAYS): string[] => {
  if (dayStart !== dayEnd || !otherHour) return generateAllHours();
  const endHour = otherHour ? dayjs(otherHour, "HH:mm") : undefined;
  return generateAllHours().filter(h => {
    if (!endHour) return true;
    const startHour = dayjs(h, "HH:mm");
    return startHour.diff(endHour, "hour") <= -1 || h === otherHour;
  });
};

export const getHoursEnd = (otherHour?: string, dayStart?: DAYS, dayEnd?: DAYS): string[] => {
  if (dayStart !== dayEnd || !otherHour) return generateAllHours();
  const startHour = otherHour ? dayjs(otherHour, "HH:mm") : undefined;
  return generateAllHours().filter(h => {
    if (!startHour) return true;
    const endHour = dayjs(h, "HH:mm");
    return endHour.diff(startHour, "hour") >= 1 || h === otherHour;
  });
};

export const getBody = (plannings: Planning[], data: Planning, roomId: string): Planning[] => {
  const userDefinedPlannings = plannings.filter(p => p.userDefined && p.day === data.day);

  if (!isEmpty(userDefinedPlannings)) {
    const timePeriodCopy = cloneDeep(userDefinedPlannings);
    if (data.id) {
      const updatedPlanningIndex = timePeriodCopy.findIndex(p => p.id === data.id);
      timePeriodCopy[updatedPlanningIndex] = {
        ...timePeriodCopy[updatedPlanningIndex],
        hourStart: data.hourStart,
        hourEnd: data.hourEnd,
        planningType: data.planningType,
        day: data.day,
      };
    } else {
      timePeriodCopy.push({ ...data, userDefined: true });
    }
    timePeriodCopy.sort((a, b) => {
      return timeToMinutes(a.hourStart!) - timeToMinutes(b.hourStart!);
    });
    const mergedPeriods: Planning[] = [];
    timePeriodCopy.forEach((t, i) => {
      if (i === 0) {
        mergedPeriods.push(t);
      } else {
        const previousT = mergedPeriods[mergedPeriods.length - 1];
        if (timeToMinutes(t.hourStart!) < timeToMinutes(previousT.hourEnd!, true)) {
          // chevauchement
          if (previousT.planningType === t.planningType) {
            if (timeToMinutes(t.hourEnd!, true) > timeToMinutes(previousT.hourEnd!, true)) {
              previousT.hourEnd = t.hourEnd;
            }
          } else {
            if (timeToMinutes(t.hourEnd!, true) >= timeToMinutes(previousT.hourEnd!, true)) {
              previousT.hourEnd = t.hourStart;
              mergedPeriods.push(t);
            } else {
              const newTimeslot = { ...previousT, hourStart: t.hourEnd };
              if (previousT.hourStart !== t.hourStart) {
                previousT.hourEnd = t.hourEnd;
              } else {
                mergedPeriods.splice(mergedPeriods.length - 1);
              }
              mergedPeriods.push(t);
              mergedPeriods.push(newTimeslot);
            }
          }
        } else {
          mergedPeriods.push(t);
        }
      }
    });

    return mergedPeriods.map(t => {
      return {
        day: t.day,
        hourStart: t.hourStart,
        hourEnd: t.hourEnd,
        planningType: t.planningType,
        roomId: roomId,
        userDefined: true,
      };
    });
  }

  return [{ ...data, userDefined: true, roomId: roomId }];
};

export type TempType = "frostFree" | "comfort" | "night" | "absence" | "preComfort";

export const getTemperature = (house: HouseMessage, room: RoomsWithProblem, type: TempType): number => {
  return type === "comfort" || type === "night" ? room[`${type}Temperature`] : house[`${type}Temperature`]!;
};

export const getCurrentTimeSlot = (planningData: Planning[]): Planning => {
  const today = dayjs();
  const currentDay = getCurrentDayForPlanning();
  const currentTime = today.hour() * 60 + today.minute();

  const timeSlot = planningData.filter(
    slot =>
      slot.day?.toLowerCase() === currentDay &&
      slot.hourStart &&
      slot.hourEnd &&
      currentTime >= timeToMinutes(slot.hourStart) &&
      currentTime < timeToMinutes(slot.hourEnd, true),
  );

  return timeSlot.find(slot => slot.userDefined) || timeSlot[0];
};

export const getCurrentDayForPlanning = (): PlanningDayEnum => {
  return dayjs().locale("en").format("dddd").toLowerCase() as PlanningDayEnum;
};

export interface TimeslotPopupDto {
  otherTimeslots: Planning[];
  current: {
    dayStart: DAYS;
    hourStart: string;
    dayEnd: DAYS;
    hourEnd: string;
  };
}

export const convertTimeslotsToTimeRange = (timeslot: Planning, timeslots: Planning[]): TimeslotPopupDto => {
  const days: Record<DAYS, number> = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  const sortedArray = timeslots.slice().sort((a, b) => {
    if (a.day !== b.day) {
      return days[a.day!] - days[b.day!];
    }
    return compareHours(a.hourStart!, b.hourStart!);
  });

  const index = sortedArray.findIndex(
    item =>
      item.planningType === timeslot.planningType &&
      item.day === timeslot.day &&
      item.hourStart === timeslot.hourStart &&
      item.hourEnd === timeslot.hourEnd,
  );

  let startIndex = index;
  let endIndex = index;

  // Trouver le début de la période continue du même type
  while (startIndex > 0 && sortedArray[startIndex - 1].planningType === timeslot.planningType) {
    if (!areHoursContinuous(sortedArray[startIndex - 1].hourEnd!, sortedArray[startIndex].hourStart!)) {
      break; // Si les heures ne sont pas continues, arrêter
    }
    startIndex--;
  }

  // Trouver la fin de la période continue du même type
  while (endIndex < sortedArray.length - 1 && sortedArray[endIndex + 1].planningType === timeslot.planningType) {
    if (!areHoursContinuous(sortedArray[endIndex].hourEnd!, sortedArray[endIndex + 1].hourStart!)) {
      break; // Si les heures ne sont pas continues, arrêter
    }
    endIndex++;
  }

  const dayStart = sortedArray[startIndex].day as DAYS;
  const hourStart = sortedArray[startIndex].hourStart!;
  const hourEnd = sortedArray[endIndex].hourEnd!;
  let dayEnd = sortedArray[endIndex].day as DAYS;
  if (hourEnd === "00:00") {
    // Minuit ne peut pas être la dernière heure de la journée, c'est forcément la première heure de la journée
    // On initialise le jour de fin avec la valeur du jour d'après
    dayEnd = (Object.keys(days) as (keyof typeof days)[]).find(key => days[key] === days[dayEnd] + 1)!;
  }

  return {
    otherTimeslots: [...sortedArray.slice(0, startIndex), ...sortedArray.slice(endIndex + 1)],
    current: {
      dayStart,
      hourStart,
      dayEnd,
      hourEnd,
    },
  };
};

// Fonction pour comparer les heures au format "hh:mm"
function compareHours(hourA: string, hourB: string): number {
  const [hoursA, minutesA] = hourA.split(":").map(Number);
  const [hoursB, minutesB] = hourB.split(":").map(Number);

  if (hoursA !== hoursB) {
    return hoursA - hoursB;
  }

  return minutesA - minutesB;
}

// Fonction pour vérifier si les heures sont continues d'un jour à l'autre
function areHoursContinuous(endHour: string, startHour: string): boolean {
  const [hoursEnd, minutesEnd] = endHour.split(":").map(Number);
  const [hoursStart, minutesStart] = startHour.split(":").map(Number);

  return hoursEnd === hoursStart && minutesEnd === minutesStart;
}

export const convertTimeRangeToPlanningArray = (
  dayStart: DAYS,
  hourStart: string,
  dayEnd: DAYS,
  hourEnd: string,
  planningType: PlanningType,
): Planning[] => {
  const daysOfWeek = Object.values(PostPlanningInnerDayEnum);
  const startIndex = daysOfWeek.indexOf(dayStart);
  const endIndex = daysOfWeek.indexOf(dayEnd);

  if (startIndex === -1 || endIndex === -1) {
    return [];
  }

  const result: Planning[] = [];
  let currentDayIndex = startIndex;
  let currentHour = hourStart;

  while (
    currentDayIndex !== endIndex ||
    (currentDayIndex === endIndex && getHour(hourEnd).isBefore(getHour(currentHour)))
  ) {
    result.push({
      day: daysOfWeek[currentDayIndex],
      hourStart: currentHour,
      hourEnd: "00:00",
      planningType: planningType,
      userDefined: true,
    });

    currentDayIndex = (currentDayIndex + 1) % 7;
    currentHour = "00:00";
  }

  hourEnd !== "00:00" &&
    result.push({
      day: daysOfWeek[endIndex],
      hourStart: currentHour,
      hourEnd: hourEnd,
      planningType: planningType,
      userDefined: true,
    });

  return result;
};

export const getActualDay = (): DAYS => {
  return Object.values(DAYS)[dayjs().get("day") - 1 === -1 ? 6 : dayjs().get("day") - 1];
};

export const mergeUpdatedPlanningToExistingPlanning = (
  updatedPlanning: Planning[],
  existingPlanning?: Planning[],
): Planning[] => {
  if (existingPlanning && existingPlanning.length > 0) {
    const mergedPlanning: Planning[] = [];
    Object.values(DAYS).forEach(d => {
      const updatedPlanningsOfDay = updatedPlanning.find(up => up.day === d); // 1 or 0
      const existingPlanningsOfDay = existingPlanning.filter(ep => ep.day === d);

      if (updatedPlanningsOfDay && existingPlanningsOfDay) {
        mergedPlanning.push(updatedPlanningsOfDay);
        existingPlanningsOfDay.forEach(ep => {
          if (
            timeToMinutes(ep.hourStart!) < timeToMinutes(updatedPlanningsOfDay.hourEnd!, true) &&
            timeToMinutes(ep.hourEnd!, true) > timeToMinutes(updatedPlanningsOfDay.hourStart!)
          ) {
            //chevauchement
            if (
              timeToMinutes(ep.hourStart!) >= timeToMinutes(updatedPlanningsOfDay.hourStart!) &&
              timeToMinutes(ep.hourEnd!, true) <= timeToMinutes(updatedPlanningsOfDay.hourEnd!, true)
            )
              return;
            if (timeToMinutes(ep.hourEnd!, true) < timeToMinutes(updatedPlanningsOfDay.hourEnd!, true)) {
              if (ep.planningType === updatedPlanningsOfDay.planningType) {
                updatedPlanningsOfDay.hourStart = ep.hourStart;
              } else {
                mergedPlanning.push({ ...ep, hourEnd: updatedPlanningsOfDay.hourStart });
              }
            }
            if (timeToMinutes(ep.hourStart!) > timeToMinutes(updatedPlanningsOfDay.hourStart!)) {
              if (ep.planningType === updatedPlanningsOfDay.planningType) {
                updatedPlanningsOfDay.hourEnd = ep.hourEnd;
              } else {
                mergedPlanning.push({ ...ep, hourStart: updatedPlanningsOfDay.hourEnd });
              }
            }
          } else {
            mergedPlanning.push(ep);
          }
        });
      } else if (updatedPlanningsOfDay) {
        mergedPlanning.push(updatedPlanningsOfDay);
      } else if (existingPlanningsOfDay) {
        mergedPlanning.push(...existingPlanningsOfDay);
      } else {
        return;
      }
    });
    return mergedPlanning;
  }
  return updatedPlanning;
};

export const getTimeslotIndexToRemove = (timeslots: Planning[], otherTimeslots: Planning[]): number[] =>
  timeslots
    .map((timeslot, index) => (otherTimeslots.some(otherTimeslot => otherTimeslot.id === timeslot.id) ? -1 : index))
    .filter(index => index !== -1);
