import type React from "react";
import { useState } from "react";

import { useTranslation } from "react-i18next";

import { primary } from "@eisox/colors";
import dayjs from "@eisox/dayjs";
import { DatePicker, Divider, Modal, SelectV2 as Select, TextField } from "@eisox/design-system";
import { ChevronDownIcon, CrossIcon, HistoryIcon, PointChartIcon } from "@eisox/icons";

import { FieldContainer } from "~/UI/components";
import type { HeatingCurveHistory } from "~/apiV2";

import { HeatingCurveList } from "../HeatingCurveList";
import { HslopeHistoryTable } from "../HslopeHistoryTable";

import styles from "./HistoryDialog.module.scss";

interface HistoryDialogProps {
  houseId: string;
  moduleId: string;
  heatingNetworkId: string;
  heatingNetworkName: string;
  externalTemperature: number;
  currentSetpointTemperautre?: number;
  currentOffsetTemperature?: number;
  tExtOptions: number[];
}

const HistoryDialog: React.FC<HistoryDialogProps> = ({
  houseId,
  moduleId,
  heatingNetworkId,
  heatingNetworkName,
  externalTemperature: externalTemperatureProp,
  currentSetpointTemperautre,
  currentOffsetTemperature,
  tExtOptions: externalTemperatureOptionsProp,
}) => {
  const { t } = useTranslation();

  const [externalTemperature, setExternalTemperature] = useState<string>(externalTemperatureProp.toString());
  const [date, setDate] = useState<Date>(dayjs().startOf("day").subtract(2, "month").toDate());
  const [heatingCurve, setHeatingCurve] = useState<HeatingCurveHistory>();

  const tExtOptions = externalTemperatureOptionsProp.map(v => ({ name: `${v}°C`, value: v.toString() }));

  const handleChangeTExt = (value: string | string[] | undefined) => setExternalTemperature(value as string);
  const handleChangeDate = (date: Date | undefined) => {
    if (date) setDate(date);
    setHeatingCurve(undefined);
  };
  const handelSelectHeatingCurve = (heatingCurve: HeatingCurveHistory) => setHeatingCurve(heatingCurve);

  return (
    <Modal.Root>
      <Modal.Trigger asChild className={styles.trigger}>
        <HistoryIcon color={primary.orange} />
      </Modal.Trigger>
      <Modal.Content className={styles.historyDialog}>
        <Modal.Header
          title={t("network.content.heatingCurve.dialog.heatingCurveHistory.title")}
          subtitle={heatingNetworkName}
          icon={<PointChartIcon />}
        >
          <Modal.Close asChild>
            <CrossIcon />
          </Modal.Close>
        </Modal.Header>
        <div className={styles.historyDialog__inputs}>
          <FieldContainer label={t("network.content.heatingCurve.dialog.heatingCurveHistory.externalTemperature")}>
            <Select options={tExtOptions} value={externalTemperature} onChange={handleChangeTExt} />
          </FieldContainer>
          <FieldContainer label={t("network.content.heatingCurve.dialog.heatingCurveHistory.startDate")}>
            <DatePicker.Root
              mode="single"
              format="L"
              value={date}
              onChange={handleChangeDate}
              disabled={{
                before: dayjs().startOf("day").subtract(2, "month").toDate(),
                after: dayjs().toDate(),
              }}
            >
              <DatePicker.Trigger asChild className={styles.historyDialog__datePicker}>
                <TextField.Root value={dayjs(date).format("L")}>
                  <TextField.Slot side="right">
                    <ChevronDownIcon />
                  </TextField.Slot>
                </TextField.Root>
              </DatePicker.Trigger>
              <DatePicker.Content sideOffset={15} style={{ zIndex: 1400 }}>
                <DatePicker.Calendar />
              </DatePicker.Content>
            </DatePicker.Root>
          </FieldContainer>
        </div>
        <HeatingCurveList
          houseId={houseId}
          moduleId={moduleId}
          heatingNetworkId={heatingNetworkId}
          extTemp={Number.parseInt(externalTemperature)}
          date={date.toISOString()}
          heatingCurve={heatingCurve}
          currentOffsetTemperature={currentOffsetTemperature}
          currentSetpointTemperautre={currentSetpointTemperautre}
          onSelectHeatingCurve={handelSelectHeatingCurve}
        />
        <Divider className={styles.historyDialog__divider} />
        <p className={styles.historyDialog__setpoint}>
          {t("network.content.heatingCurve.dialog.heatingCurveHistory.manualSetpoint")}
          <span>{heatingCurve?.con ?? "-"}°C</span>
        </p>
        <p className={styles.historyDialog__setpoint}>
          {t("network.content.heatingCurve.dialog.heatingCurveHistory.autoSetpoint")}
          <span>{heatingCurve?.con ? Math.round(heatingCurve.con + (heatingCurve.offset ?? 0)) : "-"}°C</span>
        </p>
        <HslopeHistoryTable
          houseId={houseId}
          moduleId={moduleId}
          heatingNetworkId={heatingNetworkId}
          heatingCurveId={heatingCurve?.id}
          externalTemperature={Number.parseInt(externalTemperature)}
        />
      </Modal.Content>
    </Modal.Root>
  );
};

export { HistoryDialog };
export type { HistoryDialogProps };
