import type { HttpResponseResolver } from "msw";
import { HttpResponse } from "msw";

import dayjs from "@eisox/dayjs";
import { PostHeatingCurvesHistoryFields, PostHslopeHistoryFields } from "@eisox/webapp-api-specification";
import { faker } from "@faker-js/faker";

import type {
  HeatingCurveHistory,
  HeatingCurveHistoryPathParams,
  HeatingCurveHistoryRequestBody,
  HeatingCurveHistoryResponseBody,
  HeatingCurveHslopeHistory,
  HeatingCurveHslopeHistoryPathParams,
  HeatingCurveHslopeHistoryRequestBody,
  HeatingCurveHslopeHistoryResponseBody,
} from "~/apiV2";
import { HSLOPE } from "~/constants";
import { getContentRange } from "~/mocks/utils";

const heatingCurveHistoryResolver: HttpResponseResolver<
  HeatingCurveHistoryPathParams,
  HeatingCurveHistoryRequestBody,
  HeatingCurveHistoryResponseBody
> = async ({ request }) => {
  const url = new URL(request.url);

  const pageNumberParam = url.searchParams.get("pageNumber");
  const pageSizeParam = url.searchParams.get("pageSize");

  const pageNumber = pageNumberParam ? parseInt(pageNumberParam) : undefined;
  const pageSize = pageSizeParam ? parseInt(pageSizeParam) : undefined;

  if (!pageNumber || !pageSize) throw new Error("Missing parameters");

  const { date, fields } = await request.json();

  const contentRange = getContentRange(pageNumber, pageSize, 1000);

  const data: HeatingCurveHistory[] = Array.from({ length: pageSize }, () => ({
    id: faker.database.mongodbObjectId(),
    con: fields.includes(PostHeatingCurvesHistoryFields.con) ? faker.number.int({ min: 30, max: 80 }) : undefined,
    ext: fields.includes(PostHeatingCurvesHistoryFields.ext)
      ? faker.helpers.arrayElement([-20, -15, -10, -5, 0, 5, 10, 15, 20])
      : undefined,
    offset: fields.includes(PostHeatingCurvesHistoryFields.createdAt)
      ? faker.number.int({ min: 0, max: 5 })
      : undefined,
    createdAt: fields.includes(PostHeatingCurvesHistoryFields.createdAt)
      ? faker.date.between({ from: dayjs(date).toDate(), to: new Date() }).toISOString()
      : undefined,
  }));

  return HttpResponse.json({ message: data }, { headers: { "Content-Range": contentRange } });
};

const heatingCurveHslopeHistory: HttpResponseResolver<
  HeatingCurveHslopeHistoryPathParams,
  HeatingCurveHslopeHistoryRequestBody,
  HeatingCurveHslopeHistoryResponseBody
> = async ({ request }) => {
  const url = new URL(request.url);

  const pageNumberParam = url.searchParams.get("pageNumber");
  const pageSizeParam = url.searchParams.get("pageSize");

  const pageNumber = pageNumberParam ? parseInt(pageNumberParam) : undefined;
  const pageSize = pageSizeParam ? parseInt(pageSizeParam) : undefined;

  if (!pageNumber || !pageSize) throw new Error("Missing parameters");

  const { fields } = await request.json();

  const contentRange = getContentRange(pageNumber, pageSize, 1000);

  const data: HeatingCurveHslopeHistory[] = Array.from({ length: pageSize }, () => ({
    macValve: fields.includes(PostHslopeHistoryFields.macValve) ? faker.internet.mac() : undefined,
    softwareVersion: fields.includes(PostHslopeHistoryFields.softwareVersion) ? faker.system.semver() : undefined,
    hardwareVersion: fields.includes(PostHslopeHistoryFields.hardwareVersion) ? faker.system.semver() : undefined,
    heatingNetworkOpeningAverage: fields.includes(PostHslopeHistoryFields.heatingNetworkOpeningAverage)
      ? faker.number.float({ min: 0, max: 100 })
      : undefined,
    hslopeMinC: fields.includes(PostHslopeHistoryFields.hslopeMinC)
      ? faker.number.float({ min: HSLOPE.MIN, max: HSLOPE.MAX })
      : undefined,
    maxOpening: fields.includes(PostHslopeHistoryFields.maxOpening)
      ? faker.number.float({ min: 0, max: 100 })
      : undefined,
    realExtTemp: fields.includes(PostHslopeHistoryFields.realExtTemp)
      ? faker.number.float({ min: -20, max: 40 })
      : undefined,
    heatingCurve: fields.includes(PostHslopeHistoryFields.heatingCurve)
      ? {
          con: faker.number.float({ min: 0, max: 100 }),
          ext: faker.number.float({ min: 0, max: 100 }),
          offset: faker.number.float({ min: 0, max: 100 }),
        }
      : undefined,
    heatingNetworkStartingTemperature: fields.includes(PostHslopeHistoryFields.heatingNetworkStartingTemperature)
      ? faker.number.float({ min: 0, max: 100 })
      : undefined,
    createdAt: fields.includes(PostHslopeHistoryFields.createdAt) ? faker.date.past().toISOString() : undefined,
  }));

  return HttpResponse.json({ message: data }, { headers: { "Content-Range": contentRange } });
};

export { heatingCurveHistoryResolver, heatingCurveHslopeHistory };
