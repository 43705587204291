import { useCallback, useEffect, useRef } from "react";

import { useTransformContext } from "react-zoom-pan-pinch";

import type { Point } from "../utils";

interface UseDragItemProps {
  origin: "center" | "bottom center";
  onDragStart?: (e: MouseEvent) => void;
  onDragEnd?: (e: MouseEvent) => void;
  onDragChange?: (point: Point) => void;
  disabled?: boolean;
}

interface UseDragItemType<T> {
  target: React.RefObject<T>;
}

const useDragItem = <T extends HTMLElement>(props: UseDragItemProps): UseDragItemType<T> => {
  const { origin, onDragStart, onDragEnd, onDragChange, disabled } = props;

  const { contentComponent: container, getContext } = useTransformContext();
  const scale = getContext().state.scale;

  const target = useRef<T | null>(null);

  const offset = useRef<Point | null>(null);

  const position = useRef<Point | null>(null);
  const isDragging = useRef<boolean>(false);

  const currentPositionChange = useRef(onDragChange);
  const currentPositionStart = useRef(onDragStart);
  const currentPositionEnd = useRef(onDragEnd);
  const onChangeRefId = useRef<number | undefined>();

  currentPositionChange.current = useCallback(
    (point: Point) => {
      onChangeRefId.current = onDragChange
        ? requestAnimationFrame(() => {
            onDragChange(point);
          })
        : undefined;
    },
    [onDragChange],
  );
  currentPositionStart.current = onDragStart;
  currentPositionEnd.current = onDragEnd;

  const getContainerDimensions = useCallback(() => {
    if (!container) return { width: 0, height: 0 };
    const rect = container.getBoundingClientRect();
    return {
      width: rect.width,
      height: rect.height,
    };
  }, [container]);

  const getPointFromEvent = useCallback(
    (e: MouseEvent): Point => {
      const rect = container?.getBoundingClientRect();
      return {
        x: e.clientX - (typeof rect?.left === "number" ? rect.left : 0),
        y: e.clientY - (typeof rect?.top === "number" ? rect.top : 0),
      };
    },
    [container],
  );

  const cancelCurrentDragging = useCallback(() => {
    position.current = null;
    isDragging.current = false;
    if (typeof onChangeRefId.current === "number") {
      cancelAnimationFrame(onChangeRefId.current);
    }
  }, []);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (position.current && offset.current) {
        const itemWidth = target.current?.offsetWidth ?? 0;
        const itemHeight = target.current?.offsetHeight ?? 0;

        const { width, height } = getContainerDimensions();
        const newLeft = Math.min(
          100,
          Math.max(0, ((position.current.x - offset.current.x * scale + (itemWidth / 2) * scale) * 100) / width),
        );
        const newTop = Math.min(
          100,
          Math.max(
            0,
            ((position.current.y -
              offset.current.y * scale +
              (origin === "center" ? itemHeight / 2 : itemHeight) * scale) *
              100) /
              height,
          ),
        );

        if (!isDragging.current) {
          if (currentPositionStart.current) {
            currentPositionStart.current(e);
          }
          isDragging.current = true;
        }
        requestAnimationFrame(() => {
          if (target.current) {
            target.current.style.position = "absolute";
            target.current.style.left = `${newLeft}%`;
            target.current.style.top = `${newTop}%`;
          }
        });
        currentPositionChange.current?.({ x: newLeft, y: newTop });
      } else {
        cancelCurrentDragging();
      }
    },
    [cancelCurrentDragging, getContainerDimensions, scale, origin],
  );

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      position.current = getPointFromEvent(e);
      handleMouseMove(e);
    },
    [getPointFromEvent, handleMouseMove],
  );

  const onMouseUp = useCallback(
    (e: MouseEvent) => {
      if (e.button === 0) {
        if (position.current) {
          currentPositionEnd.current?.(e);
        }
        cancelCurrentDragging();

        container?.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      }
    },
    [cancelCurrentDragging, container, onMouseMove],
  );

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (e.button === 0) {
        const targetRect = target.current?.getBoundingClientRect();
        offset.current = {
          x: e.clientX - (typeof targetRect?.left === "number" ? targetRect.left : 0),
          y: e.clientY - (typeof targetRect?.top === "number" ? targetRect.top : 0),
        };
        container?.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
      }
    },
    [container, onMouseMove, onMouseUp],
  );

  useEffect(() => {
    if (!disabled) target.current?.addEventListener("mousedown", onMouseDown);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      target.current?.removeEventListener("mousedown", onMouseDown);
      container?.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [onMouseUp, onMouseDown, onMouseMove, container, disabled]);

  return {
    target,
  };
};

export { useDragItem };
export type { UseDragItemProps, UseDragItemType };
