import { forwardRef } from "react";

import { cva, cx } from "class-variance-authority";

import { Slot } from "@radix-ui/react-slot";

import styles from "./Button.module.scss";

const buttonVariants = cva(styles.button);

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ type, asChild, className, ...props }, forwardedRef) => {
  const Comp = asChild ? Slot : "button";
  return <Comp {...props} ref={forwardedRef} type={type ?? "button"} className={cx(buttonVariants({ className }))} />;
});

Button.displayName = "Button";

export default Button;
export { Button };
export type { ButtonProps };
