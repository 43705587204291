import { createContext, useContext, useEffect, useReducer } from "react";

import { isEqual } from "lodash";

import type { BoilerRoom } from "~/socketio/types";

export enum ActionType {
  UPDATE_NAME = "UPDATE_NAME",
  UPDATE_ID_VALVES = "UPDATE_ID_VALVES",
  UPDATE_AMBIENT_SENSOR_IDS = "UPDATE_AMBIENT_SENSOR_IDS",
  INITIAL_STATE_UPDATED = "INITIAL_STATE_UPDATED",
  RESET = "RESET",
}

interface ActionPayload {
  [ActionType.UPDATE_NAME]: {
    networkId: string;
    name: string;
  };
  [ActionType.UPDATE_ID_VALVES]: {
    networkId: string;
    ids: string[];
  };
  [ActionType.UPDATE_AMBIENT_SENSOR_IDS]: {
    networkId: string;
    ids: string[];
  };
  [ActionType.INITIAL_STATE_UPDATED]: {
    data: NetworkUpdate[];
  };
  [ActionType.RESET]: undefined;
}

interface PartialHeatingNetwork {
  id: string;
  name?: string;
  ambientSensorIds?: string[];
  idValves?: string[];
}

export interface NetworkUpdate {
  id: string;
  heatingNetworks?: PartialHeatingNetwork[];
}

type Action = ActionMap<ActionPayload>[keyof ActionMap<ActionPayload>];
export interface State {
  initialState: NetworkUpdate[];
  transformedState: NetworkUpdate[];
}
type Dispatch = (action: Action) => void;

export const NetworkContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const networkReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.UPDATE_NAME:
    case ActionType.UPDATE_ID_VALVES:
    case ActionType.UPDATE_AMBIENT_SENSOR_IDS:
      const transformedState: NetworkUpdate[] = state.transformedState;
      const correspondindBoilerroomId = state.initialState.find(b =>
        b.heatingNetworks?.map(hn => hn.id).includes(action.payload.networkId),
      )?.id;

      if (!state.transformedState.find(b => b.id === correspondindBoilerroomId)) {
        transformedState.push({
          id: correspondindBoilerroomId!,
          heatingNetworks: [{ id: action.payload.networkId }],
        });
      } else if (
        !state.transformedState
          .find(b => b.id === correspondindBoilerroomId)
          ?.heatingNetworks?.find(n => n.id === action.payload.networkId)
      ) {
        transformedState
          .find(b => b.id === correspondindBoilerroomId)
          ?.heatingNetworks?.push({ id: action.payload.networkId });
      }

      transformedState.map(b =>
        b.heatingNetworks?.map(hn => {
          const updatedNetwork: PartialHeatingNetwork = hn;
          if (b.id === correspondindBoilerroomId && hn.id === action.payload.networkId) {
            const initialNetwork = state.initialState
              .find(bi => bi.id === b.id)
              ?.heatingNetworks?.find(hni => hni.id === hn.id);
            if (action.type === ActionType.UPDATE_NAME) {
              if (!updatedNetwork.name || action.payload.name != initialNetwork?.name) {
                updatedNetwork.name = action.payload.name;
              } else {
                delete updatedNetwork.name;
              }
            } else if (action.type === ActionType.UPDATE_ID_VALVES) {
              if (!isEqual(action.payload.ids.sort(), initialNetwork?.idValves?.sort())) {
                updatedNetwork.idValves = action.payload.ids;
              } else {
                delete updatedNetwork.idValves;
              }
            } else if (action.type === ActionType.UPDATE_AMBIENT_SENSOR_IDS) {
              if (!isEqual(action.payload.ids.sort(), initialNetwork?.ambientSensorIds?.sort())) {
                updatedNetwork.ambientSensorIds = action.payload.ids;
              } else {
                delete updatedNetwork.ambientSensorIds;
              }
            }
          }
        }),
      );
      //delete boilerroom/heating network where props are not changed
      transformedState.forEach((b, indexB) => {
        b.heatingNetworks?.forEach((hn, indexHn) => {
          if (!hn.name && !hn.ambientSensorIds && !hn.idValves) {
            b.heatingNetworks?.splice(indexHn, 1);
          }
        });
        if (b.heatingNetworks?.length === 0) {
          transformedState.splice(indexB, 1);
        }
      });

      return { ...state, transformedState };
    case ActionType.INITIAL_STATE_UPDATED:
      return {
        initialState: action.payload.data,
        transformedState: state.transformedState,
      };
    case ActionType.RESET:
      return {
        initialState: state.initialState,
        transformedState: [],
      };
    default:
      return state;
  }
};

const getInitialeState = (boilerrooms: BoilerRoom[] | undefined): State => {
  return {
    initialState: boilerrooms
      ? boilerrooms.map(boilerRoom => ({
          id: boilerRoom.id,
          heatingNetworks: boilerRoom.heatingNetworks?.map(network => ({
            id: network.id,
            name: network.name,
            ambientSensorIds: network.ambientSensorIds,
            idValves: network.idValves,
          })),
        }))
      : [],
    transformedState: [],
  };
};

export const useNetworkReducer = (boilerrooms: BoilerRoom[] | undefined) => {
  const [state, dispatch] = useReducer(networkReducer, boilerrooms, getInitialeState);

  useEffect(() => {
    dispatch({ type: ActionType.INITIAL_STATE_UPDATED, payload: { data: getInitialeState(boilerrooms).initialState } });
  }, [boilerrooms]);

  return { state, dispatch };
};

export const useNetworkContext = () => {
  const context = useContext(NetworkContext);
  if (context === undefined) {
    throw new Error("useNetworkContext must be used within a NetworkProvider");
  }
  return context;
};
