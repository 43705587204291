import { BORDER_ROUTER_STATUS } from "@eisox/gateways";
import { GatewaysMessageGatewaysBrModRf, GatewaysMessageGatewaysStatus } from "@eisox/webapp-api-specification";
import { faker } from "@faker-js/faker";

import type { GatewaysWithProblem } from "~/UI/screens/House";
import type { Gateway } from "~/apiV2";

import { Builder } from "../utils";

export class GatewayBuilder extends Builder<Gateway> {
  constructor() {
    const isWifiEnabled = faker.datatype.boolean();

    super({
      id: faker.database.mongodbObjectId(),
      uid: faker.number.int({ min: 1, max: 1000 }),
      isInternetGateway: faker.datatype.boolean(),
      plan: {
        planId: faker.database.mongodbObjectId(),
        x: faker.number.int({ min: 10, max: 90 }),
        y: faker.number.int({ min: 10, max: 90 }),
      },
      isWifiEnabled: isWifiEnabled,
      gatewayName: faker.lorem.words(),
      meshs: isWifiEnabled ? [{ id: faker.database.mongodbObjectId() }] : [],
      status: GatewaysMessageGatewaysStatus.idle,
      brModRf: GatewaysMessageGatewaysBrModRf.OQPSK_RC_200,
      borderRouterStatus: [BORDER_ROUTER_STATUS.OK],
    });
  }
}

export class GatewayWithProblemBuilder extends Builder<GatewaysWithProblem> {
  constructor() {
    const gateway = new GatewayBuilder().build();
    super({
      ...gateway,
      plan: { ...gateway.plan, planName: faker.lorem.words() },
      meshs: gateway.isInternetGateway ? gateway.meshs.map(mesh => ({ ...mesh, name: faker.lorem.words() })) : [],
      errors: [],
      warnings: [],
    });
  }
}
