import { MinusIcon, PlanIcon, PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./Controls.module.scss";

interface ControlsProps {
  zoomIn: VoidFunction;
  zoomOut: VoidFunction;
  resetTransform: VoidFunction;
}

export const Controls: React.FC<ControlsProps> = ({ zoomIn, zoomOut, resetTransform }) => {
  const bem = useBem(styles);
  const controlsStyle = bem("controls");

  return (
    <div className={controlsStyle()}>
      <button className={controlsStyle("button")} onClick={() => zoomIn()} type="button">
        <PlusIcon />
      </button>
      <button className={controlsStyle("button")} onClick={() => zoomOut()} type="button">
        <MinusIcon />
      </button>
      <button className={controlsStyle("button")} onClick={() => resetTransform()} type="button">
        <PlanIcon />
      </button>
    </div>
  );
};
