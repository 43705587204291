import type { ActionFunctionArgs } from "react-router-dom";

import type { PatchHouse } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { updateHouse } from "~/api/houseV2";
import { getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { API } from "~/types/API";

export const action = async ({ request, params }: ActionFunctionArgs) => {
  const { houseId } = params as { houseId: string };
  const formData = await request.formData();

  const body: PatchHouse = JSON.parse(formData.get("json") as string) as PatchHouse;

  const houseUpdate = await updateHouse(houseId, body);
  const status = getHttpStatusByFetchStatus(houseUpdate.type) as unknown as API.HTTP_STATUS;

  return {
    date: dayjs(),
    restError: houseUpdate.type,
    url: request.url,
    method: API.HTTP_METHOD.PATCH,
    body: formData.get("json") as string,
    message: [{ status: status, message: houseUpdate.result }],
  };
};
