import { useEffect, useRef, useState } from "react";

import { useIntl } from "react-intl";
import { Form, useLoaderData, useRouteLoaderData, useSubmit } from "react-router-dom";

import { Card, Select, Switch, ToggleGroup, Typography } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import { Header } from "~/UI/layouts";
import type { ModuleHistory } from "~/apiV2";
import { DataSelector, extractNamesFromPath } from "~/features/BMSHistory";
import { idLoaderHouse } from "~/routes/utils";

import type { houseLoader } from "..";
import { BarChart } from "./components";
import type { loader } from "./loader";
import { View } from "./types";
import { getOptions } from "./utils";

import styles from "./Consumption.module.scss";

export const Consumption: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const consumptionstyle = bem("consumption");
  const filtersStyle = bem("filters");
  const displayStyle = bem("display");
  const chartStyle = bem("chart");

  const formRef = useRef<HTMLFormElement>(null);

  const { modules } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;
  const { schema, data, defaultView, unitAxesMapping, defaultPaths, defaultComparison } = useLoaderData() as LoaderData<
    typeof loader
  >;
  const submit = useSubmit();

  const moduleIds = modules.map(module => module.id);

  const [compare, setCompare] = useState<boolean>(defaultComparison.length > 1);
  const [paths, setPaths] = useState<string[]>(defaultPaths);
  const [view, setView] = useState<View>(defaultView);
  const [comparison, setComparison] = useState<string[]>(defaultComparison);

  const handleChangeView = (value: string) => {
    setView(value as View);
    setComparison([getOptions(value as View)[0].value]);
  };
  const handleChangeComparisons = (values: string[]) => {
    compare ? setComparison(values) : setComparison(values.slice(-1));
  };

  const options = getOptions(view);

  useEffect(() => {
    if (schema.length === 0) {
      submit(formRef.current);
    }
  }, []);

  useEffect(() => {
    submit(formRef.current);
  }, [view, paths, comparison]);

  return (
    <div className={consumptionstyle()}>
      <Header title={formatMessage({ id: "consumption.title" })} />
      <Card className={consumptionstyle("content")}>
        <Form id="consumption-form" ref={formRef} role="search" className={filtersStyle()}>
          <Tooltip
            content={formatMessage({ id: "consumption.comparison.tooltip" })}
            openingCondition={comparison.length > 1}
          >
            {/*span allow to display tooltip even if DataSelector is disabled */}
            <span>
              <DataSelector
                boilerrooms={schema}
                paths={paths}
                onChange={setPaths}
                disabled={paths.length > 6 || (comparison.length > 1 && paths.length > 0)}
              />
            </span>
          </Tooltip>
          <input type="hidden" name="paths" value={paths.join(",")} />
          <div className={displayStyle()}>
            <ToggleGroup.Root defaultValue={View.HOUR} value={view} onValueChange={handleChangeView}>
              {Object.values(View).map(view => (
                <ToggleGroup.Item key={view} value={view}>
                  {formatMessage({ id: `consumption.view.${view}` })}
                </ToggleGroup.Item>
              ))}
            </ToggleGroup.Root>
            <input type="hidden" name="view" value={view} />
            <Select
              triggerClassName={displayStyle("comparison")}
              multiple
              value={comparison}
              options={options.map(o => ({
                ...o,
                disabled:
                  (!comparison.includes(o.value) && comparison.length > 1) ||
                  (comparison.length === 1 && comparison.includes(o.value)),
              }))}
              unscrollableContent={
                <Tooltip
                  content={formatMessage({ id: "consumption.comparison.tooltip" })}
                  openingCondition={paths.length > 1}
                >
                  <label className={displayStyle("compare")}>
                    {formatMessage({ id: "consumption.comparison.placeholder" })}
                    <Switch
                      checked={compare}
                      onCheckedChange={checked => {
                        setCompare(checked!);
                        setComparison(prevState => (checked ? prevState : prevState.slice(-1)));
                      }}
                      disabled={paths.length > 1}
                    />
                  </label>
                </Tooltip>
              }
              renderValue={(values?: string[]) => {
                const displayValue = compare ? values : values?.slice(-1);
                return (
                  <Typography>
                    {displayValue && displayValue.length > 0
                      ? displayValue.map(v => options.find(o => o.value === v)?.name).join(", ")
                      : formatMessage({ id: "consumption.comparison.placeholder" })}
                  </Typography>
                );
              }}
              onChange={handleChangeComparisons}
              displayRadio={compare}
            />
            <input type="hidden" name="comparison" value={comparison.join(",")} />
            <input type="hidden" name="moduleIds" value={moduleIds.join(",")} />
          </div>
        </Form>
        <div className={chartStyle()}>
          <BarChart
            data={data}
            unitAxesMapping={unitAxesMapping}
            view={view}
            dates={comparison}
            title={
              paths.length > 0 && comparison.length > 1
                ? extractNamesFromPath(schema as ModuleHistory["boilerRooms"], paths[0]).key
                : undefined
            }
          />
        </div>
      </Card>
    </div>
  );
};
