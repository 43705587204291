import { chunk } from "lodash";

import dayjs from "@eisox/dayjs";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import type { Style } from "@react-pdf/types";

import type { InterventionPlanState } from "~/features/InterventionPlanTool";
import { intl } from "~/i18n";

import { darkBlueColor } from "../color";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import type { Column } from "../components/Table";
import { Table } from "../components/Table";
import { orientation, size } from "../constant";
import warning from "../img/warning.png";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 7,
    padding: "10 20",
  },
  titleSection: {
    color: darkBlueColor,
    fontSize: 16,
    textDecoration: "underline",
    fontWeight: 600,
  },
  interventionContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  interventionTable: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
  estimatedDurationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  estimatedDuration: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    color: darkBlueColor,
    fontSize: 16,
    fontWeight: 600,
  },
  warningContainer: {
    border: `1px solid ${darkBlueColor}`,
    borderRadius: 15,
    padding: 7,
    display: "flex",
    flexDirection: "column",
    fontSize: 8,
    fontStyle: "italic",
    width: 400,
  },
  warningAction: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    justifyContent: "space-between",
  },
  warningIcon: { width: 40 },
  warningActionText: { width: 350 },
});

interface InterventionSectionProps<T extends Record<string, any>> {
  titleId: "gateways" | "valves";
  items: T[][][];
  columns: Column<T>[];
  minWidthCols: number[];
  style: Style;
}

const InterventionSection = <T extends Record<string, any>>({
  titleId,
  items,
  columns,
  minWidthCols,
  style,
}: InterventionSectionProps<T>) => (
  <View style={styles.interventionContainer}>
    <Text style={styles.titleSection}>
      {intl.formatMessage({
        id: `interventionPlanTool.pdf.page2.intervention${titleId.charAt(0).toUpperCase() + titleId.slice(1)}`,
      })}{" "}
      : {items.flat().flat().length} {intl.formatMessage({ id: `${titleId}.title` }, { n: items.flat().flat().length })}
    </Text>
    {items.map((rows, index) => (
      <View style={styles.interventionTable} key={index}>
        {rows.map((itemTab, i) => (
          <Table key={i} data={itemTab} columns={columns} minWidthCols={minWidthCols} style={style} />
        ))}
      </View>
    ))}
  </View>
);

const EstimatedDurationContainer: React.FC<{ operatingTime: number }> = ({ operatingTime }) => (
  <View style={styles.estimatedDurationContainer} break>
    <View style={styles.estimatedDuration}>
      <Text style={styles.titleSection}>
        {intl.formatMessage({ id: "interventionPlanTool.pdf.page2.estimatedDuration" })} :
      </Text>
      <Text>{dayjs.duration(operatingTime, "minutes").format("H[h]mm")}</Text>
    </View>
    <View style={styles.warningContainer}>
      <Text>{intl.formatMessage({ id: "interventionPlanTool.pdf.page2.warning.action" })}</Text>
      <View style={styles.warningAction}>
        <Text style={styles.warningActionText}>
          {intl.formatMessage({ id: "interventionPlanTool.pdf.page2.warning.contact" })}
        </Text>
        <Image src={warning} style={styles.warningIcon} />
      </View>
    </View>
  </View>
);

export const Page2: React.FC<InterventionPlanState> = ({ valves, gateways, operatingTime }) => {
  const valvesWithError = valves.filter(v => v.problemInstance.type || v.unclogging);
  const gatewaysWithError = gateways.filter(g => g.problemInstance.type);

  const valvesTab = chunk(chunk(valvesWithError, 6), 5);
  const gatewaysTab = chunk(chunk(gatewaysWithError, 6), 6);

  return (
    <Page size={size} orientation={orientation}>
      <Header title="Brief d’intervention 1/3" />
      <View style={styles.container}>
        {valvesWithError.length > 0 && (
          <InterventionSection
            titleId="valves"
            items={valvesTab}
            columns={[
              { field: "uid", headerName: "UID" },
              { field: "mac", headerName: "MAC" },
            ]}
            minWidthCols={[25, 110]}
            style={{ maxWidth: 150 }}
          />
        )}
        {gatewaysWithError.length > 0 && (
          <InterventionSection
            titleId="gateways"
            items={gatewaysTab}
            columns={[
              { field: "uid", headerName: "UID" },
              { field: "mac", headerName: "MAC" },
            ]}
            minWidthCols={[25, 90]}
            style={{ maxWidth: 130 }}
          />
        )}
        <EstimatedDurationContainer operatingTime={operatingTime} />
      </View>
      <Footer />
    </Page>
  );
};
