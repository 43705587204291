import { ActionFunctionArgs } from "react-router-dom";

import dayjs from "@eisox/dayjs";

import { notifyGateway } from "~/api/gatewayV2";
import { getHttpStatusByFetchStatus } from "~/constants";
import { API } from "~/types/API";

export const action = async ({ params, request }: ActionFunctionArgs) => {
  const { houseId } = params as { houseId: string };

  const formData = await request.formData();
  const body = JSON.parse(formData.get("json") as string);

  const response = await notifyGateway(houseId, body);

  return {
    date: dayjs(),
    restError: response.type,
    url: request.url,
    method: API.HTTP_METHOD.PATCH,
    body: formData.get("json") as string,
    message: [{ status: getHttpStatusByFetchStatus(response.type), message: response.result }],
  };
};
