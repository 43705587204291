import { cva } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import type { BoilerRoom } from "~/socketio/types";

import styles from "./Indicators.module.scss";

const indicatorVariant = cva(styles.indicator, {
  variants: {
    defect: {
      true: styles.indicator_defect,
    },
  },
});

interface IndicatorsProps {
  boilerRoom: BoilerRoom;
}

const Indicators: React.FC<IndicatorsProps> = ({ boilerRoom }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.indicators}>
      {boilerRoom.gazDefect !== undefined && (
        <p className={indicatorVariant({ defect: boilerRoom.gazDefect })}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.gazDefect")}
        </p>
      )}
      {boilerRoom.gazLevel !== undefined && (
        <p className={indicatorVariant()}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.gazLevel", { g: boilerRoom.gazLevel.toFixed(1) })}
        </p>
      )}
      {boilerRoom.gazConso !== undefined && (
        <p className={indicatorVariant({ defect: boilerRoom.gazDefect })}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.gazConso", { g: boilerRoom.gazConso.toFixed(1) })}
        </p>
      )}
      {boilerRoom.waterDefect !== undefined && (
        <p className={indicatorVariant({ defect: boilerRoom.waterDefect })}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.waterDefect")}
        </p>
      )}
      {boilerRoom.waterKeeperDefect !== undefined && (
        <p className={indicatorVariant({ defect: boilerRoom.waterKeeperDefect })}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.waterKeeperDefect")}
        </p>
      )}
      {boilerRoom.sludgeRemover !== undefined && (
        <p className={indicatorVariant({ defect: boilerRoom.sludgeRemover.defect })}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.sludgeRemover")}
        </p>
      )}
      {boilerRoom.elecConso !== undefined && (
        <p className={indicatorVariant()}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.elecConso", { e: boilerRoom.elecConso.toFixed(1) })}
        </p>
      )}
      {boilerRoom.calorieConso !== undefined && (
        <p className={indicatorVariant()}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.calorieConso", { c: boilerRoom.calorieConso.toFixed(1) })}
        </p>
      )}
      {boilerRoom.woodLowLevel !== undefined && (
        <p className={indicatorVariant({ defect: boilerRoom.woodLowLevel })}>
          {t(`boilerRoom.rightColumn.boilerroom.indicator.woodLowLevel.${boilerRoom.woodLowLevel}`)}
        </p>
      )}
      {boilerRoom.woodLevel !== undefined && (
        <p className={indicatorVariant({ defect: boilerRoom.woodLevel < 20 })}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.woodLevel", { w: boilerRoom.woodLevel.toFixed(1) })}
        </p>
      )}
      {boilerRoom.woodConso !== undefined && (
        <p className={indicatorVariant()}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.woodConso", { b: boilerRoom.woodConso.toFixed(1) })}
        </p>
      )}
      {boilerRoom.flowVolume !== undefined && (
        <p className={indicatorVariant()}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.flowVolume", { b: boilerRoom.flowVolume.toFixed(1) })}
        </p>
      )}
    </div>
  );
};

export { Indicators };
export type { IndicatorsProps };
