import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { ActionButton, Dialog } from "@eisox/design-system";
import { ArrowRightIcon, UpdateIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { PostNotifNotificationName, PostNotifTask } from "@eisox/webapp-api-specification";
import { useMutation } from "@tanstack/react-query";

import type { NotifyRequestBody } from "~/apiV2";
import { notify } from "~/apiV2";

import styles from "./RelaunchLearning.module.scss";

interface RelaunchLearningProps {
  open: boolean;
  onClose: VoidFunction;
  mac: string;
  gatewayId: string;
}

export const RelaunchLearning: React.FC<RelaunchLearningProps> = ({ mac, gatewayId, open, onClose }) => {
  const { formatMessage } = useIntl();

  const { houseId } = useParams() as { houseId: string };

  const bem = useBem(styles);
  const relaunchLearningStyle = bem("relaunch-learning");

  const { mutate } = useMutation({
    mutationFn: (body: NotifyRequestBody) => notify(houseId, body),
    onSuccess: () => onClose(),
  });

  const handleSubmit = () => {
    const body: NotifyRequestBody = {
      gatewayId: gatewayId,
      macValve: mac,
      task: PostNotifTask.stroke,
      notificationName: PostNotifNotificationName.TASK,
    };
    mutate(body);
  };

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose()}>
      <Dialog.Content
        title={formatMessage({ id: "drawer.listDrawer.valvesDrawer.relaunchLearning.title" })}
        icon={<UpdateIcon style={{ width: 30, height: 30 }} />}
        className={relaunchLearningStyle()}
      >
        <p>{formatMessage({ id: "drawer.listDrawer.valvesDrawer.relaunchLearning.content" }, { m: mac })}</p>
        <div className={relaunchLearningStyle("actions")}>
          <ActionButton
            variant="cancel"
            text={formatMessage({ id: "drawer.listDrawer.valvesDrawer.relaunchLearning.cancel" })}
            onClick={onClose}
          />
          <ActionButton
            variant="primary"
            text={formatMessage({ id: "drawer.listDrawer.valvesDrawer.relaunchLearning.start" })}
            icon={<ArrowRightIcon />}
            onClick={handleSubmit}
            rounded
          />
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
