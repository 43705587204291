import { PlansMessageInner } from "@eisox/backend_webapp_api";
import { intl } from "~/i18n";
import { RoomFormDto } from "../Rooms";

export const prefixId = "room";
export const keysForPattern: Array<keyof RoomFormDto> = ["plan", "roomType", "uid"];

const formatUid = (uid: number) => uid.toString().padStart(2, "0");

type SpecialFunctions<T> = {
  [K in keyof T]?: (value: T[K]) => string | number;
};
type translateKeys<T> = { [K in keyof T]?: string };
type Object = Omit<RoomFormDto, "plan"> & { plan: { planId: string } };

const replaceCheckPatterns = <T extends Record<string, any>>(
  object: T,
  pattern: string,
  replaceIndex: boolean = true,
  specialFunctions?: SpecialFunctions<T>,
  translateKeys?: translateKeys<T>,
) => {
  const regex = /\((.*?)\)/g;
  const name = pattern.replace(regex, (_, key) => {
    if (key === "index" && !replaceIndex) return key;
    const newKey = translateKeys ? Object.keys(translateKeys).find(k => translateKeys[k] === key) || key : key;
    const value = object[newKey];
    return specialFunctions?.[newKey]?.(value) || value || key;
  });
  return name;
};

export const checkGetName = (
  plans: PlansMessageInner[],
  object: Object,
  replaceIndex: boolean = true,
  patternToCheck?: string,
) => {
  if (!object.pattern) return object.name;
  const customFunction: SpecialFunctions<Object> = {
    plan: plan => plans.find(p => p.id === plan?.planId)?.name || "-",
    roomType: roomType => intl.formatMessage({ id: `plan.actions.add.room.roomForm.pattern.${roomType}` }),
    uid: uid => (uid ? formatUid(uid) : ""),
  };
  const translateKeys: translateKeys<Object> = keysForPattern.reduce(
    (acc, key) => ({
      ...acc,
      [key]: intl.formatMessage({ id: `plan.actions.add.room.roomForm.pattern.key.${String(key)}` }),
    }),
    {},
  );
  return replaceCheckPatterns(object, patternToCheck || object.pattern, replaceIndex, customFunction, translateKeys);
};
