import { useRef } from "react";

export const useScroll = () => {
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const targetRef: { [id: string | number]: HTMLDivElement | null } = {};

  const handleScrollToElement = (targetId: string | number) => {
    const scrollableDiv = scrollableRef.current;
    const targetElement = targetRef[targetId];

    if (!scrollableDiv || !targetElement) return;

    targetElement &&
      scrollableDiv.scrollTo({
        top: targetElement.offsetTop - scrollableDiv.offsetTop,
        behavior: "smooth",
      });
  };

  return { scrollableRef, targetRef, handleScrollToElement };
};
