import { useEffect, useRef, useState } from "react";

import { saveAs } from "file-saver";
import JSZip from "jszip";
import { uniqueId } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import dayjs from "@eisox/dayjs";
import { ActionButton, Button, Modal, Switch } from "@eisox/design-system";
import { ArrowRightIcon, SpannerIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";
import { useToPng } from "@hugocxl/react-to-image";
import { CircularProgress } from "@mui/material";
import { PDFViewer, pdf } from "@react-pdf/renderer";

import type { houseLoader } from "~/UI";
import useClipboard from "~/hooks/useClipboard";
import { idLoaderHouse } from "~/routes/utils";

import { InterventionPlanToolStatus, useInterventionPlanTool } from "../../../../providers";
import { calcDurationIntervention, calcEquipementsIntervention, mergeInterventionToolsItems } from "../../utils";
import type { SchemaType } from "../../utils/schema";
import { schema } from "../../utils/schema";
import { DialogExportContent } from "../DialogExportContent";
import { DialogPlanContent } from "../DialogPlanContent/DialogPlanContent";
import { Pdf } from "../PDF";

import styles from "./Dialog.module.scss";

export const idCopyButton = "copyButton";
export const idToPdfButton = "toPdfButton";

export const Dialog: React.FC<{ open: boolean; onClose: VoidFunction }> = ({ open, onClose }) => {
  const { formatMessage } = useIntl();
  const bem = useBem(styles);
  const { plans, valves, gateways, house } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    ReturnType<typeof houseLoader>
  >;
  const { state, setState, transformState, status, renamePlans, step, setStep } = useInterventionPlanTool();

  const prevStatus = useRef<InterventionPlanToolStatus>(InterventionPlanToolStatus.IDLE);

  const methods = useForm<SchemaType>({
    resolver: yupResolver(schema),
    values: state,
  });

  // CLIPBAORD
  const { copyToClipboard } = useClipboard({
    onError: error => toast.error(formatMessage({ id: "interventionPlanTool.action.copyPlan.onError" }, { e: error })),
    onSuccess: () => {
      toast.success(formatMessage({ id: "interventionPlanTool.action.copyPlan.onSuccess" }));
      setShowLoaderCopyPlan(false);
    },
  });

  // IMAGES
  const [_, convertToClipboard] = useToPng<HTMLDivElement>({
    selector: `#${idCopyButton}`,
    onLoading: () => setShowLoaderCopyPlan(true),
    onSuccess: data => copyToClipboard(data),
    onError: error => toast.error(formatMessage({ id: "interventionPlanTool.action.copyPlan.onError" }, { e: error })),
  });

  const [__, convertToPdf] = useToPng<HTMLDivElement>({
    selector: `#${idToPdfButton}`,
    onLoading: () => setShowLoaderToPdf(true),
    onSuccess: data => {
      addPlans(data, plans[step].name!, plans[step].id!);
      setShowLoaderToPdf(false);
      !isPlanIsAlreadyAdded && setStep(step + 1);
      toast.success(formatMessage({ id: "interventionPlanTool.action.addToPdf.onSuccess" }));
    },
    onError: error => toast.error(formatMessage({ id: "interventionPlanTool.action.addToPdf.onError" }, { e: error })),
  });

  // INIT STATE
  useEffect(() => {
    if (prevStatus.current === InterventionPlanToolStatus.REFRESH && status === InterventionPlanToolStatus.IDLE) {
      prevStatus.current = InterventionPlanToolStatus.IDLE;
      const transformData = transformState(valves, gateways, house.houseName!);
      const mergeItems = mergeInterventionToolsItems(state, transformData);
      const operatingTime = calcDurationIntervention(mergeItems.valves, mergeItems.gateways);
      const equipments = calcEquipementsIntervention(mergeItems.valves, mergeItems.gateways);
      setState(draft => {
        Object.assign(draft, mergeItems);
        draft.operatingTime = operatingTime;
        draft.equipments = equipments;
      });
    }
    prevStatus.current = status;
  }, [status]);

  const dialogStyle = bem("dialog");
  const actionStyle = bem("action");

  const [showPreviewPdf, setShowPreviewPdf] = useState(false);
  const [showLoaderCopyPlan, setShowLoaderCopyPlan] = useState(false);
  const [showLoaderToPdf, setShowLoaderToPdf] = useState(false);

  const isLastStep = step === plans.length;
  const isPlanIsAlreadyAdded = !isLastStep && state.plans.map(p => p.planId).includes(plans[step].id!);

  const addPlans = (base64: string, planName: string, planId: string) => {
    setState(draft => {
      draft.plans.push({
        id: uniqueId(),
        planId,
        name: planName,
        image: base64,
      });
    });
    renamePlans();
  };

  const onSubmit = methods.handleSubmit(async () => {
    const zip = new JSZip();

    // FILENAME
    const formatStart = dayjs(state.planifiedAt.start).format("DD-MM-YYYY");
    const formatEnd = dayjs(state.planifiedAt.end).format("DD-MM-YYYY");
    const dateFormatted = formatStart === formatEnd ? formatStart : `${formatStart}_${formatEnd}`;
    const houseNameFormatted = state.houseName.replace(/\s+/g, "_").toUpperCase();
    const fileName = `INTERVENTION_${houseNameFormatted}_${dateFormatted}${state.version !== "1" ? `_V${state.version}` : ""}`;

    //JSON
    const jsonData = JSON.stringify(state);
    zip.file("data.json", jsonData);

    //PDF
    const pdfBlob = await pdf(<Pdf state={state} />).toBlob();
    zip.file(`${fileName}.pdf`, pdfBlob);

    //PLANS
    state.plans.forEach(plan => {
      const base64Data = plan.image.split(",")[1];
      zip.file(`plans/${plan.name}.png`, base64Data, { base64: true });
    });

    zip.generateAsync({ type: "blob" }).then(content => {
      try {
        saveAs(content, `${fileName}.zip`);
        onClose();
      } catch (e) {
        toast.error(formatMessage({ id: "interventionPlanTool.export.errors.export" }));
      }
    });
  });

  return (
    <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
      <Modal.Content className={dialogStyle()}>
        <FormProvider {...methods}>
          <Modal.Header
            title={formatMessage({ id: "interventionPlanTool.title" })}
            subtitle={formatMessage({ id: "interventionPlanTool.subTitle.level" })}
            icon={<SpannerIcon />}
          >
            <p className={actionStyle("step")}>
              {formatMessage({ id: "interventionPlanTool.action.step" }, { f: step + 1, e: plans.length + 1 })}
              {!isLastStep && ` - ${plans[step].name || "-"}`}
            </p>
            <label className={actionStyle("preview-pdf")}>
              {formatMessage({ id: "interventionPlanTool.action.viewPdf" })}
              <Switch
                checked={showPreviewPdf}
                onCheckedChange={checked => setShowPreviewPdf(checked!)}
                disabled={showLoaderToPdf}
              />
            </label>
            {!isLastStep && (
              <>
                <div className={actionStyle("copy-plan")}>
                  {"clipboard" in navigator && (
                    <Button
                      text={formatMessage({ id: "interventionPlanTool.action.copyPlan.title" })}
                      onClick={() => convertToClipboard()}
                      disabled={showPreviewPdf}
                    />
                  )}
                  {showLoaderCopyPlan && <CircularProgress size={10} />}
                </div>

                <div className={actionStyle("add-to-pdf")}>
                  <Button
                    text={formatMessage({ id: "interventionPlanTool.action.addToPdf.title" })}
                    onClick={() => convertToPdf()}
                    disabled={showPreviewPdf}
                  />
                  <p>({state.plans.length})</p>
                  {showLoaderToPdf && <CircularProgress size={10} />}
                </div>
              </>
            )}
            {step !== 0 && (
              <Button
                className={actionStyle("step")}
                text={formatMessage({ id: "interventionPlanTool.action.previous" })}
                onClick={() => setStep(step - 1)}
                disabled={showPreviewPdf}
              />
            )}
            {!isLastStep && (
              <Button
                className={actionStyle("step")}
                text={formatMessage({ id: "interventionPlanTool.action.skip" })}
                onClick={() => setStep(step + 1)}
                disabled={showPreviewPdf}
              />
            )}
            <ActionButton
              text={formatMessage({ id: `interventionPlanTool.action.${isLastStep ? "export" : "next"}` })}
              icon={<ArrowRightIcon />}
              rounded
              onClick={e => {
                e.preventDefault();
                if (isLastStep) onSubmit();
                else isPlanIsAlreadyAdded ? setStep(step + 1) : convertToPdf();
              }}
            />
          </Modal.Header>

          {showPreviewPdf ? (
            <PDFViewer
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Pdf state={state} />
            </PDFViewer>
          ) : isLastStep ? (
            <DialogExportContent />
          ) : (
            <DialogPlanContent planIndex={step} />
          )}
        </FormProvider>
      </Modal.Content>
    </Modal.Root>
  );
};
