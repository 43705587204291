import dayjs from "@eisox/dayjs";

import type { HousePositionResponseBody as ApiHousePosition } from "~/apiV2";

interface HousePosition {
  id: string;
  houseName: string;
  position: [number, number];
  suspended: boolean;
}

const getHousePositions = (housesPositions: ApiHousePosition[]): HousePosition[] =>
  housesPositions.map(h => ({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    id: h._id!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    houseName: h.houseName!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    position: [parseFloat(h.latitude!), parseFloat(h.longitude!)],
    suspended: !!h.suspendedAt && dayjs(h.suspendedAt).isBefore(),
  }));

export { getHousePositions };
export type { HousePosition };
