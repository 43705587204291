import { ActionFunctionArgs } from "react-router-dom";

import {
  PreinstallGateways207ResponseMessageGatewaysInner,
  PreinstallGatewaysGatewaysInner,
} from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { preinstallGateways } from "~/api/gateway";
import { API } from "~/types/API";

export const action = async ({
  params,
  request,
}: ActionFunctionArgs): Promise<
  API.Response<PreinstallGateways207ResponseMessageGatewaysInner[] | null> | Response
> => {
  const { houseId } = params;
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.PUT:
      const updates: PreinstallGatewaysGatewaysInner = JSON.parse(formData.get("json") as string);

      const putResponse = (await preinstallGateways(houseId, updates)) as any;

      return {
        date: dayjs(),
        restError: putResponse.type,
        url: request.url,
        method: API.HTTP_METHOD.PUT,
        body: formData.get("json") as string,
        message: putResponse.result.message.gateways,
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
