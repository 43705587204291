import * as yup from "yup";

import { PostRoomRoomsInnerRoomTypeEnum } from "@eisox/backend_webapp_api";

import { TEMPERATURE_LIMITS } from "~/constants";
import { intl } from "~/i18n";

export interface withProperties {
  uid?: number;
  id: string;
}

export const schemaErrors = <T extends withProperties>(
  roomsName: string[],
  nextUid: number,
  fields: Record<string, T[] | undefined>,
) =>
  yup.object({
    id: yup.string().required(),
    plan: yup.object({
      planId: yup.string().required(intl.formatMessage({ id: "error.required" })),
      x: yup.number().required(intl.formatMessage({ id: "error.required" })),
      y: yup.number().required(intl.formatMessage({ id: "error.required" })),
    }),
    groupNames: yup.array(yup.string().required(intl.formatMessage({ id: "error.required" }))),
    pattern: yup.string(),
    name: yup
      .string()
      .required(intl.formatMessage({ id: "error.required" }))
      .test("unique-name", intl.formatMessage({ id: "plan.actions.add.room.error.nameUnique" }), (value, context) =>
        context.parent.uid
          ? !roomsName.filter(r => r !== context.parent.name).includes(value)
          : !roomsName.includes(value),
      ),
    uid: yup
      .number()
      .transform(value => (Number.isNaN(value) ? undefined : value))
      .when("pattern", {
        is: (pattern?: string) => pattern && pattern.length > 0 && pattern.includes("(index)"),
        then: schema => schema.required(intl.formatMessage({ id: "error.required" })),
        otherwise: schema => schema.notRequired(),
      })
      .min(nextUid, intl.formatMessage({ id: "plan.actions.preinstall.errors.uid.upperThanMinUid" }, { n: nextUid }))
      .test(
        "max-uid",
        intl.formatMessage({ id: "plan.actions.preinstall.errors.uid.lowerThanMaxUid" }),
        (value, context) =>
          value &&
          context.parent.pattern &&
          fields.lenght &&
          !Object.values(fields)
            .flat()
            .find(f => f?.id === context.parent.id)
            ? value <= Math.max(...(fields[context.parent.pattern]?.map(r => r.uid!) ?? []))
            : true,
      ),
    roomType: yup
      .string()
      .oneOf(Object.values(PostRoomRoomsInnerRoomTypeEnum))
      .required(intl.formatMessage({ id: "error.required" })),
    comfortTemperature: yup
      .number()
      .max(TEMPERATURE_LIMITS.MAX)
      .required(intl.formatMessage({ id: "error.required" }))
      .when("roomType", {
        is: PostRoomRoomsInnerRoomTypeEnum.Room,
        then: schema => schema.min(yup.ref("nightTemperature")),
        otherwise: schema => schema.min(TEMPERATURE_LIMITS.MIN),
      }),
    nightTemperature: yup.number().when("roomType", {
      is: PostRoomRoomsInnerRoomTypeEnum.Room,
      then: schema =>
        schema
          .min(TEMPERATURE_LIMITS.MIN)
          .max(yup.ref("comfortTemperature"))
          .required(intl.formatMessage({ id: "error.required" })),
      otherwise: schema => schema.notRequired(),
    }),
    isSwitchEnabled: yup.boolean().required(intl.formatMessage({ id: "error.required" })),
  });
