import { useState } from "react";

import { RoundIcon } from "@eisox/design-system";
import { CrossIcon, UploadIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./UpdatePlan.module.scss";

interface UpdatePlanProps {
  value: File;
  onChange: (file: File | undefined) => void;
}

export const UpdatePlan: React.FC<UpdatePlanProps> = ({ value, onChange }) => {
  const bem = useBem(styles);
  const updatePlanStyle = bem("update-plan");

  const [hovered, setHovered] = useState(false);

  const handleHover = () => setHovered(prevState => !prevState);

  return (
    <div className={updatePlanStyle()} onMouseEnter={handleHover} onMouseLeave={handleHover}>
      <img className={updatePlanStyle("image")} src={URL.createObjectURL(value)} alt="added plan image" />
      <div className={updatePlanStyle("blanket", { hovered })} />
      <div className={updatePlanStyle("buttons", { hovered })}>
        <label>
          <RoundIcon size={30} backgroundColor="darkGray" iconColor="white">
            <UploadIcon />
          </RoundIcon>
          <input
            type="file"
            hidden
            accept="image/png, image/jpeg"
            multiple={false}
            onChange={e => onChange(e.currentTarget.files?.[0])}
          />
        </label>
        <RoundIcon size={30} backgroundColor="darkGray" iconColor="white" onClick={() => onChange(undefined)}>
          <CrossIcon />
        </RoundIcon>
      </div>
    </div>
  );
};
