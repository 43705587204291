import type { RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import type { BoilerRoom, HeatingNetwork } from "~/socketio/types";

import type { NetworkUpdate } from "../providers";

const getHeatingNetworkToConfigure = (boilerrooms?: BoilerRoom[]): HeatingNetwork[] => {
  if (!boilerrooms) return [];
  return boilerrooms
    .flatMap(b => b.heatingNetworks)
    .filter(hn => hn?.type === "radiator" && (!hn.idValves || !hn.ambientSensorIds)) as HeatingNetwork[];
};

interface ValvesByRoom {
  id: string;
  name: string;
  valvesIds: string[];
}

const createValveObjects = (valves: ValvesWithProblem[], rooms: RoomsWithProblem[]): ValvesByRoom[] => {
  const valveMap: Record<string, string[]> = {};

  for (const valve of valves) {
    const roomId = valve.roomId || "unknown";
    if (!valveMap[roomId]) {
      valveMap[roomId] = [];
    }

    if (valve.id) {
      valveMap[roomId].push(valve.id);
    }
  }

  return Object.keys(valveMap).map(roomId => ({
    id: roomId,
    name: rooms.find(r => r.id === roomId)?.name ?? "--",
    valvesIds: valveMap[roomId],
  }));
};

const isValveDisabled = (
  initialState: NetworkUpdate[],
  updateState: NetworkUpdate[],
  valveId: string,
  currentNetworkId: string,
): boolean => {
  const initialHeatingNetworks = initialState.flatMap(b => b.heatingNetworks);
  const updateHeatingNetworks = updateState.flatMap(b => b.heatingNetworks);
  const actualState = initialHeatingNetworks.map(hn => {
    const correspondingUpdatingHN = updateHeatingNetworks.find(uhn => uhn?.id === hn?.id);
    if (correspondingUpdatingHN) {
      return {
        id: hn?.id,
        name: correspondingUpdatingHN.name ?? hn?.name,
        ambientSensorIds: correspondingUpdatingHN.ambientSensorIds ?? hn?.ambientSensorIds,
        idValves: correspondingUpdatingHN.idValves ?? hn?.idValves,
      };
    } else {
      return hn;
    }
  });

  return actualState.some(hn => hn?.idValves?.includes(valveId) && hn.id !== currentNetworkId);
};

const isRoomDisabled = (
  roomId: string,
  rooms: ValvesByRoom[],
  initialState: NetworkUpdate[],
  updateState: NetworkUpdate[],
  currentNetworkId: string,
): boolean => {
  return (
    rooms
      .find(r => r.id === roomId)
      ?.valvesIds.some(v => isValveDisabled(initialState, updateState, v, currentNetworkId)) ?? true
  );
};

const isRoomSelected = (
  room: ValvesByRoom,
  initialState: NetworkUpdate[],
  updateState: NetworkUpdate[],
  currentNetworkId: string,
) => {
  const initialHeatingNetwork = initialState.flatMap(b => b.heatingNetworks).find(hn => hn?.id === currentNetworkId);
  const updateHeatingNetwork = updateState.flatMap(b => b.heatingNetworks).find(hn => hn?.id === currentNetworkId);
  return updateHeatingNetwork?.idValves
    ? room.valvesIds.every(vId => updateHeatingNetwork.idValves?.includes(vId))
    : room.valvesIds.every(vId => initialHeatingNetwork?.idValves?.includes(vId));
};

const isValveSelected = (
  initialState: NetworkUpdate[],
  updateState: NetworkUpdate[],
  valveId: string,
  currentNetworkId: string,
): boolean | undefined => {
  const initialHeatingNetwork = initialState.flatMap(b => b.heatingNetworks).find(hn => hn?.id === currentNetworkId);
  const updateHeatingNetwork = updateState.flatMap(b => b.heatingNetworks).find(hn => hn?.id === currentNetworkId);
  return updateHeatingNetwork?.idValves
    ? updateHeatingNetwork.idValves.includes(valveId)
    : initialHeatingNetwork?.idValves?.includes(valveId);
};

const filterValvesByUidMacOrRoomName = (valves: ValvesWithProblem[], valvesByRoom: ValvesByRoom[], search: string) => {
  return valves.filter(
    o =>
      o.uid?.toString().includes(search.toLocaleLowerCase()) ||
      o.mac?.toLowerCase().includes(search.toLowerCase()) ||
      (o.id &&
        valvesByRoom
          .filter(v => v.name.toLowerCase().includes(search.toLowerCase()))
          .flatMap(v => v.valvesIds)
          .includes(o.id)),
  );
};

export {
  createValveObjects,
  getHeatingNetworkToConfigure,
  isRoomDisabled,
  isRoomSelected,
  isValveDisabled,
  isValveSelected,
  filterValvesByUidMacOrRoomName,
};
