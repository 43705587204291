import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { DataList, Tooltip } from "@eisox/design-system";
import { calcRodStroke } from "@eisox/valves";

import type { ValvesWithProblem } from "~/UI/screens/House";

import styles from "./ValveTooltip.module.scss";

type ValveTooltipProps = ValvesWithProblem;

const ValveTooltip: React.FC<ValveTooltipProps> = props => {
  const { t } = useTranslation();
  const { mac, updatedAt, gatewayName, gatewayMac, softwareVersion, closing, rssi, lqi, stateData, errors, warnings } =
    props;
  const { batZ, dateBat, batLowDate, openPos, closePos, posUpdatedAt } = stateData ?? {};

  const headerStatus = errors.length > 0 ? "error" : warnings.length > 0 ? "warning" : "connected";

  return (
    <Tooltip.Content side="right" className={styles.valveTooltip}>
      <Tooltip.Arrow />
      <div className={cx(styles.valveTooltip__title, styles[`valveTooltip__title_${headerStatus}`])}>
        {mac ?? "--"} <span>({dayjs(updatedAt).format("LT - L")})</span>
      </div>
      <DataList.Root orientation="vertical">
        <DataList.Item>
          <DataList.Label>{t("advancedMaintenance.valveTooltip.relatedBox")}</DataList.Label>
          <DataList.Value>
            {gatewayName} - {gatewayMac}
          </DataList.Value>
        </DataList.Item>
        <div style={{ display: "flex", gap: 40 }}>
          <DataList.Item>
            <DataList.Label>{t("advancedMaintenance.valveTooltip.version")}</DataList.Label>
            <DataList.Value color="white">{softwareVersion}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label>{t("advancedMaintenance.valveTooltip.opening")}</DataList.Label>
            <DataList.Value color="white">{closing ? `${100 - closing}%` : "--"}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label>{t("advancedMaintenance.valveTooltip.rssi")}</DataList.Label>
            <DataList.Value color="white">{rssi ? `${rssi} dBm` : "--"}</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label>{t("advancedMaintenance.valveTooltip.lqi")}</DataList.Label>
            <DataList.Value color="white">{lqi ?? "--"}</DataList.Value>
          </DataList.Item>
        </div>
        <DataList.Item>
          <DataList.Label>{t("advancedMaintenance.valveTooltip.battery")}</DataList.Label>
          <DataList.Value color="white">
            {batLowDate &&
              t("advancedMaintenance.valveTooltip.batLowDate", {
                d: dayjs(batLowDate).format("L"),
                h: dayjs(batLowDate).format("LT"),
              })}
            {batZ &&
              dateBat &&
              t("advancedMaintenance.valveTooltip.batz", { batz: batZ, d: dayjs(dateBat).format("LT - L") })}
            {!batLowDate && (!batZ || !dateBat) && "--"}
          </DataList.Value>
        </DataList.Item>
        <DataList.Item>
          <DataList.Label>{t("advancedMaintenance.valveTooltip.rowStroke")}</DataList.Label>
          <DataList.Value color="white">
            {openPos && closePos
              ? `${posUpdatedAt ? `${dayjs(posUpdatedAt).format("LT -L")} - ` : ""}${calcRodStroke(openPos, closePos)} mm (Position: ${openPos} - ${closePos} / 1630)`
              : "--"}
          </DataList.Value>
        </DataList.Item>
      </DataList.Root>
    </Tooltip.Content>
  );
};

export { ValveTooltip };
export type { ValveTooltipProps };
