import { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import type { LeafletMouseEvent, Map as MapType, MarkerCluster, Marker as MarkerType } from "leaflet";
import L, { Icon, LatLng } from "leaflet";
import { MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

import { useBem } from "@eisox/tools";

import iconUrl from "~/assets/img/picker.png";

import styles from "./Map.module.scss";

interface LocationMarkerProps {
  name?: string;
  position?: LatLng;
  draggable?: boolean;
  onClick?: VoidFunction;
  onChangePosition?: (latLng: LatLng) => void;
}

const LocationMarker: React.FC<LocationMarkerProps> = ({
  name,
  position = new LatLng(46.71109, 1.7191036),
  draggable = false,
  onClick,
  onChangePosition,
}) => {
  const bem = useBem(styles);
  const tooltipStyles = bem("tooltip");

  const markerRef = useRef<MarkerType>(null);

  const [positionOnMap, setPositionOnMap] = useState<LatLng>(position);

  useEffect(() => {
    setPositionOnMap(position);
  }, [position.lat, position.lng]);

  useEffect(() => {
    onChangePosition && onChangePosition(positionOnMap);
  }, [positionOnMap.lat, positionOnMap.lng]);

  const handleOnMarkerClick = () => onClick && onClick();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const handleDragEnd = () => markerRef.current && setPositionOnMap(markerRef.current?.getLatLng());

  const picker = new Icon({ iconUrl: iconUrl, iconSize: [20, 30], iconAnchor: [10, 30], tooltipAnchor: [0, -25] });

  return (
    <Marker
      ref={markerRef}
      icon={picker}
      position={positionOnMap}
      eventHandlers={{ click: handleOnMarkerClick, dragend: handleDragEnd }}
      draggable={draggable}
      bubblingMouseEvents
    >
      {name && <Tooltip opacity={1} className={tooltipStyles()} content={name} direction="top" />}
    </Marker>
  );
};

export interface MarkerProps {
  position: LatLng;
  name?: string;
  draggable?: boolean;
  onClick?: VoidFunction;
  onChangePosition?: (latLng: LatLng) => void;
}

interface MapProps {
  positions: MarkerProps[];
  positionsForZoom?: MarkerProps[];
  onMapClick?: (latLng: LatLng) => void;
  className?: string;
}

export const Map: React.FC<MapProps> = ({ positions, positionsForZoom, onMapClick, className }) => {
  const bem = useBem(styles);
  const mapContainerStyle = bem("map-container");

  const [map, setMap] = useState<MapType | null>(null);

  useEffect(() => {
    if (positionsForZoom && positionsForZoom.length > 0 && map) {
      map.flyToBounds(
        positionsForZoom.map(p => [p.position.lat, p.position.lng]),
        { duration: 0.8, easeLinearity: 0.3 },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(positionsForZoom), map]);

  useEffect(() => {
    if (positions.length > 0 && map) {
      map.flyToBounds(
        positions.map(p => [p.position.lat, p.position.lng]),
        { duration: 0.8, easeLinearity: 0.3 },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(positions), map]);

  map?.on("click", (e: LeafletMouseEvent) => onMapClick && onMapClick(e.latlng));

  const createClusterCustomIcon = function (cluster: MarkerCluster) {
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: mapContainerStyle("cluster"),
      iconSize: L.point(28, 28, true),
    });
  };

  return (
    <MapContainer
      ref={setMap}
      className={clsx(mapContainerStyle(), className)}
      center={
        positions && positions.length > 0
          ? positions.reduce((acc, c) => ({ lat: acc.lat + c.position.lat, lng: acc.lng + c.position.lng }), {
              lat: 0,
              lng: 0,
            })
          : [46.71109, 1.7191036]
      }
      zoom={13}
      doubleClickZoom={false}
      attributionControl={false}
      boundsOptions={{ padding: [10, 10] }}
    >
      <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />
      {positions && (
        <MarkerClusterGroup
          maxClusterRadius={30}
          spiderfyOnMaxZoom={true}
          showCoverageOnHover={false}
          iconCreateFunction={createClusterCustomIcon}
        >
          {positions.map((p, index) => (
            <LocationMarker
              key={index}
              name={p.name}
              position={p.position}
              draggable={p.draggable}
              onClick={p.onClick}
              onChangePosition={p.onChangePosition}
            />
          ))}
        </MarkerClusterGroup>
      )}
    </MapContainer>
  );
};
