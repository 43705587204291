import React, { forwardRef } from "react";

import clsx from "clsx";

import { useBem } from "@eisox/tools";

import { Typography } from "../Typography";

import styles from "./styles.module.scss";

export interface CommonButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text?: string;
  icon?: string | React.ReactElement;
  iconPosition?: "left" | "right";
}

export interface ButtonWithText extends CommonButtonProps {
  text: string;
  icon?: string | React.ReactElement;
}

export interface ButtonWithIcon extends CommonButtonProps {
  text?: string;
  icon: string | React.ReactElement;
}

export type ButtonProps = ButtonWithText | ButtonWithIcon;

/**
 * @deprecated Use ButtonV2 instead
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ text, icon, className, iconPosition = "right", type = "button", disabled, onClick, ...props }, ref) => {
    const bem = useBem(styles);
    const buttonStyle = bem("button");

    return (
      <button
        ref={ref}
        {...props}
        className={clsx(buttonStyle({ disabled: !!disabled }), className)}
        onClick={e => !disabled && onClick && onClick(e)}
        disabled={disabled}
        type={type}
      >
        {icon &&
          (typeof icon === "string" ? (
            <img className={clsx(buttonStyle("icon", { position: iconPosition }))} src={icon} />
          ) : (
            React.cloneElement(icon, {
              className: clsx(buttonStyle("icon", { position: iconPosition }), icon.props.className),
            })
          ))}
        {text && <Typography keepHoverOpen={false}>{text}</Typography>}
      </button>
    );
  },
);

Button.displayName = "Button";
