import * as React from "react";

import { IconProps } from "./types";

export const WindowIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M2.15 2.52631H13.85C14.2075 2.52631 14.5 2.8342 14.5 3.21052V12.7895C14.5 13.1658 14.2075 13.4737 13.85 13.4737H2.15C1.7925 13.4737 1.5 13.1658 1.5 12.7895V3.21052C1.5 2.8342 1.7925 2.52631 2.15 2.52631ZM2.18421 12.7895H5.60526V3.21052H2.18421V12.7895ZM9.71053 12.7895H6.28947V3.21052H9.71053V12.7895ZM10.3947 12.7895H13.8158V3.21052H10.3947V12.7895Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default WindowIcon;
