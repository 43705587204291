import { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActionButtonV2 as ActionButton, DrawerV2 as Drawer, Slider, TextField } from "@eisox/design-system";
import { PencilIcon } from "@eisox/icons";
import { zodResolver } from "@hookform/resolvers/zod";

import { FieldContainer } from "~/UI";
import { HN_DEFAULT_TEMPERATURE, TEMPERATURE_LIMITS } from "~/constants/appConstantV2";
import { useBoilerRoomRealTimeProviderContext } from "~/features/BoilerRooms";
import type { HeatingNetwork } from "~/socketio/types";

import type { ParametersSchemaType } from "../../helpers";
import { parametersSchema } from "../../helpers";

import styles from "./Parameters.module.scss";

const NAME = "Parameters";

interface ParametersProps extends Drawer.ContentProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  boilerRoomId: string;
  heatingNetwork: HeatingNetwork;
  ecs: boolean;
}

const Parameters: React.FC<ParametersProps> = ({ open, onOpenChange, boilerRoomId, heatingNetwork, ecs, ...props }) => {
  const { t } = useTranslation();

  const { id, name, reducedTemperatureAbs, setpointThermalShock, setpointTemperatureOnOccupation } = heatingNetwork;

  const { useUpdateBoilerRoom } = useBoilerRoomRealTimeProviderContext(NAME);

  const {
    control,
    formState: { isDirty, dirtyFields, errors },
    handleSubmit,
    register,
    reset,
  } = useForm<ParametersSchemaType>({
    resolver: zodResolver(parametersSchema(ecs)),
    values: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name: name!,
      reducedTemperatureAbs: reducedTemperatureAbs ?? HN_DEFAULT_TEMPERATURE.INNOCUPATION,
      setpointThermalShock,
      setpointTemperatureOnOccupation: setpointTemperatureOnOccupation ?? HN_DEFAULT_TEMPERATURE.OCCUPATION,
    },
  });

  const { mutate } = useUpdateBoilerRoom({
    onSuccess: () => {
      reset();
      onOpenChange(false);
    },
  });

  useEffect(() => {
    if (!open) reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit = (data: ParametersSchemaType) => {
    const updates: HeatingNetwork = { id: id };
    if (dirtyFields.name) updates.name = data.name;
    if (dirtyFields.reducedTemperatureAbs) updates.reducedTemperatureAbs = data.reducedTemperatureAbs;
    if (dirtyFields.setpointThermalShock) updates.setpointThermalShock = data.setpointThermalShock;
    if (dirtyFields.setpointTemperatureOnOccupation)
      updates.setpointTemperatureOnOccupation = data.setpointTemperatureOnOccupation;
    mutate([{ id: boilerRoomId, heatingNetworks: [updates] }]);
  };

  const displaySetpointTemperatureOnOccupationSlider = !heatingNetwork.idValves || heatingNetwork.idValves.length === 0;

  return (
    <Drawer.Content {...props}>
      <Drawer.Header>{t("network.header.parameters.title")}</Drawer.Header>
      <form className={styles.parameters} onSubmit={handleSubmit(onSubmit)}>
        <FieldContainer label={t("network.header.parameters.name.label")} error={errors.name}>
          <TextField.Root {...register("name")} placeholder={t("network.header.parameters.name.placeholder")} />
        </FieldContainer>
        <Drawer.Group>
          <Drawer.Label>{t("network.header.parameters.temperature")}</Drawer.Label>
          {ecs ? (
            <Controller
              control={control}
              name="setpointThermalShock"
              render={({ field: { value, onChange } }) => (
                <Slider
                  label={t("network.header.parameters.setpointThermalShock.label")}
                  min={30}
                  max={100}
                  step={0.5}
                  value={value}
                  onValueChange={onChange}
                />
              )}
            />
          ) : (
            <>
              <Controller
                control={control}
                name="reducedTemperatureAbs"
                render={({ field: { value, onChange } }) => (
                  <Slider
                    label={t("network.header.parameters.reducedTemperatureAbs.label")}
                    min={0}
                    max={80}
                    step={0.5}
                    value={value}
                    onValueChange={onChange}
                  />
                )}
              />
              {displaySetpointTemperatureOnOccupationSlider && (
                <Controller
                  control={control}
                  name="setpointTemperatureOnOccupation"
                  render={({ field: { value, onChange } }) => (
                    <Slider
                      label={t("network.header.parameters.setpointTemperatureOnOccupation.label")}
                      min={TEMPERATURE_LIMITS.MIN}
                      max={TEMPERATURE_LIMITS.MAX}
                      step={TEMPERATURE_LIMITS.STEP}
                      value={value}
                      onValueChange={onChange}
                    />
                  )}
                />
              )}
            </>
          )}
        </Drawer.Group>
        <ActionButton className={styles.parameters__save} type="submit" rounded disabled={!isDirty}>
          {t("network.header.parameters.save")}
          <PencilIcon />
        </ActionButton>
      </form>
    </Drawer.Content>
  );
};

Parameters.displayName = "Parameters";

export { Parameters };
export type { ParametersProps };
