import type { HistovalvesStatsPost200ResponseMessageInner } from "@eisox/backend_webapp_api";
import type { ConfigType, Dayjs } from "@eisox/dayjs";
import { getValveProblem } from "@eisox/problems-handler";
import type { IValve } from "@eisox/valves";
import { VALVE_HARDWARE_VERSION_ENUM, valveVersionHandler } from "@eisox/valves";

import type { ValvesWithProblem } from "~/UI/screens/House";
import type { Gateway, Plan, Room, Valve } from "~/apiV2";

/**
 * The function checks if a given hardware version is higher or equal to another hardware version.
 * @param {string} hardware - The `hardware` parameter is a string that represents the hardware version
 * you want to compare.
 * @param {string} hardwareToCompare - The `hardwareToCompare` parameter is a string that represents
 * the hardware version that you want to compare with.
 * @returns a boolean value.
 */
export const isValveHardwareIsUpper = (hardware?: string, hardwareToCompare?: VALVE_HARDWARE_VERSION_ENUM) => {
  if (!hardware || !hardwareToCompare) return false;
  const values = Object.values(VALVE_HARDWARE_VERSION_ENUM);
  return (
    values.indexOf(valveVersionHandler(hardware) as VALVE_HARDWARE_VERSION_ENUM) >= values.indexOf(hardwareToCompare)
  );
};

/**
 * The function `getValvesByRoomId` filters an array of valves based on a given room ID.
 * @param {string} roomId - A string representing the ID of a room.
 * @param {ValvesMessageValvesInner[]} valves
 * @returns an array of valves that have a matching roomId value to the provided roomId parameter.
 */
export const getValvesByRoomId = <T extends { roomId: string }>(roomId: string, valves: T[]): T[] => {
  return valves.filter(valve => valve.roomId === roomId);
};

interface ValveSensorsValues {
  mac?: string;
  updatedAt?: string;
  temperature?: number;
  humidity?: number;
  indoorAirQuality?: number;
  light?: number;
  closing?: number;
  correctedTemp?: number;
  correction?: number;
}

export const getHistoValvesStats = (
  histoValvesStats?: HistovalvesStatsPost200ResponseMessageInner[],
  startDate?: Dayjs,
): ValveSensorsValues[] => {
  if (histoValvesStats) {
    const data = histoValvesStats.filter(hvs => hvs.key === startDate?.valueOf()).flatMap(hvs => hvs.results?.buckets);
    return data.map(d => ({
      mac: d?.key,
      //@ts-ignore
      temperature: d?.temperature?.value,
      //@ts-ignore
      humidity: d?.humidity?.value,
      //@ts-ignore
      indoorAirQuality: d?.indoorAirQuality?.value,
      //@ts-ignore
      light: d?.light?.value,
      //@ts-ignore
      closing: d?.closing?.value,
      //@ts-ignore
      correctedTemp: d?.correctedTemp?.value,
      //@ts-ignore
      correction: d?.correction?.value,
    }));
  }
  return [];
};

export const valvesToValvesWithProblem = (
  valves: Valve[],
  plans: Plan[],
  gateways: Gateway[],
  rooms: Room[],
  date: ConfigType,
): ValvesWithProblem[] =>
  valves.map(v => {
    const plan = plans.find(p => p.id === v.plan.planId)!;
    const room = rooms.find(r => r.id === v.roomId)!;
    const gateway = gateways.find(g => g.id === v.gatewayId)!;
    const { errors, warnings, problemStatus } = getValveProblem(v as unknown as IValve, gateway, date);
    return {
      ...v,
      plan: { ...v.plan, planName: plan.name },
      roomName: room.name,
      gatewayName: gateway.gatewayName,
      gatewaySoftwareVersion: gateway.softwareVersion,
      gatewayMac: gateway.mac,
      errors,
      warnings,
      problemStatus,
    };
  });
