import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { ActionButton } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { AddPlan } from "~/UI/layouts";
import { routeToHouse } from "~/routes/utils";

import styles from "./StepTwo.module.scss";

interface StepTwoProps {
  houseId: string;
}

export const StepTwo: React.FC<StepTwoProps> = ({ houseId }) => {
  const { formatMessage } = useIntl();
  const bem = useBem(styles);
  const stepTwoStyle = bem("step-two");

  const navigate = useNavigate();

  return (
    <div className={stepTwoStyle()}>
      <AddPlan houseId={houseId} />
      <ActionButton
        rounded
        text={formatMessage({ id: "houses.house.finish" })}
        icon={<ArrowRightIcon />}
        onClick={() => navigate(routeToHouse(houseId))}
      />
    </div>
  );
};
