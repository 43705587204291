import { useBem } from "@eisox/tools";

import styles from "./Tank.module.scss";

import clsx from "clsx";
import { useIntl } from "react-intl";

interface TankProps {
  tankSanitaryHotWaterTemperature?: number;
  className?: string;
}

export const Tank: React.FC<TankProps> = ({ tankSanitaryHotWaterTemperature, className }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const tankStyle = bem("tank");

  return (
    <div className={clsx(tankStyle(), className)}>
      <p className={tankStyle("title")}>{formatMessage({ id: "network.content.globalView.scheme.tank" })}</p>
      {tankSanitaryHotWaterTemperature && (
        <p className={tankStyle("sanitary-hot-water-temperature")}>{`${tankSanitaryHotWaterTemperature.toFixed(
          1,
        )}°C`}</p>
      )}
    </div>
  );
};
