import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";

import { Alert, ButtonV2 as Button, Modal as Dialog, RoundIcon, Typography } from "@eisox/design-system";

import { GatewayProblemType, ValveProblemType } from "~/features/InterventionPlanTool";
import { upperCaseToCamelCase } from "~/utils/stringUtils";

import { Gateway } from "../Gateway";
import { Valve } from "../Valve";

import styles from "./LegendDialog.module.scss";

const LegendDialog: React.FC = () => {
  const { t } = useTranslation();

  const valvesProblems = [
    ValveProblemType.TO_INSTALL,
    ValveProblemType.VERIFY_GATEWAY,
    ValveProblemType.REVIEW_VALVE,
    ValveProblemType.CHANGE_BATTERY,
    ValveProblemType.REPLACE_VALVE,
    ValveProblemType.UNCLOG_CHECK_RADIO_TRANSMISSION,
    ValveProblemType.RESTART_VALVE,
    ValveProblemType.UNCLOG_CHANGE_BODY,
  ] as const;

  const gatewaysProblems = [GatewayProblemType.VERIFY_GATEWAY, GatewayProblemType.REPLACE_GATEWAY] as const;

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <Button>{t("maintenance.legend.button")}</Button>
      </Dialog.Trigger>
      <Dialog.Content className={cx(styles.legendDialog)}>
        <div className={styles.legend}>
          {valvesProblems.map(problem => (
            <div key={problem} className={styles.legend__item}>
              <Valve
                key={`valve-${problem}`}
                resolution={problem}
                unclogging={problem === ValveProblemType.UNCLOG_CHANGE_BODY}
              />
              {t(`maintenance.problems.valve.${upperCaseToCamelCase(problem)}`)}
            </div>
          ))}
          {gatewaysProblems.map(problem => (
            <div key={problem} className={styles.legend__item}>
              <Gateway key={`gateway-${problem}`} resolution={problem} />
              {t(`maintenance.problems.gateway.${upperCaseToCamelCase(problem)}`)}
            </div>
          ))}
        </div>
        <Alert severity="warning">{t("maintenance.legend.warning")}</Alert>
        <Dialog.Close asChild>
          <Button className={styles.legendDialog__close}>Fermer</Button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  );
};

LegendDialog.displayName = "Maintenance.LegendDialog";

export { LegendDialog };
