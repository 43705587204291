import { ActionFunctionArgs } from "react-router-dom";

import dayjs from "@eisox/dayjs";

import { updateHouse } from "~/api/houseV2";
import { getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { API } from "~/types/API";

export const action = async ({ request, params }: ActionFunctionArgs) => {
  const { houseId } = params as { houseId: string };

  const formData = await request.formData();
  const updates = JSON.parse(formData.get("json") as string);

  const response = await updateHouse(houseId, updates);

  return {
    date: dayjs(),
    restError: response.type,
    url: request.url,
    method: API.HTTP_METHOD.PATCH,
    body: formData.get("json") as string,
    message: [{ status: getHttpStatusByFetchStatus(response.type), message: response.result }],
  };
};
