import { createQueryKeys } from "@lukemorales/query-key-factory";

import { env } from "~/configuration";

import type { News } from "../types";

const getEisoxBlogNews = async (): Promise<News[]> => {
  const url = new URL(env.NEWS_API_URL);
  url.searchParams.append("orderedBy", "date");
  url.searchParams.append("_fields", "id,date,excerpt,title,link");
  url.searchParams.append("per_page", "3");
  url.searchParams.append("tags", env.NEWS_TAG);

  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const response = await fetch(url, { headers });

  if (!response.ok) throw new Error("Unable to request the latest eisox blog news");

  const data = (await response.json()) as News[];

  return data;
};

const getDocusaurusNews = async (): Promise<News[]> => {
  const url = "/resources_news.json";

  const headers = new Headers();
  headers.append("Cache-Control", "no-store");

  const response = await fetch(url, { headers });

  if (!response.ok) throw new Error("Unable to request the latest docusaurus news");

  const data = (await response.json()) as News[];

  return data;
};

const mergeNews = (news: News[]): News[] =>
  news.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()).slice(0, 3);

const getNews = async (): Promise<News[]> => {
  const [eisoxBlogNews, docusaurusNews] = await Promise.all([getEisoxBlogNews(), getDocusaurusNews()]);

  const news = mergeNews([...eisoxBlogNews, ...docusaurusNews]);

  return news;
};

export const news = createQueryKeys("news", {
  all: {
    queryKey: null,
    queryFn: getNews,
  },
});
