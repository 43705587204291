import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";

import { blueColor, darkerGrayColor, whiteColor } from "../color";

export type Column<T> = {
  [K in keyof T]: {
    field: K;
    headerName?: string;
  };
}[keyof T];

interface TableProps<T> {
  data: T[];
  columns: Column<T>[];
  minWidthCols: number[];
  style?: Style;
}

const styles = StyleSheet.create({
  table: {
    border: `1 solid ${darkerGrayColor}`,
    borderRadius: 10,
    fontSize: 10,
  },
  header: {
    borderRadius: 7,
    color: whiteColor,
    backgroundColor: blueColor,
    display: "flex",
    flexDirection: "row",
    gap: 7,
    padding: 7,
    width: "100%",
    fontWeight: 600,
  },
  headerTitle: {
    alignSelf: "center",
  },
  headerSeperator: {
    height: "100%",
    backgroundColor: whiteColor,
    width: 4,
    marginLeft: "auto",
  },
  rows: {
    padding: "0 7",
    paddingBottom: 7,
    paddingTop: 2,
    display: "flex",
    flexDirection: "column",
    gap: 2,
    color: blueColor,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 7,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  rowSeperator: {
    height: "100%",
    backgroundColor: blueColor,
    width: 4,
    marginLeft: "auto",
  },
});

export const Table = <T extends Record<string, any>>({ data, columns, style, minWidthCols }: TableProps<T>) => {
  const tableStyle: Style[] = [styles.table];
  if (style) tableStyle.push(style);

  return (
    <View style={tableStyle} break>
      <View style={styles.header}>
        {columns.map(({ field, headerName }, i) => (
          <View
            key={i}
            style={[
              styles.row,
              {
                minWidth: minWidthCols[i],
              },
            ]}
          >
            <Text style={styles.headerTitle}>{headerName}</Text>
            {i !== columns.length - 1 && <View style={styles.headerSeperator} />}
          </View>
        ))}
      </View>
      <View style={styles.rows}>
        {data.map((row, i) => (
          <View key={i} style={styles.rowContainer}>
            {columns.map(({ field }, j) => (
              <View
                key={j}
                style={[
                  styles.row,
                  {
                    minWidth: minWidthCols[j],
                  },
                ]}
              >
                <Text>{row[field]}</Text>
                {j !== columns.length - 1 && <View style={styles.rowSeperator} />}
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
};
