import { useIntl } from "react-intl";

import type { Option, SelectOrigin } from "@eisox/design-system";
import { Select, Typography } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import type { RoomsWithProblem } from "~/UI/screens/House";

import { useFilterRooms } from "../../hooks";
import { SelectFilters } from "../../layouts";

import styles from "./RoomsSelect.module.scss";

interface CommonRoomsSelectProps {
  label?: string;
  rooms: RoomsWithProblem[];
  handleRoomHovered: (roomId: string | undefined) => void;
  anchorOrigin?: SelectOrigin;
  transformOrigin?: SelectOrigin;
  disabled?: boolean;
}

type OnChangeType<T> = T extends true ? (roomsIds: string[]) => void : (roomsIds: string) => void;

interface SimpleRoomsSelectProps extends CommonRoomsSelectProps {
  multiple: false;
  value: string;
  onChange: OnChangeType<false>;
}

interface MultipleRoomsSelectProps extends CommonRoomsSelectProps {
  multiple: true;
  value: string[];
  onChange: OnChangeType<true>;
}

type RoomsSelectProps = SimpleRoomsSelectProps | MultipleRoomsSelectProps;

export const RoomsSelect: React.FC<RoomsSelectProps> = ({
  label,
  value,
  multiple = false,
  rooms,
  handleRoomHovered,
  onChange,
  anchorOrigin,
  transformOrigin,
  disabled = false,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const roomsSelectStyle = bem("rooms-select");

  const { filters, roomOptions, handleSearch, handleChangeSelector } = useFilterRooms({ rooms });

  return (
    // @ts-ignore
    <Select
      cardClassName={roomsSelectStyle("card")}
      label={label}
      multiple={multiple}
      anchorOrigin={anchorOrigin}
      shouldNotDisableIfNoOptions
      transformOrigin={transformOrigin}
      displaySelectAllButton
      value={value}
      options={roomOptions.map(r => ({ value: r.id!, name: r.name ?? "-" }))}
      renderOption={(option: Option) => (
        <Typography
          className={roomsSelectStyle("option")}
          onMouseEnter={() => handleRoomHovered(option.value)}
          onMouseLeave={() => handleRoomHovered(undefined)}
        >
          {option.name}
          <span>{rooms.find(r => r.id === option.value)?.plan?.planName}</span>
        </Typography>
      )}
      renderValue={(values?: string | string[]) => {
        const roomNames = rooms.filter(r => values?.includes(r.id!)).map(r => r.name ?? "-");
        return (
          <Tooltip
            position="top"
            content={
              values &&
              values.length > 0 && (
                <div className={roomsSelectStyle("tooltip")}>
                  {roomNames.map((v, i) => (
                    <p key={i}>{v}</p>
                  ))}
                </div>
              )
            }
          >
            <p>
              {values && values.length > 0
                ? roomNames.join(", ")
                : formatMessage({ id: "settings.content.menu.rooms.groups.select.placeholder" })}
            </p>
          </Tooltip>
        );
      }}
      onChange={onChange}
      unscrollableContent={
        <SelectFilters
          groups={[]}
          state={filters}
          handleSearch={handleSearch}
          handleChangeSelector={handleChangeSelector}
        />
      }
      disabled={disabled}
    />
  );
};
