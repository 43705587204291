import * as yup from "yup";

import { validateGatewayMac } from "@eisox/gateways";

import { intl } from "~/i18n";

export const macSchema = (existingSerialNumber: string[], macRequired = false) =>
  yup.object({
    mac: yup.string().when([], (_, schema) =>
      macRequired
        ? schema
            .test("macFormat", intl.formatMessage({ id: "plan.actions.add.gateways.serialNumber.matches" }), value =>
              validateGatewayMac(value ?? ""),
            )
            .test(
              "isUnique",
              intl.formatMessage({ id: "plan.actions.add.gateways.serialNumber.isUnique" }),
              value => !!value && !existingSerialNumber.includes(value),
            )
            .required(intl.formatMessage({ id: "error.required" }))
        : schema,
    ),
  });
