/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StatusCodes } from "http-status-codes";

import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";
import type { Plan } from "../types";

const getPlanUrl = async (houseId: string, planId: string) => {
  const { data, error } = await client.GET("/houses/{houseId}/plans/{planId}", {
    params: { path: { houseId, planId } },
  });
  if (error || !data?.message) throw new Error(error?.message);
  return data.message;
};

const getPlan = async (houseId: string, planId: string) => {
  try {
    const planUrl = await getPlanUrl(houseId, planId);
    const response = await fetch(planUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch plan: ${response.statusText}`);
    }
    const blob = await response.blob();
    return new File([blob], planId, { type: blob.type });
  } catch (error) {
    throw new Error(`Failed to fetch plan: ${(error as Error).message}`);
  }
};

const getPlans = async (houseId: string): Promise<Plan[]> => {
  const { data, error, response } = await client.GET("/houses/{houseId}/plans", {
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) return [];
  if (error || !data?.message) throw new Error(error?.message ?? "Error fetching plans");

  return data.message.map(p => ({
    ...p,
    id: p.id!,
    name: p.name!,
  }));
};

export const plan = createQueryKeys("plan", {
  url: (houseId: string, planId: string) => ({
    queryKey: [houseId, planId],
    queryFn: () => getPlanUrl(houseId, planId),
  }),
  plan: (houseId: string, planId: string) => ({
    queryKey: [houseId, planId],
    queryFn: () => getPlan(houseId, planId),
  }),
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getPlans(houseId),
  }),
});
