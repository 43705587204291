import { useTranslation } from "react-i18next";
import type { DotProps } from "recharts";
import {
  CartesianGrid,
  ComposedChart,
  Dot,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  XAxis,
  YAxis,
} from "recharts";

import { primary } from "@eisox/colors";

import styles from "./Chart.module.scss";

interface ChartProps {
  setpointTemperatureEcs?: number;
  externalTemperature?: number;
}

const Chart: React.FC<ChartProps> = ({ setpointTemperatureEcs, externalTemperature }) => {
  const { t } = useTranslation();
  return (
    <ResponsiveContainer className={styles.chart}>
      <ComposedChart margin={{ top: 10, right: 20, bottom: 40, left: 33 }}>
        <CartesianGrid vertical={false} horizontal stroke={primary.gray} />
        <XAxis
          dataKey="ext"
          type="number"
          unit="°C"
          tickMargin={16}
          axisLine={false}
          tickLine={false}
          padding={{ left: 10, right: 10 }}
          domain={["dataMin", "dataMax"]}
          style={{ fontWeight: 400, fill: primary.darkBlue }}
        >
          <Label
            style={{ fontStyle: "italic", fontWeight: 400, fill: primary.darkBlue }}
            value={t("network.content.heatingCurve.dialog.heatingCurve.externalTemperature")}
            position="bottom"
            dy={10}
          />
        </XAxis>
        <YAxis
          dataKey="con"
          type="number"
          unit="°C"
          axisLine={false}
          tickLine={false}
          tickMargin={9}
          domain={setpointTemperatureEcs ? [setpointTemperatureEcs - 30, setpointTemperatureEcs + 30] : [0, "auto"]}
          style={{ fontWeight: 400, fill: primary.darkBlue }}
        >
          <Label
            style={{ fontStyle: "italic", fontWeight: 400, fill: primary.darkBlue }}
            value={t("network.content.heatingCurve.dialog.heatingCurve.setpoint")}
            position={"left"}
            dx={-10}
            angle={-90}
          />
        </YAxis>
        <ReferenceLine y={setpointTemperatureEcs} stroke={primary.darkerGray} strokeWidth={2} />
        <Scatter
          dataKey="con"
          data={[{ con: setpointTemperatureEcs, ext: externalTemperature }]}
          shape={({ cx, cy }: DotProps) => <Dot cx={cx} cy={cy} fill={primary.darkBlue} r={8} />}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export { Chart };
export type { ChartProps };
