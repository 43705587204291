import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import { Card } from "../../../components";
import { Chart } from "../Chart";
import { ECSSetpointDialog } from "../ECSSetpointDialog.tsx";

import styles from "./ECSSetpointCard.module.scss";

interface ECSSetpointCardProps {
  boilerRoomId: string;
  heatingNetworkId: string;
  setpointTemperatureEcs?: number;
  externalTemperature?: number;
  className?: string;
}

const ECSSetpointCard: React.FC<ECSSetpointCardProps> = ({
  boilerRoomId,
  heatingNetworkId,
  setpointTemperatureEcs,
  externalTemperature,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Card.Root className={cx(styles.ecsSetpointCard, className)}>
      <Card.Header title={t("network.content.setpointECS.title")}>
        <ECSSetpointDialog
          boilerRoomId={boilerRoomId}
          heatingNetworkId={heatingNetworkId}
          setpointTemperatureEcs={setpointTemperatureEcs}
        />
      </Card.Header>
      <Chart setpointTemperatureEcs={setpointTemperatureEcs} externalTemperature={externalTemperature} />
    </Card.Root>
  );
};

export { ECSSetpointCard };
export type { ECSSetpointCardProps };
