import { client } from "../api";
import type { UpdateValveRequestBody } from "../types";

const deleteValve = async (houseId: string, valveId: string) => {
  await client.DELETE("/houses/{houseId}/valves/{valveId}", {
    params: { path: { houseId, valveId } },
  });
};

const updateValves = async (houseId: string, body: UpdateValveRequestBody) => {
  const { data } = await client.PATCH("/houses/{idHouse}/valves", { params: { path: { idHouse: houseId } }, body });
  return data?.message;
};

export { deleteValve, updateValves };
