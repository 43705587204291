import * as React from "react";

import { IconProps } from "./types";

export const HeatIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M4.75001 1.5C4.75001 1.5 1.5 2.71875 1.5 4.75C1.5 6.78125 1.90627 6.78125 3.12501 7.99999C4.34375 9.21874 4.75001 9.28694 4.75 11.25C4.74999 13.213 1.50001 14.5 1.50001 14.5"
        stroke={color}
        strokeWidth="2"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M9.62501 1.5C9.62501 1.5 6.375 2.71875 6.375 4.75C6.375 6.78125 6.78127 6.78125 8.00001 7.99999C9.21875 9.21874 9.62501 9.28694 9.625 11.25C9.62499 13.213 6.37501 14.5 6.37501 14.5"
        stroke={color}
        strokeWidth="2"
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.5 1.5C14.5 1.5 11.25 2.71875 11.25 4.75C11.25 6.78125 11.6563 6.78125 12.875 7.99999C14.0937 9.21874 14.5 9.28694 14.5 11.25C14.5 13.213 11.25 14.5 11.25 14.5"
        stroke={color}
        strokeWidth="2"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default HeatIcon;
