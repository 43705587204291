interface Valve {
  id: string;
  uid: number;
  mac?: string;
  gateway: string;
  room: string;
  plan: {
    id: string;
    x: number;
    y: number;
  };
  hslope?: {
    value: number;
    date?: string;
  };
  maxOpening?: {
    value: number;
    date: string;
  };
  softwareVersion?: string;
  gatewaySoftwareVersion?: string;
}

type GroupedValves = Record<string, Valve[]>;

interface Gateway {
  id: string;
  name: string;
  plan: {
    id: string;
    x: number;
    y: number;
  };
}

interface Room {
  id: string;
  name: string;
  plan: {
    id: string;
    x: number;
    y: number;
  };
}

interface Plan {
  id: string;
  name: string;
}

interface ManualBalancingState {
  groupedBy: GroupField;
  sortBy: SortField;
  sortOrder: SortOrder;
  plan: string;
  data: Data;
  selectedValves: string[];
}

enum Action {
  SORT_FIELD,
  SORT_ORDER,
  GROUP,
  SELECT_ITEMS,
  SELECT_VALVES,
  SET_LEVEL,
  SET_DATA,
}

enum GroupField {
  plan = "plan",
  room = "room",
  noGrouping = "noGrouping",
}

enum SortField {
  hslope = "hslope",
  maxOpening = "maxOpening",
  noSorting = "noSorting",
}

enum SortOrder {
  asc = "asc",
  desc = "desc",
}

enum Item {
  gateways = "gateways",
  rooms = "rooms",
  valves = "valves",
}

enum Data {
  hslope = "hslope",
  maxOpening = "maxOpening",
  mac = "mac",
}

export { Action, Data, GroupField, Item, SortField, SortOrder };
export type { Gateway, GroupedValves, ManualBalancingState, Plan, Room, Valve };
