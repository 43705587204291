import { useEffect, useState } from "react";

import dayjs from "@eisox/dayjs";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import { displayHour, getTop } from "~/utils/planningUtils";

import type { ClickablePlanning } from "../..";
import { Timeslot } from "../Timeslot";

import styles from "./Day.module.scss";

interface DayProps {
  day: string;
  timeslots?: ClickablePlanning[];
  current?: boolean;
}

export const Day: React.FC<DayProps> = ({ day, timeslots, current = false }) => {
  const bem = useBem(styles);
  const dayStyle = bem("day");
  const hourStyle = bem("hour");

  const [currentHour, setCurrentHour] = useState<string>(dayjs().format("HH:mm"));

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHour(dayjs().format("HH:mm"));
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={dayStyle()}>
      <p className={dayStyle("name", { current: current })}>{day.charAt(0)}</p>
      <div className={dayStyle("area")}>
        {timeslots?.map((p, index) => <Timeslot key={index} displayHours={displayHour(timeslots, index)} {...p} />)}
        {current && (
          <Tooltip content={currentHour} position="left">
            {/* calc top - padding - indicator height / 2 */}
            <div style={{ top: `calc(${getTop(currentHour)}% - 0.625rem - 0.125rem / 2)` }} className={hourStyle()}>
              <div className={hourStyle("indicator")} />
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
