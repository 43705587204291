import type { InvalidToken } from "~/apiV2";

import {
  boilerroom,
  boilerrooms,
  consumption,
  dashboard,
  enrollAccount,
  expiredToken,
  frostFreeSettings,
  functionSettings,
  gateway,
  gateways,
  generalSettings,
  groupsSettings,
  gtb,
  gtbSettings,
  histovalvesStats,
  house,
  houses,
  housesSettings,
  maintenance,
  meshs,
  module,
  moduleSettings,
  modulesSettings,
  networks,
  notify,
  plan,
  planning,
  planningSettings,
  plannings,
  plans,
  preinstallGateways,
  preinstallValves,
  recoverAccount,
  remoteAccessSettings,
  resetPassword,
  resources,
  revalidateBoilerroom,
  room,
  roomFrostFreeSettings,
  rooms,
  roomsAction,
  roomsSettings,
  seasonSettings,
  settings,
  signIn,
  temperaturesSettigns,
  updateBoilerroomPos,
  usedToken,
  users,
  valve,
  valves,
  variableBoilerroomId,
  variableHouseId,
  variableModuleId,
  variableNetworkId,
  variablePlanId,
  variablePlanningId,
  variableRoomId,
  variableToken,
  variablesGatewayId,
  variablesValveId,
} from "./routes";

export const idUserInfo = "userInfo";
export const idLoaderHouse = "house";
export const idLinkTokenVerifier = "linkTokenVerifier";

/**
 * The function `replaceSegments` replaces segments in a given path string with corresponding values
 * from a replacements object.
 * @param {string} path - The `path` parameter is a string representing a path with segments. Each
 * segment is denoted by a colon followed by a key. For example, `/users/:userId/posts/:postId` is a
 * path with two segments: `:userId` and `:postId`.
 * @param replacements - The `replacements` parameter is an object that contains key-value pairs. The
 * keys represent segments in the `path` string that need to be replaced, and the values represent the
 * replacements for those segments.
 * @returns The function `replaceSegments` returns a string.
 */
function replaceSegments(path: string, replacements: Record<string, string>): string {
  let updatedPath = path;
  Object.entries(replacements).forEach(([key, value]) => {
    updatedPath = updatedPath.replace(`${key}`, value);
  });
  return updatedPath;
}

export function routeToHouses(): string {
  return houses;
}

export function routeToHouse(houseId: string): string {
  return replaceSegments(house, { [variableHouseId]: houseId });
}

export function routeToDashboard(houseId: string): string {
  return replaceSegments(dashboard, { [variableHouseId]: houseId });
}

export function routeToPlans(houseId: string): string {
  return replaceSegments(plans, { [variableHouseId]: houseId });
}

export function routeToPlan(houseId: string, planId: string): string {
  return replaceSegments(plan, { [variableHouseId]: houseId, [variablePlanId]: planId });
}

export function routeToRooms(houseId: string, planId: string): string {
  return replaceSegments(rooms, { [variableHouseId]: houseId, [variablePlanId]: planId });
}

export function routeToRoom(houseId: string, planId: string, roomId: string): string {
  return replaceSegments(room, { [variableHouseId]: houseId, [variablePlanId]: planId, [variableRoomId]: roomId });
}

export function routeToBoilerrooms(houseId: string): string {
  return replaceSegments(boilerrooms, { [variableHouseId]: houseId });
}

export function routeToRevalidateBoilerrooms(houseId: string): string {
  return replaceSegments(revalidateBoilerroom, { [variableHouseId]: houseId });
}

export function routeToBoilerroom(houseId: string, boilerroomId: string): string {
  return replaceSegments(boilerroom, {
    [variableHouseId]: houseId,
    [variableBoilerroomId]: boilerroomId,
  });
}

export function routeToNetwork(houseId: string, boilerroomId: string, networkId: string): string {
  return replaceSegments(networks, {
    [variableHouseId]: houseId,
    [variableBoilerroomId]: boilerroomId,
    [variableNetworkId]: networkId,
  });
}

export function routeToUsers(houseId: string): string {
  return replaceSegments(users, { [variableHouseId]: houseId });
}

export function routeToMaintenance(houseId: string): string {
  return replaceSegments(maintenance, { [variableHouseId]: houseId });
}

export function routeToRessources(houseId: string): string {
  return replaceSegments(resources, { [variableHouseId]: houseId });
}

export function routeToPlannings(houseId: string) {
  return replaceSegments(plannings, { [variableHouseId]: houseId });
}

export function routeToPlanning(houseId: string, planningId: string): string {
  return replaceSegments(planning, { [variableHouseId]: houseId, [variablePlanningId]: planningId });
}

export function routeToGateway(houseId: string, gatewayId: string): string {
  return replaceSegments(gateway, {
    [variableHouseId]: houseId,
    [variablesGatewayId]: gatewayId,
  });
}

export function routeToGateways(houseId: string): string {
  return replaceSegments(gateways, {
    [variableHouseId]: houseId,
  });
}

export function routeToValves(houseId: string): string {
  return replaceSegments(valves, {
    [variableHouseId]: houseId,
  });
}

export function routeToModule(houseId: string, moduleId: string) {
  return replaceSegments(module, {
    [variableHouseId]: houseId,
    [variableModuleId]: moduleId,
  });
}

export function routeToValve(houseId: string, valveId: string): string {
  return replaceSegments(valve, {
    [variableHouseId]: houseId,
    [variablesValveId]: valveId,
  });
}

export function routeToPreinstallValves(houseId: string): string {
  return replaceSegments(preinstallValves, {
    [variableHouseId]: houseId,
  });
}

export function routeToPreinstallGateways(houseId: string): string {
  return replaceSegments(preinstallGateways, {
    [variableHouseId]: houseId,
  });
}

export function routeToGTB(houseId: string): string {
  return replaceSegments(gtb, { [variableHouseId]: houseId });
}

export function routeToSignIn(): string {
  return signIn;
}

export function routeToHistovalvesStats(): string {
  return histovalvesStats;
}

export function routeToRecoverAccount(): string {
  return recoverAccount;
}

export function routeToEnrollAccount(token: string): string {
  return replaceSegments(enrollAccount, { [variableToken]: token });
}

export function routeToResetPassword(token: string): string {
  return replaceSegments(resetPassword, { [variableToken]: token });
}

export function routeToUsedToken(token: string, type: InvalidToken): string {
  return `${usedToken}?token=${token}&type=${type}`;
}

export function routeToExpiredToken(token: string, type: InvalidToken): string {
  return `${expiredToken}?token=${token}&type=${type}`;
}

export function routeToUpdateBoilerroomPos(houseId: string, moduleId: string, boilerroomId: string): string {
  return replaceSegments(updateBoilerroomPos, {
    [variableHouseId]: houseId,
    [variableModuleId]: moduleId,
    [variableBoilerroomId]: boilerroomId,
  });
}

export function routeToSettings(houseId: string) {
  return replaceSegments(settings, { [variableHouseId]: houseId });
}

export function routeToHousesSettings(houseId: string) {
  return replaceSegments(housesSettings, { [variableHouseId]: houseId });
}

export function routeToGeneralSettings(houseId: string) {
  return replaceSegments(generalSettings, { [variableHouseId]: houseId });
}

export function routeToTemperaturesSettings(houseId: string) {
  return replaceSegments(temperaturesSettigns, { [variableHouseId]: houseId });
}

export function routeToFrostFreeSettings(houseId: string) {
  return replaceSegments(frostFreeSettings, { [variableHouseId]: houseId });
}

export function routeToSeasonSettings(houseId: string) {
  return replaceSegments(seasonSettings, { [variableHouseId]: houseId });
}

export function routeToRemoteAccessSettings(houseId: string) {
  return replaceSegments(remoteAccessSettings, { [variableHouseId]: houseId });
}

export function routeToGTBSettings(houseId: string) {
  return replaceSegments(gtbSettings, { [variableHouseId]: houseId });
}

export function routeToModulesSettings(houseId: string) {
  return replaceSegments(modulesSettings, { [variableHouseId]: houseId });
}

export function routeToModuleSetting(houseId: string, moduleId: string) {
  return replaceSegments(moduleSettings, { [variableHouseId]: houseId, [variableModuleId]: moduleId });
}

export function routeToRoomsSettings(houseId: string) {
  return replaceSegments(roomsSettings, { [variableHouseId]: houseId });
}

export function routeToRoomsFrostFreeSettings(houseId: string) {
  return replaceSegments(roomFrostFreeSettings, { [variableHouseId]: houseId });
}

export function routeToFunctionsSettings(houseId: string) {
  return replaceSegments(functionSettings, { [variableHouseId]: houseId });
}

export function routeToPlanningSettings(houseId: string) {
  return replaceSegments(planningSettings, { [variableHouseId]: houseId });
}

export function routeToGroupsSettings(houseId: string) {
  return replaceSegments(groupsSettings, { [variableHouseId]: houseId });
}

export function routeToRoomsAction(houseId: string) {
  return replaceSegments(roomsAction, { [variableHouseId]: houseId });
}

export function routeToMeshs(houseId: string) {
  return replaceSegments(meshs, { [variableHouseId]: houseId });
}

export function routeToConsumption(houseId: string) {
  return replaceSegments(consumption, { [variableHouseId]: houseId });
}

export function routeToNotify(houseId: string) {
  return replaceSegments(notify, { [variableHouseId]: houseId });
}
