import { useIntl } from "react-intl";

import { useBem } from "@eisox/tools";

import styles from "./ControlState.module.scss";

interface ControlStateProps {
  title: string;
  control: React.ReactNode;
  state: React.ReactNode;
}

export const ControlState: React.FC<ControlStateProps> = ({ title, control, state }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const controlStateStyle = bem("control-state");

  return (
    <div className={controlStateStyle()}>
      <h2 className={controlStateStyle("title")}>{title}</h2>
      <label className={controlStateStyle("label")}>
        <span>{formatMessage({ id: "boilerRoom.dialog.network.control" })}</span>
        {control}
      </label>
      <label className={controlStateStyle("label")}>
        <span>{formatMessage({ id: "boilerRoom.dialog.network.state" })}</span>
        {state}
      </label>
    </div>
  );
};
