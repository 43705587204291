import { useState } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ButtonV2 as Button, Radio, RoundIcon, Tooltip } from "@eisox/design-system";
import { BarChartIcon, GatewayIcon, NutIcon, RoomIcon, TargetIcon, ValveIcon } from "@eisox/icons";
import { GATEWAY_WARNINGS_ENUM, ROOM_WARNINGS_ENUM, VALVE_WARNINGS_ENUM } from "@eisox/problems-handler";
import { useMutation } from "@tanstack/react-query";

import { DeleteDialog } from "~/UI/components";
import { ConnectionGatewayHistory } from "~/UI/components/ConnectionGatewayHistory";
import { GatewaysListDrawer, ValveListDrawer } from "~/UI/layouts/ListDrawer";
import type { GatewaysWithProblem, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { removeRoom } from "~/apiV2";
import { ValveHistoryDialog } from "~/features/ValveHistory";
import { usePermissionsContext } from "~/providers";

import { LocationDialog } from "../LocationDialog";
import { ValveTooltip } from "../ValveTooltip";

import styles from "./Item.module.scss";

interface ItemProps {
  houseId: string;
  type: "gateways" | "valves" | "rooms";
  name?: string;
  errors: string[];
  warnings: string[];
  item: GatewaysWithProblem | ValvesWithProblem | RoomsWithProblem;
  gateways: GatewaysWithProblem[];
  rooms: RoomsWithProblem[];
  onClickOnPlan: (planId: string) => void;
  onClickOnRoom: (planId: string, roomId: string) => void;
  onDeleteRoom?: VoidFunction;
  preReplace?: boolean;
  selected?: boolean;
  onSelectionChange?: (item: GatewaysWithProblem | ValvesWithProblem) => void;
}

const Item: React.FC<ItemProps> = ({
  houseId,
  type,
  name,
  errors,
  warnings,
  item,
  gateways,
  rooms,
  onClickOnPlan,
  onClickOnRoom,
  onDeleteRoom,
  preReplace = false,
  selected = false,
  onSelectionChange,
}) => {
  const { t } = useTranslation();

  const { permissions } = usePermissionsContext("Item");

  const { mutate: deleteRoom } = useMutation({
    mutationFn: () => removeRoom(houseId, item.id),
    onSuccess: () => {
      toggleDeleteRoomDialog();
      onDeleteRoom?.();
    },
    onError: () => toast.error(t("error.unKnowError.message")),
  });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const [deleteRoomDialogOpen, setDeleteRoomDialogOpen] = useState(false);

  const toggleDrawer = () => setDrawerOpen(prevState => !prevState);
  const toggleHistoryDialog = () => setHistoryDialogOpen(prevState => !prevState);
  const toggleLocationDialog = () => setLocationDialogOpen(prevState => !prevState);
  const toggleDeleteRoomDialog = () => setDeleteRoomDialogOpen(prevState => !prevState);

  const handleClickCheckbox = () => onSelectionChange?.(item as GatewaysWithProblem | ValvesWithProblem);

  return (
    <>
      <Tooltip.Root delayDuration={0}>
        <div className={styles.item}>
          {preReplace && (
            <Radio
              multiple
              checked={selected}
              onClick={handleClickCheckbox}
              disabled={
                (item.warnings as unknown as GATEWAY_WARNINGS_ENUM).includes(GATEWAY_WARNINGS_ENUM.NOT_INSTALL) ||
                (item.warnings as unknown as VALVE_WARNINGS_ENUM).includes(VALVE_WARNINGS_ENUM.NOT_INSTALL)
              }
            />
          )}
          <RoundIcon backgroundColor={"gray"}>
            {{ gateways: <GatewayIcon />, valves: <ValveIcon />, rooms: <RoomIcon /> }[type]}
          </RoundIcon>
          <div>
            <Tooltip.Trigger>
              <span className={styles.header}>{name}</span>
            </Tooltip.Trigger>
            <ul className={styles.list}>
              {errors.map(error => (
                <li key={error} className={cx(styles.list__element, styles.list__element_error)}>
                  {error}
                </li>
              ))}
              {warnings.map(warning => (
                <li key={warning} className={cx(styles.list__element, styles.list__element_warning)}>
                  {warning}
                </li>
              ))}
              {errors.length === 0 && warnings.length === 0 && (
                <li className={cx(styles.list__element, styles.list__element_connected)}>
                  {t("advancedMaintenance.noError")}
                </li>
              )}
            </ul>
          </div>
          {(type === "valves" || type === "gateways") && (
            <>
              <NutIcon className={cx(styles.item__button, styles.item__button_icon)} onClick={toggleDrawer} />
              {(item as ValvesWithProblem | GatewaysWithProblem).mac && (
                <BarChartIcon
                  className={cx(styles.item__button, styles.item__button_icon)}
                  onClick={toggleHistoryDialog}
                />
              )}
            </>
          )}
          {type === "rooms" &&
            !preReplace &&
            (item.warnings as unknown as ROOM_WARNINGS_ENUM).includes(ROOM_WARNINGS_ENUM.NO_VALVE) &&
            permissions.room?.delete && (
              <Button className={styles.item__button} onClick={toggleDeleteRoomDialog}>
                {t("advancedMaintenance.deleteRoomDialog.button")}
              </Button>
            )}
          <TargetIcon className={cx(styles.item__button, styles.item__button_icon)} onClick={toggleLocationDialog} />
        </div>
        <Tooltip.Portal>{type === "valves" && <ValveTooltip {...(item as ValvesWithProblem)} />}</Tooltip.Portal>
      </Tooltip.Root>
      {historyDialogOpen &&
        (type === "valves" ? (
          <ValveHistoryDialog
            open
            onOpenChange={toggleHistoryDialog}
            valves={[item as ValvesWithProblem]}
            valveId={item.id}
          />
        ) : type === "gateways" && permissions.history?.gateway?.lastGatewayActivity?.read ? (
          <ConnectionGatewayHistory
            open
            onOpenChange={toggleHistoryDialog}
            {...(item as GatewaysWithProblem & { mac: string })}
          />
        ) : null)}
      {locationDialogOpen && (
        <LocationDialog
          houseId={houseId}
          type={type}
          errors={errors}
          warnings={warnings}
          item={item}
          gateways={gateways}
          rooms={rooms}
          open
          onOpenChange={toggleLocationDialog}
          onClickOnRoom={onClickOnRoom}
          onClickOnPlan={onClickOnPlan}
        />
      )}
      {drawerOpen &&
        (type === "valves" ? (
          <ValveListDrawer open onClose={toggleDrawer} valves={[item as ValvesWithProblem]} choosenValveId={item.id} />
        ) : type === "gateways" ? (
          <GatewaysListDrawer
            open
            onClose={toggleDrawer}
            gateways={[item as GatewaysWithProblem]}
            choosenGatewayId={item.id}
          />
        ) : null)}
      {deleteRoomDialogOpen && (
        <DeleteDialog.Root open onOpenChange={toggleDeleteRoomDialog}>
          <DeleteDialog.Content
            icon={<RoomIcon />}
            title={t("advancedMaintenance.deleteRoomDialog.title")}
            onDelete={deleteRoom}
          >
            <p className={styles.item__deleteDialogText}>
              {t("advancedMaintenance.deleteRoomDialog.confirm")}
              <span>{(item as RoomsWithProblem).name}</span>
            </p>
          </DeleteDialog.Content>
        </DeleteDialog.Root>
      )}
    </>
  );
};

export { Item };
export type { ItemProps };
