import { redirect } from "react-router-dom";
import type { LoaderFunctionArgs } from "react-router-dom";

import type { Planning } from "@eisox/backend_webapp_api";

import { getPlan } from "~/api/plan";
import { getPlanningsOfRoom } from "~/api/planning";
import { WHITE_PLAN_EISOX } from "~/constants";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import { routeToPlan } from "~/routes/utils";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { houseId, roomId, planId } = params;

  // plan image
  const planResponse = await getPlan(planId, houseId);
  if (planResponse.type !== SUCCESS_FETCH) {
    throw new Error();
  }
  const planImage = planResponse.result.message || WHITE_PLAN_EISOX;

  // plannings rooms
  const planningsRoomResponse = (await getPlanningsOfRoom(houseId, roomId)) as any;
  if (planningsRoomResponse.type !== SUCCESS_FETCH) {
    return redirect(routeToPlan(houseId!, planId!));
  }
  const planningsRoom = (planningsRoomResponse.result?.message?.plannings || []) as Planning[];

  return { planningsRoom, planImage };
};
