import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { primary } from "@eisox/colors";
import {
  ActionButtonV2,
  Alert,
  ButtonV2,
  Divider,
  Dropdown,
  PasswordInput,
  PhoneInput,
  RoundIcon,
  TextField,
  Typography,
} from "@eisox/design-system";
import { ArrowRightIcon, CheckedIcon, CrossIcon, QuestionMarkIcon, RejectIcon } from "@eisox/icons";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FieldContainer, Tooltip } from "~/UI";
import { queries, recoverAccount, sendValidationPhoneNumber, updateUser } from "~/apiV2";
import { PhoneNumberValidationPopup } from "~/features";
import { routeToSignIn } from "~/routes/utils";
import { useUserStore } from "~/stores";
import { accountSchema, emailSchema } from "~/utils";

import styles from "./MyAccount.module.scss";

type GeneralSchema = z.infer<typeof generalSchema>;
const generalSchema = accountSchema;

type ChangeEmailSchema = z.infer<typeof changeEmailSchema>;
const changeEmailSchema = emailSchema.extend({ password: z.string().min(1) });

export interface MyAccountProps {
  lastName?: string;
  firstName?: string;
  company?: string;
  phone?: {
    number?: string;
    verified?: boolean;
  };
  email: string;
}

export const MyAccount: React.FC<MyAccountProps> = ({ firstName, lastName, company, phone, email }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate: generalMutation } = useMutation({
    mutationFn: updateUser,
    onSuccess: async (_, { phoneNumber }) => {
      toast.success(t("myAccount.general.success"));
      await queryClient.invalidateQueries({ queryKey: queries.user.me.queryKey });
      if (phoneNumber) setPhoneValidationOpen(true);
    },
    onError: () => {
      toast.error(t("error.unKnowError.message"));
    },
  });

  const { mutate: changeEmailMutation } = useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      toast.success(t("myAccount.changeEmail.success"));
      useUserStore.getState().logout(queryClient);
      navigate(routeToSignIn());
    },
    onError: () => {
      toast.error(t("error.unKnowError.message"));
    },
  });

  const { mutate: changePassword } = useMutation({
    mutationFn: recoverAccount,
    onSuccess: () => {
      toast.success(t("myAccount.changePassword.success"));
    },
    onError: () => {
      toast.error(t("error.unKnowError.message"));
    },
  });

  const { mutate: sendCode } = useMutation({
    mutationFn: sendValidationPhoneNumber,
    onSuccess: () => {
      toast.success(t("phoneNumberValidation.success.sendCode"));
      setPhoneValidationOpen(true);
    },
    onError: () => toast.error(t("phoneNumberValidation.error.sendCode")),
  });

  const generalForm = useForm<GeneralSchema>({
    criteriaMode: "all",
    resolver: zodResolver(generalSchema),
    values: {
      firstName: firstName ?? "",
      lastName: lastName ?? "",
      company: company ?? "",
      phoneNumber: phone?.number ?? "",
    },
  });

  const emailForm = useForm<ChangeEmailSchema>({
    criteriaMode: "all",
    resolver: zodResolver(changeEmailSchema),
    values: {
      email,
      password: "",
    },
  });

  const [phoneValidationOpen, setPhoneValidationOpen] = useState(false);

  const onSubmitGeneral = generalForm.handleSubmit((data: GeneralSchema) => {
    generalMutation({
      ...(generalForm.formState.dirtyFields.firstName && { firstName: data.firstName }),
      ...(generalForm.formState.dirtyFields.lastName && { lastName: data.lastName }),
      ...(generalForm.formState.dirtyFields.company && { company: data.company }),
      ...(generalForm.formState.dirtyFields.phoneNumber && { phoneNumber: data.phoneNumber }),
    });
  });

  const onSubmitChangeEmail = emailForm.handleSubmit((data: ChangeEmailSchema) => {
    changeEmailMutation({ email: data.email, currentPassword: data.password });
  });

  return (
    <>
      <Dropdown.Content className={styles.card} side="right" sideOffset={150} align="start" collisionPadding={20}>
        <div className={styles.container}>
          <div className={styles.generalHeader}>
            <Typography as="h3">{t("myAccount.general.title")}</Typography>
            <Dropdown.Close>
              <CrossIcon />
            </Dropdown.Close>
          </div>
          <div className={styles.grid}>
            {(Object.keys(generalSchema.shape) as (keyof GeneralSchema)[]).map((k, i) => (
              <Controller
                key={i}
                control={generalForm.control}
                name={k}
                render={({ field }) => (
                  <FieldContainer
                    key={i}
                    label={t(`myAccount.${k}.label`)}
                    error={generalForm.formState.errors[k]}
                    classNames={{ label: styles.label }}
                  >
                    {k === "phoneNumber" ? (
                      <div>
                        <div className={styles.phoneNumber}>
                          <PhoneInput.Input
                            {...field}
                            error={!!generalForm.formState.errors[k]}
                            countrySelectProps={{ disabled: true }}
                            defaultCountry="FR"
                            international={false}
                          />
                          {phone?.verified ? (
                            <CheckedIcon color={primary.green} width={20} height={20} />
                          ) : (
                            <RejectIcon color={primary.red} width={20} height={20} />
                          )}
                          <Tooltip content={t("myAccount.phoneNumber.help")} position="right">
                            <RoundIcon size={20} backgroundColor="gray" iconColor="darkGray">
                              <QuestionMarkIcon height={10} />
                            </RoundIcon>
                          </Tooltip>
                        </div>
                        {!!phone?.number && !phone.verified && (
                          <ButtonV2 onClick={() => sendCode()}>
                            {t("myAccount.phoneNumber.verify")}
                            <ArrowRightIcon />
                          </ButtonV2>
                        )}
                      </div>
                    ) : (
                      <TextField.Root {...generalForm.register(`${k}`)} />
                    )}
                  </FieldContainer>
                )}
              />
            ))}
          </div>
          <ActionButtonV2 disabled={!generalForm.formState.isDirty} onClick={onSubmitGeneral} className={styles.submit}>
            {t("myAccount.save")}
            <ArrowRightIcon />
          </ActionButtonV2>
        </div>
        <Divider className={styles.divider} />
        <div className={styles.container}>
          <div className={styles.changeEmailHeader}>
            <Typography as="h3">{t("myAccount.changeEmail.title")}</Typography>{" "}
            <Tooltip content={t("myAccount.changeEmail.help")} position="right">
              <RoundIcon size={20} backgroundColor="gray" iconColor="darkGray">
                <QuestionMarkIcon height={10} />
              </RoundIcon>
            </Tooltip>
          </div>
          <div className={styles.grid}>
            {(Object.keys(changeEmailSchema.shape) as (keyof ChangeEmailSchema)[]).map((k, i) => (
              <FieldContainer
                key={i}
                label={t(`myAccount.${k}.label`)}
                error={emailForm.formState.errors[k]}
                classNames={{ label: styles.label }}
              >
                {k === "password" ? (
                  <PasswordInput {...emailForm.register(`${k}`)} />
                ) : (
                  <TextField.Root {...emailForm.register(`${k}`)} />
                )}
              </FieldContainer>
            ))}
          </div>
          <ActionButtonV2
            disabled={!emailForm.formState.dirtyFields.email}
            onClick={onSubmitChangeEmail}
            className={styles.submit}
          >
            {t("myAccount.save")}
            <ArrowRightIcon />
          </ActionButtonV2>
          {emailForm.formState.dirtyFields.email && (
            <Alert className={styles.warning} severity="warning">
              {t("myAccount.warning")}
            </Alert>
          )}
        </div>
        <Divider className={styles.divider} />
        <div className={styles.container}>
          <Typography as="h3">{t("myAccount.changePassword.title")}</Typography>
          <ButtonV2 onClick={() => changePassword({ email })}>
            {t("myAccount.changePassword.resetPassword")} <ArrowRightIcon />
          </ButtonV2>
        </div>
      </Dropdown.Content>

      {phoneValidationOpen && (
        <PhoneNumberValidationPopup
          open={phoneValidationOpen}
          onOpenChange={setPhoneValidationOpen}
          onSuccess={async () => {
            await queryClient.invalidateQueries({ queryKey: queries.user.me.queryKey });
            setPhoneValidationOpen(false);
          }}
        />
      )}
    </>
  );
};
