import type { RouteObject } from "react-router-dom";

import { signIn } from "~/routes/routes";

import { SignIn } from ".";

const route: RouteObject = {
  path: signIn,
  element: <SignIn />,
};

export { route };
