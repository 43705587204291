import { orderBy, uniqueId } from "lodash";

import type { GatewaysMessageGatewaysInner, PlansMessageInner } from "@eisox/backend_webapp_api";

import type { GatewaysWithProblem } from "~/UI/screens/House";

import type { PreinstallGatewaysDto } from "..";

export const prefixId = "gateway";
export const BOX = "BOX";

/**
 * Sets the gateway name based on the provided gateway and plans.
 * @param gateway - The gateway object.
 * @param plans - The array of plans.
 * @returns The gateway name or "-" if the plan name or gateway UID is missing.
 */
export const getGatewayName = (
  gateway: Pick<GatewaysMessageGatewaysInner, "plan" | "uid" | "isInternetGateway">,
  plans: PlansMessageInner[],
): string => {
  const planName = plans.find(p => p.id === gateway.plan?.planId)?.name;
  return planName && gateway.uid ? `${planName}_${BOX}${gateway.uid}${gateway.isInternetGateway ? "_M" : ""}` : "-";
};

/**
 * Replaces the gateway name with the provided UID.
 * If the gateway name is not available, returns a dash ("-").
 * @param gateway - The gateway object.
 * @param uid - The UID to replace in the gateway name.
 * @returns The modified gateway name or a dash ("-").
 */
export const replaceGatewayName = (gateway: GatewaysMessageGatewaysInner, uid: number): string => {
  return gateway.gatewayName
    ? gateway.gatewayName.includes(`${BOX}${gateway.uid}`)
      ? gateway.gatewayName.replace(`${BOX}${gateway.uid}`, `${BOX}${uid}`)
      : gateway.gatewayName
    : "-";
};

/**
 * Converts an array of `GatewaysWithProblem` objects to a `PreinstallGatewaysDto` object.
 *
 * @param gateways - The array of `GatewaysWithProblem` objects to be converted.
 * @returns The converted `PreinstallGatewaysDto` object.
 */
export const gatewaysMessageInnerArrayToPreinstallGatewaysDto = (
  gateways: GatewaysMessageGatewaysInner[],
): PreinstallGatewaysDto => {
  const gatewaysToPreinstall = gateways.map(v => ({
    id: v.id || uniqueId(prefixId),
    uid: v.uid!,
    isInternetGateway: !!v.isInternetGateway,
    meshs: v.meshs?.filter(m => m.id) as { id: string }[] | undefined,
    isWifiEnabled: v.isWifiEnabled ? v.isWifiEnabled : !!v.meshs?.length,
    gatewayName: v.gatewayName || "-",
    plan: {
      planId: v.plan?.planId!,
      x: v.plan?.x!,
      y: v.plan?.y!,
    },
  }));
  const orderValveToPreinstall = orderBy(gatewaysToPreinstall, ["uid"]);

  return { gateways: orderValveToPreinstall };
};
