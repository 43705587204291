import { useState } from "react";

import clsx from "clsx";
import type { SubmitHandler, UseFormReturn } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import { PostRoomRoomsInnerRoomTypeEnum } from "@eisox/backend_webapp_api";
import { ActionButton, Button, Radio, Select, Switch, TextInput, Typography } from "@eisox/design-system";
import { PencilIcon, PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Slider } from "~/UI/components";
import type { houseLoader } from "~/UI/screens";
import { DEFAULT_TEMPERATURES, ROOM_TYPES, TEMPERATURE_LIMITS } from "~/constants/appConstantV2";
import { idLoaderHouse } from "~/routes/utils";

import type { RoomFormDto, RoomsDto } from "..";
import { checkGetName, keysForPattern } from "../utils";

import styles from "./RoomForm.module.scss";

interface RoomFormProps extends HTMLProps<"div"> {
  isCurrentRoomIsOnPlan: boolean;
  form: UseFormReturn<RoomFormDto, any, RoomFormDto>;
  handleUpdateRoom: SubmitHandler<RoomFormDto>;
  handleSetToNextRoom: () => void;
  handleDeleteRoom: (r: RoomFormDto) => void;
  fields: RoomsDto;
}

export const RoomForm: React.FC<RoomFormProps> = ({
  isCurrentRoomIsOnPlan,
  className,
  form,
  handleUpdateRoom,
  handleSetToNextRoom,
  handleDeleteRoom,
  fields,
  ...props
}) => {
  const { formatMessage } = useIntl();
  const { plans, groups } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;

  const {
    control,
    formState: { isDirty, errors },
    setValue,
    handleSubmit,
    reset,
    watch,
  } = form;

  const currentRoom = watch();

  const bem = useBem(styles);
  const containerStyle = bem("container");
  const patternStyle = bem("pattern");
  const buttonContainerStyle = bem("button-container");

  const [showPattern, setShowPattern] = useState(false);

  const onChangeName = (room: RoomFormDto, takeRoomUid?: boolean) => {
    const uids = room.pattern
      ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (fields[room.pattern ? checkGetName(plans, room, false) : "default"]?.map(f => f.uid!) ?? [])
      : [];
    const uid = takeRoomUid ? room.uid : uids.length ? Math.max(...uids) + 1 : 1;
    const name = checkGetName(plans, { ...room, uid });
    setValue("uid", uid);
    setValue("name", name);
  };

  return (
    <div {...props} className={clsx(containerStyle(), className)}>
      <h3>
        {formatMessage({
          id: `plan.actions.add.room.roomForm.title.${isCurrentRoomIsOnPlan ? "editRoom" : "newRoom"}`,
        })}
      </h3>
      <div className={containerStyle("fields")}>
        <div className={containerStyle("split")}>
          <Controller
            control={control}
            name="plan.planId"
            render={({ field: { onChange, value } }) => (
              <Select
                label={formatMessage({ id: "plan.actions.add.room.roomForm.level.label" })}
                options={plans.map(p => ({ name: p.name || "--", value: p.id }))}
                value={value}
                multiple={false}
                renderValue={value => (
                  <p>
                    {value
                      ? plans.find(p => p.id === value)?.name || "--"
                      : formatMessage({ id: "plan.actions.add.room.roomForm.level.placeholder" })}
                  </p>
                )}
                onChange={v => {
                  onChange(v);
                  onChangeName({ ...currentRoom, plan: { ...currentRoom.plan, planId: v } });
                }}
                error={errors.plan?.planId}
              />
            )}
          />
          <Controller
            control={control}
            name="groupNames"
            render={({ field: { onChange, value } }) => (
              <Select
                label={formatMessage({ id: "plan.actions.add.room.roomForm.group.label" })}
                options={groups.map(g => ({ name: g.name, value: g.name }))}
                value={value || []}
                multiple
                renderValue={value => (
                  <Typography>
                    {value?.length
                      ? value.map(v => groups.find(g => g.name === v)?.name).join(", ")
                      : formatMessage({ id: "plan.actions.add.room.roomForm.group.placeholder" })}
                  </Typography>
                )}
                onChange={onChange}
                error={errors.groupNames}
              />
            )}
          />
        </div>
        <div className={containerStyle("split")}>
          <Controller
            control={control}
            name="roomType"
            render={({ field: { value, onChange } }) => (
              <Select
                label={formatMessage({ id: "plan.actions.add.room.roomForm.type.label" })}
                value={value}
                options={ROOM_TYPES.map(t => ({
                  value: t,
                  name: formatMessage({ id: `room.type.${t}` }),
                }))}
                multiple={false}
                renderValue={(value?: string) => (
                  <Typography>
                    {value
                      ? formatMessage({ id: `room.type.${value}` })
                      : formatMessage({ id: "room.parameters.drawer.type.placeholder" })}
                  </Typography>
                )}
                onChange={v => {
                  onChange(v);
                  onChangeName({ ...currentRoom, roomType: v as PostRoomRoomsInnerRoomTypeEnum });
                }}
                error={errors.roomType}
              />
            )}
          />
          <Controller
            control={control}
            name="uid"
            render={({ field: { value, onChange } }) => (
              <TextInput
                label={formatMessage({ id: "plan.actions.add.room.roomForm.index" })}
                onChange={v => {
                  onChange(+v.target.value);
                  if (!isCurrentRoomIsOnPlan && !!v.target.value)
                    onChangeName({ ...currentRoom, uid: +v.target.value }, true);
                }}
                value={value || ""}
                disabled={!currentRoom.pattern}
                type="number"
                error={errors.uid}
              />
            )}
          />
        </div>

        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange } }) => (
            <TextInput
              label={formatMessage({ id: "plan.actions.add.room.roomForm.name" })}
              value={value}
              onChange={v => {
                onChange(v.target.value);
                setValue("pattern", "");
                setValue("uid", parseInt(""));
              }}
              error={errors.name}
            />
          )}
        />

        <Controller
          control={control}
          name="comfortTemperature"
          render={({ field: { value, onChange } }) => (
            <Slider
              label={formatMessage({ id: "plan.actions.add.room.roomForm.comfort" })}
              value={value}
              defaultValue={DEFAULT_TEMPERATURES.COMFORT}
              min={TEMPERATURE_LIMITS.MIN}
              max={TEMPERATURE_LIMITS.MAX}
              step={TEMPERATURE_LIMITS.STEP}
              valueLabelDisplay={currentRoom.roomType === PostRoomRoomsInnerRoomTypeEnum.Room ? "auto" : "off"}
              valueLabelFormat={formatMessage(
                { id: "error.temperature.comfUpperNight" },
                { c: value, n: currentRoom.nightTemperature },
              )}
              onChange={(_, value) => {
                if (typeof value === "number") {
                  const minTemp = currentRoom.nightTemperature ?? DEFAULT_TEMPERATURES.NIGHT;
                  if (currentRoom.roomType === PostRoomRoomsInnerRoomTypeEnum.Room && value < minTemp)
                    value = minTemp + TEMPERATURE_LIMITS.STEP;
                  onChange(value);
                }
              }}
            />
          )}
        />
        {currentRoom.roomType === PostRoomRoomsInnerRoomTypeEnum.Room && (
          <Controller
            control={control}
            name="nightTemperature"
            render={({ field: { value, onChange } }) => (
              <Slider
                label={formatMessage({ id: "plan.actions.add.room.roomForm.night" })}
                value={value}
                defaultValue={DEFAULT_TEMPERATURES.NIGHT}
                min={TEMPERATURE_LIMITS.MIN}
                max={TEMPERATURE_LIMITS.MAX}
                step={TEMPERATURE_LIMITS.STEP}
                valueLabelDisplay="auto"
                valueLabelFormat={formatMessage(
                  { id: "error.temperature.nightLowerComf" },
                  { c: currentRoom.comfortTemperature, n: value },
                )}
                onChange={(_, value) => {
                  if (typeof value === "number") {
                    const maxTemp = currentRoom.comfortTemperature;
                    if (value > maxTemp) value = maxTemp - TEMPERATURE_LIMITS.STEP;
                    onChange(value);
                  }
                }}
              />
            )}
          />
        )}

        <label>
          <p>{formatMessage({ id: "plan.actions.add.room.roomForm.isSwitchEnabled.label" })}</p>
          <div>
            <p>
              {formatMessage(
                { id: "plan.actions.add.room.roomForm.isSwitchEnabled.value" },
                { e: currentRoom.isSwitchEnabled },
              )}
            </p>
            <Controller
              control={control}
              name="isSwitchEnabled"
              render={({ field: { value, onChange } }) => <Switch checked={value} onCheckedChange={onChange} />}
            />
          </div>
        </label>
        <div className={patternStyle()}>
          <div className={patternStyle("action")} onClick={() => setShowPattern(prev => !prev)}>
            <Radio checked={showPattern} multiple />
            <p>{formatMessage({ id: "plan.actions.add.room.roomForm.pattern.show" })}</p>
          </div>
          {showPattern && (
            <div className={patternStyle("show")}>
              <Controller
                control={control}
                name="pattern"
                render={({ field: { value, onChange } }) => (
                  <TextInput
                    label={formatMessage({ id: "plan.actions.add.room.roomForm.pattern.label" })}
                    value={value}
                    onChange={e => {
                      onChange(e.target.value);
                      if (!e.target.value) return setValue("uid", undefined);
                      onChangeName({ ...currentRoom, pattern: e.target.value });
                      if (!e.target.value.includes("(index)")) setValue("uid", undefined);
                    }}
                    error={errors.pattern}
                  />
                )}
              />
              <p>{formatMessage({ id: "plan.actions.add.room.roomForm.pattern.info" })}</p>
              <p>
                {keysForPattern
                  .map(k => formatMessage({ id: `plan.actions.add.room.roomForm.pattern.key.${k}` }))
                  .join(", ")}
              </p>
            </div>
          )}
        </div>
      </div>
      {isCurrentRoomIsOnPlan && (
        <div className={buttonContainerStyle()}>
          <Button
            text={formatMessage({ id: "plan.actions.add.room.roomForm.delete" })}
            className={buttonContainerStyle({ type: "delete" })}
            onClick={() => handleDeleteRoom(currentRoom)}
          />
          <div className={buttonContainerStyle("edit")}>
            {isDirty && (
              <Button
                text={formatMessage({ id: "plan.actions.add.room.roomForm.cancel" })}
                className={buttonContainerStyle({ type: "cancel" })}
                onClick={() => reset()}
              />
            )}
            <ActionButton
              text={formatMessage({ id: "plan.actions.add.room.roomForm.edit" })}
              onClick={() => handleSubmit(handleUpdateRoom)()}
              variant="primary"
              disabled={!isDirty}
              icon={<PencilIcon />}
            />
          </div>
        </div>
      )}

      <div className={containerStyle("infoNewRoom", { disabled: isDirty })}>
        {isCurrentRoomIsOnPlan ? (
          <Button
            text={formatMessage({ id: "plan.actions.add.room.roomForm.newRoom" })}
            icon={<PlusIcon />}
            disabled={isDirty}
            onClick={handleSetToNextRoom}
          />
        ) : (
          <span>{formatMessage({ id: "plan.actions.add.room.roomForm.infoPlanAddNewRoom" })}</span>
        )}
      </div>
    </div>
  );
};
