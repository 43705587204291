import { Gateways as GatewaysFC } from "../../Preinstall/Gateways";

interface GatewaysProps {
  open: boolean;
  onClose: VoidFunction;
}

export const Gateways: React.FC<GatewaysProps> = ({ open, onClose }) => (
  <GatewaysFC open={open} onClose={onClose} toAdd />
);
