import { PropsWithChildren, useEffect, useMemo, useState } from "react";

import { useLoaderData } from "react-router-dom";
import { TransformComponent, TransformWrapper, useTransformEffect } from "react-zoom-pan-pinch";

import { useBem } from "@eisox/tools";

import { RoomFC } from "~/UI/components/Room";
import { Valve } from "~/UI/layouts";
import { useZoomToElements } from "~/hooks";

import { useRoomContext } from "../../context";
import { loader } from "../../loader";

import styles from "./Plan.module.scss";

interface PlanElementProps extends PropsWithChildren {
  id: string;
  scale: number;
  x: number;
  y: number;
}

const PlanElement: React.FC<PlanElementProps> = ({ id, scale, x, y, children }) => {
  return (
    <div
      key={id}
      id={id}
      style={{
        transform: `translate(-${50 / scale}%, -${50 / scale}%) scale(${1 / scale}) `,
        position: "absolute",
        left: `calc(${x}%)`,
        top: `calc(${y}%)`,
        transformOrigin: `0% 0%`,
      }}
    >
      {children}
    </div>
  );
};

const ElementsIntoPlan = () => {
  const bem = useBem(styles);
  const transformComponentStyle = bem("transform-component");
  const transformComponentContentStyle = bem("transform-component-content");

  const { valves: valvesOfTheRoom, room } = useRoomContext();
  const zoomToElements = useZoomToElements();
  const { planImage } = useLoaderData() as LoaderData<typeof loader>;

  const [loaded, setLoaded] = useState(false);
  const [scale, setScale] = useState(1);

  useTransformEffect(({ state }) => {
    const timeout = setTimeout(() => setScale(state.scale), 100);
    return () => clearTimeout(timeout);
  });

  const valvesOnPlan = useMemo(() => valvesOfTheRoom.filter(v => v.plan && v.plan.x && v.plan.y), [valvesOfTheRoom]);

  useEffect(() => {
    if (valvesOnPlan.length > 0) {
      zoomToElements(
        valvesOnPlan.map(v => v.id!),
        undefined,
        800,
        "easeInOutQuad",
      );
    }
  }, [valvesOnPlan, loaded]);

  return (
    <TransformComponent wrapperClass={transformComponentStyle()} contentClass={transformComponentContentStyle()}>
      <div className={transformComponentContentStyle("plan")}>
        <img
          alt=""
          onLoad={() => setLoaded(true)}
          className={transformComponentContentStyle("image")}
          src={planImage}
        />
        {valvesOnPlan.map((v, i) => (
          <PlanElement key={i} id={v.id!} scale={scale} x={v.plan!.x!} y={v.plan!.y!}>
            <Valve key={i} {...v} selected displayDetails />
          </PlanElement>
        ))}
        {valvesOnPlan.length === 0 && (
          <PlanElement id={room.id!} scale={scale} x={room.plan!.x!} y={room.plan!.y!}>
            <RoomFC name={room.name!} selected />
          </PlanElement>
        )}
      </div>
    </TransformComponent>
  );
};

export const Plan: React.FC = () => {
  const bem = useBem(styles);
  const planStyle = bem("plan");

  return (
    <div className={planStyle()}>
      <TransformWrapper
        wheel={{ disabled: true }}
        pinch={{ disabled: true }}
        panning={{ disabled: true }}
        doubleClick={{ disabled: true }}
        limitToBounds={false}
      >
        <ElementsIntoPlan />
      </TransformWrapper>
    </div>
  );
};
