import type { HTMLAttributes } from "react";
import React, { forwardRef } from "react";

import { cx } from "class-variance-authority";
import clsx from "clsx";

import { CrossIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import * as RadixDialog from "@radix-ui/react-dialog";

import { Circle } from "../Circle";

import styles from "./styles.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
const ROOT_NAME = "Dialog";
type RootProps = RadixDialog.DialogProps;
const Root = RadixDialog.Root;
Root.displayName = ROOT_NAME;

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/
const TRIGGER_NAME = "DialogTrigger";
type TriggerProps = RadixDialog.DialogTriggerProps;
const Trigger = RadixDialog.Trigger;
Trigger.displayName = TRIGGER_NAME;

/* -------------------------------------------------------------------------------------------------
 * Close
 * -----------------------------------------------------------------------------------------------*/
const CLOSE_NAME = "DialogClose";
type CloseProps = RadixDialog.DialogCloseProps;
const Close = RadixDialog.Close;
Close.displayName = CLOSE_NAME;

/* -------------------------------------------------------------------------------------------------
 * Footer
 * -----------------------------------------------------------------------------------------------*/
const FOOTER_NAME = "DialogFooter";
type FooterProps = HTMLAttributes<HTMLDivElement>;
const Footer: React.FC<FooterProps> = ({ className, children, ...props }) => {
  const bem = useBem(styles);
  const footerStyle = bem("footer");

  return (
    <div {...props} className={clsx(footerStyle(), className)}>
      {children}
    </div>
  );
};
Footer.displayName = FOOTER_NAME;

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
const CONTENT_NAME = "DialogContent";
interface ContentProps extends RadixDialog.DialogContentProps {
  title?: string;
  icon?: React.ReactElement;
}
const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ title, icon, children, className, ...props }, forwardedRef) => {
    const bem = useBem(styles);
    const overlayStyle = bem("overlay");
    const modalStyle = bem("content");

    return (
      <RadixDialog.Portal>
        <RadixDialog.Overlay className={overlayStyle()} />
        <RadixDialog.Content {...props} ref={forwardedRef} className={cx(modalStyle(), className)}>
          <RadixDialog.Close className={modalStyle("close-icon")}>
            <CrossIcon className={modalStyle("close-icon", { icon: true })} />
          </RadixDialog.Close>
          {icon && (
            <Circle className={modalStyle("circle")} size={70}>
              {icon}
            </Circle>
          )}
          {title && <RadixDialog.Title className={modalStyle("title")}>{title}</RadixDialog.Title>}
          {children}
        </RadixDialog.Content>
      </RadixDialog.Portal>
    );
  },
);
Content.displayName = CONTENT_NAME;

/* -------------------------------------------------------------------------------------------------
 * Description
 * -----------------------------------------------------------------------------------------------*/
const DESCRIPTION_NAME = "DialogDescription";
type DescriptionProps = RadixDialog.DialogDescriptionProps;
const Description = RadixDialog.Description;
Description.displayName = DESCRIPTION_NAME;

export { Root, Trigger, Content, Close, Description, Footer };
export type { RootProps, TriggerProps, ContentProps, CloseProps, DescriptionProps, FooterProps };
