import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { Option } from "@eisox/design-system";
import { ActionButtonV2 as ActionButton, Alert, Dialog, SelectV2 as Select } from "@eisox/design-system";
import { CopyIcon } from "@eisox/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowRightIcon } from "@mui/x-date-pickers";

import { FieldContainer } from "~/UI";
import { useBoilerRoomContext } from "~/features/BoilerRooms";
import type { BoilerRoom, HeatingCurve } from "~/socketio/types";

import type { CopySchemaType } from "../../helpers";
import { copySchema } from "../../helpers";

import styles from "./CopyDialog.module.scss";

const NAME = "CopyDialog";

interface CopyDialogProps {
  heatingNetworkId: string;
  heatingNetworkName: string;
  heatingCurve: HeatingCurve[];
  boilerRooms: BoilerRoom[];
}

const CopyDialog: React.FC<CopyDialogProps> = ({ heatingNetworkId, heatingNetworkName, heatingCurve, boilerRooms }) => {
  const { t } = useTranslation();

  const { useUpdateBoilerRoom } = useBoilerRoomContext(NAME);

  const [open, setOpen] = useState(false);

  const { mutate } = useUpdateBoilerRoom({ onSuccess: () => handleOpenChange(false) });

  const options: Option[] = boilerRooms.flatMap(
    b =>
      b.heatingNetworks
        ?.filter(hn => hn.type === "radiator" && hn.id !== heatingNetworkId)
        .map(hn => ({ value: hn.id, name: `${b.name ?? "-"}/${hn.name ?? "-"}` })) ?? [],
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<CopySchemaType>({ resolver: zodResolver(copySchema), defaultValues: { heatingNetworks: [] } });

  const onSubmit = (data: CopySchemaType) => {
    const updates = boilerRooms.map(boilerRoom => {
      const filteredHeatingNetworks = boilerRoom.heatingNetworks
        ?.filter(network => data.heatingNetworks.includes(network.id))
        .map(network => ({
          id: network.id,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          heatingCurves: network.heatingCurves?.map(({ offset, ...curve }) => {
            const heatingCurveToCopy = heatingCurve.find(hc => hc.ext === curve.ext);
            return { ...curve, con: heatingCurveToCopy?.con ?? curve.con };
          }),
        }));
      return {
        id: boilerRoom.id,
        heatingNetworks: filteredHeatingNetworks,
      };
    });
    mutate(updates);
  };

  const handleOpenChange = (open: boolean) => {
    reset();
    setOpen(open);
  };

  return (
    <Dialog.Root open={open} onOpenChange={handleOpenChange}>
      <Dialog.Trigger asChild className={styles.trigger}>
        <CopyIcon className={styles.icon} />
      </Dialog.Trigger>
      <Dialog.Content
        className={styles.copyDialog}
        title={t("network.content.heatingCurve.dialog.copy.title")}
        icon={<CopyIcon />}
      >
        <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
          <Dialog.Description className={styles.description}>
            {t("network.content.heatingCurve.dialog.copy.description", { n: heatingNetworkName })}
          </Dialog.Description>
          <FieldContainer label={t("network.content.heatingCurve.dialog.copy.heatingNetwork.label")}>
            <Controller
              control={control}
              name="heatingNetworks"
              render={({ field: { value, onChange } }) => (
                <Select
                  placeholder={t("network.content.heatingCurve.dialog.copy.heatingNetwork.placeholder")}
                  options={options}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </FieldContainer>
          <Alert severity="warning">{t("network.content.heatingCurve.dialog.copy.warning")}</Alert>
          <Dialog.Footer>
            <Dialog.Close asChild>
              <ActionButton variant="cancel">{t("network.content.heatingCurve.dialog.copy.cancel")}</ActionButton>
            </Dialog.Close>
            <ActionButton type="submit" rounded disabled={!isDirty}>
              {t("network.content.heatingCurve.dialog.copy.copy")}
              <ArrowRightIcon />
            </ActionButton>
          </Dialog.Footer>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};

CopyDialog.displayName = NAME;

export { CopyDialog };
export type { CopyDialogProps };
