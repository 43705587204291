import { useIntl } from "react-intl";
import { Link, useMatches } from "react-router-dom";

import { ButtonV2, Sidebar as DSSidebar, Divider, Tooltip } from "@eisox/design-system";
import { ArrowLeftIcon, MapIcon } from "@eisox/icons";

import type { SidebarFooterProps } from "~/UI/components";
import { SidebarFooter } from "~/UI/components";
import { routeToHouses } from "~/routes/utils";

import { HouseNameCard } from "../HouseNameCard";

import styles from "./Sidebar.module.scss";

interface SidebarProps extends Omit<DSSidebar.FooterProps, "version">, SidebarFooterProps {
  houseName: string;
  displayChristmasHat?: boolean;
  children?: React.ReactNode;
  canAccessPreprod?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({
  houseName,
  email,
  role,
  firstName,
  lastName,
  phone,
  company,
  lastNewsId,
  news,
  isLoading,
  displayChristmasHat = false,
  canAccessPreprod = false,
  children,
}) => {
  const { formatMessage } = useIntl();

  const user = {
    email,
    role,
    firstName,
    phone,
    lastName,
    company,
  };

  return (
    <DSSidebar.Root displayChristmasHat={displayChristmasHat}>
      <DSSidebar.Header className={styles.sidebar__header}>
        {({ collapsed }) => (
          <>
            {collapsed ? (
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <Link to={routeToHouses()}>
                    <MapIcon className={styles.sidebar__icon} />
                  </Link>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content className={styles.sidebar__tooltip} side="right" sideOffset={20}>
                    {formatMessage({ id: "houses.title" })}
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            ) : (
              <ButtonV2 asChild className={styles.sidebar__button}>
                <Link to={routeToHouses()}>
                  <ArrowLeftIcon />
                  {formatMessage({ id: "houses.title" })}
                </Link>
              </ButtonV2>
            )}
            <Divider className={styles.sidebar__divider} />
            <HouseNameCard name={houseName} collapsed={collapsed} />
          </>
        )}
      </DSSidebar.Header>
      <DSSidebar.Content>{children}</DSSidebar.Content>
      <SidebarFooter
        {...user}
        news={news}
        isLoading={isLoading}
        lastNewsId={lastNewsId}
        canAccessPreprod={canAccessPreprod}
      />
    </DSSidebar.Root>
  );
};

interface SidebarItemProps extends DSSidebar.ItemProps {
  to: string;
  newWindow?: boolean;
}

export const SidebarItem: React.FC<SidebarItemProps> = props => {
  const { to, newWindow, children, ...sidebarItemProps } = props;
  const matches = useMatches();
  const isValid = matches.some(match => match.pathname.includes(to));
  return (
    <DSSidebar.Item {...sidebarItemProps} asChild className={styles.sidebar__item} isActive={isValid}>
      <Link to={to} target={newWindow ? "_blank" : undefined} rel={newWindow ? "noopener noreferrer" : undefined}>
        {children}
      </Link>
    </DSSidebar.Item>
  );
};
