import clsx from "clsx";

import { Dayjs } from "@eisox/dayjs";
import { DateInput } from "@eisox/design-system";
import { useBem } from "@eisox/tools";
import { DateValidationError } from "@mui/x-date-pickers";
import { FieldChangeHandler } from "@mui/x-date-pickers/internals";

import styles from "./PeriodInterval.module.scss";

const DATE_FORMAT = "DD/MM/YYYY";

interface PeriodIntervalProps {
  begin?: Dayjs;
  end?: Dayjs;
  onChangeBegin: FieldChangeHandler<Dayjs | null, DateValidationError>;
  onChangeEnd: FieldChangeHandler<Dayjs | null, DateValidationError>;
  className?: string;
}

export const PeriodInterval: React.FC<PeriodIntervalProps> = ({
  begin,
  end,
  onChangeBegin,
  onChangeEnd,
  className,
}) => {
  const bem = useBem(styles);
  const periodIntervalStyle = bem("period-interval");

  return (
    <div className={clsx(periodIntervalStyle(), className)}>
      <DateInput
        className={periodIntervalStyle("date-input")}
        format={DATE_FORMAT}
        value={begin}
        onChange={onChangeBegin}
      />
      <span className={periodIntervalStyle("separator")}>-</span>
      <DateInput
        className={periodIntervalStyle("date-input")}
        format={DATE_FORMAT}
        value={end}
        onChange={onChangeEnd}
      />
    </div>
  );
};
