type UnknownArrayOrObject = unknown[] | Record<string, unknown> | {};

export const getDirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject,
): UnknownArrayOrObject => {
  if (dirtyFields === true) {
    return allValues;
  }

  if (Array.isArray(dirtyFields)) {
    //@ts-ignore
    dirtyFields.forEach((_, index) => getDirtyValues(dirtyFields[index], allValues[index]));
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      //@ts-ignore
      getDirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};
