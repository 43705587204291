import { useBem } from "@eisox/tools";

import styles from "./MenuItem.module.scss";

import { NavLink } from "react-router-dom";

export interface MenuItemProps {
  title: string;
  subItems: { title: string; url: string; hasPermissions?: boolean }[];
}

export const MenuItem: React.FC<MenuItemProps> = ({ title, subItems }) => {
  const bem = useBem(styles);
  const menuItemStyle = bem("menu-item");

  return (
    <div className={menuItemStyle()}>
      <h2 className={menuItemStyle("title")}>{title}</h2>
      {subItems.map((item, index) => (
        <NavLink
          key={index}
          className={({ isActive }) => menuItemStyle("sub-item", { active: isActive })}
          to={item.url}
        >
          {item.title}
        </NavLink>
      ))}
    </div>
  );
};
