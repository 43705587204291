import { Circle, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import type { SVGPresentationAttributes } from "@react-pdf/types";

import { intl } from "~/i18n";

import { GatewayProblemType, ValveProblemType } from "../../../../../providers";
import { colors, darkBlueColor } from "../color";

const rowWidth = 220;
const circleSize = 20;

const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    alignItems: "center",
    fontSize: 10,
    padding: "5 10",
    width: rowWidth,
  },
  circle: {
    width: 20,
    height: 20,
    borderRadius: 200,
  },
  separator: {
    borderBottom: `1px solid ${darkBlueColor}`,
    width: rowWidth,
  },
});

export const Lexique: React.FC<{}> = () => {
  const object = {
    ...ValveProblemType,
    ...GatewayProblemType,
  };

  return (
    <View>
      {Object.values(object).map((o, i) => {
        const transformString = `${o.toLowerCase().replace(/_(.)/g, (_, p1) => p1.toUpperCase())}`;
        const color = colors[`${transformString}Color` as keyof typeof colors];
        const style: SVGPresentationAttributes =
          o === ValveProblemType.UNCLOG_CHANGE_BODY
            ? { strokeDasharray: "2,2", stroke: darkBlueColor }
            : { fill: color };
        return (
          <>
            <View style={styles.row}>
              <Svg viewBox={`0 0 ${circleSize} ${circleSize}`} width={circleSize} height={circleSize}>
                <Circle cx={circleSize / 2} cy={circleSize / 2} r={circleSize / 2} style={style} />
              </Svg>
              <Text style={{ width: rowWidth }}>
                {intl.formatMessage({ id: `interventionPlanTool.pdf.lexique.${transformString}` })}
              </Text>
            </View>
            {i !== Object.values(object).length - 1 && <View style={styles.separator} />}
          </>
        );
      })}
    </View>
  );
};
