import { ValvesMessageValvesInner } from "@eisox/backend_webapp_api";

export interface withProperties {
  mac?: string;
  uid?: number;
}

/**
 * Returns the UID of the item with the largest UID value and a valid MAC address.
 * @param items - An array of objects with properties including 'mac' and 'uid'.
 * @returns The UID of the item with the largest UID value and a valid MAC address.
 */
export const getItemBiggerUidWithMac = (items: withProperties[]): number =>
  items.reduce((maxUid, obj) => {
    return obj.mac && obj.uid && obj.uid > maxUid ? obj.uid : maxUid;
  }, 0);

/**
 * Returns the UID of the item with the largest UID value.
 * @param items - An array of objects with properties including 'uid'.
 * @returns The UID of the item with the largest UID value.
 */
export const getItemBiggerUid = (items: withProperties[]): number =>
  items.reduce((maxUid, obj) => {
    return obj.uid && obj.uid > maxUid ? obj.uid : maxUid;
  }, 0);

/**
 * Returns the UID of the item with the largest UID value, among the items that have either a matching gateway ID in the valves array or a non-null mac property.
 *
 * @param items - An array of items with properties including id, uid, and mac.
 * @param valves - An array of valves with properties including gatewayId and mac.
 * @returns The UID of the item with the largest UID value, or 0 if no such item is found.
 */
export const getItemBiggerUidWithMacOrValvesInstalled = (
  items: Array<{ id?: string } & withProperties>,
  valves: ValvesMessageValvesInner[],
): number =>
  items.reduce((maxUid, { id, uid, mac }) => {
    return valves.some(v => v.gatewayId === id && v.mac) || mac ? Math.max(maxUid, uid || 0) : maxUid;
  }, 0);
