import { PostHistoBoxIntervalEnum } from "@eisox/backend_webapp_api";

export const MAX_INTERVAL_DATE: Record<PostHistoBoxIntervalEnum, number> = {
  [PostHistoBoxIntervalEnum.Day]: 30,
  [PostHistoBoxIntervalEnum.Hour]: 7,
};

export const MAX_COUNT: Record<PostHistoBoxIntervalEnum, number> = {
  [PostHistoBoxIntervalEnum.Hour]: 60 / 5,
  [PostHistoBoxIntervalEnum.Day]: 288,
};
