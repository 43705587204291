import type { RouteObject } from "react-router-dom";

import { recoverAccount } from "~/routes/routes";

import { RecoverAccount } from ".";

const route: RouteObject = {
  path: recoverAccount,
  element: <RecoverAccount />,
};

export { route };
