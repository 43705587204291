import { useLoaderData } from "react-router-dom";

import { useBem } from "@eisox/tools";

import { HistorySlider } from "~/UI/components";
import { PlanV2 } from "~/UI/layouts/PlanV2";
import type { ItemType } from "~/UI/layouts/PlanV2/components";
import KeyUpDown from "~/assets/svg/keyUpDown.svg?react";
import { usePlansContext } from "~/providers";
import { DisplaySection } from "~/stores";

import { useHistory } from "./hooks";
import type { loader } from "./loader";

import styles from "./Plan.module.scss";

export const Plan: React.FC = () => {
  const bem = useBem(styles);
  const planStyle = bem("plan");

  const { planImage } = useLoaderData() as LoaderData<typeof loader>;

  const { selectedDisplaySelector } = usePlansContext("Plan");

  const { rooms, gateways, valves, boilerrooms, submit } = useHistory();

  const historyDisplayed = selectedDisplaySelector.includes(DisplaySection.HISTORY);

  const items: ItemType[] = [];
  if (selectedDisplaySelector.includes(DisplaySection.VALVES)) items.push(...valves);
  if (selectedDisplaySelector.includes(DisplaySection.ROOMS)) items.push(...rooms);
  if (selectedDisplaySelector.includes(DisplaySection.GATEWAYS)) items.push(...gateways);
  if (selectedDisplaySelector.includes(DisplaySection.BOILERS)) items.push(...boilerrooms);

  return (
    <>
      <PlanV2 className={planStyle("plan")} planUrl={planImage} items={items} />
      <KeyUpDown className={planStyle("key-up-down")} />
      {historyDisplayed && <HistorySlider onChange={submit} />}
    </>
  );
};
