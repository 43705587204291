import { useRef } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { ActionButtonV2 as ActionButton, Dialog, SelectV2 as Select } from "@eisox/design-system";
import { PointChartIcon } from "@eisox/icons";
import type { PostHistovalvesStatsFields } from "@eisox/webapp-api-specification";
import { useQuery } from "@tanstack/react-query";

import type { ValvesWithProblem } from "~/UI/screens/House";
import { queries } from "~/apiV2";
import { usePermissionsContext } from "~/providers";
import { getDateOptions } from "~/utils";

import type { ChartDataType } from "../../helpers";
import { exportData, formatUnit, getDataTypeByPermission, transformData } from "../../helpers";

import styles from "./DataExportPopup.module.scss";

interface ExportDataType {
  data: PostHistovalvesStatsFields[];
  valve?: string;
  start: string;
  end: string;
}

interface DataExportPopupProps extends Dialog.RootProps {
  data: PostHistovalvesStatsFields[];
  valves: ValvesWithProblem[];
  valveId: string;
  date: string;
}

const DataExportPopup: React.FC<DataExportPopupProps> = ({
  data: dataProp,
  date: dateProp,
  valves,
  valveId,
  open,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  const { permissions } = usePermissionsContext("DataExportPopup");

  const valvesWithMac = valves.filter(v => !!v.mac);

  const format = useRef<"csv" | "xlsx">("csv");

  const { control, watch, handleSubmit } = useForm<ExportDataType>({
    values: {
      data: dataProp,
      valve: valveId,
      start: dateProp,
      end: dayjs(dateProp).endOf("day").toISOString(),
    },
  });

  const data = watch("data");
  const valve = watch("valve");
  const startDate = watch("start");
  const endDate = watch("end");

  const { data: response, refetch } = useQuery({
    ...queries.valve.history({
      fields: [...data],
      startDate,
      endDate,
      valves: [valvesWithMac.find(v => v.id === valve)?.mac!],
    }),
    enabled: open,
  });

  const dataType = getDataTypeByPermission(permissions, valve!, valvesWithMac);

  const dataOptions = dataType.map(type => ({
    value: type,
    name: `${t(`room.historyDialog.data.${type}`)} (${formatUnit(type)})`,
  }));
  const valveOptions = valvesWithMac.map(v => ({ value: v.id, name: v.mac! }));
  const endDateOptions = getDateOptions(false).filter(o => dayjs(o.value).isAfter(startDate));

  const onSubmit = async () => {
    await refetch();
    const transformedData = transformData(response, data);
    const translatedData = transformedData.map(d => {
      const translatedItem: Record<string, string | number> = {
        [t("room.historyDialog.data.date")]: dayjs(d.date).format("DD/MM/YYYY - HH:mm"),
      };
      for (const key in d) {
        if (key !== "date" && Object.prototype.hasOwnProperty.call(d, key)) {
          translatedItem[`${t(`room.historyDialog.data.${key}`)} (${formatUnit(key as PostHistovalvesStatsFields)})`] =
            d[key as keyof ChartDataType]!;
        }
      }
      return translatedItem;
    });
    exportData(translatedData, valve!, format.current);
  };

  return (
    <Dialog.Root open={open} {...props}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content
        className={styles.dataExportPopup}
        title={t("room.historyDialog.dataExportationPopup.title")}
        icon={<PointChartIcon />}
      >
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.dataExportPopup__inputs}>
            <Controller
              name="data"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select options={dataOptions} value={value} onChange={onChange} />
              )}
            />
            <Controller
              name="valve"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select options={valveOptions} value={value} onChange={onChange} disabled={!!valveId} />
              )}
            />
            <Controller
              name="start"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select options={getDateOptions()} value={value} onChange={onChange} />
              )}
            />
            <Controller
              name="end"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Select options={endDateOptions} value={value} onChange={onChange} />
              )}
            />
          </div>
          <div className={styles.dataExportPopup__buttons}>
            <Dialog.Close asChild>
              <ActionButton variant="cancel">{t("room.historyDialog.dataExportationPopup.cancel")}</ActionButton>
            </Dialog.Close>
            {(["csv", "xlsx"] as const).map(csv => (
              <ActionButton
                type="submit"
                rounded
                disabled={data.length === 0 || valve === undefined}
                onClick={() => (format.current = csv)}
              >
                {t(`room.historyDialog.dataExportationPopup.export.${csv}`)}
              </ActionButton>
            ))}
          </div>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export { DataExportPopup };
export type { DataExportPopupProps };
