import clsx from "clsx";

import { useBem } from "@eisox/tools";

import { Temperature } from "../Temperature/Temperature";

import styles from "./Temperatures.module.scss";

interface TemperaturesProps {
  orientation?: "horizontal" | "vertical";
  startingTemperature?: number;
  returnTemperature?: number;
  className?: string;
}

export const Temperatures: React.FC<TemperaturesProps> = ({
  orientation = "horizontal",
  startingTemperature,
  returnTemperature,
  className,
}) => {
  const bem = useBem(styles);
  const temperaturesStyle = bem("temperatures");

  return (
    <div className={clsx(temperaturesStyle({ orientation }), className)}>
      {startingTemperature !== undefined ? (
        <Temperature
          className={temperaturesStyle("starting", { orientation })}
          type={"starting"}
          orientation={orientation}
          value={startingTemperature}
        />
      ) : (
        <div className={temperaturesStyle("starting", { orientation })} />
      )}
      {returnTemperature !== undefined && (
        <Temperature
          className={temperaturesStyle("returning", { orientation })}
          type={"returning"}
          orientation={orientation}
          value={returnTemperature}
        />
      )}
    </div>
  );
};
