import { useState } from "react";

import { useIntl } from "react-intl";
import { Navigate, Outlet, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

import { ActionButton, Button, Card } from "@eisox/design-system";
import { GatewayIcon } from "@eisox/icons";
import { useBem, useKeyPress } from "@eisox/tools";

import { Header } from "~/UI/layouts";
import { GatewaysListDrawer } from "~/UI/layouts/ListDrawer";
import type { houseLoader } from "~/UI/screens";
import { usePermissionsContext } from "~/providers";
import { PlansProvider } from "~/providers/Plans";
import { idLoaderHouse, routeToPlan } from "~/routes/utils";
import { UserRole } from "~/utils";

import { ActionMenu, Parameters, RightColumn } from "./components";

import styles from "./Plans.module.scss";

export const Plans: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const headerStyle = bem("header");
  const plansStyle = bem("plans");

  const navigate = useNavigate();
  const { houseId, planId } = useParams() as { houseId: string; planId?: string };
  const { plans, gateways } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;

  const { permissions, role } = usePermissionsContext("Plans");

  const currentPlan = plans.find(p => p.id === planId);

  const [gatewaysListDrawerOpen, setGatewaysListDrawerOpen] = useState(false);
  const [addPlanDrawerOpen, setAddPlanDrawerOpen] = useState(false);

  useKeyPress({ code: "ArrowUp" }, () => !gatewaysListDrawerOpen && plans.length > 0 && onKeyPress("up"));
  useKeyPress({ code: "ArrowDown" }, () => !gatewaysListDrawerOpen && plans.length > 0 && onKeyPress("down"));

  if (!planId && plans.length > 0 && plans[0].id && !addPlanDrawerOpen) {
    return <Navigate to={routeToPlan(houseId, plans[0].id)} replace />;
  }

  const handleAddPlanDrawerOpen = () => setAddPlanDrawerOpen(prevState => !prevState);

  const onKeyPress = (key: string) => {
    const indexDelta = key === "up" ? 1 : -1;
    const newIndex = (plans.findIndex(plan => plan.id === planId) + indexDelta + plans.length) % plans.length;

    if (plans[newIndex].id !== planId) {
      navigate(routeToPlan(houseId, plans[newIndex].id));
    }
  };

  return (
    <>
      <Header
        title={currentPlan?.name ?? "--"}
        parameters={{
          title: formatMessage({ id: "plans.parameters.title" }),
          content: <Parameters plan={currentPlan!} />,
        }}
        canAccessParameters={!!currentPlan}
      >
        <div className={headerStyle()}>
          {role === UserRole.INSTALLER && (
            <ActionMenu addPlanDrawerOpen={addPlanDrawerOpen} onAddPlan={handleAddPlanDrawerOpen} />
          )}
          {gateways.length > 0 && (
            <Button
              text={formatMessage({ id: "plans.boxs" })}
              icon={<GatewayIcon style={{ width: 20, height: 20 }} />}
              onClick={() => setGatewaysListDrawerOpen(true)}
            />
          )}
        </div>
      </Header>
      <main className={plansStyle()}>
        <PlansProvider>
          <Card className={plansStyle("plan")}>
            {currentPlan ? (
              <Outlet />
            ) : (
              <ActionButton
                text={formatMessage({ id: "plans.add.title" })}
                onClick={handleAddPlanDrawerOpen}
                disabled={!permissions.plan?.create}
              />
            )}
          </Card>
          <RightColumn />
        </PlansProvider>
      </main>
      <GatewaysListDrawer
        open={gatewaysListDrawerOpen}
        onClose={() => setGatewaysListDrawerOpen(false)}
        gateways={gateways}
      />
    </>
  );
};
