import { useState } from "react";

import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";

import type { UnitTypeShort } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import { useBem } from "@eisox/tools";
import { useQuery } from "@tanstack/react-query";

import type { BatteryHistory } from "~/apiV2";
import { queries } from "~/apiV2";
import { HISTORY } from "~/constants";
import { FORMAT_DATE } from "~/constants/timeConstants";

import { Popup } from "../Popup";
import type { RootProps } from "../Popup/Popup";
import { Table } from "../Table";

import styles from "./BatteryHistoryPopup.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
interface Content {
  data: BatteryHistory[];
}

const Content: React.FC<Content> = ({ data }) => {
  const bem = useBem(styles);
  const tableStyle = bem("table");

  const { t } = useTranslation();

  const transformedData = data.map(item => {
    const { batLowDate, dateNewBat, createdAt, batZ = "-", softwareVersion } = item;
    const date = batLowDate ?? dateNewBat ?? createdAt;
    return {
      date,
      info: batLowDate
        ? t("drawer.listDrawer.historyPopup.batteryHistoryPopup.table.content.lowBat", { b: batZ })
        : dateNewBat
          ? t("drawer.listDrawer.historyPopup.batteryHistoryPopup.table.content.newBat")
          : t("drawer.listDrawer.historyPopup.batteryHistoryPopup.table.content.measure", { b: batZ }),
      softwareVersion,
    };
  });

  return (
    transformedData.length > 0 && (
      <Table
        data={transformedData}
        className={tableStyle()}
        initialState={{
          sorting: [{ id: "date", desc: true }],
        }}
        columns={[
          {
            accessorKey: "date",
            header: t("drawer.listDrawer.historyPopup.batteryHistoryPopup.table.header.date"),
            cell: ({
              row: {
                original: { date },
              },
            }) => (date ? dayjs(date).format(FORMAT_DATE.batteryHistory) : "-"),
          },
          {
            accessorKey: "info",
            header: t("drawer.listDrawer.historyPopup.batteryHistoryPopup.table.header.info"),
            enableSorting: false,
          },
          {
            accessorKey: "softwareVersion",
            header: t("drawer.listDrawer.historyPopup.batteryHistoryPopup.table.header.softwareVersion"),
            enableSorting: false,
            cell: ({
              row: {
                original: { softwareVersion },
              },
            }) => softwareVersion ?? "-",
          },
        ]}
      />
    )
  );
};

/* -------------------------------------------------------------------------------------------------
 * BatteryHistoryPopup
 * -----------------------------------------------------------------------------------------------*/
interface BatteryHistoryPopupProps extends RootProps {
  mac: string;
}

const BatteryHistoryPopup: React.FC<BatteryHistoryPopupProps> = ({ mac, children, ...props }) => {
  const bem = useBem(styles);
  const batteryHistoryPopupStyle = bem("battery-history-popup");

  const { t } = useTranslation();

  const [range, setRange] = useState<Popup.FormValues["dateRange"]>({
    from: dayjs()
      .subtract(parseInt(HISTORY.BATTERY.initial[0]), HISTORY.BATTERY.initial[1] as UnitTypeShort)
      .toDate(),
    to: dayjs().toDate(),
  });

  const { data } = useQuery({
    ...queries.history.battery(range.from.toISOString(), range.to.toISOString(), mac),
    staleTime: dayjs(range.to).isBefore(dayjs()) ? Infinity : 0,
  });

  const handleSubmit = (data: Popup.FormValues) => setRange(data.dateRange);

  const orderedData = orderBy(
    data?.filter(d => d.batLowDate ?? d.batZ ?? d.dateNewBat),
    "createdAt",
    "desc",
  );

  return (
    <Popup.Root {...props}>
      <Popup.Content
        limit={HISTORY.BATTERY.limit}
        inital={HISTORY.BATTERY.initial}
        handleSubmit={handleSubmit}
        title={t("drawer.listDrawer.historyPopup.batteryHistoryPopup.title")}
        className={batteryHistoryPopupStyle()}
      >
        <Content data={orderedData} />
      </Popup.Content>
      <Popup.Trigger asChild>{children}</Popup.Trigger>
    </Popup.Root>
  );
};

export { BatteryHistoryPopup };
export type { BatteryHistoryPopupProps };
