import { merge } from "lodash";

import type { Permission } from "..";
import { managerPermissions } from "./manager";

export const managerBoilerRoomPermissions: Permission = merge<Permission, Permission>(
  {
    valve: {
      heatingNetworkId: { read: true },
    },
    module: {
      boilerroom: {
        read: true,
      },
    },
    page: {
      boilerroom: true,
      consumption: true,
      gtb: true,
    },
  },
  managerPermissions,
);
