import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import type { UnitTypeShort } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import { ActionButton, ActionButtonV2, DatePicker, Dialog, TextField, TextInput } from "@eisox/design-system";
import { ArrowRightIcon, CrossIcon, HistoryIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";

import styles from "./Popup.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
type RootProps = Dialog.RootProps;
const Root = Dialog.Root;
Root.displayName = "HistoryPopUp.Popup";

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/
type TriggerProps = Dialog.TriggerProps;
const Trigger = Dialog.Trigger;
Trigger.displayName = "HistoryPopUp.Trigger";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
const schema = z.object({
  dateRange: z.object({
    from: z.date(),
    to: z.date(),
  }),
});

type FormValues = z.infer<typeof schema>;

interface ContentProps extends Omit<Dialog.ContentProps, "icon"> {
  handleSubmit: (data: FormValues) => void;
  limit: `${number}${UnitTypeShort}`;
  inital: `${number}${UnitTypeShort}`;
}

const Content: React.FC<ContentProps> = ({ limit, inital, handleSubmit, title, className, children, ...props }) => {
  const { t } = useTranslation();

  const bem = useBem(styles);
  const historyPopupStyle = bem("popup");
  const historyPopupContentStyle = bem("content");
  const historyPopupContentFormDateStyle = bem("form-date");

  const {
    handleSubmit: handleSubmitForm,
    formState: { errors, isDirty },
    control,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      dateRange: {
        from: dayjs()
          .subtract(parseInt(inital[0]), inital[1] as UnitTypeShort)
          .toDate(),
        to: dayjs().toDate(),
      },
    },
  });

  const onSubmit = (data: FormValues) => handleSubmit(data);

  return (
    <Dialog.Content
      {...props}
      className={clsx(historyPopupStyle(), className)}
      icon={<HistoryIcon className={historyPopupStyle("icon")} />}
      title={title}
    >
      <div className={historyPopupContentStyle()}>
        <div className={historyPopupContentStyle("header")}>
          <form className={historyPopupContentFormDateStyle()} onSubmit={handleSubmitForm(onSubmit)}>
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { value, onChange } }) => (
                <DatePicker.Root
                  mode="range"
                  format="DD/MM/YYYY"
                  value={value}
                  onChange={onChange}
                  captionLayout="dropdown"
                  disabled={{
                    before: dayjs()
                      .subtract(parseInt(limit[0]), limit[1] as UnitTypeShort)
                      .toDate(),
                    after: dayjs().toDate(),
                  }}
                >
                  <DatePicker.Trigger asChild>
                    {value => (
                      <TextInput
                        value={value}
                        label={t("drawer.listDrawer.historyPopup.date")}
                        className={historyPopupContentFormDateStyle("date-input")}
                      />
                    )}
                  </DatePicker.Trigger>
                  <DatePicker.Content asChild style={{ zIndex: 9999 }}>
                    <DatePicker.Calendar />
                  </DatePicker.Content>
                </DatePicker.Root>
              )}
            />

            <ActionButton
              icon={<ArrowRightIcon />}
              variant="primary"
              type="submit"
              disabled={!isDirty || !!Object.keys(errors).length}
              className={historyPopupContentFormDateStyle("button")}
            />
          </form>
          {errors.dateRange?.message && <p>{errors.dateRange.message}</p>}
        </div>

        {children}

        <div className={historyPopupContentStyle("footer")}>
          <Dialog.Close asChild>
            <ActionButtonV2 rounded variant="primary">
              {t("drawer.listDrawer.historyPopup.close")}
              <CrossIcon style={{ width: 10, height: 10 }} />
            </ActionButtonV2>
          </Dialog.Close>
        </div>
      </div>
    </Dialog.Content>
  );
};

Content.displayName = "HistoryPopUp.Content";

export { Content, Root, Trigger };
export type { ContentProps, FormValues, RootProps, TriggerProps };
