import { intl } from "~/i18n";

import { replaceGatewayName } from "../Gateways/utils";

import { cloneDeep, orderBy } from "lodash";
import { toast } from "react-toastify";
import * as Yup from "yup";

export interface WithUid {
  uid?: number;
}

/**
 * Offset the UIDs of items in an array starting from a specified UID.
 * @param items - The array of items with UIDs.
 * @param startUid - The UID to start the offset from.
 * @returns A list of uids, the first of which is the uid of element startUid, and the next of which is the uid of n-1 + 1
 */
export const offsetUid = <T extends WithUid>(items: T[], startUid: number): T[] => {
  return items.reduce<T[]>(
    (acc, item, index) => [...acc, { ...item, uid: index === 0 ? startUid : acc[index - 1].uid! + 1 }],
    [],
  );
};

/**
 * Reorders items in an array based on their unique identifiers.
 * Replace the gatewayName if it exists.
 *
 * @template T - The type of items in the array.
 * @param items - The array of items to be reordered.
 * @param startUid - The unique identifier of the item to be moved.
 * @param targetUid - The unique identifier of the target position for the item.
 * @returns The reordered array of items.
 */

type reOrderItemsUidType = WithUid & { gatewayName?: string };

export const reOrderItemsUid = <T extends reOrderItemsUidType>(items: T[], startUid: number, targetUid: number) => {
  const indexWithStartUid = items.findIndex(item => item.uid === startUid);
  const indexWithTargetUid = items.findIndex(item => item.uid === targetUid);

  if (indexWithStartUid === indexWithTargetUid) return items;
  else if (indexWithTargetUid === -1 || indexWithStartUid === -1) {
    toast.error(
      intl.formatMessage({ id: "plan.actions.preinstall.errors.uid.notFoundItems" }, { n: startUid, n2: targetUid }),
    );
    return;
  }
  const copyItems = cloneDeep(items);
  if (copyItems[indexWithStartUid].gatewayName) {
    copyItems[indexWithStartUid].gatewayName = replaceGatewayName(copyItems[indexWithStartUid], targetUid);
  }
  copyItems[indexWithStartUid].uid = targetUid;

  if (startUid > targetUid) {
    for (let i = indexWithStartUid - 1; i >= indexWithTargetUid; i--) {
      if (copyItems[i].gatewayName) copyItems[i].gatewayName = replaceGatewayName(copyItems[i], copyItems[i].uid! + 1);
      copyItems[i].uid!++;
    }
  } else if (startUid < targetUid) {
    for (let i = indexWithStartUid + 1; i <= indexWithTargetUid; i++) {
      if (copyItems[i].gatewayName) copyItems[i].gatewayName = replaceGatewayName(copyItems[i], copyItems[i].uid! - 1);
      copyItems[i].uid!--;
    }
  }

  return orderBy(copyItems, ["uid"]);
};

export interface withProperties {
  uid?: number;
  id: string;
}

/**
 * Returns a Yup schema for validating a uid field.
 * @param nextUid The next uid value.
 * @param fields An array of objects with properties.
 * @returns A Yup object schema.
 */
export const schemaUid = <T extends withProperties>(nextUid: number, fields: T[]) => {
  return Yup.object({
    uid: Yup.number()
      .transform(value => (Number.isNaN(value) ? undefined : value))
      .required(intl.formatMessage({ id: "error.required" }))
      .min(nextUid, intl.formatMessage({ id: "plan.actions.preinstall.errors.uid.upperThanMinUid" }, { n: nextUid }))
      .test(
        "max-uid",
        intl.formatMessage(
          { id: "plan.actions.preinstall.errors.uid.lowerThanMaxUid" },
          { n: fields[fields.length - 1]?.uid! + 1 || nextUid },
        ),
        (value, context) =>
          value <=
          (fields.find(f => f.id === context.parent.id)
            ? fields[fields.length - 1]?.uid!
            : fields[fields.length - 1]?.uid! + 1 || nextUid),
      )
      .test(
        "is-unique",
        intl.formatMessage({ id: "plan.actions.preinstall.errors.uid.alreadyExist" }),
        (value, context) =>
          fields.find(f => f.id === context.parent.id) ? true : !fields.map(f => f.uid).includes(value),
      ),
  });
};
