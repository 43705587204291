import * as React from "react";

import { IconProps } from "./types";

export const BracesIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M2.8 4.75V6.05001C2.8 6.40751 2.5075 6.70001 2.15 6.70001H1.5V9.30001H2.15C2.5075 9.30001 2.8 9.59251 2.8 9.95001V11.25C2.8 12.3225 3.6775 13.2 4.75 13.2H6.7V11.9H4.75C4.3925 11.9 4.1 11.6075 4.1 11.25V9.95001C4.1 9.10501 3.554 8.37701 2.8 8.11051V7.88951C3.554 7.62301 4.1 6.89501 4.1 6.05001V4.75C4.1 4.3925 4.3925 4.1 4.75 4.1H6.7V2.8H4.75C3.6775 2.8 2.8 3.6775 2.8 4.75ZM13.85 6.70001C13.4925 6.70001 13.2 6.40751 13.2 6.05001V4.75C13.2 3.6775 12.3225 2.8 11.25 2.8H9.3V4.1H11.25C11.6075 4.1 11.9 4.3925 11.9 4.75V6.05001C11.9 6.89501 12.446 7.62301 13.2 7.88951V8.11051C12.446 8.37701 11.9 9.09851 11.9 9.95001V11.25C11.9 11.6075 11.6075 11.9 11.25 11.9H9.3V13.2H11.25C12.3225 13.2 13.2 12.3225 13.2 11.25V9.95001C13.2 9.59251 13.4925 9.30001 13.85 9.30001H14.5V6.70001H13.85Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default BracesIcon;
