import { ActionFunctionArgs, redirect } from "react-router-dom";

import {
  HousesHouseIdPlansPlanIdPatch200Response,
  HousesHouseIdPlansPost200ResponseMessage,
} from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { loading } from "~/UI/layouts/LoadingPopup";
import { editPlan, removePlan } from "~/api/plan";
import { RestError } from "~/constants";
import { SUCCESS_FETCH, getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { FetchResponse } from "~/helpers/communication/fetchType";
import { routeToPlan, routeToPlans } from "~/routes/utils";
import { API } from "~/types/API";

export const action = async ({
  request,
  params,
}: ActionFunctionArgs): Promise<API.Response<HousesHouseIdPlansPlanIdPatch200Response | null> | Response> => {
  const { houseId, planId } = params as { houseId: string; planId: string };
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.PATCH:
      const patchResponse = (await editPlan(
        houseId,
        planId,
        formData,
      )) as unknown as FetchResponse<HousesHouseIdPlansPlanIdPatch200Response>;
      const postStatus = getHttpStatusByFetchStatus(patchResponse.type) as unknown as API.HTTP_STATUS;
      return {
        date: dayjs(),
        restError: patchResponse.type as RestError | undefined,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [{ status: postStatus, message: patchResponse.result }],
      };
    case API.HTTP_METHOD.DELETE:
      const deleteResponse = (await removePlan(planId, houseId)) as FetchResponse<undefined>;
      if (deleteResponse.type !== SUCCESS_FETCH) {
        const deleteStatus = getHttpStatusByFetchStatus(deleteResponse.type) as unknown as API.HTTP_STATUS;
        return {
          date: dayjs(),
          restError: deleteResponse.type as RestError,
          url: request.url,
          method: API.HTTP_METHOD.DELETE,
          message: [{ status: deleteStatus, message: deleteResponse.result }],
        };
      }
      loading(false);
      return redirect(routeToPlans(houseId));
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
