import type { LoaderFunctionArgs } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import dayjs from "@eisox/dayjs";

import { getBoilerRoomConsumption } from "~/api/boilerRoomConso";
import type { BoilerRoomHistoryBody, BoilerroomHistoryResponseType } from "~/api/boilerRoomHistory";
import { getBoilerRoomHistory } from "~/api/boilerRoomHistory";
import type { ModuleHistory } from "~/apiV2";
import { env } from "~/configuration";
import { MODE_ENV } from "~/constants/appConstantV2";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import { getBoilerRoomObject, getDataFromHistoryResponse, getUnitAxesMapping } from "~/features/BMSHistory";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { intl } from "~/i18n";
import type { BoilerRoom } from "~/socketio/types";
import { useHistoryStore } from "~/stores";

import { View } from "./types";
import {
  filterConsumptionScheme,
  getConsumption,
  getDateRangeWithPrevRange,
  getOptions,
  getUnitAxesMappingWithComparison,
} from "./utils";

const getMostRecentDatePerModuleGroup = (dates: BoilerroomHistoryResponseType["message"]) => {
  const recentDatesMap = new Map<string, { moduleIds: string[]; createdAt: string }>();

  dates.forEach(date => {
    const { createdAt, moduleIds } = date as { createdAt: string; moduleIds: string[] };
    const sortedModuleIds = moduleIds.slice().sort();
    const key = sortedModuleIds.join("-");
    const currentMax = recentDatesMap.get(key);

    if (!currentMax || dayjs(createdAt).isAfter(dayjs(currentMax.createdAt))) {
      recentDatesMap.set(key, { moduleIds: sortedModuleIds, createdAt: createdAt });
    }
  });

  return Array.from(recentDatesMap.values());
};

const getBody = (
  paths: string[],
  ranges: { startDate: string; endDate: string }[],
  moduleIds: string[],
): BoilerRoomHistoryBody => {
  return {
    ranges: ranges,
    fields: ["createdAt"],
    ...getBoilerRoomObject(paths),
    moduleIds,
  };
};

const fetchLast90DaysDates = async (houseId: string, moduleIds: string[]) => {
  const response = await getBoilerRoomHistory(houseId, {
    ranges: [
      {
        startDate: dayjs().subtract(90, "day").startOf("day").toISOString(),
        endDate: dayjs().startOf("day").toISOString(),
      },
    ],
    moduleIds,
    fields: ["createdAt", "moduleIds"],
  });
  if (response.type === SUCCESS_FETCH) {
    const message = response.result.message;
    return message;
  } else {
    toast.error(intl.formatMessage({ id: "error.unKnowError.message" }));
  }
  return [];
};

const getBoilerRoomSchema = async (houseId: string, moduleIds: string[]): Promise<BoilerRoom[] | undefined> => {
  const dates = await fetchLast90DaysDates(houseId, moduleIds);
  if (dates.length > 0) {
    const mostRecentDatePerModule = getMostRecentDatePerModuleGroup(dates);
    const boilerRooms = await Promise.all(
      mostRecentDatePerModule.flatMap(async group => {
        const response = await getBoilerRoomHistory(houseId, {
          ranges: [{ startDate: group.createdAt, endDate: group.createdAt }],
          moduleIds: group.moduleIds,
        });
        if (response.type === SUCCESS_FETCH) {
          return response.result.message[0].boilerRooms!;
        } else {
          toast.error(intl.formatMessage({ id: "error.unKnowError.message" }));
          return [];
        }
      }),
    );
    return boilerRooms.flat();
  }
  return undefined;
};

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  if (env.MODE === MODE_ENV.PROD) throw Error();

  const houseId = params.houseId!;

  const url = new URL(request.url);
  const paths = url.searchParams.get("paths");
  const view = url.searchParams.get("view");
  const comparison = url.searchParams.get("comparison");
  const moduleIds = url.searchParams.get("moduleIds");

  const defaultView = yup.string().oneOf(Object.values(View)).isValidSync(view) ? view : View.HOUR;
  const defaultComparison = comparison ? comparison.split(",") : [getOptions(defaultView)[0].value];
  const defaultModuleIds = moduleIds ? moduleIds.split(",") : [];
  const defaultPaths = paths ? paths.split(",") : [];

  const compare = defaultComparison.length > 1;

  const dateRanges = getDateRangeWithPrevRange(defaultView, defaultComparison);

  const schema = useHistoryStore.getState().consumption;
  let data: Record<string, number | string>[] = [];
  let unitAxesMapping: Record<string, string[]> = {};

  if (defaultModuleIds.length > 0) {
    if (schema[houseId] === undefined || schema[houseId].length === 0) {
      schema[houseId] = (await getBoilerRoomSchema(houseId, defaultModuleIds)) ?? [];
      useHistoryStore.getState().addConsumption(schema);
    }
    if (defaultPaths.length > 0) {
      unitAxesMapping = getUnitAxesMapping(defaultPaths, schema[houseId] as ModuleHistory["boilerRooms"]);
      if (compare) unitAxesMapping = getUnitAxesMappingWithComparison(unitAxesMapping, defaultView, defaultComparison);
      const body = getBody(defaultPaths, dateRanges, defaultModuleIds);
      let response: FetchResponse<BoilerroomHistoryResponseType>;
      if (defaultView === View.HOUR) {
        response = await getBoilerRoomHistory(houseId, body);
      } else {
        response = await getBoilerRoomConsumption(houseId, defaultView, body);
      }
      if (response.type === SUCCESS_FETCH) {
        const dataFromHistoryResponse = getDataFromHistoryResponse(
          defaultPaths,
          response.result.message as ModuleHistory[],
          schema[houseId] as ModuleHistory["boilerRooms"],
        );
        data = getConsumption(dataFromHistoryResponse, defaultView, compare);
      } else {
        toast.error(intl.formatMessage({ id: "error.unKnowError.message" }));
      }
    }
  }

  const filteredSchema = filterConsumptionScheme(schema[houseId]);

  return { schema: filteredSchema, data, unitAxesMapping, defaultView, defaultComparison, defaultPaths };
};
