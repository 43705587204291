import React, { useMemo, useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Link, useParams, useRouteLoaderData } from "react-router-dom";
import * as yup from "yup";

import { PatchRooms } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import { ActionButton, Alert, Steps, Switch } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";

import { houseLoader } from "~/UI/screens";
import { Periods } from "~/UI/screens/Settings/layouts";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToFrostFreeSettings, routeToRoomsAction } from "~/routes/utils";
import { API } from "~/types/API";
import { ErrorType } from "~/types/ReactHookFormType";

import { RoomsSelectors } from "../../layouts";
import { getFrostFreePeriods, getRoomPatchFromRooms, mergeFrostFreePeriods } from "./utils";

import styles from "./FrostFree.module.scss";

const DATE_FORMAT = "YYYY-MM-DD";

const schema = (formatMessage: FormatMessageFn) =>
  yup.object({
    frostFreePeriods: yup.array().of(
      yup
        .object({
          begin: yup.string().required(),
          end: yup.string().required(),
          added: yup.boolean().default(false),
          removed: yup.boolean().default(false),
        })
        .test("isBeginBeforeEnd", formatMessage({ id: "settings.content.addPeriod.startDateAfterEndDate" }), value => {
          const { begin, end, removed } = value;
          return removed || begin === end || dayjs(begin, DATE_FORMAT).isBefore(dayjs(end, DATE_FORMAT));
        }),
    ),
  });

export type RoomFrostFreePeriods = yup.InferType<ReturnType<typeof schema>>;

export const FrostFree: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const frostFreeStyle = bem("frost-free");

  const { houseId } = useParams() as { houseId: string };
  const { rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;

  const [selectedRoomIds, setSelectedRoomIds] = useState<string[]>([]);
  const [merge, setMerge] = useState(true);

  const selectedRooms = React.useMemo(
    () => rooms.filter(room => selectedRoomIds.includes(room.id!)),
    [rooms, selectedRoomIds],
  );
  const frostFreePeriods = getFrostFreePeriods(selectedRooms);
  const initialState = useMemo(() => {
    return { frostFreePeriods: frostFreePeriods.length === 1 ? frostFreePeriods[0].frostFreePeriods : [] };
  }, [frostFreePeriods]);

  const { Form, submit } = useAction({
    onSuccess: () => setMerge(true),
  });

  const {
    control,
    formState: {
      isDirty,
      errors: { frostFreePeriods: frostFreePeriodsErrors },
    },
    handleSubmit,
    watch,
  } = useForm<RoomFrostFreePeriods>({
    resolver: yupResolver(schema(formatMessage)),
    values: initialState,
  });

  const currentFrostFreePeriods = watch("frostFreePeriods");

  const { fields, append, remove, update } = useFieldArray({ control, name: "frostFreePeriods" });

  const result = merge
    ? mergeFrostFreePeriods(
        selectedRooms.map(r => ({
          ...r,
          frostFreePeriods: r.frostFreePeriods?.filter(
            p => !currentFrostFreePeriods?.some(cp => cp.begin === p.begin && cp.end === p.end && cp.removed),
          ),
        })),
        (currentFrostFreePeriods?.filter(p => !p.removed) ?? []).map(p => ({ begin: p.begin, end: p.end })),
      )
    : selectedRooms.map(r => ({
        ...r,
        frostFreePeriods: currentFrostFreePeriods?.filter(ffp => !ffp.removed),
      }));

  const onSubmit = () => {
    const body: PatchRooms[] = getRoomPatchFromRooms(result);
    submit(body, API.HTTP_METHOD.PATCH, routeToRoomsAction(houseId));
  };

  return (
    <Form className={frostFreeStyle()} onSubmit={handleSubmit(onSubmit)}>
      <Alert severity="help">
        {formatMessage(
          { id: "settings.content.menu.rooms.frostFree.building" },
          {
            a: (
              <Link to={routeToFrostFreeSettings(houseId)}>
                {formatMessage({ id: "settings.content.menu.rooms.frostFree.seeParameter" })}
              </Link>
            ),
          },
        )}
      </Alert>
      <Steps>
        <Steps.Step title={formatMessage({ id: "settings.content.menu.rooms.frostFree.steps.selectRooms" })}>
          <RoomsSelectors onChange={setSelectedRoomIds} />
          <div className={frostFreeStyle("selected-rooms")}>
            {selectedRooms.map((r, i) => (
              <React.Fragment key={i}>
                <p>{r.name}</p>
                <p>
                  {r?.frostFreePeriods
                    ?.map(p => `${dayjs(p.begin).format("DD/MM/YYYY")} - ${dayjs(p.end).format("DD/MM/YYYY")}`)
                    .join(", ")}
                </p>
              </React.Fragment>
            ))}
          </div>
        </Steps.Step>
        <Steps.Step title={formatMessage({ id: "settings.content.menu.rooms.frostFree.steps.newFrostFreePeriods" })}>
          <label className={frostFreeStyle("label")}>
            {formatMessage({ id: "settings.content.menu.rooms.frostFree.eraseOldPeriods" })}
            <Switch checked={!merge} onCheckedChange={checked => setMerge(!checked as boolean)} />
          </label>
          <Periods
            className={frostFreeStyle("periods")}
            periods={fields}
            onAddPeriod={append}
            onRemovePeriod={remove}
            onUpdatePeriod={update}
            errors={frostFreePeriodsErrors as ErrorType[]}
          />
          <div className={frostFreeStyle("selected-rooms")}>
            {result.map((r, i) => (
              <React.Fragment key={i}>
                <p>{r.name}</p>
                <p>
                  {r?.frostFreePeriods
                    ?.map(p => `${dayjs(p.begin).format("DD/MM/YYYY")} - ${dayjs(p.end).format("DD/MM/YYYY")}`)
                    .join(", ")}
                </p>
              </React.Fragment>
            ))}
          </div>
        </Steps.Step>
        <Steps.Step title={formatMessage({ id: "settings.content.menu.rooms.frostFree.steps.save" })}>
          <ActionButton
            type="submit"
            text={formatMessage({ id: "settings.content.menu.rooms.frostFree.save" })}
            icon={<ArrowRightIcon />}
            disabled={!isDirty}
          />
        </Steps.Step>
      </Steps>
    </Form>
  );
};
