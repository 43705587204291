import { useTranslation } from "react-i18next";

import { ActionButtonV2 as ActionButton, Dialog } from "@eisox/design-system";
import { ArrowRightIcon, QuestionMarkIcon, UserIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { UserRole } from "~/utils";

import { Role } from "../Role";

import styles from "./HelpPopup.module.scss";

export const HelpPopup: React.FC = () => {
  const { t } = useTranslation();

  const bem = useBem(styles);
  const helpPopupStyle = bem("help-popup");
  const tableStyle = bem("table");

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <div className={helpPopupStyle("trigger")}>
          <QuestionMarkIcon />
        </div>
      </Dialog.Trigger>
      <Dialog.Content className={helpPopupStyle()} icon={<UserIcon className={helpPopupStyle("icon")} />}>
        <div className={helpPopupStyle("content")}>
          <div className={tableStyle()}>
            {/* line 1 */}
            <div />
            <div>
              <Role circle={false} role={UserRole.INSTALLER} />
            </div>
            <div>
              <Role circle={false} role={UserRole.OWNER} />
            </div>
            <div>
              <Role circle={false} role={UserRole.MANAGER} />
            </div>
            <div>
              <Role circle={false} role={UserRole.VISITOR} />
            </div>
            <div>
              <Role circle={false} role={UserRole.API} />
            </div>
            {/* line 2 */}
            <p>{t("users.help.userManagment")}</p>
            <div>
              <Role role={UserRole.INSTALLER} size={14} />
            </div>
            <div>
              <Role role={UserRole.OWNER} size={14} />
            </div>
            <div />
            <div />
            <div />
            {/* line 3 */}
            <p>{t("users.help.buildingDataAccess")}</p>
            <div>
              <Role role={UserRole.INSTALLER} size={14} />
            </div>
            <div>
              <Role role={UserRole.OWNER} size={14} />
            </div>
            <div>
              <Role role={UserRole.MANAGER} size={14} />
            </div>
            <div>
              <Role role={UserRole.VISITOR} size={14} />
              <p className={tableStyle("exception")}>{t("users.help.maintenanceException")}</p>
            </div>
            <div />

            {/* line 4 */}
            <p>{t("users.help.comfortTemperatureUpdate")}</p>
            <div>
              <Role role={UserRole.INSTALLER} size={14} />
            </div>
            <div>
              <Role role={UserRole.OWNER} size={14} />
            </div>
            <div>
              <Role role={UserRole.MANAGER} size={14} />
            </div>
            <div />
            <div />
            {/* line 5 */}
            <p>{t("users.help.userPlanningManagment")}</p>
            <div>
              <Role role={UserRole.INSTALLER} size={14} />
            </div>
            <div>
              <Role role={UserRole.OWNER} size={14} />
            </div>
            <div>
              <Role role={UserRole.MANAGER} size={14} />
            </div>
            <div />
            <div />
            {/* line 6 */}
            <p>{t("users.help.productLocationManagment")}</p>
            <div>
              <Role role={UserRole.INSTALLER} size={14} />
            </div>
            <div>
              <Role role={UserRole.OWNER} size={14} />
            </div>
            <div />
            <div />
            <div />
            {/* line 7 */}
            <p>{t("users.help.newProductAddition")}</p>
            <div>
              <Role role={UserRole.INSTALLER} size={14} />
            </div>
            <div>
              <Role role={UserRole.OWNER} size={14} />
            </div>
            <div />
            <div />
            <div />
            {/* line 8 */}
            <p>{t("users.help.apiAccess")}</p>
            <div />
            <div />
            <div />
            <div />
            <div>
              <Role role={UserRole.API} size={14} />
            </div>
          </div>
        </div>
        <Dialog.Close asChild>
          <ActionButton className={helpPopupStyle("close")} rounded>
            {t("users.help.close")}
            <ArrowRightIcon />
          </ActionButton>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  );
};
