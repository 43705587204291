import { RoomRoomType } from "@eisox/webapp-api-specification";
import { faker } from "@faker-js/faker";

import type { RoomsWithProblem } from "~/UI/screens/House";
import type { Room } from "~/apiV2";
import { DEFAULT_TEMPERATURES } from "~/constants/appConstantV2";

import { Builder } from "../utils";

export class RoomBuilder extends Builder<Room> {
  constructor() {
    super({
      id: faker.database.mongodbObjectId(),
      name: faker.lorem.word(3),
      groupNames: [],
      isSwitchEnabled: faker.datatype.boolean(),
      isFrostFree: faker.datatype.boolean(),
      isPreComfRoom: faker.datatype.boolean(),
      isAutoPrecomf: faker.datatype.boolean(),
      plan: {
        planId: faker.database.mongodbObjectId(),
        x: faker.number.int({ min: 10, max: 90 }),
        y: faker.number.int({ min: 10, max: 90 }),
      },
      comfortTemperature: DEFAULT_TEMPERATURES.COMFORT,
      nightTemperature: DEFAULT_TEMPERATURES.NIGHT,
      roomType: faker.helpers.enumValue(RoomRoomType),
    });
  }
}

export class RoomWithProblemBuilder extends Builder<RoomsWithProblem> {
  constructor() {
    const room = new RoomBuilder().build();
    super({
      ...room,
      plan: {
        ...room.plan,
        planName: faker.lorem.word(),
      },
      errors: [],
      warnings: [],
      problemStatus: undefined,
    });
  }
}
