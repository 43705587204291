import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import * as RadixTooltip from "@radix-ui/react-tooltip";

import styles from "./Tooltip.module.scss";

/** --------------------------------------------------------------------------------------------------------------------
 * TooltipProvider
 * ------------------------------------------------------------------------------------------------------------------ */

type ProviderProps = RadixTooltip.TooltipProviderProps;

const Provider = RadixTooltip.Provider;

Provider.displayName = "TooltipProvider";

/** --------------------------------------------------------------------------------------------------------------------
 * Tooltip
 * ------------------------------------------------------------------------------------------------------------------ */

type TooltipProps = RadixTooltip.TooltipProps;

const Root = RadixTooltip.Root;

Root.displayName = "Tooltip";

/** --------------------------------------------------------------------------------------------------------------------
 * TooltipTrigger
 * ------------------------------------------------------------------------------------------------------------------ */

type TriggerProps = RadixTooltip.TooltipTriggerProps;

const Trigger = RadixTooltip.Trigger;

Trigger.displayName = "TooltipTrigger";

/** --------------------------------------------------------------------------------------------------------------------
 * TooltipPortal
 * ------------------------------------------------------------------------------------------------------------------ */

type PortalProps = RadixTooltip.TooltipPortalProps;

const Portal = RadixTooltip.Portal;

Portal.displayName = "TooltipPortal";

/** --------------------------------------------------------------------------------------------------------------------
 * TooltipContent
 * ------------------------------------------------------------------------------------------------------------------ */

type ContentProps = RadixTooltip.TooltipContentProps;

const Content = forwardRef<HTMLDivElement, ContentProps>(({ className, children, ...props }, forwardedRed) => (
  <RadixTooltip.Content {...props} ref={forwardedRed} className={cx(styles.tooltip__content, className)}>
    {children}
  </RadixTooltip.Content>
));

Content.displayName = "TooltipContent";

/** --------------------------------------------------------------------------------------------------------------------
 * TooltipArrow
 * ------------------------------------------------------------------------------------------------------------------ */

type ArrowProps = RadixTooltip.TooltipArrowProps;

const Arrow = forwardRef<SVGSVGElement, ArrowProps>(({ className, ...props }, forwardedRed) => (
  <RadixTooltip.Arrow {...props} ref={forwardedRed} className={cx(styles.tooltip__arrow, className)} />
));

Arrow.displayName = "TooltipArrow";

export { Provider, Root, Trigger, Portal, Content, Arrow };
export type { ProviderProps, TooltipProps, TriggerProps, PortalProps, ContentProps, ArrowProps };
