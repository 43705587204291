import clsx from "clsx";

import type { Dayjs } from "@eisox/dayjs";
import type { DateFieldProps } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";

import "./styles.scss";

export interface DateInputProps extends DateFieldProps<Dayjs> {
  adapterLocale?: string;
  className?: string;
}
export const DateInput: React.FC<DateInputProps> = ({ className, adapterLocale, format = "DD/MM", ...props }) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={adapterLocale ?? "fr"}
      localeText={{
        fieldDayPlaceholder: _ => "--",
        fieldMonthPlaceholder: _ => "--",
        fieldYearPlaceholder: _ => "----",
      }}
    >
      <DateField {...props} className={clsx("date-input", className)} format={format} />
    </LocalizationProvider>
  );
};
