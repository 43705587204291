import dayjs from "@eisox/dayjs";

import type { Gateway as ApiGateway, Plan as ApiPlan, Room as ApiRoom, Valve as ApiValve } from "~/apiV2";

import type { Gateway, Plan, Room, Valve } from "../types";

const apiValveToValveMapper = (valve: ApiValve, gateways: ApiGateway[]): Valve => ({
  id: valve.id,
  uid: valve.uid,
  mac: valve.mac,
  gateway: valve.gatewayId,
  room: valve.roomId,
  plan: {
    id: valve.plan.planId,
    x: valve.plan.x,
    y: valve.plan.y,
  },
  ...(valve.stateData?.hslopeMinC
    ? {
        hslope: {
          value: valve.stateData.hslopeMinC,
          date: dayjs(valve.updatedAt).format("LT - L"),
        },
      }
    : {}),
  ...(valve.maxOpening ? { maxOpening: { value: valve.maxOpening.value, date: valve.maxOpening.lastChange } } : {}),
  softwareVersion: valve.softwareVersion,
  gatewaySoftwareVersion: gateways.find(g => g.id === valve.gatewayId)?.softwareVersion,
});

const apiGatewayToGatewayMapper = (gateway: ApiGateway): Gateway => ({
  id: gateway.id,
  name: gateway.gatewayName,
  plan: {
    id: gateway.plan.planId,
    x: gateway.plan.x,
    y: gateway.plan.y,
  },
});

const apiRoomToRoomMapper = (room: ApiRoom): Room => ({
  id: room.id,
  name: room.name,
  plan: {
    id: room.plan.planId,
    x: room.plan.x,
    y: room.plan.y,
  },
});

const apiPlanToPlanMapper = (plan: ApiPlan): Plan => ({
  id: plan.id,
  name: plan.name,
});

const apiValvesToValvesMapper = (valves: ApiValve[], gateways: ApiGateway[]): Valve[] =>
  valves.map(v => apiValveToValveMapper(v, gateways));

const apiGatewaysToGatewaysMapper = (gateways: ApiGateway[]): Gateway[] => gateways.map(apiGatewayToGatewayMapper);

const apiRoomsToRoomsMapper = (rooms: ApiRoom[]): Room[] => rooms.map(apiRoomToRoomMapper);

const apiPlansToPlansMapper = (plans: ApiPlan[]): Plan[] => plans.map(apiPlanToPlanMapper);

export { apiGatewaysToGatewaysMapper, apiPlansToPlansMapper, apiRoomsToRoomsMapper, apiValvesToValvesMapper };
