import type { TranslateDefect } from "@eisox/problems-handler";
import { DefectType, ObjectType, translateDefect } from "@eisox/problems-handler";

import { i18next } from "~/i18n";
import type { Boiler, BoilerRoom, HeatingNetwork, Pumps, SludgeRemover } from "~/socketio/types";

interface BoilerRoomError {
  boilerRoomId: string;
  boilerRoomName: string;
  message: string;
}

const getMessage = (props: Omit<TranslateDefect, "language">) =>
  translateDefect({ ...props, language: i18next.language as "fr" | "en" });

const getPumpsErrors = (props: {
  objectType: ObjectType;
  pumps: Pumps;
  boilerName?: string;
  heatingNetworkName?: string;
}): string[] => {
  const { objectType, pumps, boilerName, heatingNetworkName } = props;
  const errors: string[] = [];

  const getPumpsErrorMessage = (defectType: DefectType) =>
    getMessage({ objectType, defectType, boilerName, heatingNetworkName });

  if (pumps.defectP1) {
    errors.push(getPumpsErrorMessage(DefectType.DEFECT_P1));
  }

  if (pumps.defectP2) {
    errors.push(getPumpsErrorMessage(DefectType.DEFECT_P2));
  }

  if (pumps.missmatchDefectP1) {
    errors.push(getPumpsErrorMessage(DefectType.MISS_MATCH_DEFECT_P1));
  }

  if (pumps.missmatchDefectP2) {
    errors.push(getPumpsErrorMessage(DefectType.MISS_MATCH_DEFECT_P2));
  }

  return errors;
};

const getBoilerErrors = (boiler: Boiler): string[] => {
  const errors: string[] = [];

  const getBoilerErrorMessage = (
    props: Omit<Parameters<typeof getMessage>[0], "objectType" | "boilerName" | "heatingNetworkName">,
  ) => getMessage({ ...props, objectType: ObjectType.BOILER, boilerName: boiler.name });

  if (boiler.ashPanDefect) {
    errors.push(getBoilerErrorMessage({ defectType: DefectType.ASH_PAN_DEFECT }));
  }

  if (boiler.defectBurner) {
    errors.push(getBoilerErrorMessage({ defectType: DefectType.DEFECT_BURNER }));
  }

  if (boiler.defect) {
    errors.push(getBoilerErrorMessage({ defectType: DefectType.DEFECT }));
  }

  if (boiler.missmatchDefect) {
    errors.push(getBoilerErrorMessage({ defectType: DefectType.MISS_MATCH_DEFECT }));
  }

  if (boiler.communicationDefect) {
    errors.push(
      getBoilerErrorMessage({
        defectType: DefectType.COMMUNICATION_DEFECT,
      }),
    );
  }

  if (boiler.twoWaysValve?.defect) {
    errors.push(
      getMessage({
        objectType: ObjectType.TWO_WAYS_VALVE,
        defectType: DefectType.DEFECT,
        boilerName: boiler.name,
      }),
    );
  }

  if (boiler.pumps) {
    errors.push(
      ...getPumpsErrors({ objectType: ObjectType.BOILER_PUMP, pumps: boiler.pumps, boilerName: boiler.name }),
    );
  }

  return errors;
};

const getBoilersErrors = (boilers: Boiler[]): string[] => {
  const errors: string[] = [];

  boilers.forEach(boiler => {
    errors.push(...getBoilerErrors(boiler));
  });

  return errors;
};

const getHeatingNetworkErrors = (heatingNetwork: HeatingNetwork): string[] => {
  const errors: string[] = [];

  const getHeatingNetworkErrorMessage = (
    props: Omit<Parameters<typeof getMessage>[0], "objectType" | "boilerName" | "heatingNetworkName">,
  ) => getMessage({ ...props, objectType: ObjectType.BOILER, heatingNetworkName: heatingNetwork.name });

  if (heatingNetwork.thermostatDefect) {
    errors.push(getHeatingNetworkErrorMessage({ defectType: DefectType.THERMOSTAT_DEFECT }));
  }

  if (heatingNetwork.threeWaysValve?.defect) {
    errors.push(
      getMessage({
        objectType: ObjectType.THREE_WAYS_VALVE,
        defectType: DefectType.DEFECT,
        heatingNetworkName: heatingNetwork.name,
      }),
    );
  }

  if (heatingNetwork.pumps) {
    errors.push(
      ...getPumpsErrors({
        objectType: ObjectType.HEATING_NETWORK_PUMP,
        pumps: heatingNetwork.pumps,
        heatingNetworkName: heatingNetwork.name,
      }),
    );
  }

  if (heatingNetwork.recyclePumpsECS) {
    errors.push(
      ...getPumpsErrors({
        objectType: ObjectType.RECYCLE_PUMPS_ECS,
        pumps: heatingNetwork.recyclePumpsECS,
        heatingNetworkName: heatingNetwork.name,
      }),
    );
  }

  return errors;
};

const getHeatingNetworksErrors = (heatingNetworks: HeatingNetwork[]): string[] => {
  const errors: string[] = [];

  heatingNetworks.forEach(heatingNetwork => {
    errors.push(...getHeatingNetworkErrors(heatingNetwork));
  });

  return errors;
};

const getSludgeRemoverErrors = (sludgeRemover: SludgeRemover): string[] => {
  const errors: string[] = [];

  if (sludgeRemover.defect) {
    errors.push(getMessage({ objectType: ObjectType.SLUDGE_REMOVER, defectType: DefectType.DEFECT }));
  }

  if (sludgeRemover.pumps) {
    errors.push(...getPumpsErrors({ objectType: ObjectType.SLUDGE_REMOVER_PUMP, pumps: sludgeRemover.pumps }));
  }

  return errors;
};

const getBoilerRoomErrorMessage = (props: Omit<Parameters<typeof getMessage>[0], "objectType">) =>
  getMessage({ ...props, objectType: ObjectType.BOILER_ROOM });

const getBoilerRoomErrors = (boilerRoom: BoilerRoom): BoilerRoomError[] => {
  const errors: BoilerRoomError[] = [];

  const boilerRoomId = boilerRoom.id;
  const boilerRoomName = boilerRoom.name ?? "-";

  if (boilerRoom.gazDefect)
    errors.push({
      boilerRoomId,
      boilerRoomName,
      message: getBoilerRoomErrorMessage({ defectType: DefectType.GAZ_DEFECT }),
    });

  if (boilerRoom.waterDefect) {
    errors.push({
      boilerRoomId,
      boilerRoomName,
      message: getBoilerRoomErrorMessage({ defectType: DefectType.WATER_DEFECT }),
    });
  }

  if (boilerRoom.waterKeeperDefect) {
    errors.push({
      boilerRoomId,
      boilerRoomName,
      message: getBoilerRoomErrorMessage({ defectType: DefectType.WATER_KEEPER_DEFECT }),
    });
  }

  if (boilerRoom.waterTreatmentDefect) {
    errors.push({
      boilerRoomId,
      boilerRoomName,
      message: getBoilerRoomErrorMessage({ defectType: DefectType.WATER_TREATMENT_DEFECT }),
    });
  }

  if (boilerRoom.gazDetectionDefect) {
    errors.push({
      boilerRoomId,
      boilerRoomName,
      message: getBoilerRoomErrorMessage({ defectType: DefectType.GAZ_DETECTION_DEFECT }),
    });
  }

  if (boilerRoom.saltTankDefect) {
    errors.push({
      boilerRoomId,
      boilerRoomName,
      message: getBoilerRoomErrorMessage({ defectType: DefectType.SALT_TANK_DEFECT }),
    });
  }

  if (boilerRoom.externalTempDefect) {
    errors.push({
      boilerRoomId,
      boilerRoomName,
      message: getBoilerRoomErrorMessage({ defectType: DefectType.EXTERNAL_TEMP_DEFECT }),
    });
  }

  if (boilerRoom.recyclePumps) {
    const errorMessages = getPumpsErrors({ objectType: ObjectType.RECYCLE_PUMP, pumps: boilerRoom.recyclePumps });
    errors.push(...errorMessages.map(m => ({ boilerRoomId, boilerRoomName, message: m })));
  }

  if (boilerRoom.sludgeRemover) {
    const errorMessages = getSludgeRemoverErrors(boilerRoom.sludgeRemover);
    errors.push(...errorMessages.map(m => ({ boilerRoomId, boilerRoomName, message: m })));
  }

  if (boilerRoom.boilers) {
    const errorMessages = getBoilersErrors(boilerRoom.boilers);
    errors.push(...errorMessages.map(m => ({ boilerRoomId, boilerRoomName, message: m })));
  }

  if (boilerRoom.heatingNetworks) {
    const errorMessages = getHeatingNetworksErrors(boilerRoom.heatingNetworks);
    errors.push(...errorMessages.map(m => ({ boilerRoomId, boilerRoomName, message: m })));
  }

  return errors;
};

const getErrors = (boilerRooms: BoilerRoom[]): BoilerRoomError[] => {
  const errors: BoilerRoomError[] = [];

  boilerRooms.forEach(boilerRoom => {
    errors.push(...getBoilerRoomErrors(boilerRoom));
  });

  return errors;
};

export { getErrors };
export type { BoilerRoomError };
