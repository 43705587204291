import * as React from "react";

import { IconProps } from "./types";

export const ThreeWayValveIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M12.1252 4.31643L6.96079 7.27694C6.83426 7.35093 6.72939 7.4565 6.65648 7.5833C6.58358 7.7101 6.54523 7.85365 6.54523 7.99968C6.54523 8.14576 6.58358 8.2893 6.65648 8.41611C6.72939 8.54286 6.83426 8.64848 6.96079 8.72247L12.1252 11.683C12.2475 11.7542 12.3867 11.7916 12.5284 11.7916C12.6702 11.7916 12.8094 11.7542 12.9317 11.683C13.059 11.61 13.1646 11.5046 13.2374 11.3775C13.3103 11.2505 13.3478 11.1064 13.3463 10.9602V5.03921C13.3477 4.89307 13.31 4.74917 13.2372 4.62227C13.1644 4.49538 13.0589 4.39006 12.9317 4.31711C12.8094 4.24583 12.6703 4.2082 12.5285 4.20808C12.3868 4.20796 12.2476 4.24536 12.1252 4.31643ZM12.6593 4.78598C12.7036 4.8118 12.7401 4.84884 12.7652 4.89334C12.7903 4.93784 12.803 4.98818 12.8022 5.03921V10.9602C12.803 11.0112 12.7903 11.0616 12.7652 11.1061C12.7401 11.1506 12.7036 11.1876 12.6593 11.2134C12.6196 11.2368 12.5743 11.2491 12.5281 11.2491C12.482 11.2491 12.4366 11.2368 12.3969 11.2134L7.23249 8.2529C7.1885 8.22658 7.15205 8.18937 7.12681 8.1449C7.10151 8.10037 7.08819 8.05016 7.08819 7.99903C7.08819 7.94789 7.10151 7.89768 7.12681 7.85316C7.15205 7.80869 7.1885 7.77148 7.23249 7.7451L12.3969 4.78463C12.4367 4.76146 12.4821 4.74936 12.5283 4.7496C12.5745 4.74983 12.6197 4.7624 12.6593 4.78598Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M10.0089 13.2843L7.03521 8.14295C6.9609 8.01696 6.85478 7.91253 6.72744 7.84C6.60009 7.76742 6.4559 7.72923 6.30917 7.72923C6.16243 7.72923 6.01824 7.76742 5.8909 7.84C5.76355 7.91253 5.65749 8.01696 5.58318 8.14295L2.60944 13.2843C2.53794 13.4061 2.50026 13.5447 2.50026 13.6858C2.50026 13.8269 2.53794 13.9654 2.60944 14.0873C2.68277 14.214 2.78867 14.319 2.91626 14.3916C3.04385 14.4641 3.18853 14.5015 3.33544 14.5H9.28295C9.42974 14.5014 9.57425 14.4639 9.70171 14.3914C9.82916 14.3189 9.935 14.214 10.0082 14.0873C10.0798 13.9655 10.1176 13.827 10.1178 13.6859C10.1179 13.5448 10.0803 13.4062 10.0089 13.2843ZM9.53726 13.8161C9.51137 13.8601 9.47415 13.8965 9.42947 13.9215C9.38473 13.9465 9.33419 13.9592 9.28295 13.9583H3.33544C3.28419 13.9592 3.23363 13.9465 3.18893 13.9215C3.14423 13.8965 3.10701 13.8601 3.08109 13.8161C3.05761 13.7766 3.04522 13.7314 3.04522 13.6855C3.04522 13.6395 3.05761 13.5943 3.08109 13.5548L6.0548 8.41346C6.08129 8.36964 6.11867 8.33335 6.16335 8.30822C6.20804 8.28303 6.25852 8.26981 6.30987 8.26981C6.36122 8.26981 6.4117 8.28303 6.45639 8.30822C6.50108 8.33335 6.53845 8.36964 6.56489 8.41346L9.53867 13.5548C9.5619 13.5945 9.57409 13.6397 9.57382 13.6856C9.5736 13.7316 9.56098 13.7767 9.53726 13.8161Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M2.60884 2.71573L5.58259 7.85706C5.6569 7.98305 5.76296 8.08748 5.89031 8.16001C6.01765 8.23259 6.16184 8.27078 6.30858 8.27078C6.45531 8.27078 6.59951 8.23259 6.72685 8.16001C6.85419 8.08748 6.96031 7.98305 7.03457 7.85706L10.0083 2.71573C10.0798 2.5939 10.1175 2.45533 10.1175 2.31422C10.1175 2.17312 10.0798 2.03455 10.0083 1.91272C9.93501 1.78597 9.82911 1.68095 9.7015 1.60842C9.57393 1.5359 9.42926 1.49849 9.2823 1.50005H3.33485C3.18805 1.49861 3.04351 1.53608 2.91605 1.6086C2.78859 1.68111 2.6828 1.78608 2.60952 1.91272C2.53792 2.03449 2.50012 2.17303 2.5 2.31413C2.49988 2.45524 2.53745 2.59384 2.60884 2.71573ZM3.08049 2.18389C3.10641 2.13986 3.14363 2.10349 3.18833 2.07851C3.23303 2.05352 3.2836 2.04082 3.33485 2.0417H9.2823C9.3336 2.04082 9.38414 2.05352 9.42882 2.07851C9.47356 2.10349 9.51078 2.13986 9.53667 2.18389C9.56018 2.22347 9.57253 2.26859 9.57253 2.31456C9.57253 2.36053 9.56018 2.40566 9.53667 2.44524L6.56294 7.58655C6.53651 7.63037 6.49914 7.66666 6.45439 7.6918C6.40971 7.71698 6.35928 7.7302 6.30793 7.7302C6.25658 7.7302 6.2061 7.71698 6.16141 7.6918C6.11672 7.66666 6.07929 7.63037 6.05286 7.58655L3.07913 2.44524C3.05585 2.40554 3.0437 2.36035 3.04394 2.31438C3.04418 2.26841 3.0568 2.22334 3.08049 2.18389Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default ThreeWayValveIcon;
