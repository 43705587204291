import clsx from "clsx";
import { useIntl } from "react-intl";

import { Circle } from "@eisox/design-system";
import { ThreeWayValveIcon, TwoWayValveIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Valve, ValveCommand } from "~/socketio/types";

import styles from "./MultiWayValve.module.scss";

interface MultiWaysValveProps extends Omit<Valve, "id"> {
  type: 2 | 3;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const MultiWayValve: React.FC<MultiWaysValveProps> = ({
  type,
  command,
  defect,
  forcing,
  opening,
  onClick,
  className,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const multiWayValveStyle = bem("multi-way-valve");

  const error = defect ? "error" : undefined;
  const manual = command === ValveCommand.MANUAL;

  return (
    <Circle
      size={45}
      className={clsx(multiWayValveStyle({ three: type === 3, clickable: !!onClick }), className)}
      onClick={onClick}
      error={error}
    >
      {type === 2 ? (
        <TwoWayValveIcon className={multiWayValveStyle("icon")} />
      ) : (
        <ThreeWayValveIcon className={multiWayValveStyle("icon")} />
      )}
      <div className={multiWayValveStyle("opening", { two: type === 2, clickable: !!onClick })} onClick={onClick}>
        <p>{`${
          manual ? `${formatMessage({ id: "boilerroom.valve.command.2" })}${type === 3 ? " - " : ""}` : ""
        } ${opening?.toFixed(1) ?? "-"}%`}</p>
      </div>
    </Circle>
  );
};
