import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import * as AKCombobox from "@ariakit/react/combobox";

import styles from "./Combobox.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Combobox
 * -----------------------------------------------------------------------------------------------*/

type ComboboxProps = AKCombobox.ComboboxProviderProps;

const Root: React.FC<ComboboxProps> = AKCombobox.ComboboxProvider;

Root.displayName = "Combobox";

/* -------------------------------------------------------------------------------------------------
 * ComboboxInput
 * -----------------------------------------------------------------------------------------------*/

type ComboboxInputProps = AKCombobox.ComboboxProps;

const Input = forwardRef<HTMLInputElement, ComboboxInputProps>(({ className, ...props }, forwardedRef) => (
  <AKCombobox.Combobox {...props} ref={forwardedRef} className={cx(styles.combobox__input, className)} />
));

Input.displayName = "ComboboxInput";

/* -------------------------------------------------------------------------------------------------
 * ComboboxValue
 * -----------------------------------------------------------------------------------------------*/

type ComboboxValueProps = AKCombobox.ComboboxValueProps;

const Value: React.FC<ComboboxValueProps> = AKCombobox.ComboboxValue;

Value.displayName = "ComboboxValue";

/* -------------------------------------------------------------------------------------------------
 * ComboboxPopover
 * -----------------------------------------------------------------------------------------------*/

type ComboboxPopoverProps = AKCombobox.ComboboxPopoverProps;

const Popover = forwardRef<HTMLDivElement, ComboboxPopoverProps>(({ className, ...props }, forwardedRef) => (
  <AKCombobox.ComboboxPopover
    gutter={10}
    {...props}
    ref={forwardedRef}
    className={cx(styles.combobox__popover, className)}
  />
));

Popover.displayName = "ComboboxPopover";

/* -------------------------------------------------------------------------------------------------
 * ComboboxList
 * -----------------------------------------------------------------------------------------------*/

type ComboboxListProps = AKCombobox.ComboboxListProps;

const List: React.FC<ComboboxListProps> = AKCombobox.ComboboxList;

List.displayName = "ComboboxList";

/* -------------------------------------------------------------------------------------------------
 * ComboboxItem
 * -----------------------------------------------------------------------------------------------*/

type ComboboxItemProps = AKCombobox.ComboboxItemProps;

const Item = forwardRef<HTMLDivElement, ComboboxItemProps>(({ className, ...props }, forwardedRef) => (
  <AKCombobox.ComboboxItem {...props} ref={forwardedRef} className={cx(styles.combobox__item, className)} />
));

Item.displayName = "ComboboxItem";

export { Root, Value, Input, Popover, List, Item };
export type { ComboboxProps, ComboboxValueProps, ComboboxInputProps, ComboboxPopoverProps, ComboboxItemProps };
