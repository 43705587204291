import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import * as RadixToggle from "@radix-ui/react-toggle";

import styles from "./Toggle.module.scss";

type ToggleProps = RadixToggle.ToggleProps;

const Toggle = forwardRef<HTMLButtonElement, ToggleProps>(({ className, ...props }, forwardedRef) => (
  <RadixToggle.Root {...props} ref={forwardedRef} className={cx(styles.toggle, className)} />
));

export { Toggle };
export type { ToggleProps };
