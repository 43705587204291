import jwtDecode from "jwt-decode";
import type { LoaderFunctionArgs } from "react-router-dom";
import { redirect } from "react-router-dom";

import { InvalidToken } from "~/apiV2";
import { routeToSignIn } from "~/routes/utils";

const isValidType = (value: string | undefined): value is InvalidToken =>
  value === InvalidToken.ACCOUNT || value === InvalidToken.PASSWORD;

const loader = ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const params = Object.fromEntries(url.searchParams.entries());
  const type = params.type;
  const token = params.token;

  const email = token && jwtDecode<{ email?: string }>(token).email;

  if (!isValidType(type) || !token || !email) return redirect(routeToSignIn());

  return { type, email };
};

export { loader };
