import React, { Fragment } from "react";

import { convert } from "html-to-text";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Dialog, Divider, Loader } from "@eisox/design-system";

import type { News } from "~/apiV2";
import { env } from "~/configuration";
import { routeToRessources } from "~/routes/utils";

import styles from "./NewsPopup.module.scss";

interface NewsPopupProps {
  houseId?: string;
  news: News[];
  isLoading: boolean;
}

export const NewsPopup: React.FC<NewsPopupProps> = ({ news, isLoading, houseId }) => {
  const { t } = useTranslation();

  return (
    <Dialog.Content className={styles.newsPopup} title={t("news.title")}>
      <div className={styles.content}>
        {news.map((n, index) => {
          const to = n.docusaurus ? (houseId ? routeToRessources(houseId) + n.link : "/resources" + n.link) : n.link;
          return (
            <Fragment key={index}>
              <Dialog.Close asChild>
                <Link
                  className={styles.news}
                  to={to}
                  target={n.docusaurus ? undefined : "_blank"}
                  rel={n.docusaurus ? undefined : "noopener noreferrer"}
                >
                  <h2 className={styles.news__title}>{convert(n.title.rendered)}</h2>
                  <p className={styles.news__content}>{convert(n.excerpt.rendered)}</p>
                </Link>
              </Dialog.Close>
              {index + 1 < news.length && <Divider className={styles.content__divider} />}
            </Fragment>
          );
        })}
        {isLoading && <Loader />}
      </div>
      <a className={styles.newsPopup__link} href={env.NEWS_BLOG_URL} target="_blank">
        {t("news.blog")}
      </a>
    </Dialog.Content>
  );
};
