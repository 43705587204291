import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { Circle } from "@eisox/design-system";
import { ArrowRightIcon, ThermometerIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import type { Weather } from "~/apiV2";

import type { ExternalTemperature } from "../../helpers";
import {
  getAPITemperature,
  getExternalTemperatureAverage,
  getMeasuredTemperatures,
  getMeasuredTemperaturesNumber,
  isTemperatureValid,
} from "../../helpers";
import { OverviewCard } from "../OverviewCard";

import styles from "./TemperaturesCard.module.scss";

interface TemperatureRowProps {
  measuredTemperature?: ExternalTemperature;
  apiTemperature?: ExternalTemperature;
}

const TemperatureRow: React.FC<TemperatureRowProps> = ({ measuredTemperature, apiTemperature }) => {
  const bem = useBem(styles);
  const temperatureRowStyle = bem("temperature-row");

  const isValid = isTemperatureValid(measuredTemperature);

  return (
    <div className={temperatureRowStyle()}>
      <Circle className={temperatureRowStyle("indicator", { valid: isValid })} size={10} />
      {measuredTemperature ? (
        <>
          <p className={temperatureRowStyle("temperature")}>
            {measuredTemperature.name}
            {measuredTemperature.externalTemperature !== undefined &&
              `: ${measuredTemperature.externalTemperature.toFixed(1)}°C`}
          </p>
          {measuredTemperature.updatedAt && (
            <p className={temperatureRowStyle("date")}>
              ({dayjs(measuredTemperature.updatedAt).format("HH:mm - DD/MM/YYYY")})
            </p>
          )}
          {!isValid && (
            <>
              <ArrowRightIcon />
              <p className={temperatureRowStyle("temperature")}>
                OpenWeatherMap: {apiTemperature?.externalTemperature?.toFixed(1) ?? "--,-"}°C
              </p>
            </>
          )}
        </>
      ) : (
        <p className={temperatureRowStyle("temperature")}>OpenWeatherMap: --,-°C</p>
      )}
    </div>
  );
};

interface TooltipContentProps {
  temperatures: ExternalTemperature[];
}

const TooltipContent: React.FC<TooltipContentProps> = ({ temperatures }) => {
  const bem = useBem(styles);
  const tooltipContentStyle = bem("tooltip-content");

  const measuredTemperatures = getMeasuredTemperatures(temperatures);
  const apiTemperature = getAPITemperature(temperatures);

  return (
    <div className={tooltipContentStyle()}>
      {measuredTemperatures.length > 0 ? (
        measuredTemperatures.map((temperature, key) => (
          <TemperatureRow key={key} measuredTemperature={temperature} apiTemperature={apiTemperature} />
        ))
      ) : (
        <TemperatureRow />
      )}
    </div>
  );
};

interface TemperaturesCardProps {
  temperatures: Weather;
  className?: string;
}

export const TemperaturesCard: React.FC<TemperaturesCardProps> = ({ temperatures, className }) => {
  const { t } = useTranslation();

  const externalTemperatureNumber = getMeasuredTemperaturesNumber(temperatures);

  const externalTemperatureAverage = getExternalTemperatureAverage(temperatures);
  const title = Number.isNaN(externalTemperatureAverage) ? "--,-" : externalTemperatureAverage.toString();

  return (
    <Tooltip content={<TooltipContent temperatures={temperatures} />} position="left" keepHoverOpen={false}>
      <OverviewCard
        className={className}
        icon={<ThermometerIcon />}
        iconColor="white"
        iconBackgroundColor="blue"
        title={title}
        subtitle={t("dashboard.externalTemp")}
        hasDegre
        size="large"
      >
        {externalTemperatureNumber > 0 && (
          <Circle className={styles.temperaturesCard__number} size={18}>
            {externalTemperatureNumber}
          </Circle>
        )}
      </OverviewCard>
    </Tooltip>
  );
};

TemperaturesCard.displayName = "DashboardTemperaturesCard";
