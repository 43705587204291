import type { ComponentPropsWithoutRef } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { RoundIcon, ToggleGroup } from "@eisox/design-system";
import { UpdateIcon } from "@eisox/icons";
import { useControllableState } from "@eisox/tools";

import styles from "./LiveHistoryToggle.module.scss";

enum Mode {
  Live = "live",
  History = "history",
}

interface LiveHistoryToggleProps extends Omit<ComponentPropsWithoutRef<"div">, "children"> {
  mode?: Mode;
  onModeChange?: (mode: Mode) => void;
  defaultMode?: Mode;
  date?: string;
  onReload?: () => void;
}

const LiveHistoryToggle: React.FC<LiveHistoryToggleProps> = ({
  mode: modeProp,
  onModeChange,
  defaultMode = Mode.Live,
  date,
  onReload,
  className,
  ...props
}) => {
  const { t } = useTranslation();

  const [mode, setMode] = useControllableState({
    prop: modeProp,
    onChange: onModeChange,
    defaultProp: defaultMode,
  });

  const handleChangeMode = (value: string) => {
    setMode(value as Mode);
  };

  return (
    <div {...props} className={cx(styles.liveHistoryToggle, className)}>
      <ToggleGroup.Root value={mode} onValueChange={handleChangeMode} rounded={false}>
        <ToggleGroup.Item value={Mode.History}>{t("liveHistoryToggle.history")}</ToggleGroup.Item>
        <ToggleGroup.Item value={Mode.Live}>{t("liveHistoryToggle.live")}</ToggleGroup.Item>
      </ToggleGroup.Root>
      <div className={styles.liveHistoryToggle__info}>
        <p className={styles.liveHistoryToggle__date}>{dayjs(date).format("L - LTS")}</p>
        {mode === Mode.Live && onReload && (
          <RoundIcon size={21} backgroundColor={"gray"} iconColor="darkBlue" onClick={onReload}>
            <UpdateIcon />
          </RoundIcon>
        )}
      </div>
    </div>
  );
};

LiveHistoryToggle.displayName = "LiveHistoryToggle";

export { LiveHistoryToggle };
export type { LiveHistoryToggleProps };
