import React, { forwardRef } from "react";

import type { DateRange } from "react-day-picker";

import dayjs from "@eisox/dayjs";
import { createContext, useControllableState } from "@eisox/tools";
import * as Popover from "@radix-ui/react-popover";

import { Calendar as _Calendar } from "../Calendar";
import { Card } from "../Card";

import styles from "./DatePicker.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Context
 * -----------------------------------------------------------------------------------------------*/
const DatePickerContext = "DatePickerContext";
type DatePickerContextValue<T extends _Calendar.Mode> = _Calendar.CalendarProps<T> & { format: string };
const [DatePickerDialogProvider, useDatePickerContext] =
  createContext<DatePickerContextValue<_Calendar.Mode>>(DatePickerContext);

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
const ROOT_NAME = "DatePicker";
type RootProps<T extends _Calendar.Mode> = Popover.PopoverProps & DatePickerContextValue<T>;
const Root = <T extends _Calendar.Mode>({
  children,
  open,
  defaultOpen,
  onOpenChange,
  modal,
  ...props
}: RootProps<T>) => {
  const [value, setValue] = useControllableState({ prop: props.value, onChange: props.onChange });

  return (
    <DatePickerDialogProvider
      {...props}
      value={value as _Calendar.Value<_Calendar.Mode>}
      onChange={setValue as (v: _Calendar.Value<_Calendar.Mode>) => void}
    >
      <Popover.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange} modal={modal}>
        {children}
      </Popover.Root>
    </DatePickerDialogProvider>
  );
};
Root.displayName = ROOT_NAME;

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/
const TRIGGER_NAME = "DatePickerTrigger";
interface TriggerProps extends Omit<Popover.PopoverTriggerProps, "children"> {
  children?: React.ReactNode | ((value: string | undefined) => React.ReactNode);
}

const formatDate = <T extends _Calendar.Mode>(date: _Calendar.Value<T>, format: string, mode: T) => {
  if (!date) return;
  switch (mode) {
    case "single":
      return dayjs(date as Date).format(format);
    case "multiple":
      return (date as Date[]).map(d => dayjs(d).format(format)).join(", ");
    case "range": {
      const { from, to } = date as DateRange;
      return `${dayjs(from).format(format)}${to ? ` - ${dayjs(to).format(format)}` : ""}`;
    }
  }
};

const Trigger = forwardRef<HTMLButtonElement, TriggerProps>(({ children, ...props }, ref) => {
  const { mode, value, format } = useDatePickerContext(TRIGGER_NAME);
  return (
    <Popover.Trigger ref={ref} {...props}>
      {typeof children === "function" ? children(formatDate(value, format, mode)) : children}
    </Popover.Trigger>
  );
});

Trigger.displayName = TRIGGER_NAME;

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
const CONTENT_NAME = "DatePickerContent";
type ContentProps = Popover.PopoverContentProps;
const Content = forwardRef<HTMLDivElement, ContentProps>(({ children, ...props }, ref) => (
  <Popover.Portal>
    <Popover.Content ref={ref} {...props}>
      <Card className={styles.calendar}>{children}</Card>
    </Popover.Content>
  </Popover.Portal>
));
Content.displayName = CONTENT_NAME;

/* -------------------------------------------------------------------------------------------------
 * Calendar
 * -----------------------------------------------------------------------------------------------*/
const CALENDAR_NAME = "Calendar";
const Calendar = () => {
  const props = useDatePickerContext(CALENDAR_NAME);
  return <_Calendar.Calendar {...props} />;
};
Calendar.displayName = CALENDAR_NAME;

export { Root, Trigger, Content, Calendar };
export type { RootProps, TriggerProps, ContentProps, DateRange };
