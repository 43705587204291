import * as React from "react";

import { IconProps } from "./types";

export const QuestionMarkIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M7.10282 10.1056C7.10282 9.65399 7.18217 9.25117 7.34085 8.89718C7.49954 8.54319 7.68263 8.23803 7.89015 7.98169C8.10986 7.72535 8.40893 7.41408 8.78733 7.04789C9.26339 6.59624 9.61127 6.21174 9.83099 5.89437C10.0629 5.56479 10.1789 5.18639 10.1789 4.75916C10.1789 4.14883 10.008 3.67887 9.6662 3.3493C9.32442 3.00751 8.81174 2.83662 8.12817 2.83662C6.80987 2.83662 5.90658 3.5385 5.41832 4.94225L4.10001 4.30141C4.40517 3.42254 4.91785 2.73897 5.63803 2.2507C6.37043 1.75023 7.21879 1.5 8.1831 1.5C9.30611 1.5 10.185 1.79296 10.8197 2.37887C11.4667 2.96479 11.7901 3.75822 11.7901 4.75916C11.7901 5.34507 11.6559 5.83944 11.3873 6.24225C11.1188 6.63286 10.716 7.0723 10.1789 7.56056C9.67841 8.02441 9.30001 8.43944 9.04367 8.80563C8.79954 9.15962 8.67747 9.59296 8.67747 10.1056V10.893H7.10282V10.1056ZM7.92677 14.5C7.59719 14.5 7.32864 14.4023 7.12113 14.207C6.91362 13.9995 6.80986 13.7371 6.80986 13.4197C6.80986 13.1023 6.91362 12.8399 7.12113 12.6324C7.32864 12.4249 7.59719 12.3211 7.92677 12.3211C8.24414 12.3211 8.50658 12.4249 8.71409 12.6324C8.93381 12.8399 9.04367 13.1023 9.04367 13.4197C9.04367 13.7249 8.93381 13.9812 8.71409 14.1887C8.50658 14.3962 8.24414 14.5 7.92677 14.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default QuestionMarkIcon;
