import dayjs from "@eisox/dayjs";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

import type { InterventionPlanState } from "~/features/InterventionPlanTool";
import { intl } from "~/i18n";

import { whiteColor } from "../color";
import { orientation, size } from "../constant";
import bg from "../img/bg.png";
import logo from "../img/logo_white.png";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: whiteColor,
    gap: 25,
  },
  bg: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  logo: {
    width: 350,
  },
  information: {
    width: "100%",
    textAlign: "center",
  },
  contact: {
    fontSize: 16,
    fontWeight: 600,
  },
  edit: {
    fontSize: 16,
    fontWeight: 600,
    width: "100%",
    textAlign: "center",
  },
});

export const PageContact: React.FC<InterventionPlanState> = ({ contact, editAt }) => {
  return (
    <Page size={size} orientation={orientation}>
      <View style={styles.container}>
        <Image src={bg} style={styles.bg} />
        <Image src={logo} style={styles.logo} />
        <View style={styles.information}>
          <Text style={styles.contact}>
            {intl.formatMessage({ id: "interventionPlanTool.pdf.pageContact.contact" })} : {contact.firstName}{" "}
            {contact.lastName}
          </Text>
          <Text style={styles.contact}>{contact.email}</Text>
          <Text style={styles.contact}>{contact.phoneNumber}</Text>
        </View>
        <View style={styles.edit}>
          <Text>
            {intl.formatMessage(
              { id: "interventionPlanTool.pdf.pageContact.editAt" },
              { d: dayjs(editAt).format("DD/MM/YYYY") },
            )}
          </Text>
        </View>
      </View>
    </Page>
  );
};
