import * as React from "react";

import { IconProps } from "./types";

export const PencilIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <g clipPath="url(#clip0_30_156)">
        <path
          d="M13.0073 2.0233C12.3083 1.32557 11.1765 1.32557 10.4776 2.0233L9.84443 2.66004L3.10396 9.39758L3.08963 9.41202C3.08616 9.41548 3.08616 9.41918 3.08247 9.41918C3.07531 9.42992 3.06456 9.44055 3.05752 9.4513C3.05752 9.45489 3.05382 9.45489 3.05382 9.45846C3.04666 9.46921 3.04319 9.47637 3.03592 9.48711C3.03245 9.4907 3.03245 9.49417 3.02887 9.49786C3.02528 9.50861 3.0217 9.51577 3.01801 9.52652C3.01801 9.52998 3.01454 9.52998 3.01454 9.53368L1.51903 14.0312C1.47516 14.1593 1.50851 14.3011 1.60487 14.3961C1.67258 14.463 1.7639 14.5003 1.85891 14.5C1.89775 14.4994 1.93625 14.4933 1.9734 14.4821L6.46708 12.9829C6.47056 12.9829 6.47056 12.9829 6.47426 12.9794C6.48555 12.9761 6.49641 12.9712 6.50637 12.9649C6.50917 12.9646 6.51163 12.9634 6.51365 12.9615C6.52427 12.9543 6.53861 12.947 6.54934 12.9399C6.55998 12.9329 6.57083 12.9221 6.58158 12.9149C6.58516 12.9112 6.58862 12.9112 6.58862 12.9077C6.59232 12.9041 6.59948 12.9007 6.60306 12.8934L13.9768 5.51905C14.6744 4.82008 14.6744 3.68818 13.9768 2.98932L13.0073 2.0233ZM6.3526 12.142L3.86251 9.65176L10.0949 3.41878L12.585 5.90899L6.3526 12.142ZM3.51177 10.3137L5.68716 12.4891L2.42061 13.5769L3.51177 10.3137ZM13.4724 5.01807L13.0931 5.40096L10.6029 2.91053L10.9858 2.52775C11.4047 2.10915 12.0837 2.10915 12.5028 2.52775L13.4758 3.50094C13.8916 3.92177 13.89 4.59925 13.4724 5.01807Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_30_156">
          <rect width="13" height="13" transform="translate(1.5 1.5)" />
        </clipPath>
      </defs>
    </svg>
  ),
);

export default PencilIcon;
