import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import styles from "./Error.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
type RootProps = React.ComponentPropsWithRef<"div">;
const Root = forwardRef<HTMLDivElement, RootProps>(({ className, ...props }, forwardedRef) => (
  <div {...props} ref={forwardedRef} role="alert" aria-live="assertive" className={cx(styles.root, className)} />
));

/* -------------------------------------------------------------------------------------------------
 * Title
 * -----------------------------------------------------------------------------------------------*/
type TitleProps = React.ComponentPropsWithRef<"h2">;
const Title = forwardRef<HTMLHeadingElement, TitleProps>(({ className, ...props }, forwardedRef) => (
  <h2 {...props} ref={forwardedRef} className={cx(styles.title, className)} />
));

/* -------------------------------------------------------------------------------------------------
 * Description
 * -----------------------------------------------------------------------------------------------*/
type DescriptionProps = React.ComponentPropsWithRef<"p">;
const Description = forwardRef<HTMLParagraphElement, DescriptionProps>(({ className, ...props }, forwardedRef) => (
  <p {...props} ref={forwardedRef} className={cx(styles.description, className)} />
));

export { Root, Title, Description };
export type { RootProps, TitleProps, DescriptionProps };
