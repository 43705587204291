import React, { useState } from "react";

import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";

import { Button, Dropdown } from "@eisox/design-system";
import {
  BoilerRoomIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  GatewayIcon,
  MeshIcon,
  PlanIcon,
  RoomIcon,
  ValveIcon,
} from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { houseLoader } from "~/UI/screens";
import { idLoaderHouse } from "~/routes/utils";

import { AddGateways, AddRooms, AddValves } from "../Actions/Add";
import { Levels } from "../Actions/Add/Levels";
import { WifiMesh } from "../Actions/Manage";
import { MoveDialog, MoveValves } from "../Actions/Move";
import { PreinstallGateways, PreinstallValves } from "../Actions/Preinstall";

import styles from "./ActionsMenu.module.scss";

enum PlansDialog {
  // ADD
  ADD_ROOM,
  ADD_GATEWAY,
  ADD_VALVE,

  // MOVE
  MOVE_ROOM,
  MOVE_GATEWAY,
  MOVE_VALVE,
  MOVE_BOILERROOM,

  // PRE-INSTALL
  PREINSTALL_GATEWAY,
  PREINSTALL_VALVE,

  // MANAGE
  MANAGE_WIFIMESH,
}

interface Item {
  name: string;
  subItem: {
    name: string;
    icon: React.ReactElement;
    canAccess: boolean;
    onClick: VoidFunction;
  }[];
}

interface ActionMenuProps {
  addPlanDrawerOpen: boolean;
  onAddPlan: VoidFunction;
}

export const ActionMenu: React.FC<ActionMenuProps> = ({ addPlanDrawerOpen, onAddPlan }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const dropdownContentStyle = bem("dropdown-content");
  const actionMenuStyle = bem("action-menu");
  const itemsStyle = bem("items");
  const subItemsStyle = bem("sub-items");

  const { boilerroomPos, plans, gateways, rooms, valves } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    ReturnType<typeof houseLoader>
  >;

  const [selectedItemIndex, setSelectedItemIndex] = useState<number>();
  const [dialogOpen, setDialogOpen] = useState<PlansDialog>();

  const handleCloseDialog = () => setDialogOpen(undefined);

  const items: Item[] = [
    {
      name: "plans.action.add",
      subItem: [
        { name: "plans.action.levels", icon: <PlanIcon />, canAccess: true, onClick: onAddPlan },
        {
          name: "plans.action.rooms",
          icon: <RoomIcon />,
          canAccess: plans.length > 0,
          onClick: () => setDialogOpen(PlansDialog.ADD_ROOM),
        },
        {
          name: "plans.action.gateways",
          icon: <GatewayIcon />,
          canAccess: plans.length > 0,
          onClick: () => setDialogOpen(PlansDialog.ADD_GATEWAY),
        },
        {
          name: "plans.action.valves",
          icon: <ValveIcon />,
          canAccess: rooms.length > 0 && gateways.length > 0,
          onClick: () => setDialogOpen(PlansDialog.ADD_VALVE),
        },
      ],
    },
    {
      name: "plans.action.move",
      subItem: [
        {
          name: "plans.action.rooms",
          icon: <RoomIcon />,
          canAccess: rooms.length > 0,
          onClick: () => setDialogOpen(PlansDialog.MOVE_ROOM),
        },
        {
          name: "plans.action.gateways",
          icon: <GatewayIcon />,
          canAccess: gateways.length > 0,
          onClick: () => setDialogOpen(PlansDialog.MOVE_GATEWAY),
        },
        {
          name: "plans.action.valves",
          icon: <ValveIcon />,
          canAccess: valves.length > 0,
          onClick: () => setDialogOpen(PlansDialog.MOVE_VALVE),
        },
        {
          name: "plans.action.boilerrooms",
          icon: <BoilerRoomIcon />,
          canAccess: boilerroomPos.length > 0,
          onClick: () => setDialogOpen(PlansDialog.MOVE_BOILERROOM),
        },
      ],
    },
    {
      name: "plans.action.preInstall",
      subItem: [
        {
          name: "plans.action.gateways",
          icon: <GatewayIcon />,
          canAccess: plans.length > 0,
          onClick: () => setDialogOpen(PlansDialog.PREINSTALL_GATEWAY),
        },
        {
          name: "plans.action.valves",
          icon: <ValveIcon />,
          canAccess: rooms.length > 0 && gateways.length > 0,
          onClick: () => setDialogOpen(PlansDialog.PREINSTALL_VALVE),
        },
      ],
    },
    {
      name: "plans.action.manage",
      subItem: [
        {
          name: "plans.action.wifiMesh",
          icon: <MeshIcon />,
          canAccess: true,
          onClick: () => setDialogOpen(PlansDialog.MANAGE_WIFIMESH),
        },
      ],
    },
  ];

  const displayedItems = items.filter(i => i.subItem.some(si => si.canAccess));

  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger>
          <Button text={formatMessage({ id: "plans.action.title" })} icon={<ChevronDownIcon />} />
        </Dropdown.Trigger>
        <Dropdown.Content className={dropdownContentStyle()}>
          <div className={actionMenuStyle()}>
            <div className={itemsStyle()}>
              {displayedItems.map((i, index) => (
                <button
                  key={index}
                  className={itemsStyle("item", { selected: selectedItemIndex === index })}
                  onClick={() => setSelectedItemIndex(index)}
                >
                  {formatMessage({ id: i.name })}
                  {(selectedItemIndex === undefined || selectedItemIndex === index) && <ChevronRightIcon />}
                </button>
              ))}
            </div>
            {selectedItemIndex !== undefined && (
              <div className={subItemsStyle()}>
                {displayedItems[selectedItemIndex].subItem
                  .filter(si => si.canAccess)
                  .map((si, index) => (
                    <Button
                      key={index}
                      className={subItemsStyle("sub-item")}
                      text={formatMessage({ id: si.name })}
                      icon={si.icon}
                      iconPosition="left"
                      onClick={si.onClick}
                    />
                  ))}
              </div>
            )}
          </div>
        </Dropdown.Content>
      </Dropdown.Root>

      {/* ADD */}
      {addPlanDrawerOpen && <Levels open={addPlanDrawerOpen} onClose={onAddPlan} />}
      {dialogOpen === PlansDialog.ADD_ROOM && (
        <AddRooms open={dialogOpen === PlansDialog.ADD_ROOM} onClose={handleCloseDialog} />
      )}
      {dialogOpen === PlansDialog.ADD_GATEWAY && <AddGateways open onClose={handleCloseDialog} />}
      {dialogOpen === PlansDialog.ADD_VALVE && (
        <AddValves open={dialogOpen === PlansDialog.ADD_VALVE} onClose={handleCloseDialog} />
      )}

      {/* MOVE */}
      {dialogOpen === PlansDialog.MOVE_ROOM && (
        <MoveDialog ressource={"room"} items={rooms} open onClose={handleCloseDialog} />
      )}
      {dialogOpen === PlansDialog.MOVE_GATEWAY && (
        <MoveDialog ressource={"gateway"} items={gateways} open onClose={handleCloseDialog} />
      )}
      {dialogOpen === PlansDialog.MOVE_VALVE && <MoveValves open onClose={handleCloseDialog} />}
      {dialogOpen === PlansDialog.MOVE_BOILERROOM && (
        <MoveDialog
          ressource={"boilerroom"}
          items={boilerroomPos.filter(bP => bP.boilerRooms).flatMap(bP => bP.boilerRooms!)}
          open
          onClose={handleCloseDialog}
        />
      )}

      {/* PRE-INSTALL */}
      {dialogOpen === PlansDialog.PREINSTALL_GATEWAY && <PreinstallGateways open onClose={handleCloseDialog} />}
      {dialogOpen === PlansDialog.PREINSTALL_VALVE && <PreinstallValves open onClose={handleCloseDialog} />}

      {/* MANAGE */}
      {dialogOpen === PlansDialog.MANAGE_WIFIMESH && <WifiMesh open onClose={handleCloseDialog} />}
    </>
  );
};
