import { cx } from "class-variance-authority";
import { DayPicker } from "react-day-picker";
import type { DateRange, PropsBase } from "react-day-picker";
import * as locales from "react-day-picker/locale";
import "react-day-picker/style.css";

import { createContext } from "@eisox/tools";

import styles from "./Calendar.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Context
 * -----------------------------------------------------------------------------------------------*/
const CalendarContext = "CalendarContext";
interface CalendarContextValue {
  locale: string;
}
const [Provider, useCalendarContext] = createContext<CalendarContextValue>(CalendarContext);

/* -------------------------------------------------------------------------------------------------
 * Calendar
 * -----------------------------------------------------------------------------------------------*/
const CALENDAR_NAME = "Calendar";

type Mode = "single" | "multiple" | "range";

type Value<T extends Mode> = T extends "single"
  ? Date | undefined
  : T extends "multiple"
    ? Date[] | undefined
    : DateRange | undefined;

interface CalendarProps<T extends Mode> extends Omit<PropsBase, "selected" | "onSelect" | "mode" | "locale"> {
  mode: T;
  value?: Value<T>;
  onChange?: (value: Value<T>) => void;
  className?: string;
}

const Calendar = <T extends Mode>({ className, mode, value, onChange, ...props }: CalendarProps<T>) => {
  const { locale } = useCalendarContext(CALENDAR_NAME);

  const dayPickerProps = {
    single: {
      selected: value as Value<"single">,
      onSelect: onChange as (date: Value<"single">) => void,
      mode: "single" as const,
    },
    multiple: {
      selected: value as Value<"multiple">,
      onSelect: onChange as (dates: Value<"multiple">) => void,
      mode: "multiple" as const,
    },
    range: {
      selected: value as Value<"range">,
      onSelect: onChange as (range: Value<"range">) => void,
      mode: "range" as const,
    },
  };

  return (
    <DayPicker
      {...props}
      {...dayPickerProps[mode]}
      className={cx(styles.calendar, className)}
      locale={
        locales[
          locale.replace(
            /([a-z]{2})-?([a-z]{2})?/i,
            (_, p1: string, p2: string) => p1.toLowerCase() + (p2 ? p2.toUpperCase() : ""),
          ) as keyof typeof locales
        ]
      }
    />
  );
};
Calendar.displayName = CALENDAR_NAME;

export { Calendar, Provider };
export type { CalendarProps, Mode, Value };
