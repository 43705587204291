import { forwardRef } from "react";

import styles from "./styles.module.scss";

import clsx from "clsx";

export interface CardProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const Card = forwardRef<HTMLDivElement, CardProps>(({ children, className, onClick, ...props }, ref) => (
  <div {...props} ref={ref} className={clsx(styles.card, className)} onClick={onClick}>
    {children}
  </div>
));
