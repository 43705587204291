import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import { Typography } from "@eisox/design-system";
import { ChevronUpIcon } from "@eisox/icons";
import * as Collapsible from "@radix-ui/react-collapsible";

import styles from "./Group.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Group
 * -----------------------------------------------------------------------------------------------*/

type GroupProps = Collapsible.CollapsibleProps;

const Root = forwardRef<HTMLDivElement, GroupProps>(({ className, ...props }, forwardedRef) => (
  <Collapsible.Root {...props} ref={forwardedRef} className={cx(styles.group, className)} />
));

Root.displayName = "Maintenance.Group";

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/

type TriggerProps = Omit<Collapsible.CollapsibleTriggerProps, "children"> & {
  name: string;
  error: number;
};

const Trigger = forwardRef<HTMLButtonElement, TriggerProps>(({ name, error, className, ...props }, forwardRef) => (
  <Collapsible.Trigger {...props} ref={forwardRef} className={cx(styles.trigger, className)}>
    <Typography as="span">{name}</Typography>
    <span className={cx(styles.trigger__error, error > 0 && styles.trigger__error_error)}>{error > 0 && error}</span>
    <ChevronUpIcon />
  </Collapsible.Trigger>
));

Trigger.displayName = "Maintenance.GroupTrigger";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/

type ContentProps = Collapsible.CollapsibleContentProps;

const Content = forwardRef<HTMLDivElement, ContentProps>(({ className, ...props }, forwardedRef) => (
  <Collapsible.Content {...props} ref={forwardedRef} className={cx(styles.content, className)} />
));

Content.displayName = "Maintenance.GroupContent";

export { Root, Trigger, Content };
export type { GroupProps, TriggerProps, ContentProps };
