import type { PropsWithChildren } from "react";
import { createContext, useContext, useState } from "react";

import { useParams, useRouteLoaderData } from "react-router-dom";
import type { Updater } from "use-immer";
import { useImmer } from "use-immer";

import type { houseLoader } from "~/UI/screens";
import type { GatewaysWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { idLoaderHouse } from "~/routes/utils";

import {
  CONTACTS,
  calcDurationIntervention,
  calcEquipementsIntervention,
  gatewaysToGatewayInterventionTools,
  valvesToValveInterventionTools,
} from "../components/InterventionPlanTool/utils";
import type { GatewayProblemType, InterventionPlanState } from "./types";
import { InterventionPlanToolStatus, ValveProblemType } from "./types";

const initialState: InterventionPlanState = {
  houseName: "",
  houseId: "",
  adress: "",
  planifiedAt: {
    start: "",
    end: "",
  },
  editAt: "",
  contact: {
    id: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
  },
  isForEisoxProvider: true,
  operatingTime: 0,
  commentsIntervention: "",
  equipments: [],
  valves: [],
  gateways: [],
  plans: [],
  version: "1",
};

const InterventionPlanToolContext = createContext<{
  state: InterventionPlanState;
  setState: Updater<InterventionPlanState>;
  status: InterventionPlanToolStatus;
  setStatus: React.Dispatch<React.SetStateAction<InterventionPlanToolStatus>>;
  transformState: (
    valves: ValvesWithProblem[],
    gateways: GatewaysWithProblem[],
    houseName: string,
  ) => InterventionPlanState;
  setProblemValve: (valveId: string, problem?: ValveProblemType) => void;
  setProblemGateway: (gatewayId: string, problem?: GatewayProblemType) => void;
  renamePlans: () => void;
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}>({
  state: initialState,
  status: InterventionPlanToolStatus.IDLE,
  setStatus: () => {},
  setState: () => {},
  transformState: () => initialState,
  setProblemValve: () => {},
  setProblemGateway: () => {},
  renamePlans: () => {},
  step: 0,
  setStep: () => {},
});

export const InterventionPlanToolProvider: React.FC<{ data?: InterventionPlanState } & PropsWithChildren> = ({
  data,
  children,
}) => {
  const { valves, gateways, house } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;
  const { houseId } = useParams() as { houseId: string };

  const transformState = (
    valves: ValvesWithProblem[],
    gateways: GatewaysWithProblem[],
    houseName: string,
  ): InterventionPlanState => {
    const transformValves = valvesToValveInterventionTools(valves);
    const transformGateways = gatewaysToGatewayInterventionTools(gateways);
    const operatingTime = calcDurationIntervention(transformValves, transformGateways);
    const equipments = calcEquipementsIntervention(transformValves, transformGateways);

    return {
      valves: transformValves,
      gateways: transformGateways,
      contact: CONTACTS[0],
      houseName,
      houseId,
      editAt: new Date().toISOString(),
      planifiedAt: {
        start: new Date().toISOString(),
        end: new Date().toISOString(),
      },
      operatingTime,
      equipments,
      plans: [],
      version: "1",
      adress: "",
      commentsIntervention: "",
      isForEisoxProvider: true,
    };
  };

  const [state, setState] = useImmer(data || transformState(valves, gateways, house.houseName!));
  const [status, setStatus] = useState<InterventionPlanToolStatus>(InterventionPlanToolStatus.IDLE);
  const [step, setStep] = useState(0);

  const setProblemValve = (valveId: string, problem?: ValveProblemType) => {
    setState(draft => {
      const valve = draft.valves.find(v => v.id === valveId);
      if (!valve || valve.problemInstance.type === problem) return;
      valve.problemInstance.isForcing = !(
        problem === ValveProblemType.UNCLOG_CHANGE_BODY && !valve.problemInstance.isForcing
      );
      if (problem === ValveProblemType.UNCLOG_CHANGE_BODY) valve.unclogging = !valve.unclogging;
      else valve.problemInstance.type = problem;
      draft.operatingTime = calcDurationIntervention(draft.valves, draft.gateways);
      draft.equipments = calcEquipementsIntervention(draft.valves, draft.gateways);
    });
  };

  const setProblemGateway = (gatewayId: string, problem?: GatewayProblemType) => {
    setState(draft => {
      const gateway = draft.gateways.find(v => v.id === gatewayId);
      if (!gateway) return;
      gateway.problemInstance.isForcing = true;
      gateway.problemInstance.type = problem;
      draft.operatingTime = calcDurationIntervention(draft.valves, draft.gateways);
      draft.equipments = calcEquipementsIntervention(draft.valves, draft.gateways);
    });
  };

  const renamePlans = () => {
    setState(draft => {
      const nameCountMap = draft.plans.reduce<Record<string, { total: number; current: number }>>((acc, { name }) => {
        const baseName = name.split("_zone_")[0];
        if (!acc[baseName]) acc[baseName] = { total: 0, current: 1 };
        acc[baseName].total++;
        return acc;
      }, {});

      draft.plans.forEach(plan => {
        const baseName = plan.name.split("_zone_")[0];
        if (nameCountMap[baseName].total > 1) {
          plan.name = `${baseName}_zone_${nameCountMap[baseName].current++}`;
        }
      });
    });
  };

  return (
    <InterventionPlanToolContext.Provider
      value={{
        state,
        setState,
        status,
        setStatus,
        transformState,
        setProblemValve,
        setProblemGateway,
        renamePlans,
        step,
        setStep,
      }}
    >
      {children}
    </InterventionPlanToolContext.Provider>
  );
};

export const useInterventionPlanTool = () => {
  const context = useContext(InterventionPlanToolContext);
  if (context === undefined) {
    throw new Error("useInterventionPlanTool must be used within a InterventionPlanToolProvider");
  }
  return context;
};
