import clsx from "clsx";

import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import { NetworkDialog, Pumps } from "~/features/BoilerRooms";
import type { HeatingNetwork } from "~/socketio/types";

import { Tank } from "../Tank";
import { WaterlineTemperature } from "../WaterlineTemperature";

import styles from "./ECS.module.scss";

interface ECSProps {
  network: HeatingNetwork;
  className?: string;
}

export const ECS: React.FC<ECSProps> = ({ network, className }) => {
  const bem = useBem(styles);
  const ecsStyle = bem("ecs");

  const { startingTemperaturesECS, returnTemperaturesECS, tankSanitaryHotWaterTemperature, recyclePumpsECS } = network;

  return (
    <div className={clsx(ecsStyle(), className)}>
      <div className={ecsStyle("waterline", { position: "top" })} />
      <Tank tankSanitaryHotWaterTemperature={tankSanitaryHotWaterTemperature} className={ecsStyle("tank")} />
      {recyclePumpsECS && (
        <NetworkDialog dialogTitle={network.name ?? "-"} networkId={network.id} {...network}>
          <Pumps className={ecsStyle("pumps")} {...recyclePumpsECS} vertical />
        </NetworkDialog>
      )}
      {startingTemperaturesECS && (
        <Tooltip
          content={startingTemperaturesECS.map((t, index) => (
            <p>{`${index + 1} - ${t}°C`}</p>
          ))}
        >
          <WaterlineTemperature
            className={ecsStyle("waterline-temperature", { type: "starting" })}
            temperatures={startingTemperaturesECS}
          />
        </Tooltip>
      )}
      {returnTemperaturesECS && (
        <Tooltip
          content={returnTemperaturesECS.map((t, index) => (
            <p>{`${index + 1} - ${t}°C`}</p>
          ))}
        >
          <WaterlineTemperature
            className={ecsStyle("waterline-temperature", { type: "return" })}
            temperatures={returnTemperaturesECS}
          />
        </Tooltip>
      )}
      <div className={ecsStyle("waterline", { position: "bottom" })} />
    </div>
  );
};
