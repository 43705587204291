import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import dayjs from "@eisox/dayjs";
import type { Modal } from "@eisox/design-system";
import { ActionButtonV2 as ActionButton, ButtonV2 as Button, Dialog, SelectV2 as Select } from "@eisox/design-system";
import { ArrowRightIcon, PointChartIcon } from "@eisox/icons";
import { PostModuleHistoryFields } from "@eisox/webapp-api-specification";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { Module, ModuleHistory, ModuleHistoryRequestBody } from "~/apiV2";
import { getModuleHistory, queries } from "~/apiV2";
import { exportData } from "~/features/ValveHistory";
import type { BoilerRoom } from "~/socketio/types";
import { useHistoryStore } from "~/stores";

import { getBoilerRoomObject, getDataFromHistoryResponse } from "../../helpers";
import { DataSelector } from "../DataSelector";

import styles from "./DataExportDialog.module.scss";

interface DataExportDialogProps extends Modal.RootProps {
  houseId: string;
  modules: Module[];
  boilerRooms: NonNullable<ModuleHistory["boilerRooms"]>;
  date: string;
  paths: string[];
}

const DataExportDialog: React.FC<DataExportDialogProps> = ({
  houseId,
  modules,
  boilerRooms,
  date: dateProp,
  ...props
}) => {
  const { t } = useTranslation();

  const moduleIds = useMemo(() => modules.map(module => module.id!), [modules]);

  const boilerrRoomPath = useHistoryStore.use.boilerromPath();
  const addBoilerRoomPath = useHistoryStore.use.addBoilerromPath();

  const paths = useMemo(() => boilerrRoomPath[houseId] ?? [], [boilerrRoomPath, houseId]);

  const handleChangePaths = (paths: string[]) => addBoilerRoomPath({ [houseId]: paths });

  const [startDate, setStartDate] = useState<string>(dateProp);
  const [endDate, setEndDate] = useState<string>(dayjs(dateProp).endOf("day").toISOString());

  const queryClient = useQueryClient();

  const body: ModuleHistoryRequestBody = {
    ranges: [
      {
        startDate: startDate,
        endDate: endDate,
      },
    ],
    moduleIds,
    fields: [PostModuleHistoryFields.createdAt],
    ...getBoilerRoomObject(paths),
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (_: "csv" | "xlsx") => getModuleHistory(houseId, body),
    onSuccess: (data, variables) => {
      queryClient.setQueryData(queries.history.module(houseId, body).queryKey, data);
      const dataToExport = getDataFromHistoryResponse(paths, data, boilerRooms);
      const transformedData = dataToExport.map(d => ({
        ...d,
        date: dayjs(d.date).format("DD/MM/YYYY - HH:mm"),
      }));
      exportData(transformedData, dayjs().toISOString(), variables);
    },
    onError: () => toast.error(""),
  });

  const dateOptions = Array.from({ length: 90 }, (_, index) => {
    const date = dayjs().startOf("day").subtract(index, "day");
    return {
      value: date.toISOString(),
      name: date.format("ddd DD MMMM"),
    };
  }).filter(
    date =>
      dayjs(date.value).isBefore(dayjs(endDate)) &&
      dayjs(date.value).isAfter(dayjs(endDate).startOf("day").subtract(7, "days")),
  );

  const endDateOptions = dateOptions
    .map(date => ({ ...date, value: dayjs(date.value).endOf("day").toISOString() }))
    .filter(
      date =>
        dayjs(date.value).isAfter(dayjs(startDate)) && dayjs(date.value).isBefore(dayjs(startDate).add(7, "days")),
    );

  return (
    <Dialog.Root {...props}>
      <Dialog.Trigger asChild>
        <Button className={styles.button} disabled={boilerRooms.length === 0}>
          {t("boilerRoom.history.detailedHistory.export")} <ArrowRightIcon />
        </Button>
      </Dialog.Trigger>
      <Dialog.Content
        className={styles.dataExportDialog}
        title={t("boilerRoom.history.detailedHistory.dataExportPopup.title")}
        icon={<PointChartIcon />}
        aria-describedby={undefined}
      >
        <div className={styles.dataExportDialog__content}>
          <label className={styles.dataExportDialog__label}>
            {t("boilerRoom.history.detailedHistory.dataExportPopup.data.label")}
            <DataSelector boilerrooms={boilerRooms as BoilerRoom[]} paths={paths} onChange={handleChangePaths} />
          </label>
          <Select
            label={t("boilerRoom.history.detailedHistory.dataExportPopup.start.label")}
            options={dateOptions}
            value={startDate}
            onChange={value => setStartDate(value as string)}
          />
          <Select
            label={t("boilerRoom.history.detailedHistory.dataExportPopup.end.label")}
            options={endDateOptions}
            value={endDate}
            onChange={value => setEndDate(value as string)}
          />
        </div>
        <div className={styles.dataExportDialog__footer}>
          <Dialog.Close asChild>
            <ActionButton variant="cancel">
              {t("boilerRoom.history.detailedHistory.dataExportPopup.cancel")}
            </ActionButton>
          </Dialog.Close>
          <ActionButton onClick={() => mutate("csv")} disabled={isPending}>
            {t("boilerRoom.history.detailedHistory.dataExportPopup.csv")}
          </ActionButton>
          <ActionButton onClick={() => mutate("xlsx")} disabled={isPending}>
            {t("boilerRoom.history.detailedHistory.dataExportPopup.xlsx")}
          </ActionButton>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export { DataExportDialog };
export type { DataExportDialogProps };
