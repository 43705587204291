import { createElement } from "react";

import clsx from "clsx";

import { useBem, useTextOverflowEllipsisDetection } from "@eisox/tools";

import * as Tooltip from "../Tooltip";

import styles from "./styles.module.scss";

export const TypographyDefaultAsType = "p";
export type TypographyDefaultAsType = typeof TypographyDefaultAsType;

export interface TypographyOwnProps<E extends React.ElementType> {
  children?: React.ReactNode;
  as?: E;
  keepHoverOpen?: boolean;
  className?: string;
}

export type TypographyProps<E extends React.ElementType> = TypographyOwnProps<E> &
  Omit<React.ComponentProps<E>, keyof TypographyOwnProps<E>>;

export const Typography = <E extends React.ElementType = TypographyDefaultAsType>({
  children,
  as,
  keepHoverOpen,
  className,
  ...otherProps
}: TypographyProps<E>) => {
  const Tag = as ?? TypographyDefaultAsType;

  const { ref, isTruncated } = useTextOverflowEllipsisDetection<HTMLElement>();

  const bem = useBem(styles);
  const typographyStyle = bem("typography");

  const element = createElement(
    Tag,
    {
      ref,
      className: clsx(typographyStyle(), className),
      ...otherProps,
    },
    children,
  );

  return isTruncated ? (
    <Tooltip.Root disableHoverableContent={!keepHoverOpen}>
      <Tooltip.Trigger asChild>{element}</Tooltip.Trigger>
      <Tooltip.Content className={typographyStyle("tooltip")}>
        {children}
        <Tooltip.Arrow />
      </Tooltip.Content>
    </Tooltip.Root>
  ) : (
    element
  );
};
