import * as React from "react";

import type { IconProps } from "./types";

export const PhoneIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M23.2426 17.6188L19.6442 17.208C19.2211 17.1583 18.7923 17.2051 18.3898 17.345C17.9874 17.4849 17.622 17.7141 17.3209 18.0155L14.7142 20.6222C10.6929 18.5764 7.42415 15.3077 5.37839 11.2863L7.99922 8.66549C8.60839 8.05632 8.90589 7.20632 8.80672 6.34215L8.39589 2.77215C8.31587 2.08095 7.9843 1.44335 7.46438 0.980909C6.94446 0.518467 6.27255 0.263515 5.57672 0.264652H3.12589C1.52506 0.264652 0.193391 1.59632 0.292558 3.19715C1.04339 15.2955 10.7192 24.9572 22.8034 25.708C24.4042 25.8072 25.7359 24.4755 25.7359 22.8747V20.4238C25.7501 18.993 24.6734 17.7888 23.2426 17.6188Z"
        fill={color}
      />
    </svg>
  ),
);

export default PhoneIcon;
