import React from "react";

import { CircularProgress } from "@mui/material";

import styles from "./LoaderApp.module.scss";

const LoaderApp = () => {
  return (
    <div className={styles.loader}>
      <CircularProgress size={50} />
    </div>
  );
};

export default LoaderApp;
