import * as React from "react";

import { IconProps } from "./types";

export const ArrowUpIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M8 14.5V1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M5 4.75L8 1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M11 4.75L8 1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default ArrowUpIcon;
