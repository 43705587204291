import { useIntl } from "react-intl";

import { primary } from "@eisox/colors";
import { ActionButton, Dialog } from "@eisox/design-system";
import { ArrowRightIcon, WarningIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./ConfirmCancelPopup.module.scss";

interface ConfirmCancelPopupProps {
  open: boolean;
  title?: string;
  className?: string;
  onClose?: VoidFunction;
  onValidate: VoidFunction;
}

export const ConfirmCancelPopup: React.FC<ConfirmCancelPopupProps> = ({
  open,
  title,

  className,
  onClose,
  onValidate,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const confirmCancelPopupStyle = bem("confirm-cancel-popup");

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose?.()}>
      <Dialog.Content
        className={className}
        title={title}
        icon={<WarningIcon color={primary.red} className={confirmCancelPopupStyle("icon")} />}
      >
        <p>{formatMessage({ id: "plan.actions.preinstall.confirmCancelPopup.content" })}</p>
        <div className={confirmCancelPopupStyle("button-container")}>
          <ActionButton
            variant="cancel"
            onClick={onClose}
            text={formatMessage({ id: "plan.actions.preinstall.confirmCancelPopup.back" })}
          />
          <ActionButton
            variant="primary"
            onClick={onValidate}
            text={formatMessage({ id: "plan.actions.preinstall.confirmCancelPopup.confirm" })}
            rounded
            icon={<ArrowRightIcon />}
          />
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
