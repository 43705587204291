import type { RouteObject } from "react-router-dom";

import { expiredToken } from "~/routes/routes";

import { ExpiredToken } from ".";

const route: RouteObject = {
  path: expiredToken,
  element: <ExpiredToken />,
};

export { route };
