import * as React from "react";

import { IconProps } from "./types";

export const CopyIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M3.775 14.5C3.4175 14.5 3.11135 14.3726 2.85655 14.1178C2.60175 13.863 2.47457 13.5571 2.475 13.2V4.1H3.775V13.2H10.925V14.5H3.775ZM6.375 11.9C6.0175 11.9 5.71135 11.7726 5.45655 11.5178C5.20175 11.263 5.07457 10.9571 5.075 10.6V2.8C5.075 2.4425 5.2024 2.13635 5.4572 1.88155C5.712 1.62675 6.01793 1.49957 6.375 1.5H12.225C12.5825 1.5 12.8887 1.6274 13.1435 1.8822C13.3983 2.137 13.5254 2.44293 13.525 2.8V10.6C13.525 10.9575 13.3976 11.2637 13.1428 11.5185C12.888 11.7733 12.5821 11.9004 12.225 11.9H6.375ZM6.375 10.6H12.225V2.8H6.375V10.6Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default CopyIcon;
