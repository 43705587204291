import React, { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import { cx } from "class-variance-authority";

import { ChevronLeftIcon, ChevronUpIcon } from "@eisox/icons";
import { createContext, useComposedRefs, useControllableState, useIsMobile } from "@eisox/tools";
import * as Collapsible from "@radix-ui/react-collapsible";
import * as Popover from "@radix-ui/react-popover";
import { Slot } from "@radix-ui/react-slot";

import { Copyright, Divider, Eisox, RoundIcon, Sheet } from "..";
import * as Tooltip from "../Tooltip";
import christmasHat from "./christmas_hat.png";

import styles from "./Sidebar.module.scss";

/* -------------------------------------------------------------------------------------------------
 * SidebarProvider
 * -----------------------------------------------------------------------------------------------*/

const SIDEBAR_NAME = "Sidebar";
interface SidebarContextValue {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onOpenToggle: () => void;
  collapsed: boolean;
  onCollapsedChange: (collapsed: boolean) => void;
  onCollapseToggle: () => void;
  isMobile: boolean;
}

const [SidebarProvider, useSidebarContext] = createContext<SidebarContextValue>(SIDEBAR_NAME);

interface ProviderProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  defaultOpen?: boolean;
  collapsed?: boolean;
  onCollapsedChange?: (collapsed: boolean) => void;
  defaultCollapsed?: boolean;
  children?: React.ReactNode;
}

const Provider: React.FC<ProviderProps> = props => {
  const {
    open: openProp,
    onOpenChange,
    defaultOpen,
    collapsed: collapsedProp,
    onCollapsedChange,
    defaultCollapsed = false,
    children,
  } = props;
  const isMobile = useIsMobile();

  const [open = false, setOpen] = useControllableState({
    prop: openProp,
    onChange: onOpenChange,
    defaultProp: defaultOpen,
  });
  const [collapsed = false, setCollapsed] = useControllableState({
    prop: collapsedProp,
    onChange: (collapsed: boolean) => {
      onCollapsedChange?.(collapsed);
    },
    defaultProp: defaultCollapsed,
  });

  return (
    <Tooltip.Provider>
      <SidebarProvider
        open={open}
        onOpenChange={setOpen}
        onOpenToggle={useCallback(() => setOpen(prev => !prev), [setOpen])}
        collapsed={collapsed}
        onCollapsedChange={setCollapsed}
        onCollapseToggle={useCallback(() => setCollapsed(prev => !prev), [setCollapsed])}
        isMobile={isMobile}
      >
        {children}
      </SidebarProvider>
    </Tooltip.Provider>
  );
};

Provider.displayName = "SidebarProvider";

/* -------------------------------------------------------------------------------------------------
 * Sidebar
 * -----------------------------------------------------------------------------------------------*/

interface SidebarProps extends React.ComponentPropsWithRef<"div"> {
  displayChristmasHat?: boolean;
  overlay?: {
    className?: string;
    style?: React.CSSProperties;
  };
}

const Root = forwardRef<HTMLDivElement, SidebarProps>((props, forwardedRef) => {
  const { displayChristmasHat = false, className, children, overlay, ...sidebarProps } = props;
  const { open, onOpenChange, isMobile, collapsed, onCollapseToggle } = useSidebarContext(SIDEBAR_NAME);

  const handleFullScreen = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    const doc = window.document;
    const docEl = doc.documentElement;
    if (!doc.fullscreenElement) {
      void docEl.requestFullscreen.call(docEl);
    } else {
      void doc.exitFullscreen.call(doc);
    }
  };

  if (isMobile) {
    return (
      <Sheet.Root open={open} onOpenChange={onOpenChange}>
        <Sheet.Portal>
          <Sheet.Overlay {...overlay} />
          <Sheet.Content {...sidebarProps} side="left" className={cx(styles.sidebar, className)}>
            <Eisox className={cx(styles.sidebar__eisox)} />
            {children}
          </Sheet.Content>
        </Sheet.Portal>
      </Sheet.Root>
    );
  }

  return (
    <div
      {...sidebarProps}
      ref={forwardedRef}
      className={cx(styles.sidebar, collapsed && styles.sidebar_collapsed, className)}
    >
      <Eisox
        width={collapsed ? "31" : "133"}
        viewBox={collapsed ? "0 0 31 30" : "0 0 133 30"}
        className={cx(styles.sidebar__eisox, collapsed && styles.sidebar__eisox_collapsed)}
        onClick={handleFullScreen}
      />
      {displayChristmasHat && (
        <img
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          src={christmasHat}
          className={cx(styles.sidebar__christmasHat, collapsed && styles.sidebar__christmasHat_collapsed)}
        />
      )}
      <RoundIcon
        size={30}
        className={cx(styles.sidebar__collapser, collapsed && styles.sidebar__collapser_collapsed)}
        onClick={onCollapseToggle}
      >
        <ChevronLeftIcon />
      </RoundIcon>
      {children}
    </div>
  );
});

Root.displayName = "Sidebar";

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/

interface TriggerProps extends React.ComponentPropsWithRef<"button"> {
  asChild?: boolean;
}

const Trigger = forwardRef<HTMLButtonElement, TriggerProps>((props, forwardedRef) => {
  const { asChild, onClick, ...triggerProps } = props;
  const { onOpenToggle } = useSidebarContext(SIDEBAR_NAME);
  const Comp = asChild ? Slot : "button";

  return (
    <Comp
      {...triggerProps}
      ref={forwardedRef}
      onClick={e => {
        onClick?.(e);
        onOpenToggle();
      }}
    />
  );
});

Trigger.displayName = "SidebarTrigger";

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/

interface ChildrenProps {
  collapsed: boolean;
  isMobile: boolean;
}

interface HeaderProps extends Omit<React.ComponentPropsWithRef<"div">, "children"> {
  asChild?: boolean;
  children?: React.ReactNode | ((state: ChildrenProps) => React.ReactNode);
}

const Header = forwardRef<HTMLDivElement, HeaderProps>((props, forwardedRef) => {
  const { asChild, className, children, ...headerProps } = props;
  const { collapsed, isMobile } = useSidebarContext(SIDEBAR_NAME);
  const Comp = asChild ? Slot : "div";

  return (
    <Comp {...headerProps} ref={forwardedRef} className={cx(styles.header, className)} data-collapsed={collapsed}>
      {typeof children === "function" ? children({ collapsed, isMobile }) : children}
    </Comp>
  );
});

Header.displayName = "SidebarHeader";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/

const CONTENT_NAME = "SidebarContent";

interface ContentContextValue {
  selectedItem: ItemElement | null;
  onSelectedItemChange: (selectedItem: ItemElement) => void;
  itemRefCallback?: (node: HTMLButtonElement | null, active: boolean, disabled: boolean) => void;
}

const [ContentProvider, useContentContext] = createContext<ContentContextValue>(CONTENT_NAME);

type ContentProps = React.ComponentPropsWithRef<"div">;

const Content = forwardRef<HTMLDivElement, ContentProps>((props, forwardedRef) => {
  const { className, children, ...contentProps } = props;
  const firstValidItemFoundRef = useRef(false);
  const markerRef = useRef<HTMLDivElement>(null);
  const [selectedItem, setSelectedItem] = useState<ItemElement | null>(null);

  const itemRefCallback = useCallback((node: HTMLButtonElement | null, active: boolean, disabled: boolean) => {
    const isFirstValidItem = !firstValidItemFoundRef.current && !disabled;
    const isSelectedItem = active;
    if (isFirstValidItem || isSelectedItem) {
      firstValidItemFoundRef.current = true;
      setSelectedItem(node);
    }
  }, []);

  useEffect(() => {
    if (selectedItem && markerRef.current) {
      const marker = markerRef.current;
      marker.style.top = `${selectedItem.offsetTop}px`;
      marker.style.height = `${selectedItem.offsetHeight}px`;
    }
  }, [selectedItem, markerRef]);

  return (
    <ContentProvider
      selectedItem={selectedItem}
      onSelectedItemChange={setSelectedItem}
      itemRefCallback={itemRefCallback}
    >
      <div {...contentProps} ref={forwardedRef} className={cx(styles.content, className)}>
        {children}
        <div ref={markerRef} className={styles.content__marker} />
      </div>
    </ContentProvider>
  );
});

Content.displayName = CONTENT_NAME;

/* -------------------------------------------------------------------------------------------------
 * Item
 * -----------------------------------------------------------------------------------------------*/

const ITEM_NAME = "SidebarItem";

type ItemElement = React.ElementRef<typeof Item>;

interface ItemProps extends React.ComponentPropsWithRef<"button"> {
  asChild?: boolean;
  isActive?: boolean;
}

const Item = forwardRef<HTMLButtonElement, ItemProps>((props, forwardedRef) => {
  const { asChild, isActive, disabled, onClick, className, ...itemProps } = props;
  const { collapsed, onOpenChange, isMobile } = useSidebarContext(ITEM_NAME);
  const { selectedItem, onSelectedItemChange, itemRefCallback } = useContentContext(ITEM_NAME);

  const textContent = useRef<string>("");
  const itemRef = useRef<HTMLButtonElement>(null);
  const selected =
    selectedItem !== null && itemRef.current !== null ? selectedItem === itemRef.current : (isActive ?? false);
  const composedRefs = useComposedRefs(
    forwardedRef,
    itemRef,
    node => itemRefCallback?.(node, selected, !!disabled),
    node => (textContent.current = node?.textContent ?? ""),
  );

  const Comp = asChild ? Slot : "button";

  const button = (
    <Comp
      {...itemProps}
      ref={composedRefs}
      className={cx(styles.item, className)}
      onClick={e => {
        onClick?.(e);
        if (isMobile) {
          onOpenChange(false);
        }
        if (itemRef.current) {
          onSelectedItemChange(itemRef.current);
        }
      }}
      disabled={disabled}
      data-active={selected}
      data-collapsed={collapsed}
    />
  );

  useEffect(() => {
    if (itemRef.current && isActive) {
      onSelectedItemChange(itemRef.current);
    }
  }, [isActive, onSelectedItemChange]);

  if (collapsed && !isMobile) {
    return (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{button}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className={styles.tooltip} side="right" sideOffset={20}>
            {textContent.current}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    );
  }

  return button;
});

Item.displayName = "SidebarItem";

/* -------------------------------------------------------------------------------------------------
 * Footer
 * -----------------------------------------------------------------------------------------------*/

interface FooterProps {
  email: string;
  role?: string;
  version: string;
  firstName?: string;
  lastName?: string;
  chip?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  expanded?: boolean;
  onExpandedChange?: (expanded: boolean) => void;
  defaultExpanded?: boolean;
}

const Footer: React.FC<FooterProps> = props => {
  const {
    email,
    role,
    version,
    firstName,
    lastName,
    chip,
    className,
    style,
    children,
    expanded: expandedProp,
    onExpandedChange,
    defaultExpanded,
  } = props;
  const { collapsed, isMobile } = useSidebarContext(SIDEBAR_NAME);

  const [expanded, setExpanded] = useControllableState({
    prop: expandedProp,
    onChange: onExpandedChange,
    defaultProp: defaultExpanded,
  });

  if (collapsed && !isMobile) {
    const fallback = (firstName && lastName ? `${firstName[0]}${lastName[0]}` : email[0])?.toUpperCase();

    return (
      <div className={cx(styles.footer, styles.footer_collapsed)}>
        <Popover.Root>
          <Popover.Trigger className={styles.popover__trigger}>{fallback}</Popover.Trigger>
          <Popover.Content className={styles.popover__content} align="end" side="right" sideOffset={30}>
            <div className={styles.user}>
              <p className={styles.user__name}>{firstName && lastName ? `${firstName} ${lastName}` : email}</p>
              {role && <p className={styles.user__role}>{role}</p>}
            </div>
            <Divider className={styles.popover__divider} />
            {children}
            <Copyright className={styles.version} version={version} />
          </Popover.Content>
        </Popover.Root>
      </div>
    );
  }

  return (
    <Collapsible.Root open={expanded} onOpenChange={setExpanded} className={styles.footer}>
      <Collapsible.Trigger className={styles.footer__trigger}>
        <div className={styles.user}>
          <p className={styles.user__name}>{firstName && lastName ? `${firstName} ${lastName}` : email}</p>
          {role && <p className={styles.user__role}>{role}</p>}
        </div>
        {!expanded && chip}
        <ChevronUpIcon />
      </Collapsible.Trigger>
      <Collapsible.Content className={cx(styles.footer__content, className)} style={style}>
        {children}
        <Copyright className={styles.version} version={version} />
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

Footer.displayName = "SidebarFooter";

export { Content, Footer, Header, Item, Provider, Root, Trigger };
export type { ContentProps, FooterProps, HeaderProps, ItemProps, ProviderProps, SidebarProps, TriggerProps };
