import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import type { DotProps, TooltipProps } from "recharts";
import {
  CartesianGrid,
  ComposedChart,
  Dot,
  Label,
  Line,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";

import { primary } from "@eisox/colors";

import type { HeatingCurve } from "~/socketio/types";

import styles from "./Chart.module.scss";

interface ChartProps {
  auto: boolean;
  points: HeatingCurve[];
  setPointTemperature?: number;
  externalTemperature?: number;
  className?: string;
}

const Chart: React.FC<ChartProps> = ({ auto, points, setPointTemperature, externalTemperature, className }) => {
  const { t } = useTranslation();

  const manualData = points;
  const autoData = points.map(p => ({ ...p, auto: p.con + (p.offset ?? 0) }));

  return (
    <ResponsiveContainer className={cx(styles.chart, className)}>
      <ComposedChart margin={{ top: 10, right: 20, bottom: 40, left: 33 }}>
        <Tooltip content={<CustomTooltip auto={auto} />} />
        <CartesianGrid vertical={false} horizontal stroke={primary.gray} />
        <XAxis
          dataKey="ext"
          type="number"
          unit="°C"
          tickMargin={16}
          axisLine={false}
          tickLine={false}
          padding={{ left: 10, right: 10 }}
          domain={["dataMin", "dataMax"]}
          style={{ fontWeight: 400, fill: primary.darkBlue }}
        >
          <Label
            style={{ fontStyle: "italic", fontWeight: 400, fill: primary.darkBlue }}
            value={t("network.content.heatingCurve.dialog.heatingCurve.externalTemperature")}
            position="bottom"
            dy={10}
          />
        </XAxis>
        <YAxis
          dataKey="con"
          type="number"
          unit="°C"
          axisLine={false}
          tickLine={false}
          tickMargin={9}
          domain={[0, "auto"]}
          style={{ fontWeight: 400, fill: primary.darkBlue }}
        >
          <Label
            style={{ fontStyle: "italic", fontWeight: 400, fill: primary.darkBlue }}
            value={t("network.content.heatingCurve.dialog.heatingCurve.setpoint")}
            position={"left"}
            dx={-10}
            angle={-90}
          />
        </YAxis>
        <Line
          data={manualData}
          type={"linear"}
          dataKey={"con"}
          fill={primary.darkerGray}
          stroke={primary.darkerGray}
          strokeWidth={2}
          dot={{ stroke: primary.darkerGray, strokeWidth: 5 }}
        />
        {auto && (
          <Line
            data={autoData}
            type={"linear"}
            dataKey={"auto"}
            fill={primary.orange}
            stroke={primary.orange}
            strokeWidth={2}
            dot={{ stroke: primary.orange, strokeWidth: 5 }}
          />
        )}
        <Scatter
          data={[{ con: setPointTemperature, ext: externalTemperature }]}
          dataKey={"con"}
          shape={({ cx, cy }: DotProps) => <Dot cx={cx} cy={cy} fill={primary.darkBlue} r={8} />}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

interface CustomTooltipProps extends TooltipProps<ValueType, NameType> {
  auto: boolean;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ payload, label, auto }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.tooltip}>
      <p className={cx(styles.tooltip__label, styles.tooltip__label_ext)}>
        {t("network.content.heatingCurve.chart.tooltip.tExt")}
        <span>{label}°C</span>
      </p>
      <p className={styles.tooltip__label}>{t("network.content.heatingCurve.chart.tooltip.setpoint")}</p>
      {auto && (
        <p className={cx(styles.tooltip__label, styles.tooltip__label_auto)}>
          {t("network.content.heatingCurve.chart.tooltip.auto")}
          <span>{payload?.[1]?.value}°C</span>
        </p>
      )}
      <p className={cx(styles.tooltip__label, styles.tooltip__label_manual)}>
        {t("network.content.heatingCurve.chart.tooltip.manual")}
        <span>{payload?.[0]?.value}°C</span>
      </p>
    </div>
  );
};

export { Chart };
export type { ChartProps };
