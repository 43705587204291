import * as React from "react";

import { IconProps } from "./types";

export const TapIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M11.7632 3.27381H4.23685V4.04762H11.7632V3.27381ZM4.23685 2.5C3.85898 2.5 3.55264 2.84645 3.55264 3.27381V4.04762C3.55264 4.47499 3.85898 4.82143 4.23685 4.82143H11.7632C12.141 4.82143 12.4474 4.47499 12.4474 4.04762V3.27381C12.4474 2.84645 12.141 2.5 11.7632 2.5H4.23685Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M9.02634 4.82143H6.97369V6.36906H9.02634V4.82143ZM6.97369 4.04762C6.59581 4.04762 6.28947 4.39407 6.28947 4.82143V6.36906C6.28947 6.79642 6.59581 7.14287 6.97369 7.14287H9.02634C9.40417 7.14287 9.71054 6.79642 9.71054 6.36906V4.82143C9.71054 4.39407 9.40417 4.04762 9.02634 4.04762H6.97369Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M9.71055 7.14286H6.28948V8.69047H9.71055V7.14286ZM6.28948 6.36905C5.9116 6.36905 5.60527 6.7155 5.60527 7.14286V8.69047C5.60527 9.11787 5.9116 9.46428 6.28948 9.46428H9.71055C10.0884 9.46428 10.3947 9.11787 10.3947 8.69047V7.14286C10.3947 6.7155 10.0884 6.36905 9.71055 6.36905H6.28948Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M12.4474 9.46428H3.55263V11.7858H12.4474V9.46428ZM3.55263 8.69048C3.17475 8.69048 2.86842 9.03694 2.86842 9.46428V11.7858C2.86842 12.2131 3.17475 12.5596 3.55263 12.5596H12.4474C12.8252 12.5596 13.1316 12.2131 13.1316 11.7858V9.46428C13.1316 9.03694 12.8252 8.69048 12.4474 8.69048H3.55263Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M13.8158 8.69047H13.1316V12.5596H13.8158V8.69047ZM13.1316 7.91668C12.7537 7.91668 12.4474 8.26312 12.4474 8.69047V12.5596C12.4474 12.9869 12.7537 13.3334 13.1316 13.3334H13.8158C14.1937 13.3334 14.5 12.9869 14.5 12.5596V8.69047C14.5 8.26312 14.1937 7.91668 13.8158 7.91668H13.1316Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M2.86843 8.69047H2.18421V12.5596H2.86843V8.69047ZM2.18421 7.91668C1.80633 7.91668 1.5 8.26312 1.5 8.69047V12.5596C1.5 12.9869 1.80633 13.3334 2.18421 13.3334H2.86843C3.2463 13.3334 3.55263 12.9869 3.55263 12.5596V8.69047C3.55263 8.26312 3.2463 7.91668 2.86843 7.91668H2.18421Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default TapIcon;
