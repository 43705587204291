import { Image, StyleSheet, Text, View } from "@react-pdf/renderer";

import { darkBlueColor } from "../color";
import logo from "../img/logo.png";

const styles = StyleSheet.create({
  footer: {
    width: "100%",
    padding: "10 20",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: darkBlueColor,
    marginTop: "auto",
  },
  logoContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  logo: {
    height: 20,
  },
  beforeElement: {
    width: 6,
    height: "100%",
    backgroundColor: darkBlueColor,
  },
  pageNumber: {
    fontSize: 18,
    fontWeight: 600,
  },
});

export const Footer: React.FC = () => {
  return (
    <View style={styles.footer}>
      <View style={styles.logoContainer}>
        <View style={styles.beforeElement} />
        <Image src={logo} style={styles.logo} />
      </View>
      <Text style={styles.pageNumber} render={({ pageNumber }) => pageNumber} />
    </View>
  );
};
