import { colorVariable } from "../constants/ThemeColors";

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: colorVariable.primaryColor,
      dark: colorVariable.primaryColorDark,
      contrastText: colorVariable.tertiaryColor,
    },
    secondary: {
      main: colorVariable.secondaryColor,
      contrastText: colorVariable.tertiaryColor,
    },
    tertary: {
      main: colorVariable.tertiaryColor,
      contrastText: colorVariable.secondary,
    },
    error: {
      main: colorVariable.dangerColor,
      dark: colorVariable.dangerColorDark,
      contrastText: colorVariable.tertiaryColor,
    },
    warning: {
      main: colorVariable.warningColor,
      contrastText: colorVariable.tertiaryColor,
    },
    success: {
      main: colorVariable.primaryColor,
      contrastText: colorVariable.tertiaryColor,
    },
    info: {
      main: colorVariable.infoColor,
      contrastText: colorVariable.tertiaryColor,
    },
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 52,
          height: 26,
          padding: 0,
          margin: 0,
        },
        switchBase: {
          padding: "2px",
          "&.Mui-checked": {
            transform: "translateX(26px)",
            color: "white",
            "& + .MuiSwitch-track": {
              backgroundColor: colorVariable.primaryColor,
              opacity: 1,
              border: "none",
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: colorVariable.primaryColor,
              border: `6px solid ${colorVariable.primaryColor}`,
            },
          },
          "&.Mui-disabled": {
            "& + .MuiSwitch-track": {
              opacity: 1,
              cursor: "not-allowed",
              backgroundColor: colorVariable.disabledColor,
            },
            "& .MuiSwitch-thumb::before": {
              content: '""',
              position: "absolute",
              width: "6px",
              height: "2px",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: colorVariable.disabledColor,
            },
          },
          "& + .MuiSwitch-track": {
            backgroundColor: colorVariable.disabledColor,
            opacity: 1,
            transition:
              "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        },
        thumb: {
          color: colorVariable.tertiaryColor,
          width: 22,
          height: 22,
        },
        track: {
          borderRadius: 26 / 2,
          border: `2px solid ${colorVariable.disabledColor}}`,
        },
      },
      variants: [
        {
          props: { variant: "mixed" },
          style: {
            "& .MuiSwitch-thumb": {
              marginLeft: "13px",
            },
            "& .MuiSwitch-thumb::before": {
              marginLeft: "6px",
            },
            "& .Mui-checked": {
              marginLeft: "-26px",
            },
            "& .MuiSwitch-track": {
              backgroundColor: `${colorVariable.disabledColor} !important`,
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colorVariable.primaryColor,
          padding: "10px 15px !important",
          "&:hover": {
            backgroundColor: `${colorVariable.primaryColorDark}`,
            transform: "scale(1.05)",
          },
          "&.Mui-disabled": {
            backgroundColor: colorVariable.disabledColor,
          },
        },
        text: {
          color: "white",
          fontWeight: "400",
          "&.Mui-disabled": {
            color: colorVariable.tertiaryColor,
          },
        },
      },
      variants: [
        {
          props: { variant: "rounded" },
          style: {
            color: "white",
            padding: "10px 40px !important",
            borderRadius: "50px",
            "&.Mui-disabled": {
              color: colorVariable.tertiaryColor,
            },
          },
        },
        {
          props: { variant: "cancel" },
          style: {
            color: `${colorVariable.quaternaryColor} !important`,
            backgroundColor: "transparent",
            fontWeight: "400",
            fontStyle: "italic",
            "&:hover": {
              backgroundColor: "transparent !important",
            },
          },
        },
        {
          props: { variant: "delete" },
          style: {
            color: colorVariable.tertiaryColor,
            backgroundColor: colorVariable.dangerColor,
            "&:hover": {
              backgroundColor: `${colorVariable.dangerColorDark} !important`,
            },
          },
        },
        {
          props: { variant: "" },
          style: {
            color: colorVariable.tertiaryColor,
          },
        },
      ],
    },
    MuiSlider: {
      styleOverrides: {
        valueLabel: {
          backgroundColor: `${colorVariable.secondaryColor}`,
        },
        mark: {
          height: 8,
          width: 1,
          opacity: 0.38,
          "&.MuiSlider-markActive": {
            backgroundColor: "#1100FA",
            opacity: 1,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          marginTop: "15px",
        },
        listbox: {
          "& li": {
            padding: "5px 20px",
            "&:hover": {
              backgroundColor: colorVariable.quinaryColor,
            },
          },
        },
      },
    },
    MuiListItem: {
      variants: [
        {
          props: { variant: "test" },
          style: {
            paddingTop: "4px",
            paddingBottom: "4px",
            paddingRight: "4px",
            paddingLeft: "4px",
            margin: "4px",
            fontSize: "0.8rem",
            fontFamily: "Poppins !important",
            lineHeight: "1",
            "&:focus": {
              backgroundColor: colorVariable.primaryColor,
              "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: colorVariable.tertiaryColor,
                fontFamily: "Poppins !important",
              },
            },
            "& .MuiListItemText-primary": {
              fontFamily: "Poppins !important",
            },
          },
        },
      ],
    },
  },
});

export default theme;
