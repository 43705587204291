import { intl } from "~/i18n";

import * as Yup from "yup";

export const schemaErrors = () =>
  Yup.object({
    id: Yup.string().required(intl.formatMessage({ id: "error.required" })),
    gatewayName: Yup.string().required(intl.formatMessage({ id: "error.required" })),
    plan: Yup.object({
      planId: Yup.string().required(intl.formatMessage({ id: "error.required" })),
      x: Yup.number().required(intl.formatMessage({ id: "error.required" })),
      y: Yup.number().required(intl.formatMessage({ id: "error.required" })),
    }),
    isInternetGateway: Yup.boolean().required(intl.formatMessage({ id: "error.required" })),
    isWifiEnabled: Yup.boolean().required(intl.formatMessage({ id: "error.required" })),
    meshs: Yup.array()
      .of(Yup.object({ id: Yup.string().required(intl.formatMessage({ id: "error.required" })) }))
      .when("isWifiEnabled", ([isWifiEnabled], schema) =>
        isWifiEnabled
          ? schema
              .required(intl.formatMessage({ id: "error.required" }))
              .min(1, intl.formatMessage({ id: "error.required" }))
          : schema,
      ),
  });
