import { forwardRef, useState } from "react";

import { EyeClosedIcon, EyeIcon } from "@eisox/icons";

import { TextField } from "../TextField";

import styles from "./PasswordInput.module.scss";

type PasswordInputProps = Omit<TextField.TextFieldProps, "type">;

const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(({ ...rest }, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField.Root {...rest} ref={ref} type={showPassword ? "text" : "password"}>
      <TextField.Slot side="right" onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? (
          <EyeIcon onClick={() => setShowPassword(false)} className={styles.icon} />
        ) : (
          <EyeClosedIcon onClick={() => setShowPassword(true)} className={styles.icon} />
        )}
      </TextField.Slot>
    </TextField.Root>
  );
});

PasswordInput.displayName = "PasswordInput";

export { PasswordInput };
export type { PasswordInputProps };
