/**
 * Checks if the first version is greater than the second version.
 * @param version1 - The first version.
 * @param version2 - The second version.
 * @returns True if the first version is greater or equal than the second version, false otherwise.
 */
export const isVersionIsUpper = (version1?: string, version2?: string): boolean => {
  if (!version1 || !version2) return false;
  const [major1, minor1, patch1] = version1.split(".").map(Number);
  const [major2, minor2, patch2] = version2.split(".").map(Number);

  return major1 > major2 || (major1 === major2 && (minor1 > minor2 || (minor1 === minor2 && patch1 >= patch2)));
};
