import { ActionFunctionArgs } from "react-router-dom";

import { AddRoomsByHouseId207Response, PatchRooms, PostRoom } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { patchRooms, postRooms } from "~/api/room";
import { RestError } from "~/constants/appConstantV2";
import { MULTIPLE_RESPONSES } from "~/constants/fetchConstants";
import { FetchResponse } from "~/helpers/communication/fetchType";
import { API } from "~/types/API";

export const action = async ({
  request,
  params,
}: ActionFunctionArgs): Promise<API.Response<string | null> | Response> => {
  const { houseId } = params;
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.PATCH:
      const updates: PatchRooms = JSON.parse(formData.get("json") as string);
      let patchResponse = (await patchRooms(houseId, updates)) as FetchResponse<{
        message: { status: number; message: string; roomIds: string[] }[];
      }>;
      // 404s returned by the server are ignored
      if (patchResponse.type === MULTIPLE_RESPONSES)
        patchResponse = {
          ...patchResponse,
          result: {
            message: patchResponse.result.message.map(m => ({ ...m, status: m.status === 404 ? 200 : m.status })),
          },
        };
      return {
        date: dayjs(),
        restError: patchResponse.type as RestError | undefined,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [...patchResponse.result.message],
      };
    case API.HTTP_METHOD.POST:
      const updatesPost: PostRoom = JSON.parse(formData.get("json") as string);
      let postResponse = (await postRooms(houseId, updatesPost)) as FetchResponse<AddRoomsByHouseId207Response>;
      return {
        date: dayjs(),
        restError: postResponse.type as RestError | undefined,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [...postResponse.result.message!.map(m => ({ status: m.status!, message: m.id }))],
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
