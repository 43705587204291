import { SelectOrigin } from "./index";

export const getPositionStyles = (
  select: HTMLDivElement,
  anchorOrigin: SelectOrigin,
  transformOrigin: SelectOrigin
) => {
  const rect = select.getBoundingClientRect();
  const { vertical: anchorVertical, horizontal: anchorHorizontal } = anchorOrigin; // trigger
  const { vertical: transformVertical, horizontal: transformHorizontal } = transformOrigin; // card

  let position = { transform: "" };

  if (anchorVertical === "bottom") {
    if (anchorHorizontal === "center") {
      position.transform = `translate(${rect.width / 2}px, ${rect.height}px)`;
    }
    if (anchorHorizontal === "left") {
      position.transform = `translate(0px, ${rect.height}px)`;
    }
    if (anchorHorizontal === "right") {
      position.transform = `translate(${rect.width}px, ${rect.height}px)`;
    }
  } else if (anchorVertical === "center") {
    if (anchorHorizontal === "center") {
      position.transform = `translate(${rect.width / 2}px, ${rect.height / 2}px)`;
    }
    if (anchorHorizontal === "left") {
      position.transform = `translate(0px, ${rect.height / 2}px)`;
    }
    if (anchorHorizontal === "right") {
      position.transform = `translate(${rect.width}px, ${rect.height / 2}px)`;
    }
  } else if (anchorVertical === "top") {
    if (anchorHorizontal === "center") {
      position.transform = `translate(${rect.width / 2}px, 0px)`;
    }
    if (anchorHorizontal === "left") {
      position.transform = `translate(0px, 0px)`;
    }
    if (anchorHorizontal === "right") {
      position.transform = `translate(${rect.width}px, 0px)`;
    }
  }

  if (transformVertical === "bottom") {
    if (transformHorizontal === "center") {
      position = { transform: `${position.transform} translate(-50%, -100%)` };
    }
    if (transformHorizontal === "left") {
      position = { transform: `${position.transform} translate(0, -100%)` };
    }
    if (transformHorizontal === "right") {
      position = { transform: `${position.transform} translate(-100%, -100%)` };
    }
  } else if (transformVertical === "center") {
    if (transformHorizontal === "center") {
      position = { transform: `${position.transform} translate(-50%, -50%)` };
    }
    if (transformHorizontal === "left") {
      position = { transform: `${position.transform} translate(0, -50%)` };
    }
    if (transformHorizontal === "right") {
      position = { transform: `${position.transform} translate(-100%, -50%)` };
    }
  } else if (transformVertical === "top") {
    if (transformHorizontal === "center") {
      position = { transform: `${position.transform} translate(-50%, 0)` };
    }
    if (transformHorizontal === "left") {
      position = { transform: `${position.transform} translate(0, 0)` };
    }
    if (transformHorizontal === "right") {
      position = { transform: `${position.transform} translate(-100%, 0)` };
    }
  }

  return position;
};
