import { useTranslation } from "react-i18next";

import { ActionButtonV2 as ActionButton, Dialog } from "@eisox/design-system";
import { GatewayIcon } from "@eisox/icons";

import type { Event } from "~/socketio/requests";

import styles from "./EmitDataErrorDialog.module.scss";

interface EmitDataErrorDialogProps extends Dialog.RootProps {
  type: Event.UPDATE_DATA | Event.RESET_DATA;
  error?: string;
}

const EmitDataErrorDialog: React.FC<EmitDataErrorDialogProps> = ({ type, error, ...props }) => {
  const { t } = useTranslation();

  return (
    <Dialog.Root {...props}>
      <Dialog.Content title={t("boilerRoom.popup.communicationError.title.communicationError")} icon={<GatewayIcon />}>
        <Dialog.Description>{t(`boilerRoom.popup.communicationError.message.${type}`)}</Dialog.Description>
        {error && <p className={styles.error}>{error}</p>}
        <Dialog.Footer>
          <Dialog.Close asChild>
            <ActionButton asChild variant="cancel">
              {t("boilerRoom.popup.communicationError.cancel.close")}
            </ActionButton>
          </Dialog.Close>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

EmitDataErrorDialog.displayName = "EmitDataErrorDialog";

export { EmitDataErrorDialog };
export type { EmitDataErrorDialogProps };
