import clsx from "clsx";

import { Circle } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import styles from "./ItemInfo.module.scss";

interface ItemInfoProps extends React.HTMLAttributes<HTMLDivElement> {
  error?: ErrorType;
  selected?: boolean;
  label: string;
  subLabel: string;
  icon: React.ReactNode;
}

export const ItemInfo: React.FC<ItemInfoProps> = ({ error, selected, label, subLabel, icon, ...props }) => {
  const bem = useBem(styles);
  const itemInfoStyle = bem("item-info");
  const iconStyle = bem("icon");

  return (
    <div {...props} className={clsx(itemInfoStyle(), props.className)}>
      <Circle
        error={error}
        className={iconStyle({ type: selected !== undefined ? (selected ? "selected" : "unselected") : "" })}
      >
        {icon}
      </Circle>
      <div className={itemInfoStyle("texts", { selected: !!selected })}>
        <p>{label}</p>
        <p>{subLabel}</p>
      </div>
    </div>
  );
};
