import { produce } from "immer";
import { uniqueId } from "lodash";

import { GATEWAY_ERRORS_ENUM, VALVE_ERRORS_ENUM, VALVE_WARNINGS_ENUM } from "@eisox/problems-handler";
import { MECA_PROBLEMS_ENUM } from "@eisox/valves";

import type { GatewaysWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import type {
  Contact,
  Equipment,
  GatewayInterventionTools,
  InterventionPlanState,
  ProblemInstance,
  ValveInterventionTools,
} from "~/features/InterventionPlanTool/providers/types";
import { GatewayProblemType, ValveProblemType } from "~/features/InterventionPlanTool/providers/types";

import { contacts } from "../index.json";

export const CONTACTS: Contact[] = contacts.map(contact => ({
  id: uniqueId(),
  ...contact,
}));

//! The order of the enum values is important, it is used to determine the priority of the problems
export const ProblemValveByError = {
  [ValveProblemType.TO_INSTALL]: [VALVE_WARNINGS_ENUM.NOT_INSTALL],
  [ValveProblemType.CHANGE_BATTERY]: [
    VALVE_ERRORS_ENUM.LONG_TIME_DISCONNECTED,
    VALVE_ERRORS_ENUM.LOW_BATTERY,
    VALVE_WARNINGS_ENUM.LOW_BATTERY_WARNING,
  ],
  [ValveProblemType.VERIFY_GATEWAY]: [VALVE_ERRORS_ENUM.DISCONNECTED_GATEWAY],
  [ValveProblemType.REVIEW_VALVE]: [MECA_PROBLEMS_ENUM.PLUGGED_FALSE, MECA_PROBLEMS_ENUM.UNSCREWED],
  [ValveProblemType.UNCLOG_CHECK_RADIO_TRANSMISSION]: [VALVE_WARNINGS_ENUM.BAD_LQI, VALVE_WARNINGS_ENUM.BAD_RSSI],
  [ValveProblemType.RESTART_VALVE]: [
    VALVE_ERRORS_ENUM.DISCONNECTED,
    MECA_PROBLEMS_ENUM.MOTOR_PROBLEM,
    MECA_PROBLEMS_ENUM.ENCODER_PROBLEM,
    MECA_PROBLEMS_ENUM.INA_PROBLEM,
    MECA_PROBLEMS_ENUM.NO_MOVE_AT_LOW_SPEED,
    MECA_PROBLEMS_ENUM.NO_MAX_INT,
    MECA_PROBLEMS_ENUM.NO_MAX_INT_CLOSE,
    VALVE_WARNINGS_ENUM.NOT_ACTIVATED,
  ],
};

export const ProblemValveIsUnclogging = [
  MECA_PROBLEMS_ENUM.VALVE_IS_SEIZED_UP,
  MECA_PROBLEMS_ENUM.FAIL_TO_CLOSE,
  VALVE_WARNINGS_ENUM.LOW_ROW_STROKE,
];

//! The order of the enum values is important, it is used to determine the priority of the problems
export const ProblemGatewayByError = {
  [GatewayProblemType.REPLACE_GATEWAY]: [GATEWAY_ERRORS_ENUM.BORDER_ROUTER_STATUS],
  [GatewayProblemType.VERIFY_GATEWAY]: [GATEWAY_ERRORS_ENUM.DISCONNECTED],
};

const DURATION: {
  [key in ValveProblemType | GatewayProblemType]?: number; // IN MINUTES
} = {
  [ValveProblemType.TO_INSTALL]: 5,
  [ValveProblemType.CHANGE_BATTERY]: 2.5,
  [ValveProblemType.VERIFY_GATEWAY]: 10,
  [ValveProblemType.REVIEW_VALVE]: 4,
  [ValveProblemType.UNCLOG_CHECK_RADIO_TRANSMISSION]: 2,
  [ValveProblemType.RESTART_VALVE]: 2,
  [ValveProblemType.REPLACE_VALVE]: 5,
  [GatewayProblemType.REPLACE_GATEWAY]: 10,
  [ValveProblemType.UNCLOG_CHANGE_BODY]: 5,
};

export const valvesToValveInterventionTools = (valves: ValvesWithProblem[]): ValveInterventionTools[] => {
  return valves.map(valve => {
    const problemIsInErrorOrWarning = (error: MECA_PROBLEMS_ENUM | VALVE_ERRORS_ENUM | VALVE_WARNINGS_ENUM) =>
      valve.errors.includes(error as MECA_PROBLEMS_ENUM | VALVE_ERRORS_ENUM) ||
      valve.warnings.includes(error as VALVE_WARNINGS_ENUM);

    const problemInstanceType = (Object.keys(ProblemValveByError) as (keyof typeof ProblemValveByError)[]).find(
      problemType => ProblemValveByError[problemType].some(error => problemIsInErrorOrWarning(error)),
    );
    return {
      ...valve,
      problemInstance: {
        type: problemInstanceType,
        isForcing: false,
        isSolved: false,
      },
      unclogging: ProblemValveIsUnclogging.some(error => problemIsInErrorOrWarning(error)),
      notes: [
        {
          id: uniqueId(),
          x: valve.plan?.x || 50,
          y: (valve.plan?.y || 50) + 3,
          messages: [valve.mac?.slice(-5) || ""],
        },
      ],
    };
  });
};

export const gatewaysToGatewayInterventionTools = (gateways: GatewaysWithProblem[]): GatewayInterventionTools[] => {
  return gateways.map(gateway => {
    const problemInstanceType = (Object.keys(ProblemGatewayByError) as GatewayProblemType[]).find(problemType =>
      ProblemGatewayByError[problemType].some(error => gateway.errors.includes(error)),
    );
    return {
      ...gateway,
      problemInstance: {
        type: problemInstanceType,
        isForcing: false,
        isSolved: false,
      },
      notes: [
        {
          id: uniqueId(),
          x: gateway.plan?.x || 50,
          y: (gateway.plan?.y || 50) + 3,
          messages: [gateway.mac?.slice(-5) || ""],
        },
      ],
    };
  });
};

const modifyObjectFromTruthTable = <T>(obj1: ProblemInstance<T>, obj2: ProblemInstance<T>): ProblemInstance<T> => {
  const newObj = { ...obj1 };
  // Vérification si obj1 est en remplacement de valve et si obj2 est en erreur forcée ou en remplacement de valve
  if (obj1.type === ValveProblemType.REPLACE_VALVE) {
    if (obj2.isForcing || obj2.type === ValveProblemType.REPLACE_VALVE) {
      return obj2;
    }
    return newObj;
  }
  // Vérification si obj1 est en erreur autre que remplacement de valve
  else if (obj1.type) {
    // Si obj2 est en erreur
    if (obj2.type) {
      if (obj2.isForcing || (!obj1.isForcing && !obj2.isForcing)) return obj2;
    } else {
      // Si obj2 n'est pas en erreur
      newObj.isSolved = true;
      if (obj1.isForcing) newObj.isForcing = true;
    }
  } else {
    // Si obj1 n'est pas en erreur
    if (obj1.type !== obj2.type || obj2.isForcing) return obj2;
  }
  return newObj;
};

export const mergeInterventionToolsItems = (
  oldItems: InterventionPlanState,
  newItems: InterventionPlanState,
): InterventionPlanState => {
  const newObj = produce(oldItems, draft => {
    Object.assign(draft, newItems);
    draft.valves = oldItems.valves.reduce((acc, oldValve) => {
      const newValve = newItems.valves.find(v => v.id === oldValve.id);
      if (newValve) {
        acc.push({
          ...newValve,
          unclogging: oldValve.unclogging || newValve.unclogging,
          problemInstance: modifyObjectFromTruthTable(oldValve.problemInstance, newValve.problemInstance),
        });
      }
      return acc;
    }, [] as ValveInterventionTools[]);

    draft.gateways = oldItems.gateways.reduce((acc, oldGateway) => {
      const newGateway = newItems.gateways.find(v => v.id === oldGateway.id);
      if (newGateway) {
        acc.push({
          ...newGateway,
          problemInstance: modifyObjectFromTruthTable(oldGateway.problemInstance, newGateway.problemInstance),
        });
      }
      return acc;
    }, [] as GatewayInterventionTools[]);
  });

  return newObj;
};

export const calcDurationIntervention = (
  valves: ValveInterventionTools[],
  gateways: GatewayInterventionTools[],
): number => {
  const calculateDuration = (tools: (ValveInterventionTools | GatewayInterventionTools)[]) => {
    return tools.reduce((totalDuration, tool) => {
      return (
        totalDuration +
        (tool.problemInstance.type && tool.problemInstance.type in DURATION
          ? (DURATION[tool.problemInstance.type] ?? 0)
          : "unclogging" in tool && tool.unclogging
            ? (DURATION[ValveProblemType.UNCLOG_CHANGE_BODY] ?? 0)
            : 0)
      );
    }, 0);
  };

  return Math.ceil((calculateDuration(valves) + calculateDuration(gateways)) * 1.1);
};

export const calcEquipementsIntervention = (
  valves: ValveInterventionTools[],
  gateways: GatewayInterventionTools[],
): Equipment[] => {
  const isValveMin = (valve: ValveInterventionTools) =>
    valve.errors.some(e =>
      [MECA_PROBLEMS_ENUM.MOTOR_PROBLEM, MECA_PROBLEMS_ENUM.ENCODER_PROBLEM, MECA_PROBLEMS_ENUM.INA_PROBLEM].includes(
        // @ts-expect-error - TS doesn't know that e is a MECA_PROBLEMS_ENUM
        e,
      ),
    ) || valve.problemInstance.type === ValveProblemType.CHANGE_BATTERY;

  const isValveMax = (valve: ValveInterventionTools) =>
    valve.errors.some(e => e === MECA_PROBLEMS_ENUM.PLUGGED_FALSE || e === VALVE_ERRORS_ENUM.DISCONNECTED);

  const isGatewayMin = (gateway: GatewayInterventionTools) =>
    gateway.problemInstance.type === GatewayProblemType.REPLACE_GATEWAY;

  const isGatewayMax = (gateway: GatewayInterventionTools) => gateway.errors.includes(GATEWAY_ERRORS_ENUM.DISCONNECTED);

  const isBatteryMin = (valve: ValveInterventionTools) =>
    valve.problemInstance.type === ValveProblemType.CHANGE_BATTERY;

  const isBatteryMax = (valve: ValveInterventionTools) => valve.problemInstance.type === ValveProblemType.RESTART_VALVE;

  const { valveMin, valveMax, batteryMin, batteryMax } = valves.reduce(
    (acc, valve) => {
      if (isValveMin(valve)) acc.valveMin++;
      else if (isValveMax(valve)) acc.valveMax++;

      if (isBatteryMin(valve)) acc.batteryMin++;
      else if (isBatteryMax(valve)) acc.batteryMax++;
      return acc;
    },
    { valveMin: 0, valveMax: 0, batteryMin: 0, batteryMax: 0 },
  );

  const { gatewayMin, gatewayMax } = gateways.reduce(
    (acc, gateway) => {
      if (isGatewayMin(gateway)) acc.gatewayMin++;
      else if (isGatewayMax(gateway)) acc.gatewayMax++;
      return acc;
    },
    { gatewayMin: 0, gatewayMax: 0 },
  );

  return [
    {
      id: uniqueId(),
      name: "Piles",
      canEditName: false,
      min: batteryMin,
      max: batteryMax,
    },
    {
      id: uniqueId(),
      name: "Têtes th.",
      canEditName: false,
      min: valveMin,
      max: Math.ceil((valveMin + valveMax) * 1.2),
    },
    {
      id: uniqueId(),
      name: "Boxs",
      canEditName: false,
      min: gatewayMin,
      max: gatewayMax,
    },
  ];
};
