import clsx from "clsx";
import { clamp } from "lodash";
import { useIntl } from "react-intl";

import type { CardProps } from "@eisox/design-system";
import { Card, Circle } from "@eisox/design-system";
import { SunIcon, ThermometerIcon, WaterDropIcon, WindIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import {
  DATA_SEUILS,
  getAirQualityThreshold,
  getAirQualityV5Threshold,
  getHumidityThreshold,
  getLightThreshold,
  getTemperatureThreshold,
} from "@eisox/valves";

import { usePermissionsContext } from "~/providers";
import { kebabCaseToCamelCase } from "~/utils/stringUtils";

import styles from "./SensorCard.module.scss";

export type SensorCardType = "temperature" | "humidity" | "airQuality" | "light";

interface Sensors {
  icon: React.ReactNode;
  textToDisplay?: string;
  unity?: string;
  min: {
    value: number;
    text: string;
  };
  max: {
    value: number;
    text: string;
  };
  isAuthorized: boolean;
  color?: `${SensorCardType}-${string}`;
}

export interface SensorsCardProps extends Omit<CardProps, "type"> {
  type: SensorCardType;
  value?: number;
  isRoomHasValvesV5?: boolean;
}

export const SensorCard: React.FC<SensorsCardProps> = ({ type, value, isRoomHasValvesV5 = false, className }) => {
  const { formatMessage } = useIntl();
  const { permissions } = usePermissionsContext("SensorCard");

  const bem = useBem(styles);
  const cardStyle = bem("card-style");
  const jaugeStyle = bem("jauge-style");

  const sensorValue =
    value !== undefined
      ? type === "temperature"
        ? getTemperatureThreshold(value)
        : type === "humidity"
          ? getHumidityThreshold(value)
          : type === "airQuality"
            ? isRoomHasValvesV5
              ? getAirQualityV5Threshold(value)
              : getAirQualityThreshold(value)
            : getLightThreshold(value)
      : undefined;

  const sensors: Record<SensorCardType, Sensors> = {
    temperature: {
      icon: <ThermometerIcon />,
      unity: "°C",
      min: {
        value: 14,
        text: formatMessage({ id: "room.sensor.temperature.cold" }),
      },
      max: {
        value: 27,
        text: formatMessage({ id: "room.sensor.temperature.hot" }),
      },
      isAuthorized: true,
    },
    humidity: {
      icon: <WaterDropIcon />,
      unity: "%",
      min: {
        value: 0,
        text: formatMessage({ id: "room.sensor.humidity.dry" }),
      },
      max: {
        value: 100,
        text: formatMessage({ id: "room.sensor.humidity.wet" }),
      },
      isAuthorized: !!permissions.valve?.sensors?.humidity?.read,
    },
    airQuality: {
      icon: <WindIcon />,
      textToDisplay: sensorValue && formatMessage({ id: `airQuality.${kebabCaseToCamelCase(sensorValue)}` }),
      min: {
        value: DATA_SEUILS[isRoomHasValvesV5 ? "airQualityV5" : "airQuality"].MAX,
        text: formatMessage({ id: "room.sensor.airQuality.veryBad" }),
      },
      max: {
        value: DATA_SEUILS[isRoomHasValvesV5 ? "airQualityV5" : "airQuality"].MIN,
        text: formatMessage({ id: "room.sensor.airQuality.excellent" }),
      },
      isAuthorized: !!permissions.valve?.sensors?.indoorAirQuality?.read,
      //@ts-ignore
      color: sensorValue ? `${isRoomHasValvesV5 ? "airQualityV5" : "airQuality"}-${sensorValue}` : undefined,
    },
    light: {
      icon: <SunIcon />,
      unity: "lux",
      min: {
        value: DATA_SEUILS.light.MIN - 200,
        text: formatMessage({ id: "room.sensor.luminosity.dark" }),
      },
      max: {
        value: DATA_SEUILS.light.MAX + 200,
        text: formatMessage({ id: "room.sensor.luminosity.luminous" }),
      },
      isAuthorized: !!permissions.valve?.sensors?.light?.read,
    },
  };

  const {
    min: { value: minValue, text: minText },
    max: { value: maxValue, text: maxText },
    color,
    icon,
    textToDisplay,
    unity,
    isAuthorized,
  } = sensors[type];

  const jaugePosition = value && clamp(((value - minValue) * 100) / (maxValue - minValue), 0, 100);

  return (
    isAuthorized && (
      <Card className={clsx(cardStyle(), className)}>
        <Circle
          className={cardStyle("icon", {
            color: color || (sensorValue && `${type}-${sensorValue}`) || "gray",
          })}
          size={60}
        >
          {icon}
        </Circle>
        <h3>
          {textToDisplay || value || "--"}
          {unity && <span>{unity}</span>}
        </h3>
        <div className={jaugeStyle()}>
          <div className={jaugeStyle("jauge", { type: type })}>
            {jaugePosition !== undefined && (
              <span style={{ left: `${jaugePosition}%`, transform: `translateX(-${jaugePosition}%)` }} />
            )}
          </div>
          <div className={jaugeStyle("text")}>
            <p>{minText}</p>
            <p>{maxText}</p>
          </div>
        </div>
      </Card>
    )
  );
};
