import { useState } from "react";

import { useIntl } from "react-intl";

import { ArrowRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { ValveHistoryDialog } from "~/features/ValveHistory";
import { usePermissionsContext } from "~/providers";

import { useRoomContext } from "../../context";
import { hasPermissionRoomData } from "../../utils";
import { ConsignTemp } from "../ConsignTemp";
import type { SensorCardType } from "../SensorCard";
import { SensorCard } from "../SensorCard";

import styles from "./Stats.module.scss";

export const Stats: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const statsStyle = bem("stats");

  const { permissions } = usePermissionsContext("Stats");
  const { room, isRoomHasValvesV5, valves } = useRoomContext();

  const [historyOpen, setHistoryOpen] = useState(false);

  const sensorCardType: Record<SensorCardType, number | undefined> = {
    temperature: room.temperature,
    humidity: room.humidity,
    airQuality: room.airQuality,
    light: room.light,
  };

  return (
    <>
      <div className={statsStyle()}>
        <div className={statsStyle("sensors-cards")}>
          {Object.entries(sensorCardType).map(([key, value]) => (
            <SensorCard key={key} type={key as SensorCardType} value={value} isRoomHasValvesV5={isRoomHasValvesV5} />
          ))}
        </div>
        <div className={statsStyle("room-data")}>
          {permissions.valve?.correctedTemp?.read && hasPermissionRoomData(permissions) && <ConsignTemp />}
          {permissions.history?.valve.histoValve?.read && (
            <button className={statsStyle("history")} onClick={() => setHistoryOpen(true)}>
              {formatMessage({ id: "room.history" })}
              <ArrowRightIcon />
            </button>
          )}
        </div>
      </div>
      {historyOpen && <ValveHistoryDialog open onOpenChange={open => setHistoryOpen(open)} valves={valves} />}
    </>
  );
};
