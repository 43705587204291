import { RoundIcon } from "@eisox/design-system";
import { RefreshIcon } from "@eisox/icons";

import styles from "./Refresh.module.scss";

interface RefreshProps {
  onClick: () => void;
}

export const Refresh: React.FC<RefreshProps> = ({ onClick }) => (
  <RoundIcon
    size={30}
    onClick={onClick}
    backgroundColor="gray"
    iconColor="darkGray"
    className={styles.refresh}
    data-testid="refresh"
  >
    <RefreshIcon className={styles.refresh__icon} />
  </RoundIcon>
);
