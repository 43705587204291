import type { PropsWithChildren } from "react";
import React from "react";

import { useBem } from "@eisox/tools";

import { BoilerromLayout } from "~/stores";

import styles from "./Boilers.module.scss";

interface BoilersProps extends PropsWithChildren {
  layout: BoilerromLayout;
}

export const Boilers: React.FC<BoilersProps> = ({ layout, children }) => {
  const bem = useBem(styles);
  const boilersStyle = bem("boilers");

  const boilerCardHeight = 104;
  const rowGap = 10;

  return (
    <div
      className={boilersStyle({ one: layout === BoilerromLayout.TREE && React.Children.count(children) === 1 })}
      style={{ rowGap }}
    >
      {React.Children.map(children, (child, i) => (
        <>
          {React.cloneElement(child as React.ReactElement, { key: i })}
          <div
            className={boilersStyle("waterline")}
            style={{ top: i * boilerCardHeight + i * rowGap + boilerCardHeight / 2 }}
          />
        </>
      ))}
    </div>
  );
};
