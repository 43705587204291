import { useMemo } from "react";

import { cloneDeep } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import type { ErrorType, Option } from "@eisox/design-system";
import { ActionButton, Alert, Dialog, Select } from "@eisox/design-system";
import { ArrowRightIcon, CalendarIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { useBoilerRoomRealTimeProviderContext } from "~/features/BoilerRooms";
import type { Planning as PlanningType } from "~/socketio/types";
import {
  DAYS,
  convertTimeRangeToPlanningArray,
  convertTimeslotsToTimeRange,
  getActualDay,
  getHoursEnd,
  getHoursStart,
  mergeUpdatedPlanningToExistingPlanning,
} from "~/utils/planningUtils";

import styles from "./ReducedTemperatureTimeslotPopup.module.scss";

const NAME = "ReducedTemperatureTimeslotPopup";

export interface ReducedTemperatureTimeslotPopupType {
  dayStart: DAYS;
  hourStart: string;
  dayEnd: DAYS;
  hourEnd: string;
  planningType: "occupation" | "inoccupation" | "thermalShock";
}

interface ReducedTemperatureTimeslotPopupProps {
  open: boolean;
  onClose: VoidFunction;
  planning?: PlanningType;
  weekPlannings?: PlanningType[];
}

export const ReducedTemperatureTimeslotPopup: React.FC<ReducedTemperatureTimeslotPopupProps> = ({
  open,
  onClose,
  planning,
  weekPlannings,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const reducedTemperatureTimeslotPopupStyle = bem("reduced-temperature-timeslot-popup");
  const contentStyle = bem("content");
  const alertStyle = bem("alert");
  const footerStyle = bem("footer");

  const planningToUpdate = useMemo(
    () =>
      planning && weekPlannings
        ? convertTimeslotsToTimeRange(
            planning,
            weekPlannings.filter(p => p.userDefined),
          )
        : undefined,
    [],
  );

  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<ReducedTemperatureTimeslotPopupType>({
    defaultValues: {
      dayStart: planningToUpdate?.current.dayStart ?? getActualDay(),
      hourStart: planningToUpdate?.current.hourStart,
      dayEnd: planningToUpdate?.current.dayEnd ?? getActualDay(),
      hourEnd: planningToUpdate?.current.hourEnd,
      planningType: planning?.planningType,
    },
  });

  const { planningType, dayStart, hourStart, dayEnd, hourEnd } = watch();

  const { boilerroomId, networkId } = useParams() as { boilerroomId: string; networkId: string };

  const { useUpdateBoilerRoom } = useBoilerRoomRealTimeProviderContext(NAME);

  const { mutate } = useUpdateBoilerRoom({
    onSuccess: () => onClose(),
  });

  const handleDeleteTimeslot = () => {
    const keepedPlannings = planningToUpdate?.otherTimeslots as PlanningType[];
    const planningPayload = cloneDeep(keepedPlannings);
    planningPayload.forEach(p => {
      p.type = p.planningType;
      delete p.planningType;
    });
    mutate([{ id: boilerroomId, heatingNetworks: [{ id: networkId, plannings: planningPayload }] }]);
  };

  const onSubmit = (data: ReducedTemperatureTimeslotPopupType) => {
    const updatedPlannings = convertTimeRangeToPlanningArray(
      data.dayStart,
      data.hourStart,
      data.dayEnd,
      data.hourEnd,
      data.planningType,
    );
    const plannings = mergeUpdatedPlanningToExistingPlanning(
      updatedPlannings,
      planningToUpdate?.otherTimeslots ?? weekPlannings?.filter(p => p.userDefined),
    ) as PlanningType[];
    const planningPayload: PlanningType[] = cloneDeep(plannings);
    planningPayload.forEach(p => {
      p.type = p.planningType;
      delete p.planningType;
    });
    mutate([{ id: boilerroomId, heatingNetworks: [{ id: networkId, plannings: planningPayload }] }]);
  };

  const renderValue = (field: "hour" | "day") => (value?: string, _?: ErrorType) => (
    <p>
      {field === "day"
        ? formatMessage({
            id: value ? `plannings.${value}.long` : `network.content.planning.title.popup.day.placeholder`,
          })
        : value
          ? value
          : formatMessage({ id: "network.content.planning.title.popup.hour.placeholder" })}
    </p>
  );

  const renderOption = (option: Option, _: boolean) => <p>{option.name}</p>;

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose()}>
      <Dialog.Content
        className={reducedTemperatureTimeslotPopupStyle()}
        title={formatMessage({ id: `network.content.planning.title.popup.title.${planningToUpdate ? "edit" : "add"}` })}
        icon={<CalendarIcon />}
      >
        <div className={contentStyle()}>
          <h2 className={contentStyle("text", { type: "label" })}>
            {formatMessage({ id: "network.content.planning.title.popup.start" })}
          </h2>
          <Controller
            control={control}
            name="dayStart"
            rules={{ required: formatMessage({ id: "error.emptyField" }) }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                options={Object.values(DAYS).map(d => ({
                  value: d,
                  name: formatMessage({ id: `plannings.${d}.long` }),
                }))}
                renderValue={renderValue("day")}
                renderOption={renderOption}
                onChange={(value: string) => onChange(value)}
                error={errors.dayStart}
              />
            )}
          />
          <Controller
            control={control}
            name="hourStart"
            rules={{ required: formatMessage({ id: "error.emptyField" }) }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                options={getHoursStart(hourEnd, dayStart, dayEnd).map(h => ({
                  value: h,
                  name: h.replace(":", "h"),
                }))}
                renderValue={renderValue("hour")}
                renderOption={renderOption}
                onChange={(value: string) => onChange(value)}
                error={errors.hourStart}
              />
            )}
          />
          <h2 className={contentStyle("text", { type: "label" })}>
            {formatMessage({ id: "network.content.planning.title.popup.end" })}
          </h2>
          <Controller
            control={control}
            name="dayEnd"
            rules={{ required: formatMessage({ id: "error.emptyField" }) }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                options={Object.values(DAYS).map(d => ({
                  value: d,
                  name: formatMessage({ id: `plannings.${d}.long` }),
                }))}
                renderValue={renderValue("day")}
                renderOption={renderOption}
                onChange={(value: string) => onChange(value)}
                error={errors.dayEnd}
              />
            )}
          />
          <Controller
            control={control}
            name="hourEnd"
            rules={{ required: formatMessage({ id: "error.emptyField" }) }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                options={getHoursEnd(hourStart, dayStart, dayEnd).map(h => ({
                  value: h,
                  name: h.replace(":", "h"),
                }))}
                renderValue={renderValue("hour")}
                renderOption={renderOption}
                onChange={(value: string) => onChange(value)}
                error={errors.hourEnd}
              />
            )}
          />
          <h2 className={contentStyle("text", { type: "label" })}>
            {formatMessage({ id: "network.content.planning.title.popup.type.label" })}
          </h2>
          <Controller
            control={control}
            name="planningType"
            rules={{ required: formatMessage({ id: "error.emptyField" }) }}
            render={({ field: { value, onChange } }) => (
              <Select
                value={value}
                options={["occupation", "inoccupation"].map(v => ({
                  name: formatMessage({ id: `network.content.planning.title.popup.type.${v}` }),
                  value: v,
                }))}
                renderValue={(value?: string, _?: ErrorType) => (
                  <p>
                    {value
                      ? formatMessage({ id: `network.content.planning.title.popup.type.${value}` })
                      : formatMessage({ id: "network.content.planning.title.popup.type.placeholder" })}
                  </p>
                )}
                renderOption={renderOption}
                onChange={(value: string) => onChange(value)}
                error={errors.planningType}
              />
            )}
          />
        </div>
        {dayStart === dayEnd && hourStart && hourEnd && hourStart === hourEnd && (
          <Alert className={alertStyle()}>
            <p>
              {formatMessage(
                { id: "network.content.planning.title.popup.alert" },
                {
                  type:
                    planningType !== undefined
                      ? formatMessage({ id: `network.content.planning.title.popup.type.${planningType}` })
                      : undefined,
                },
              )}
            </p>
          </Alert>
        )}
        <div className={footerStyle()}>
          <ActionButton
            variant="cancel"
            className={footerStyle("button", { type: "cancel" })}
            text={formatMessage({
              id: `network.content.planning.title.popup.footer.${planning ? "delete" : "cancel"}`,
            })}
            onClick={() => (planning ? handleDeleteTimeslot() : onClose())}
          />
          <ActionButton
            text={formatMessage({ id: `network.content.planning.title.popup.footer.${planning ? "edit" : "add"}` })}
            icon={<ArrowRightIcon />}
            onClick={() => handleSubmit(onSubmit)()}
            disabled={!isDirty}
            rounded
          />
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

ReducedTemperatureTimeslotPopup.displayName = NAME;
