import bcrypt from "bcryptjs";
import sha256 from "crypto-js/sha256";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActionButtonV2, PasswordInput, UnProtectedRouteResponsiveCard } from "@eisox/design-system";
import { CheckedIcon } from "@eisox/icons";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { FieldContainer } from "~/UI";
import type { ResetPasswordRequestBody, ResetPasswordRequestParams } from "~/apiV2";
import { env } from "~/configuration";
import { usePasswordConditions } from "~/hooks";
import { i18next } from "~/i18n";
import { passwordSchema } from "~/utils";

import styles from "./ResetPassword.module.scss";

type ResetPasswordSchema = z.infer<typeof schema>;
const schema = z
  .object({
    password: passwordSchema,
    confirmPassword: z.string(),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: i18next.t("resetPassword.errors.matchPassword"),
    path: ["confirmPassword"],
  });

interface PageProps {
  token: string;
  submit: (body: ResetPasswordRequestBody & ResetPasswordRequestParams) => void;
}

const ResetPasswordPage: React.FC<PageProps> = ({ token, submit }) => {
  const { validatePassword, passwordErrors } = usePasswordConditions();

  const { t } = useTranslation();

  const {
    register,
    formState: { errors, isDirty },
    control,
    handleSubmit,
  } = useForm<ResetPasswordSchema>({
    criteriaMode: "all",
    resolver: zodResolver(schema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = (data: ResetPasswordSchema) => {
    const body = {
      token,
      hashedPassword: bcrypt.hashSync(sha256(data.password).toString(), 10),
    };
    submit(body);
  };

  return (
    <UnProtectedRouteResponsiveCard version={env.VERSION}>
      <form className={styles.resetPassword} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.resetPassword__container}>
          <Controller
            control={control}
            name="password"
            render={({ field: { value, onChange } }) => (
              <FieldContainer label={t("resetPassword.password")}>
                <PasswordInput
                  error={!!errors.password}
                  value={value}
                  onChange={e => {
                    onChange(e);
                    validatePassword(e.target.value);
                  }}
                />
              </FieldContainer>
            )}
          />
          <FieldContainer label={t("resetPassword.confirmPassword")} error={errors.confirmPassword}>
            <PasswordInput {...register("confirmPassword")} />
          </FieldContainer>

          <div className={styles.passwordRules}>
            {Object.entries(passwordErrors).map(([key, { isValid, translation }]) => (
              <div>
                <CheckedIcon aria-checked={isValid} aria-invalid={errors.password?.message === key} />
                <p>{translation}</p>
              </div>
            ))}
          </div>
        </div>
        <ActionButtonV2 type="submit" disabled={!isDirty}>
          {t("resetPassword.submit")}
        </ActionButtonV2>
      </form>
    </UnProtectedRouteResponsiveCard>
  );
};

export { ResetPasswordPage };
