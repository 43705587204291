import type { RouteObject } from "react-router-dom";

import type { QueryClient } from "@tanstack/react-query";

import { route as invalidTokenRoute } from "./(invalidToken)/route";
import { route as tokenRoute } from "./(token)/route";
import { route as recoverAccountRoute } from "./recoverAccount/route";
import { route as signInRoute } from "./signIn/route";

const route = (queryClient: QueryClient): RouteObject[] => [
  { ...tokenRoute(queryClient) },
  { ...signInRoute },
  { ...recoverAccountRoute },
  { ...invalidTokenRoute },
];

export { route };
