import { useEffect, useState } from "react";

import { ItemType } from "../components";

import _ from "lodash";
import { ReactZoomPanPinchState } from "react-zoom-pan-pinch";

interface usDragItemProps {
  isDragging: boolean;
  onMouseDown: (e: React.MouseEvent, item: ItemType) => void;
  onItemMove: React.MouseEventHandler<HTMLDivElement>;
}

export const useDragItem = (
  rzppState: ReactZoomPanPinchState,
  items: ItemType[],
  itemRefs: { [id: string]: HTMLDivElement | null },
  imageNaturalSize: { width: number; height: number },
  onChange?: (items: ItemType[]) => void,
): usDragItemProps => {
  const { width: imageNaturalWidth, height: imageNaturalHeight } = imageNaturalSize;

  const [isDragging, setIsDragging] = useState(false);
  const [draggedItem, setDraggedItem] = useState<ItemType | undefined>();

  const onItemMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    if (!draggedItem) return;
    const bounds = event.currentTarget.getBoundingClientRect();
    const x = ((event.clientX - bounds.left - rzppState.positionX) * 100) / (imageNaturalWidth * rzppState.scale);
    const y = ((event.clientY - bounds.top - rzppState.positionY) * 100) / (imageNaturalHeight * rzppState.scale);

    const itemRef = itemRefs[draggedItem.id];
    if (itemRef) {
      if (x >= 0 && x <= 100) {
        itemRef.style.left = `${x}%`;
      }
      if (y >= 0 && y <= 100) {
        itemRef.style.top = `${y}%`;
      }
    }
  };

  function findAndUpdateItem(items: ItemType[], idToFind: string, newX: number, newY: number): void {
    for (const item of items) {
      if (item.id === idToFind) {
        item.x = newX;
        item.y = newY;
        return;
      }
      if (item.items) {
        findAndUpdateItem(item.items, idToFind, newX, newY);
      }
    }
  }

  useEffect(() => {
    const handleMouseUp = (e: MouseEvent) => {
      e.preventDefault();
      document.removeEventListener("mouseup", handleMouseUp);
      if (draggedItem?.id) {
        const newItems = _.cloneDeep(items);
        findAndUpdateItem(
          newItems,
          draggedItem.id,
          parseFloat(itemRefs[draggedItem.id]?.style.left as string),
          parseFloat(itemRefs[draggedItem.id]?.style.top as string),
        );
        onChange && onChange(newItems);
      }
      setIsDragging(false);
      setDraggedItem(undefined);
    };

    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const onMouseDown = (e: React.MouseEvent, item: ItemType) => {
    e.preventDefault();
    setIsDragging(true);
    setDraggedItem(item);
  };

  return {
    isDragging,
    onItemMove,
    onMouseDown,
  };
};
