/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StatusCodes } from "http-status-codes";

import { createQueryKeys } from "@lukemorales/query-key-factory";

import { DEFAULT_TEMPERATURES } from "~/constants";
import { transformTemperature } from "~/utils";

import { client } from "../api";
import type { Room } from "../types";

const getRooms = async (houseId: string): Promise<Room[]> => {
  const { data, error, response } = await client.GET("/houses/{houseId}/rooms", {
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) return [];
  else if (error || !data?.message?.rooms) throw new Error(error?.message ?? "Error fetching rooms");

  return data.message.rooms.map(r => ({
    ...r,
    id: r.id!,
    name: r.name!,
    roomType: r.roomType!,
    plan: r.plan as Room["plan"],
    isAutoPrecomf: r.isAutoPrecomf ?? true,
    isPreComfRoom: !!r.isPreComfRoom,
    isFrostFree: !!r.isFrostFree,
    isSwitchEnabled: !!r.isSwitchEnabled,
    groupNames: r.groupNames ?? [],
    nightTemperature: r.nightTemperature ? transformTemperature(r.nightTemperature) : DEFAULT_TEMPERATURES.NIGHT,
    comfortTemperature: r.comfortTemperature
      ? transformTemperature(r.comfortTemperature)
      : DEFAULT_TEMPERATURES.COMFORT,
  }));
};

export const room = createQueryKeys("room", {
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getRooms(houseId),
  }),
});
