import { env } from "~/configuration";
import { POST_METHOD } from "~/constants/fetchConstants";
import { internalFetchByUrl } from "~/helpers/communication/FetchApi";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { version } from "~/routesStatic/routesExpress";
import type { BoilerRoom } from "~/socketio/types";
import { useUserStore } from "~/stores";

const ServerUrl = env.SERVER_URL;

export interface BoilerRoomHistoryBody {
  ranges: { startDate: string; endDate: string }[];
  fields?: string[];
  moduleIds: string[];
  boilerRooms?: Record<string, any>;
}

export interface BoilerroomHistoryResponseType {
  message: {
    moduleIds?: string[];
    boilerRooms?: (BoilerRoom & { createdAt: string })[];
    createdAt?: string;
  }[];
}

export const getBoilerRoomHistory = (
  houseId: string,
  body: BoilerRoomHistoryBody,
): Promise<FetchResponse<BoilerroomHistoryResponseType>> => {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const routeBoilerRoomHistory = ServerUrl + version + `history/houses/${houseId}/modules`;
  return internalFetchByUrl(routeBoilerRoomHistory, POST_METHOD, customHeaders, body);
};
