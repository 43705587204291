import { cx } from "class-variance-authority";

import { Card, Tooltip } from "@eisox/design-system";

import styles from "./HouseNameCard.module.scss";

interface HouseNameCardProps {
  name: string;
  collapsed?: boolean;
}

export const HouseNameCard: React.FC<HouseNameCardProps> = ({ name, collapsed = false }) => {
  const houseName = collapsed ? name.trim().slice(0, 1) : name;

  const card = (
    <Card className={cx(styles.houseNameCard, collapsed && styles.houseNameCard_collapsed)}>{houseName}</Card>
  );

  if (collapsed) {
    return (
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{card}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content side="right" sideOffset={20} className={styles.houseNameCard__tooltip}>
            {name}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    );
  }

  return card;
};
