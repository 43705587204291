import { t } from "i18next";
import { sortBy } from "lodash";
import { $enum } from "ts-enum-util";

import {
  GATEWAY_ERRORS_ENUM,
  GATEWAY_WARNINGS_ENUM,
  HIGHT_TEMP,
  LOW_FROST_FREE_TEMP,
  LOW_TEMP,
  VALVE_ERRORS_ENUM,
  VALVE_WARNINGS_ENUM,
} from "@eisox/problems-handler";
import { ROOM_ERRORS_ENUM, ROOM_TEMP_PROBLEMS_ENUM, ROOM_WARNINGS_ENUM } from "@eisox/problems-handler";
import { MECA_PROBLEMS_ENUM } from "@eisox/valves";

import type { GatewaysWithProblem, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";

enum VALVE_GROUPED_PROBLEMS {
  MECA_PROBLEMS = "MECA_PROBLEMS",
}

const getFiltersOptions = (items: "gateways" | "valves" | "rooms") =>
  ({
    gateways: [...$enum(GATEWAY_ERRORS_ENUM).getValues(), ...$enum(GATEWAY_WARNINGS_ENUM).getValues()].map(e => ({
      value: e,
      name: t(`advancedMaintenance.filters.gateways.${e}`),
    })),
    valves: [
      ...$enum(VALVE_ERRORS_ENUM).getValues(),
      ...$enum(VALVE_WARNINGS_ENUM).getValues(),
      ...$enum(VALVE_GROUPED_PROBLEMS).getValues(),
    ].map(e => ({
      value: e,
      name: t(`advancedMaintenance.filters.valves.${e}`),
    })),
    rooms: [
      ...$enum(ROOM_ERRORS_ENUM).getValues(),
      ...$enum(ROOM_TEMP_PROBLEMS_ENUM).getValues(),
      ...$enum(ROOM_WARNINGS_ENUM).getValues(),
    ].map(e => ({
      value: e,
      name: t(`advancedMaintenance.filters.rooms.${e}`, {
        t: LOW_FROST_FREE_TEMP,
        c: HIGHT_TEMP.TEMP,
        min_c: HIGHT_TEMP.COMFORT_TEMP,
        a: LOW_TEMP.TEMP,
        max_a: LOW_TEMP.ABSENCE_TEMP,
      }),
    })),
  })[items];

const getSortOptions = (items: "gateways" | "valves" | "rooms") =>
  ({
    gateways: [
      { value: "gatewayName", name: t("advancedMaintenance.sort.gatewayName") },
      { value: "lastGatewayActivity", name: t("advancedMaintenance.sort.communicationDate") },
    ],
    valves: [
      { value: "roomName", name: t("advancedMaintenance.sort.roomName") },
      { value: "gatewayName", name: t("advancedMaintenance.sort.gatewayName") },
      { value: "updatedAt", name: t("advancedMaintenance.sort.communicationDate") },
    ],
    rooms: [],
  })[items];

const getFiltersItems = <T extends ValvesWithProblem | GatewaysWithProblem | RoomsWithProblem>(
  items: T[],
  filters: string[],
): T[] => {
  const transformedFilters = [...filters];
  if (transformedFilters.length === 0) return items;
  // MECA_PROBLEMS_ENUM group by MECA_PROBLEMS
  if (transformedFilters.includes(VALVE_GROUPED_PROBLEMS.MECA_PROBLEMS))
    transformedFilters.push(...$enum(MECA_PROBLEMS_ENUM).getValues());
  return items.filter(item =>
    // @ts-expect-error : filter not correctly type
    transformedFilters.some(filter => item.errors.includes(filter) || item.warnings.includes(filter)),
  );
};

const getDisplayedGateways = (
  gateways: GatewaysWithProblem[],
  search: string,
  filters: string[],
  sort: string,
): GatewaysWithProblem[] => {
  let filteredGateways = getFiltersItems(gateways, filters);
  if (search) {
    const propertiesToCheck = [
      "uid",
      "mac",
      "gatewayName",
      "softwareVersion",
      "hardwareVersion",
    ] as (keyof GatewaysWithProblem)[];
    filteredGateways = filteredGateways.filter(gateway =>
      propertiesToCheck.some(property => gateway[property]?.toString().toLowerCase().includes(search.toLowerCase())),
    );
  }

  return sortBy(filteredGateways, sort);
};

const getDisplayedValves = (
  valves: ValvesWithProblem[],
  search: string,
  filters: string[],
  sort: string,
): ValvesWithProblem[] => {
  let filteredValves = getFiltersItems(valves, filters);
  if (search) {
    const propertiesToCheck = [
      "uid",
      "mac",
      "roomName",
      "gatewayName",
      "softwareVersion",
      "hardwareVersion",
    ] as (keyof ValvesWithProblem)[];
    filteredValves = filteredValves.filter(valve =>
      propertiesToCheck.some(property => valve[property]?.toString().toLowerCase().includes(search.toLowerCase())),
    );
  }
  return sortBy(filteredValves, sort);
};

const getDisplayedRooms = (rooms: RoomsWithProblem[], search: string, filters: string[]): RoomsWithProblem[] => {
  let filteredRooms = getFiltersItems(rooms, filters);
  if (search) {
    const propertiesToCheck = ["name"] as (keyof RoomsWithProblem)[];
    filteredRooms = filteredRooms.filter(room =>
      propertiesToCheck.some(property => room[property]?.toString().toLowerCase().includes(search.toLowerCase())),
    );
  }
  return sortBy(filteredRooms, "name");
};

export { getDisplayedGateways, getDisplayedRooms, getDisplayedValves, getFiltersOptions, getSortOptions };
