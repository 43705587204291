import * as React from "react";

import { IconProps } from "./types";

export const UploadIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M12.875 10.4375V12.875H3.125V10.4375H1.5V12.875C1.5 13.7688 2.23125 14.5 3.125 14.5H12.875C13.7688 14.5 14.5 13.7688 14.5 12.875V10.4375H12.875ZM3.9375 5.5625L5.08313 6.70813L7.1875 4.61188V11.25H8.8125V4.61188L10.9169 6.70813L12.0625 5.5625L8 1.5L3.9375 5.5625Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default UploadIcon;
