import type { BORDER_ROUTER_STATUS } from "@eisox/gateways";
import type { paths } from "@eisox/webapp-api-specification";

/* -------------------------------------------------------------------------------------------------
 * Gateway
 * -----------------------------------------------------------------------------------------------*/
enum GatewayHardwareVersion {
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
  V4 = "V4",
  "V4.2" = "V4.2",
  V5 = "V5",
}

type OriginalGateway = NonNullable<
  NonNullable<
    paths["/houses/{houseId}/gateways"]["get"]["responses"]["200"]["content"]["application/json"]["message"]
  >["gateways"]
>[number];

type Gateway = Omit<
  MakeRequired<
    OriginalGateway,
    "id" | "uid" | "gatewayName" | "brModRf" | "isInternetGateway" | "isWifiEnabled" | "status"
  >,
  "meshs"
> & {
  borderRouterStatus: BORDER_ROUTER_STATUS[];
  hardwareVersion?: keyof typeof GatewayHardwareVersion;
  plan: NonNullable<Required<OriginalGateway["plan"]>>;
  meshs: MakeRequired<NonNullable<OriginalGateway["meshs"]>[number], "id">[];
};

/* -------------------------------------------------------------------------------------------------
 * Gateways House
 * -----------------------------------------------------------------------------------------------*/

type GatewaysHousePath = "/houses/{houseId}/gateways";

type OriginalGatewaysHouseResponseBody =
  paths[GatewaysHousePath]["get"]["responses"]["200"]["content"]["application/json"];

type GatewaysHouseParams = paths[GatewaysHousePath]["get"]["parameters"]["path"];

type GatewaysHouseResponseBody = Omit<OriginalGatewaysHouseResponseBody, "message"> & {
  message: Omit<OriginalGatewaysHouseResponseBody["message"], "gateways"> & {
    gateways: Gateway[];
  };
};

type UpdateGatewayRequestBody =
  paths["/houses/{idHouse}/gateways"]["patch"]["requestBody"]["content"]["application/json"];

/* -------------------------------------------------------------------------------------------------
 * Notify
 * -----------------------------------------------------------------------------------------------*/

type NotifyPath = "/houses/notify/{idHouse}";

type NotifyRequestBody = paths[NotifyPath]["post"]["requestBody"]["content"]["application/json"];

export type {
  GatewaysHouseParams,
  GatewaysHousePath,
  Gateway,
  NotifyRequestBody,
  UpdateGatewayRequestBody,
  GatewaysHouseResponseBody,
};
export { GatewayHardwareVersion };
