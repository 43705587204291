import { CrossIcon } from "@eisox/icons";

import { RoundIcon, Sheet } from "..";

import styles from "./Drawer.module.scss";

export interface DrawerProps extends React.ComponentPropsWithoutRef<"div"> {
  title: string;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  defaultOpen?: boolean;
  children?: React.ReactNode;
  overlay?: {
    style?: React.CSSProperties;
    className?: string;
  };
}

export const Drawer: React.FC<DrawerProps> = props => {
  const { title, open, onOpenChange, defaultOpen, children, overlay, ...drawerProps } = props;

  return (
    <Sheet.Root open={open} onOpenChange={onOpenChange} defaultOpen={defaultOpen}>
      <Sheet.Portal>
        <Sheet.Overlay {...overlay} />
        <Sheet.Content {...drawerProps}>
          <div className={styles.drawer}>
            <div className={styles.drawer__header}>
              <h1>{title}</h1>
              <Sheet.Close asChild>
                <RoundIcon iconColor="blue" style={{ cursor: "pointer" }}>
                  <CrossIcon />
                </RoundIcon>
              </Sheet.Close>
            </div>
            <div className={styles.drawer__content}>{children}</div>
          </div>
        </Sheet.Content>
      </Sheet.Portal>
    </Sheet.Root>
  );
};
