import { useEffect, useState } from "react";

import { useFetcher } from "react-router-dom";

import { showError } from "~/UI/layouts/ErrorPopup";
import { loading } from "~/UI/layouts/LoadingPopup";
import { API } from "~/types/API";

interface ActionProps<T> {
  displayLoadingPopup?: boolean;
  convertFormDataToJson?: boolean;
  onSuccess?: (response?: T) => void;
  onError?: VoidFunction;
}

export const useAction = <T>({
  displayLoadingPopup = true,
  convertFormDataToJson = true,
  onSuccess,
  onError,
}: ActionProps<T>) => {
  const fetcher = useFetcher<API.Response<T>>();

  const state = fetcher.state;
  const response = fetcher.data;

  const [prevState, setPrevState] = useState<API.REQUEST_STATUS>(state);

  useEffect(() => {
    if (response && state === "idle" && (prevState === "loading" || prevState === "submitting")) {
      loading(false);

      /* 
      1 - response.message = [{status: 200, message: ""}]
      2 - response.message = [{status: 207, message: [{status: 200, message: ""}, {status: 400, message: ""}, ...]]
      3 - response.message = [{status: 200, message: ""}, {status: 400, message: ""}, ...]
      */
      if (response.message?.length === 1) {
        if (
          response.message[0].status === API.HTTP_STATUS.OK ||
          (Array.isArray(response.message[0].message) &&
            response.message[0].message.every(m => m.status === API.HTTP_STATUS.OK))
        ) {
          onSuccess && onSuccess(response.message[0].message);
        } else {
          onError
            ? onError()
            : showError(
                response.restError,
                response.url,
                response.method,
                response.body,
                response.message[0].message as string,
              );
        }
      }
      if (response.message?.length > 1) {
        // 207
        if (
          response.message.some(m =>
            Array.isArray(m.message) && m.message.length > 1
              ? m.message.some(m2 => m2.status !== API.HTTP_STATUS.OK)
              : m.status !== API.HTTP_STATUS.OK,
          )
        ) {
          onError
            ? onError()
            : showError(
                response.restError,
                response.url,
                response.method,
                response.body,
                response.message as string | Array<{ message?: string; status: API.HTTP_STATUS; [key: string]: any }>,
              );
        }
        onSuccess && onSuccess();
      }
    }
    setPrevState(fetcher.state);
  }, [fetcher.state]);

  const submit = (data: Record<string, any> | FormData | null, method: API.HTTP_METHOD, route: string) => {
    let body;
    if (convertFormDataToJson) {
      body = new FormData();
      body.append("json", JSON.stringify(data));
    } else {
      body = data;
    }
    fetcher.submit(body as FormData | null, {
      method: method,
      action: route,
      encType: "multipart/form-data",
    });
    displayLoadingPopup && loading(true);
  };

  return {
    Form: fetcher.Form,
    state: fetcher.state,
    response: response,
    formData: fetcher.formData,
    submit: submit,
  };
};
