import * as React from "react";

import { IconProps } from "./types";

export const LightingIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M9.05951 2.31367H9.06175H9.05951ZM9.05951 2.31367L8.14152 7.13683L11.4671 7.14801L6.94036 13.6865L7.85837 8.38028L4.53297 8.36869L9.05951 2.31367ZM9.06013 1.50003C9.03816 1.50003 9.01621 1.50083 8.99465 1.50244C8.85104 1.51405 8.75583 1.56205 8.64295 1.63793C8.57938 1.68031 8.52207 1.73139 8.47269 1.78967C8.45786 1.80717 8.44387 1.82536 8.43077 1.84419L3.87167 7.87805C3.69165 8.12479 3.66521 8.45047 3.80353 8.72264C3.94165 8.99481 4.22093 9.1673 4.52707 9.16954L6.87568 9.18663L6.13729 13.537C6.06711 13.9109 6.26646 14.2836 6.61734 14.4337C6.71932 14.4775 6.82916 14.5001 6.94015 14.5C7.19584 14.5 7.40596 14.3804 7.56362 14.1644L12.1284 7.62582C12.3084 7.37907 12.3347 7.05299 12.1966 6.78123C12.0584 6.50907 11.7792 6.33656 11.473 6.33433L9.12441 6.33026L9.85487 2.50507C9.87035 2.44244 9.87814 2.37817 9.87807 2.31366C9.87807 1.87347 9.52758 1.51465 9.08902 1.50041C9.07927 1.5 9.06969 1.5 9.06015 1.5L9.06013 1.50003Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default LightingIcon;
