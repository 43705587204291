import { StatusCodes } from "http-status-codes";
import type { Middleware } from "openapi-fetch";
import createClient from "openapi-fetch";

import type { paths } from "@eisox/webapp-api-specification";

import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { UnauthorizedException } from "./exceptions";

const middleware: Middleware = {
  onRequest({ request }) {
    request.headers.set("X-Auth-Token", useUserStore.getState().token);
    return request;
  },
  onResponse({ response }) {
    if ((response.status as StatusCodes) === StatusCodes.UNAUTHORIZED) {
      throw new UnauthorizedException();
    }
    return response;
  },
};

const client = createClient<paths>({ baseUrl: `${env.SERVER_URL}/api/v3/` });
client.use(middleware);

export { client };
