import type { PropsWithChildren } from "react";
import { useState } from "react";

import { useIntl } from "react-intl";

import { ActionButtonV2 as ActionButton, Dialog } from "@eisox/design-system";
import { BracesIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { VariableDetails } from "../VariableDetails/VariableDetails";

import styles from "./VariableDetailsDialog.module.scss";

const NAME = "DetailsVariables";

type DetailsVariables = PropsWithChildren;

export const VariableDetailsDialog: React.FC<DetailsVariables> = ({ children }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const detailsVariablesStyle = bem("details-variables");

  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content
        className={detailsVariablesStyle()}
        title={formatMessage({ id: "boilerRoom.header.actions.variablesDetails.title" })}
        icon={<BracesIcon />}
      >
        <VariableDetails  />
        <Dialog.Close asChild>
          <ActionButton className={detailsVariablesStyle("close-button")} variant="cancel">
            {formatMessage({ id: "boilerRoom.header.actions.variablesDetails.close" })}
          </ActionButton>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Root>
  );
};

VariableDetailsDialog.displayName = NAME;
