import { Permission } from "..";

export const managerPermissions: Permission = {
  house: {
    read: true,
    lastPresenceDetected: {
      read: true,
    },
  },
  valve: {
    error: true,
    warning: true,
    read: true,
    mac: { update: true },
    stateData: {
      autoConf: { read: true },
      autoPreConf: {
        read: true,
      },
      anticipation: {
        read: true,
      },
      openWin: {
        read: true,
      },
    },
    sensors: {
      humidity: {
        read: true,
      },
      light: {
        read: true,
      },
      indoorAirQuality: {
        read: true,
      },
    },
    closing: {
      read: true,
    },
    correctedTemp: {
      read: true,
    },
  },
  gateway: {
    error: true,
    warning: true,
    read: true,
    mac: { update: true },
  },
  plan: {
    read: true,
  },
  room: {
    error: true,
    warning: true,
    read: true,
    isFrostFree: {
      update: true,
    },
  },
  planning: {
    create: true,
    update: true,
  },
  history: {
    valve: { histoValve: { read: true } },
  },
  page: {
    maintenance: true,
    ressource: true,
    settings: true,
  },
};
