import { useState } from "react";

import { NavLink, useParams, useRouteLoaderData } from "react-router-dom";

import { PostModuleTypeEnum } from "@eisox/backend_webapp_api";
import { Card, SelectV2 as Select, Typography } from "@eisox/design-system";
import { RedirectionIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { houseLoader } from "~/UI/screens";
import { env } from "~/configuration/envConfig";
import { usePermissionsContext } from "~/providers";
import { idLoaderHouse } from "~/routes/utils";
import { useUserStore } from "~/stores";
import { concatBoilerroomNamesFromModule, getPLCPageLink } from "~/utils";

import styles from "./GTB.module.scss";

export const GTB: React.FC = () => {
  const bem = useBem(styles);
  const gtbStyle = bem("gtb");
  const selectStyle = bem("select");

  const { houseId } = useParams() as { houseId: string };
  const userToken = useUserStore.use.token();

  const { boilerroomPos, modules } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;
  const { permissions } = usePermissionsContext("GTB");

  if (!permissions.page?.gtb) throw new Error("Unauthorized");

  const selectableModules = boilerroomPos.filter(b =>
    modules
      .filter(m =>
        [PostModuleTypeEnum.EclypseBoilerRoom as string, PostModuleTypeEnum.NiagaraBoilerRoom as string].includes(
          m.type,
        ),
      )
      .filter(m => m.isProxyEnabled)
      .flatMap(m => m.id)
      .includes(b.moduleId),
  );

  // Select eclypse module by default if exist
  const initialModule =
    selectableModules.find(sm =>
      modules.find(m => m.id === sm.moduleId && m.type === PostModuleTypeEnum.EclypseBoilerRoom),
    ) ?? selectableModules[0];

  const [currentModulePos, setCurrentModulePos] = useState(initialModule);
  const currentModule = modules.find(m => m.id === currentModulePos.moduleId);

  return (
    <main className={gtbStyle()}>
      {selectableModules.length > 1 && (
        <Select
          classNames={{ trigger: selectStyle() }}
          options={selectableModules.map(m => {
            const name = concatBoilerroomNamesFromModule(m);
            return {
              name: name !== "" ? name : (modules.find(mo => mo.id === m.moduleId)?.url ?? "-"),
              value: m.moduleId,
            };
          })}
          value={currentModulePos.moduleId}
          renderOption={(name, value) =>
            modules.find(m => m.id === value)?.type === PostModuleTypeEnum.NiagaraBoilerRoom ? (
              <NavLink
                className={selectStyle("item")}
                to={getPLCPageLink(userToken, houseId, value as string)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Typography>{name}</Typography>
                <RedirectionIcon />
              </NavLink>
            ) : (
              <Typography>{name}</Typography>
            )
          }
          onChange={value => setCurrentModulePos(boilerroomPos.find(m => m.moduleId === value)!)}
        />
      )}
      {currentModule?.type !== PostModuleTypeEnum.NiagaraBoilerRoom && (
        <Card className={gtbStyle("card")}>
          <iframe
            title="Eclypse viewer"
            className={gtbStyle("iframe")}
            src={`${env.GTB_URL}/eisox/user/login?token=${userToken}&houseId=${houseId}&moduleId=${
              currentModulePos.moduleId
            }`}
          />
        </Card>
      )}
    </main>
  );
};
