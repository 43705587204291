import { ActionFunctionArgs } from "react-router-dom";

import { HousesHouseIdPlansPost200ResponseMessage } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { createPlan, removePlan } from "~/api/plan";
import { RestError, getHttpStatusByFetchStatus } from "~/constants";
import { FetchResponse } from "~/helpers/communication/fetchType";
import { API } from "~/types/API";

export const action = async ({
  request,
  params,
}: ActionFunctionArgs): Promise<API.Response<HousesHouseIdPlansPost200ResponseMessage | null> | Response> => {
  const { houseId, planId } = params as { houseId: string; planId: string };
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.POST:
      const postResponse = (await createPlan(
        houseId,
        formData,
      )) as unknown as FetchResponse<HousesHouseIdPlansPost200ResponseMessage>;
      const postStatus = getHttpStatusByFetchStatus(postResponse.type) as unknown as API.HTTP_STATUS;
      return {
        date: dayjs(),
        restError: postResponse.type as RestError | undefined,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [{ status: postStatus, message: postResponse.result }],
      };
    case API.HTTP_METHOD.DELETE:
      const deleteResponse = (await removePlan(planId, houseId)) as FetchResponse<undefined>;
      const deleteStatus = getHttpStatusByFetchStatus(deleteResponse.type) as unknown as API.HTTP_STATUS;
      return {
        date: dayjs(),
        restError: deleteResponse.type as RestError,
        url: request.url,
        method: API.HTTP_METHOD.DELETE,
        message: [{ status: deleteStatus, message: deleteResponse.result }],
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
