import type { ComponentPropsWithRef } from "react";
import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import type { ItemWithResolutionProps } from "../../types";
import { Gateway } from "../Gateway";
import { Valve } from "../Valve";

import styles from "./Item.module.scss";

interface ItemProps extends ItemWithResolutionProps<"gateway" | "valve">, Omit<ComponentPropsWithRef<"div">, "id"> {
  selected?: boolean;
}

const Item = forwardRef<HTMLDivElement, ItemProps>((props, forwardedRef) => {
  const {
    type,
    uid,
    name,
    errors,
    warnings,
    resolution,
    unclogging = false,
    selected = false,
    onClick,
    ...itemProps
  } = props;
  return (
    <div
      {...itemProps}
      ref={forwardedRef}
      className={cx(styles.item, selected && styles.item_selected)}
      onClick={onClick}
    >
      {type === "valve" ? (
        <Valve size={30} resolution={resolution} unclogging={unclogging} />
      ) : (
        <Gateway size={30} resolution={resolution} />
      )}
      <div>
        <div className={styles.header}>
          <span>{uid}</span>
          <span>{name}</span>
        </div>
        <ul className={styles.list}>
          {errors.map((error, index) => (
            <li key={`error-${error}-${index}`} className={cx(styles.list__element, styles.list__element_error)}>
              {error}
            </li>
          ))}
          {warnings.map((warning, index) => (
            <li key={`warning-${warning}-${index}`} className={cx(styles.list__element, styles.list__element_warning)}>
              {warning}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
});

export { Item };
