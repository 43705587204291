import { ActionFunctionArgs } from "react-router-dom";

import { PatchValvesValvesInner, PostValves } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { addValves, editValves } from "~/api/valve";
import { API } from "~/types/API";

export const action = async ({
  params,
  request,
}: ActionFunctionArgs): Promise<API.Response<string | null> | Response> => {
  const { houseId } = params;
  const formData = await request.formData();

  switch (request.method) {
    case API.HTTP_METHOD.POST:
      const adds: PostValves = JSON.parse(formData.get("json") as string);
      const postResponse = (await addValves(houseId, adds.valves)) as any;
      return {
        date: dayjs(),
        restError: postResponse.type,
        url: request.url,
        method: API.HTTP_METHOD.POST,
        body: formData.get("json") as string,
        message: [...postResponse.result.message],
      };
    case API.HTTP_METHOD.PATCH:
      const updates: PatchValvesValvesInner[] = JSON.parse(formData.get("json") as string);
      const patchResponse = (await editValves(houseId, updates)) as any;
      return {
        date: dayjs(),
        restError: patchResponse.type,
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message: [...patchResponse.result.message],
      };
    default:
      return {
        date: dayjs(),
        method: request.method as API.HTTP_METHOD,
        url: request.url,
        message: [
          {
            status: API.HTTP_STATUS.NOT_FOUND,
            message: null,
          },
        ],
      };
  }
};
