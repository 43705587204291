import { http } from "msw";

import type { PlanHouseParams, PlanHousePath, PlanHouseResponseBody } from "~/apiV2";

import { getCleverCloudPlanResolver, getPlanByHouseIdAndPlanIdResolver } from "../resolvers";
import type { VersionedPath } from "../utils";

export const handlers = [
  http.get<PlanHouseParams, never, PlanHouseResponseBody, VersionedPath<PlanHousePath>>(
    "/api/v3/houses/:houseId/plans/:planId",
    getPlanByHouseIdAndPlanIdResolver,
  ),
  http.get("http://plan-eisox-dev.cellar-c2.services.clever-cloud.com/:planId.png", getCleverCloudPlanResolver),
];
