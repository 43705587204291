import { useCallback, useMemo, useState } from "react";

import { cx } from "class-variance-authority";
import type { FieldArrayWithId } from "react-hook-form";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ActionButtonV2 as ActionButton,
  ButtonV2 as Button,
  Modal,
  SelectV2 as Select,
  SelectV2,
  Typography,
} from "@eisox/design-system";
import { BinIcon, CrossIcon, PencilIcon, PlusIcon, TowerIcon } from "@eisox/icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FieldContainer } from "~/UI";
import type { Creators, RolesInHouses, UpdateRolesRequestBody } from "~/apiV2";
import { queries, udapteRoles } from "~/apiV2";
import { routeToDashboard } from "~/routes/utils";
import { UserRole } from "~/utils";

import { addRoleSchema, getUserRolesByHouse, getUserRolesModifications } from "../../helpers";
import type { AddRoleSchemaType, UpdateUserDialogFormType } from "../../helpers";
import type { User } from "../../helpers";
import { OwnerChangeDialog } from "../OwnerChangeDialog";

import styles from "./UpdateUserDialog.module.scss";

interface AddRoleProps {
  houseId: string;
  rolesInHouses: RolesInHouses;
  connectedUserRole: UserRole;
  onAddRole: (houseId: string, role: UserRole) => void;
}

const AddRole: React.FC<AddRoleProps> = ({ houseId, rolesInHouses, connectedUserRole, onAddRole }) => {
  const { t } = useTranslation();

  const { control, handleSubmit, reset, watch } = useForm<AddRoleSchemaType>({
    resolver: zodResolver(addRoleSchema),
  });

  const selectedHouseId = watch("houseId");

  const houseOptions = useMemo(
    () => rolesInHouses.map(h => ({ value: h._id!, name: h.houseName ?? "-" })),
    [rolesInHouses],
  );

  const roleOptions = useMemo(() => {
    const roles = [UserRole.MANAGER, UserRole.VISITOR];
    if (connectedUserRole === UserRole.OWNER && selectedHouseId === houseId) {
      roles.push(UserRole.OWNER);
    }
    return roles.map(r => ({ value: r, name: t(`users.role.${r}`) }));
  }, [connectedUserRole, selectedHouseId, houseId, t]);

  const handleAddRole = () =>
    handleSubmit((data: AddRoleSchemaType) => {
      onAddRole(data.houseId, data.role);
      reset();
    })();

  return (
    <div className={styles.addRole}>
      <Controller
        control={control}
        name="houseId"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <FieldContainer label={t("users.modification.add.title")} error={error}>
            <Select
              classNames={{ trigger: styles.addRole__house }}
              options={houseOptions}
              value={value}
              onChange={onChange}
              placeholder={t("users.modification.add.building")}
            />
          </FieldContainer>
        )}
      />
      <Controller
        control={control}
        name="role"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <FieldContainer label="role" error={error} style={{ label: { color: "transparent" } }}>
              <Select
                classNames={{ trigger: styles.addRole__role }}
                options={roleOptions}
                value={value}
                onChange={onChange}
                renderValue={value => (
                  <Typography>
                    {t(
                      value
                        ? value === UserRole.MANAGER_BOILERROOM
                          ? "users.role.manager"
                          : `users.role.${value as string}`
                        : "users.modification.add.role",
                    )}
                  </Typography>
                )}
              />
            </FieldContainer>
            <FieldContainer label="option" style={{ label: { color: "transparent" } }}>
              <SelectV2
                classNames={{ trigger: styles.addRole__role }}
                options={[{ value: UserRole.MANAGER_BOILERROOM, name: t("users.role.option.boilerRoom") }]}
                value={[value]}
                onChange={values => onChange(values.length > 0 ? UserRole.MANAGER_BOILERROOM : UserRole.MANAGER)}
                renderValue={values => {
                  const displayPlaceholder =
                    !values ||
                    values.length === 0 ||
                    (values.length === 1 && (values[0] as UserRole) !== UserRole.MANAGER_BOILERROOM);
                  return (
                    <Typography
                      className={cx(styles.addRole__options, displayPlaceholder && styles.addRole__options_placeholder)}
                    >
                      {t(displayPlaceholder ? "users.modification.options" : "users.role.option.boilerRoom")}
                    </Typography>
                  );
                }}
                disabled={!(value === UserRole.MANAGER || value === UserRole.MANAGER_BOILERROOM)}
              />
            </FieldContainer>
          </>
        )}
      />
      <Button className={styles.addRole__button} onClick={handleAddRole}>
        {t("users.modification.add.button")} <PlusIcon />
      </Button>
    </div>
  );
};
interface UpdateUserDialogProps {
  user: User;
  houseId: string;
  connectedUserRole: UserRole;
  rolesInHouses: RolesInHouses;
  creators: Creators;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const UpdateUserDialog: React.FC<UpdateUserDialogProps> = ({
  user,
  houseId,
  connectedUserRole,
  rolesInHouses,
  creators,
  open,
  onOpenChange,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate: updateUser } = useMutation({
    mutationFn: (body: UpdateRolesRequestBody) => udapteRoles(user.email, body),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: queries.user.roles.queryKey }),
        queryClient.invalidateQueries({ queryKey: queries.user.rolesInHouses.queryKey }),
        queryClient.invalidateQueries({ queryKey: queries.user.houseUsers(houseId).queryKey }),
      ]);
      if (ownerChangeDialogOpen) navigate(routeToDashboard(houseId));
      setOwnerChangeDialogOpen(false);
      onOpenChange(false);
    },
    onError: () => toast.error(t("error.unKnowError.message")),
  });

  const userRolesByHouse = getUserRolesByHouse(user.id, rolesInHouses);
  const initialState = userRolesByHouse.map(r => ({ ...r, deleted: false, add: false }));

  const {
    control,
    formState: { isDirty, dirtyFields },
    handleSubmit,
  } = useForm<UpdateUserDialogFormType>({
    values: { roles: initialState },
  });

  const { fields, append, update, remove } = useFieldArray({ control, name: "roles" });

  const [ownerChangeDialogOpen, setOwnerChangeDialogOpen] = useState(false);

  const handleAddRole = (houseId: string, role: UserRole) =>
    append({
      houseId,
      role,
      deleted: false,
      add: true,
      houseName: rolesInHouses.find(h => h._id === houseId)?.houseName ?? "-",
    });

  const getUserRoleOptions = useCallback(
    (field: UpdateUserDialogFormType["roles"][number]) => {
      const roles = [UserRole.MANAGER, UserRole.VISITOR];
      if (connectedUserRole === UserRole.OWNER && field.houseId === houseId) {
        roles.push(UserRole.OWNER);
      }
      if (creators.find(c => c.email?.toUpperCase() === user.email.toUpperCase())) {
        roles.push(UserRole.INSTALLER);
      }
      return roles.map(r => ({ value: r, name: t(`users.role.${r}`) }));
    },
    [connectedUserRole, creators, houseId, t, user.email],
  );

  const isRoleUpdateDisabled = useCallback(
    (userRole: UserRole, field: FieldArrayWithId<UpdateUserDialogFormType, "roles", "id">) => {
      return (
        (userRole === UserRole.OWNER && (connectedUserRole !== UserRole.OWNER || field.houseId !== houseId)) ||
        field.deleted
      );
    },
    [connectedUserRole, houseId],
  );

  const handleClickCrossIcon = (field: FieldArrayWithId<UpdateUserDialogFormType, "roles", "id">, index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = field;
    update(index, { ...rest, deleted: false });
  };

  const handleClickBinIcon = (field: FieldArrayWithId<UpdateUserDialogFormType, "roles", "id">, index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = field;
    if (field.add) {
      remove(index);
    } else {
      update(index, { ...rest, deleted: true });
    }
  };

  const onSubmit = (data: UpdateUserDialogFormType) => {
    if (data.roles.find(r => r.houseId === houseId && r.role === UserRole.OWNER) && !ownerChangeDialogOpen) {
      setOwnerChangeDialogOpen(true);
      return;
    }
    const index = dirtyFields.roles!.reduce((accumulator: number[], currentValue, index) => {
      if (Object.values(currentValue).some(v => v)) accumulator.push(index);
      return accumulator;
    }, []);
    const roles = getUserRolesModifications(
      index.map(i => data.roles[i]),
      index.map(i => initialState[i]),
    );
    updateUser(roles);
  };

  return (
    <>
      <Modal.Root open={open} onOpenChange={onOpenChange}>
        <Modal.Content>
          <Modal.Header
            title={t("users.modification.title")}
            subtitle={t("users.modification.subtitle")}
            icon={<TowerIcon />}
          >
            <Modal.Close asChild>
              <ActionButton variant="cancel">{t("users.modification.cancel")}</ActionButton>
            </Modal.Close>
            <ActionButton rounded disabled={!isDirty} onClick={() => handleSubmit(onSubmit)()}>
              {t("users.modification.save")} <PencilIcon />
            </ActionButton>
          </Modal.Header>
          <AddRole
            houseId={houseId}
            rolesInHouses={rolesInHouses.filter(r => !userRolesByHouse.map(ur => ur.houseId).includes(r._id!))}
            connectedUserRole={connectedUserRole}
            onAddRole={handleAddRole}
          />
          <h3 className={styles.updateUserDialog__title}>{t("users.modification.houses")}</h3>
          <div className={styles.updateUserDialog__roles}>
            {fields.map((f, i) => (
              <div className={cx(styles.role, f.deleted && styles.role_deleted)}>
                <Typography>{f.houseName}</Typography>
                <Controller
                  control={control}
                  name={`roles.${i}.role`}
                  render={({ field: { value, onChange } }) => {
                    const disabled = isRoleUpdateDisabled(value, f);
                    return (
                      <>
                        <Select
                          classNames={{
                            trigger: cx(
                              styles.role__role,
                              styles[`role__role_${value}`],
                              disabled && styles.role__role_disabled,
                            ),
                          }}
                          options={getUserRoleOptions(f)}
                          value={value}
                          onChange={onChange}
                          renderValue={value => (
                            <Typography>
                              {t(
                                value
                                  ? value === UserRole.MANAGER_BOILERROOM
                                    ? "users.role.manager"
                                    : `users.role.${value as string}`
                                  : "users.modification.add.role",
                              )}
                            </Typography>
                          )}
                        />
                        {value === UserRole.MANAGER || value === UserRole.MANAGER_BOILERROOM ? (
                          <Select
                            options={[{ value: UserRole.MANAGER_BOILERROOM, name: t("users.role.option.boilerRoom") }]}
                            value={[value]}
                            onChange={value =>
                              onChange(value.length > 0 ? UserRole.MANAGER_BOILERROOM : UserRole.MANAGER)
                            }
                            renderValue={value => {
                              const displayPlaceholder =
                                !value ||
                                value.length === 0 ||
                                (value.length === 1 && (value[0] as UserRole) === UserRole.MANAGER);
                              return (
                                <Typography
                                  className={cx(
                                    styles.role__options,
                                    displayPlaceholder && styles.role__options_placeholder,
                                  )}
                                >
                                  {t(
                                    displayPlaceholder ? "users.modification.options" : "users.role.option.boilerRoom",
                                  )}
                                </Typography>
                              );
                            }}
                            disabled={disabled}
                          />
                        ) : (
                          <div />
                        )}
                        {f.deleted ? (
                          <CrossIcon className={styles.role__delete} onClick={() => handleClickCrossIcon(f, i)} />
                        ) : !disabled ? (
                          <BinIcon className={styles.role__delete} onClick={() => handleClickBinIcon(f, i)} />
                        ) : (
                          <div />
                        )}
                      </>
                    );
                  }}
                />
                {f.deleted && <div className={styles.role__strikethrough} />}
              </div>
            ))}
          </div>
        </Modal.Content>
      </Modal.Root>
      {ownerChangeDialogOpen && (
        <OwnerChangeDialog open onOpenChange={setOwnerChangeDialogOpen} onSubmit={() => handleSubmit(onSubmit)()} />
      )}
    </>
  );
};

export { UpdateUserDialog };
export type { UpdateUserDialogProps };
