import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useRouteLoaderData } from "react-router-dom";

import { ButtonV2 as Button, Loader } from "@eisox/design-system";
import {
  GatewayIcon,
  NutIcon,
  PlanIcon,
  RoomIcon,
  ThermometerIcon,
  TickIcon,
  ValveIcon,
  WarningIcon,
} from "@eisox/icons";
import { ROOM_ERRORS_ENUM } from "@eisox/problems-handler";
import { useQuery } from "@tanstack/react-query";

import type { houseLoader } from "~/UI";
import { Page } from "~/UI";
import { queries } from "~/apiV2";
import { OverviewCard, Plan, PresenceCard, TablePlanningsStat, TemperaturesCard } from "~/features/dashboard";
import { usePermissionsContext } from "~/providers";
import { idLoaderHouse, routeToMaintenance, routeToPlans, routeToSettings } from "~/routes/utils";
import { calculateAverageComfort } from "~/utils/Room";
import { isPresenceRecentlyDetected } from "~/utils/houseUtils";

import styles from "./Dashboard.module.scss";

const DashboardPage = () => {
  const { t } = useTranslation();

  const { permissions } = usePermissionsContext("DashboardPage");

  const navigate = useNavigate();
  const { houseId } = useParams() as { houseId: string };
  const { date, gateways, house, plans, rooms, valves } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    ReturnType<typeof houseLoader>
  >;

  const planId = plans.find(plan => plan.id)?.id;

  const {
    data: weather,
    isLoading: isWeatherLoading,
    isError: isWeatherError,
  } = useQuery({
    ...queries.meteo.house(houseId),
    retry: false,
  });
  const { data: planningsStat, isLoading: isPlanningStatsLoading } = useQuery({
    ...queries.planning.stat(houseId),
    throwOnError: true,
  });
  const { data: plan } = useQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ...queries.plan.plan(houseId, planId!),
    enabled: !!planId,
  });

  const presence = isPresenceRecentlyDetected(house, date);
  const comfortAverage = calculateAverageComfort(rooms);

  const valvesProblemsCount = valves.reduce((acc, v) => acc + (v.errors.length > 0 ? 1 : 0), 0);
  const gatewaysProblemsCount = gateways.reduce((acc, g) => acc + (g.errors.length > 0 ? 1 : 0), 0);
  const roomsProblemsCount = rooms.reduce(
    (acc, r) => acc + (r.errors.filter(r => !r.includes(ROOM_ERRORS_ENUM.VALVE_ERROR)).length > 0 ? 1 : 0),
    0,
  );

  const errorsCount = valvesProblemsCount + gatewaysProblemsCount + roomsProblemsCount;

  const noRooms = rooms.length === 0;

  return (
    <>
      <Page.Header>
        <Page.Title>{house.houseName}</Page.Title>
        {permissions.page?.settings && (
          <Button asChild>
            <Link to={routeToSettings(houseId)}>
              {t("button.settings")} <NutIcon />
            </Link>
          </Button>
        )}
      </Page.Header>
      <Page.Content className={styles.dashboard}>
        <div className={styles.dashboard__house}>
          <OverviewCard
            icon={<PlanIcon />}
            title={plans.length.toString()}
            subtitle={t("level", { count: plans.length })}
            variants="info"
          />
          <OverviewCard
            icon={<RoomIcon />}
            title={rooms.length.toString()}
            subtitle={t("room", { count: rooms.length })}
            variants="info"
          />
          <OverviewCard
            icon={<GatewayIcon />}
            title={gateways.length.toString()}
            subtitle={t("gateway", { count: gateways.length })}
            variants="info"
          />
          <OverviewCard
            icon={<ValveIcon />}
            title={valves.length.toString()}
            subtitle={t("valve", { count: valves.length })}
            variants="info"
          />
        </div>
        <div className={cx(styles.dashboard__cards, styles.dashboard__cards_column)}>
          {permissions.page?.maintenance && (
            <OverviewCard
              className={styles.dashboard__maintenance}
              icon={errorsCount > 0 ? <WarningIcon /> : <TickIcon />}
              iconColor={errorsCount > 0 ? "red" : "green"}
              title={t("dashboard.error", { count: errorsCount })}
              subtitle={t("dashboard.seeMaintenance")}
              variants={errorsCount > 0 ? "danger" : "success"}
              size={"large"}
              onClick={() => navigate(routeToMaintenance(houseId))}
            />
          )}
          {isWeatherLoading ? (
            <Loader className={styles.dashboard__loader} />
          ) : !isWeatherError && weather ? (
            <TemperaturesCard className={styles.dashboard__temperatures} temperatures={weather} />
          ) : (
            <TemperaturesCard className={styles.dashboard__temperatures} temperatures={[]} />
          )}
        </div>
        {!noRooms && (
          <div className={cx(styles.dashboard__planningStats)}>
            {isPlanningStatsLoading || !planningsStat ? (
              <Loader className={styles.dashboard__loader} />
            ) : (
              <TablePlanningsStat data={planningsStat} />
            )}
          </div>
        )}
        <Plan
          className={cx(styles.dashboard__plan, noRooms && styles.dashboard__plan_noRooms)}
          plan={plan}
          onClick={() => navigate(routeToPlans(houseId))}
        />
        {!noRooms && (
          <div className={styles.dashboard__cards}>
            {permissions.house?.lastPresenceDetected?.read && (
              <PresenceCard className={styles.dashboard__presence} presence={presence} />
            )}
            <OverviewCard
              className={styles.dashboard__comfort}
              icon={<ThermometerIcon />}
              iconColor="white"
              iconBackgroundColor="orange"
              title={comfortAverage.toString()}
              subtitle={t("dashboard.averageComfort")}
              hasDegre
              size="large"
            />
          </div>
        )}
      </Page.Content>
    </>
  );
};

export { DashboardPage };
