import { ActionFunctionArgs } from "react-router-dom";

import { PatchGatewaysGatewaysInner } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { editGateways, notifyGateway, removeGateway } from "~/api/gateway";
import { HTTPS_STATUS_CODES, getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { API } from "~/types/API";

export const action = async ({ params, request }: ActionFunctionArgs) => {
  const formData = await request.formData();
  const updates = JSON.parse(formData.get("json") as string);

  const { gatewayId, houseId } = params;

  switch (request.method) {
    case API.HTTP_METHOD.PATCH:
      const message = [];
      const { gatewayName, mac, isRemoteAccess, exprireAt, isInternetGateway, brModRf, uid } = updates;

      if (gatewayName || mac || isInternetGateway !== undefined || brModRf || uid) {
        const g: PatchGatewaysGatewaysInner[] = [
          {
            id: gatewayId,
            ...(gatewayName && { gatewayName }),
            ...(mac && { mac }),
            ...(isInternetGateway !== undefined && { isInternetGateway }),
            ...(brModRf && { brModRf }),
            ...(uid && { uid }),
          },
        ];
        const response: any = await editGateways(g, houseId);

        if (response)
          message.push({
            status: getHttpStatusByFetchStatus(response.type),
            ...response.result,
          });
      }

      if (isRemoteAccess !== undefined || exprireAt) {
        const enableSSH = (isRemoteAccess && exprireAt) || exprireAt;
        const response: any = await notifyGateway(
          [gatewayId],
          houseId,
          enableSSH ? "ENABLE_SSH" : "DISABLE_SSH",
          undefined,
          undefined,
          undefined,
          undefined,
          enableSSH ? exprireAt : undefined,
        );

        if (response)
          message.push({
            status: getHttpStatusByFetchStatus(response.type),
            ...response.result,
          });
      }

      return {
        date: dayjs(),
        restError: HTTPS_STATUS_CODES["207"],
        url: request.url,
        method: API.HTTP_METHOD.PATCH,
        body: formData.get("json") as string,
        message,
      };

    case API.HTTP_METHOD.DELETE:
      const response: any = await removeGateway(gatewayId, houseId);
      return {
        date: dayjs(),
        restError: response.type,
        url: request.url,
        method: API.HTTP_METHOD.DELETE,
        body: formData.get("json") as string,
        message: [{ ...response.result, status: getHttpStatusByFetchStatus(response.type) }],
      };
  }
};
