import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useNavigate, useRouteError } from "react-router-dom";

import { ActionButtonV2, Error as ErrorDS } from "@eisox/design-system";
import { captureException } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";

import { UnauthorizedException } from "~/apiV2";
import { routeToHouses, routeToSignIn } from "~/routes/utils";
import { useUserStore } from "~/stores";

export const Error: React.FC = () => {
  const { t } = useTranslation();

  const error = useRouteError() as Error | undefined;
  const location = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const logout = useUserStore.use.logout();

  const isLogin = !(error instanceof UnauthorizedException);

  useEffect(() => {
    if (isLogin) captureException(error, { level: "error" });
  }, [isLogin, error]);

  const handleClick = () => navigate(routeToHouses());

  if (!isLogin) {
    logout(queryClient);
    return <Navigate to={routeToSignIn()} state={{ from: location }} replace />;
  }

  return (
    <ErrorDS.Root>
      <ErrorDS.Title>{error ? 500 : 404}</ErrorDS.Title>
      <ErrorDS.Description>{t(`${error ? "extraPages.error.500" : "extraPages.error.404"}`)}</ErrorDS.Description>
      <ActionButtonV2 onClick={handleClick}>{t("sidebar.buildings")}</ActionButtonV2>
    </ErrorDS.Root>
  );
};
