import { useTranslation } from "react-i18next";
import { $enum } from "ts-enum-util";

import { SelectV2 as Select } from "@eisox/design-system";
import { ArrowUpDownIcon, ChevronDownIcon, MenuIcon } from "@eisox/icons";
import { useControllableState } from "@eisox/tools";

import { GroupField, SortField, SortOrder } from "../../types";

import styles from "./Display.module.scss";

interface DisplayProps {
  groupedBy?: GroupField;
  onGroupChange?: (groupBy: GroupField) => void;
  sortBy?: SortField;
  sortOrder?: SortOrder;
  onSortByChange?: (field: SortField) => void;
  onSortOrderChange?: (order: SortOrder) => void;
}

const Display: React.FC<DisplayProps> = props => {
  const { t } = useTranslation();

  const {
    groupedBy: groupedByProp,
    onGroupChange,
    sortBy: sortByProp,
    onSortByChange,
    sortOrder: sortOrderProp,
    onSortOrderChange,
  } = props;

  const [groupedBy = GroupField.plan, setGroupBy] = useControllableState({
    prop: groupedByProp,
    onChange: onGroupChange,
  });
  const [sortBy = SortField.hslope, setSortBy] = useControllableState({
    prop: sortByProp,
    onChange: onSortByChange,
  });
  const [sortOrder = SortOrder.asc, setSortOrder] = useControllableState({
    prop: sortOrderProp,
    onChange: onSortOrderChange,
  });

  const handleChangeGroup = (groupBy: string | string[]) => {
    setGroupBy(groupBy as GroupField);
  };

  const hnandleChangeSortBy = (sortBy: string | string[]) => {
    setSortBy(sortBy as SortField);
  };

  const handleChangeSortOrder = () => {
    setSortOrder(prev => (prev === SortOrder.asc ? SortOrder.desc : SortOrder.asc));
  };

  return (
    <div className={styles.display}>
      <div className={styles.display__row}>
        <MenuIcon />
        {t("manualBalancing.display.group.label")}
        <Select
          classNames={{ select: styles.display__group }}
          options={$enum(GroupField)
            .getValues()
            .map(value => ({
              name: t(`manualBalancing.display.group.${value}`),
              value,
            }))}
          value={groupedBy}
          onChange={handleChangeGroup}
        />
      </div>
      <div className={styles.display__row}>
        <ArrowUpDownIcon />
        {t("manualBalancing.display.sort.label")}
        <Select
          options={$enum(SortField)
            .getValues()
            .map(value => ({
              name: t(`manualBalancing.display.sort.${value}`),
              value,
            }))}
          value={sortBy}
          onChange={hnandleChangeSortBy}
        />
        <button type="button" data-sort={sortOrder} className={styles.display__sort} onClick={handleChangeSortOrder}>
          <ChevronDownIcon />
        </button>
      </div>
    </div>
  );
};

Display.displayName = "ManualBalancing.Display";

export { Display };
