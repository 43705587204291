import { Controller, Control as RHFControl } from "react-hook-form";
import { useIntl } from "react-intl";

import { Select, Typography } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { Slider } from "~/UI/components";
import { HeatingNetwork, Valve, ValveCommand } from "~/socketio/types";

import { ControlState } from "../ControlState";

import styles from "./MultiWaysValveState.module.scss";

interface ControlProps {
  control: RHFControl<Omit<HeatingNetwork, "id">, any>;
  history: boolean;
}

const Control: React.FC<ControlProps> = ({ control, history }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const selectStyle = bem("select");
  const sliderStyle = bem("slider");

  return (
    <Controller
      control={control}
      name="threeWaysValve.command"
      render={({ field: { value, onChange } }) => (
        <>
          <Select
            className={selectStyle()}
            value={value?.toString()}
            options={[
              {
                name: formatMessage({ id: "boilerRoom.dialog.network.auto" }),
                value: "1",
              },
              {
                name: formatMessage({ id: "boilerRoom.dialog.network.manual" }),
                value: "2",
              },
            ]}
            renderValue={(value?: string) => (
              <Typography>
                {formatMessage({
                  id: `pumps.state.${value === "1" ? "auto" : "manual"}`,
                })}
              </Typography>
            )}
            onChange={(value: string) => onChange(parseInt(value))}
            disabled={history}
          />
          {value === ValveCommand.MANUAL && (
            <Controller
              control={control}
              name="threeWaysValve.forcing"
              render={({ field: { value, onChange } }) => (
                <div className={sliderStyle()}>
                  <Slider
                    className={sliderStyle("slider")}
                    value={value!}
                    onChange={onChange}
                    min={0}
                    max={100}
                    step={10}
                    displayButtons={false}
                    displayValue={false}
                    disabled={history}
                  />
                  <p className={sliderStyle("value")}>{value}%</p>
                </div>
              )}
            />
          )}
        </>
      )}
    />
  );
};

interface StateProps {
  valve: Valve;
}

const State: React.FC<StateProps> = ({ valve }) => {
  const bem = useBem(styles);
  const stateStyle = bem("state");
  const defectStyle = bem("defect");

  return (
    <>
      <span className={stateStyle()}>{`${valve.opening}%`}</span>
      {valve.defect && <div className={defectStyle()} />}
    </>
  );
};

interface MultiWaysValveStateProps {
  twoWaysValve?: boolean; //threeWayValve by default
  control: RHFControl<Omit<HeatingNetwork, "id">, any>;
  valve: Valve;
  history: boolean;
}

export const MultiWaysValveState: React.FC<MultiWaysValveStateProps> = ({
  twoWaysValve = false,
  control,
  valve,
  history,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ControlState
      title={formatMessage({
        id: `boilerRoom.dialog.network.${twoWaysValve ? "twoWaysValve" : "threeWaysValve"}.title`,
      })}
      control={<Control control={control} history={history} />}
      state={<State valve={valve} />}
    />
  );
};
