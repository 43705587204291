import { useIntl } from "react-intl";

import { useBem } from "@eisox/tools";

import { PumpsDialog } from "../../../PumpsDialog";

import styles from "./PumpsPermutation.module.scss";

interface PumpsPermutationProps {
  permutationDay?: number;
  permutationHour?: number;
}

export const PumpsPermutation: React.FC<PumpsPermutationProps> = ({ permutationDay, permutationHour }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const pumpsPermutationStyle = bem("pumps-permutation");

  return (
    <div className={pumpsPermutationStyle()}>
      <h4 className={pumpsPermutationStyle("text", { type: "title" })}>
        {formatMessage({ id: "boilerRoom.dialog.network.pumpsPermutation.title" })}
      </h4>
      {permutationDay !== undefined && permutationHour !== undefined && (
        <p className={pumpsPermutationStyle("text")}>
          {formatMessage(
            { id: "boilerRoom.dialog.network.pumpsPermutation.text1" },
            {
              d: formatMessage({
                id: `boilerRoom.dialog.cascade.boilerPriorities.permutationDay.${permutationDay}`,
              }).toLowerCase(),
              h: permutationHour,
            },
          )}
        </p>
      )}
      <p className={pumpsPermutationStyle("text", { type: "italic" })}>
        {formatMessage(
          { id: "boilerRoom.dialog.network.pumpsPermutation.text2" },
          {
            link: (
              <PumpsDialog>
                <a className={pumpsPermutationStyle("text", { type: "link" })}>
                  {formatMessage({ id: "boilerRoom.dialog.cascade.pumpsPermutation.pumpsPermutation" })}
                </a>
              </PumpsDialog>
            ),
          },
        )}
      </p>
    </div>
  );
};
