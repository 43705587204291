import * as React from "react";

import { IconProps } from "./types";

export const PDFIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M12.7956 4.28768L10.0873 1.57935C10.0365 1.52856 9.96779 1.5 9.89583 1.5H4.20832C3.61085 1.5 3.125 1.98585 3.125 2.58334V13.4167C3.125 14.0142 3.61085 14.5 4.20834 14.5H11.7917C12.3891 14.5 12.875 14.0142 12.875 13.4166V4.47915C12.875 4.40722 12.8464 4.33847 12.7956 4.28768ZM10.1666 2.42465L11.9503 4.20834H10.7083C10.4097 4.20834 10.1666 3.96527 10.1666 3.66668V2.42465ZM12.3333 13.4166C12.3333 13.7152 12.0903 13.9583 11.7917 13.9583H4.20834C3.90975 13.9583 3.66668 13.7152 3.66668 13.4166V2.58334C3.66668 2.28475 3.90975 2.04168 4.20834 2.04168H9.62498V3.66668C9.62498 4.26415 10.1108 4.75 10.7083 4.75H12.3333V13.4166Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M9.38025 9.45058C9.12948 9.25329 8.89119 9.05044 8.73251 8.89176C8.5262 8.68545 8.34236 8.48548 8.18266 8.29506C8.43182 7.52515 8.54102 7.12818 8.54102 6.91656C8.54102 6.01758 8.21624 5.83322 7.72854 5.83322C7.35799 5.83322 6.91604 6.02576 6.91604 6.94249C6.91604 7.34663 7.13742 7.83725 7.5762 8.40748C7.46882 8.73517 7.34265 9.11313 7.2009 9.53921C7.13265 9.74366 7.05861 9.93301 6.9803 10.1081C6.91658 10.1364 6.85467 10.1653 6.7949 10.1951C6.57962 10.3028 6.37517 10.3995 6.18553 10.4895C5.32065 10.8989 4.74936 11.1697 4.74936 11.7045C4.74936 12.0928 5.17123 12.3332 5.56186 12.3332C6.06543 12.3332 6.82583 11.6606 7.38125 10.5276C7.95782 10.3001 8.67459 10.1316 9.24033 10.0261C9.69366 10.3747 10.1943 10.7082 10.4369 10.7082C11.1084 10.7082 11.2494 10.3199 11.2494 9.99434C11.2494 9.35405 10.5178 9.35405 10.166 9.35405C10.0568 9.35405 9.76376 9.38633 9.38025 9.45058ZM5.56186 11.7916C5.40713 11.7916 5.30239 11.7186 5.29101 11.7045C5.29101 11.5125 5.86363 11.2412 6.41747 10.9788C6.45264 10.9622 6.48836 10.9454 6.52459 10.9283C6.11781 11.5181 5.71552 11.7916 5.56186 11.7916ZM7.4577 6.94249C7.4577 6.37491 7.63386 6.37491 7.72854 6.37491C7.92004 6.37491 7.99938 6.37491 7.99938 6.91656C7.99938 7.03083 7.92321 7.31645 7.78381 7.76242C7.57114 7.43496 7.4577 7.15436 7.4577 6.94249ZM7.66532 9.8534C7.68225 9.80636 7.69866 9.75873 7.71452 9.71061C7.81502 9.40909 7.90549 9.13824 7.98615 8.89439C8.09856 9.01817 8.21971 9.14487 8.34958 9.27474C8.40037 9.32549 8.52625 9.43976 8.69395 9.58284C8.36011 9.65557 8.00491 9.74576 7.66532 9.8534ZM10.7077 9.99438C10.7077 10.1161 10.7077 10.1666 10.4565 10.1682C10.3826 10.1523 10.2121 10.0518 10.0015 9.90844C10.0779 9.9 10.1343 9.89576 10.166 9.89576C10.5662 9.89576 10.6797 9.93486 10.7077 9.99438Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default PDFIcon;
