import * as React from "react";

import { IconProps } from "./types";

export const ImageIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M13.4166 3H2.58333C1.98534 3.0007 1.5007 3.48533 1.5 4.08333V11.0167C1.5007 11.6146 1.98534 12.0993 2.58333 12.1H13.4166C13.4774 12.1002 13.5381 12.0949 13.598 12.0841C14.1193 11.9969 14.5009 11.5452 14.5 11.0167V4.08333C14.4993 3.4853 14.0146 3.0007 13.4166 3ZM2.58333 11.6666C2.24655 11.6666 1.96556 11.4093 1.93593 11.0738L6.25582 8.24939L10.6489 11.6666H2.58333ZM14.0667 11.0167C14.067 11.3347 13.8367 11.606 13.5228 11.6573C13.4877 11.6636 13.4522 11.6668 13.4166 11.6666H11.3546L8.48404 9.4326L11.051 7.37991L14.0667 9.18929V11.0167ZM14.0667 8.68402L11.1449 6.93097C11.0672 6.88426 10.9687 6.89084 10.8979 6.94744L8.13172 9.1596L6.39947 7.81237C6.32648 7.75566 6.22557 7.75146 6.14814 7.80197L1.93333 10.5577V4.08333C1.93333 3.72434 2.22434 3.43333 2.58333 3.43333H13.4166C13.7756 3.43333 14.0667 3.72434 14.0667 4.08333V8.68402Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M7.99984 4.29985C7.28189 4.29985 6.69987 4.88186 6.69987 5.59984C6.70058 6.31752 7.28219 6.89913 7.99984 6.89984C8.71785 6.89984 9.29984 6.31782 9.29984 5.59984C9.29984 4.88186 8.71785 4.29985 7.99984 4.29985ZM7.99984 6.46651C7.52123 6.46651 7.13321 6.07848 7.13321 5.59984C7.13321 5.1212 7.52123 4.73317 7.99984 4.73317C8.47854 4.73317 8.86651 5.1212 8.86651 5.59984C8.86651 6.07848 8.47854 6.46651 7.99984 6.46651Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default ImageIcon;
