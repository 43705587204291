import type { ComponentPropsWithoutRef } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import type { PlansMessageInner } from "@eisox/backend_webapp_api";
import { Loader, RoundIcon } from "@eisox/design-system";
import { NutIcon, PastIcon, QuestionMarkIcon } from "@eisox/icons";

import { Tooltip } from "~/UI";
import type { GatewaysWithProblem, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import type { Gateway, Plan, Room, Valve } from "~/apiV2";
import { env } from "~/configuration";
import { MODE_ENV } from "~/constants";
import { ManualBalancingModal as ManualBalancingDialog } from "~/features/ManualBalancing";
import { routeToConsumption } from "~/routes/utils";
import type { HeatingNetwork } from "~/socketio/types";

import { Card } from "../../../components";
import { getNetworkDemand, getValvesDemand } from "../../helpers";
import { Scheme } from "../Scheme";

import styles from "./SchemeCard.module.scss";

const NAME = "SchemeCard";

interface SchemeCardProps extends ComponentPropsWithoutRef<"div"> {
  houseId: string;
  boilerRoomId: string;
  ecs: boolean;
  heatingNetwork?: HeatingNetwork;
  valves: ValvesWithProblem[];
  gateways: GatewaysWithProblem[];
  rooms: RoomsWithProblem[];
  plans: PlansMessageInner[];
}

const SchemeCard: React.FC<SchemeCardProps> = ({
  houseId,
  boilerRoomId,
  ecs,
  heatingNetwork,
  valves,
  gateways,
  rooms,
  plans,
  ...props
}) => {
  const { t } = useTranslation();

  const heatingNetworkValves = valves.filter(v => v.heatingNetworkId === heatingNetwork?.id);

  const heatingNetworkRooms = rooms.filter(r => heatingNetworkValves.map(v => v.roomId).includes(r.id));
  const heatingNetworkGateways = gateways.filter(g => heatingNetworkValves.map(v => v.gatewayId).includes(g.id));

  return (
    <Card.Root {...props} className={styles.schemeCard}>
      <Card.Header title={t("network.content.globalView.title")} />
      <div className={styles.content}>
        {heatingNetwork ? (
          <>
            <Scheme boilerRoomId={boilerRoomId} network={heatingNetwork} />
            {!ecs && (
              <div className={styles.indicators}>
                <div className={styles.indicators__indicator}>
                  {t("network.content.globalView.indicators.ambiantTemperature", {
                    t: heatingNetwork.ambientTemperature,
                  })}
                </div>
                <div className={cx(styles.indicators__indicator, styles.indicators__indicator_inertia)}>
                  {t("network.content.globalView.indicators.averageInertia", { i: heatingNetwork.heatingSlope ?? "-" })}
                  <Tooltip content={t("network.content.globalView.indicators.help")}>
                    <RoundIcon size={21} backgroundColor={"gray"} iconColor={"darkGray"}>
                      <QuestionMarkIcon className={styles.indicators__questionMark} />
                    </RoundIcon>
                  </Tooltip>
                </div>
                {env.MODE !== MODE_ENV.PROD && heatingNetwork.calorieConso !== undefined && (
                  <div className={styles.indicators__indicator}>
                    {t("network.content.globalView.indicators.calories", { c: heatingNetwork.calorieConso })}
                    <Link
                      to={`${routeToConsumption(houseId)}?paths=boilerRooms[${boilerRoomId}].heatingNetworks[${heatingNetwork.id}].calorieConso`}
                    >
                      <PastIcon />
                    </Link>
                  </div>
                )}
                <div className={styles.indicators__indicator}>
                  {t("network.content.globalView.indicators.networkDemand", {
                    p: getNetworkDemand(heatingNetworkValves, heatingNetwork.id) ?? "-",
                  })}
                  <Tooltip content={t("network.content.globalView.indicators.networkDemandHelp")}>
                    <RoundIcon size={21} backgroundColor={"gray"} iconColor={"darkGray"}>
                      <QuestionMarkIcon className={styles.indicators__questionMark} />
                    </RoundIcon>
                  </Tooltip>
                </div>
                <div className={styles.indicators__indicator}>
                  {t("network.content.globalView.indicators.valvesDemand", {
                    d: getValvesDemand(heatingNetworkValves, heatingNetwork.id),
                  })}
                  <Tooltip content={t("network.content.globalView.indicators.valvesDemandHelp")}>
                    <RoundIcon size={21} backgroundColor={"gray"} iconColor={"darkGray"}>
                      <QuestionMarkIcon className={styles.indicators__questionMark} />
                    </RoundIcon>
                  </Tooltip>
                </div>
                {heatingNetworkValves.length > 0 && (
                  <ManualBalancingDialog
                    houseId={houseId}
                    networkName={heatingNetwork.name ?? "-"}
                    valves={heatingNetworkValves as Valve[]}
                    rooms={heatingNetworkRooms as Room[]}
                    gateways={heatingNetworkGateways as Gateway[]}
                    plans={plans as Plan[]}
                  >
                    <div className={styles.indicators__indicator}>
                      {t("network.content.globalView.indicators.balancing")}
                      <NutIcon className={styles.indicators__nutIcon} />
                    </div>
                  </ManualBalancingDialog>
                )}
              </div>
            )}
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Card.Root>
  );
};

SchemeCard.displayName = NAME;

export { SchemeCard };
export type { SchemeCardProps };
