import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import type { RoomFCProps } from "~/UI/components";
import { RoomFC } from "~/UI/components";

import styles from "./Room.module.scss";

type RoomProps = RoomFCProps;

const Room = forwardRef<HTMLDivElement, RoomProps>(({ selected, ...props }, forwardedRef) => {
  return <RoomFC {...props} ref={forwardedRef} className={cx(styles.room, selected && styles.room_selected)} />;
});

export { Room };
export type { RoomProps };
