import * as React from "react";

import { IconProps } from "./types";

export const TrendingArrowIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M10.6 4.10001L12.0885 5.58851L8.9165 8.76051L6.3165 6.16051L1.5 10.9835L2.4165 11.9L6.3165 8.00001L8.9165 10.6L13.0115 6.51151L14.5 8.00001V4.10001H10.6Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default TrendingArrowIcon;
