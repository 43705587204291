/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import type { Dayjs } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import { DateInput, Slider } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import {
  DEFAULT_MID_SEASON_DELAY,
  DEFAULT_MID_SEASON_HEATING_TEMPERATURE,
  DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE,
  TEMPERATURE_LIMITS,
} from "~/constants";

import type { SummerWinterWithHalfSeasonType } from "../../schemas";
import { SummerWinterWithHalfSeasonScheme } from "../SummerWinterWithHalfSeasonScheme";

import styles from "./SummerWinterWithHalfSeason.module.scss";

interface SummerWinterWithHalfSeasonProps {
  disabled?: boolean;
}

export const SummerWinterWithHalfSeason: React.FC<SummerWinterWithHalfSeasonProps> = ({ disabled = false }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const summerWinterWithHalfSeasonStyle = bem("summer-winter-with-half-season");
  const inputsStyle = bem("inputs");
  const inputsLeftStyle = bem("inputs-left");
  const inputsRightStyle = bem("inputs-right");
  const seasonsStyle = bem("seasons");

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<SummerWinterWithHalfSeasonType>();

  const winterBegin = watch("winterPeriods.begin");
  const winterEnd = watch("winterPeriods.end");
  const summerBegin = watch("summerPeriods.begin");
  const summerEnd = watch("summerPeriods.end");
  const noHeatingTemperature = watch("midSeason.noHeatingTemperature");
  const heatingTemperature = watch("midSeason.heatingTemperature");

  const handleChangeDate = (date: Dayjs | null, onChange: (...event: unknown[]) => void) => {
    if (date?.isValid()) onChange(date.format("DD-MM"));
  };

  return (
    <div className={summerWinterWithHalfSeasonStyle()}>
      <div className={inputsStyle()}>
        <div className={inputsLeftStyle()}>
          <div className={seasonsStyle()}>
            <div className={seasonsStyle("season")}>
              <h3 className={seasonsStyle("title")}>
                {formatMessage({ id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.winter" })}
              </h3>
              <Controller
                name="winterPeriods.begin"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DateInput
                    value={value ? dayjs(value, "DD/MM") : null}
                    onChange={value => handleChangeDate(value, onChange)}
                    disabled={disabled}
                  />
                )}
              />
              <span className={seasonsStyle("separator")}>-</span>
              <Controller
                name="winterPeriods.end"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DateInput
                    value={value ? dayjs(value, "DD/MM") : null}
                    onChange={value => handleChangeDate(value, onChange)}
                    disabled={disabled}
                  />
                )}
              />
            </div>
            <div className={seasonsStyle("season")}>
              <h3 className={seasonsStyle("title")}>
                {formatMessage({ id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.summer" })}
              </h3>
              <Controller
                name="summerPeriods.begin"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DateInput
                    value={value ? dayjs(value, "DD/MM") : null}
                    onChange={value => handleChangeDate(value, onChange)}
                    disabled={disabled}
                  />
                )}
              />
              <span className={seasonsStyle("separator")}>-</span>
              <Controller
                name="summerPeriods.end"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DateInput
                    value={value ? dayjs(value, "DD/MM") : null}
                    onChange={value => handleChangeDate(value, onChange)}
                    disabled={disabled}
                  />
                )}
              />
            </div>
            {(!!errors.summerPeriods || !!errors.winterPeriods) && (
              <p className={seasonsStyle("error")}>
                {formatMessage({ id: "settings.content.menu.houses.season.required" })}
              </p>
            )}
          </div>
          <div className={inputsLeftStyle("periods")}>
            <p className={inputsLeftStyle("text")}>
              {formatMessage({ id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.midSeasonPeriods" })}
            </p>
            <ul>
              <li className={inputsLeftStyle("text")}>
                {formatMessage(
                  { id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.range" },
                  {
                    d1: dayjs(summerEnd, "DD-MM").isValid()
                      ? dayjs(summerEnd, "DD-MM").add(1, "day").format("DD/MM")
                      : "--/--",
                    d2: dayjs(winterBegin, "DD-MM").isValid()
                      ? dayjs(winterBegin, "DD-MM").subtract(1, "day").format("DD/MM")
                      : "--/--",
                  },
                )}
              </li>
              <li className={inputsLeftStyle("text")}>
                {formatMessage(
                  { id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.range" },
                  {
                    d1: dayjs(winterEnd, "DD-MM").isValid()
                      ? dayjs(winterEnd, "DD-MM").add(1, "day").format("DD/MM")
                      : "--/--",
                    d2: dayjs(summerBegin, "DD-MM").isValid()
                      ? dayjs(summerBegin, "DD-MM").subtract(1, "day").format("DD/MM")
                      : "--/--",
                  },
                )}
              </li>
            </ul>
          </div>
        </div>
        <div className={inputsRightStyle()}>
          <h3 className={inputsRightStyle("title")}>
            {formatMessage({ id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.midSeason" })}
          </h3>
          <Controller
            name="midSeason.noHeatingTemperature"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Slider
                className={inputsRightStyle("slider")}
                label={formatMessage({
                  id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.noHeatingTemperature",
                })}
                value={value}
                onValueChange={value => {
                  if (value) {
                    const minTemp = heatingTemperature ?? DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE;
                    if (value <= minTemp) value = minTemp + TEMPERATURE_LIMITS.STEP;
                    onChange(value);
                  }
                }}
                valueLabelFormat={formatMessage(
                  {
                    id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.noHeatingTemperatureUpperHeatingTemperature",
                  },
                  {
                    nht: noHeatingTemperature ?? DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE,
                    ht: heatingTemperature ?? DEFAULT_MID_SEASON_HEATING_TEMPERATURE,
                  },
                )}
                min={TEMPERATURE_LIMITS.MIN}
                max={TEMPERATURE_LIMITS.MAX}
                step={TEMPERATURE_LIMITS.STEP}
                defaultValue={DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE}
              />
            )}
          />
          <Controller
            name="midSeason.heatingTemperature"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Slider
                className={inputsRightStyle("slider")}
                label={formatMessage({
                  id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.heatingTemperature",
                })}
                value={value}
                onValueChange={value => {
                  if (value) {
                    const maxTemp = noHeatingTemperature ?? DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE;
                    if (value > maxTemp) value = maxTemp - TEMPERATURE_LIMITS.STEP;
                    onChange(value);
                  }
                }}
                valueLabelFormat={formatMessage(
                  {
                    id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.noHeatingTemperatureUpperHeatingTemperature",
                  },
                  {
                    nht: noHeatingTemperature ?? DEFAULT_MID_SEASON_NO_HEATING_TEMPERATURE,

                    ht: heatingTemperature ?? DEFAULT_MID_SEASON_HEATING_TEMPERATURE,
                  },
                )}
                min={TEMPERATURE_LIMITS.MIN}
                max={TEMPERATURE_LIMITS.MAX}
                step={TEMPERATURE_LIMITS.STEP}
                defaultValue={DEFAULT_MID_SEASON_HEATING_TEMPERATURE}
              />
            )}
          />
          <Controller
            name="midSeason.delay"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Slider
                className={inputsRightStyle("slider")}
                label={formatMessage({
                  id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.delay",
                })}
                value={value}
                onValueChange={onChange}
                min={0}
                max={24}
                step={1}
                defaultValue={DEFAULT_MID_SEASON_DELAY}
              />
            )}
          />
        </div>
      </div>
      <SummerWinterWithHalfSeasonScheme
        summerStart={summerBegin}
        summerEnd={summerEnd}
        winterStart={winterBegin}
        winterEnd={winterEnd}
      />
    </div>
  );
};
