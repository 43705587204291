import React, { useEffect, useRef } from "react";

import { cx } from "class-variance-authority";
import { Command } from "cmdk";
import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { Typography } from "@eisox/design-system";
import {
  PathsHistoryHousesHouseIdModulesModuleIdHeatingNetworksHeatingNetworkIdHeatingCurvesPostParametersQueryDesc as HeatingCurveHistoryDesc,
  PathsHistoryHousesHouseIdModulesModuleIdHeatingNetworksHeatingNetworkIdHeatingCurvesPostParametersQuerySort as HeatingCurveHistorySort,
  PostHeatingCurvesHistoryFields,
} from "@eisox/webapp-api-specification";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import { useVirtualizer } from "@tanstack/react-virtual";

import type { HeatingCurveHistory } from "~/apiV2";
import { queries } from "~/apiV2";

import styles from "./HeatingCurve.module.scss";

interface HeatingCurveListProps {
  houseId: string;
  moduleId: string;
  heatingNetworkId: string;
  extTemp: number;
  date: string;
  heatingCurve?: HeatingCurveHistory;
  currentSetpointTemperautre?: number;
  currentOffsetTemperature?: number;
  onSelectHeatingCurve: (heatingCurve: HeatingCurveHistory) => void;
}

const HeatingCurveList: React.FC<HeatingCurveListProps> = ({
  houseId,
  moduleId,
  heatingNetworkId,
  date,
  extTemp,
  heatingCurve,
  currentSetpointTemperautre,
  currentOffsetTemperature,
  onSelectHeatingCurve,
}) => {
  const { t } = useTranslation();

  const prevDate = useRef(date);
  const parentRef = useRef<HTMLDivElement | null>(null);

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery<
    { data: HeatingCurveHistory[]; meta: { total: number; nextPage?: number } },
    Error
  >({
    ...queries.history.heatingCurveHistory(
      houseId,
      moduleId,
      heatingNetworkId,
      [HeatingCurveHistorySort.createdAt],
      [HeatingCurveHistoryDesc.createdAt],
      {
        extTemp,
        date,
        fields: [
          PostHeatingCurvesHistoryFields.id,
          PostHeatingCurvesHistoryFields.con,
          PostHeatingCurvesHistoryFields.ext,
          PostHeatingCurvesHistoryFields.offset,
          PostHeatingCurvesHistoryFields.createdAt,
        ],
      },
    ),
    initialPageParam: 1,
    getNextPageParam: lastPage => lastPage.meta.nextPage,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    retry: false,
  });

  const items = data?.pages.flatMap(p => p.data) ?? [];
  const totalCount = data?.pages[0]?.meta?.total ?? items.length;

  const rowVirtualizer = useVirtualizer({
    count: totalCount,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 36 + 2,
  });

  const handleSelectionChange = (item: HeatingCurveHistory) => item.id && onSelectHeatingCurve(item);

  useEffect(() => {
    const lastItem = rowVirtualizer.getVirtualItems()[rowVirtualizer.getVirtualItems().length - 1];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!lastItem) return;
    if (lastItem.index >= items.length - 1 && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, fetchNextPage, items.length, isFetchingNextPage, rowVirtualizer.getVirtualItems()]);

  useEffect(() => {
    if (date !== prevDate.current) {
      rowVirtualizer.scrollToIndex(0);
    }
    prevDate.current = date;
  }, [date, rowVirtualizer]);

  return (
    <Command>
      <Command.List
        ref={parentRef}
        style={{ display: "flex", flexDirection: "column", gap: 2, height: 112, overflowY: "auto" }}
      >
        <Command.Item
          key="current-period"
          className={cx(
            styles.heatingCurveList__item,
            heatingCurve?.id === "current-period" && styles.heatingCurveList__item_selected,
          )}
          onSelect={() =>
            handleSelectionChange({
              id: "current-period",
              con: currentSetpointTemperautre,
              offset: currentOffsetTemperature,
            })
          }
          style={{ height: 36 }}
        >
          <Typography>En cours</Typography>
        </Command.Item>
        <div
          style={{
            height: rowVirtualizer.getTotalSize(),
            position: "relative",
            width: "100%",
          }}
        >
          {rowVirtualizer.getVirtualItems().map(virtualRow => {
            const isLoaderRow = virtualRow.index > items.length - 1;
            const item = items[virtualRow.index] as HeatingCurveHistory | undefined;
            const selected =
              item?.con === heatingCurve?.con &&
              item?.offset === heatingCurve?.offset &&
              item?.createdAt === heatingCurve?.createdAt;
            return (
              <Command.Item
                key={virtualRow.index}
                style={{
                  position: "absolute",
                  top: 0,
                  transform: `translateY(${virtualRow.start}px)`,
                  height: 36,
                }}
                className={cx(styles.heatingCurveList__item, selected && styles.heatingCurveList__item_selected)}
                onSelect={_ => item && handleSelectionChange(item)}
              >
                {isLoaderRow ? (
                  hasNextPage ? (
                    <Typography>
                      {t("network.content.heatingCurve.dialog.heatingCurveHistory.list.loadingMore")}
                    </Typography>
                  ) : (
                    <Typography>
                      {t("network.content.heatingCurve.dialog.heatingCurveHistory.list.nothingMoreToLoad")}
                    </Typography>
                  )
                ) : (
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  <Typography>{`${dayjs(item!.createdAt).format("L LTS")} - ${item!.con ? Math.round(item!.con + (item!.offset ?? 0)) : "-"}°C`}</Typography>
                )}
              </Command.Item>
            );
          })}
        </div>
      </Command.List>
    </Command>
  );
};

export { HeatingCurveList };
export type { HeatingCurveListProps };
