import { useBem } from "@eisox/tools";

import styles from "./WaterlineTemperature.module.scss";

import clsx from "clsx";

interface WaterlineTemperatureProps {
  temperatures: number[];
  className?: string;
}

export const WaterlineTemperature: React.FC<WaterlineTemperatureProps> = ({ temperatures, className }) => {
  const bem = useBem(styles);
  const waterlineTemperatureStyle = bem("waterline-temperature");

  return (
    <p className={clsx(waterlineTemperatureStyle(), className)}>
      {`${Math.min(...temperatures).toFixed(1)}°C`}
      {temperatures.length > 1 && <span>{` (${temperatures.length})`}</span>}
    </p>
  );
};
