import type { RouteObject } from "react-router-dom";

import type { QueryClient } from "@tanstack/react-query";

import { houses } from "~/routes/routes";

import { Houses } from "./index";
import { loader } from "./loader";

export const route = (queryClient: QueryClient): RouteObject => ({
  path: houses,
  loader: loader(queryClient),
  element: <Houses />,
});
