import type { PropsWithChildren, ReactElement } from "react";
import React from "react";

import clsx from "clsx";

import { useBem } from "@eisox/tools";

import type { BoilerromLayout } from "~/stores";

import styles from "./HeatingNetworks.module.scss";

interface HeatingNetworksProps extends PropsWithChildren {
  layout: BoilerromLayout;
}

export const HeatingNetworks: React.FC<HeatingNetworksProps> = ({ children, layout }) => {
  const bem = useBem(styles);
  const heatingNetworksStyle = bem("heating-networks");

  return (
    <div className={heatingNetworksStyle({ layout })}>
      {React.Children.map(children, (child, i) => {
        const last = React.Children.count(children) === i + 1 && React.Children.count(children) % 2 !== 0;
        return React.cloneElement(child as React.ReactElement, {
          className: clsx(
            heatingNetworksStyle("heating-network", { last, layout }),
            (child as ReactElement).props.className,
          ),
        });
      })}
    </div>
  );
};
