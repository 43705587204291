import * as React from "react";

import { IconProps } from "./types";

export const RefreshIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M14.1875 1.5H9.3125V6.375L11.1325 4.555C12.0468 5.46852 12.5611 6.70756 12.5625 8C12.5625 9.00745 12.2499 9.99006 11.6678 10.8123C11.0857 11.6346 10.2627 12.256 9.3125 12.5906V14.2887C12.1156 13.5656 14.1875 11.0306 14.1875 8C14.1875 6.20438 13.4481 4.5875 12.27 3.4175L14.1875 1.5ZM2 8C2 9.79562 2.73937 11.4125 3.9175 12.5825L2 14.5H6.875V9.625L5.055 11.445C4.14073 10.5315 3.62641 9.29244 3.625 8C3.62496 6.99255 3.93757 6.00991 4.51969 5.18766C5.10182 4.36541 5.92476 3.74407 6.875 3.40937V1.71125C4.07187 2.43437 2 4.96937 2 8Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default RefreshIcon;
