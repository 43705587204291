import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import * as RadixPopover from "@radix-ui/react-popover";

import styles from "./Dropdown.module.scss";

/** --------------------------------------------------------------------------------------------------------------------
 * Dropdown
 * ------------------------------------------------------------------------------------------------------------------ */

type DropdownProps = RadixPopover.PopoverProps;

const Root = RadixPopover.Root;

Root.displayName = "Dropdown";

/** --------------------------------------------------------------------------------------------------------------------
 * Trigger
 * ------------------------------------------------------------------------------------------------------------------ */

type TriggerProps = RadixPopover.PopoverTriggerProps;

const Trigger = RadixPopover.Trigger;

Trigger.displayName = "DropdownTrigger";

/** --------------------------------------------------------------------------------------------------------------------
 * Content
 * ------------------------------------------------------------------------------------------------------------------ */

interface ContentProps extends RadixPopover.PopoverContentProps {
  arrow?: boolean;
}

const Content = forwardRef<HTMLDivElement, ContentProps>((props, forwardedRef) => {
  const { arrow = false, className, children, ...contentProps } = props;

  return (
    <RadixPopover.Portal>
      <RadixPopover.Content {...contentProps} ref={forwardedRef} className={cx(styles.dropdown, className)}>
        {arrow && <RadixPopover.Arrow className={styles.dropdown__arrow} />}
        {children}
      </RadixPopover.Content>
    </RadixPopover.Portal>
  );
});

Content.displayName = "DropdownContent";

/** --------------------------------------------------------------------------------------------------------------------
 * Close
 * ------------------------------------------------------------------------------------------------------------------ */

type CloseProps = RadixPopover.PopoverCloseProps;

const Close = RadixPopover.Close;

Close.displayName = "DropdownClose";

export { Root, Trigger, Content, Close };
export type { DropdownProps, TriggerProps, ContentProps, CloseProps };
