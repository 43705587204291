import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import { RoundIcon } from "@eisox/design-system";
import { GatewayIcon } from "@eisox/icons";

import styles from "./Gateway.module.scss";

type GatewayProps = React.HTMLAttributes<HTMLDivElement>;

const Gateway = forwardRef<HTMLDivElement, GatewayProps>(({ className, children, ...props }, forwardedRef) => (
  <div {...props} ref={forwardedRef} className={cx(styles.gateway, className)}>
    <RoundIcon size={20} backgroundColor="gray">
      <GatewayIcon />
    </RoundIcon>
    {children}
  </div>
));

Gateway.displayName = "ManualBalancing.Gateway";

export { Gateway };
export type { GatewayProps };
