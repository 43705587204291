import type { HousesHouseIdBoilerroomsPosGet200ResponseMessageInner, Module } from "@eisox/backend_webapp_api";

import type { BoilerroomDataRes } from "~/socketio/types";

const getBoilerRoomIdsByGatewayId = (
  modules: Module[],
  boilerRoomPos: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[],
) => {
  const result = new Map<string, string[]>(); // gatewayId -> boilerRoomIds
  modules.forEach(m => {
    if (m.type === "eclypseBoilerRoom") {
      const boilerRoomIds =
        boilerRoomPos
          .find(br => br.moduleId === m.id)
          ?.boilerRooms?.map(b => b.id)
          .filter((id): id is string => !!id) ?? [];
      if (!result.has(m.gatewayId!)) {
        result.set(m.gatewayId!, boilerRoomIds);
      } else {
        result.get(m.gatewayId!)!.push(...boilerRoomIds);
      }
    }
  });
  return result;
};

const getGatewaySyncByGatewayIdInitialState = (gateways: string[]) =>
  gateways.reduce(
    (acc, gatewayId) => {
      acc[gatewayId] = false;
      return acc;
    },
    {} as Record<string, boolean>,
  );

const allGatewaysSynced = (gatewaysSync: Record<string, boolean>) => Object.values(gatewaysSync).every(sync => sync);
const someGatewaysSynced = (gatewaysSync: Record<string, boolean>) => Object.values(gatewaysSync).some(sync => sync);

const convertPlanningTypeToType = (data: BoilerroomDataRes): BoilerroomDataRes => {
  const convertedData = { ...data };
  convertedData.boilerRooms?.forEach(boilerRoom => {
    boilerRoom.heatingNetworks?.forEach(heatingNetwork => {
      heatingNetwork.plannings?.forEach(planning => {
        if (planning.type) {
          planning.planningType = planning.type;
          delete planning.type;
        }
      });
    });
  });
  return convertedData;
};

const handleHeatingNetworkPlanningsUpdate = (response: BoilerroomDataRes, state?: BoilerroomDataRes) => {
  const convertedData = { ...response };
  convertedData.boilerRooms?.forEach(b => {
    b.heatingNetworks?.forEach(hn => {
      const existingPlannings =
        state?.boilerRooms
          ?.find(bs => bs.id === b.id)
          ?.heatingNetworks?.find(hns => hns.id === hn.id)
          ?.plannings?.filter(p => !p.userDefined) ?? [];
      hn.plannings = [...existingPlannings, ...(hn.plannings ?? [])];
    });
  });
  return convertedData;
};

function mergeObjects(obj1: any, obj2: any): any {
  const mergedObj = { ...obj1 };

  if (obj2.errors) {
    mergedObj.errors = obj2.errors;
  } else if (!obj2.errors && mergedObj.errors) {
    delete mergedObj.errors;
  }

  if (obj2.plannings) {
    mergedObj.plannings = obj2.plannings;
  }

  if (obj2.heatingCurves) {
    mergedObj.heatingCurves = obj2.heatingCurves;
  }

  for (const key in obj2) {
    if (key === "errors" || key === "plannings" || key === "heatingCurves") continue;
    if (typeof obj2[key] === "object" && !Array.isArray(obj2[key])) {
      mergedObj[key] = mergeObjects(mergedObj[key], obj2[key]);
    } else if (Array.isArray(obj2[key])) {
      mergedObj[key] = mergeArrays(mergedObj[key], obj2[key]);
    } else {
      mergedObj[key] = obj2[key];
    }
  }
  return mergedObj;
}

function mergeArrays(arr1: any[], arr2: any[]): any[] {
  let mergedArray = arr1 ? [...arr1] : [];

  if (mergedArray.every(element => typeof element === "object" && element !== null)) {
    if (arr2.length > 0) {
      for (const obj2 of arr2) {
        const existingObjIndex = mergedArray.findIndex(obj1 => obj1.id && obj2.id && obj1.id === obj2.id);
        if (existingObjIndex !== -1) {
          mergedArray[existingObjIndex] = mergeObjects(mergedArray[existingObjIndex], obj2);
        } else {
          mergedArray = [...mergedArray, obj2];
        }
      }
    }
  } else {
    mergedArray = arr2;
  }

  return mergedArray;
}

export function deepArrayCompare(array1: any[], array2: any[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    const element1 = array1[i];
    const element2 = array2[i];

    if (Array.isArray(element1) && Array.isArray(element2)) {
      if (!deepArrayCompare(element1, element2)) {
        return false;
      }
    } else if (typeof element1 === "object" && typeof element2 === "object") {
      if (!deepObjectCompare(element1, element2)) {
        return false;
      }
    } else if (element1 !== element2) {
      return false;
    }
  }

  return true;
}

export function deepObjectCompare(obj1: any, obj2: any): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const value1 = obj1[key];
    const value2 = obj2[key];

    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (!deepArrayCompare(value1, value2)) {
        return false;
      }
    } else if (typeof value1 === "object" && typeof value2 === "object") {
      if (!deepObjectCompare(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
}

export {
  allGatewaysSynced,
  convertPlanningTypeToType,
  getBoilerRoomIdsByGatewayId,
  getGatewaySyncByGatewayIdInitialState,
  handleHeatingNetworkPlanningsUpdate,
  mergeObjects,
  someGatewaysSynced,
};
