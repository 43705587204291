import clsx from "clsx";
import { useIntl } from "react-intl";

import { Card, Switch, Typography } from "@eisox/design-system";
import { ChevronDownIcon, HeatIcon, LeafIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import * as Popover from "@radix-ui/react-popover";

import { Slider } from "~/UI/components";
import { DEFAULT_TEMPERATURES } from "~/constants";

import type { ParametersType } from "../../../../layouts/RoomsSelectors";

import styles from "./ParametersSelect.module.scss";

interface ParametersSelectProps {
  filters: ParametersType;
  onChange: (parameters: ParametersType) => void;
  disabled?: boolean;
}

export const ParametersSelect: React.FC<ParametersSelectProps> = ({ filters, onChange, disabled = false }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const triggerStyle = bem("trigger");
  const cardStyle = bem("card");

  const value = Object.entries(filters)
    .filter(([_, value]) => value !== null && value !== undefined)
    .map(([key, _]) => formatMessage({ id: `settings.content.menu.rooms.selectors.parameters.${key}.value` }));

  return (
    <Popover.Root>
      <Popover.Trigger className={triggerStyle({ disabled: disabled })} disabled={disabled}>
        <Typography className={triggerStyle("value", { placeholder: value.length === 0 })}>
          {value.length > 0
            ? value.join(", ")
            : formatMessage({ id: "settings.content.menu.rooms.function.parameters.title" })}
        </Typography>
        <ChevronDownIcon />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content asChild align="center" side="bottom">
          <Card className={cardStyle()}>
            <h3 className={cardStyle("title")}>
              {formatMessage({ id: "settings.content.menu.rooms.selectors.parameters.unusualAbsenceDetection.title" })}
              <LeafIcon className={cardStyle("logo", { color: "green" })} />
            </h3>
            <label className={cardStyle("label")}>
              {formatMessage(
                { id: "settings.content.menu.rooms.selectors.parameters.label" },
                {
                  activate: filters.unusualAbsenceDetection,
                },
              )}
              <Switch
                forceThreeState
                checked={filters.unusualAbsenceDetection}
                onCheckedChange={value => onChange({ ...filters, unusualAbsenceDetection: value })}
              />
            </label>
            <h3 className={cardStyle("title")}>
              {formatMessage({
                id: "settings.content.menu.rooms.selectors.parameters.precomfortRangeGeneration.title",
              })}
              <HeatIcon className={cardStyle("logo", { color: "comfort" })} />
            </h3>
            <label className={cardStyle("label")}>
              {formatMessage(
                {
                  id: "settings.content.menu.rooms.selectors.parameters.label",
                },
                {
                  activate: filters.precomfortRangeGeneration,
                },
              )}
              <Switch
                forceThreeState
                checked={filters.precomfortRangeGeneration}
                onCheckedChange={value => onChange({ ...filters, precomfortRangeGeneration: value })}
              />
            </label>
            <h3 className={cardStyle("title")}>
              {formatMessage({ id: "settings.content.menu.rooms.selectors.parameters.buttonSettings.title" })}
            </h3>
            <label className={cardStyle("label")}>
              {formatMessage(
                { id: "settings.content.menu.rooms.selectors.parameters.label" },
                {
                  activate: filters.buttonSettings,
                },
              )}
              <Switch
                forceThreeState
                checked={filters.buttonSettings}
                onCheckedChange={value => onChange({ ...filters, buttonSettings: value })}
              />
            </label>

            <>
              <h3 className={cardStyle("title")}>
                {formatMessage({ id: "settings.content.menu.rooms.selectors.parameters.frostFreeMode.title" })}
              </h3>
              <label className={cardStyle("label")}>
                {formatMessage(
                  { id: "settings.content.menu.rooms.selectors.parameters.label" },
                  {
                    activate: filters.frostFreeMode,
                  },
                )}
                <Switch
                  forceThreeState
                  checked={filters.frostFreeMode}
                  onCheckedChange={value => onChange({ ...filters, frostFreeMode: value })}
                />
              </label>
            </>

            <Slider
              className={cardStyle("slider")}
              label={formatMessage({ id: "settings.content.menu.rooms.selectors.parameters.comfortTemperature.title" })}
              min={0}
              max={30}
              step={0.5}
              defaultValue={DEFAULT_TEMPERATURES.COMFORT}
              value={filters.comfortTemperature}
              onChange={(_, value) =>
                (typeof value === "number" || value === undefined) &&
                onChange({ ...filters, comfortTemperature: value })
              }
              displayButtons={false}
              undefinedValue
            />
            <Slider
              className={cardStyle("slider")}
              label={formatMessage({ id: "settings.content.menu.rooms.selectors.parameters.nightTemperature.title" })}
              min={0}
              max={30}
              step={0.5}
              defaultValue={DEFAULT_TEMPERATURES.NIGHT}
              value={filters.nightTemperature}
              onChange={(_, value) =>
                (typeof value === "number" || value === undefined) && onChange({ ...filters, nightTemperature: value })
              }
              displayButtons={false}
              undefinedValue
            />
          </Card>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
