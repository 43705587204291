import dayjs from "@eisox/dayjs";
import { BinIcon, CrossIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { ErrorType } from "~/types/ReactHookFormType";

import { Period } from "../../layouts";
import { PeriodInterval } from "../PeriodInterval";

import styles from "./UpdatePeriod.module.scss";

const DATE_FORMAT = "YYYY-MM-DD";

interface UpdatePeriodProps {
  period: Period;
  onChangeBegin: (begin: string) => void;
  onChangeEnd: (end: string) => void;
  onRestore: VoidFunction;
  onRemove: VoidFunction;
  removed: boolean;
  error?: ErrorType;
}

export const UpdatePeriod: React.FC<UpdatePeriodProps> = ({
  period,
  onChangeBegin,
  onChangeEnd,
  onRestore,
  onRemove,
  removed,
  error,
}) => {
  const bem = useBem(styles);
  const udpatePeriodStyle = bem("update-period");

  return (
    <>
      <div className={udpatePeriodStyle()}>
        <PeriodInterval
          className={udpatePeriodStyle("interval", { removed })}
          begin={dayjs(period.begin, DATE_FORMAT)}
          end={dayjs(period.end, DATE_FORMAT)}
          onChangeBegin={begin => begin && onChangeBegin(begin.format(DATE_FORMAT))}
          onChangeEnd={end => end && onChangeEnd(end.format(DATE_FORMAT))}
        />
        {period.removed ? (
          <CrossIcon className={udpatePeriodStyle("button")} onClick={onRestore} />
        ) : (
          <BinIcon className={udpatePeriodStyle("button")} onClick={onRemove} />
        )}
        {period.removed && <span className={udpatePeriodStyle("strikethrough")} />}
      </div>
      {error?.message && <div className={udpatePeriodStyle("error")}>{error.message}</div>}
    </>
  );
};
