import React, { useEffect, useRef, useState } from "react";

import { clsx } from "clsx";
import { useIntl } from "react-intl";

import { ActionButton } from "@eisox/design-system";
import { ChevronUpIcon, HistoryIcon, PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { FORMAT_DATE } from "~/constants/timeConstants";
import { formatedDate } from "~/utils/timeUtils";

import { DataInformation } from "../DataInformation";

import styles from "./DropdownDetails.module.scss";

interface DropdownDetailsProps extends React.PropsWithChildren {
  header: React.ReactElement;
  lastCom?: string;
  lastActivityHistory?: {
    isAuthorized?: boolean;
    onClick?: VoidFunction;
  };
  errors: string[];
  warnings: string[];
  childrenClassName?: string;
  full?: boolean;
  edit?: {
    isAuthorized?: boolean;
    disabled?: boolean;
    onClick?: VoidFunction;
  };
  move?: {
    isAuthorized?: boolean;
    disabled?: boolean;
    onClick?: VoidFunction;
  };
  replace?: {
    isAuthorized?: boolean;
    disabled?: boolean;
    onClick?: VoidFunction;
  };
}

export const DropdownDetails: React.FC<DropdownDetailsProps> = ({
  header,
  children,
  lastCom,
  errors,
  warnings,
  childrenClassName,
  full = false,
  lastActivityHistory = { isAuthorized: false, onClick: () => {} },
  edit = { isAuthorized: false, disabled: false, onClick: () => {} },
  move = { isAuthorized: false, disabled: false, onClick: () => {} },
  replace = { isAuthorized: false, disabled: false, onClick: () => {} },
}) => {
  const { formatMessage } = useIntl();

  const ref = useRef<HTMLDivElement>(null);
  const refHeader = useRef<HTMLDivElement>(null);

  const bem = useBem(styles);
  const dropdownDetailsStyle = bem("dropdown-details");
  const headerStyle = bem("header");
  const childrenStyle = bem("children");
  const buttonsStyle = bem("buttons");

  const [expanded, setExpanded] = useState(true);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (ref.current) setHeight(ref.current.scrollHeight);
  }, [ref, ref.current?.scrollHeight]);

  return (
    <div className={dropdownDetailsStyle({ full })}>
      <div className={headerStyle()} onClick={() => !full && setExpanded(!expanded)} ref={refHeader}>
        {header} {!full && <ChevronUpIcon className={headerStyle("arrow", { expanded: expanded })} />}
      </div>
      <div className={childrenStyle({ full })}>
        {children && (
          <div
            ref={ref}
            style={{
              height: `${expanded ? height : 0}px`,
              overflowY: `${expanded && ref.current?.offsetHeight === height ? "auto" : "hidden"}`,
            }}
            className={clsx(childrenStyle("content"), childrenClassName)}
          >
            {children}
          </div>
        )}
        <DataInformation
          label={
            <>
              {formatMessage({ id: "drawer.listDrawer.lastCom" })}
              {lastActivityHistory.isAuthorized && <HistoryIcon onClick={lastActivityHistory.onClick} />}
            </>
          }
          value={lastCom && formatedDate(FORMAT_DATE.listDrawerLastCom, lastCom)}
          auth={!!lastCom}
          style={{
            marginTop: `${
              expanded && refHeader.current ? `${window.getComputedStyle(refHeader.current).marginBottom}` : 0
            }`,
          }}
        />
        {errors.length > 0 && (
          <div className={dropdownDetailsStyle("errors")}>
            <p>{formatMessage({ id: "drawer.listDrawer.errors" }, { e: errors.length })}</p>
            {errors.map((error, index) => (
              <p key={index}>{error}</p>
            ))}
          </div>
        )}
        {/* warnings are protected by authorization, but the array is empty if the user doesn't have permissions  */}
        {warnings.length > 0 && (
          <div className={dropdownDetailsStyle("warnings")}>
            <p>{formatMessage({ id: "drawer.listDrawer.warnings" }, { w: warnings.length })}</p>
            {warnings.map((warning, index) => (
              <p key={index}>{warning}</p>
            ))}
          </div>
        )}
      </div>

      {(edit.isAuthorized || move.isAuthorized || replace.isAuthorized) && (
        <div className={buttonsStyle({ full })}>
          {edit.isAuthorized && (
            <ActionButton
              text={formatMessage({ id: "drawer.listDrawer.editButton" })}
              rounded
              className={buttonsStyle("button", { disabled: !!edit.disabled })}
              icon={<PencilIcon />}
              onClick={edit.onClick}
              disabled={edit.disabled}
            />
          )}
          {replace.isAuthorized && (
            <ActionButton
              text={formatMessage({ id: "drawer.listDrawer.replace.button" })}
              rounded
              className={buttonsStyle("button", { disabled: !!replace.disabled })}
              icon={<PencilIcon />}
              onClick={replace.onClick}
              disabled={replace.disabled}
            />
          )}
          {move.isAuthorized && (
            <ActionButton
              text={formatMessage({ id: "drawer.listDrawer.moveButton" })}
              rounded
              className={buttonsStyle("button", { disabled: !!move.disabled })}
              icon={<PencilIcon />}
              onClick={move.onClick}
              disabled={move.disabled}
            />
          )}
        </div>
      )}
    </div>
  );
};
