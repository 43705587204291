import { useState } from "react";

import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActionButtonV2 as ActionButton, Modal, Switch, TextField } from "@eisox/design-system";
import { NetworkIcon, PencilIcon } from "@eisox/icons";
import { zodResolver } from "@hookform/resolvers/zod";

import { Tooltip } from "~/UI";
import type { GatewaysWithProblem } from "~/UI/screens/House";
import { GATEWAY_MIN_SOFTWARE_VERSIONS, HEATING_CURVE_AUTO } from "~/constants";
import { useBoilerRoomContext } from "~/features/BoilerRooms";
import type { HeatingCurve as HeatingCurveType, HeatingNetwork } from "~/socketio/types";
import { isVersionIsUpper } from "~/utils";

import { heatingCurveSchema } from "../../helpers";
import type { HeatingCurveSchemaType } from "../../helpers";
import { Chart } from "../Chart";
import { HeatingCurve } from "../HeatingCurve";

import styles from "./HeatingCurveDialog.module.scss";

const NAME = "HeatingCurveDialog";

interface HeatingCurveDialogProps {
  houseId: string;
  moduleId: string;
  boilerRoomId: string;
  heatingNetworkId: string;
  heatingNetworkName: string;
  gateways: GatewaysWithProblem[];
  isAutoHeatingCurves?: boolean;
  targetHslope?: number;
  maxOffsetAdaptation?: number;
  heatingCurve?: HeatingCurveType[];
}

const HeatingCurveDialog: React.FC<HeatingCurveDialogProps> = ({
  houseId,
  moduleId,
  boilerRoomId,
  heatingNetworkId,
  heatingNetworkName,
  gateways,
  isAutoHeatingCurves,
  targetHslope,
  maxOffsetAdaptation,
  heatingCurve,
}) => {
  const { t } = useTranslation();

  const { useUpdateBoilerRoom } = useBoilerRoomContext(NAME);

  const [open, setOpen] = useState(false);

  const { mutate } = useUpdateBoilerRoom({ onSuccess: () => handleOpenChange(false) });

  const methods = useForm<HeatingCurveSchemaType>({
    resolver: zodResolver(heatingCurveSchema),
    values: {
      auto: isAutoHeatingCurves ?? false,
      heatingCurve: heatingCurve ?? [],
      hslopeTarget: targetHslope ?? HEATING_CURVE_AUTO.DEFAULT_HSLOPE_TARGET,
      maxOffsetAdaptation: maxOffsetAdaptation ?? HEATING_CURVE_AUTO.DEFAULT_MAX_OFFSET_ADAPTATION,
    },
  });
  const {
    control,
    formState: { dirtyFields, isDirty },
    register,
    reset,
    watch,
  } = methods;

  const auto = watch("auto");
  const hslopeTarget = watch("hslopeTarget");
  const points = watch("heatingCurve");

  const isAutoHeatingCurveAvailable = !gateways.some(
    gateway => !isVersionIsUpper(gateway.softwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.BOILERROOM_AUTO_HEATING_CURVE),
  );

  const onSubmit = (data: HeatingCurveSchemaType) => {
    const updates: HeatingNetwork = { id: heatingNetworkId };
    if (dirtyFields.auto) updates.isAutoHeatingCurves = data.auto;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    if (dirtyFields.heatingCurve) updates.heatingCurves = data.heatingCurve.map(({ offset, ...rest }) => ({ ...rest }));
    if (dirtyFields.hslopeTarget) updates.targetHslope = data.hslopeTarget;
    if (dirtyFields.maxOffsetAdaptation) updates.maxOffsetAdaptation = data.maxOffsetAdaptation;
    mutate([{ id: boilerRoomId, heatingNetworks: [updates] }]);
  };

  const handleOpenChange = (open: boolean) => {
    reset();
    setOpen(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild className={styles.trigger}>
        <PencilIcon className={styles.icon} />
      </Modal.Trigger>
      <Modal.Content className={styles.heatingCurveDialog}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Modal.Header
            title={t("network.content.heatingCurve.dialog.heatingCurve.title", { hn: heatingNetworkName })}
            subtitle={t("network.content.heatingCurve.dialog.heatingCurve.subtitle")}
            icon={<NetworkIcon />}
          >
            <Modal.Close asChild>
              <ActionButton variant="cancel">{t("boilerRoom.dialog.network.header.cancel")}</ActionButton>
            </Modal.Close>
            <ActionButton type="submit" rounded disabled={!isDirty}>
              {t("boilerRoom.dialog.network.header.save")}
              <PencilIcon />
            </ActionButton>
          </Modal.Header>
          <Controller
            control={control}
            name="auto"
            render={({ field: { value, onChange } }) => (
              <Tooltip
                content={t("network.content.heatingCurve.dialog.heatingCurve.boxVersionNotCompatible", {
                  v: GATEWAY_MIN_SOFTWARE_VERSIONS.BOILERROOM_AUTO_HEATING_CURVE,
                })}
                displayContent={!isAutoHeatingCurveAvailable}
              >
                <label className={styles.heatingCurveDialog__title}>
                  {t("network.content.heatingCurve.dialog.heatingCurve.autoMode")}

                  <Switch checked={value} onCheckedChange={onChange} disabled={!isAutoHeatingCurveAvailable} />
                </label>
              </Tooltip>
            )}
          />
          <FormProvider {...methods}>
            <div className={styles.auto}>
              <p className={styles.auto__text}>
                {t("network.content.heatingCurve.dialog.heatingCurve.text", { obj: hslopeTarget })}
              </p>
              <div className={styles.auto__inputs}>
                <label className={styles.heatingCurveDialog__label}>
                  {t("network.content.heatingCurve.dialog.heatingCurve.maxOffsetAdaptation.label")}
                  <span />
                  <TextField.Root
                    {...register("hslopeTarget", { valueAsNumber: true })}
                    type="number"
                    className={styles.heatingCurveDialog__input}
                    disabled={!auto}
                  />
                  <span>{t("network.content.heatingCurve.dialog.heatingCurve.maxOffsetAdaptation.unity")}</span>
                </label>
                <label className={styles.heatingCurveDialog__label}>
                  {t("network.content.heatingCurve.dialog.heatingCurve.targetHslope.label")}
                  <span>+/-</span>
                  <TextField.Root
                    {...register("maxOffsetAdaptation", { valueAsNumber: true })}
                    type="number"
                    className={styles.heatingCurveDialog__input}
                    disabled={!auto}
                  />
                  <span>{t("network.content.heatingCurve.dialog.heatingCurve.targetHslope.unity")}</span>
                </label>
              </div>
            </div>
            <h2 className={styles.heatingCurveDialog__title}>
              {t("network.content.heatingCurve.dialog.heatingCurve.heatingCurve")}
            </h2>
            <div className={styles.heatingCurve}>
              <HeatingCurve
                auto={auto}
                houseId={houseId}
                moduleId={moduleId}
                heatingNetworkId={heatingNetworkId}
                heatingNetworkName={heatingNetworkName}
              />
              <Chart className={styles.heatingCurve__chart} auto={auto} points={points} />
            </div>
          </FormProvider>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};

HeatingCurveDialog.displayName = NAME;

export { HeatingCurveDialog };
export type { HeatingCurveDialogProps };
