import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import { useBem } from "@eisox/tools";

import { PlanV2 } from "~/UI/layouts";
import { ItemType } from "~/UI/layouts/PlanV2/components";
import { getPlan } from "~/api/plan";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";

import styles from "./Plan.module.scss";

interface PlanProps {
  items: ItemType[];
  planId: string;
  onClickPlan: (x: number, y: number) => void;
  onChangeOnPlan: (items: ItemType[]) => void;
}

export const Plan: React.FC<PlanProps> = ({ items, planId, onChangeOnPlan, onClickPlan }) => {
  const { houseId } = useParams();

  const bem = useBem(styles);
  const planStyle = bem("plan");

  const [planUrl, setPlanUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    getPlan(planId, houseId).then(response =>
      response.type === SUCCESS_FETCH ? setPlanUrl(response.result.message) : setPlanUrl(undefined),
    );
  }, [planId]);

  return (
    <PlanV2 planUrl={planUrl} items={items} onClick={onClickPlan} onChange={onChangeOnPlan} className={planStyle()} />
  );
};
