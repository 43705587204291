import React, { PropsWithChildren } from "react";

import { useBem } from "@eisox/tools";

import styles from "./styles.module.scss";

import clsx from "clsx";

export interface StepProps extends PropsWithChildren {
  title?: React.ReactNode;
}

const Step: React.FC<StepProps> = ({ title, children }) => {
  const bem = useBem(styles);
  const stepStyle = bem("step");
  return (
    <>
      <h3 className={stepStyle("title")}>{title}</h3>
      <div className={stepStyle("content")}>{children}</div>
    </>
  );
};

export interface StepsProps {
  children: React.ReactElement<StepProps> | Array<React.ReactElement<StepProps>>;
  className?: string;
}

export const Steps = Object.assign(
  ({ children, className }: StepsProps) => {
    const bem = useBem(styles);
    const stepsStyle = bem("steps");

    return (
      <div className={clsx(stepsStyle(), className)}>
        {React.Children.map(children, (step, index) => {
          if (React.isValidElement(step) && step.type === Step) {
            return (
              <div key={index} className={stepsStyle("step")}>
                <div className={stepsStyle("number")}>{index + 1}</div>
                <div className={stepsStyle("divider")} />
                {step}
              </div>
            );
          }
        })}
      </div>
    );
  },
  { Step },
);
