import { forwardRef } from "react";

import { primary } from "@eisox/colors";
import type { IconProps } from "@eisox/icons";

const Eisox = forwardRef<SVGSVGElement, IconProps>(({ color = primary.blue, ...props }, forwardedRef) => (
  <svg
    width="133"
    height="30"
    viewBox="0 0 133 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={forwardedRef}
  >
    <path
      d="M22.9546 12.1667C22.3958 10.361 21.4029 8.90781 19.9746 7.80722C18.546 6.70753 16.9281 6.15699 15.1224 6.15699C13.3491 6.15699 11.7558 6.70753 10.3444 7.80722C8.932 8.90781 7.94688 10.361 7.38886 12.1667H22.9546ZM30.3927 14.9252C30.3927 15.3854 30.3681 15.8612 30.3188 16.3536C30.2696 16.8463 30.2118 17.3558 30.1464 17.8806H7.38886C7.65133 18.7017 8.02077 19.4817 8.49717 20.2207C8.97281 20.9593 9.55618 21.5999 10.2459 22.1415C10.9355 22.6834 11.7153 23.1107 12.5857 23.4223C13.4553 23.7348 14.416 23.8903 15.4672 23.8903C16.7481 23.8903 18.0288 23.6685 19.3096 23.2253C20.5902 22.782 21.657 22.1833 22.5113 21.4273L26.9937 25.9099C25.0235 27.3877 23.0939 28.4305 21.2059 29.038C19.3172 29.645 17.3222 29.9492 15.2209 29.9492C13.0859 29.9492 11.091 29.5551 9.23604 28.7669C7.38037 27.9788 5.77093 26.912 4.40865 25.5649C3.0456 24.219 1.97024 22.6342 1.1822 20.8118C0.394022 18.9891 0 17.0433 0 14.9744C0 12.9057 0.394022 10.9598 1.1822 9.13732C1.97024 7.3146 3.05397 5.73052 4.43337 4.38382C5.81264 3.0375 7.42117 1.97025 9.26063 1.18208C11.0993 0.393902 13.0697 -0.000120163 15.1717 -0.000120163C17.1744 -0.000120163 19.0955 0.369188 20.935 1.10819C22.7738 1.84706 24.3993 2.87376 25.8115 4.18674C27.2232 5.5005 28.34 7.06835 29.1613 8.89107C29.9817 10.7135 30.3927 12.7254 30.3927 14.9252Z"
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path d="M34.9238 29.2129H42.1157V0.691992H34.9238V29.2129Z" fill={color} fillRule="evenodd" clipRule="evenodd" />
    <path
      d="M69.5037 21.2328C69.5037 22.6455 69.1742 23.9014 68.5164 25.0012C67.8573 26.1018 67.0017 27.0208 65.9486 27.7596C64.8958 28.4986 63.7104 29.0573 62.3943 29.4346C61.0775 29.8118 59.7774 30.001 58.4944 30.001C56.3215 30.001 54.3221 29.5983 52.4955 28.7941C50.6684 27.9899 49.0643 26.7006 47.6821 24.9273L51.9677 20.5926C52.8211 21.676 53.8063 22.5223 54.9231 23.1292C56.0391 23.7374 57.2382 24.0407 58.5191 24.0407C59.635 24.0407 60.5632 23.8361 61.3022 23.4249C62.0408 23.0148 62.4105 22.3989 62.4105 21.5778C62.4105 20.7243 61.8848 20.01 60.8342 19.4348C59.7829 18.8607 58.3049 18.2945 56.4008 17.7354C55.2841 17.4076 54.2004 16.9888 53.1498 16.4793C52.0985 15.9706 51.1624 15.3466 50.3421 14.6077C49.5208 13.8688 48.8643 13.0238 48.3717 12.0707C47.8791 11.1188 47.6328 10.0187 47.6328 8.77034C47.6328 7.39107 47.9537 6.15197 48.5957 5.05139C49.2376 3.9517 50.0765 3.03184 51.1132 2.29297C52.15 1.5541 53.3268 0.987713 54.6436 0.593561C55.9598 0.199539 57.2929 0.00246239 58.6429 0.00246239C62.9871 0.00246239 66.5419 1.64472 69.3068 4.92833L65.0704 9.21379C64.2492 8.19623 63.3133 7.39107 62.2628 6.79997C61.2113 6.20887 60.0621 5.91345 58.8144 5.91345C58.3874 5.91345 57.9356 5.97112 57.46 6.08581C56.9834 6.20128 56.5402 6.36527 56.1299 6.57856C55.719 6.79224 55.3826 7.07093 55.1201 7.41578C54.8569 7.76063 54.7261 8.14706 54.7261 8.57327C54.7261 9.32926 55.2263 9.98575 56.2285 10.5436C57.2297 11.1026 58.6669 11.6604 60.5386 12.2186C61.7533 12.5802 62.903 13.04 63.9868 13.5979C65.0704 14.1564 66.0225 14.8046 66.8436 15.5436C67.6641 16.2823 68.313 17.12 68.7896 18.0556C69.2651 18.9916 69.5037 20.0506 69.5037 21.2328Z"
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M96.2017 14.9764C96.2017 13.7617 95.9885 12.6291 95.5631 11.5777C95.1373 10.527 94.5562 9.60731 93.8197 8.81914C93.0829 8.03097 92.2234 7.41515 91.2412 6.97196C90.259 6.52851 89.2115 6.30697 88.0987 6.30697C86.9856 6.30697 85.9375 6.52851 84.9554 6.97196C83.9733 7.41515 83.1133 8.03097 82.377 8.81914C81.6405 9.60731 81.0593 10.527 80.6336 11.5777C80.2079 12.6291 79.9955 13.7617 79.9955 14.9764C79.9955 16.1919 80.2079 17.3247 80.6336 18.3754C81.0593 19.4266 81.6405 20.3457 82.377 21.1339C83.1133 21.922 83.9733 22.5376 84.9554 22.981C85.9375 23.4242 86.9856 23.6462 88.0987 23.6462C89.2115 23.6462 90.259 23.4242 91.2412 22.981C92.2234 22.5376 93.0829 21.922 93.8197 21.1339C94.5562 20.3457 95.1373 19.4266 95.5631 18.3754C95.9885 17.3247 96.2017 16.1919 96.2017 14.9764ZM103.295 14.9764C103.295 17.1115 102.901 19.0897 102.113 20.9122C101.325 22.7345 100.241 24.3109 98.8618 25.6409C97.4825 26.971 95.8648 28.0224 94.0099 28.7934C92.1542 29.5649 90.1754 29.9512 88.0741 29.9512C85.9722 29.9512 84.0019 29.5649 82.1628 28.7934C80.3235 28.0224 78.7149 26.9634 77.3357 25.6165C75.9566 24.2702 74.8727 22.6854 74.0845 20.8629C73.2965 19.0402 72.9023 17.0784 72.9023 14.9764C72.9023 12.9077 73.3041 10.9619 74.1093 9.13927C74.9137 7.31655 76.0056 5.72437 77.3849 4.36106C78.7642 2.9989 80.3728 1.93127 82.2123 1.15932C84.051 0.388262 86.0051 0.00183296 88.0741 0.00183296C90.1754 0.00183296 92.1542 0.388262 94.0099 1.15932C95.8648 1.93127 97.4825 2.98178 98.8618 4.31188C100.241 5.64186 101.325 7.2267 102.113 9.06539C102.901 10.905 103.295 12.8752 103.295 14.9764Z"
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
    />
    <path
      d="M132.455 29.2627H124.327L118.416 20.15L112.456 29.2627H104.328L114.476 14.3373L105.165 0.692505H113.195L118.416 8.67246L123.588 0.692505H131.667L122.308 14.3373L132.455 29.2627Z"
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
));

export default Eisox;
export { Eisox };
