import { client } from "../api";
import type { UpdateBoilerRoomRequestBody } from "../types";

interface UpdateBoilerRoomParams {
  moduleId: string;
  boilerRoomId: string;
  body: UpdateBoilerRoomRequestBody;
}

const updateBoilerRoom = async ({ moduleId, boilerRoomId, body }: UpdateBoilerRoomParams) => {
  const { data, error } = await client.PATCH("/modules/{moduleId}/boilerrooms/{boilerRoomId}", {
    params: { path: { moduleId, boilerRoomId } },
    body,
  });
  if (error) throw new Error(error);
  if (!data?.message) throw new Error();
  return data.message;
};

export { updateBoilerRoom };
export type { UpdateBoilerRoomParams };
