import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";

import styles from "./DropdownMenu.module.scss";

/** --------------------------------------------------------------------------------------------------------------------
 * Dropdown
 * ------------------------------------------------------------------------------------------------------------------ */

type DropdownMenuProps = RadixDropdownMenu.DropdownMenuProps;

const Root = RadixDropdownMenu.Root;

Root.displayName = "DropdownMenu";

/** --------------------------------------------------------------------------------------------------------------------
 * Trigger
 * ------------------------------------------------------------------------------------------------------------------ */

type TriggerProps = RadixDropdownMenu.DropdownMenuTriggerProps;

const Trigger = RadixDropdownMenu.Trigger;

Trigger.displayName = "DropdownMenuTrigger";

/** --------------------------------------------------------------------------------------------------------------------
 * Content
 * ------------------------------------------------------------------------------------------------------------------ */

type ContentProps = RadixDropdownMenu.DropdownMenuContentProps;

const Content = forwardRef<HTMLDivElement, ContentProps>(({ className, ...props }, forwardedRef) => (
  <RadixDropdownMenu.Portal>
    <RadixDropdownMenu.Content {...props} ref={forwardedRef} className={cx(styles.content, className)} />
  </RadixDropdownMenu.Portal>
));

Content.displayName = "DropdownMenuContent";

/** --------------------------------------------------------------------------------------------------------------------
 * Arrow
 * ------------------------------------------------------------------------------------------------------------------ */

type ArrowProps = RadixDropdownMenu.DropdownMenuArrowProps;

const Arrow = forwardRef<SVGSVGElement, ArrowProps>(({ className, ...props }, forwardedRef) => (
  <RadixDropdownMenu.Arrow {...props} ref={forwardedRef} className={cx(styles.arrow, className)} />
));

Arrow.displayName = "DropdownMenuArrow";

/** --------------------------------------------------------------------------------------------------------------------
 * Item
 * ------------------------------------------------------------------------------------------------------------------ */

type ItemProps = RadixDropdownMenu.DropdownMenuItemProps;

const Item = forwardRef<HTMLDivElement, ItemProps>(({ className, ...props }, forwardedRef) => (
  <RadixDropdownMenu.Item {...props} ref={forwardedRef} className={cx(styles.item, className)} />
));

Item.displayName = "DropdownMenuItem";

/** --------------------------------------------------------------------------------------------------------------------
 * Group
 * ------------------------------------------------------------------------------------------------------------------ */

type GroupProps = RadixDropdownMenu.DropdownMenuGroupProps;

const Group = RadixDropdownMenu.Group;

Group.displayName = "DropdownMenuGroup";

/** --------------------------------------------------------------------------------------------------------------------
 * Label
 * ------------------------------------------------------------------------------------------------------------------ */

type LabelProps = RadixDropdownMenu.DropdownMenuLabelProps;

const Label = forwardRef<HTMLDivElement, LabelProps>(({ className, ...props }, forwardedRef) => (
  <RadixDropdownMenu.Label {...props} ref={forwardedRef} className={cx(styles.label, className)} />
));

Label.displayName = "DropdownMenuLabel";

/** --------------------------------------------------------------------------------------------------------------------
 * CheckboxItem
 * ------------------------------------------------------------------------------------------------------------------ */

type CheckboxItemProps = RadixDropdownMenu.DropdownMenuCheckboxItemProps;

const CheckboxItem = forwardRef<HTMLDivElement, CheckboxItemProps>(({ className, ...props }, forwardedRef) => (
  <RadixDropdownMenu.CheckboxItem {...props} ref={forwardedRef} className={cx(styles.checkboxItem, className)} />
));

CheckboxItem.displayName = "DropdownMenuCheckboxItem";

/** --------------------------------------------------------------------------------------------------------------------
 * RadioGroup
 * ------------------------------------------------------------------------------------------------------------------ */

type RadioGroupProps = RadixDropdownMenu.DropdownMenuRadioGroupProps;

const RadioGroup = RadixDropdownMenu.RadioGroup;

RadioGroup.displayName = "DropdownMenuRadioGroup";

/** --------------------------------------------------------------------------------------------------------------------
 * RadioItem
 * ------------------------------------------------------------------------------------------------------------------ */

type RadioItemProps = RadixDropdownMenu.DropdownMenuRadioItemProps;

const RadioItem = forwardRef<HTMLDivElement, RadioItemProps>(({ className, ...props }, forwardedRef) => (
  <RadixDropdownMenu.RadioItem {...props} ref={forwardedRef} className={cx(styles.radioItem, className)} />
));

RadioItem.displayName = "DropdownMenuRadioItem";

/** --------------------------------------------------------------------------------------------------------------------
 * ItemIndicator
 * ------------------------------------------------------------------------------------------------------------------ */

type ItemIndicatorProps = RadixDropdownMenu.DropdownMenuItemIndicatorProps;

const ItemIndicator = forwardRef<HTMLSpanElement, ItemIndicatorProps>(({ className, ...props }, forwardedRef) => (
  <RadixDropdownMenu.ItemIndicator {...props} ref={forwardedRef} className={cx(styles.itemIndicator, className)} />
));

ItemIndicator.displayName = "DropdownMenuItemIndicator";

/** --------------------------------------------------------------------------------------------------------------------
 * Sub
 * ------------------------------------------------------------------------------------------------------------------ */

type SubProps = RadixDropdownMenu.DropdownMenuSubProps;

const Sub = RadixDropdownMenu.Sub;

Sub.displayName = "DropdownMenuSub";

/** --------------------------------------------------------------------------------------------------------------------
 * SubTrigger
 * ------------------------------------------------------------------------------------------------------------------ */

type SubTriggerProps = RadixDropdownMenu.DropdownMenuSubTriggerProps;

const SubTrigger = forwardRef<HTMLDivElement, SubTriggerProps>(({ className, ...props }, forwardedRef) => (
  <RadixDropdownMenu.SubTrigger {...props} ref={forwardedRef} className={cx(styles.subTrigger, className)} />
));

SubTrigger.displayName = "DropdownMenuSubTrigger";

/** --------------------------------------------------------------------------------------------------------------------
 * SubContent
 * ------------------------------------------------------------------------------------------------------------------ */

type SubContentProps = RadixDropdownMenu.DropdownMenuSubContentProps;

const SubContent = forwardRef<HTMLDivElement, SubContentProps>(({ className, ...props }, forwardedRef) => (
  <RadixDropdownMenu.SubContent {...props} ref={forwardedRef} className={cx(styles.subContent, className)} />
));

SubContent.displayName = "DropdownMenuSubContent";

export {
  Arrow,
  CheckboxItem,
  Content,
  Group,
  Item,
  ItemIndicator,
  Label,
  RadioGroup,
  RadioItem,
  Root,
  Sub,
  SubContent,
  SubTrigger,
  Trigger,
};
export type {
  ArrowProps,
  CheckboxItemProps,
  ContentProps,
  DropdownMenuProps,
  GroupProps,
  ItemIndicatorProps,
  ItemProps,
  LabelProps,
  RadioGroupProps,
  RadioItemProps,
  SubContentProps,
  SubProps,
  SubTriggerProps,
  TriggerProps,
};
