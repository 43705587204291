import { forwardRef } from "react";
import React from "react";

import clsx from "clsx";

import { UploadIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./styles.module.scss";

export interface FileInputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  onAddFile?: (files: File[]) => void;
}

const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ onAddFile, className, placeholder, ...props }, ref) => {
    const bem = useBem(styles);
    const fileInputStyle = bem("file-input");

    const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      const droppedFiles = e.dataTransfer.files;
      if (droppedFiles.length > 0) {
        onAddFile && onAddFile(Array.from(droppedFiles));
      }
    };

    return (
      <label className={clsx(fileInputStyle(), className)} onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
        {placeholder}
        <UploadIcon className={fileInputStyle("icon")} />
        <input ref={ref} {...props} type="file" className={fileInputStyle("input")} />
      </label>
    );
  },
);

FileInput.displayName = "FileInput";

export { FileInput };
