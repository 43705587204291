import type { ZodError, ZodObject, ZodRawShape } from "zod";
import { z } from "zod";
import { zodI18nMap } from "zod-i18n-map";
import en from "zod-i18n-map/locales/en/zod.json";
import fr from "zod-i18n-map/locales/fr/zod.json";

z.setErrorMap(zodI18nMap);

export { z, en, fr };
export type { ZodError, ZodObject, ZodRawShape };
