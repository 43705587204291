import { forwardRef } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { RoundIcon, Tooltip } from "@eisox/design-system";
import { GatewayIcon, ValveIcon } from "@eisox/icons";

import { Room } from "../Room";

import styles from "./PlanItem.module.scss";

interface PlanItemProps {
  type: "gateways" | "valves" | "rooms";
  name?: string;
  mac?: string;
  updatedAt?: string;
  errors: string[];
  warnings: string[];
  onClick?: () => void;
}

const PlanItem: React.FC<PlanItemProps> = forwardRef<HTMLDivElement, PlanItemProps>(
  ({ type, name: nameProp, mac, updatedAt: updatedAtProp, errors, warnings, onClick }, forwardedRef) => {
    const { t } = useTranslation();

    const updatedAt = dayjs(updatedAtProp).format("LT - L");
    const headerStatus = errors.length > 0 ? "error" : warnings.length > 0 ? "warning" : "connected";

    const icon = {
      gateways: <GatewayIcon />,
      valves: <ValveIcon />,
      rooms: null,
    }[type];

    const name = {
      gateways: (
        <>
          {nameProp} <span>{updatedAt}</span>
        </>
      ),
      valves: (
        <>
          {mac} <span>{updatedAt}</span>
        </>
      ),
      rooms: nameProp,
    }[type];

    return (
      <Tooltip.Root>
        <Tooltip.Trigger>
          {type === "rooms" ? (
            <Room name={nameProp ?? "--"} selected onClick={onClick} />
          ) : (
            <RoundIcon
              ref={forwardedRef}
              iconColor="white"
              backgroundColor="blue"
              size={20}
              style={{ cursor: "default" }}
            >
              {icon}
            </RoundIcon>
          )}
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className={styles.tooltip}>
            <Tooltip.Arrow />
            <p className={cx(styles.tooltip__header, styles[`tooltip__header_${headerStatus}`])}>{name}</p>
            {errors.length > 0 && (
              <div>
                <span>{t("advancedMaintenance.locationDialog.tooltip.errors")}</span>
                <ul className={styles.tooltip__list}>
                  {errors.map(e => (
                    <li className={cx(styles.tooltip__problem, styles.tooltip__problem_error)}>{e}</li>
                  ))}
                </ul>
              </div>
            )}
            {warnings.length > 0 && (
              <div>
                <span>{t("advancedMaintenance.locationDialog.tooltip.warnings")}</span>
                <ul className={styles.tooltip__list}>
                  {warnings.map(w => (
                    <li className={cx(styles.tooltip__problem, styles.tooltip__problem_warning)}>{w}</li>
                  ))}
                </ul>
              </div>
            )}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    );
  },
);

export { PlanItem };
export type { PlanItemProps as ValveProps };
