import React, { forwardRef } from "react";

import clsx from "clsx";

import { Circle, Typography } from "@eisox/design-system";
import { ArrowDownIcon, ArrowLeftIcon, ArrowUpIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./Card.module.scss";

interface CardProps {
  title: string;
  subtitle?: string;
  icon: JSX.Element;
  orientation?: "up" | "down";
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  className?: string;
}

export const Card: React.FC<CardProps> = forwardRef<HTMLDivElement, CardProps>(
  ({ title, subtitle, icon, onClick, orientation, className }, forwardedRef) => {
    const bem = useBem(styles);
    const cardStyle = bem("card");
    const titlesStyle = bem("titles");

    return (
      <div ref={forwardedRef} className={clsx(cardStyle(), className)} onClick={onClick}>
        <Circle className={cardStyle("circle")}>
          {React.cloneElement(icon, { className: clsx(icon.props.className, cardStyle("icon")) })}
        </Circle>
        {!!subtitle && <ArrowLeftIcon className={cardStyle("arrow")} />}
        <div className={titlesStyle()}>
          <Typography className={titlesStyle("title")}>{title}</Typography>
          {subtitle && <Typography className={titlesStyle("subtitle")}>{subtitle}</Typography>}
        </div>
        {orientation &&
          (orientation === "up" ? (
            <ArrowUpIcon className={cardStyle("arrow")} />
          ) : (
            <ArrowDownIcon className={cardStyle("arrow")} />
          ))}
      </div>
    );
  },
);
