import { useIntl } from "react-intl";

import { primary } from "@eisox/colors";
import { ActionButton, Dialog } from "@eisox/design-system";
import { WarningIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Mode, useModeContext } from "../../providers";

import styles from "./HistoryExitPopup.module.scss";

const NAME = "HistoryExitPopup";

interface HistoryExitPopupProps {
  children?: React.ReactNode;
}

export const HistoryExitPopup: React.FC<HistoryExitPopupProps> = ({ children }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const historyPopupStyle = bem("history-exit");

  const { onModeChange } = useModeContext(NAME);

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content
        className={historyPopupStyle()}
        icon={<WarningIcon color={primary.red} className={historyPopupStyle("icon")} />}
        title={formatMessage({ id: "boilerRoom.popup.historyExit.title" })}
      >
        <div className={historyPopupStyle("buttons")}>
          <Dialog.Close asChild>
            <ActionButton variant="cancel" text={formatMessage({ id: "boilerRoom.popup.historyExit.cancel" })} />
          </Dialog.Close>
          <ActionButton
            rounded
            variant="primary"
            text={formatMessage({ id: "boilerRoom.popup.historyExit.continue" })}
            onClick={() => onModeChange(Mode.Live)}
          />
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

HistoryExitPopup.displayName = NAME;
