import { useState } from "react";
import type { ComponentPropsWithoutRef } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import { ButtonV2, Dropdown, Loader, Toggle } from "@eisox/design-system";
import { OptionIcon, PlusIcon } from "@eisox/icons";

import { PlanningTypeKey } from "~/UI";
import { Week } from "~/UI/layouts/Week";
import { PlanningType } from "~/socketio/types";
import type { HeatingNetwork } from "~/socketio/types";

import { Card } from "../../../components";
import { ReducedTemperatureTimeslotPopup } from "../ReducedTemperatureTimeslotPopup";
import { ThermalShockTimeslotPopup } from "../ThermalShockTimeslotPopup";

import styles from "./PlanningCard.module.scss";

interface PlanningCardProps extends ComponentPropsWithoutRef<"div"> {
  ecs: boolean;
  heatingNetwork?: HeatingNetwork;
}

const PlanningCard: React.FC<PlanningCardProps> = ({ ecs, heatingNetwork, className, ...props }) => {
  const { t } = useTranslation();

  const [openReducedTemperatureTimeslotPopup, setOpenReducedTemperatureTimeslotPopup] = useState(false);
  const [openThermalShockTimeslotPopup, setOpenThermalShockTimeslotPopup] = useState(false);
  const [displayUserDefinedPlanning, setDisplayUserDefinedPlanning] = useState(true);
  const [planningToUpdateIndex, setPlanningToUpdateIndex] = useState<number | undefined>(undefined);

  const isLoading = heatingNetwork?.plannings === undefined;
  const plannings = heatingNetwork?.plannings?.filter(p => (displayUserDefinedPlanning ? true : !p.userDefined)) ?? [];
  const weekPlannings = plannings.map((p, i) => ({
    ...p,
    onClick: p.userDefined ? () => handleClickTimeslot(i) : undefined,
  }));

  const handleClickTimeslot = (index: number) => {
    setPlanningToUpdateIndex(index);
    if (ecs) {
      setOpenThermalShockTimeslotPopup(true);
    } else {
      setOpenReducedTemperatureTimeslotPopup(true);
    }
  };
  const handleClosePopup = () => {
    setPlanningToUpdateIndex(undefined);
    if (ecs) {
      setOpenThermalShockTimeslotPopup(false);
    } else {
      setOpenReducedTemperatureTimeslotPopup(false);
    }
  };
  const toggleDisplayUserDefinedPlanning = () => setDisplayUserDefinedPlanning(prev => !prev);

  return (
    <>
      <Card.Root {...props} className={cx(styles.planningCard, className)}>
        <Card.Header
          title={t(ecs ? "network.content.planning.title.thermalShock" : "network.content.planning.title.reduced")}
          className={styles.header}
        >
          {!ecs && (
            <ButtonV2 asChild onClick={() => setOpenReducedTemperatureTimeslotPopup(true)}>
              <PlusIcon />
            </ButtonV2>
          )}
          <Dropdown.Root>
            <Dropdown.Trigger asChild>
              <ButtonV2 asChild>
                <OptionIcon />
              </ButtonV2>
            </Dropdown.Trigger>
            <Dropdown.Content className={styles.dropdown} sideOffset={10} align="end">
              <p className={styles.dropdown__label}>{t("network.content.planning.title.display")}</p>
              <Toggle pressed={displayUserDefinedPlanning} onPressedChange={toggleDisplayUserDefinedPlanning}>
                {t("plannings.popup.header.options.userDefinedPopup")}
              </Toggle>
            </Dropdown.Content>
          </Dropdown.Root>
        </Card.Header>
        <div className={styles.content}>
          <div className={styles.content__week}>{isLoading ? <Loader /> : <Week plannings={weekPlannings} />}</div>
          <div className={styles.content__legend}>
            {ecs ? (
              <PlanningTypeKey planningType={PlanningType.ThermalShock} />
            ) : (
              <>
                <PlanningTypeKey planningType={PlanningType.Occupation} />
                <PlanningTypeKey planningType={PlanningType.Inoccupation} />
                <PlanningTypeKey planningType={"userDefined"} />
              </>
            )}
          </div>
        </div>
      </Card.Root>
      {openReducedTemperatureTimeslotPopup && (
        <ReducedTemperatureTimeslotPopup
          open={openReducedTemperatureTimeslotPopup}
          onClose={handleClosePopup}
          planning={planningToUpdateIndex !== undefined ? plannings[planningToUpdateIndex] : undefined}
          weekPlannings={plannings}
        />
      )}
      {openThermalShockTimeslotPopup && (
        <ThermalShockTimeslotPopup
          open={openThermalShockTimeslotPopup}
          onClose={handleClosePopup}
          planning={planningToUpdateIndex !== undefined ? plannings[planningToUpdateIndex] : undefined}
          weekPlannings={plannings}
        />
      )}
    </>
  );
};

PlanningCard.displayName = "PlanningCard";

export { PlanningCard };
export type { PlanningCardProps };
