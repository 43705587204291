import { cva } from "class-variance-authority";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { NutIcon } from "@eisox/icons";

import { Tooltip } from "~/UI/components";
import Warning from "~/assets/svg/boilerroomWarning.svg?react";
import { routeToFrostFreeSettings, routeToSeasonSettings } from "~/routes/utils";
import type { BoilerRoom } from "~/socketio/types";

import { checkInconsistencies } from "../../helpers";

import styles from "./PrimaryIndicators.module.scss";

const indicatorVariant = cva(styles.indicator, {
  variants: {
    variant: {
      tExt: styles.indicator_variant_tExt,
      frostFree: styles.indicator_variant_frostFree,
      season: styles.indicator_variant_season,
    },
  },
});

interface PrimaryIndicatorsProps {
  houseId: string;
  boilerRooms: BoilerRoom[];
}

const PrimaryIndicators: React.FC<PrimaryIndicatorsProps> = ({ houseId, boilerRooms }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const externalTemperature = checkInconsistencies(boilerRooms, "externalTemperature");
  const frostFreeModeState = checkInconsistencies(boilerRooms, "frostFreeModeState");
  const summerModeState = checkInconsistencies(boilerRooms, "summerModeState");

  const handleClickFrostFreeNutIcon = () => navigate(routeToFrostFreeSettings(houseId));
  const handleClickSeasonNutIcon = () => navigate(routeToSeasonSettings(houseId));

  return (
    <div className={styles.primaryIndicators}>
      {externalTemperature !== null && (
        <div className={indicatorVariant({ variant: "tExt" })}>
          {t("boilerRoom.rightColumn.boilerroom.indicator.externalTemperature", { t: externalTemperature.value })}
        </div>
      )}
      {frostFreeModeState !== null && (
        <div className={indicatorVariant({ variant: "frostFree" })}>
          <Trans
            t={t}
            i18nKey="boilerRoom.rightColumn.boilerroom.indicator.frostFree"
            components={[<span />]}
            tOptions={{ ff: t(frostFreeModeState.value ? "activated" : "deactivated.minus") }}
          />
          <NutIcon className={styles.indicator__icon} onClick={handleClickFrostFreeNutIcon} />
          {frostFreeModeState.inconsistency && (
            <InconsistencyTooltip property="frostFreeModeState" inconsistencies={frostFreeModeState.inconsistency} />
          )}
        </div>
      )}
      {summerModeState !== null && (
        <div className={indicatorVariant({ variant: "season" })}>
          <Trans
            t={t}
            i18nKey="boilerRoom.rightColumn.boilerroom.indicator.season"
            components={[<span />]}
            tOptions={{ s: t(summerModeState.value ? "winter" : "summer") }}
          />
          <NutIcon className={styles.indicator__icon} onClick={handleClickSeasonNutIcon} />
          {summerModeState.inconsistency && (
            <InconsistencyTooltip property="summerModeState" inconsistencies={summerModeState.inconsistency} />
          )}
        </div>
      )}
    </div>
  );
};

interface InconsistencyTooltipProps<T extends keyof BoilerRoom> {
  property: T;
  inconsistencies: Map<string, BoilerRoom[T]>;
}

const InconsistencyTooltip: <T extends keyof BoilerRoom>(props: InconsistencyTooltipProps<T>) => JSX.Element = ({
  property,
  inconsistencies,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={
        <div className={styles.inconsistencyTooltip}>
          <p>{t("boilerRoom.rightColumn.boilerroom.inconsistency")}</p>
          {Array.from(inconsistencies).map(([key, value]) => (
            <p key={key}>
              {key}:{" "}
              {property === "frostFreeModeState"
                ? t(value === undefined ? "undefined" : value ? "activated" : "deactivated.minus")
                : property === "summerModeState"
                  ? t(value === undefined ? "undefined" : value ? "winter" : "summer")
                  : `${value as number}`}
            </p>
          ))}
        </div>
      }
    >
      <Warning className={styles.inconsistencyTooltip__icon} />
    </Tooltip>
  );
};

export { PrimaryIndicators };
export type { PrimaryIndicatorsProps };
