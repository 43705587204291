import { useIntl } from "react-intl";

import { ActionButton, AlertDialog } from "@eisox/design-system";
import { WarningIcon } from "@eisox/icons";

import styles from "./SuspendedAccountDialog.module.scss";

interface SuspendedAccountDialogProps extends AlertDialog.RootProps {
  isAdmin: boolean;
}

const SuspendedAccountDialog: React.FC<SuspendedAccountDialogProps> = ({ isAdmin, children, ...props }) => {
  const { formatMessage } = useIntl();

  return (
    <AlertDialog.Root {...props}>
      <AlertDialog.Trigger asChild>{children}</AlertDialog.Trigger>
      <AlertDialog.Content
        title={formatMessage({ id: "suspendedAccountDialog.title" })}
        icon={<WarningIcon className={styles.suspendedAccountDialog__warning} />}
        className={styles.suspendedAccountDialog}
      >
        <div className={styles.suspendedAccountDialog__description}>
          {formatMessage({ id: isAdmin ? "suspendedAccountDialog.notAdmin" : "suspendedAccountDialog.admin" })}
        </div>
        <AlertDialog.Footer>
          <AlertDialog.Cancel asChild>
            <ActionButton variant="cancel" text={formatMessage({ id: "suspendedAccountDialog.cancel" })} />
          </AlertDialog.Cancel>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog.Root>
  );
};

SuspendedAccountDialog.displayName = "SuspendedAccountDialog";

export { SuspendedAccountDialog };
export type { SuspendedAccountDialogProps };
