import { createContext, useContext } from "react";

import type { Planning } from "@eisox/backend_webapp_api";

import type { RoomsWithProblem, ValvesWithProblem } from "../House";

interface RoomContext {
  valves: ValvesWithProblem[];
  room: RoomsWithProblem;
  isRoomHasValvesV5: boolean;
  plannings: Planning[];
  setParametersOpen: (boolen: boolean) => void;
}

export const RoomContext = createContext<RoomContext>({
  valves: [],
  room: {} as RoomsWithProblem,
  isRoomHasValvesV5: false,
  plannings: [],
  setParametersOpen: () => {},
});

export const useRoomContext = () => {
  if (!RoomContext) throw new Error("RoomContext is not defined");
  return useContext(RoomContext);
};
