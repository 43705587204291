import dayjs from "@eisox/dayjs";
import type { Option } from "@eisox/design-system";

import type { BoilerroomHistoryResponseType } from "~/api/boilerRoomHistory";

const getDateOptions = (startOfDay = true, length = 30): Option[] =>
  Array.from({ length: length }).map((_, i) => {
    let date = dayjs().subtract(i, "day");
    date = startOfDay ? date.startOf("day") : date.endOf("day");
    return { value: date.toISOString(), name: date.format("ddd DD MMM") };
  });

const closestDate = (date: string, dates: string[]) => {
  const closest = dates.reduce((a, b) => {
    return Math.abs(new Date(a).getTime() - new Date(date).getTime()) <
      Math.abs(new Date(b).getTime() - new Date(date).getTime())
      ? a
      : b;
  });
  return closest;
};

const convertAndSortDates = (data: BoilerroomHistoryResponseType): Record<string, string[]> => {
  const result: Record<string, string[]> = {};

  data.message.forEach(({ createdAt, moduleIds }) => {
    moduleIds?.forEach(moduleId => {
      if (!result[moduleId]) {
        result[moduleId] = [];
      }
      if (createdAt) {
        result[moduleId] = result[moduleId].concat(createdAt);
      }
    });
  });

  Object.keys(result).forEach(moduleId => {
    result[moduleId] = result[moduleId].sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
  });

  return result;
};

export { closestDate, convertAndSortDates, getDateOptions };
