import type { PropsWithChildren } from "react";
import { useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { Loader, Modal, SelectV2 as Select } from "@eisox/design-system";
import { CrossIcon, PointChartIcon } from "@eisox/icons";
import { PostModuleHistoryFields } from "@eisox/webapp-api-specification";
import { useQuery } from "@tanstack/react-query";

import { HistoryChart } from "~/UI";
import type { Module, ModuleHistory } from "~/apiV2";
import { queries } from "~/apiV2";
import { useHistoryStore } from "~/stores";

import {
  extractNamesFromPath,
  getBoilerRoomObject,
  getDataFromHistoryResponse,
  getMostRecentDate,
  getUnitAxesMapping,
} from "../../helpers";
import { DataExportDialog } from "../DataExportDialog/DataExportDialog";
import { DataSelector } from "../DataSelector";

import styles from "./DetailedHistoryDialog.module.scss";

interface DetailedHistoryDialogProps extends PropsWithChildren {
  houseId: string;
  modules: Module[];
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const DetailedHistoryDialog: React.FC<DetailedHistoryDialogProps> = ({
  houseId,
  modules,
  open,
  onOpenChange,
  children,
}) => {
  const { t } = useTranslation();

  const moduleIds = useMemo(() => modules.map(module => module.id!), [modules]);

  const boilerrRoomPath = useHistoryStore.use.boilerromPath();
  const addBoilerRoomPath = useHistoryStore.use.addBoilerromPath();

  const paths = useMemo(() => boilerrRoomPath[houseId] ?? [], [boilerrRoomPath, houseId]);

  const [date, setDate] = useState<string>();

  const definedBoilerRoom = (
    boilerRoom: NonNullable<ModuleHistory["boilerRooms"]>[number] | undefined,
  ): boilerRoom is NonNullable<ModuleHistory["boilerRooms"]>[number] => !!boilerRoom;

  const { data: lastData } = useQuery({
    ...queries.history.lastModule(houseId),
    select: data => ({
      schema: data.flatMap(d => d.boilerRooms).filter(definedBoilerRoom),
      date: dayjs(getMostRecentDate(data)).startOf("day").toISOString(),
    }),
  });

  const { data: chartData, isLoading } = useQuery({
    ...queries.history.module(houseId, {
      ranges: [
        {
          startDate: date ?? lastData?.date,
          endDate: dayjs(date ?? lastData?.date)
            .endOf("day")
            .toISOString(),
        },
      ],
      moduleIds,
      fields: [PostModuleHistoryFields.createdAt],
      ...getBoilerRoomObject(paths),
    }),
    select: data => getDataFromHistoryResponse(paths, data, lastData?.schema),
    enabled: paths.length > 0 && !!lastData,
  });

  const dataKeyAndPathMatching = useMemo(() => {
    const matching: Record<string, string> = {};
    paths.forEach(p => {
      const dataKey = extractNamesFromPath(lastData?.schema, p).key;
      matching[dataKey] = p;
    });
    return matching;
  }, [paths, lastData?.schema]);

  const handleChangePaths = (paths: string[]) => addBoilerRoomPath({ [houseId]: paths });
  const handleChangeDate = (value: string | string[]) => setDate(value as string);
  const handleRemoveData = (dataKey: string | number | undefined) =>
    addBoilerRoomPath({ [houseId]: paths.filter(p => dataKey && p !== dataKeyAndPathMatching[dataKey]) });

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={styles.detailedHistoryDialog}>
        <Modal.Header title={t("boilerRoom.history.detailedHistory.title")} subtitle="" icon={<PointChartIcon />}>
          <Modal.Close asChild>
            <CrossIcon className={styles.detailedHistoryDialog__close} />
          </Modal.Close>
        </Modal.Header>
        <div className={styles.selectors}>
          <DataSelector
            // @ts-expect-error : id must be string in BoilerRoom type from API
            boilerrooms={lastData?.schema ?? []}
            paths={paths}
            onChange={handleChangePaths}
            disabled={isLoading}
          />
          <Select
            placeholder={t("boilerRoom.history.detailedHistory.date.placeholder")}
            classNames={{ trigger: styles.selectors__date }}
            options={Array.from({ length: 90 }, (_, index) => {
              const date = dayjs().startOf("day").subtract(index, "day");
              return {
                value: date.toISOString(),
                name: date.format("ddd DD MMMM"),
              };
            })}
            value={date ?? lastData?.date}
            onChange={handleChangeDate}
            disabled={isLoading}
          />
          {lastData && (
            <DataExportDialog
              houseId={houseId}
              modules={modules}
              boilerRooms={lastData.schema}
              date={lastData.date}
              paths={paths}
            />
          )}
        </div>
        <div className={styles.detailedHistoryDialog__chart}>
          {paths.length === 0 ? (
            <p>{t("boilerRoom.history.noData")}</p>
          ) : isLoading === true || chartData === undefined ? (
            <Loader />
          ) : (
            <HistoryChart
              data={chartData}
              unitAxesMapping={getUnitAxesMapping(paths, lastData?.schema)}
              translationKey=""
              layout="vertical"
              yAxisDomain={[0, "dataMax"]}
              onRemoveData={handleRemoveData}
            />
          )}
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};

export { DetailedHistoryDialog };
export type { DetailedHistoryDialogProps };
