import type { PropsWithChildren } from "react";

import clsx from "clsx";

import { Tooltip as DSTooltip } from "@eisox/design-system";

import styles from "./Tooltip.module.scss";

interface TooltipProps extends PropsWithChildren {
  content: React.ReactNode;
  displayContent?: boolean;
  position?: "top" | "right" | "bottom" | "left";
  openingCondition?: boolean;
  keepHoverOpen?: boolean;
  disableHoverableContent?: boolean;
  className?: string;
  forceMount?: true;
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  content,
  position = "bottom",
  openingCondition = true,
  keepHoverOpen = true,
  forceMount,
  className,
}) => (
  <DSTooltip.Provider delayDuration={0}>
    <DSTooltip.Root disableHoverableContent={!keepHoverOpen}>
      <DSTooltip.Trigger asChild>{children}</DSTooltip.Trigger>
      {content && openingCondition && (
        <DSTooltip.Portal forceMount={forceMount}>
          <DSTooltip.Content className={clsx(styles.tooltip__content, className)} side={position}>
            {content}
            <DSTooltip.Arrow />
          </DSTooltip.Content>
        </DSTooltip.Portal>
      )}
    </DSTooltip.Root>
  </DSTooltip.Provider>
);
