import type { Planning, TimeslotPopupDto } from "~/utils/planningUtils";
import { getActualDay } from "~/utils/planningUtils";

import type { TimeslotDto } from "../components";

export const timeslotPopupDtoToTimeslotDtoMapper = (
  planningToUpdate?: TimeslotPopupDto,
  planning?: Planning,
): TimeslotDto => ({
  dayStart: planningToUpdate?.current.dayStart ?? getActualDay(),
  hourStart: planningToUpdate?.current.hourStart,
  dayEnd: planningToUpdate?.current.dayEnd ?? getActualDay(),
  hourEnd: planningToUpdate?.current.hourEnd,
  planningType: planning?.planningType,
});
