import { useTranslation } from "react-i18next";

import { PlanV2 as DSPlan } from "@eisox/design-system";
import { useQuery } from "@tanstack/react-query";

import { queries } from "~/apiV2";

import type { Gateway, Room, Valve } from "../../types";
import { Data } from "../../types";
import { Gateway as GatewayFC } from "../Gateway";
import { Room as RoomFC } from "../Room";
import { Valve as ValveFC } from "../Valve";

import styles from "./Plan.module.scss";

interface PlanProps {
  houseId: string;
  id: string;
  valves: Valve[];
  displayValves: boolean;
  onClickOnValve?: (id: string) => void;
  rooms: Room[];
  displayRooms: boolean;
  gateways: Gateway[];
  displayGateways: boolean;
  data: Data;
  selectedValveId?: string;
  itemSelectionCallback?: VoidFunction;
}

const Plan: React.FC<PlanProps> = ({
  houseId,
  id,
  valves,
  displayValves,
  onClickOnValve,
  rooms,
  displayRooms,
  gateways,
  displayGateways,
  data,
  selectedValveId,
  itemSelectionCallback,
}) => {
  const { t } = useTranslation();

  const { data: plan, isLoading } = useQuery(queries.plan.plan(houseId, id));

  if (isLoading) return <div className={styles.plan} />;

  const getValveLabel = (valve: Valve): string => {
    return {
      [Data.hslope]: `${valve.hslope?.value ?? "--"} ${t("manualBalancing.unity.hslope")}`,
      [Data.maxOpening]: `${valve.maxOpening?.value ?? "--"} %`,
      [Data.mac]: `${valve.mac ?? "--"}`,
    }[data];
  };

  const gatewaysOnPlan = gateways.filter(gateway => gateway.plan.id === id);
  const roomsOnPlan = rooms.filter(room => room.plan.id === id);
  const valvesOnPlan = valves.filter(valve => valve.plan.id === id);

  return (
    <div className={styles.plan}>
      <DSPlan.Root>
        <DSPlan.Controls className={styles.plan__controls} />
        <DSPlan.Wrapper plan={plan} className={styles.plan__wrapper}>
          {displayGateways &&
            gatewaysOnPlan.map(gateway => (
              <DSPlan.Item
                key={gateway.id}
                asChild
                position={{ x: gateway.plan.x, y: gateway.plan.y }}
                origin="bottom center"
              >
                <GatewayFC>{gateway.name}</GatewayFC>
              </DSPlan.Item>
            ))}
          {displayRooms &&
            roomsOnPlan.map(room => (
              <DSPlan.Item key={room.id} asChild position={{ x: room.plan.x, y: room.plan.y }} origin="bottom center">
                <RoomFC>{room.name}</RoomFC>
              </DSPlan.Item>
            ))}
          {displayValves &&
            valvesOnPlan.map(valve => (
              <DSPlan.Item
                key={valve.id}
                asChild
                position={{ x: valve.plan.x, y: valve.plan.y }}
                onClick={() => onClickOnValve?.(valve.id)}
              >
                <ValveFC
                  label={getValveLabel(valve)}
                  highlighted={selectedValveId === valve.id}
                  onAnimationEnd={itemSelectionCallback}
                />
              </DSPlan.Item>
            ))}
        </DSPlan.Wrapper>
      </DSPlan.Root>
    </div>
  );
};

Plan.displayName = "ManualBalancing.Plan";

export { Plan };
export type { PlanProps };
