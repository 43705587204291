import { forwardRef } from "react";

import { cx } from "class-variance-authority";
import type { OTPInputProps, RenderProps } from "input-otp";
import { OTPInput } from "input-otp";

import styles from "./OTPInput.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
type RootProps = Omit<OTPInputProps, "render" | "children"> &
  Omit<React.ComponentPropsWithoutRef<typeof OTPInput>, "children"> & {
    children: (slot: RenderProps) => React.ReactElement;
  };

const Root = forwardRef<React.ElementRef<typeof OTPInput>, RootProps>(({ className, children, ...props }, ref) => {
  return <OTPInput {...props} ref={ref} className={cx(styles.root, className)} render={slot => children(slot)} />;
});

Root.displayName = "OTPInput";

/* -------------------------------------------------------------------------------------------------
 * Group
 * -----------------------------------------------------------------------------------------------*/
type GroupProps = React.ComponentPropsWithoutRef<"div">;

const Group = forwardRef<React.ElementRef<"div">, GroupProps>(({ className, children, ...props }, ref) => {
  return (
    <div {...props} ref={ref} className={cx(styles.group, className)}>
      {children}
    </div>
  );
});

Group.displayName = "OTPInputGroup";

/* -------------------------------------------------------------------------------------------------
 * Slot
 * -----------------------------------------------------------------------------------------------*/
type SlotProps = RenderProps["slots"][number] & React.ComponentPropsWithoutRef<"div"> & { isError?: boolean };

const Slot = forwardRef<React.ElementRef<"div">, SlotProps>(
  ({ char, hasFakeCaret, isActive, isError, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cx(styles.slot, isActive && styles.slot_active, isError && styles.slot_error, className)}
        {...props}
      >
        <span className={styles.slot__char}>{char}</span>
        {hasFakeCaret && (
          <div className={styles.slot__caretContainer}>
            <div className={styles.slot__caret} />
          </div>
        )}
      </div>
    );
  },
);

Slot.displayName = "InputOTPSlot";

export { Root, Group, Slot };
export type { RootProps, GroupProps, SlotProps };
