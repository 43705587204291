import * as React from "react";

import { IconProps } from "./types";

export const MagnifyingGlassIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M7.22487 1.5C4.06827 1.5 1.5 4.06827 1.5 7.22487C1.5 10.3817 4.06827 12.9497 7.22487 12.9497C10.3817 12.9497 12.9497 10.3817 12.9497 7.22487C12.9497 4.06827 10.3817 1.5 7.22487 1.5ZM7.22487 11.8929C4.65097 11.8929 2.5569 9.79879 2.5569 7.22489C2.5569 4.651 4.65097 2.5569 7.22487 2.5569C9.79876 2.5569 11.8928 4.65097 11.8928 7.22487C11.8928 9.79876 9.79876 11.8929 7.22487 11.8929Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.3452 13.5979L11.3154 10.5681C11.1089 10.3617 10.7746 10.3617 10.5681 10.5681C10.3617 10.7744 10.3617 11.1091 10.5681 11.3154L13.5979 14.3452C13.7011 14.4484 13.8363 14.5 13.9715 14.5C14.1066 14.5 14.2419 14.4484 14.3452 14.3452C14.5516 14.1389 14.5516 13.8042 14.3452 13.5979Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default MagnifyingGlassIcon;
