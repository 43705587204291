import type { ComponentPropsWithRef } from "react";
import { forwardRef, useState } from "react";

import { cx } from "class-variance-authority";
import type { FieldError, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { Tooltip as DSTooltip, RoundIcon, TextField } from "@eisox/design-system";
import { ArrowRightIcon, GaugeIcon, HistoryIcon, TapIcon, ValveIcon } from "@eisox/icons";

import { Tooltip } from "~/UI/components";
import { GATEWAY_MIN_SOFTWARE_VERSIONS, VAVLE_MIN_SOFTWARE_VERSIONS } from "~/constants/appConstantV2";
import { isVersionIsUpper } from "~/utils";

import type { Valve } from "../../types";
import { HslopeHistoryPopup } from "../HslopeHistoryPopup";

import styles from "./Item.module.scss";

interface ItemProps extends Omit<Valve, "plan" | "room" | "gateway">, Omit<ComponentPropsWithRef<"div">, "id"> {
  register?: UseFormRegister<Record<string, number>>;
  error?: FieldError;
  selected?: boolean;
}

const Item = forwardRef<HTMLDivElement, ItemProps>((props, ref) => {
  const { t } = useTranslation();
  const { id, uid, mac, hslope, maxOpening, softwareVersion, gatewaySoftwareVersion, register, error, selected } =
    props;

  const [hslopeHistoricPopupOpen, setHslopeHistoricPopupOpen] = useState(false);

  const inputDisabled = !(
    isVersionIsUpper(softwareVersion, VAVLE_MIN_SOFTWARE_VERSIONS.MANUAL_BALANCING) &&
    isVersionIsUpper(gatewaySoftwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.MANUAL_BALANCING)
  );

  return (
    <DSTooltip.Provider>
      <div {...props} ref={ref} className={cx(styles.item, selected && styles.item_selected)}>
        <div>
          <RoundIcon className={styles.item__icon} size={30} backgroundColor="gray">
            <ValveIcon />
          </RoundIcon>
        </div>
        <div>
          <div className={styles.header}>
            <span>{uid}</span>
            <span>{mac ?? "--"}</span>
            {mac && (
              <>
                <HistoryIcon className={styles.header__history} onClick={() => setHslopeHistoricPopupOpen(true)} />
                {hslopeHistoricPopupOpen && (
                  <HslopeHistoryPopup
                    mac={mac}
                    open={hslopeHistoricPopupOpen}
                    onOpenChange={setHslopeHistoricPopupOpen}
                  />
                )}
              </>
            )}
          </div>
          <div className={styles.content}>
            <div className={styles.informations}>
              <Tooltip content={hslope?.date}>
                <div className={styles.informations__information}>
                  <GaugeIcon />
                  {`${hslope?.value ?? "--"} ${t("manualBalancing.unity.hslope")}`}
                </div>
              </Tooltip>
              <div className={styles.informations__information}>
                <TapIcon />
                {`${maxOpening?.value ?? 100}${t("manualBalancing.unity.maxOpening")}`}
              </div>
            </div>
            <ArrowRightIcon />
            <Tooltip
              content={t("manualBalancing.minSoftwareVersion", {
                v: VAVLE_MIN_SOFTWARE_VERSIONS.MANUAL_BALANCING,
                g: GATEWAY_MIN_SOFTWARE_VERSIONS.MANUAL_BALANCING,
              })}
              openingCondition={inputDisabled}
            >
              <TextField.Root
                {...(register ? register(`${id}`) : {})}
                className={styles.content__input}
                type="number"
                error={!!error}
                disabled={inputDisabled}
              >
                <TextField.Slot side="right" className={styles.content__input_slot}>
                  %
                </TextField.Slot>
              </TextField.Root>
            </Tooltip>
          </div>
          {error && <span className={styles.item__error}>{error.message}</span>}
        </div>
      </div>
    </DSTooltip.Provider>
  );
});

Item.displayName = "ManualBalancing.Item";

export { Item };
export type { ItemProps };
