import type { PropsWithChildren } from "react";
import { useState } from "react";

import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";

import type { UnitTypeShort } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import { useBem } from "@eisox/tools";
import { calcRodStroke } from "@eisox/valves";
import { useQuery } from "@tanstack/react-query";

import type { MecaHistory } from "~/apiV2";
import { queries } from "~/apiV2";
import { FORMAT_DATE, HISTORY } from "~/constants";

import { Popup } from "../Popup";
import type { RootProps } from "../Popup/Popup";
import { Table } from "../Table";

import styles from "./MecaHistoryPopup.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
interface ContentProps {
  data: MecaHistory[];
}

const Content: React.FC<ContentProps> = ({ data }) => {
  const bem = useBem(styles);
  const tableStyle = bem("table");

  const { t } = useTranslation();

  const transformData = data.map(item => {
    const date = item.mecaProblemUpdatedAt ?? item.createdAt;
    return {
      ...item,
      date,
      rodStroke:
        item.openPos !== undefined && item.closePos !== undefined
          ? calcRodStroke(item.openPos, item.closePos)
          : undefined,
    };
  });

  return (
    <Table
      data={transformData}
      className={tableStyle()}
      initialState={{
        sorting: [{ id: "date", desc: true }],
      }}
      columns={[
        {
          accessorKey: "date",
          header: t("drawer.listDrawer.historyPopup.mecaHistoryPopup.table.header.date"),
          cell: ({
            row: {
              original: { date },
            },
          }) => (date ? dayjs(date).format(FORMAT_DATE.mecaHistory) : "-"),
        },
        {
          accessorKey: "openPos",
          header: t("drawer.listDrawer.historyPopup.mecaHistoryPopup.table.header.open"),
          cell: ({
            row: {
              original: { openPos },
            },
          }) => openPos ?? "-",
          enableSorting: false,
        },
        {
          accessorKey: "closePos",
          header: t("drawer.listDrawer.historyPopup.mecaHistoryPopup.table.header.close"),
          cell: ({
            row: {
              original: { closePos },
            },
          }) => closePos ?? "-",
          enableSorting: false,
        },
        {
          accessorKey: "rodStroke",
          header: t("drawer.listDrawer.historyPopup.mecaHistoryPopup.table.header.course"),
          cell: ({
            row: {
              original: { rodStroke },
            },
          }) => rodStroke ?? "-",
          enableSorting: false,
        },
        {
          accessorKey: "mecaProblemName",
          header: t("drawer.listDrawer.historyPopup.mecaHistoryPopup.table.header.problem"),
          enableSorting: false,
          cell: ({
            row: {
              original: { mecaProblemName },
            },
          }) => mecaProblemName ?? "-",
        },
        {
          accessorKey: "softwareVersion",
          header: t("drawer.listDrawer.historyPopup.mecaHistoryPopup.table.header.softwareVersion"),
          enableSorting: false,
          cell: ({
            row: {
              original: { softwareVersion },
            },
          }) => softwareVersion ?? "-",
        },
      ]}
    />
  );
};

/* -------------------------------------------------------------------------------------------------
 * MecaHistoryPopup
 * -----------------------------------------------------------------------------------------------*/
interface MecaHistoryPopupProps extends RootProps {
  mac: string;
}

const MecaHistoryPopup: React.FC<MecaHistoryPopupProps> = ({ mac, children, ...props }) => {
  const bem = useBem(styles);
  const mecaHistoryPopupStyle = bem("meca-history-popup");

  const { t } = useTranslation();

  const [range, setRange] = useState<Popup.FormValues["dateRange"]>({
    from: dayjs()
      .subtract(parseInt(HISTORY.MECA.initial[0]), HISTORY.MECA.initial[1] as UnitTypeShort)
      .toDate(),
    to: dayjs().toDate(),
  });

  const { data } = useQuery({
    ...queries.history.meca(range.from.toISOString(), range.to.toISOString(), mac),
    staleTime: dayjs(range.to).isBefore(dayjs()) ? Infinity : 0,
  });

  const handleSubmit = (data: Popup.FormValues) => setRange(data.dateRange);

  const orderedData = orderBy(data, "createdAt", "desc");

  return (
    <Popup.Root {...props}>
      <Popup.Content
        limit={HISTORY.MECA.limit}
        inital={HISTORY.MECA.initial}
        handleSubmit={handleSubmit}
        title={t("drawer.listDrawer.historyPopup.mecaHistoryPopup.title")}
        className={mecaHistoryPopupStyle()}
      >
        <Content data={orderedData} />
      </Popup.Content>
      <Popup.Trigger asChild>{children}</Popup.Trigger>
    </Popup.Root>
  );
};

export { MecaHistoryPopup };
export type { MecaHistoryPopupProps };
