import type { PropsWithChildren } from "react";

import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { ActionButtonV2, ButtonV2, Dialog, OTPInput } from "@eisox/design-system";
import { ArrowRightIcon, PhoneIcon } from "@eisox/icons";
import { useCountDown } from "@eisox/tools";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";

import { checkValidationCode, sendValidationPhoneNumber } from "~/apiV2";

import styles from "./Popup.module.scss";

const TIME_TO_RESEND = 1000 * 60;
const LENGHT_CODE = 6;

type Schema = z.infer<typeof schema>;
const schema = z.object({
  code: z.string().length(LENGHT_CODE),
});

interface PopupProps extends PropsWithChildren, Dialog.RootProps {
  onSuccess: () => void;
}

const Popup: React.FC<PopupProps> = ({ onSuccess, children, ...props }) => {
  const { t } = useTranslation();

  const { remainingTime, restart } = useCountDown(TIME_TO_RESEND);

  const { mutate: sendCode } = useMutation({
    mutationFn: sendValidationPhoneNumber,
    onSuccess: () => toast.success(t("phoneNumberValidation.success.sendCode")),
    onError: () => toast.error(t("phoneNumberValidation.error.sendCode")),
    onMutate: () => restart(),
  });

  const { mutate: checkCode } = useMutation({
    mutationFn: checkValidationCode,
    onSuccess: () => {
      toast.success(t("phoneNumberValidation.success.checkCode"));
      reset();
      onSuccess();
    },
    onError: () => toast.error(t("phoneNumberValidation.error.checkCode")),
  });

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
  } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { code: "" },
  });

  const handleResendCode = () => {
    if (remainingTime > 0) return;
    sendCode();
    reset();
  };

  const onSubmit = handleSubmit(data => {
    checkCode(data);
  });

  return (
    <Dialog.Root {...props}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Content title={t("phoneNumberValidation.title")} icon={<PhoneIcon />}>
        <div className={styles.container}>
          <p className={styles.container__enterCode}>{t("phoneNumberValidation.enterCode")}</p>
          <Controller
            control={control}
            name="code"
            render={({ field: { value, onChange } }) => (
              <OTPInput.Root maxLength={LENGHT_CODE} value={value} onChange={onChange} onComplete={onSubmit}>
                {({ slots }) => (
                  <OTPInput.Group>
                    {slots.map((slot, index) => (
                      <OTPInput.Slot {...slot} isError={!!errors.code && !slot.char} key={index} />
                    ))}
                  </OTPInput.Group>
                )}
              </OTPInput.Root>
            )}
          />
          <ButtonV2 disabled={remainingTime > 0} onClick={handleResendCode} className={styles.container__resend}>
            {remainingTime > 0
              ? t("phoneNumberValidation.timeResendCode", { s: remainingTime / 1000, count: remainingTime })
              : t("phoneNumberValidation.resend")}
          </ButtonV2>
        </div>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <ActionButtonV2 variant="cancel">{t("phoneNumberValidation.cancel")}</ActionButtonV2>
          </Dialog.Close>
          <ActionButtonV2 variant="primary" rounded disabled={!isDirty} onClick={onSubmit}>
            {t("phoneNumberValidation.verify")}
            <ArrowRightIcon />
          </ActionButtonV2>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export { Popup };
