import { useEffect, useState } from "react";

import { Navigate, useLocation, useMatch, useParams } from "react-router-dom";

import type { GetHousesPos } from "@eisox/backend_webapp_api";
import { Card } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { getHousesPositions } from "~/api/house";
import LoaderApp from "~/components/LoaderApp";
import { env } from "~/configuration";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { routeToRessources } from "~/routes/utils";

import styles from "./Resources.module.scss";

export const Resources = () => {
  const bem = useBem(styles);
  const resourcesStyle = bem("resources");

  const location = useLocation();
  const { houseId } = useParams();
  const match = useMatch(routeToRessources(houseId!) + "/*");

  const anchor = location.hash;

  return (
    <div className={resourcesStyle()}>
      <Card className={resourcesStyle("card")}>
        <iframe
          id="eisoxDocumentation"
          title="eisox documentation"
          src={env.RESSOURCES_URL + match?.params["*"] + anchor}
          className={resourcesStyle("iframe")}
        />
      </Card>
    </div>
  );
};

export const RedirectResources = () => {
  const location = useLocation();
  const match = useMatch("/resources/*");
  const [houseId, setHouseId] = useState<string>();

  const anchor = location.hash;

  useEffect(() => {
    void getHousesPositions().then((housesReponse: FetchResponse<GetHousesPos>) => {
      const houses = housesReponse.type === SUCCESS_FETCH ? housesReponse.result.message! : [];
      if (houses.length === 0) throw new Error();
      setHouseId(houses[0]._id);
    });
  }, []);

  return houseId ? <Navigate to={routeToRessources(houseId) + "/" + match?.params["*"] + anchor} /> : <LoaderApp />;
};
