import { createQueryKeys } from "@lukemorales/query-key-factory";

import { env } from "~/configuration";

import type { GeocodeAddress, GeocodeAddressBody, LatLng } from "../types";

const getGeoCodeAddress = async (body: GeocodeAddressBody): Promise<GeocodeAddress> => {
  const url = new URL(`${env.MAPQUEST_API_URL}/address`);

  const params = new URLSearchParams();
  params.append("key", env.MAPQUEST_API_KEY);
  url.search = params.toString();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const response = await fetch(url, { method: "POST", headers, body: JSON.stringify(body) });

  if (!response.ok) {
    throw new Error("Failed to fetch geocode address");
  }

  const data = (await response.json()) as GeocodeAddress;

  return data;
};

const getReversedGeoCodeAddress = async (latLng: LatLng): Promise<GeocodeAddress> => {
  const url = new URL(`${env.MAPQUEST_API_URL}/reverse`);

  const params = new URLSearchParams();
  params.append("key", env.MAPQUEST_API_KEY);
  url.search = params.toString();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const body = JSON.stringify({
    location: {
      latLng: {
        lat: latLng.lat,
        lng: latLng.lng,
      },
    },
    options: {
      thumbMaps: false,
    },
    includeNearestIntersection: true,
    includeRoadMetadata: true,
  });

  const response = await fetch(url, { method: "POST", headers, body: body });

  if (!response.ok) {
    throw new Error("Failed to fetch reversed geocode address");
  }

  const data = (await response.json()) as GeocodeAddress;

  return data;
};

export const mapQuest = createQueryKeys("mapQuest", {
  geoCode: (body: GeocodeAddressBody) => ({
    queryKey: [body],
    queryFn: () => getGeoCodeAddress(body),
  }),
  reverse: (latLng: LatLng) => ({
    queryKey: [latLng],
    queryFn: () => getReversedGeoCodeAddress(latLng),
  }),
});
