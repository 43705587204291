import { useCallback, useEffect, useState } from "react";

interface UseCountDownOptions {
  interval?: number;
  onTick?: (remainingTime: number) => void;
  onComplete?: () => void;
}

const useCountDown = (duration: number, { interval = 1000, onTick, onComplete }: UseCountDownOptions = {}) => {
  const [remainingTime, setRemainingTime] = useState(duration);
  const [restartCount, setRestartCount] = useState(0);

  const tick = useCallback(() => {
    setRemainingTime(prev => {
      const next = Math.max(prev - interval, 0);
      if (next > 0) onTick?.(next);
      else onComplete?.();
      return next;
    });
  }, [interval, onTick, onComplete]);

  const restart = useCallback(() => {
    setRemainingTime(duration);
    setRestartCount(prev => prev + 1);
  }, [duration]);

  useEffect(() => {
    setRemainingTime(duration);
    const timer = setInterval(tick, interval);
    return () => clearInterval(timer);
  }, [tick, interval, duration, restartCount]);

  return { remainingTime, restartCount, restart };
};

export { useCountDown };
