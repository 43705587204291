import type { CSSProperties, ElementType } from "react";
import { forwardRef } from "react";

import { cx } from "class-variance-authority";
import type { FieldError, Merge } from "react-hook-form";

import styles from "./FieldContainer.module.scss";

interface FieldContainerProps extends Omit<HTMLProps<"div">, "className" | "style"> {
  label?: string;
  error?: Merge<FieldError, (FieldError | undefined)[]> | FieldError;
  classNames?: {
    container?: string;
    label?: string;
    error?: string;
  };
  style?: {
    container?: CSSProperties;
    label?: CSSProperties;
    error?: CSSProperties;
  };
}

const FieldContainer: React.FC<FieldContainerProps> = forwardRef<HTMLDivElement, FieldContainerProps>(
  ({ label, error, classNames, style, children, ...props }, ref) => {
    const Tag = (label ? "label" : "div") as ElementType;
    return (
      <Tag {...props} ref={ref} className={cx(styles.container, classNames?.container)} style={style?.container}>
        {label !== undefined && (
          <span style={style?.label} className={cx(styles.label, classNames?.label)}>
            {label}
          </span>
        )}
        {children}
        {error?.message && (
          <p className={cx(styles.error, classNames?.error)} style={style?.error}>
            {error.message}
          </p>
        )}
      </Tag>
    );
  },
);

export { FieldContainer };
export type { FieldContainerProps };
