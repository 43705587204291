import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";

const getWeatherByHouseId = async (houseId: string) => {
  const { data, error } = await client.GET("/meteo/weather/{houseId}/", {
    params: {
      path: {
        houseId,
      },
    },
  });
  if (error) throw new Error(error.message);
  return data ?? [];
};

export const meteo = createQueryKeys("meteo", {
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getWeatherByHouseId(houseId),
  }),
});
