import { forwardRef } from "react";

import clsx from "clsx";

import { useBem } from "@eisox/tools";

import styles from "./RoomFC.module.scss";

export interface RoomFCProps extends HTMLProps<"div"> {
  name: string;
  disabled?: boolean;
  selected?: boolean;
}

export const RoomFC: React.FC<RoomFCProps> = forwardRef<HTMLDivElement, RoomFCProps>(
  ({ name, disabled = false, selected = false, className, ...props }, forwardedRef) => {
    const bem = useBem(styles);
    const roomStyle = bem("room");

    return (
      <div
        {...props}
        ref={forwardedRef}
        className={clsx(roomStyle({ disabled: disabled, selected: selected }), className)}
      >
        <p>{name}</p>
      </div>
    );
  },
);
