import type React from "react";
import { useState } from "react";

import { useParams } from "react-router-dom";

import { createContext } from "@eisox/tools";

import { HistoryProvider } from "./HistoryProvider";
import { RealTimeProvider } from "./RealTimeProvider";

enum Mode {
  Live = "live",
  History = "history",
}

interface BoilerRoomContextValue {
  mode: Mode;
  onModeChange: (mode: Mode) => void;
}

const [ModeProvider, useModeContext] = createContext<BoilerRoomContextValue>("BoilerRoom");

const ModeContextProvider: React.FC = () => {
  const { houseId, boilerroomId: boilerRoomId } = useParams() as { houseId: string; boilerroomId: string };

  const [mode, setMode] = useState<Mode>(Mode.Live);

  const handleChangeMode = (mode: Mode) => {
    setMode(mode);
  };

  return (
    <ModeProvider mode={mode} onModeChange={handleChangeMode}>
      {mode === Mode.Live && <RealTimeProvider />}
      {mode === Mode.History && <HistoryProvider houseId={houseId} boilerRoomId={boilerRoomId} />}
    </ModeProvider>
  );
};

export { ModeContextProvider, Mode, useModeContext };
