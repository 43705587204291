/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StatusCodes } from "http-status-codes";

import type { VALVE_HARDWARE_VERSION_ENUM } from "@eisox/valves";
import { ValvesMessageValvesStatus } from "@eisox/webapp-api-specification";
import { createQueryKeys } from "@lukemorales/query-key-factory";

import { transformHumidity, transformLight, transformTemperature } from "~/utils";

import { client } from "../api";
import type { HistovalvesStatsBody, Valve } from "../types";

const getHistoValveStats = async (body: HistovalvesStatsBody) => {
  const { data, error } = await client.POST("/histovalvesStats", { body });
  if (error) throw new Error(error.message);
  return data?.message ?? [];
};

const getValves = async (houseId: string): Promise<Valve[]> => {
  const { data, error, response } = await client.GET("/houses/{houseId}/valves", {
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) return [];
  else if (error || !data?.message?.valves) throw new Error(error?.message ?? "Error fetching valves");

  return data.message.valves.map(v => ({
    ...v,
    id: v.id!,
    uid: v.uid!,
    roomId: v.roomId!,
    gatewayId: v.gatewayId!,
    status: v.status ?? ValvesMessageValvesStatus.toAutoInstall,
    hardwareVersion: v.hardwareVersion as keyof typeof VALVE_HARDWARE_VERSION_ENUM | undefined,
    plan: v.plan as Valve["plan"],
    correction: v.correction ? transformTemperature(v.correction) : undefined,
    correctedTemp: v.correctedTemp ? transformTemperature(v.correctedTemp) : undefined,
    mecaProblems: v.mecaProblems ? (v.mecaProblems as Valve["mecaProblems"]) : [],
    sensors: {
      ...(v.sensors ?? {}),
      temperature: v.sensors?.temperature ? transformTemperature(v.sensors.temperature) : undefined,
      humidity: v.sensors?.humidity ? transformHumidity(v.sensors.humidity) : undefined,
      light: v.sensors?.light ? transformLight(v.sensors.light) : undefined,
    },
    stateData: {
      ...(v.stateData ?? {}),
      swOffset: v.stateData?.swOffset ? transformTemperature(v.stateData.swOffset) : undefined,
    },
    maxOpening: v.maxOpening ? (v.maxOpening as Required<Valve["maxOpening"]>) : undefined,
  }));
};

export const valve = createQueryKeys("valve", {
  history: (body: HistovalvesStatsBody) => ({
    queryKey: [body],
    queryFn: () => getHistoValveStats(body),
  }),
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getValves(houseId),
  }),
});
