import { useCallback, useState } from "react";

import { useIntl } from "react-intl";

import { Loader } from "@eisox/design-system";
import * as Dialog from "@radix-ui/react-dialog";

import styles from "./LoadingPopup.module.scss";

export let loading = (loading: boolean) => {};

export const LoadingPopup: React.FC = () => {
  const { formatMessage } = useIntl();

  const [open, setOpen] = useState(false);

  loading = useCallback((loading: boolean) => setOpen(loading), [setOpen]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.loadingPopup__overlay} />
        <Dialog.Content
          className={styles.loadingPopup}
          aria-describedby={undefined}
          onOpenAutoFocus={e => e.preventDefault()}
          onCloseAutoFocus={e => e.preventDefault()}
        >
          <Dialog.Title className={styles.loadingPopup__title}>
            {formatMessage({ id: `loadingPopup.title` })}
          </Dialog.Title>
          <Loader />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
