import type { ChangeEvent } from "react";
import { forwardRef, useLayoutEffect, useMemo, useState } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import { ActionButtonV2 as ActionButton, Dialog, TextField } from "@eisox/design-system";
import { createContext } from "@eisox/tools";

import { FieldContainer } from "~/UI";

import styles from "./DeleteDialog.module.scss";

/* -------------------------------------------------------------------------------------------------
 * DeleteDialog
 * -----------------------------------------------------------------------------------------------*/

type DeleteDialogProps = Dialog.RootProps;

/**
 * @deprecated use Alert dialog (@eisox/design-system) instead
 */
const Root = Dialog.Root;

Root.displayName = "DeleteDialog";

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/

type TriggerProps = Dialog.TriggerProps;

const Trigger = Dialog.Trigger;

Trigger.displayName = "DeleteDialog.Trigger";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/

const CONTENT_NAME = "DeleteDialog.Content";

interface ContentContextValue {
  disabled: boolean;
  onDisabledChange: (disabled: boolean) => void;
}

const [ContentContextProvider, useContentContext] = createContext<ContentContextValue>(CONTENT_NAME);

interface ContentProps extends Dialog.ContentProps {
  onDelete: () => void;
}

const Content = forwardRef<HTMLDivElement, ContentProps>((props, forwardedRef) => {
  const { t } = useTranslation();

  const { onDelete, children, ...contentProps } = props;
  const [disabled, setDisabled] = useState(false);

  return (
    <ContentContextProvider disabled={disabled} onDisabledChange={setDisabled}>
      <Dialog.Content {...contentProps} ref={forwardedRef} className={styles.deleteDialog}>
        <Dialog.Description>{children}</Dialog.Description>
        <div className={styles.deleteDialog__actions}>
          <Dialog.Close asChild>
            <ActionButton variant="cancel">{t("deleteDialog.cancel")}</ActionButton>
          </Dialog.Close>
          <ActionButton variant="danger" onClick={onDelete} disabled={disabled}>
            {t("deleteDialog.delete")}
          </ActionButton>
        </div>
      </Dialog.Content>
    </ContentContextProvider>
  );
});

Content.displayName = CONTENT_NAME;

/* -------------------------------------------------------------------------------------------------
 * Validator
 * -----------------------------------------------------------------------------------------------*/

const VALIDATOR_NAME = "DeleteDialog.Validator";

interface CommunValidatorProps {
  className?: string;
}

type ValidatorProps = CommunValidatorProps &
  (
    | { captcha: string; captchaLength?: never }
    | { captchaLength: number; captcha?: never }
    | { captcha?: never; captchaLength?: never }
  );

const Validator = forwardRef<HTMLDivElement, ValidatorProps>((props, forwardedRef) => {
  const { t } = useTranslation();

  const { onDisabledChange } = useContentContext(VALIDATOR_NAME);
  const { captchaLength = 4, captcha } = props;

  const [value, setValue] = useState("");

  const generatedCaptcha = useMemo(() => captcha ?? generateCaptcha(captchaLength), [captcha, captchaLength]);

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
    onDisabledChange(value !== generatedCaptcha);
  };

  useLayoutEffect(() => {
    onDisabledChange(true);
  }, [onDisabledChange]);

  return (
    <div ref={forwardedRef} className={cx(styles.validator)}>
      <span className={styles.validator__captcha}>{generatedCaptcha}</span>
      <FieldContainer label={t("deleteDialog.code")}>
        <TextField.Root
          className={styles.validator__input}
          placeholder={t("deleteDialog.placeholder")}
          value={value}
          onChange={handleChangeValue}
        />
      </FieldContainer>
    </div>
  );
});

Validator.displayName = VALIDATOR_NAME;

const generateCaptcha = (length: number) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    captcha += characters.charAt(randomIndex);
  }

  return captcha;
};

export { Content, Root, Trigger, Validator };
export type { ContentProps, DeleteDialogProps, TriggerProps, ValidatorProps };
