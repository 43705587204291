import type { RouteObject } from "react-router-dom";

import { usedToken } from "~/routes/routes";

import { UsedToken } from ".";

const route: RouteObject = {
  path: usedToken,
  element: <UsedToken />,
};

export { route };
