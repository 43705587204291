import type { PostHistoBoxIntervalEnum } from "@eisox/backend_webapp_api";

/* -------------------------------------------------------------------------------------------------
 * ENUM
 * -----------------------------------------------------------------------------------------------*/

enum DataType {
  TEMPERATURE = "temperature",
  HUMIDITY = "humidity",
  NAME = "name",
  AIR_QUALITY = "airQuality",
  LIGHT = "light",
}

enum DisplaySection {
  VALVES = "valves",
  GATEWAYS = "gateways",
  ROOMS = "rooms",
  BOILERS = "boilers",
  HISTORY = "history",
}

enum BoilerromLayout {
  TREE = "tree",
  PARALLEL = "parallel",
}

/* -------------------------------------------------------------------------------------------------
 * INTERFACE
 * -----------------------------------------------------------------------------------------------*/

interface Plan {
  data: DataType;
  display: DisplaySection[];
}

interface Boilerroom {
  layout: BoilerromLayout;
}

interface Maintenance {
  advanced: boolean;
}

interface ConnectionGatewayHistory {
  mode: PostHistoBoxIntervalEnum;
}

interface UserPreferenceState {
  plan: Plan;
  boilerroom: Boilerroom;
  maintenance: Maintenance;
  connectionGatewayHistory: ConnectionGatewayHistory;
  sidebarCollapsed: boolean;
}

interface UserPreferencAction {
  addPlanData: (data: DataType) => DataType;
  addPlanDisplay: (display: DisplaySection) => DisplaySection[];
  setBoilerroomLayout: (layout: BoilerromLayout) => void;
  setMaintenanceAdvanced: (advanced: boolean) => void;
  setConnectionGatewayHistoryMode: (mode: PostHistoBoxIntervalEnum) => void;
  toggleSidebar: (collapsed: boolean) => void;
}

export { DataType, DisplaySection, BoilerromLayout };
export type { UserPreferenceState, UserPreferencAction };
