import type { RouteObject } from "react-router-dom";

import type { QueryClient } from "@tanstack/react-query";

import { route as enrollAccountRoute } from "./enrollAccount/route";
import { loader } from "./loader";
import { route as resetPasswordRoute } from "./resetPassword/route";

const tokenLoaderId = "tokenLoaderId";

const route = (queryClient: QueryClient): RouteObject => ({
  id: tokenLoaderId,
  loader: loader(queryClient),
  children: [{ ...enrollAccountRoute }, { ...resetPasswordRoute }],
});

export { route, tokenLoaderId };
