import { useTranslation } from "react-i18next";

import {
  ActionButtonV2 as ActionButton,
  ButtonV2 as Button,
  PlanV2 as DSPlan,
  Modal,
  Tooltip,
} from "@eisox/design-system";
import { ArrowRightIcon, GatewayIcon, RoomIcon, ValveIcon } from "@eisox/icons";
import { useQuery } from "@tanstack/react-query";

import type { GatewaysWithProblem, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { queries } from "~/apiV2";

import { Gateway } from "../Gateway";
import { PlanItem } from "../PlanItem";
import { Room } from "../Room";

import styles from "./Location.module.scss";

interface LocationDialogProps {
  houseId: string;
  type: "gateways" | "valves" | "rooms";
  errors: string[];
  warnings: string[];
  item: GatewaysWithProblem | ValvesWithProblem | RoomsWithProblem;
  rooms: RoomsWithProblem[];
  gateways: GatewaysWithProblem[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onClickOnPlan: (planId: string) => void;
  onClickOnRoom: (planId: string, roomId: string) => void;
}

const LocationDialog: React.FC<LocationDialogProps> = ({
  houseId,
  type,
  errors,
  warnings,
  item,
  rooms,
  gateways,
  open,
  onOpenChange,
  onClickOnPlan,
  onClickOnRoom,
}) => {
  const { t } = useTranslation();

  const { data: plan } = useQuery(queries.plan.plan(houseId, item.plan?.planId!));

  const icon = {
    gateways: <GatewayIcon />,
    valves: <ValveIcon />,
    rooms: <RoomIcon />,
  }[type];

  const roomsOnPlan = rooms.filter(r => {
    if (type !== "rooms") {
      return r.plan?.planId === item.plan?.planId;
    } else {
      return r.plan?.planId === item.plan?.planId && (item as RoomsWithProblem).id !== r.id;
    }
  });

  const gatewaysOnPlan = gateways.filter(g => {
    if (type !== "gateways") {
      return g.plan?.planId === item.plan?.planId;
    } else {
      return g.plan?.planId === item.plan?.planId && (item as GatewaysWithProblem).id !== g.id;
    }
  });

  const selectedGateway = (gateway: GatewaysWithProblem): boolean => {
    if (type !== "valves") return false;
    return (item as ValvesWithProblem).gatewayId === gateway.id;
  };

  const selectedRoom = (room: RoomsWithProblem): boolean => {
    if (type !== "valves") return false;
    return (item as ValvesWithProblem).roomId === room.id;
  };

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Content className={styles.locationDialog}>
        <Modal.Header
          icon={icon}
          title={t(`advancedMaintenance.locationDialog.title.${type}`)}
          subtitle={t(`advancedMaintenance.locationDialog.subtitle.${type}`)}
        >
          <Modal.Close asChild>
            <ActionButton variant="cancel">{t("advancedMaintenance.locationDialog.close")}</ActionButton>
          </Modal.Close>
        </Modal.Header>
        <Button className={styles.locationDialog__button} onClick={() => onClickOnPlan(item.plan?.planId!)}>
          {item.plan?.planName ?? "--"} <ArrowRightIcon />
        </Button>
        <DSPlan.Root>
          <DSPlan.Wrapper className={styles.locationDialog__plan} plan={plan}>
            <Tooltip.Provider delayDuration={0}>
              {roomsOnPlan.map(r => (
                <DSPlan.Item
                  key={r.id}
                  position={{ ...(r.plan as Required<NonNullable<RoomsWithProblem["plan"]>>) }}
                  origin="bottom center"
                >
                  <Room
                    name={r.name ?? "--"}
                    selected={selectedRoom(r)}
                    onClick={() => selectedRoom(r) && onClickOnRoom(r.plan?.planId!, r.id!)}
                    style={{ cursor: selectedRoom(r) ? "pointer" : "default" }}
                  />
                </DSPlan.Item>
              ))}
              {gatewaysOnPlan.map(g => (
                <DSPlan.Item
                  key={g.id}
                  position={{ ...(g.plan as Required<NonNullable<GatewaysWithProblem["plan"]>>) }}
                >
                  <Gateway selected={selectedGateway(g)} style={{ cursor: "default" }}>
                    {g.gatewayName}
                  </Gateway>
                </DSPlan.Item>
              ))}
              {type === "gateways" && (
                <DSPlan.Item position={{ ...(item.plan as Required<NonNullable<GatewaysWithProblem["plan"]>>) }}>
                  <PlanItem
                    type={"gateways"}
                    errors={errors}
                    warnings={warnings}
                    name={(item as GatewaysWithProblem).gatewayName}
                    updatedAt={(item as GatewaysWithProblem).lastGatewayActivity}
                  />
                </DSPlan.Item>
              )}
              {type === "valves" && (
                <DSPlan.Item position={{ ...(item.plan as Required<NonNullable<ValvesWithProblem["plan"]>>) }}>
                  <PlanItem
                    type="valves"
                    errors={errors}
                    warnings={warnings}
                    mac={(item as ValvesWithProblem).mac}
                    updatedAt={(item as ValvesWithProblem).updatedAt}
                  />
                </DSPlan.Item>
              )}
              {type === "rooms" && (
                <DSPlan.Item position={{ ...(item.plan as Required<NonNullable<RoomsWithProblem["plan"]>>) }}>
                  <PlanItem
                    type="rooms"
                    errors={errors}
                    warnings={warnings}
                    name={(item as RoomsWithProblem).name}
                    onClick={() => onClickOnRoom(item.plan?.planId!, item.id!)}
                  />
                </DSPlan.Item>
              )}
            </Tooltip.Provider>
          </DSPlan.Wrapper>
        </DSPlan.Root>
      </Modal.Content>
    </Modal.Root>
  );
};

export { LocationDialog };
export type { LocationDialogProps };
