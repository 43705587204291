import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import { ActionButtonV2 as ActionButton, Dialog } from "@eisox/design-system";
import { ArrowRightIcon, GatewayIcon } from "@eisox/icons";

import { routeToDashboard } from "~/routes/utils";

interface ConnectionErrorDialogProps {
  houseId: string;
}

const ConnectionErrorDialog: React.FC<ConnectionErrorDialogProps> = ({ houseId }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleOpenChange = (open: boolean) => {
    if (!open) navigate(routeToDashboard(houseId));
  };

  return (
    <Dialog.Root open onOpenChange={handleOpenChange}>
      <Dialog.Content title={t("boilerRoom.popup.communicationError.title.communicationError")} icon={<GatewayIcon />}>
        <Dialog.Description>{t("boilerRoom.popup.communicationError.message.SERVER_ERROR")}</Dialog.Description>
        <Dialog.Footer>
          <Dialog.Close asChild>
            <ActionButton asChild variant="cancel">
              <Link to={routeToDashboard(houseId)}>
                {t("boilerRoom.popup.communicationError.cancel.backToDashboard")}
              </Link>
            </ActionButton>
          </Dialog.Close>
          <ActionButton rounded>
            {t("boilerRoom.popup.communicationError.confirm.retry")}
            <ArrowRightIcon />
          </ActionButton>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog.Root>
  );
};

ConnectionErrorDialog.displayName = "ConnectionErrorDialog";

export { ConnectionErrorDialog };
