import * as React from "react";

import { IconProps } from "./types";

export const EyeClosedIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M2.09091 3.35045L2.84727 2.6L12.7273 12.48L11.9768 13.2364L10.1568 11.4164C9.47727 11.6409 8.75636 11.7591 8 11.7591C5.04545 11.7591 2.52227 9.92136 1.5 7.32727C1.90773 6.28727 2.55773 5.37136 3.385 4.64454L2.09091 3.35045ZM8 5.55454C8.47013 5.55454 8.92105 5.74131 9.25349 6.07376C9.58594 6.40622 9.77273 6.85711 9.77273 7.32727C9.77302 7.52852 9.73905 7.72834 9.67227 7.91818L7.40909 5.655C7.59895 5.58821 7.79874 5.55424 8 5.55454ZM8 2.89545C10.9545 2.89545 13.4777 4.73318 14.5 7.32727C14.0178 8.55234 13.1982 9.61568 12.1364 10.3941L11.2973 9.54909C12.1144 8.98383 12.7735 8.21899 13.2118 7.32727C12.7341 6.35224 11.9924 5.53077 11.0711 4.95625C10.1498 4.38173 9.0858 4.0772 8 4.07727C7.35591 4.07727 6.72364 4.18363 6.13273 4.37273L5.22273 3.46863C6.07364 3.10227 7.01318 2.89545 8 2.89545ZM2.78818 7.32727C3.2659 8.30231 4.00756 9.12375 4.92889 9.69829C5.85021 10.2728 6.91423 10.5773 8 10.5773C8.40773 10.5773 8.80955 10.5359 9.18182 10.4532L7.83455 9.1C7.42339 9.05592 7.03967 8.87244 6.74725 8.58C6.45484 8.28761 6.27134 7.9039 6.22727 7.49273L4.21818 5.47773C3.63318 5.98 3.14273 6.60636 2.78818 7.32727Z"
        fill={color}
      />

      <rect width="13" height="9.45455" transform="translate(1.5 3.25)" />
    </svg>
  ),
);

export default EyeClosedIcon;
