import * as React from "react";

import { IconProps } from "./types";

export const SubstationIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M6.14286 12.5857V11.6571H7.07143V9.8V8.87143H6.14286H4.28571V11.6571H5.21429V12.5857H1.5V11.6571H2.42857V7.94286C2.42857 7.69659 2.5264 7.4604 2.70055 7.28626C2.87468 7.11211 3.11087 7.01428 3.35714 7.01428H6.14286H7.07143V6.08571V4.22857H6.14286V3.3H9.85714V4.22857H8.92857V7.01428H12.6429C12.8891 7.01428 13.1253 7.11211 13.2994 7.28626C13.4736 7.4604 13.5714 7.69659 13.5714 7.94286V11.6571H14.5V12.5857H10.7857V11.6571H11.7143V8.87143H9.39286H8.92857V11.6571H9.85714V12.5857H6.14286ZM7.53571 4.22857H8.46429V7.47857H8.92857H12.6429C12.875 7.47857 13.1071 7.71071 13.1071 7.94286V11.6571H12.1786V8.40714H10.7857H9.39286H8.46429V8.87143V11.6571H7.53571V8.87143V8.40714H3.82143V11.6571H2.89286V7.94286C2.89286 7.71071 3.125 7.47857 3.35714 7.47857H7.53571V7.01428V4.22857Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default SubstationIcon;
