import * as React from "react";

import { IconProps } from "./types";

export const EyeDataIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <g clipPath="url(#clip0_30_133)">
        <path
          d="M8 6.20455C8.47013 6.20455 8.92105 6.39132 9.25349 6.72377C9.58594 7.05622 9.77273 7.50712 9.77273 7.97727C9.77273 8.44743 9.58594 8.89833 9.25349 9.23077C8.92105 9.56321 8.47013 9.75 8 9.75C7.52987 9.75 7.07894 9.56321 6.74649 9.23077C6.41404 8.89833 6.22727 8.44743 6.22727 7.97727C6.22727 7.50712 6.41404 7.05622 6.74649 6.72377C7.07894 6.39132 7.52987 6.20455 8 6.20455ZM8 3.54546C10.9545 3.54546 13.4777 5.38318 14.5 7.97727C13.4777 10.5714 10.9545 12.4091 8 12.4091C5.04545 12.4091 2.52227 10.5714 1.5 7.97727C2.52227 5.38318 5.04545 3.54546 8 3.54546ZM2.78818 7.97727C3.26578 8.95246 4.00741 9.77405 4.92874 10.3488C5.85007 10.9234 6.91415 11.228 8 11.228C9.08585 11.228 10.1499 10.9234 11.0712 10.3488C11.9926 9.77405 12.7342 8.95246 13.2118 7.97727C12.7342 7.00209 11.9926 6.18047 11.0712 5.60581C10.1499 5.03114 9.08585 4.72649 8 4.72649C6.91415 4.72649 5.85007 5.03114 4.92874 5.60581C4.00741 6.18047 3.26578 7.00209 2.78818 7.97727Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_30_133">
          <rect width="13" height="9.45455" transform="translate(1.5 3.25)" />
        </clipPath>
      </defs>
    </svg>
  ),
);

export default EyeDataIcon;
