import { LegendDialog } from "../LegendDialog";
import { ToggleGroup } from "../ToggleGroup";

import styles from "./Footer.module.scss";

interface FooterProps {
  view?: "list" | "plan";
  onViewChange?: (view: "list" | "plan") => void;
}

const Footer: React.FC<FooterProps> = props => {
  const { view, onViewChange } = props;

  return (
    <div className={styles.footer}>
      <ToggleGroup.Root
        className={styles.footer__toggleGroup}
        defaultValue="plan"
        value={view}
        onValueChange={onViewChange}
      >
        <ToggleGroup.Item value="plan">Plan</ToggleGroup.Item>
        <ToggleGroup.Item value="list">Liste</ToggleGroup.Item>
      </ToggleGroup.Root>
      <LegendDialog />
    </div>
  );
};

Footer.displayName = "Maintenance.Footer";

export { Footer };
