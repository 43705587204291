import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import type { PatchHouse } from "@eisox/backend_webapp_api";
import { ActionButton, Button, RoundIcon, Slider } from "@eisox/design-system";
import { ArrowRightIcon, QuestionMarkIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import type { houseLoader } from "~/UI/screens";
import { DEFAULT_TEMPERATURES, TEMPERATURE_LIMITS } from "~/constants";
import { useAction } from "~/hooks";
import { usePermissionsContext } from "~/providers";
import { idLoaderHouse, routeToFrostFreeSettings, routeToFunctionsSettings, routeToHouse } from "~/routes/utils";
import { API } from "~/types/API";

import styles from "./Temperatures.module.scss";

export const Temperatures: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const temperaturesStyle = bem("temperatures");

  const { permissions } = usePermissionsContext("Temperatures");

  const navigate = useNavigate();
  const { houseId } = useParams() as { houseId: string };
  const { house, rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;

  const { Form, submit, state } = useAction({
    onSuccess: () => {
      reset({}, { keepValues: true });
      toast(formatMessage({ id: "settings.content.menu.houses.temperatures.success" }), { type: "success" });
    },
  });

  const {
    control,
    formState: { isDirty, dirtyFields },
    handleSubmit,
    watch,
    reset,
  } = useForm<Required<Pick<PatchHouse, "absenceTemperature" | "preComfortTemperature">>>({
    defaultValues: {
      absenceTemperature: house.absenceTemperature,
      preComfortTemperature: house.preComfortTemperature,
    },
  });

  const abscenceTemp = watch("absenceTemperature");
  const preComfTemps = watch("preComfortTemperature");

  const onSubmit = (data: PatchHouse) => {
    const body: PatchHouse = {};
    if (dirtyFields.absenceTemperature) body.absenceTemperature = data.absenceTemperature;
    if (dirtyFields.preComfortTemperature) body.preComfortTemperature = data.preComfortTemperature;
    submit(body, API.HTTP_METHOD.PATCH, routeToHouse(houseId));
  };

  return (
    <Form className={temperaturesStyle()} onSubmit={handleSubmit(onSubmit)}>
      <h3 className={temperaturesStyle("title")}>
        {formatMessage({ id: "settings.content.menu.houses.temperatures.title" })}
      </h3>
      <Controller
        control={control}
        name="absenceTemperature"
        render={({ field: { value, onChange } }) => (
          <Slider
            className={temperaturesStyle("slider")}
            label={formatMessage({ id: "settings.content.menu.houses.temperatures.absenceTemperature.label" })}
            min={TEMPERATURE_LIMITS.MIN}
            max={TEMPERATURE_LIMITS.MAX}
            step={TEMPERATURE_LIMITS.STEP}
            value={value}
            defaultValue={DEFAULT_TEMPERATURES.ABSENCE}
            valueLabelDisplay="auto"
            valueLabelFormat={formatMessage(
              {
                id: "error.temperature.absenceUpperFrostFreeLowerPreconf",
              },
              { a: value, p: preComfTemps, g: house.frostFreeTemperature },
            )}
            onValueChange={value => {
              if (typeof value === "number") {
                const minTemp = house.frostFreeTemperature;
                const maxTemp = preComfTemps;
                if (value < minTemp) value = minTemp + TEMPERATURE_LIMITS.STEP;
                else if (value > maxTemp) value = maxTemp - TEMPERATURE_LIMITS.STEP;
                onChange(value);
              }
            }}
            disabled={!permissions.house?.absenceTemperature?.update}
          />
        )}
      />
      <Controller
        control={control}
        name="preComfortTemperature"
        render={({ field: { value, onChange } }) => (
          <Slider
            className={temperaturesStyle("slider")}
            label={
              <span className={temperaturesStyle("label")}>
                {formatMessage({ id: "settings.content.menu.houses.temperatures.preComfortTemperature.label" })}
                <Tooltip
                  content={formatMessage({ id: "settings.content.menu.houses.temperatures.tooltip" })}
                  keepHoverOpen={false}
                >
                  <RoundIcon backgroundColor="gray" iconColor="darkGray" size={20}>
                    <QuestionMarkIcon style={{ width: 10, height: 10 }} />
                  </RoundIcon>
                </Tooltip>
              </span>
            }
            min={TEMPERATURE_LIMITS.MIN}
            max={TEMPERATURE_LIMITS.MAX}
            step={TEMPERATURE_LIMITS.STEP}
            value={value}
            defaultValue={DEFAULT_TEMPERATURES.PRECOMFORT}
            valueLabelDisplay="auto"
            valueLabelFormat={formatMessage(
              {
                id: "error.temperature.preconfUpperAbsence",
              },
              { a: abscenceTemp, p: value },
            )}
            onValueChange={value => {
              if (typeof value === "number") {
                const minTemp = abscenceTemp;
                if (value < minTemp) value = minTemp + TEMPERATURE_LIMITS.STEP;
                onChange(value);
              }
            }}
            disabled={!permissions.house?.preComfortTemperature?.update}
          />
        )}
      />
      <Button
        className={temperaturesStyle("link")}
        text={formatMessage({ id: "settings.content.menu.houses.temperatures.frostFreeTemperature" })}
        icon={<ArrowRightIcon />}
        onClick={() => navigate(routeToFrostFreeSettings(houseId))}
      />
      {rooms.length > 0 && (
        <Button
          className={temperaturesStyle("link")}
          text={formatMessage({ id: "settings.content.menu.houses.temperatures.comfortAndNightTemperature" })}
          icon={<ArrowRightIcon />}
          onClick={() => navigate(routeToFunctionsSettings(houseId))}
        />
      )}
      <ActionButton
        className={temperaturesStyle("submit-button")}
        type="submit"
        text={formatMessage({ id: "settings.content.menu.houses.temperatures.save" })}
        icon={<ArrowRightIcon />}
        disabled={!isDirty || ["submitting", "loading"].includes(state)}
      />
    </Form>
  );
};
