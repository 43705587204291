import { useTranslation } from "react-i18next";

import { primary } from "@eisox/colors";
import { ButtonV2 as Button, DropdownMenu } from "@eisox/design-system";
import { CheckedIcon, ChevronDownIcon, MenuIcon, PastIcon, PumpHorizontalIcon } from "@eisox/icons";

import type { Module } from "~/apiV2";
import { GATEWAY_MIN_SOFTWARE_VERSIONS } from "~/constants/appConstantV2";
import { DetailedHistoryDialog } from "~/features/BMSHistory";
import { DefectHistoryPopup } from "~/features/HistoryPopUp";
import { BoilerromLayout, useUserPreferenceStore } from "~/stores/userPreference";
import { isVersionIsUpper } from "~/utils";

import { PumpsDialog } from "../PumpsDialog";

import styles from "./DisplayDropdownMenu.module.scss";

interface DisplayDropdownMenuProps {
  houseId: string;
  boilerRoomId: string;
  gatewaySoftwareVersion?: string;
  modules: Module[];
  disabled?: boolean;
}

const DisplayDropdownMenu: React.FC<DisplayDropdownMenuProps> = ({
  houseId,
  boilerRoomId,
  gatewaySoftwareVersion,
  modules,
  disabled,
}) => {
  const { t } = useTranslation();

  const layout = useUserPreferenceStore.use.boilerroom().layout;
  const setBoilerroomLayout = useUserPreferenceStore.use.setBoilerroomLayout();

  const handleSelectItem = (e: Event) => e.preventDefault();

  const handleLayoutChange = (tree: boolean) =>
    setBoilerroomLayout(tree ? BoilerromLayout.TREE : BoilerromLayout.PARALLEL);

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild disabled={disabled}>
        <Button>
          {t("boilerRoom.header.display.display")} <ChevronDownIcon />
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end" sideOffset={10}>
        <DropdownMenu.CheckboxItem
          className={styles.dropdownMenuItem}
          checked={layout === BoilerromLayout.TREE}
          onCheckedChange={handleLayoutChange}
        >
          <MenuIcon className={styles.dropdownMenuItem__icon} />
          {t("boilerRoom.header.display.compactView")}
          <DropdownMenu.ItemIndicator className={styles.dropdownMenuItem__indicator}>
            <CheckedIcon color={primary.blue} />
          </DropdownMenu.ItemIndicator>
        </DropdownMenu.CheckboxItem>
        <DetailedHistoryDialog houseId={houseId} modules={modules}>
          <DropdownMenu.Item onSelect={handleSelectItem}>
            <PastIcon />
            {t("boilerRoom.header.display.history.detailed")}
          </DropdownMenu.Item>
        </DetailedHistoryDialog>
        {gatewaySoftwareVersion &&
          isVersionIsUpper(gatewaySoftwareVersion, GATEWAY_MIN_SOFTWARE_VERSIONS.BOILERROOM_DEFECT_HISTORY) && (
            <DefectHistoryPopup houseId={houseId} boilerroomId={boilerRoomId}>
              <DropdownMenu.Item onSelect={handleSelectItem}>
                <PastIcon />
                {t("boilerRoom.header.display.history.defect")}
              </DropdownMenu.Item>
            </DefectHistoryPopup>
          )}
        <PumpsDialog>
          <DropdownMenu.Item onSelect={handleSelectItem}>
            <PumpHorizontalIcon />
            {t("boilerRoom.header.actions.pumps.gestion")}
          </DropdownMenu.Item>
        </PumpsDialog>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

DisplayDropdownMenu.displayName = "DisplayDropdownMenu";

export { DisplayDropdownMenu };
