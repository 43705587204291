import { Dayjs } from "@eisox/dayjs";

import { RestError } from "~/constants/appConstantV2";

export namespace API {
  export enum HTTP_STATUS {
    OK = 200,
    ACCEPTED = 202,
    MULTI_STATUS = 207,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    INTERNAL_SERVER_ERROR = 500,
  }

  export enum HTTP_METHOD {
    GET = "GET",
    PATCH = "PATCH",
    PUT = "PUT",
    POST = "POST",
    DELETE = "DELETE",
  }

  export type REQUEST_STATUS = "loading" | "idle" | "submitting";

  export interface Response<T> {
    date: Dayjs;
    restError?: RestError;
    url: string;
    method: HTTP_METHOD;
    body?: string;
    message: {
      status: HTTP_STATUS;
      message?: T;
      id?: string;
    }[];
  }
}
