import { useRef, useState } from "react";

import { cx } from "class-variance-authority";

import { CrossIcon, MinusIcon, PlusIcon } from "@eisox/icons";
import { useControllableState } from "@eisox/tools";
import * as RadixSlider from "@radix-ui/react-slider";

import { Tooltip } from "..";

import styles from "./Slider.module.scss";

interface SliderProps
  extends Omit<RadixSlider.SliderProps, "value" | "defaultValue" | "onValueChange" | "onValueCommit"> {
  label?: React.ReactNode;
  displayButtons?: boolean;
  displayValue?: boolean;
  undefinedValue?: boolean;
  value?: number;
  defaultValue?: number;
  onValueChange?: (value?: number) => void;
  onValueCommit?: (value?: number) => void;
  valueLabelDisplay?: "on" | "off" | "auto";
  valueLabelFormat?: string | ((value?: number) => React.ReactNode);
  marks?: number[];
  classNames?: {
    label?: string;
    root?: string;
    track?: string;
    range?: string;
    thumb?: string;
    tooltip?: string;
  };
}

const Slider: React.FC<SliderProps> = ({
  label,
  displayButtons = true,
  displayValue = true,
  undefinedValue = false,
  value: valueProp,
  step,
  defaultValue,
  onValueChange,
  onValueCommit,
  valueLabelDisplay = "auto",
  valueLabelFormat,
  marks,
  className,
  classNames,
  disabled,
  ...props
}) => {
  const sliderRef = useRef<HTMLSpanElement>(null);

  const [value, setValue] = useControllableState<number | undefined>({
    prop: valueProp,
    defaultProp: defaultValue,
    onChange: onValueChange,
  });
  const [isInteracting, setIsInteracting] = useState(false);

  const onPointerDown = () => setIsInteracting(true);
  const onPointerUp = () => setIsInteracting(false);

  const handleClickMinusButton = () => setValue(prev => (prev ? prev - (step ?? 1) : undefined));
  const handleClickPlusButton = () => setValue(prev => (prev ? prev + (step ?? 1) : undefined));
  const handleDeleteValue = () => setValue(undefined);

  const isTooltipOpen = valueLabelDisplay === "on" || (valueLabelDisplay === "auto" && isInteracting);

  const handleChangeValue = (value: number[]) => {
    if (marks && marks.length > 0) {
      const closestMark = marks.reduce((prev, curr) =>
        Math.abs(curr - value[0]!) < Math.abs(prev - value[0]!) ? curr : prev,
      );
      setValue(closestMark);
    } else {
      setValue(value[0]);
    }
  };

  function getThumbInBoundsOffset(width: number, left: number, direction: number) {
    const halfWidth = width / 2;
    const halfPercent = 50;
    const offset = linearScale([0, halfPercent], [0, halfWidth]);
    return (halfWidth - offset(left) * direction) * direction;
  }

  function linearScale(input: readonly [number, number], output: readonly [number, number]) {
    return (value: number) => {
      if (input[0] === input[1] || output[0] === output[1]) return output[0];
      const ratio = (output[1] - output[0]) / (input[1] - input[0]);
      return output[0] + ratio * (value - input[0]);
    };
  }

  function convertValueToPercentage(value: number, min: number, max: number) {
    const maxSteps = max - min;
    const percentPerStep = 100 / maxSteps;
    const percentage = percentPerStep * (value - min);
    return percentage;
  }

  return (
    <div className={cx(styles.slider, className)}>
      {(label ?? displayButtons) && (
        <div className={styles.label}>
          {label && <label className={cx(styles.label__label, classNames?.label)}>{label}</label>}
          <div className={styles.label__buttons}>
            {displayButtons && (
              <MinusIcon
                className={cx(styles.label__button, disabled && styles.label__button_disabled)}
                onClick={handleClickMinusButton}
              />
            )}
            {displayValue && (
              <span className={styles.label__buttons}>
                {typeof value === "number" ? Number(value).toFixed(1) : "--"}
              </span>
            )}
            {displayButtons && (
              <PlusIcon
                className={cx(styles.label__button, disabled && styles.label__button_disabled)}
                onClick={handleClickPlusButton}
              />
            )}
            {!displayButtons && undefinedValue && (
              <CrossIcon className={cx(styles.label__button, styles.label__button_close)} onClick={handleDeleteValue} />
            )}
          </div>
        </div>
      )}
      <RadixSlider.Root
        {...props}
        ref={sliderRef}
        className={cx(styles.slider__root, classNames?.root)}
        value={value !== undefined ? [value] : undefined}
        onValueChange={handleChangeValue}
        onValueCommit={newValue => onValueCommit?.(newValue[0])}
        onPointerDown={onPointerDown}
        onPointerUp={onPointerUp}
      >
        {marks && marks.length && (
          <div className={styles.marksContainer}>
            {marks?.map((mark, index) => {
              const thumbSize = 22;
              const min = props.min ?? 0;
              const max = props.max ?? 100;
              const markPosition = convertValueToPercentage(mark, min, max);
              const thumbOffset = getThumbInBoundsOffset(thumbSize, markPosition, 1);

              return (
                <div
                  key={index}
                  className={styles.mark}
                  style={{ left: `calc(${markPosition}% + ${thumbOffset}px)` }}
                />
              );
            })}
          </div>
        )}
        <RadixSlider.Track className={cx(styles.slider__track, classNames?.track)}>
          <RadixSlider.Range className={cx(styles.slider__range, classNames?.range)} />
        </RadixSlider.Track>
        <Tooltip.Provider delayDuration={0} disableHoverableContent>
          <Tooltip.Root open={isTooltipOpen}>
            <Tooltip.Trigger asChild>
              <RadixSlider.Thumb
                className={cx(styles.slider__thumb)}
                onMouseEnter={onPointerDown}
                onMouseLeave={onPointerUp}
              />
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content side="top" className={cx(styles.slider__tootlip, classNames?.tooltip)}>
                {valueLabelFormat
                  ? typeof valueLabelFormat === "string"
                    ? valueLabelFormat
                    : valueLabelFormat(value)
                  : (value ?? "—")}
                <Tooltip.Arrow />
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      </RadixSlider.Root>
    </div>
  );
};

export default Slider;
export { Slider };
