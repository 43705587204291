import type { LastGatewayActivity200ResponseMessageInner, PostHistoBoxIntervalEnum } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import type { DateCountEntry } from "./ConnectionGatewayHistory";

export const fillMissingDates = (
  data: LastGatewayActivity200ResponseMessageInner[],
  interval: PostHistoBoxIntervalEnum,
  startDate: string,
  endDate: string,
): DateCountEntry[] => {
  const dateMap = new Map(
    data
      .sort((a, b) => dayjs(a.date).diff(dayjs(b.date)))
      .map(entry => [dayjs(entry.date).toISOString(), entry.value ?? 0]),
  );

  const result: DateCountEntry[] = [];
  let currentDate = dayjs(startDate);
  const finalDate = dayjs(endDate);

  while (currentDate.isBefore(finalDate) || currentDate.isSame(finalDate)) {
    const dateKey = currentDate.toISOString();
    result.push({ date: dateKey, value: dateMap.get(dateKey) ?? 0 });
    currentDate = currentDate.add(1, interval);
  }

  return result;
};
