import type { Box, Point } from "./types";

const calculateSelectionBox = ({ startPoint, endPoint }: { startPoint: Point; endPoint: Point }): Box => ({
  left: Math.min(startPoint.x, endPoint.x),
  top: Math.min(startPoint.y, endPoint.y),
  width: Math.abs(startPoint.x - endPoint.x),
  height: Math.abs(startPoint.y - endPoint.y),
});

const calculateBoxArea = (box: Box) => box.width * box.height;

export { calculateSelectionBox, calculateBoxArea };
