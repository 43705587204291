import { cva } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { RoundIcon, Typography } from "@eisox/design-system";
import { ChevronDownIcon, TickIcon, WarningIcon } from "@eisox/icons";
import * as Collapsible from "@radix-ui/react-collapsible";

import { routeToBoilerroom } from "~/routes/utils";

import type { BoilerRoomError } from "../../helpers";

import styles from "./ErrorCollapsible.module.scss";

const errorCollapsibleVariants = cva(styles.errorCollapsible, {
  variants: {
    error: {
      true: styles.errorCollapsible_error,
    },
  },
});
interface ErrorCollapsibleProps {
  houseId: string;
  errors: BoilerRoomError[];
  className?: string;
}

const ErrorCollapsible: React.FC<ErrorCollapsibleProps> = ({ houseId, errors, className }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const error = errors.length > 0;

  return (
    <Collapsible.Root className={errorCollapsibleVariants({ error, className })} disabled={!error}>
      <Collapsible.Trigger className={styles.errorCollapsible__trigger}>
        <RoundIcon iconColor={error ? "red" : "green"}>
          {error ? <WarningIcon className={styles.icon} /> : <TickIcon className={styles.icon} />}
        </RoundIcon>
        {t("boilerRoom.errors.total", { count: errors.length })}
        {error && <ChevronDownIcon className={styles.chevron} />}
      </Collapsible.Trigger>
      <Collapsible.Content asChild>
        {/* Radix Collapsible animation problem : force to add asChild and className to children div to fix animation problem */}
        <div className={styles.errorCollapsible__content}>
          {errors.map((error, index) => (
            <Typography
              key={index}
              className={styles.errorCollapsible__error}
              onClick={() => navigate(routeToBoilerroom(houseId, error.boilerRoomId))}
            >
              {`${error.boilerRoomName}: ${error.message}`}
            </Typography>
          ))}
        </div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export { ErrorCollapsible };
export type { ErrorCollapsibleProps };
