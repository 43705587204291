import { useIntl } from "react-intl";

import { useBem } from "@eisox/tools";
import {
  getAirQualityThreshold,
  getAirQualityV5Threshold,
  getHumidityThreshold,
  getLightThreshold,
  getTemperatureThreshold,
} from "@eisox/valves";

import type { RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { usePermissionsContext } from "~/providers";
import { DataType } from "~/stores";
import { isRoomHasValvesV5 } from "~/utils";

import styles from "./Room.module.scss";

type Data = Record<
  string,
  {
    value?: number | string;
    unity?: string;
    color?: string;
  }
>;

interface RoomProps {
  displayed?: DataType;
  room: RoomsWithProblem;
  valves?: ValvesWithProblem[];
  onClick?: VoidFunction;
  temperature?: number;
  humidity?: number;
  airQuality?: number;
  light?: number;
}

export const Room: React.FC<RoomProps> = ({
  displayed = DataType.NAME,
  room,
  valves,
  onClick,
  temperature,
  humidity,
  airQuality,
  light,
}) => {
  const { formatMessage } = useIntl();
  const { permissions } = usePermissionsContext("Room");

  const bem = useBem(styles);
  const roomStyle = bem("room");

  const temperatureThreshold = temperature !== undefined ? getTemperatureThreshold(temperature) : undefined;
  const humidityThreshold = humidity !== undefined ? getHumidityThreshold(humidity) : undefined;
  const airQualityThreshold =
    airQuality !== undefined
      ? isRoomHasValvesV5(room.id, valves ?? [])
        ? getAirQualityV5Threshold(airQuality)
        : getAirQualityThreshold(airQuality)
      : undefined;
  const lightThreshold = light !== undefined ? getLightThreshold(light) : undefined;

  const data: Data = {
    temperature: {
      value: temperature?.toFixed(1),
      unity: "°C",
      color: temperatureThreshold && `temperature-${temperatureThreshold}`,
    },
    name: {
      value: room.name ?? "--",
      color: "dark-blue",
    },
    humidity: {
      value: humidity?.toFixed(1),
      unity: "%",
      color: humidityThreshold && `humidity-${humidityThreshold}`,
    },
    airQuality: {
      value:
        airQualityThreshold &&
        formatMessage({ id: `airQuality.${airQualityThreshold.replace(/-./g, x => x[1].toUpperCase())}` }),
      color: airQualityThreshold && `air-quality-${airQualityThreshold}`,
    },
    light: {
      value: light?.toFixed(1),
      unity: "Lux",
      color: lightThreshold && `light-${lightThreshold}`,
    },
  };

  return (
    <div className={roomStyle({ color: data[displayed].color ?? "dark-gray" })} onClick={onClick}>
      {((permissions.room?.error && room.problemStatus === "error") ||
        (permissions.room?.warning && room.problemStatus === "warning")) && (
        <div className={roomStyle("error", { variant: room.problemStatus })} />
      )}
      <p>
        {data[displayed].value ?? "-"} {data[displayed].unity}
      </p>
    </div>
  );
};
