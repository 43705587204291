import { useState } from "react";

import type { RoomsWithProblem } from "~/UI/screens/House";

import type { SelectorsType } from "../layouts";
import type { SelectFiltersType } from "../utils";
import { filterRoomsWithTextSearch } from "../utils";

interface UseFilterRoomsProps {
  rooms: RoomsWithProblem[];
}

interface UseFilterRoomsType {
  filters: SelectFiltersType;
  roomOptions: RoomsWithProblem[];
  handleSearch: (value: string) => void;
  handleChangeSelector: (values: SelectorsType) => void;
}

const useFilterRooms = ({ rooms }: UseFilterRoomsProps): UseFilterRoomsType => {
  const [filters, setFilters] = useState<SelectFiltersType>({
    search: "",
    types: [],
    plans: [],
    groups: [],
  });

  const roomOptions = filterRoomsWithTextSearch(rooms, filters);

  const handleSearch = (value: string) => {
    setFilters(prevState => ({ ...prevState, search: value }));
  };

  const handleChangeSelector = (values: SelectorsType) => {
    setFilters(prevState => ({ ...prevState, ...values }));
  };

  return {
    filters,
    roomOptions,
    handleSearch,
    handleChangeSelector,
  };
};

export { useFilterRooms };
export type { UseFilterRoomsProps, UseFilterRoomsType };
