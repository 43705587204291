import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import { z } from "@eisox/zod";

import type { UserRole } from "~/apiV2";
import { createSelectors, validateKeyWithSchema } from "~/utils/Zustand";

import type { UserAction, UserState } from "./type";

const initalState: UserState = {
  roles: {},
  token: "",
  id: "",
};

const validatorSchema = z.object({
  token: z.string(),
  id: z.string(),
});

const useUserStoreBase = create<UserState & UserAction>()(
  persist(
    set => ({
      ...initalState,
      setToken: (token: string) => set({ token }),
      setId: (id: string) => set({ id }),
      setRoles: (roles: UserRole) => set({ roles }),
      logout: queryClient => {
        queryClient.clear();
        set(initalState);
      },
    }),
    {
      storage: createJSONStorage(() => localStorage, {
        reviver: (key, value) => validateKeyWithSchema(validatorSchema, initalState, key, value),
      }),
      name: "user",
      version: 1,
    },
  ),
);

const useUserStore = createSelectors(useUserStoreBase);

export { useUserStore };
