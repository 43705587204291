import { PlanningPlanningTypeEnum } from "@eisox/backend_webapp_api";

import { PlanningTypeKey } from "~/UI/components";

import { RoomDataType } from "~/UI/screens/Room/utils/getRoomData";
import { useBem } from "@eisox/tools";


import styles from "./ContentTooltip.module.scss";

import { useIntl } from "react-intl";
import { RoomsWithProblem } from "~/UI/screens/House";

interface ContentTooltipProps {
  currentTimeSlot: {
    temperature: number;
    type: PlanningPlanningTypeEnum;
  };
  room: RoomsWithProblem;
  roomData: RoomDataType;
  isShowBoxNote: boolean;
  isShowSwOffset: boolean;
  isShowCorrection: boolean;
}

export const ContentTooltip: React.FC<ContentTooltipProps> = ({
  currentTimeSlot,
  room,
  roomData,
  isShowBoxNote,
  isShowSwOffset,
  isShowCorrection,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const tooltipContentStyle = bem("tooltip-content");
  const temperatureContainerStyle = bem("temperature-container");
  const roomDataContainerStyle = bem("room-data-container");

  const { swOffset, correction } = room;

  return (
    <div className={tooltipContentStyle()}>
      <div className={tooltipContentStyle("container")}>
        <div className={temperatureContainerStyle()}>
          <div className={temperatureContainerStyle("temperature")}>
            <p>{currentTimeSlot.temperature}°C</p>
            <PlanningTypeKey planningType={currentTimeSlot.type} />
          </div>
          {isShowSwOffset && (
            <div className={temperatureContainerStyle("temperature")}>
              <p>{swOffset !== undefined ? `${swOffset > 0 ? "+" : ""} ${swOffset}` : "-"}°C</p>
              <p>{formatMessage({ id: "room.ConsignTemp.averageButton" })}</p>
            </div>
          )}
          {isShowCorrection && (
            <div className={temperatureContainerStyle("temperature")}>
              <p>{correction !== undefined ? `${correction > 0 ? "+" : ""} ${correction}` : "-"}°C</p>
              <p>{formatMessage({ id: "room.ConsignTemp.correction" })}</p>
            </div>
          )}
        </div>
        {Object.values(roomData).some(({ selected, disabled }) => selected && !disabled) && (
          <div className={roomDataContainerStyle()}>
            {Object.values(roomData).map(
              ({ icon, translation, disabled, selected }, index) =>
                !disabled &&
                selected && (
                  <div key={index} className={roomDataContainerStyle("room-data")}>
                    {icon}
                    <p>{translation}</p>
                  </div>
                ),
            )}
          </div>
        )}
      </div>
      {isShowBoxNote && <p>{formatMessage({ id: "room.ConsignTemp.boxInfo" })}</p>}
    </div>
  );
};
