import { startTransition, useState } from "react";

import clsx from "clsx";
import { useIntl } from "react-intl";

import { Combobox } from "@eisox/design-system";
import { useBem } from "@eisox/tools";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "@uidotdev/usehooks";

import { queries } from "~/apiV2";
import type { Location } from "~/apiV2";

import styles from "./SearchLocation.module.scss";

interface SearchLocationProps {
  onChange: (location: Location) => void;
  value?: Location;
  className?: string;
  disabled?: boolean;
}

export const SearchLocation: React.FC<SearchLocationProps> = ({ onChange, className, value, disabled = false }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const searchLocationStyle = bem("search-location");

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const debounceSearchValue = useDebounce(searchValue, 500);

  const { data } = useQuery({
    ...queries.mapQuest.geoCode({ location: debounceSearchValue }),
    placeholderData: prev => prev,
    staleTime: 500,
  });

  const locations =
    data?.results
      .flatMap(r => r.locations)
      .filter(r => [r.street, r.adminArea5, r.adminArea4, r.postalCode].some(Boolean)) ?? [];

  const formatAddress = (adress: Location) => {
    return [adress.street, adress.adminArea5, adress.adminArea4, adress.postalCode].filter(Boolean).join(", ");
  };

  return (
    <div className={clsx(searchLocationStyle(), className)}>
      <Combobox.Root
        setValue={value => startTransition(() => setSearchValue(value))}
        setSelectedValue={selectedValue => {
          onChange(locations.find(location => formatAddress(location) === selectedValue)!);
          setOpen(false);
        }}
      >
        <Combobox.Input
          className={searchLocationStyle("input")}
          placeholder={formatMessage({ id: "settings.content.menu.houses.general.address" })}
          disabled={disabled}
          onChange={e => setOpen(e.target.value.length > 0)}
        />
        <Combobox.Popover className={searchLocationStyle("popover")} sameWidth unmountOnHide open={open}>
          {locations.length ? (
            locations.map((location, i) => <Combobox.Item key={i} value={formatAddress(location)} />)
          ) : (
            <div className={searchLocationStyle("item")}>{formatMessage({ id: "buildings.noResult" })}</div>
          )}
        </Combobox.Popover>
      </Combobox.Root>
      {value && <p className={searchLocationStyle("address")}>{formatAddress(value)}</p>}
    </div>
  );
};
