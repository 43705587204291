import { PatchRooms } from "@eisox/backend_webapp_api";

import { GroupDto } from "../Groups";

export const getBody = (groups: GroupDto[], initialState: GroupDto[]): PatchRooms[] => {
  const patchRoomsMap = new Map<string, string[]>();
  groups.forEach(g => {
    g.rooms.forEach(r => {
      patchRoomsMap.has(r) && !!patchRoomsMap.get(r)
        ? patchRoomsMap.set(r, [...patchRoomsMap.get(r)!, g.name])
        : patchRoomsMap.set(r, [g.name]);
    });
  });

  const patchRoomsArray: PatchRooms[] = [];
  const groupNamesMap = new Map<string, PatchRooms>();

  patchRoomsMap.forEach((groupNames, roomId) => {
    const groupNameKey = groupNames.sort().join("-");
    let patchRooms = groupNamesMap.get(groupNameKey);

    if (!patchRooms) {
      patchRooms = { roomIds: [], groupNames };
      groupNamesMap.set(groupNameKey, patchRooms);
      patchRoomsArray.push(patchRooms);
    }

    patchRooms.roomIds?.push(roomId);
  });

  // Rooms no longer in any group
  const noGroupRooms = [
    ...new Set(initialState.flatMap(g => g.rooms).filter(r => !groups.flatMap(ng => ng.rooms).includes(r))),
  ];
  noGroupRooms.length > 0 && patchRoomsArray.push({ groupNames: [], roomIds: noGroupRooms });

  return patchRoomsArray;
};
