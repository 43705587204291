import type { PostPlanningInnerDayEnum } from "@eisox/backend_webapp_api";

export interface BoilerroomDataRes {
  date: string;
  date_prev?: string;
  boilerRooms?: BoilerRoom[];
  errors?: Error[];
}

export enum PermutationCommand {
  MANUAL = 1,
  AUTO = 2,
}

export interface BoilerRoom {
  id: string;
  name?: string;
  objectNameParent?: string;
  noHeatingTemperature?: number;
  heatingTemperature?: number;
  summerModeState?: number;
  temporisationSummerMode?: number;
  frostFreeModeState?: number;
  reducedFFTemperature?: number;
  setpointStartFrostFree?: number;
  temporisationFrostFreeMode?: number;
  lossTempDelta?: number;
  permutationDay?: number;
  permutationHour?: number;
  permutationCmd?: PermutationCommand;
  startingTemperature?: number;
  returnTemperature?: number;
  externalTemperature?: number;
  setpointTemperature?: number;
  lowSetpointTemperature?: number;
  highSetpointTemperature?: number;
  gazDefect?: boolean;
  waterDefect?: boolean;
  waterKeeperDefect?: boolean;
  flowVolume?: number;
  woodLevel?: number;
  woodLowLevel?: boolean;
  gazLevel?: number;
  gazConso?: number;
  calorieConso?: number;
  elecConso?: number;
  woodConso?: number;
  recyclePumps?: Pumps;
  sludgeRemover?: SludgeRemover;
  heatingNetworks?: HeatingNetwork[];
  boilers?: Boiler[];
  waterTreatmentDefect?: boolean;
  gazDetectionDefect?: boolean;
  saltTankDefect?: boolean;
  externalTempDefect?: boolean;
}

export interface SludgeRemover {
  defect?: boolean;
  level?: number;
  pumps?: Pumps;
}

export enum PumpCommand {
  OFF = 1,
  PUMP_1 = 2,
  PUMP_2 = 3,
  AUTO = 4,
}

export enum PumpCommandAutomate {
  OFF = 0,
  ON = 1,
}

export enum PumpCommandMan {
  MANUAL = 1,
  AUTO = 2,
}

export enum PumpState {
  OFF = 0,
  ON = 1,
}

export interface Pumps {
  id: string;
  command?: PumpCommand;
  commandAutomateP1?: PumpCommandAutomate;
  commandAutomateP2?: PumpCommandAutomate;
  commandManP1?: PumpCommandMan;
  commandManP2?: PumpCommandMan;
  defectP1?: boolean;
  defectP2?: boolean;
  stateP1?: PumpState;
  stateP2?: PumpState;
  operationHoursP1?: number;
  operationHoursP2?: number;
  nbStateChangeP1?: number;
  nbStateChangeP2?: number;
  missmatchDefectP1?: boolean;
  missmatchDefectP2?: boolean;
}

export enum HeatingNetworkType {
  ECS = "sanitaryHotWater",
  RADIATOR = "radiator",
  AIR_HEATER = "airHeater",
  CENTRAL_AIR_HANDLING_UNIT = "centralAirHandlingUnit",
  CONSTANT = "constant",
  CONVECTION_HEATER = "convectionHeater",
  RADIANT_HEAT = "radiantHeat",
}

export interface HeatingNetwork {
  id: string;
  name?: string;
  objectName?: string;
  objectNameParent?: string;
  type?: HeatingNetworkType;
  startingTemperature?: number;
  returnTemperature?: number;
  ambientTemperature?: number;
  setpointTemperature?: number;
  setpointTemperatureOnOccupation?: number;
  setpointTemperatureOnInoccupation?: number;
  reducedTemperatureAbs?: number;
  flowVolume?: number;
  setpointTemperatureEcs?: number;
  setpointThermalShock?: number;
  calorieConso?: number;
  heatingSlope?: number;
  anticipationMax?: number;
  tankSanitaryHotWaterTemperature?: number;
  idValves?: string[];
  ambientSensorIds?: string[];
  threeWaysValve?: Valve;
  pumps?: Pumps;
  recyclePumpsECS?: Pumps;
  heatingCurves?: HeatingCurve[];
  plannings?: Planning[];
  startingTemperaturesECS?: number[];
  returnTemperaturesECS?: number[];
  thermostatDefect?: boolean;
  maxOffsetAdaptation?: number;
  isAutoHeatingCurves?: boolean;
  targetHslope?: number;
}

export enum ValveCommand {
  AUTO = 1,
  MANUAL = 2,
}

export interface Valve {
  id: string;
  defect?: boolean;
  command?: ValveCommand;
  opening?: number;
  forcing?: number;
}

export interface HeatingCurve {
  id?: string;
  objectName?: string;
  con: number;
  ext: number;
  offset?: number;
}

export enum Command {
  OFF = 1,
  NORMAL = 2,
  FAST = 3,
  AUTO = 4,
}

export enum CommandAutomate {
  OFF = 0,
  ON = 1,
}

export enum CommandMan {
  MANUAL = 1,
  AUTO = 2,
}

export enum State {
  OFF = 0,
  ON = 1,
}

export enum Speed {
  UNCONTROLLABLE = 1,
  NORMAL = 2,
  FAST = 3,
}

export enum Type {
  GAZ_FIOUL = "1",
  HEAT_PUMP_COOLING_UNIT = "2",
  RCU = "3",
  WOOD_BOILER = "5",
}

export interface Boiler {
  id: string;
  type?: Type;
  name?: string;
  command?: Command; // 1: éteinte, 2: allure normale, 3: grande allure, 4: auto => géré par l'Eclypse
  commandAutomate?: CommandAutomate; // 0: éteinte, 1: allumée
  commandMan?: CommandMan; // Commutateur, 1: Manuel (marche ou arrêt), 2: Mode auto: Eclypse gère l'état
  state?: State; //0: éteinte, 1: allumée
  stateNb?: number;
  speed?: Speed; // 1: non contrôlable, 2: allure normale, 3: grande vitesse
  defectNb?: number;
  defect?: boolean;
  defectBurner?: boolean;
  ashPanDefect?: boolean;
  setpointTemperature?: number;
  setpointTemperatureAct?: number;
  startingTemperature?: number;
  returnTemperature?: number;
  smokeTemperature?: number;
  siloTemperature?: number;
  priority?: number;
  priorityForcing?: number;
  operationHours?: number;
  screwOperationHours?: number;
  nbStateChange?: number;
  twoWaysValve?: Valve;
  pumps?: Pumps;
  missmatchDefect?: boolean;
  communicationDefect?: boolean;
}

export interface Error {
  moduleId?: string;
  type?: string;
  msg?: string;
}

export type TypeRawData = "analogValue" | "multiStateValue" | "schedule";

export interface RawData {
  type?: TypeRawData;
  instance?: number;
  property?: "objectName" | "presentValue";
  value?: string | number;
}

export interface RawDataResV2 {
  rawData?: RawDataV2[];
  errors?: Error[];
}

export interface RawDataV2 {
  moduleId: string;
  data: RawData[];
}

export interface ResetDataRes {
  moduleIdsOk?: string[];
  errors?: Error[];
}

export enum PlanningType {
  Occupation = "occupation",
  Inoccupation = "inoccupation",
  ThermalShock = "thermalShock",
}

export interface Planning {
  day?: PostPlanningInnerDayEnum;
  hourStart?: string;
  hourEnd?: string;
  planningType?: PlanningType;
  userDefined?: boolean;
  type?: PlanningType;
}

export interface HeatingSlope {
  macValve: string;
  hslopeMinC: number;
  heatingNetworkOpeningAverage: number;
  dateGateway: string;
  realExtTemp: number;
  heatingNetworkStartingTemperature: number;
}

export interface CurrentHeatingCurveHslope {
  externalTemperature: number;
  heatingNetworkId: string;
  heatingSlopes?: HeatingSlope[];
}
