import * as React from "react";

import { IconProps } from "./types";

export const ArrowRightIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M1.5 8H14.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M11.25 5L14.5 8"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M11.25 11L14.5 8"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default ArrowRightIcon;
