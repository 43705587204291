import { PreinstallValvesDto } from "../Valves";

import { orderBy, uniqueId } from "lodash";
import { ValvesWithProblem } from "~/UI/screens/House";

export const prefixId = "valve";

/**
 * Converts an array of valves to a PreinstallValvesDto object.
 *
 * @param valves - The array of valves to convert.
 * @returns The PreinstallValvesDto object.
 */
export const valvesMessageInnerArrayToPreinstallValvesDto = (valves: ValvesWithProblem[]): PreinstallValvesDto => {
  const valveToPreinstall = valves.map(v => ({
    id: uniqueId(prefixId),
    uid: v.uid!,
    roomId: v.roomId!,
    gatewayId: v.gatewayId!,
    heatingNetworkId: v.heatingNetworkId!,
    plan: {
      planId: v.plan?.planId!,
      x: v.plan?.x!,
      y: v.plan?.y!,
    },
  }));
  const orderValveToPreinstall = orderBy(valveToPreinstall, ["uid"]);

  return { valves: orderValveToPreinstall };
};
