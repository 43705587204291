import { forwardRef } from "react";

import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { primary } from "@eisox/colors";

import type { CircleProps } from "../Circle";
import { Circle } from "../Circle";

import styles from "./RoundIcon.module.scss";

type AccType = Record<keyof typeof primary, string>;

const roundIconVariants = cva(styles.roundIcon, {
  variants: {
    backgroundColor: (Object.keys(primary) as (keyof typeof primary)[]).reduce((acc, key) => {
      acc[key] = styles[`roundIcon_backgroundColor_${key}`];
      return acc;
    }, {} as AccType),
    iconColor: (Object.keys(primary) as (keyof typeof primary)[]).reduce((acc, key) => {
      acc[key] = styles[`roundIcon_iconColor_${key}`];
      return acc;
    }, {} as AccType),
  },
  defaultVariants: {
    backgroundColor: "white",
    iconColor: "darkBlue",
  },
});

interface RoundIconProps extends CircleProps, VariantProps<typeof roundIconVariants> {
  size?: number;
}

const RoundIcon = forwardRef<HTMLDivElement, RoundIconProps>(
  ({ size, backgroundColor, iconColor, className, ...props }, forwardedRef) => (
    <Circle
      {...props}
      ref={forwardedRef}
      size={size}
      className={roundIconVariants({ backgroundColor, iconColor, className })}
    />
  ),
);

export default RoundIcon;
export { RoundIcon };
export type { RoundIconProps };
