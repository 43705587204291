import clsx from "clsx";
import { useIntl } from "react-intl";

import type { PlanningPlanningTypeEnum } from "@eisox/backend_webapp_api";
import { useBem } from "@eisox/tools";

import type { PlanningType as HeatingNetworkPlanningType } from "~/socketio/types";

import styles from "./PlanningTypeKey.module.scss";

export type PlanningType = PlanningPlanningTypeEnum | "userDefined" | "frostFree" | HeatingNetworkPlanningType;

interface PlanningTypeKeyProps extends React.HTMLAttributes<HTMLParagraphElement> {
  planningType: PlanningType;
  temperature?: {
    show: boolean;
    value?: number | string;
  };
}

export const PlanningTypeKey: React.FC<PlanningTypeKeyProps> = ({
  planningType,
  temperature = {
    show: false,
  },
  className,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const planningTypeKeyStyle = bem("planning-type-key");

  return (
    <p {...props} className={clsx(planningTypeKeyStyle({ type: planningType }), className)}>
      {formatMessage({ id: `plannings.${planningType}` })}{" "}
      {temperature.show && <span>{`(${temperature.value ?? "-"}°C)`}</span>}
    </p>
  );
};
