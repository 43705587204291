import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import { Card, Circle } from "@eisox/design-system";

import { Tooltip } from "~/UI/components";
import { UserRole } from "~/utils";

import styles from "./Role.module.scss";

type RoleProps<TVariant = boolean> = TVariant extends true
  ? {
      role: UserRole;
      circle?: TVariant;
      displayTooltip?: boolean;
      size?: number;
      className?: string;
    }
  : {
      role: UserRole;
      circle?: TVariant;
      displayTooltip?: never;
      size?: never;
      className?: string;
    };

export const Role: React.FC<RoleProps> = ({ role, size = 22, circle = true, displayTooltip = false, className }) => {
  const { t } = useTranslation();

  return (
    <>
      {circle ? (
        <Tooltip className={styles[`role_role_${role}`]} content={displayTooltip && t(`users.role.${role}`)}>
          <Circle
            className={cx(
              styles.role,
              styles[`role_role_${role}`],
              role === UserRole.MANAGER_BOILERROOM && styles.role_option,
              className,
            )}
            size={size}
          />
        </Tooltip>
      ) : (
        <Card className={cx(styles[`role_role_${role}`], styles.role_rectangle, className)}>
          {t(`users.role.${role}`)}
        </Card>
      )}
    </>
  );
};
