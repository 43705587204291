import { deletePlanningById } from "~/api/planning";

import { SUCCESS_FETCH } from "~/constants/fetchConstants";

import { ActionFunctionArgs, json } from "react-router-dom";

export const action = async ({ params, request }: ActionFunctionArgs) => {
  if (request.method === "DELETE") {
    const reponse: any = await deletePlanningById(params.planningId, params.houseId);
    if (reponse.type !== SUCCESS_FETCH) {
      return json({ error: reponse.result });
    }
  }
  return null;
};
