import { useIntl } from "react-intl";

import { Loader } from "@eisox/design-system";
import * as Dialog from "@radix-ui/react-dialog";

import styles from "./LoadingPopup.module.scss";

interface LoadingPopupProps {
  open: boolean;
  type: "fetching" | "mutating";
}

export const LoadingPopup: React.FC<LoadingPopupProps> = ({ open, type }) => {
  const { formatMessage } = useIntl();

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.loadingPopup__overlay} />
        <Dialog.Content
          className={styles.loadingPopup}
          aria-describedby={undefined}
          onOpenAutoFocus={e => e.preventDefault()}
          onCloseAutoFocus={e => e.preventDefault()}
        >
          <Dialog.Title className={styles.loadingPopup__title}>
            {formatMessage({ id: `loadingPopup.${type}` })}
          </Dialog.Title>
          <Loader />
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
