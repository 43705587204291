/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StatusCodes } from "http-status-codes";

import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";
import type { HeatingNetwork } from "../types";

const getHeatingNetworks = async (houseId: string): Promise<HeatingNetwork[]> => {
  const { data, error, response } = await client.GET("/houses/{houseId}/heatingNetworks", {
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) return [];
  else if (error || !data?.message?.heatingNetworks)
    throw new Error(error?.message ?? "Error fetching heating networks");

  return data.message.heatingNetworks.map(hn => ({
    ...hn,
    id: hn.id!,
    name: hn.name!,
    moduleId: hn.moduleId!,
    type: hn.type!,
  }));
};

export const heatingNetwork = createQueryKeys("heatingNetwork", {
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getHeatingNetworks(houseId),
  }),
});
