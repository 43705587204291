import { useState } from "react";

import { useIntl } from "react-intl";

import { ActionButton, FileInput, Modal } from "@eisox/design-system";
import { PlusIcon, SpannerIcon } from "@eisox/icons";

interface ImportDataInterventionPlanToolProps {
  open: boolean;
  onClose: VoidFunction;
  onChange: (file: File) => void;
}

export const ImportDataInterventionPlanTool: React.FC<ImportDataInterventionPlanToolProps> = ({
  open,
  onClose,
  onChange,
}) => {
  const { formatMessage } = useIntl();

  const [file, setFile] = useState<File | null>(null);

  return (
    <Modal.Root open={open} onOpenChange={open => !open && onClose()}>
      <Modal.Content>
        <Modal.Header
          icon={<SpannerIcon />}
          title={formatMessage({ id: "interventionPlanTool.title" })}
          subtitle={formatMessage({ id: "interventionPlanTool.subTitle.import" })}
        >
          <ActionButton
            onClick={onClose}
            variant="cancel"
            text={formatMessage({ id: "interventionPlanTool.action.cancel" })}
          />
          <ActionButton
            disabled={!file}
            onClick={() => file && onChange(file)}
            text={formatMessage({ id: "interventionPlanTool.action.import" })}
            icon={<PlusIcon style={{ width: 10, height: 10 }} />}
            rounded
          />
        </Modal.Header>
        <FileInput
          placeholder={formatMessage({ id: "interventionPlanTool.import.placeholder" })}
          multiple={false}
          onChange={event => event.target.files?.[0] && setFile(event.target.files[0])}
          accept="application/JSON"
        />
      </Modal.Content>
    </Modal.Root>
  );
};
