import clsx from "clsx";

import { CrossIcon, MinusIcon, PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { Slider as MUISlider, SliderProps as MUISliderProps } from "@mui/material";

import { colorVariable } from "~/constants/ThemeColors";

import styles from "./Slider.module.scss";

interface CommonSliderProps extends MUISliderProps {
  label?: React.ReactNode;
  displayButtons?: boolean;
  displayValue?: boolean;
  undefinedValue?: boolean;
  className?: string;
  muiClassName?: string;
}

interface WithValue extends CommonSliderProps {
  value: number | number[];
  defaultValue?: number | number[];
}

interface WithDefaultValue extends CommonSliderProps {
  value?: number | number[];
  defaultValue: number | number[];
}

type SliderProps = WithValue | WithDefaultValue;

export const Slider: React.FC<SliderProps> = ({
  label,
  value,
  defaultValue,
  min,
  max,
  step,
  scale,
  onChange,
  onChangeCommitted,
  disabled = false,
  displayButtons = true,
  displayValue = true,
  undefinedValue = false,
  className,
  ...props
}) => {
  const bem = useBem(styles);
  const sliderStyle = bem("slider");
  const counterStyle = bem("counter");

  const handleClickMinusButton: React.MouseEventHandler<HTMLOrSVGElement> = e => {
    e.preventDefault();
    if (!disabled && typeof value === "number" && onChange) {
      const newValue = value - (step ?? 1);
      if (min === undefined || newValue >= min) onChange(e as unknown as Event, newValue, 0);
    }
  };

  const handleClickPlusButton: React.MouseEventHandler<HTMLOrSVGElement> = e => {
    e.preventDefault();
    if (!disabled && typeof value === "number" && onChange) {
      const newValue = value + (step ?? 1);
      if (max === undefined || newValue <= max) onChange(e as unknown as Event, newValue, 0);
    }
  };

  const handleDeleteValue = () => {
    //@ts-ignore
    onChange && onChange(undefined);
  };

  return (
    <div className={clsx(sliderStyle(), className)}>
      {(label || displayButtons) && (
        <div className={counterStyle()}>
          {label && <p className={counterStyle("title")}>{label}</p>}

          <div className={counterStyle("buttons")}>
            {displayButtons && (
              <MinusIcon className={counterStyle("button", { disabled: disabled })} onClick={handleClickMinusButton} />
            )}
            {displayValue && (
              <span className={counterStyle("buttons")}>
                {typeof value === "number" ? Number(value).toFixed(1) : "--"}
              </span>
            )}
            {displayButtons && (
              <PlusIcon className={counterStyle("button", { disabled: disabled })} onClick={handleClickPlusButton} />
            )}
            {!displayButtons && undefinedValue && (
              <CrossIcon className={counterStyle("button", { close: true })} onClick={handleDeleteValue} />
            )}
          </div>
        </div>
      )}
      <MUISlider
        {...props}
        size="small"
        value={value ?? defaultValue}
        min={min}
        step={step}
        max={max}
        scale={scale}
        onChange={onChange}
        onChangeCommitted={onChangeCommitted}
        disabled={disabled}
        sx={{
          "&.Mui-disabled": {
            "& .MuiSlider-track": {
              backgroundColor: `${colorVariable.quaternaryColor} !important`,
            },
            "& .MuiSlider-thumb::before": {
              content: '""',
              position: "absolute",
              width: "6px",
              height: "2px",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
              backgroundColor: `${colorVariable.disabledColor}`,
              zIndex: 100,
            },
            "& .MuiSlider-thumb::after": {
              border: `2px solid ${colorVariable.disabledColor} !important`,
            },
          },
        }}
      />
    </div>
  );
};
