import { z } from "@eisox/zod";

import { MODE_ENV } from "~/constants/appConstantV2";

const validStringNumber = z.string().refine(v => !isNaN(Number(v)), { message: "Invalid number format" });

export const envSchemaBase = z.object({
  VERSION: z.string(),
  PREPROD_WEBAPP_URL: z.string().url().optional(),
  PROD_WEBAPP_URL: z.string().url().optional(),
  SERVER_URL: z.string().url(),
  WEBSOCKET_URL: z.string().url(),
  GTB_URL: z.string().url(),
  RESSOURCES_URL: z.string().url(),
  SUPPORT_MAIL: z.string().email(),
  MAPQUEST_API_URL: z.string().url(),
  MAPQUEST_API_KEY: z.string(),
  NEWS_API_URL: z.string().url(),
  NEWS_BLOG_URL: z.string().url(),
  NEWS_TAG: validStringNumber,
});

export const envSchema = (mode: string) =>
  envSchemaBase
    .refine(data => !(mode === MODE_ENV.PROD && !data.PREPROD_WEBAPP_URL), {
      message: `required if ENVIRONMENT is ${MODE_ENV.PROD}`,
      path: ["PREPROD_WEBAPP_URL"],
    })
    .refine(data => !(mode === MODE_ENV.PREPROD && !data.PROD_WEBAPP_URL), {
      message: `required if ENVIRONMENT is ${MODE_ENV.PREPROD}`,
      path: ["PROD_WEBAPP_URL"],
    });
