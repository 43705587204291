// ! DON'T REPLACE BY POPUP V2, IT BREAK CSS IMPORT ORDER
import { IntlProvider } from "react-intl";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { Calendar } from "@eisox/design-system";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { ErrorPopup } from "~/UI/layouts/ErrorPopup";
import { LoadingPopup } from "~/UI/layouts/LoadingPopup";
import { Toast } from "~/features/BoilerRooms";
import { i18next, oldMessage } from "~/i18n";
import { router } from "~/routes";

import theme from "./styles/theme.js";

import "./styles/main.scss";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const queryClientRouter = router(queryClient);

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <Calendar.Provider locale={i18next.language}>
        <IntlProvider locale={i18next.language} messages={oldMessage}>
          <Toast.Provider>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop
              pauseOnHover
              rtl={false}
              closeOnClick={false}
              draggable={false}
              theme="colored"
            />
            <RouterProvider router={queryClientRouter} />
            <ErrorPopup />
            <LoadingPopup />
            <Toast.Viewport />
          </Toast.Provider>
        </IntlProvider>
      </Calendar.Provider>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

export default App;
