import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ButtonV2, Copyright, Dropdown } from "@eisox/design-system";
import { UserIcon } from "@eisox/icons";
import { useQueryClient } from "@tanstack/react-query";

import { env } from "~/configuration";
import { routeToSignIn } from "~/routes/utils";
import { useUserStore } from "~/stores/user";

import styles from "./MobileUserDropdown.module.scss";

export const MobileUserDropdown: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const handleLogout = () => {
    useUserStore.getState().logout(queryClient);
    navigate(routeToSignIn());
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <UserIcon />
      </Dropdown.Trigger>
      <Dropdown.Content className={styles.mobileUserDropdown} side="bottom" align="end">
        <ButtonV2 className={styles.mobileUserDropdown__button} onClick={handleLogout}>
          {t("sidebarFooter.logout")}
        </ButtonV2>
        <Copyright className={styles.version} version={env.VERSION} />
      </Dropdown.Content>
    </Dropdown.Root>
  );
};
