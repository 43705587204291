import type { HousesHouseIdBoilerroomsPosGet200ResponseMessageInner, Module } from "@eisox/backend_webapp_api";

import type { ModuleHistoryResponseBody } from "~/apiV2";

const getModuleId = (boilerRoomId: string, boilerRoomPos: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[]) => {
  const moduleId = boilerRoomPos.find(bp => bp.boilerRooms?.map(b => b.id).includes(boilerRoomId))?.moduleId;
  if (!moduleId) throw new Error(`Module not found for boiler room with id: ${boilerRoomId}`);
  return moduleId;
};

const getSameGatewayModuleIds = (
  boilerRoomId: string,
  modules: Module[],
  boilerRoomPos: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[],
) => {
  const moduleId = boilerRoomPos.find(m => m.boilerRooms?.map(b => b.id).includes(boilerRoomId))?.moduleId;
  if (!moduleId) throw new Error(`Module not found for boiler room with id: ${boilerRoomId}`);
  const gatewayId = modules.find(m => m.id === moduleId)?.gatewayId;
  if (!gatewayId) throw new Error(`Gateway not found for module with id: ${moduleId}`);
  const sameGatewayModules = modules.filter(m => m.gatewayId === gatewayId);
  const sameGatewayModuleIds = sameGatewayModules.map(m => m.id).filter((m): m is string => m !== undefined);
  return sameGatewayModuleIds;
};

const convertAndSortDates = (dates: ModuleHistoryResponseBody) => {
  const result = new Map<string, string[]>();

  dates?.forEach(({ createdAt, moduleIds }) => {
    moduleIds?.forEach(moduleId => {
      if (!result.has(moduleId)) result.set(moduleId, []);
      if (createdAt) result.get(moduleId)?.push(createdAt);
    });
  });

  result.forEach((createdAtList, moduleId) => {
    result.set(
      moduleId,
      createdAtList.sort((a, b) => new Date(b).getTime() - new Date(a).getTime()),
    );
  });

  return result;
};

export { convertAndSortDates, getModuleId, getSameGatewayModuleIds };
