import type { ComponentPropsWithoutRef, ElementRef } from "react";
import React, { forwardRef, useEffect, useRef, useState } from "react";

import { cx } from "class-variance-authority";
import type {
  MiniMapProps as MiniMapPropsRZP,
  ReactZoomPanPinchContentRef,
  ReactZoomPanPinchProps,
} from "react-zoom-pan-pinch";
import {
  MiniMap as MiniMapRZP,
  TransformComponent,
  TransformWrapper,
  useControls,
  useTransformContext,
  useTransformEffect,
} from "react-zoom-pan-pinch";

import { MinusIcon, PlanIcon, PlusIcon } from "@eisox/icons";
import { createContext, useComposedRefs, useControllableState } from "@eisox/tools";
import { Slot } from "@radix-ui/react-slot";

import { useClickOnPlan, useDragItem } from "./hooks";
import type { Point } from "./utils";

import styles from "./Plan.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Plan
 * -----------------------------------------------------------------------------------------------*/

const PLAN_NAME = "Plan";

interface PlanContextValue {
  planNode: ElementRef<"img"> | null;
  imgSizeRef: React.MutableRefObject<{ width: number; height: number } | undefined>;
  onPlanNodeChange: (node: ElementRef<"img">) => void;
}

const [PlanContextProvider, usePlanContext] = createContext<PlanContextValue>(PLAN_NAME);

interface RootProps extends Omit<ReactZoomPanPinchProps, "centerOnInit"> {
  onClickOnPlan?: (point: Point) => void;
}

const Root: React.FC<RootProps> = ({ onClickOnPlan, ...props }) => {
  const [planNode, setPlanNode] = useState<ElementRef<"img"> | null>(null);

  const imgSizeRef = useRef<{ width: number; height: number }>();

  const { handleMouseDown, handleMouseUp } = useClickOnPlan({ onClickOnPlan });

  return (
    <PlanContextProvider imgSizeRef={imgSizeRef} planNode={planNode} onPlanNodeChange={setPlanNode}>
      <TransformWrapper
        {...props}
        onPanningStart={(ref, event) => {
          handleMouseDown(ref, event);
          props.onPanningStart?.(ref, event);
        }}
        onPanningStop={(ref, event) => {
          handleMouseUp(ref, event);
          props.onPanningStop?.(ref, event);
        }}
        centerOnInit
      />
    </PlanContextProvider>
  );
};

Root.displayName = PLAN_NAME;

/* -------------------------------------------------------------------------------------------------
 * Wrapper
 * -----------------------------------------------------------------------------------------------*/

const WRAPPER_NAME = "PlanWrapper";

interface WrapperProps extends ComponentPropsWithoutRef<"div"> {
  plan?: File;
  onPlanLoaded?: VoidFunction;
}

const Wrapper: React.FC<WrapperProps> = props => {
  const { plan, onPlanLoaded, className, style, children, ...wrapperProps } = props;
  const { imgSizeRef, onPlanNodeChange } = usePlanContext(WRAPPER_NAME);

  const instance = useTransformContext();

  const prevPlan = useRef(plan);

  const imgUrlRef = useRef<string>();
  // For rerenedering component only
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (plan && instance.wrapperComponent) {
      prevPlan.current = plan;
      const { width, height } = instance.wrapperComponent.getBoundingClientRect();
      void getScaleAndImgSize(plan, width, height).then(({ scale, imgW, imgH }) => {
        const url = URL.createObjectURL(plan);
        imgUrlRef.current = url;
        imgSizeRef.current = { width: imgW * scale, height: imgH * scale };
        instance.contentComponent?.style.setProperty("width", `${imgW * scale}px`);
        instance.contentComponent?.style.setProperty("height", `${imgH * scale}px`);
        instance.getContext().resetTransform(0);
        instance.getContext().centerView(undefined, 0);
        setLoading(false);
        return () => URL.revokeObjectURL(url);
      });
    }
  }, [plan, instance, imgSizeRef]);

  return (
    <TransformComponent
      wrapperClass={cx(styles.wrapper, className)}
      wrapperStyle={style}
      wrapperProps={wrapperProps}
      contentStyle={{ position: "relative" }}
    >
      {!loading && plan === prevPlan.current && imgUrlRef.current && imgSizeRef.current && (
        <>
          {children}
          <img
            ref={onPlanNodeChange}
            alt="plan"
            src={imgUrlRef.current}
            width={imgSizeRef.current.width}
            height={imgSizeRef.current.height}
            onLoad={() => {
              onPlanLoaded?.();
            }}
          />
        </>
      )}
    </TransformComponent>
  );
};

Wrapper.displayName = WRAPPER_NAME;

/* -------------------------------------------------------------------------------------------------
 * Item
 * -----------------------------------------------------------------------------------------------*/

interface ItemProps extends React.ComponentPropsWithRef<"div"> {
  position?: Point;
  origin?: "center" | "bottom center";
  asChild?: boolean;
  onPositionChange?: (point: Point) => void;
  onPositionEnd?: (point: Point) => void;
  defaultPosition?: Point;
  draggable?: boolean;
}

const Item = forwardRef<HTMLDivElement, ItemProps>((props, forwardedRef) => {
  const {
    position: positionProp,
    onPositionChange,
    onPositionEnd,
    defaultPosition,
    draggable = false,
    origin = "center",
    style,
    asChild,
    children,
    ...itemProps
  } = props;

  const instance = useTransformContext();

  const Comp = asChild ? Slot : "div";

  const [scale, setScale] = useState<number>(instance.getContext().state.scale);
  const [position = { x: 0, y: 0 }, setPosition] = useControllableState<Point>({
    prop: positionProp,
    onChange: onPositionChange,
    defaultProp: defaultPosition,
  });

  const { target } = useDragItem<HTMLDivElement>({
    origin,
    onDragStart: instance.clearPanning,
    onDragChange: ({ x, y }) => setPosition({ x, y }),
    onDragEnd: () => onPositionEnd?.(position),
    disabled: !draggable,
  });

  const ref = useRef<HTMLDivElement>(null);
  const composedRefs = useComposedRefs(ref, forwardedRef, target);

  useTransformEffect(({ state }) => {
    setScale(state.scale);
  });

  return (
    <Comp
      {...itemProps}
      ref={composedRefs}
      style={{
        ...style,
        position: "absolute",
        left: `${position.x}%`,
        top: `${position.y}%`,
        userSelect: "none",
        transformOrigin: origin === "center" ? "center" : "bottom center",
        transform: `translate(-50%, -${origin === "center" ? "50%" : "100%"}) scale(${1 / scale})`,
      }}
    >
      {children}
    </Comp>
  );
});

Item.displayName = "PlanItem";

/* -------------------------------------------------------------------------------------------------
 * Controls
 * -----------------------------------------------------------------------------------------------*/

const CONTROLS_NAME = "Controls";

type ControlsProps = React.ComponentPropsWithRef<"div">;

const Controls = forwardRef<HTMLDivElement, ControlsProps>(({ className, ...props }, forwardedRef) => {
  const { zoomIn, zoomOut, resetTransform } = useControls();

  return (
    <div {...props} ref={forwardedRef} className={cx(styles.controls, className)}>
      <button
        className={styles.controls__button}
        onClick={e => {
          e.preventDefault();
          zoomIn();
        }}
      >
        <PlusIcon />
      </button>
      <button
        className={styles.controls__button}
        onClick={e => {
          e.preventDefault();
          zoomOut();
        }}
      >
        <MinusIcon />
      </button>
      <button
        className={styles.controls__button}
        onClick={e => {
          e.preventDefault();
          resetTransform();
        }}
      >
        <PlanIcon />
      </button>
    </div>
  );
});

Controls.displayName = CONTROLS_NAME;

/* -------------------------------------------------------------------------------------------------
 * Minimap
 * ------------------------------------------------------------------------------------------------*/

type MiniMapProps = Omit<MiniMapPropsRZP, "children">;

const MiniMap: React.FC<MiniMapProps> = props => {
  const { imgSizeRef, planNode } = usePlanContext("minimap");
  return planNode && imgSizeRef.current ? (
    <MiniMapRZP {...props}>
      <img src={planNode.src} width={imgSizeRef.current.width} height={imgSizeRef.current.height} />
    </MiniMapRZP>
  ) : null;
};

MiniMap.displayName = "MiniMap";

/* -------------------------------------------------------------------------------------------------
 * HOC
 * -----------------------------------------------------------------------------------------------*/

function withReactZoomPanPinchContentRef<P>(
  WrappedComponent: React.ComponentType<P & { rzppRef: ReactZoomPanPinchContentRef }>,
) {
  const ComponentWithReactZoomPanPinchContentRef = (props: P) => {
    return <Root>{ref => <WrappedComponent {...props} rzppRef={ref} />}</Root>;
  };
  return ComponentWithReactZoomPanPinchContentRef;
}

const getScaleAndImgSize = async (file: File, maxWidth: number, maxHeight: number = maxWidth) => {
  const reader = new FileReader();
  const result = await new Promise<string>((resolve, reject) => {
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error("Failed to read file"));
    reader.readAsDataURL(file);
  });

  const img = new Image();
  img.src = result;

  await new Promise<void>((resolve, reject) => {
    img.onload = () => resolve();
    img.onerror = event => console.log(event);
  });

  const scale = Math.min(maxWidth / img.width, maxHeight / img.height);

  return { scale, imgW: img.width, imgH: img.height };
};

export { Controls, Item, MiniMap, Root, withReactZoomPanPinchContentRef, Wrapper };
export type { ItemProps, MiniMapProps, RootProps, WrapperProps };
