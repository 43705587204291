import { useMemo } from "react";

import { useTranslation } from "react-i18next";

import { ButtonV2 as Button, SelectV2 as Select, TextField } from "@eisox/design-system";
import { MagnifyingGlassIcon } from "@eisox/icons";
import { useControllableState } from "@eisox/tools";

import { getFiltersOptions, getSortOptions } from "../../helpers";

import styles from "./Filters.module.scss";

interface FiltersProps {
  displayedItems: "gateways" | "valves" | "rooms";
  search?: string;
  onSearchChange?: (search: string) => void;
  defaultSearch?: string;
  filters?: string[];
  onFiltersChange?: (filters: string[]) => void;
  defaultFilters?: string[];
  sort?: string;
  onSortChange?: (value: string) => void;
  defaultSort?: string;
  total: number;
}

const Filters: React.FC<FiltersProps> = ({
  displayedItems,
  search: searchProp,
  onSearchChange,
  defaultSearch,
  filters: filtersProp,
  onFiltersChange,
  defaultFilters,
  sort: sortProp,
  onSortChange,
  defaultSort,
  total = 0,
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useControllableState({
    prop: searchProp,
    onChange: onSearchChange,
    defaultProp: defaultSearch,
  });
  const [filters, setFilters] = useControllableState({
    prop: filtersProp,
    onChange: onFiltersChange,
    defaultProp: defaultFilters,
  });
  const [sort, setSort] = useControllableState({
    prop: sortProp,
    onChange: onSortChange,
    defaultProp: defaultSort,
  });

  const filtersOptions = useMemo(() => getFiltersOptions(displayedItems), [displayedItems]);
  const allFiltersOptionsSelected = filters?.length === filtersOptions.length;

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value);

  const handleFiltersChange = (value: string | string[]) => setFilters(value as string[]);
  const handleSelectAllFilters = () => {
    if (allFiltersOptionsSelected) {
      setFilters([]);
    } else {
      setFilters(filtersOptions.map(f => f.value));
    }
  };

  const handleChangesortValue = (value: string | string[]) => setSort(value as string);

  return (
    <div className={styles.filters}>
      <TextField.Root
        className={styles.filters__input}
        value={search}
        onChange={handleSearchChange}
        placeholder={t("advancedMaintenance.placeholder.input")}
      >
        <TextField.Slot>
          <MagnifyingGlassIcon />
        </TextField.Slot>
      </TextField.Root>
      <Select
        classNames={{ content: styles.filters__select, unscrollableContent: styles.filters__unscrollableContent }}
        placeholder={t("advancedMaintenance.placeholder.filters")}
        value={filters}
        onChange={handleFiltersChange}
        options={filtersOptions}
        unscrollableContent={
          <Button onClick={handleSelectAllFilters}>
            {t(`advancedMaintenance.filters.${allFiltersOptionsSelected ? "unselectAll" : "selectAll"}`)}
          </Button>
        }
      />
      {displayedItems !== "rooms" && (
        <Select options={getSortOptions(displayedItems)} value={sort} onChange={handleChangesortValue} />
      )}
      <span className={styles.filters__total}>{t("advancedMaintenance.total", { count: total })}</span>
    </div>
  );
};

export { Filters };
export type { FiltersProps };
