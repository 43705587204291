import type { ComponentPropsWithRef, Dispatch, SetStateAction } from "react";
import { forwardRef, useEffect, useRef, useState } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";
import type { ReactZoomPanPinchContext } from "react-zoom-pan-pinch";
import { useTransformEffect } from "react-zoom-pan-pinch";
import { $enum } from "ts-enum-util";

import { PlanV2 as DSPlan, SelectV2 as Select } from "@eisox/design-system";

import type { Plan as PlanType } from "~/apiV2";
import { GatewayProblemType, ValveProblemType } from "~/features/InterventionPlanTool";
import { upperCaseToCamelCase } from "~/utils/stringUtils";

import type { ItemProps as ItemType } from "../../types";
import { Gateway } from "../Gateway";
import { LegendDialog } from "../LegendDialog";
import { Valve } from "../Valve";

import styles from "./Plan.module.scss";

interface ItemProps
  extends ItemType<"gateway" | "valve">,
    Omit<ComponentPropsWithRef<"div">, "id" | "type" | "draggable"> {
  selected?: boolean;
}

const Item = forwardRef<HTMLDivElement, ItemProps>((props, forwardedRef) => {
  const { id, type, uid, name, resolution = null, unclogging = false, plan, selected = false, ...itemProps } = props;

  return (
    <DSPlan.Item
      {...itemProps}
      ref={forwardedRef}
      asChild
      id={id}
      position={{ x: plan.x, y: plan.y }}
      className={cx(styles.item, selected && styles.item_selected)}
    >
      <div className={styles.item}>
        {type === "gateway" ? (
          <Gateway resolution={resolution} />
        ) : (
          <Valve resolution={resolution} unclogging={unclogging} />
        )}
        {(!!resolution || !!unclogging) && (
          <div className={styles.item__label}>
            {uid}
            {name ? ` - ${type === "gateway" ? name : name.slice(-5)}` : ""}
          </div>
        )}
      </div>
    </DSPlan.Item>
  );
});

Item.displayName = "Maintenance.PlanItem";

interface PlanProps {
  plan?: File;
  planId: string;
  plans: PlanType[];
  items: ItemType<"gateway" | "valve">[];
  selectedItem?: string;
  setSelectedItem: Dispatch<SetStateAction<string | undefined>>;
  onItemClick: (item: ItemType<"valve" | "gateway">) => void;
  onPlanChange: (planId: string) => void;
}

const Plan: React.FC<PlanProps> = ({
  plan,
  planId,
  plans,
  items,
  selectedItem,
  setSelectedItem,
  onItemClick,
  onPlanChange,
}) => {
  const { t } = useTranslation();

  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const [selectedItemForGrowth, setSelectedItemForGrowth] = useState<string>();
  const [visibleItems, setVisibleItems] = useState<ItemType<"gateway" | "valve">[]>(items);

  const visibleValveResolutions = Array.from(
    new Set(visibleItems.filter(v => v.type === "valve").map(v => v.resolution)),
  );
  if (visibleItems.some(v => v.unclogging)) {
    visibleValveResolutions.push(ValveProblemType.UNCLOG_CHANGE_BODY);
  }
  const displayedValveResolutions = visibleValveResolutions.filter(r =>
    $enum(ValveProblemType)
      .getValues()
      .includes(r as ValveProblemType),
  );
  const visibleGatewayResolutions = Array.from(
    new Set(visibleItems.filter(v => v.type === "gateway").map(v => v.resolution)),
  );
  const displayedGatewayResolutions = visibleGatewayResolutions.filter(r =>
    $enum(GatewayProblemType)
      .getValues()
      .includes(r as GatewayProblemType),
  );

  const plansOptions = plans.map(p => ({ value: p.id, name: p.name }));

  const handleTransform = (
    instance: ReactZoomPanPinchContext,
    state: {
      scale: number;
      positionX: number;
      positionY: number;
    },
  ) => {
    const visibleItems: ItemType<"gateway" | "valve">[] = [];

    const { positionX, positionY } = state;
    const { width: planWidth, height: planHeight } = instance.contentComponent?.getBoundingClientRect() ?? {};
    const { width: viewportWidth, height: viewportHeight } = instance.wrapperComponent?.getBoundingClientRect() ?? {};

    if (planWidth && planHeight && viewportWidth && viewportHeight) {
      // Calcul des coordonnées de la zone visible dans le référentiel du plan
      const visibleLeft = -positionX;
      const visibleTop = -positionY;
      const visibleRight = visibleLeft + viewportWidth;
      const visibleBottom = visibleTop + viewportHeight;

      for (const item of items) {
        // Convertir les positions en pourcentags vers des positions absolues
        const itemX = (item.plan.x / 100) * planWidth;
        const itemY = (item.plan.y / 100) * planHeight;

        // Vérifier si l'élément est dans la zone visible
        if (itemX >= visibleLeft && itemX <= visibleRight && itemY >= visibleTop && itemY <= visibleBottom) {
          visibleItems.push(item);
        }

        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
          setVisibleItems(visibleItems);
        }, 300);
      }
    }
  };

  const handlePlanLoaded = () => {
    setSelectedItemForGrowth(selectedItem);
  };

  const handleAnimationEnd = () => {
    setSelectedItemForGrowth(undefined);
    setSelectedItem(undefined);
  };

  const handleChangePlan = (value: string | string[]) => onPlanChange(value as string);

  useTransformEffect(({ instance, state }) => handleTransform(instance, state));

  useEffect(() => {
    if (selectedItem) setSelectedItemForGrowth(selectedItem);
  }, [selectedItem]);

  return (
    <div className={styles.plan}>
      <DSPlan.Controls className={styles.plan__controls} />
      <Select
        classNames={{ trigger: styles.plan__select }}
        options={plansOptions}
        value={planId}
        onChange={handleChangePlan}
      />
      <DSPlan.Wrapper plan={plan} className={styles.plan__plan} onPlanLoaded={handlePlanLoaded}>
        {items.map((item, index) => {
          return (
            <Item
              key={`plan-item-${index}`}
              {...item}
              onClick={() => onItemClick(item)}
              onAnimationEnd={handleAnimationEnd}
              selected={item.id === selectedItemForGrowth}
            />
          );
        })}
      </DSPlan.Wrapper>
      <div className={styles.legend}>
        <div className={styles.legend__items}>
          {displayedValveResolutions.map(resolution => (
            <div key={resolution} className={styles.legend__item}>
              <Valve
                key={`valve-${resolution}`}
                resolution={resolution ?? null}
                unclogging={resolution === ValveProblemType.UNCLOG_CHANGE_BODY}
              />
              {t(`maintenance.problems.valve.${upperCaseToCamelCase(resolution!)}`)}
            </div>
          ))}
          {displayedGatewayResolutions.map(resolution => (
            <div key={resolution} className={styles.legend__item}>
              <Gateway key={`gateway-${resolution}`} resolution={resolution ?? null} />
              {t(`maintenance.problems.gateway.${upperCaseToCamelCase(resolution!)}`)}
            </div>
          ))}
        </div>
        <LegendDialog />
      </div>
    </div>
  );
};

export { Plan };
export type { PlanProps };
