import * as React from "react";

import { IconProps } from "./types";

export const RoomIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M14.1577 1.5L9.4742 1.5C9.28521 1.5 8.68396 1.64371 8.68396 2.18421C8.68396 2.72471 9.28521 2.86842 9.4742 2.86842H13.1313L14.4129 1.61433C14.3502 1.54417 14.2591 1.5 14.1577 1.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.5 1.84235V6.52583C14.5 6.71477 14.3563 7.31604 13.8158 7.31604C13.2753 7.31604 13.1316 6.71477 13.1316 6.52583V2.86867L14.3857 1.5871C14.4559 1.64975 14.5 1.74089 14.5 1.84235Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1.5 1.84235L1.5 6.52583C1.5 6.71477 1.64371 7.31604 2.18421 7.31604C2.72471 7.31604 2.86842 6.71477 2.86842 6.52583V2.86867L1.61433 1.5871C1.54417 1.64975 1.5 1.74089 1.5 1.84235Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1.84235 1.5L6.52583 1.5C6.71476 1.5 7.31604 1.64371 7.31604 2.18421C7.31604 2.72471 6.71476 2.86842 6.52583 2.86842H2.86867L1.5871 1.61433C1.64975 1.54417 1.74089 1.5 1.84235 1.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.5 14.1577V9.4742C14.5 9.28521 14.3563 8.68396 13.8158 8.68396C13.2753 8.68396 13.1316 9.28521 13.1316 9.4742V13.1313L14.3857 14.4129C14.4559 14.3502 14.5 14.2591 14.5 14.1577Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.1577 14.5H9.4742C9.28521 14.5 8.68396 14.3563 8.68396 13.8158C8.68396 13.2753 9.28521 13.1316 9.4742 13.1316H13.1313L14.4129 14.3857C14.3502 14.4559 14.2591 14.5 14.1577 14.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1.84235 14.5H6.52583C6.71476 14.5 7.31604 14.3563 7.31604 13.8158C7.31604 13.2753 6.71476 13.1316 6.52583 13.1316H2.86867L1.5871 14.3857C1.64975 14.4559 1.74089 14.5 1.84235 14.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1.5 14.1577L1.5 9.4742C1.5 9.28521 1.64371 8.68396 2.18421 8.68396C2.72471 8.68396 2.86842 9.28521 2.86842 9.4742V13.1313L1.61433 14.4129C1.54417 14.3502 1.5 14.2591 1.5 14.1577Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M5.94759 6.36899C5.94759 6.13625 6.13626 5.94759 6.36899 5.94759H9.63481C9.86751 5.94759 10.0562 6.13625 10.0562 6.36899V9.6348C10.0562 9.8675 9.86751 10.0562 9.63481 10.0562H6.36899C6.13626 10.0562 5.94759 9.8675 5.94759 9.6348V6.36899Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default RoomIcon;
