import { useIntl } from "react-intl";

import { ActionButton, Modal } from "@eisox/design-system";
import { ArrowRightIcon, LocationIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./DialogHeader.module.scss";

interface DialogHeaderProps {
  item: "valve" | "gateway" | "room" | "boilerroom";
  disabled?: boolean;
}

export const DialogHeader: React.FC<DialogHeaderProps> = ({ item, disabled = false }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const headerStyle = bem("header");

  return (
    <Modal.Header
      title={formatMessage({ id: `plan.actions.move.${item}.title` })}
      subtitle={formatMessage({ id: `plan.actions.move.${item}.subtitle` })}
      icon={<LocationIcon />}
    >
      <Modal.Close asChild>
        <ActionButton
          className={headerStyle("button", { variant: "cancel" })}
          variant="cancel"
          text={formatMessage({ id: "plan.actions.move.cancel" })}
        />
      </Modal.Close>
      <ActionButton
        type="submit"
        rounded
        text={formatMessage({ id: "plan.actions.move.save" })}
        icon={<ArrowRightIcon />}
        disabled={disabled}
      />
    </Modal.Header>
  );
};
