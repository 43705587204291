import { ItemType } from "~/UI/layouts/PlanV2/components/Items";

export function getMaxDepth(arr: ItemType[]): number {
  let maxDepth = 0;

  for (const obj of arr) {
    if (Array.isArray(obj.items)) {
      const depth = 1 + getMaxDepth(obj.items);
      maxDepth = Math.max(maxDepth, depth);
    }
  }

  return maxDepth;
}

export const flattenItems = (items: ItemType[]): ItemType[] => {
  const flattenedItems: ItemType[] = [];
  function flattenRecursive(item: ItemType) {
    flattenedItems.push(item);
    if (item.items) {
      item.items.forEach(flattenRecursive);
    }
  }
  items.forEach(flattenRecursive);
  return flattenedItems;
};

/**
 * If all the items in the selection are already selected, then they are deselected,
 * otherwise they are added to the selection.
 *
 * @param items initial items
 * @param selectedItems selected items
 * @returns transformed items
 */
export function selectionItems(items: ItemType[], selectedItems: ItemType[]): ItemType[] {
  if (selectedItems.every(i => i.selected)) {
    return removeItemsFromSelection(items, selectedItems);
  } else {
    return addItemsInSelection(items, selectedItems);
  }
}

function addItemsInSelection(items: ItemType[], selectedItems: ItemType[], parentItem?: ItemType): ItemType[] {
  const updatedItems = [...items];
  const undisabledSelectedItems = [...selectedItems].filter(i => !i.disabled);

  for (const item of updatedItems) {
    if (!item.selected) {
      item.selected = undisabledSelectedItems.flatMap(sI => sI.id).includes(item.id) || !!parentItem?.selected;
    }

    if (item.items) {
      addItemsInSelection(item.items, undisabledSelectedItems, item);
      item.selected = item.items.every(i => i.selected);
    }
  }

  return updatedItems;
}

function removeItemsFromSelection(items: ItemType[], selectedItems: ItemType[]): ItemType[] {
  const updatedItems = [...items];
  const undisabledSelectedItems = [...selectedItems].filter(i => !i.disabled);

  for (const item of updatedItems) {
    if (undisabledSelectedItems.map(sI => sI.id).includes(item.id)) {
      deselectChildrenItems(item);
    }
    if (item.items) {
      removeItemsFromSelection(item.items, undisabledSelectedItems);
      item.selected = item.items.every(i => i.selected);
    }
  }

  return updatedItems;
}

function deselectChildrenItems(item: ItemType) {
  item.selected = false;
  if (item.items) {
    for (const childItem of item.items) {
      deselectChildrenItems(childItem);
    }
  }
}
