import { PhoneInput } from "@eisox/design-system";
import { z } from "@eisox/zod";

import { intl } from "~/i18n";

import { PasswordRules } from "./type";

const nameRegExp = /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s-]+$/;

const accountSchema = z.object({
  lastName: z
    .string()
    .regex(nameRegExp, { message: intl.formatMessage({ id: "myAccount.lastName.error" }) })
    .min(1)
    .max(40),
  firstName: z
    .string()
    .regex(nameRegExp, { message: intl.formatMessage({ id: "myAccount.firstName.error" }) })
    .min(1)
    .max(40),
  company: z.string().min(1),
  phoneNumber: z
    .string()
    .optional()
    .refine(value => !value || PhoneInput.isValidPhoneNumber(value), {
      message: intl.formatMessage({ id: "myAccount.phoneNumber.error" }),
    }),
});

const emailSchema = z.object({
  email: z.string().email(),
});

const passwordSchema = z
  .string()
  .regex(/^.{12,}$/, { message: PasswordRules.MIN_LENGTH })
  .regex(/[A-Z]/, { message: PasswordRules.UPPER_CASE })
  .regex(/[a-z]/, { message: PasswordRules.LOWER_CASE })
  .regex(/[0-9]/, { message: PasswordRules.NUMBER })
  .regex(/[@#$%()[\]{}*+\-_.,:;<>=|&~?!]/, { message: PasswordRules.SPECIAL_CHARACTER });

export { accountSchema, emailSchema, passwordSchema, nameRegExp };
