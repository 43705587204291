import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";
import * as yup from "yup";

import { Circle, Dialog, TextInput } from "@eisox/design-system";
import { ActionButton } from "@eisox/design-system";
import { validateGatewayMac } from "@eisox/gateways";
import { GatewayIcon, ValveIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { validateValveMac } from "@eisox/valves";
import { yupResolver } from "@hookform/resolvers/yup";

import type { houseLoader } from "~/UI";
import type { GatewaysWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { useAction } from "~/hooks";
import { idLoaderHouse, routeToGateway, routeToValve } from "~/routes/utils";
import { API } from "~/types/API";
import { getDirtyValues } from "~/utils";

import styles from "./ReplacePopUp.module.scss";

interface ReplacePopUpProps {
  open?: boolean;
  onClose?: () => void;
  isForReplaceBox?: boolean;
  mac: string;
  id: string;
}

interface UpdateElementDto {
  mac: string;
}

export const ReplacePopUp: React.FC<ReplacePopUpProps> = ({
  open = false,
  onClose,
  isForReplaceBox = true,
  mac,
  id,
}) => {
  const { formatMessage } = useIntl();
  const { houseId } = useParams();
  const { gateways, valves } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;

  const bem = useBem(styles);
  const replacePopUpStyle = bem("delete-pop-up");
  const contentStyle = bem("content");

  const { submit, Form } = useAction({
    onSuccess: () => {
      onClose?.();
    },
  });

  const schema = yup.object({
    mac: yup
      .string()
      .required(formatMessage({ id: "error.emptyField" }))
      .test(
        "mac",
        formatMessage({ id: "error.invalidMacFormat" }, { e: isForReplaceBox ? "Box" : "Tête th." }),
        value => (isForReplaceBox ? validateGatewayMac(value) : validateValveMac(value)),
      )
      .test(
        "mac",
        formatMessage({ id: "error.invalidMacAlreadyExist" }, { e: isForReplaceBox ? "Box" : "Tête th." }),
        value => {
          return isForReplaceBox ? !gateways.find(e => e.mac === value) : !valves.find(e => e.mac === value);
        },
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<UpdateElementDto>({
    resolver: yupResolver(schema),
    defaultValues: {
      mac: "",
    },
  });

  const onSubmit = handleSubmit(data => {
    const dirtyValues = getDirtyValues(dirtyFields, data as any) as unknown as Partial<UpdateElementDto>;
    submit(
      dirtyValues,
      API.HTTP_METHOD.PATCH,
      isForReplaceBox ? routeToGateway(houseId!, id) : routeToValve(houseId!, id),
    );
  });

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose?.()}>
      <Dialog.Content
        title={formatMessage(
          { id: `drawer.listDrawer.replace.popup.title` },
          {
            e: isForReplaceBox ? "Box" : "Tête th.",
          },
        )}
        icon={
          <Circle size={70} className={replacePopUpStyle("icon")}>
            {isForReplaceBox ? <GatewayIcon /> : <ValveIcon />}
          </Circle>
        }
      >
        <Form className={replacePopUpStyle()} onSubmit={onSubmit}>
          <div className={contentStyle()}>
            <div className={contentStyle("replace")}>
              <p>{formatMessage({ id: "drawer.listDrawer.replace.popup.replace" })}</p>
              <p>{mac}</p>
            </div>
            <Controller
              name="mac"
              control={control}
              render={({ field }) => (
                <TextInput
                  {...field}
                  onChange={e =>
                    field.onChange(e.target.value.trim()[isForReplaceBox ? "toLowerCase" : "toUpperCase"]())
                  }
                  error={errors.mac}
                  label={formatMessage({ id: "drawer.listDrawer.replace.popup.by" })}
                  placeholder={mac}
                />
              )}
            />
          </div>
          <div className={replacePopUpStyle("footer")}>
            <ActionButton
              variant="cancel"
              text={formatMessage({ id: "drawer.listDrawer.replace.popup.buttons.cancel" })}
              onClick={onClose}
            />
            <ActionButton
              rounded
              variant="primary"
              text={formatMessage({ id: "drawer.listDrawer.replace.popup.buttons.replace" })}
              type="submit"
              disabled={!isDirty}
            />
          </div>
        </Form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
