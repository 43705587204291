import { forwardRef } from "react";

import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { primary } from "@eisox/colors";
import { Slot } from "@radix-ui/react-slot";

import styles from "./Heading.module.scss";

const headingVariants = cva(styles.heading, {
  variants: {
    size: {
      1: styles.heading_size_1,
      2: styles.heading_size_2,
      3: styles.heading_size_3,
      4: styles.heading_size_4,
      5: styles.heading_size_5,
      6: styles.heading_size_6,
    },
    color: {
      [primary.blue]: styles.heading_color_blue,
      [primary.red]: styles.heading_color_red,
      [primary.green]: styles.heading_color_green,
      [primary.orange]: styles.heading_color_orange,
      [primary.gray]: styles.heading_color_gray,
      [primary.white]: styles.heading_color_white,
      [primary.darkBlue]: styles.heading_color_darkBlue,
      [primary.darkGray]: styles.heading_color_darkGray,
      [primary.darkerGray]: styles.heading_color_darkerGray,
    },
    weight: {
      bold: styles.heading_weight_bold,
      medium: styles.heading_weight_medium,
      regular: styles.heading_weight_regular,
    },
  },
  defaultVariants: {
    size: 5,
    color: primary.darkBlue,
    weight: "bold",
  },
});

type Tag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface HeadingProps extends Omit<React.ComponentPropsWithRef<Tag>, "color">, VariantProps<typeof headingVariants> {
  as?: Tag;
  asChild?: boolean;
}

const Heading = forwardRef<HTMLHeadingElement, HeadingProps>((props, forwardedRef) => {
  const { as: Tag = "h1", size, color, weight, className, children, asChild, ...headingProps } = props;
  return (
    <Slot {...headingProps} ref={forwardedRef} className={headingVariants({ size, color, weight, className })}>
      {asChild ? children : <Tag>{children}</Tag>}
    </Slot>
  );
});

Heading.displayName = "Heading";

export { Heading };
export type { HeadingProps, Tag };
