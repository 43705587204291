import type { PropsWithChildren } from "react";
import React, { forwardRef } from "react";

import { cx } from "class-variance-authority";
import clsx from "clsx";

import { useBem } from "@eisox/tools";
import * as RadixDialog from "@radix-ui/react-dialog";

import { Circle } from "../Circle";

import styles from "./styles.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
const ROOT_NAME = "Modal";
type RootProps = RadixDialog.DialogProps;
const Root = RadixDialog.Root;
Root.displayName = ROOT_NAME;

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/
const TRIGGER_NAME = "ModalTrigger";
type TriggerProps = RadixDialog.DialogTriggerProps;
const Trigger = RadixDialog.Trigger;
Trigger.displayName = TRIGGER_NAME;

/* -------------------------------------------------------------------------------------------------
 * Close
 * -----------------------------------------------------------------------------------------------*/
const CLOSE_NAME = "ModalClose";
type CloseProps = RadixDialog.DialogCloseProps;
const Close = RadixDialog.Close;
Close.displayName = CLOSE_NAME;

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/
const HEADER_NAME = "DialogHeader";
interface HeaderProps extends PropsWithChildren {
  title: string;
  subtitle: string;
  icon: React.ReactElement;
  className?: string;
}
const Header: React.FC<HeaderProps> = ({ title, subtitle, icon, className, children }) => {
  const bem = useBem(styles);
  const headerStyle = bem("header");
  const titlesStyle = bem("titles");

  return (
    <div className={clsx(headerStyle(), className)}>
      <div className={headerStyle("content")}>
        <Circle className={headerStyle("icon")} size={70}>
          {icon}
        </Circle>
        <div className={titlesStyle()}>
          <RadixDialog.Title asChild>
            <h2 className={titlesStyle("title")}>{title}</h2>
          </RadixDialog.Title>
          <h3 className={titlesStyle("subtitle")}>{subtitle}</h3>
        </div>
      </div>
      {children}
    </div>
  );
};
Header.displayName = HEADER_NAME;

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/

const CONTENT_NAME = "ModalContent";
type ContentProps = RadixDialog.DialogContentProps;

const Content = forwardRef<HTMLDivElement, ContentProps>(({ className, children, ...props }, forwardedRef) => {
  const bem = useBem(styles);
  const overlayStyle = bem("modal-overlay");
  const modalStyle = bem("modal-content");

  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay className={overlayStyle()} />
      <RadixDialog.Content {...props} ref={forwardedRef} className={cx(modalStyle(), className)}>
        {children}
      </RadixDialog.Content>
    </RadixDialog.Portal>
  );
});
Content.displayName = CONTENT_NAME;

export { Close, Content, Header, Root, Trigger };
export type { CloseProps, ContentProps, HeaderProps, RootProps, TriggerProps };
