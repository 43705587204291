import { forwardRef, useRef } from "react";

import { cx } from "class-variance-authority";

import { composeRefs } from "@eisox/tools";

import styles from "./TextField.module.scss";

/* -------------------------------------------------------------------------------------------------
 * TextField
 * -----------------------------------------------------------------------------------------------*/

interface TextFieldProps extends React.ComponentPropsWithRef<"input"> {
  defaultValue?: string | number;
  value?: string | number;
  type?:
    | "date"
    | "datetime-local"
    | "email"
    | "hidden"
    | "month"
    | "number"
    | "password"
    | "search"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";
  error?: boolean;
}

const Root = forwardRef<HTMLInputElement, TextFieldProps>((props, forwardedRef) => {
  const { className, style, error, children, ...inputProps } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const composedRefs = composeRefs(inputRef, forwardedRef);

  const onPointerDown = (event: React.PointerEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    if (target.closest("input, button, a")) return;

    const input = inputRef.current;
    if (!input) return;

    const isRightSlot = target.closest(`
        .${styles.textField__slot}[data-side='right'],
        .${styles.textField__slot}:not([data-side='right']) ~ .${styles.textField__slot}:not([data-side='left'])
      `);

    const cursorPosition = isRightSlot ? input.value.length : 0;

    requestAnimationFrame(() => {
      // Only some input types support this, browsers will throw an error if not supported
      // See: https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/setSelectionRange#:~:text=Note%20that%20according,not%20support%20selection%22.
      try {
        input.setSelectionRange(cursorPosition, cursorPosition);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        /* empty */
      }
      input.focus();
    });
  };

  return (
    <div
      className={cx(styles.textField, error && styles.textField_error, className)}
      style={style}
      onPointerDown={onPointerDown}
    >
      <input spellCheck={false} {...inputProps} ref={composedRefs} className={styles.textField__input} />
      {children}
    </div>
  );
});

Root.displayName = "TextField";

/* -------------------------------------------------------------------------------------------------
 * Slot
 * -----------------------------------------------------------------------------------------------*/

interface SlotProps extends React.ComponentPropsWithRef<"div"> {
  side?: "left" | "right";
}

const Slot = forwardRef<HTMLDivElement, SlotProps>((props, forwardedRef) => {
  const { className, side, children, ...divProps } = props;
  return (
    <div data-side={side} {...divProps} ref={forwardedRef} className={cx(styles.textField__slot, className)}>
      {children}
    </div>
  );
});

Slot.displayName = "TextField.Slot";

export { Root, Slot };
export type { TextFieldProps, SlotProps };
