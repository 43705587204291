import * as React from "react";

import { IconProps } from "./types";

export const NetworkIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M13.2 9.3H14.5V14.5H13.2V13.2H8C7.65521 13.2 7.32455 13.063 7.08077 12.8192C6.83695 12.5754 6.7 12.2448 6.7 11.9V8.65H5.4V7.35H6.7V5.4H2.8V6.7H1.5V1.5H2.8V2.8H8C8.34479 2.8 8.67545 2.93696 8.91923 3.18076C9.16304 3.42456 9.3 3.75522 9.3 4.1V7.35H10.6V8.65H9.3V10.6H13.2V9.3ZM2.8 4.75V3.45H8C8.33761 3.45 8.65 3.775 8.65 4.1V11.25H13.2V12.55H8C7.675 12.55 7.35 12.225 7.35 11.9V4.75H6.7H2.8Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default NetworkIcon;
