import { Image, Page, StyleSheet, View } from "@react-pdf/renderer";

import type { Plan } from "~/features/InterventionPlanTool";

import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { orientation, size } from "../constant";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    padding: "10 20",
    height: "80%",
  },
  containerImage: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
});

export const PagePlan: React.FC<Plan> = ({ name, image }) => {
  return (
    <Page size={size} orientation={orientation}>
      <Header title={name} />
      <View style={styles.container}>
        <View style={styles.containerImage}>
          <Image src={image} style={styles.image} />
        </View>
      </View>
      <Footer />
    </Page>
  );
};
