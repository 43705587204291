import { useRef, useState } from "react";

import clsx from "clsx";
import type { ReactZoomPanPinchContentRef, ReactZoomPanPinchState } from "react-zoom-pan-pinch";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

import { Card } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import LoaderApp from "~/components/LoaderApp";

import { Controls } from "./components/Controls";
import type { ItemType } from "./components/Items";
import { Items } from "./components/Items";
import MiniMap from "./components/Minimap/Minimap";
import { useDragItem } from "./hooks";
import { useContainerSelection } from "./hooks/useContainerSelection";
import { useInitImageScale } from "./hooks/useInitImageScale";
import { calculateClickPercentage } from "./utils";

import styles from "./PlanV2.module.scss";

interface PlanV2Props {
  planUrl?: string;
  items?: ItemType[];
  onClick?: (x: number, y: number) => void;
  onChange?: (items: ItemType[]) => void;
  selectionFunction?: (items: ItemType[], selectedItems: ItemType[]) => ItemType[];
  rectangleSelection?: boolean;
  zoomFactor?: number;
  className?: string;
  id?: string;
  displayControl?: boolean;
  displayPreview?: boolean;
}

export const PlanV2: React.FC<PlanV2Props> = ({
  planUrl,
  items = [],
  onClick,
  onChange,
  selectionFunction,
  rectangleSelection = false,
  zoomFactor = 8,
  className,
  id,
  displayControl = true,
  displayPreview = false,
}) => {
  const bem = useBem(styles);
  const containerPlanStyle = bem("container-plan");
  const planStyle = bem("plan");

  const wrapperRef = useRef<ReactZoomPanPinchContentRef>(null);
  const container = useRef<HTMLDivElement>(null);
  const itemsRefs: Record<string, HTMLDivElement | null> = {};

  const checkForDrag = useRef<number>(0);
  const [rzppState, setRzppState] = useState<ReactZoomPanPinchState>({
    positionX: 0,
    positionY: 0,
    scale: 0,
    previousScale: 0,
  });

  const { loading, imageScale, imageNaturalSize } = useInitImageScale(container.current, wrapperRef, planUrl);
  const {
    isDragging: isDraggingRectangle,
    rectangle,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  } = useContainerSelection(rectangleSelection, container.current, itemsRefs, items, onChange, selectionFunction);
  const {
    isDragging: isDraggingItem,
    onItemMove,
    onMouseDown,
  } = useDragItem(rzppState, items, itemsRefs, imageNaturalSize, onChange);

  const isDragging = isDraggingRectangle || isDraggingItem;

  const handleClickOnPlan = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    // To avoid to confuse click with drag
    if (e.clientX < checkForDrag.current + 5 && e.clientX > checkForDrag.current - 5) {
      const { x, y } = calculateClickPercentage(e);
      onClick && onClick(x, y);
    }
  };

  return (
    <Card
      ref={container}
      className={clsx(containerPlanStyle(), className)}
      onMouseDown={e => {
        checkForDrag.current = e.clientX;
        handleMouseDown(e);
      }}
      onMouseMove={e => {
        handleMouseMove(e);
        onItemMove(e);
      }}
      onMouseUp={handleMouseUp}
      id={id}
    >
      <div className={planStyle()}>
        {!loading ? (
          imageScale > 0 && (
            <TransformWrapper
              ref={wrapperRef}
              initialScale={imageScale}
              minScale={imageScale}
              maxScale={imageScale * zoomFactor}
              centerOnInit
              panning={{ disabled: isDragging }}
              doubleClick={{ disabled: true }}
              onTransformed={e => setRzppState(e.state)}
            >
              {utils => (
                <>
                  {displayControl && <Controls {...utils} />}
                  <TransformComponent wrapperClass={planStyle("transform")}>
                    <img
                      src={planUrl}
                      onClick={handleClickOnPlan}
                      style={{ pointerEvents: "all", willChange: "transform", maxWidth: "unset" }}
                    />
                    <Items
                      refs={itemsRefs}
                      items={items}
                      imageScale={imageScale}
                      onChange={onChange}
                      selectionFunction={selectionFunction}
                      onMouseDown={onMouseDown}
                    />
                  </TransformComponent>

                  {displayPreview && (
                    <MiniMap
                      imageUrl={planUrl!}
                      initScale={imageScale}
                      displayedImageWidth={imageNaturalSize.width * imageScale}
                      displayedImageHeight={imageNaturalSize.height * imageScale}
                    />
                  )}
                </>
              )}
            </TransformWrapper>
          )
        ) : (
          <LoaderApp />
        )}
        {isDragging && (
          <div
            className={styles.plan__rectangle}
            style={{
              left: Math.min(rectangle.startX, rectangle.endX),
              top: Math.min(rectangle.startY, rectangle.endY),
              width: Math.abs(rectangle.endX - rectangle.startX),
              height: Math.abs(rectangle.endY - rectangle.startY),
            }}
          />
        )}
      </div>
    </Card>
  );
};
