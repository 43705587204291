import * as React from "react";

import { IconProps } from "./types";

export const CalendarIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M13.1072 2.89285H11.7143V1.96429C11.7143 1.70787 11.5064 1.5 11.25 1.5C10.9936 1.5 10.7857 1.70787 10.7857 1.96429V2.89285H5.21427V1.96429C5.21427 1.70787 5.0064 1.5 4.75001 1.5C4.49361 1.5 4.28572 1.70787 4.28572 1.96429V2.89285H2.89285C2.1236 2.89285 1.5 3.51646 1.5 4.28572V13.1072C1.5 13.8764 2.1236 14.5 2.89285 14.5H13.1072C13.8764 14.5 14.5 13.8764 14.5 13.1072V4.28572C14.5 3.51646 13.8764 2.89285 13.1072 2.89285ZM13.5714 13.1072C13.5714 13.3636 13.3636 13.5715 13.1072 13.5715H2.89285C2.63643 13.5715 2.42856 13.3636 2.42856 13.1072V7.07142H13.5714V13.1072ZM13.5714 6.14286H2.42856V4.28572C2.42856 4.02928 2.63643 3.82142 2.89285 3.82142H4.28572V4.74998C4.28572 5.0064 4.49358 5.21427 4.75001 5.21427C5.00644 5.21427 5.21431 5.0064 5.21431 4.74998V3.82142H10.7857V4.74998C10.7857 5.0064 10.9936 5.21427 11.25 5.21427C11.5064 5.21427 11.7143 5.0064 11.7143 4.74998V3.82142H13.1072C13.3636 3.82142 13.5715 4.02928 13.5715 4.28572L13.5714 6.14286Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default CalendarIcon;
