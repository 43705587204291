import { routeToGeneralSettings } from "~/routes/utils";

import { Navigate, useParams } from "react-router-dom";

export * from "./Settings";
export * from "./pages";

export const Index: React.FC = () => {
  const { houseId } = useParams() as { houseId: string };
  return <Navigate to={routeToGeneralSettings(houseId)} replace />;
};
