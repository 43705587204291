import { intl } from "~/i18n";

export const FORMAT_DATE = {
  boilerRoom: "DD/MM - HH:mm:ss",
  valveLastCom: "ddd DD MMM - HH:mm",
  listDrawerLastCom: "dddd DD MMMM, HH:mm",
  user: "DD/MM/YYYY",
  valveBat: "HH:mm - DD/MM/YYYY",
  valveDetails: "DD/MM/YYYY - HH:mm",
  valveUpdate: "HH:mm - DD/MM/YYYY",
  valveLowBattery: "HH:mm - DD/MM/YYYY",
  valveBatUpdate: "HH:mm - DD/MM/YYYY",
  valveBatChanged: "DD/MM/YYYY",
  valveTige: "DD/MM/YYYY - HH:mm",
  startTestMesh: "DD/MM/YYYY - HH:mm",
  valveExport: "DD/MM/YYYY - HH:mm",
  selectDate: "ddd DD MMM",
  valveProbleme: `DD/MM/YYYY ${intl.formatMessage({ id: "sanityCheck.at" })} HH:mm`,
  boxProbleme: `DD/MM/YYYY ${intl.formatMessage({ id: "sanityCheck.at" })} HH:mm`,
  refreshMaintenance: "HH:mm",
  cascadeHandler: "DD/MM",
  planData: "DD/MM - HH:mm:ss",
  errorPopupBoiler: "DD/MM - HH:mm:ss",
  remoteAccessActivateUntil: "DD/MM/YYYY - HH:mm:ss",
  remoteAccessWillBeActivateUntil: "DD/MM/YYYY - HH:mm:ss",
  batteryHistory: "DD/MM/YYYY HH:mm",
  mecaHistory: "DD/MM/YYYY HH:mm",
  defectHistory: "DD/MM/YYYY HH:mm",
};
