import { utils, writeFile } from "xlsx";

import dayjs from "@eisox/dayjs";
import type {
  GatewayErrors,
  GatewayWarnings,
  RoomErrors,
  RoomWarnings,
  ValveErrors,
  ValveWarnings,
} from "@eisox/problems-handler";
import {
  GATEWAY_ERRORS_ENUM,
  GATEWAY_WARNINGS_ENUM,
  VALVE_ERRORS_ENUM,
  VALVE_WARNINGS_ENUM,
} from "@eisox/problems-handler";

import type { GatewaysWithProblem, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { i18next } from "~/i18n";

interface Rows {
  header: string[];
  rows: (string | number)[][];
}

const getValveRows = (valves: ValvesWithProblem[], includeProblems: boolean): Rows => {
  const allPossibleProblems = Array.from(
    new Set([...valves.flatMap(v => v.errors), ...valves.flatMap(v => v.warnings)]),
  );

  const translation = allPossibleProblems.map(problem => i18next.t(`advancedMaintenance.filters.valves.${problem}`));

  const header = [
    i18next.t("maintenance.exportToCSV.header.uid"),
    i18next.t("maintenance.exportToCSV.header.mac"),
    i18next.t("maintenance.exportToCSV.header.roomName"),
    i18next.t("maintenance.exportToCSV.header.gatewayName"),
    i18next.t("maintenance.exportToCSV.header.gatewayMac"),
    ...(includeProblems ? translation : []),
  ];

  const rows = valves.map(valve => {
    const dates: Partial<Record<ValveErrors | ValveWarnings, string>> = {
      [VALVE_ERRORS_ENUM.LOW_BATTERY]: valve.stateData.batLowDate,
      [VALVE_WARNINGS_ENUM.LOW_BATTERY_WARNING]: valve.stateData.batLowDate,
      [VALVE_ERRORS_ENUM.DISCONNECTED]: valve.updatedAt,
      [VALVE_WARNINGS_ENUM.DISCONNECTED_WARNING]: valve.updatedAt,
      [VALVE_ERRORS_ENUM.DISCONNECTED_GATEWAY]: valve.updatedAt,
      [VALVE_ERRORS_ENUM.LONG_TIME_DISCONNECTED]: valve.updatedAt,
    };

    const problemColumns = includeProblems
      ? allPossibleProblems.map(problem => {
          const isProblemPresent =
            valve.errors.includes(problem as ValveErrors) || valve.warnings.includes(problem as ValveWarnings);
          const date = dates[problem] ?? valve.mecaProblems.find(mp => mp.name === problem)?.updatedAt;
          return isProblemPresent ? (date ? dayjs(date).format("L LTS") : "X") : "";
        })
      : [];

    return [valve.uid, valve.mac ?? "-", valve.roomName, valve.gatewayName, valve.gatewayMac ?? "-", ...problemColumns];
  });

  return { header, rows };
};

const getGatewayRows = (gateways: GatewaysWithProblem[], includeProblems: boolean): Rows => {
  const allPossibleProblems = Array.from(
    new Set([...gateways.flatMap(g => g.errors), ...gateways.flatMap(g => g.warnings)]),
  );

  const translation = allPossibleProblems.map(problem => i18next.t(`advancedMaintenance.filters.gateways.${problem}`));

  const header = [
    i18next.t("maintenance.exportToCSV.header.uid"),
    i18next.t("maintenance.exportToCSV.header.mac"),
    i18next.t("maintenance.exportToCSV.header.name"),
    ...(includeProblems ? translation : []),
  ];

  const rows = gateways.map(gateway => {
    const dates: Partial<Record<GatewayErrors | GatewayWarnings, string>> = {
      [GATEWAY_ERRORS_ENUM.DISCONNECTED]: gateway.lastGatewayActivity,
      [GATEWAY_WARNINGS_ENUM.DISCONNECTED_WARNING]: gateway.lastGatewayActivity,
    };

    const problemColumns = includeProblems
      ? allPossibleProblems.map(problem => {
          if (
            gateway.errors.includes(problem as GatewayErrors) ||
            gateway.warnings.includes(problem as GatewayWarnings)
          ) {
            return dates[problem] ? dayjs(dates[problem]).format("L LTS") : "X";
          }
          return "";
        })
      : [];

    return [gateway.uid ?? "-", gateway.mac ?? "-", gateway.gatewayName ?? "-", ...problemColumns];
  });

  return { header, rows };
};

const getRoomRows = (
  rooms: Pick<RoomsWithProblem, "name" | "errors" | "warnings">[],
  includeProblems: boolean,
): Rows => {
  const allPossibleProblems = Array.from(new Set([...rooms.flatMap(r => r.errors), ...rooms.flatMap(r => r.warnings)]));

  const translation = includeProblems
    ? allPossibleProblems.map(problem => i18next.t(`advancedMaintenance.filters.rooms.${problem}`))
    : [];

  const header = [i18next.t("maintenance.exportToCSV.header.name"), ...translation];

  const rows = rooms.map(room => {
    const problemColumns = includeProblems
      ? allPossibleProblems.map(problem => {
          if (room.errors.includes(problem as RoomErrors) || room.warnings.includes(problem as RoomWarnings)) {
            return "X";
          }
          return "";
        })
      : [];

    return [room.name ?? "-", ...problemColumns];
  });

  return { header, rows };
};

const exportToCSV = (rows: Rows) => {
  const { header, rows: data } = rows;
  const worksheet = utils.aoa_to_sheet([header, ...data]);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Export");
  writeFile(workbook, `data_${dayjs().format("L")}.csv`);
};

export { exportToCSV, getGatewayRows, getRoomRows, getValveRows };
