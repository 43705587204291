import { client } from "../api";
import type { ModuleHistoryRequestBody } from "../types";

const getModuleHistory = async (houseId: string, body: ModuleHistoryRequestBody) => {
  const { data, error } = await client.POST("/history/houses/{houseId}/modules", {
    params: { path: { houseId } },
    body,
  });
  if (error) throw new Error(error.message);
  if (!data?.message) throw new Error();
  return data.message;
};

export { getModuleHistory };
