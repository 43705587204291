import { useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import type { Dayjs } from "@eisox/dayjs";
import { ButtonV2 as Button, Dropdown } from "@eisox/design-system";
import { BracesIcon, ChevronDownIcon, GatewayIcon, PDFIcon, SwapIcon } from "@eisox/icons";

import type { GatewaysWithProblem, HouseType, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { PreReplaceDialog } from "~/features/AdvancedMaintenance";
import type { InterventionPlanState } from "~/features/InterventionPlanTool";
import { ImportDataInterventionPlanTool, InterventionPlanTool } from "~/features/InterventionPlanTool";
import { WifiMeshTestDialog } from "~/features/WifiMeshTest";
import { UserRole } from "~/utils";
import type { WebappRolePermission } from "~/utils";

import styles from "./ActionDropdown.module.scss";

enum MaintenanceDialog {
  WIFI_MESH_TEST,
  INTERVENTION_PLAN,
  IMPORT_INTERVENTION_PLAN,
  PRE_REPLACEMENT,
}

interface ActionDropdownProps {
  house: HouseType;
  valves: ValvesWithProblem[];
  gateways: GatewaysWithProblem[];
  rooms: RoomsWithProblem[];
  role?: WebappRolePermission;
  udpatedAt: Dayjs;
  onRefresh: VoidFunction;
  onClickOnPlan: (planId: string) => void;
  onClickOnRoom: (planId: string, roomId: string) => void;
}

const ActionDropdown: React.FC<ActionDropdownProps> = props => {
  const { t } = useTranslation();
  const { house, valves, gateways, rooms, role, udpatedAt, onRefresh, onClickOnPlan, onClickOnRoom } = props;

  const [dialogOpen, setDialogOpen] = useState<MaintenanceDialog>();
  const [data, setData] = useState<InterventionPlanState | undefined>(undefined);

  const handleCloseDialog = () => setDialogOpen(undefined);

  const handleChangeFile = (file: File) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = e => {
      const data = JSON.parse(e.target?.result as string) as InterventionPlanState;
      //TODO check with yup schema if data is valid
      if (data.houseId !== house._id) return toast.error(t("interventionPlanTool.import.error.houseId"));
      setData(data);
      setDialogOpen(MaintenanceDialog.INTERVENTION_PLAN);
    };
  };

  const handlePreReplace = () => setDialogOpen(MaintenanceDialog.PRE_REPLACEMENT);

  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <Button className={styles.trigger}>
            {t("maintenance.actions.button")}
            <ChevronDownIcon />
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Content className={styles.actionDropdown} align="end">
          {role === UserRole.INSTALLER && (
            <>
              <div className={styles.actionDropdown__section}>
                <h3>{t("maintenance.actions.test.title")}</h3>
                <Dropdown.Close asChild>
                  <Button
                    className={styles.actionDropdown__item}
                    onClick={() => setDialogOpen(MaintenanceDialog.WIFI_MESH_TEST)}
                  >
                    <GatewayIcon />
                    {t("maintenance.actions.test.wifiMesh")}
                  </Button>
                </Dropdown.Close>
              </div>
              <div className={styles.actionDropdown__section}>
                <h3>{t("maintenance.actions.interventions.title")}</h3>
                <Dropdown.Close asChild>
                  <Button
                    className={styles.actionDropdown__item}
                    onClick={() => setDialogOpen(MaintenanceDialog.INTERVENTION_PLAN)}
                  >
                    <PDFIcon />
                    {t("maintenance.actions.interventions.create")}
                  </Button>
                </Dropdown.Close>
                <Dropdown.Close asChild>
                  <Button
                    className={styles.actionDropdown__item}
                    onClick={() => setDialogOpen(MaintenanceDialog.IMPORT_INTERVENTION_PLAN)}
                  >
                    <BracesIcon />
                    {t("maintenance.actions.interventions.import")}
                  </Button>
                </Dropdown.Close>
              </div>
              <div className={styles.actionDropdown__section}>
                <h3>{t("maintenance.actions.replace.title")}</h3>
                <Dropdown.Close asChild>
                  <Button className={styles.actionDropdown__item} onClick={handlePreReplace}>
                    <SwapIcon />
                    {t("maintenance.actions.replace.preReplace")}
                  </Button>
                </Dropdown.Close>
              </div>
            </>
          )}
        </Dropdown.Content>
      </Dropdown.Root>

      {dialogOpen === MaintenanceDialog.WIFI_MESH_TEST && <WifiMeshTestDialog open onOpenChange={handleCloseDialog} />}
      {dialogOpen === MaintenanceDialog.INTERVENTION_PLAN && (
        <InterventionPlanTool open onClose={handleCloseDialog} data={data} />
      )}
      {dialogOpen === MaintenanceDialog.IMPORT_INTERVENTION_PLAN && (
        <ImportDataInterventionPlanTool open onClose={handleCloseDialog} onChange={handleChangeFile} />
      )}
      {dialogOpen === MaintenanceDialog.PRE_REPLACEMENT && (
        <PreReplaceDialog
          open
          role={role}
          onOpenChange={handleCloseDialog}
          house={house}
          valves={valves}
          gateways={gateways}
          rooms={rooms}
          udpatedAt={udpatedAt}
          onRefresh={onRefresh}
          onClickOnPlan={onClickOnPlan}
          onClickOnRoom={onClickOnRoom}
          onPreReplace={handleCloseDialog}
        />
      )}
    </>
  );
};

export { ActionDropdown };
export type { ActionDropdownProps };
