import { useState } from "react";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActionButtonV2 as ActionButton, Modal, TextField } from "@eisox/design-system";
import { NetworkIcon, PencilIcon } from "@eisox/icons";
import { zodResolver } from "@hookform/resolvers/zod";

import { FieldContainer } from "~/UI";
import { useBoilerRoomRealTimeProviderContext } from "~/features/BoilerRooms";

import type { SetpointSchemaType } from "../../helpers";
import { setpointSchema } from "../../helpers";

import styles from "./ECSSetpointDialog.module.scss";

const NAME = "ECSSetpointDialog";

interface ECSSetpointDialogProps {
  boilerRoomId: string;
  heatingNetworkId: string;
  setpointTemperatureEcs?: number;
}

const ECSSetpointDialog: React.FC<ECSSetpointDialogProps> = ({
  boilerRoomId,
  heatingNetworkId,
  setpointTemperatureEcs,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const { useUpdateBoilerRoom } = useBoilerRoomRealTimeProviderContext(NAME);

  const { mutate } = useUpdateBoilerRoom({ onSuccess: () => handleOpenChange(false) });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
  } = useForm<SetpointSchemaType>({
    resolver: zodResolver(setpointSchema),
    defaultValues: { setpointTemperatureEcs: setpointTemperatureEcs },
  });

  const handleOpenChange = (open: boolean) => {
    reset();
    setOpen(open);
  };

  const onSubmit = (data: SetpointSchemaType) => {
    mutate([{ id: boilerRoomId, heatingNetworks: [{ id: heatingNetworkId, ...data }] }]);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild className={styles.trigger}>
        <PencilIcon />
      </Modal.Trigger>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            title={t("network.content.setpointECS.dialog.title")}
            subtitle={t("network.content.setpointECS.dialog.subtitle")}
            icon={<NetworkIcon />}
          >
            <Modal.Close asChild>
              <ActionButton variant="cancel">{t("network.content.setpointECS.dialog.cancel")}</ActionButton>
            </Modal.Close>
            <ActionButton type="submit" rounded disabled={!isDirty}>
              {t("network.content.setpointECS.dialog.save")} <PencilIcon />
            </ActionButton>
          </Modal.Header>
          <FieldContainer
            label={t("network.content.setpointECS.dialog.setpoint")}
            error={errors.setpointTemperatureEcs}
          >
            <div className={styles.ecsSetpointDialog__fieldContainer}>
              <TextField.Root
                className={styles.ecsSetpointDialog__input}
                type="number"
                {...register("setpointTemperatureEcs")}
              />
              <span>°C</span>
            </div>
          </FieldContainer>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};

ECSSetpointDialog.displayName = NAME;

export { ECSSetpointDialog };
export type { ECSSetpointDialogProps };
