import { cloneElement, useEffect, useRef, useState } from "react";
import React from "react";

import clsx from "clsx";

import { Circle } from "@eisox/design-system";
import { composeRefs, useBem } from "@eisox/tools";

import styles from "./RadialMenu.module.scss";

interface ChildProps {
  className?: string;
  onClick?: () => void;
  ref?: React.RefObject<HTMLDivElement>;
}

interface CircularMenuProps {
  items: React.ReactElement[];
  radius?: number;
  children: React.ReactElement<ChildProps>;
  openningCondition?: boolean;
}

export const RadialMenu: React.FC<CircularMenuProps> = ({ items, children, radius = 50, openningCondition = true }) => {
  const bem = useBem(styles);

  const radialMenuStyle = bem("radial-menu");

  const [isOpen, setIsOpen] = useState(false);
  const [renderedItems, setRenderedItems] = useState(false);

  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (isOpen) {
      setRenderedItems(true);
    } else {
      const timer = setTimeout(() => setRenderedItems(false), 500); // Durée de l'animation
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (renderedItems) {
      itemRefs.current.forEach((item, index) => {
        if (item) {
          const angle = (index / items.length) * 2 * Math.PI - Math.PI / 2;
          const x = radius * Math.cos(angle) - item.offsetWidth / 2;
          const y = radius * Math.sin(angle) - item.offsetHeight / 2;
          item.style.transform = isOpen ? `translate(${x}px, ${y}px)` : `translate(-50%, -50%)`;
          item.style.transitionDelay = `${(isOpen ? index : items.length - index) * (0.2 / items.length)}s`;
          item.style.opacity = isOpen ? "1" : "0";
        }
      });
    }
  }, [isOpen, renderedItems, items.length, radius]);

  return (
    <div className={radialMenuStyle()}>
      <Circle size={isOpen ? radius * 2 : 0} className={radialMenuStyle("circle")} />
      {React.isValidElement(children) &&
        cloneElement(children, {
          onClick: () => {
            children.props.onClick && children.props.onClick();
            openningCondition && setIsOpen(prev => !prev);
          },
          className: clsx(children.props.className, radialMenuStyle("central-element")),
        })}

      {renderedItems &&
        items.map((item, index) =>
          cloneElement(item, {
            key: index,
            className: clsx(item.props.className, radialMenuStyle("menu-item")),
            ref: (element: HTMLDivElement) => (itemRefs.current[index] = element),
          }),
        )}
    </div>
  );
};
