import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import styles from "./Loader.module.scss";

type LoaderProps = React.HTMLAttributes<HTMLDivElement>;

const Loader = forwardRef<HTMLDivElement, LoaderProps>(({ className, ...props }, forwardedRef) => (
  <div {...props} ref={forwardedRef} className={cx(styles.loader, className)}>
    <span />
  </div>
));

export default Loader;
export { Loader };
export type { LoaderProps };
