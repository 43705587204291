import * as React from "react";

import { IconProps } from "./types";

export const TickIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M14.2618 4.48856C14.4143 4.33602 14.5 4.12912 14.5 3.9134C14.5 3.69768 14.4143 3.49078 14.2618 3.33824C14.1092 3.1857 13.9023 3.1 13.6866 3.1C13.4708 3.1 13.264 3.1857 13.1115 3.33824L5.56289 10.8884L2.88856 8.21247C2.73602 8.05992 2.52912 7.97423 2.3134 7.97423C2.09767 7.97423 1.89078 8.05992 1.73824 8.21247C1.5857 8.36501 1.5 8.57191 1.5 8.78763C1.5 9.00335 1.5857 9.21025 1.73824 9.36279L4.98773 12.6123C5.06319 12.6879 5.15284 12.748 5.25153 12.7889C5.35022 12.8299 5.45603 12.851 5.56289 12.851C5.66974 12.851 5.77555 12.8299 5.87424 12.7889C5.97293 12.748 6.06258 12.6879 6.13804 12.6123L14.2618 4.48856Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default TickIcon;
