import * as React from "react";

import type { IconProps } from "./types";

export const CheckedIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" />
      <path d="M4 8L7 11L12 5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  ),
);

export default CheckedIcon;
