import * as React from "react";

import { IconProps } from "./types";

export const DashboardIconIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <g clipPath="url(#clip0_30_121)">
        <path
          d="M1.92307 1.92307C2.19395 1.65218 2.56136 1.5 2.94444 1.5H5.83333C6.21642 1.5 6.58382 1.65218 6.85471 1.92307C7.1256 2.19395 7.27778 2.56136 7.27778 2.94444V6.55556C7.27778 6.93864 7.1256 7.30605 6.85471 7.57693C6.58382 7.84782 6.21642 8 5.83333 8H2.94444C2.56136 8 2.19395 7.84782 1.92307 7.57693C1.65218 7.30605 1.5 6.93864 1.5 6.55556V2.94444C1.5 2.56136 1.65218 2.19395 1.92307 1.92307ZM5.83333 2.94444H2.94444V6.55556H5.83333V2.94444ZM9.1453 1.92307C9.41621 1.65218 9.7836 1.5 10.1667 1.5H13.0556C13.4386 1.5 13.806 1.65218 14.0769 1.92307C14.3478 2.19396 14.5 2.56136 14.5 2.94444V4.38889C14.5 4.77198 14.3478 5.13938 14.0769 5.41026C13.806 5.68115 13.4386 5.83333 13.0556 5.83333H10.1667C9.7836 5.83333 9.41621 5.68115 9.1453 5.41026C8.87439 5.13938 8.72222 4.77198 8.72222 4.38889V2.94444C8.72222 2.56136 8.87439 2.19395 9.1453 1.92307ZM13.0556 2.94444H10.1667V4.38889H13.0556V2.94444ZM9.1453 7.70085C9.41621 7.42996 9.7836 7.27778 10.1667 7.27778H13.0556C13.4386 7.27778 13.806 7.42996 14.0769 7.70085C14.3478 7.97173 14.5 8.33913 14.5 8.72222V13.0556C14.5 13.4386 14.3478 13.806 14.0769 14.0769C13.806 14.3478 13.4386 14.5 13.0556 14.5H10.1667C9.7836 14.5 9.41621 14.3478 9.1453 14.0769C8.87439 13.806 8.72222 13.4386 8.72222 13.0556V8.72222C8.72222 8.33913 8.87439 7.97173 9.1453 7.70085ZM13.0556 8.72222H10.1667V13.0556H13.0556V8.72222ZM1.92307 9.86752C2.19395 9.59662 2.56136 9.44444 2.94444 9.44444H5.83333C6.21642 9.44444 6.58382 9.59662 6.85471 9.86752C7.1256 10.1384 7.27778 10.5058 7.27778 10.8889V13.0556C7.27778 13.4386 7.1256 13.806 6.85471 14.0769C6.58382 14.3478 6.21642 14.5 5.83333 14.5H2.94444C2.56136 14.5 2.19396 14.3478 1.92307 14.0769C1.65218 13.806 1.5 13.4386 1.5 13.0556V10.8889C1.5 10.5058 1.65218 10.1384 1.92307 9.86752ZM5.83333 10.8889H2.94444V13.0556H5.83333V10.8889Z"
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_30_121">
          <rect width="13" height="13" transform="translate(1.5 1.5)" />
        </clipPath>
      </defs>
    </svg>
  ),
);

export default DashboardIconIcon;
