import clsx from "clsx";
import { useIntl } from "react-intl";

import styles from "./SummerWinterScheme.module.scss";

interface SummerWinterSchemeProps {
  summerStart: string;
  summerEnd: string;
  winterStart: string;
  winterEnd: string;
  className?: string;
}

export const SummerWinterScheme: React.FC<SummerWinterSchemeProps> = ({
  summerStart,
  summerEnd,
  winterStart,
  winterEnd,
  className,
}) => {
  const { formatMessage } = useIntl();

  const formatDate = (date: string) => (date ? date.replace("-", "/") : "--/--");

  return (
    <div className={clsx(styles.summerWinterScheme, className)}>
      <p className={styles.summerWinterScheme__date}>{formatDate(summerStart)}</p>
      <p className={styles.summerWinterScheme__season}>
        {formatMessage({ id: "settings.content.menu.houses.season.summerWinter.sheme.summer" })}
      </p>
      <p className={styles.summerWinterScheme__date}>{formatDate(summerEnd)}</p>
      <div className={styles.summerWinterScheme__separator} />
      <p className={styles.summerWinterScheme__date}>{formatDate(winterStart)}</p>
      <p className={styles.summerWinterScheme__season}>
        {formatMessage({ id: "settings.content.menu.houses.season.summerWinter.sheme.winter" })}
      </p>
      <p className={styles.summerWinterScheme__date}>{formatDate(winterEnd)}</p>
    </div>
  );
};
