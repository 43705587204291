import { z } from "@eisox/zod";

const DEFAULT_LOW_SET_TEMPERATURE = 60;
const DEFAULT_HIGH_SET_TEMPERATURE = 80;

const schema = z.object({
  name: z.string(),
  lowSetpointTemperature: z.number(),
  highSetpointTemperature: z.number(),
});

type SchemaType = z.infer<typeof schema>;

export { DEFAULT_HIGH_SET_TEMPERATURE, DEFAULT_LOW_SET_TEMPERATURE, schema };
export type { SchemaType };
