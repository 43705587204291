import { client } from "../api";
import type { DeleteNotificationRequestBody, PutNotificationRequestBody } from "../types";

const removeNotification = async (houseId: string, body: DeleteNotificationRequestBody) => {
  const { error } = await client.DELETE("/houses/{houseId}/notifications/", { params: { path: { houseId } }, body });
  if (error) throw new Error(error.message);
};

const putNotification = async (houseId: string, body: PutNotificationRequestBody) => {
  const { error } = await client.PUT("/houses/{houseId}/notifications/", { params: { path: { houseId } }, body });
  if (error) throw new Error(error.message);
};

export { removeNotification, putNotification };
