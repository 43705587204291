import type { PropsWithChildren } from "react";

import { useIntl } from "react-intl";

import { useBem } from "@eisox/tools";

import type { ItemType } from "~/UI/layouts/PlanV2/components/Items";

import { Item, ItemCard } from "../ItemCard";
import type { ItemOnPlan, PlanFilters } from "../Plan";
import { Plan } from "../Plan";
import { SearchInput } from "../SearchInput";

import styles from "./DialogContent.module.scss";

interface DialogContentProps extends PropsWithChildren {
  ressource: "valve" | "gateway" | "room" | "boilerroom";
  sorts: string[];
  currentItemId?: string;
  items: { id: string; title: string; subtitle: string; onClick: VoidFunction; selected: boolean }[];
  itemsOnPlan: ItemOnPlan[];
  planFilters?: PlanFilters;
  onChangeFilters: (search: string, sort: string) => void;
  onChangeItemsOnPlan: (items: ItemType[]) => void;
  handleItemHovered: (itemId: string | undefined) => void;
}

export const DialogContent: React.FC<DialogContentProps> = ({
  ressource,
  sorts,
  currentItemId,
  items,
  itemsOnPlan,
  planFilters,
  onChangeFilters,
  onChangeItemsOnPlan,
  handleItemHovered,
  children,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const contentStyle = bem("content");
  const rightStyle = bem("right");

  return (
    <div className={contentStyle()}>
      <Plan currentItemId={currentItemId} items={itemsOnPlan} filters={planFilters} onChange={onChangeItemsOnPlan} />
      <div className={rightStyle()}>
        <SearchInput
          sorts={sorts}
          placeholder={formatMessage({ id: `plan.actions.move.${ressource}.search.placeholder` })}
          onChange={onChangeFilters}
        />
        <div className={rightStyle("list")}>
          {items.map((i, k) => (
            <Item
              key={k}
              ressource={ressource}
              title={i.title}
              subtitle={i.subtitle}
              onClick={i.onClick}
              selected={i.selected}
              onMouseEnter={() => handleItemHovered(i.id)}
              onMouseLeave={() => handleItemHovered(undefined)}
            />
          ))}
        </div>
        <ItemCard ressource={ressource} {...items.find(i => i.id === currentItemId)}>
          {children}
        </ItemCard>
      </div>
    </div>
  );
};
