import { useIntl } from "react-intl";

import { Circle, Modal } from "@eisox/design-system";
import { ActionButton } from "@eisox/design-system";
import { BoilerRoomIcon, NetworkIcon, PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./Header.module.scss";

interface HeaderProps {
  title: string;
  network: boolean;
  isDirty: boolean;
  history?: boolean;
}

export const Header: React.FC<HeaderProps> = ({ title, network, isDirty, history = false }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const headerStyle = bem("header");

  return (
    <Modal.Header
      title={title}
      subtitle={formatMessage({ id: "boilerRoom.dialog.network.header.subtitle" })}
      icon={
        <Circle size={70} className={headerStyle("logo")}>
          {network ? <NetworkIcon /> : <BoilerRoomIcon className={headerStyle("boilerroom")} />}
        </Circle>
      }
    >
      <Modal.Close asChild>
        <ActionButton
          className={headerStyle("button")}
          variant="cancel"
          text={formatMessage({ id: "boilerRoom.dialog.network.header.cancel" })}
        />
      </Modal.Close>
      <ActionButton
        type="submit"
        text={formatMessage({ id: "boilerRoom.dialog.network.header.save" })}
        icon={<PencilIcon />}
        disabled={!isDirty || history}
      />
    </Modal.Header>
  );
};
