import type { PropsWithChildren } from "react";
import { useState } from "react";

import i18next from "i18next";
import { orderBy } from "lodash";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import type { UnitTypeShort } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import { Circle, Dialog } from "@eisox/design-system";
import { TargetIcon } from "@eisox/icons";
import type { DefectType, Language, ObjectType } from "@eisox/problems-handler";
import { translateDefect } from "@eisox/problems-handler";
import { useBem } from "@eisox/tools";
import { useQuery } from "@tanstack/react-query";

import type { DefectHistory } from "~/apiV2";
import { queries } from "~/apiV2";
import { HISTORY } from "~/constants";
import { FORMAT_DATE } from "~/constants/timeConstants";
import { routeToBoilerroom } from "~/routes/utils";

import { Popup } from "../Popup";
import { Table } from "../Table";

import styles from "./DefectHistoryPopup.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
interface Content {
  data: DefectHistory[];
  houseId: string;
  boilerroomId: string;
}

const Content: React.FC<Content> = ({ data, houseId, boilerroomId }) => {
  const bem = useBem(styles);
  const contentStyle = bem("content");

  const { t } = useTranslation();

  const navigate = useNavigate();

  const transformedData = data.map(item => ({
    ...item,
    location: `${item.boilerroomName}/${item.boilerName ?? item.heatingNetworkName ?? "-"}`,
    message: translateDefect({
      defectType: item.defectType as DefectType,
      objectType: item.objectType as ObjectType,
      language: i18next.language as Language,
    }),
  }));

  return transformedData.length > 0 ? (
    <Table
      className={contentStyle("table")}
      data={transformedData}
      initialState={{
        sorting: [{ id: "begin", desc: true }],
      }}
      columns={[
        {
          accessorKey: "begin",
          header: t("boilerRoom.defectHistory.begin"),
          cell: ({
            row: {
              original: { begin },
            },
          }) => (begin ? dayjs(begin).format(FORMAT_DATE.defectHistory) : "-"),
        },
        {
          accessorKey: "end",
          header: t("boilerRoom.defectHistory.end"),
          cell: ({
            row: {
              original: { end },
            },
          }) =>
            end ? (
              dayjs(end).format(FORMAT_DATE.defectHistory)
            ) : (
              <div className={contentStyle("active-error")}>
                <Circle size={10} />
                {t("boilerRoom.defectHistory.activeError")}
              </div>
            ),
        },
        {
          accessorKey: "location",
          header: t("boilerRoom.defectHistory.location"),
          cell: ({
            row: {
              original: { location, boilerroomId: currentBoilerroomId },
            },
          }) =>
            boilerroomId === currentBoilerroomId ? (
              <Dialog.Close asChild>
                <div className={contentStyle("location")}>
                  {location}
                  <TargetIcon />
                </div>
              </Dialog.Close>
            ) : (
              <Link className={contentStyle("location")} to={routeToBoilerroom(houseId, boilerroomId)}></Link>
            ),
        },
        {
          accessorKey: "message",
          header: t("boilerRoom.defectHistory.description"),
          enableSorting: false,
        },
      ]}
    />
  ) : (
    t("boilerRoom.defectHistory.error.notDataFound")
  );
};

/* -------------------------------------------------------------------------------------------------
 * DefectHistoryPopup
 * -----------------------------------------------------------------------------------------------*/
interface DefectHistoryPopupProps extends PropsWithChildren {
  houseId: string;
  boilerroomId: string;
}

const DefectHistoryPopup: React.FC<DefectHistoryPopupProps> = ({ houseId, boilerroomId, children }) => {
  const bem = useBem(styles);
  const defectHistoryPopupStyle = bem("defect-history-popup");

  const { t } = useTranslation();

  const [range, setRange] = useState<Popup.FormValues["dateRange"]>({
    from: dayjs()
      .subtract(parseInt(HISTORY.DEFECT_BOILERROOM.initial[0]), HISTORY.DEFECT_BOILERROOM.initial[1] as UnitTypeShort)
      .toDate(),
    to: dayjs().toDate(),
  });

  const { data } = useQuery({
    ...queries.history.defect(range.from.toISOString(), range.to.toISOString(), houseId),
    staleTime: dayjs(range.to).isBefore(dayjs()) ? Infinity : 0,
  });

  const handleSubmit = (data: Popup.FormValues) => setRange(data.dateRange);

  const orderedData = orderBy(data, "createdAt", "desc");

  return (
    <Popup.Root>
      <Popup.Trigger asChild>{children}</Popup.Trigger>
      <Popup.Content
        limit={HISTORY.DEFECT_BOILERROOM.limit}
        inital={HISTORY.DEFECT_BOILERROOM.initial}
        handleSubmit={handleSubmit}
        title={t("boilerRoom.defectHistory.title")}
        className={defectHistoryPopupStyle()}
      >
        <Content data={orderedData} houseId={houseId} boilerroomId={boilerroomId} />
      </Popup.Content>
    </Popup.Root>
  );
};

export { DefectHistoryPopup };
export type { DefectHistoryPopupProps };
