import { useMemo, useState } from "react";

import { useIntl } from "react-intl";

import { primary } from "@eisox/colors";
import { ActionButton, Dialog, TextInput } from "@eisox/design-system";
import { BinIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import LoaderApp from "~/components/LoaderApp";
import { useAction } from "~/hooks";
import { API } from "~/types/API";

import { generateRandomCaptcha } from "../../screens/Settings/utils";

import styles from "./DeletePopup.module.scss";

interface DeletePopupProps {
  open: boolean;
  onClose: VoidFunction;
  elementToDelete: string;
  text?: string;
  route: string;
}

/**
 * @deprecated use Alert dialog (@eisox/design-system) instead
 */
export const DeletePopup: React.FC<DeletePopupProps> = ({ open, onClose, elementToDelete, text, route }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const deletePopupStyle = bem("delete-popup");
  const contentStyle = bem("content");
  const captchaStyle = bem("captcha");
  const footerStyle = bem("footer");

  const { submit, state } = useAction<string>({
    onSuccess: () => onClose && onClose(),
  });

  const captcha = useMemo(() => generateRandomCaptcha(4), []);

  const [value, setValue] = useState("");

  const onSubmit = () => {
    submit(null, API.HTTP_METHOD.DELETE, route);
  };

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose()}>
      <Dialog.Content
        className={deletePopupStyle()}
        title={formatMessage({ id: "settings.content.delete.title" }, { h: elementToDelete })}
        icon={<BinIcon color={primary.red} className={deletePopupStyle("icon")} />}
      >
        {state === "submitting" ? (
          <LoaderApp />
        ) : (
          <>
            <div className={contentStyle()}>
              {text && <p className={contentStyle("text")}>{text}</p>}
              <div className={captchaStyle()}>
                <p className={captchaStyle("captcha")}>{captcha}</p>
                <TextInput
                  className={captchaStyle("input")}
                  label={formatMessage({ id: "settings.content.delete.code" })}
                  placeholder={formatMessage({ id: "settings.content.delete.placeholder" })}
                  value={value}
                  onChange={e => setValue(e.target.value)}
                />
              </div>
            </div>
            <div className={footerStyle()}>
              <ActionButton
                variant="cancel"
                text={formatMessage({ id: "settings.content.delete.cancel" })}
                onClick={() => onClose && onClose()}
              />
              <ActionButton
                variant="danger"
                text={formatMessage({ id: "settings.content.delete.delete" })}
                onClick={onSubmit}
                disabled={value !== captcha}
              />
            </div>
          </>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};
