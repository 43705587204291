import { Divider } from "@eisox/design-system";

import { HistorySliderV2 } from "~/UI/components";

import { useBoilerRoomHistoryProvider } from "../../providers/HistoryProvider";

import styles from "./HistorySlider.module.scss";

const NAME = "HistorySlider";

const HistorySlider: React.FC = () => {
  const { dates, onDateChange } = useBoilerRoomHistoryProvider(NAME);

  return (
    <div className={styles.historySlider}>
      <Divider />
      <HistorySliderV2 sortedDates={dates} onDateCommit={onDateChange} />
    </div>
  );
};

HistorySlider.displayName = NAME;

export { HistorySlider };
