import { LatLng } from "leaflet";

import { Map as MapFC } from "~/UI/components";

import type { HousePosition } from "../../helpers";

import styles from "./Map.module.scss";

interface MapProps {
  housePositions: HousePosition[];
  housePositionsForZoom: HousePosition[];
  onPositionClick: (position: HousePosition) => void;
}

export const Map: React.FC<MapProps> = ({ housePositions, housePositionsForZoom, onPositionClick }) => {
  const positions = housePositions.map(m => ({
    name: m.houseName,
    position: new LatLng(m.position[0], m.position[1]),
    onClick: () => onPositionClick(m),
  }));

  const positionsForZoom = housePositionsForZoom.map(m => ({
    position: new LatLng(m.position[0], m.position[1]),
  }));

  return <MapFC className={styles.map} positions={positions} positionsForZoom={positionsForZoom} />;
};
