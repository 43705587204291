import * as React from "react";

import { IconProps } from "./types";

export const FolderIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M2.8 2.8H6.7L8 4.1H13.2C13.915 4.1 14.5 4.685 14.5 5.4V11.9C14.5 12.615 13.915 13.2 13.2 13.2H2.8C2.085 13.2 1.5 12.615 1.5 11.9L1.5065 4.1C1.5065 3.385 2.085 2.8 2.8 2.8ZM7.4605 5.40001L6.1605 4.10001H2.8V11.9H13.2V5.40001H7.4605Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default FolderIcon;
