// primary.ts
export const primary = {
  white: "#ffffff",
  gray: "#f3f3f9",
  darkerGray: "#d0d0d0",
  darkGray: "#828282",
  blue: "#1100fa",
  darkBlue: "#122138",
  green: "#27ae60",
  red: "#fa0000",
  orange: "#fa7800",
  warning: "#ffc302",
};

// alert.ts
export const alert = {
  warning: "#ffc302",
};

// planning.ts
export const planning = {
  absence: "#dddbff",
  preComfort: "#ffe085",
  comfort: "#f6b870",
  night: "#b6b3ff",
  frostFree: "#b3daff",
};

// water.ts
export const water = {
  cold: "#087bbe",
  hot: "#ff810a",
};

// temperature.ts
export const temperature = {
  cold: "#0066ff",
  medium: "#ffa800",
  hot: "#ff0600",
};

// humidity.ts
export const humidity = {
  dry: "#eccc5d",
  good: "#01b5dd",
  wet: "#455eb7",
};

// airQuality.ts
export const airQuality = {
  perfect: "#32b541",
  good: "#15b325",
  average: "#e79a00",
  worse: "#e37101",
  bad: "#ce0404",
  veryBad: "#710139",
};

// light.ts
export const light = {
  dark: "#8b773f",
  medium: "#ffd577",
  bright: "#fff7c6",
};

// opening.ts
export const openning = {
  closed: "#d099fa",
  half: "#9c2bf4",
  totally: "#6609b0",
};

// role.ts
export const role = {
  installer: "#fa7800",
  owner: "#27ae60",
  manager: "#2f80ed",
  visitor: "#fa00b4",
  api: "#00affa",
};

// maintenance.ts
export const maintenance = {
  restartValve: "#0070c0",
  verifyGateway: "#ffeb9c",
  reviewValve: "#00b050",
  changeBattery: "#ffc000",
  replace: "#ff0000",
  uncloCheckRadioTransmission: "#ffc7ce",
  toInstall: "#ffff00",
};

// chart.ts
export const chart = {
  orange: "#E1A624",
  blue: "#317AC1",
  darkGray: "#384454",
  lightGray: "#D4D3DC",
  brown: "#AD956B",
  purple: "#B085C2",
};
