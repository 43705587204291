import { FieldErrors } from "react-hook-form";
import { useIntl } from "react-intl";

import { Typography } from "@eisox/design-system";
import { BinIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";

import { GroupDto } from "../../Groups";

import styles from "./Group.module.scss";

interface GroupProps {
  name: string;
  onClick: VoidFunction;
  onDelete: VoidFunction;
  selected?: boolean;
  errors?: FieldErrors<GroupDto>;
}

export const Group: React.FC<GroupProps> = ({ name, onClick, onDelete, selected = false, errors }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const groupStyle = bem("group");
  const iconsStyle = bem("icons");

  const handleClickBin = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    onDelete();
  };

  const isOnError = !!errors?.name || !!errors?.rooms;
  const errorMessage =
    errors &&
    Object.keys(errors)
      .filter(key => !!errors[key as keyof FieldErrors<GroupDto>]?.message)
      .map(key => errors[key as keyof FieldErrors<GroupDto>]?.message)
      .join("\n");

  return (
    <div
      className={groupStyle({ placeholder: name === "", selected: selected, error: isOnError })}
      onClick={() => onClick()}
    >
      <Typography>
        {name !== "" ? name : formatMessage({ id: "settings.content.menu.rooms.groups.name.placeholder" })}
      </Typography>
      <span className={iconsStyle()}>
        <BinIcon className={iconsStyle("bin")} onClick={handleClickBin} />
        {errors && (
          <Tooltip content={errorMessage} keepHoverOpen={false}>
            <div className={iconsStyle("error")} />
          </Tooltip>
        )}
      </span>
    </div>
  );
};
