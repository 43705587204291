import type { HouseUsers, RolesInHouses, UpdateRolesRequestBody } from "~/apiV2";
import { UserRole } from "~/utils/User";

//! WARNING IF YOU CHANGE THIS IMPORT, TEST U CRASH
import type { Filters, User } from "./types";

const getUserRoles = (roles: NonNullable<RolesInHouses[number]["users"]>[number]["roles"]) => {
  const userRoles: UserRole[] = [];
  for (const key in roles) {
    if (Object.prototype.hasOwnProperty.call(roles, key)) {
      const value = roles[key as keyof NonNullable<RolesInHouses[number]["users"]>[number]["roles"]];
      if (value && Object.values(UserRole).includes(key as UserRole)) {
        userRoles.push(key as UserRole);
      }
    }
  }
  return userRoles;
};

const getPeriodicity = (userId: string, houseId: string, houseUsers: HouseUsers): "m" | "w" | undefined =>
  houseUsers.find(h => h._id === userId)?.notifs?.find(n => n.houseId === houseId && n.type === "maintenance")
    ?.periodicity;

const getAlert = (userId: string, houseId: string, houseUsers: HouseUsers) =>
  (houseUsers.find(h => h._id === userId)?.notifs?.find(n => n.houseId === houseId && n.type === "gtb")?.methods as
    | ("email" | "sms")[]
    | undefined) ?? [];

const getUsers = (houseId: string, rolesInHouses: RolesInHouses, houseUsers: HouseUsers): User[] => {
  const users = rolesInHouses.find(r => r._id === houseId)?.users;
  if (!users) return [];
  return users.map(u => ({
    id: u._id!,
    firstName: u.firstName,
    lastName: u.lastName,
    email: u.emails?.[0].address!,
    phone: u.phone ? { number: u.phone.number!, verified: u.phone.verified ?? false } : undefined,
    roles: getUserRoles(u.roles),
    additionDate: u.dateOfGrant !== undefined ? new Date(u.dateOfGrant) : undefined,

    maintenanceEmail: getPeriodicity(u._id!, houseId, houseUsers) ?? "disabled",

    alert: getAlert(u._id!, houseId, houseUsers),
  }));
};

const getUpdateRoleRequestBody = (
  role: Partial<Record<UserRole, boolean>>,
  houseIds: string[],
): UpdateRolesRequestBody => {
  const userRoles: UpdateRolesRequestBody = [];
  houseIds.forEach(houseId => {
    const userRolesInHouse: UpdateRolesRequestBody[number] = { houseId };
    Object.keys(role).forEach(key => {
      const userRole = `is${key.charAt(0).toUpperCase() + key.slice(1)}` as Exclude<
        keyof typeof userRolesInHouse,
        "houseId"
      >;
      userRolesInHouse[userRole] = role[key as UserRole];
    });
    userRoles.push(userRolesInHouse);
  });
  return userRoles;
};

const filterUsers = (users: User[], filters: Filters): User[] => {
  let filteredUsers = [...users];
  if (filters.roles.length) {
    filteredUsers = filteredUsers.filter(u =>
      u.roles.every(
        r =>
          filters.roles.includes(r) || (filters.roles.includes(UserRole.MANAGER) && r === UserRole.MANAGER_BOILERROOM),
      ),
    );
  }
  if (filters.search) {
    const text = filters.search.toUpperCase();
    filteredUsers = filteredUsers.filter(
      u =>
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        u.firstName?.toUpperCase().includes(text) ||
        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        u.lastName?.toUpperCase().includes(text) ||
        u.email.toUpperCase().includes(text) ||
        u.roles.some(r => r.toUpperCase().includes(text)),
    );
  }
  return filteredUsers;
};

const isEmailExistingInAnotherHouse = (email: string, housesWhereAdded: string[], houses: RolesInHouses): boolean =>
  houses
    .filter(h => housesWhereAdded.includes(h._id!))
    .flatMap(h => h.users?.flatMap(u => u.emails?.map(e => e.address)) ?? [])
    .includes(email);

const isEmailUnique = (existingEmails: string[], email: string): boolean =>
  !existingEmails.map(e => e.toUpperCase()).includes(email.toUpperCase());

export const getMaxUserRole = (roles: UserRole[]): UserRole => {
  if (roles.includes(UserRole.OWNER)) return UserRole.OWNER;
  if (roles.includes(UserRole.INSTALLER)) return UserRole.INSTALLER;
  if (roles.includes(UserRole.API)) return UserRole.API;
  if (roles.includes(UserRole.MANAGER_BOILERROOM)) return UserRole.MANAGER_BOILERROOM;
  if (roles.includes(UserRole.MANAGER)) return UserRole.MANAGER;
  return UserRole.VISITOR;
};

const getUserRolesByHouse = (userId: string, rolesInHouses: RolesInHouses) =>
  rolesInHouses
    .filter(r => r.users?.map(u => u._id).includes(userId))
    .map(r => ({
      houseId: r._id!,
      houseName: r.houseName ?? "-",
      role: getMaxUserRole(getUserRoles(r.users?.find(u => u._id === userId)?.roles)),
    }));

interface UpdateUserDialogFormType {
  roles: (ReturnType<typeof getUserRolesByHouse>[number] & {
    deleted: boolean;
    add: boolean;
  })[];
}

const getUserRolesModifications = (
  modifications: UpdateUserDialogFormType["roles"],
  initialState: UpdateUserDialogFormType["roles"],
) => {
  const roles: UpdateRolesRequestBody = [];

  // Role changes
  const roleChanges = modifications.reduce((acc: number[], currentValue, index) => {
    if (!currentValue.deleted && !currentValue.add) acc.push(index);
    return acc;
  }, []);
  roles.push(
    ...roleChanges.flatMap(rc =>
      getUpdateRoleRequestBody({ [modifications[rc].role]: true, [initialState[rc].role]: false }, [
        modifications[rc].houseId,
      ]),
    ),
  );

  // Role deletion/addition
  roles.push(
    ...modifications
      .filter(m => m.deleted || m.add)
      .flatMap(m => getUpdateRoleRequestBody({ [m.role]: m.add }, [m.houseId])),
  );

  return roles;
};

export {
  filterUsers,
  getUpdateRoleRequestBody,
  getUserRolesByHouse,
  getUserRolesModifications,
  getUsers,
  isEmailExistingInAnotherHouse,
  isEmailUnique,
};
export type { UpdateUserDialogFormType };
