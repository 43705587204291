import type { InterventionPlanState } from "../../providers";
import { InterventionPlanToolProvider } from "../../providers";
import { Dialog } from "./components";

interface InterventionPlanToolProps {
  open: boolean;
  onClose: VoidFunction;
  data?: InterventionPlanState;
}

export const InterventionPlanTool: React.FC<InterventionPlanToolProps> = ({ data, ...props }) => {
  return (
    <InterventionPlanToolProvider data={data}>
      <Dialog {...props} />
    </InterventionPlanToolProvider>
  );
};
