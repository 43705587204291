/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StatusCodes } from "http-status-codes";

import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";
import type { Module } from "../types";

const getModules = async (houseId: string): Promise<Module[]> => {
  const { data, error, response } = await client.GET("/houses/{houseId}/modules", {
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) return [];
  else if (error || !data?.message) throw new Error(error ?? "Error fetching modules");

  return data.message.map(m => ({
    ...m,
    id: m.id!,
    type: m.type!,
    houseId: m.houseId!,
    gatewayId: m.gatewayId!,
    operatorProtocolType: m.operatorProtocolType!,
    operatorPort: m.operatorPort!,
    port: m.port!,
    isSsl: m.isSsl!,
    url: m.url!,
  }));
};

export const module = createQueryKeys("module", {
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getModules(houseId),
  }),
});
