import {
  LOW_FROST_FREE_TEMP,
  ROOM_ERRORS_ENUM,
  ROOM_TEMP_PROBLEMS_ENUM,
  ROOM_WARNINGS_ENUM,
} from "@eisox/problems-handler";

import type { HouseType, RoomsWithProblem } from "~/UI/screens/House";
import { intl } from "~/i18n";

const TEMP_PROBLEMS_TRANSLATION = {
  [ROOM_TEMP_PROBLEMS_ENUM.FROST_FREE]: (h: HouseType) =>
    intl.formatMessage({ id: "rooms.tempProblems.frostFree" }, { t: h.frostFreeTemperature - LOW_FROST_FREE_TEMP }),
  [ROOM_TEMP_PROBLEMS_ENUM.HIGH_TEMP]: () => intl.formatMessage({ id: "rooms.errors.highTemp" }),
  [ROOM_TEMP_PROBLEMS_ENUM.LOW_TEMP]: () => intl.formatMessage({ id: "rooms.errors.lowTemp" }),
};

/* ERRORS */
const ROOM_ERRORS_TRANSLATION = Object.assign(TEMP_PROBLEMS_TRANSLATION, {
  [ROOM_ERRORS_ENUM.VALVE_ERROR]: () => intl.formatMessage({ id: "rooms.errors.valveError" }),
});

/* WARNINGS */
const ROOM_WARNINGS_TRANSLATION = Object.assign(TEMP_PROBLEMS_TRANSLATION, {
  [ROOM_WARNINGS_ENUM.NO_VALVE]: () => intl.formatMessage({ id: "rooms.warnings.noValve" }),
  [ROOM_WARNINGS_ENUM.VALVE_WARNING]: () => intl.formatMessage({ id: "rooms.warnings.valveWarning" }),
});

/**
 * Retrieves the translated problem messages for a given room.
 *
 * @param room - The room object containing the problem messages.
 * @param house - The house object containing the translation data.
 * @returns An object containing the translated error and warning messages for the room.
 */
export const getRoomProblemTranslation = (room: RoomsWithProblem, house: HouseType) => {
  const errors = room.errors.map(error => ROOM_ERRORS_TRANSLATION[error](house));
  const warnings = room.warnings.map(warning => ROOM_WARNINGS_TRANSLATION[warning](house));
  return { errors, warnings };
};
