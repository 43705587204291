import * as React from "react";

import { IconProps } from "./types";

export const WaterDropIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M9.18049 2.93326C8.88028 2.49178 8.6154 2.08561 8.36817 1.71477C8.33285 1.66179 8.27987 1.60882 8.22689 1.5735C8.01498 1.43223 7.73244 1.50286 7.59117 1.71477C7.36159 2.08561 7.0967 2.47412 6.77884 2.93326C5.38377 4.98172 3.29999 8.07208 3.29999 9.838C3.29999 11.1271 3.82976 12.2926 4.6774 13.1403C5.52504 13.9702 6.69054 14.5 7.97966 14.5C9.26876 14.5 10.4343 13.9702 11.2819 13.1226C12.1295 12.2749 12.6594 11.1095 12.6594 9.82032C12.6594 8.0544 10.5756 4.98172 9.18049 2.93326ZM10.6462 12.4869C9.9575 13.1756 9.02155 13.5817 7.97966 13.5817C6.93777 13.5817 6.00184 13.1579 5.31313 12.4869C4.62442 11.7981 4.21826 10.8622 4.21826 9.82032C4.21826 8.33696 6.21375 5.38788 7.53818 3.42771C7.69711 3.19814 7.83839 2.96857 7.97966 2.77432C8.12093 2.96857 8.26221 3.19814 8.42114 3.42771C9.7456 5.40554 11.741 8.33696 11.741 9.82032C11.741 10.8622 11.3172 11.7981 10.6462 12.4869Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default WaterDropIcon;
