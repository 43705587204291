import type { ComponentPropsWithRef } from "react";
import { forwardRef, useEffect, useState } from "react";

import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import { ArrowRightIcon } from "@eisox/icons";

import styles from "./Plan.module.scss";

interface PlanProps extends ComponentPropsWithRef<"div"> {
  plan?: File;
}

export const Plan = forwardRef<HTMLDivElement, PlanProps>((props, forwardedRef) => {
  const { plan, style, className, ...planProps } = props;

  const { t } = useTranslation();

  const [backgroundImageUrl, setBackgroundImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (plan) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === "string") {
          setBackgroundImageUrl(reader.result);
        } else {
          throw new Error(`Unexpected result type: ${typeof reader.result}`);
        }
      };
      reader.readAsDataURL(plan);
    }
  }, [plan]);

  return (
    <div
      {...planProps}
      ref={forwardedRef}
      className={cx(styles.plan, className)}
      style={{ backgroundImage: `url(${backgroundImageUrl})`, ...style }}
    >
      <div className={styles.plan__overlay} />
      <p className={styles.plan__title}>{t("dashboard.accessArea")}</p>
      <ArrowRightIcon className={styles.plan__arrow} />
    </div>
  );
});

Plan.displayName = "DashboardPlan";
