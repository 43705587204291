import * as React from "react";

import { IconProps } from "./types";

export const ThermometerIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M9.52686 9.94585V3.04565C9.52686 2.19278 8.82303 1.5 7.95913 1.5C7.08142 1.5 6.36656 2.19278 6.36656 3.04565V9.94585C5.53025 10.6138 5.19352 11.7151 5.52749 12.728C5.68758 13.2414 6.00775 13.6858 6.4466 14.006C6.88821 14.3289 7.40987 14.5 7.95913 14.5C9.06316 14.5 10.0651 13.7713 10.3935 12.7253C10.7026 11.7068 10.3549 10.6083 9.52686 9.94585ZM9.86636 12.5624C9.60964 13.3766 8.82579 13.948 7.95913 13.948C7.07866 13.948 6.31136 13.3904 6.05191 12.5624C6.05191 12.5597 6.05191 12.5597 6.05191 12.5569C5.7759 11.7289 6.07951 10.8236 6.80265 10.3074C6.87441 10.2578 6.91858 10.1722 6.91858 10.0839V3.04565C6.91858 2.49639 7.38503 2.05202 7.95913 2.05202C8.50838 2.05202 8.97484 2.50743 8.97484 3.04565V10.0839C8.97484 10.1722 9.019 10.255 9.09076 10.3074C9.81113 10.8208 10.1203 11.7289 9.86636 12.5624Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default ThermometerIcon;
