import { useTranslation } from "react-i18next";

import { primary } from "@eisox/colors";
import { ActionButtonV2 as ActionButton, Dialog } from "@eisox/design-system";
import { ArrowRightIcon, WarningIcon } from "@eisox/icons";

import styles from "./OwnerChangeDialog.module.scss";

interface OwnerChangeDialogProps {
  onSubmit: VoidFunction;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const OwnerChangeDialog: React.FC<OwnerChangeDialogProps> = ({ onSubmit, open, onOpenChange }) => {
  const { t } = useTranslation();

  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content className={styles.ownerChangeDialog} icon={<WarningIcon color={primary.red} />}>
        <h2 className={styles.ownerChangeDialog__title}>{t("users.ownerChange.title")}</h2>
        <p className={styles.ownerChangeDialog__text}>{t("users.ownerChange.text")}</p>
        <div className={styles.ownerChangeDialog__actions}>
          <Dialog.Close asChild>
            <ActionButton variant="cancel">{t("users.ownerChange.cancel")}</ActionButton>
          </Dialog.Close>
          <ActionButton variant="danger" rounded onClick={onSubmit}>
            {t("users.ownerChange.save")} <ArrowRightIcon />
          </ActionButton>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export { OwnerChangeDialog };
export type { OwnerChangeDialogProps };
