import { useIntl } from "react-intl";
import type { Params } from "react-router-dom";
import { Link, Navigate, Outlet, useLoaderData, useLocation, useParams } from "react-router-dom";

import { PostModuleTypeEnum } from "@eisox/backend_webapp_api";
import { primary } from "@eisox/colors";
import dayjs from "@eisox/dayjs";
import {
  BoilerRoomIcon,
  BuildingIcon,
  DashboardIcon,
  FolderIcon,
  LightingIcon,
  MapIcon,
  NutIcon,
  PlanIcon,
  SpannerIcon,
  UserIcon,
} from "@eisox/icons";
import { useQuery, useSuspenseQueries } from "@tanstack/react-query";

import { MobileUserDropdown, Page } from "~/UI/components";
import { queries } from "~/apiV2";
import { env } from "~/configuration";
import { MODE_ENV } from "~/constants/appConstantV2";
import { Sidebar, SidebarItem } from "~/features/house";
import { PermissionsContextProvider } from "~/providers";
import {
  routeToBoilerrooms,
  routeToConsumption,
  routeToDashboard,
  routeToGTB,
  routeToHouse,
  routeToHouses,
  routeToMaintenance,
  routeToPlans,
  routeToRessources,
  routeToSettings,
  routeToUsers,
} from "~/routes/utils";
import { useUserPreferenceStore, useUserStore } from "~/stores";
import type { UserRole } from "~/utils";
import { canAccessBoilerroom, getPLCPageLink, getPermissions, getRoleName } from "~/utils";

import type { loader } from "./loader";

export const House: React.FC = () => {
  const { formatMessage } = useIntl();

  const { data: news, isLoading } = useQuery(queries.news.all);
  const { house, boilerroomPos, modules } = useLoaderData() as LoaderData<ReturnType<typeof loader>>;
  const { pathname } = useLocation();
  const params: Params = useParams();
  const token = useUserStore.use.token();
  const [
    {
      data: { firstName, lastName, company, email, lastNewsId, phone },
    },
    { data: roles },
  ] = useSuspenseQueries({ queries: [queries.user.me, queries.user.roles] });

  const advancedMaintenance = useUserPreferenceStore.use.maintenance().advanced;

  const houseId = params.houseId!;

  if (pathname === routeToHouse(houseId)) return <Navigate to={routeToDashboard(houseId)} />;

  const { permissions, role } = getPermissions(roles, houseId);

  const boilerRoomModules = modules.filter(m =>
    [PostModuleTypeEnum.EclypseBoilerRoom as string, PostModuleTypeEnum.NiagaraBoilerRoom as string].includes(m.type),
  );
  const niagaraModules = modules.filter(m => m.type === PostModuleTypeEnum.NiagaraBoilerRoom);
  const gtbInNewWindow = boilerRoomModules.filter(m => m.isProxyEnabled).length === 1 && niagaraModules.length === 1;
  const routeToNiagaraGTB = gtbInNewWindow && getPLCPageLink(token, houseId, niagaraModules[0].id);

  const canAccessBoilerRoom = !!permissions.page?.boilerroom && !!boilerRoomModules.length;
  const canAccessGTB = !!permissions.page?.gtb && modules.some(m => m.isProxyEnabled);
  const canAccessConsumption =
    !!permissions.page?.consumption && canAccessBoilerroom(boilerroomPos) && env.MODE !== MODE_ENV.PROD;
  const canAccessUsers = !!permissions.page?.users;
  const canAccessMaintenance = !!permissions.page?.maintenance;
  const canAccessRessources = !!permissions.page?.ressource;
  const canAccessSettings = !!permissions.page?.settings;

  const roleName = getRoleName(
    (Object.keys(roles) as (keyof typeof roles)[]).find(r => r !== "default" && roles[r]?.includes(houseId)) as
      | UserRole
      | undefined,
  );

  if (!!house.suspendedAt && dayjs(house.suspendedAt).isBefore() && !roles.installer?.includes(house._id))
    return <Navigate to={`${routeToHouses()}?suspended=${house._id}`} />;

  const displayChristmasHat = roles.installer && dayjs().month() === 11;

  return (
    <PermissionsContextProvider permissions={permissions} role={role}>
      <Page.Root>
        <Sidebar
          houseName={house.houseName}
          role={roleName}
          email={email}
          firstName={firstName}
          lastName={lastName}
          company={company}
          phone={phone}
          lastNewsId={lastNewsId}
          news={news ?? []}
          isLoading={isLoading}
          canAccessPreprod={!!roles.installer}
          displayChristmasHat={displayChristmasHat}
        >
          <SidebarItem to={routeToDashboard(houseId)}>
            <DashboardIcon />
            {formatMessage({ id: "sidebar.dashboard" })}
          </SidebarItem>
          <SidebarItem to={routeToPlans(houseId)}>
            <PlanIcon />
            {formatMessage({ id: "sidebar.area" })}
          </SidebarItem>
          {canAccessBoilerRoom && (
            <SidebarItem to={routeToBoilerrooms(houseId)}>
              <BoilerRoomIcon />
              {formatMessage({ id: "sidebar.boilerRoom" })}
            </SidebarItem>
          )}
          {canAccessGTB && (
            <SidebarItem
              to={gtbInNewWindow ? (routeToNiagaraGTB as string) : routeToGTB(houseId)}
              newWindow={gtbInNewWindow}
            >
              <BuildingIcon />
              {formatMessage({ id: "sidebar.gtb" })}
            </SidebarItem>
          )}
          {canAccessConsumption && (
            <SidebarItem to={`${routeToConsumption(houseId)}?moduleIds=${modules.map(m => m.id).join(",")}`}>
              <LightingIcon />
              {formatMessage({ id: "sidebar.consumption" })}
            </SidebarItem>
          )}
          {canAccessUsers && (
            <SidebarItem to={routeToUsers(houseId)}>
              <UserIcon />
              {formatMessage({ id: "sidebar.users" })}
            </SidebarItem>
          )}
          {canAccessMaintenance && (
            <SidebarItem to={`${routeToMaintenance(houseId)}${advancedMaintenance ? "/advanced" : ""}`}>
              <SpannerIcon />
              {formatMessage({ id: "sidebar.maintenance" })}
            </SidebarItem>
          )}
          {canAccessRessources && (
            <SidebarItem to={routeToRessources(houseId)}>
              <FolderIcon />
              {formatMessage({ id: "sidebar.ressources" })}
            </SidebarItem>
          )}
          {canAccessSettings && (
            <SidebarItem to={routeToSettings(houseId)}>
              <NutIcon />
              {formatMessage({ id: "sidebar.settings" })}
            </SidebarItem>
          )}
        </Sidebar>
        <Page.Main
          leftIcon={
            <Link to={routeToHouses()}>
              <MapIcon style={{ color: primary.darkBlue }} />
            </Link>
          }
          rightIcon={<MobileUserDropdown />}
        >
          <Outlet />
        </Page.Main>
      </Page.Root>
    </PermissionsContextProvider>
  );
};
