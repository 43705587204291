import type { ComponentPropsWithRef } from "react";
import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import { RoundIcon } from "@eisox/design-system";
import { GatewayIcon } from "@eisox/icons";

import styles from "./Gateway.module.scss";

interface GatewayProps extends Omit<ComponentPropsWithRef<"div">, "children"> {
  selected: boolean;
  children?: string;
}

const Gateway = forwardRef<HTMLDivElement, GatewayProps>(
  ({ selected = false, className, children, ...props }, forwardedRef) => {
    return (
      <div {...props} ref={forwardedRef} className={cx(styles.gateway, selected && styles.gateway_selected, className)}>
        <RoundIcon size={20} backgroundColor="white" iconColor="darkBlue">
          <GatewayIcon />
        </RoundIcon>
        {children}
      </div>
    );
  },
);

export { Gateway };
export type { GatewayProps };
