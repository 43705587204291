import type { ComponentPropsWithoutRef } from "react";
import { useMemo } from "react";
import React from "react";

import { useIntl } from "react-intl";

import type { CircleProps } from "@eisox/design-system";
import { Card, Circle, Typography } from "@eisox/design-system";
import { BoilerRoomIcon, TargetIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import Warning from "~/assets/svg/boilerroomWarning.svg?react";
import Cold from "~/assets/svg/cold.svg?react";
import Commutator from "~/assets/svg/commutator.svg?react";
import CoolingUnit from "~/assets/svg/coolingUnit.svg?react";
import Fire from "~/assets/svg/fire.svg?react";
import RCU from "~/assets/svg/rcu.svg?react";
import type { Boiler, CommandAutomate, State } from "~/socketio/types";
import { Speed, Type } from "~/socketio/types";
import { isBoilerAutoManualOrForcing, shouldDisplayBoilerCommutator } from "~/utils/schemeUtils";

import styles from "./BoilerCard.module.scss";

interface BoilerLogoProps {
  type?: Type;
  state?: State | CommandAutomate | "mismatch" | null;
  defect: boolean;
}

const BoilerLogo: React.FC<BoilerLogoProps> = ({ type, state, defect }) => {
  const bem = useBem(styles);
  const logoStyle = bem("logo");

  const Logo = () => {
    switch (type) {
      case Type.GAZ_FIOUL:
      case Type.WOOD_BOILER:
        return <BoilerRoomIcon className={logoStyle("boilerroom")} />;
      case Type.HEAT_PUMP_COOLING_UNIT:
        return <CoolingUnit />;
      case Type.RCU:
        return <RCU />;
      default:
        return <></>;
    }
  };

  const error = (): CircleProps["error"] => {
    if (defect) return "error";
    if (state === null || state === undefined) return "unknown";
    return state ? "on" : "off";
  };

  return (
    <Circle className={logoStyle()} error={error()}>
      <Logo />
    </Circle>
  );
};

interface BoilerSpeedProps {
  type: Type;
  speed: Speed;
}

const BoilerSpeed: React.FC<BoilerSpeedProps> = ({ type, speed }) => {
  const getLogo = (type: "fire" | "cold", count: 1 | 2) => (
    <>{[...Array(count)].map((_, i) => (type === "fire" ? <Fire key={i} /> : <Cold key={i} />))}</>
  );
  const renderSpeed = () => {
    switch (type) {
      case Type.GAZ_FIOUL:
      case Type.WOOD_BOILER:
        switch (speed) {
          case Speed.NORMAL:
          case Speed.UNCONTROLLABLE:
            return getLogo("fire", 1);
          case Speed.FAST:
            return getLogo("fire", 2);
          default:
            return <></>;
        }
      case Type.HEAT_PUMP_COOLING_UNIT:
        switch (speed) {
          case Speed.NORMAL:
          case Speed.UNCONTROLLABLE:
            return getLogo("cold", 1);
          case Speed.FAST:
            return getLogo("cold", 2);
          default:
            return <></>;
        }
      default:
        return <></>;
    }
  };
  return renderSpeed();
};

export interface BoilerCardProps extends ComponentPropsWithoutRef<"div">, Omit<Boiler, "id"> {
  mismatchError: boolean;
  boilerState: { state: State | CommandAutomate | "mismatch" | null; error?: string };
}

export const BoilerCard: React.FC<BoilerCardProps> = ({
  name,
  priority,
  type,
  setpointTemperature,
  speed,
  state,
  commandAutomate,
  commandMan,
  command,
  defect,
  mismatchError,
  boilerState,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const boilerCardStyle = bem("boiler-card");
  const topStyle = bem("top");
  const bottomStyle = bem("bottom");

  const displayCommutator = useMemo(
    () => shouldDisplayBoilerCommutator(state, commandAutomate, commandMan, command),
    [state, commandAutomate, commandMan, command],
  );

  const autoManualOrForcing = useMemo(() => isBoilerAutoManualOrForcing(command, commandMan), [command, commandMan]);

  return (
    <Card {...props} className={boilerCardStyle()} style={{ cursor: "pointer" }}>
      <div className={topStyle()}>
        <BoilerLogo type={type} state={state} defect={defect || mismatchError} />
        <Typography className={topStyle("name")}>{name}</Typography>
        {!!priority && (
          <Tooltip content={formatMessage({ id: "boiler.tootltip.priority" })}>
            <p className={topStyle("priority")}>{priority}</p>
          </Tooltip>
        )}
      </div>
      <div className={bottomStyle()}>
        <div className={bottomStyle("left")}>
          {defect || mismatchError ? (
            <Tooltip
              content={formatMessage(
                { id: mismatchError ? "boiler.tootltip.default.mismatch" : "boiler.tootltip.default.default" },
                { n: name, s: state, ca: commandAutomate },
              )}
            >
              <p className={bottomStyle("text", { default: true })}>{formatMessage({ id: "boiler.default" })}</p>
            </Tooltip>
          ) : (
            boilerState.state !== null &&
            (boilerState.state ? (
              type && speed ? (
                <BoilerSpeed type={type} speed={speed} />
              ) : (
                <p className={bottomStyle("text")}>{formatMessage({ id: "boiler.on" })}</p>
              )
            ) : (
              <p className={bottomStyle("text")}>{formatMessage({ id: "boiler.off" })}</p>
            ))
          )}
        </div>
        <Tooltip
          content={formatMessage({
            id: `boiler.tootltip.${setpointTemperature !== undefined ? "setPointTemperature" : "noSetpointTemperature"}`,
          })}
        >
          <div className={bottomStyle("middle")}>
            <TargetIcon />
            <p>{`${setpointTemperature !== undefined ? setpointTemperature.toFixed(1).toString().replace(".", ",") : "--"} °C`}</p>
          </div>
        </Tooltip>
        <div className={bottomStyle("right")}>
          {formatMessage({ id: `boiler.${autoManualOrForcing}` })}
          {boilerState.error && (
            <Tooltip className={boilerCardStyle("tooltip")} content={boilerState.error}>
              <Warning className={bottomStyle("warning")} />
            </Tooltip>
          )}
          {displayCommutator && (
            <Tooltip content={formatMessage({ id: "boiler.tootltip.commutator.manual" })}>
              <Commutator />
            </Tooltip>
          )}
        </div>
      </div>
    </Card>
  );
};
