import type { ActionFunctionArgs } from "react-router-dom";
import { redirect } from "react-router-dom";

import type { PatchHouse } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import type { QueryClient } from "@tanstack/react-query";

import { loading } from "~/UI/layouts/LoadingPopup";
import { editHouse, removeHouse } from "~/api/house";
import { queries } from "~/apiV2";
import { SUCCESS_FETCH, getHttpStatusByFetchStatus } from "~/constants/fetchConstants";
import { routeToHouses } from "~/routes/utils";
import { API } from "~/types/API";

export const action =
  (queryClient: QueryClient) =>
  async ({ request, params }: ActionFunctionArgs): Promise<API.Response<string | null> | Response> => {
    const { houseId } = params;
    const formData = await request.formData();

    switch (request.method) {
      case API.HTTP_METHOD.PATCH:
        const updates: Partial<PatchHouse> = JSON.parse(formData.get("json") as string);
        const patchResponse = (await editHouse(
          houseId,
          updates.frostFreeTemperature,
          updates.absenceTemperature,
          updates.houseName,
          updates.isFrostFree,
          updates.longitude,
          updates.latitude,
          updates.isRemoteAccess,
          updates.preComfortTemperature,
          updates.summerModePeriod,
          updates.winterModePeriod,
          updates.midSeason,
          updates.isSwitchEnabled,
          updates.comfortTemperature,
          updates.isPreComfRoom,
        )) as any;
        const patchStatus = getHttpStatusByFetchStatus(patchResponse.type) as unknown as API.HTTP_STATUS;
        return {
          date: dayjs(),
          restError: patchResponse.type,
          url: request.url,
          method: API.HTTP_METHOD.PATCH,
          body: formData.get("json") as string,
          message: [{ status: patchStatus, message: patchResponse.result }],
        };
      case API.HTTP_METHOD.DELETE:
        const deleteResponse = (await removeHouse(houseId)) as any;
        if (deleteResponse.type !== SUCCESS_FETCH) {
          const deleteStatus = getHttpStatusByFetchStatus(deleteResponse.type) as unknown as API.HTTP_STATUS;
          return {
            date: dayjs(),
            restError: deleteResponse.type,
            url: request.url,
            method: API.HTTP_METHOD.DELETE,
            message: [{ status: deleteStatus, message: deleteResponse.result }],
          };
        }
        await queryClient.invalidateQueries(queries.house.pos);
        loading(false);
        return redirect(routeToHouses());
      default:
        return {
          date: dayjs(),
          method: request.method as API.HTTP_METHOD,
          url: request.url,
          message: [
            {
              status: API.HTTP_STATUS.NOT_FOUND,
              message: null,
            },
          ],
        };
    }
  };
