import { forwardRef } from "react";

import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { $enum } from "ts-enum-util";

import { RoundIcon } from "@eisox/design-system";
import { GatewayIcon } from "@eisox/icons";

import { GatewayProblemType } from "~/features/InterventionPlanTool";
import { upperCaseToCamelCase } from "~/utils/stringUtils";

import styles from "./Gateway.module.scss";

const gatewayVariants = cva(styles.gateway, {
  variants: {
    resolution: $enum(GatewayProblemType)
      .getKeys()
      .map(upperCaseToCamelCase)
      .reduce(
        (acc, key) => {
          acc[key] = styles[`gateway_${key}`];
          return acc;
        },
        {} as Record<string, string>,
      ),
  },
});

interface GatewayProps extends Required<VariantProps<typeof gatewayVariants>> {
  size?: number;
}

const Gateway = forwardRef<HTMLDivElement, GatewayProps>(({ resolution, size = 20 }, forwardedRed) => (
  <RoundIcon
    ref={forwardedRed}
    size={size}
    className={gatewayVariants({ resolution: resolution && upperCaseToCamelCase(resolution) })}
  >
    <GatewayIcon />
  </RoundIcon>
));

export { Gateway };
export type { GatewayProps };
