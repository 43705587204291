import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";

const login = async (email: string, password: string) => {
  const { data } = await client.POST("/login/", { body: { email, password } });
  return data;
};

export const auth = createQueryKeys("auth", {
  login: (email: string, password: string) => ({
    queryKey: [email, password],
    queryFn: login,
  }),
});
