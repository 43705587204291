import clsx from "clsx";
import { useIntl } from "react-intl";

import { useBem } from "@eisox/tools";

import { ErrorType } from "~/types/ReactHookFormType";

import { AddPeriod } from "../../components/AddPeriod/AddPeriod";
import { UpdatePeriod } from "../../components/UpdatePeriod";

import styles from "./Periods.module.scss";

export type Period = {
  begin: string;
  end: string;
  removed: boolean;
  added: boolean;
};

interface PeriodsProps {
  periods: Period[];
  onAddPeriod: (period: Period) => void;
  onRemovePeriod: (index: number) => void;
  onUpdatePeriod: (index: number, period: Period) => void;
  errors?: ErrorType[];
  className?: string;
}

export const Periods: React.FC<PeriodsProps> = ({
  periods,
  onAddPeriod,
  onRemovePeriod,
  onUpdatePeriod,
  errors,
  className,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const tableStyle = bem("table");

  return (
    <div className={clsx(tableStyle(), className)}>
      <h4 className={tableStyle("column-name")}>
        {formatMessage({ id: "settings.content.menu.houses.frostFree.frostFreeMode.periods.start" })}
      </h4>
      <h4 className={tableStyle("column-name")}>
        {formatMessage({ id: "settings.content.menu.houses.frostFree.frostFreeMode.periods.end" })}
      </h4>
      {periods.map((period, index) => (
        <UpdatePeriod
          key={index}
          period={period}
          onChangeBegin={begin => onUpdatePeriod(index, { ...period, begin })}
          onChangeEnd={end => onUpdatePeriod(index, { ...period, end })}
          onRestore={() => period.removed && onUpdatePeriod(index, { ...period, removed: false })}
          onRemove={() => (period.added ? onRemovePeriod(index) : onUpdatePeriod(index, { ...period, removed: true }))}
          removed={period.removed}
          error={errors?.[index]}
        />
      ))}
      {periods.length < 11 && (
        <AddPeriod onAddPeriod={period => onAddPeriod({ ...period, removed: false, added: true })} />
      )}
    </div>
  );
};
