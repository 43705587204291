import clsx from "clsx";
import { useIntl } from "react-intl";

import type { Dayjs } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";

import styles from "../SummerWinterScheme/SummerWinterScheme.module.scss";

interface SummerWinterWithHalfSeasonSchemeProps {
  summerStart?: string;
  summerEnd?: string;
  winterStart?: string;
  winterEnd?: string;
  className?: string;
}

const DEFAULT_VALUE = "--/--";

const SummerWinterWithHalfSeasonScheme: React.FC<SummerWinterWithHalfSeasonSchemeProps> = ({
  summerStart = DEFAULT_VALUE,
  summerEnd = DEFAULT_VALUE,
  winterStart = DEFAULT_VALUE,
  winterEnd = DEFAULT_VALUE,
  className,
}) => {
  const { formatMessage } = useIntl();

  const addOneDay = (day?: string) =>
    day === DEFAULT_VALUE ? DEFAULT_VALUE : dayjs(day, "DD/MM").add(1, "day").format("DD/MM");
  const subtractOneDay = (day?: string) =>
    day === DEFAULT_VALUE ? DEFAULT_VALUE : dayjs(day, "DD/MM").subtract(1, "day").format("DD/MM");

  return (
    <div className={clsx(styles.summerWinterScheme, className)}>
      <p className={styles.summerWinterScheme__date}>{subtractOneDay(summerStart)}</p>
      {/* Summer */}
      <div className={styles.summerWinterScheme__separator} />
      <p className={styles.summerWinterScheme__date}>{summerStart}</p>
      <p className={styles.summerWinterScheme__season}>
        {formatMessage({ id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.summer" })}
      </p>
      <p className={styles.summerWinterScheme__date}>{summerEnd}</p>
      {/* Mid-season */}
      <div className={styles.summerWinterScheme__separator} />
      <p className={styles.summerWinterScheme__date}>{addOneDay(summerEnd)}</p>
      <p className={styles.summerWinterScheme__season}>
        {formatMessage({ id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.midSeason" })}
      </p>
      <p className={styles.summerWinterScheme__date}>{subtractOneDay(winterStart)}</p>
      {/* Winter */}
      <div className={styles.summerWinterScheme__separator} />
      <p className={styles.summerWinterScheme__date}>{winterStart}</p>
      <p className={styles.summerWinterScheme__season}>
        {formatMessage({ id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.winter" })}
      </p>
      <p className={styles.summerWinterScheme__date}>{winterEnd}</p>
      {/* Mid-season */}
      <div className={styles.summerWinterScheme__separator} />
      <p className={styles.summerWinterScheme__date}>{addOneDay(winterEnd)}</p>
      <p className={styles.summerWinterScheme__season}>
        {formatMessage({ id: "settings.content.menu.houses.season.summerWinterWithHalfSeason.midSeason" })}
      </p>
    </div>
  );
};

export { SummerWinterWithHalfSeasonScheme };
