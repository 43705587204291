import { cloneElement } from "react";

import clsx from "clsx";

import { WarningIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { CardProps } from "../Card";
import { Card } from "../Card";
import { Circle } from "../Circle";

import styles from "./styles.module.scss";

export interface AlertProps extends CardProps {
  severity?: "error" | "warning" | "help";
}

export const Alert: React.FC<AlertProps> = ({ severity = "warning", className, children, ...props }) => {
  const bem = useBem(styles);
  const alertStyle = bem("alert");

  const ENUM_SEVERITY = {
    error: <WarningIcon />,
    warning: <WarningIcon />,
    help: <Circle className={alertStyle("help")}>?</Circle>,
  };

  return (
    <Card {...props} className={clsx(alertStyle({ severity: severity }), className)}>
      {cloneElement(ENUM_SEVERITY[severity], {
        className: clsx(alertStyle("logo"), ENUM_SEVERITY[severity].props.className),
      })}
      {children}
    </Card>
  );
};
