import clsx from "clsx";

import type { Planning } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import type { Planning as SocketIoPlanning } from "~/socketio/types";
import { getCurrentDayForPlanning, mergeTimePeriods } from "~/utils/planningUtils";

import { Day } from "./components/Day";

import styles from "./Week.module.scss";

interface CommonClickablePlanning {
  temperature?: number;
}

type RestClickablePlanning = Planning &
  CommonClickablePlanning & {
    onClick?: (planning: Planning) => void;
  };

type SocketIoClickablePlanning = SocketIoPlanning &
  CommonClickablePlanning & {
    id?: never;
    onClick?: (planning: SocketIoPlanning) => void;
  };

export type ClickablePlanning = RestClickablePlanning | SocketIoClickablePlanning;

interface WeekProps {
  plannings: ClickablePlanning[];
  displayUserDefined?: boolean;
  className?: string;
}

export const Week: React.FC<WeekProps> = ({ plannings, displayUserDefined = true, className }) => {
  const currentDay = getCurrentDayForPlanning();

  const planningsToDisplay = displayUserDefined ? plannings : plannings.filter(p => !p.userDefined);

  // English days of the week because in database it's stored in English
  const ENGLISH_DAYS = [
    ...dayjs().locale("en").localeData().weekdays().slice(dayjs.localeData().firstDayOfWeek()),
    ...dayjs().locale("en").localeData().weekdays().slice(0, dayjs.localeData().firstDayOfWeek()),
  ].map(day => day.toLowerCase());

  // Retrieve the days in the user's language
  const LOCALIZED_DAYS = [
    ...dayjs.weekdays().slice(dayjs.localeData().firstDayOfWeek()),
    ...dayjs.weekdays().slice(0, dayjs.localeData().firstDayOfWeek()),
  ];

  return (
    <div className={clsx(styles.week, className)}>
      {ENGLISH_DAYS.map((day, index) => (
        <Day
          key={index}
          day={LOCALIZED_DAYS[index]}
          timeslots={mergeTimePeriods(planningsToDisplay.filter(p => p.day === day))}
          current={currentDay === day}
        />
      ))}
      <div className={styles.weekDays}></div>
    </div>
  );
};
