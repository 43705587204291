import { PropsWithChildren, cloneElement } from "react";

import { Card, Circle } from "@eisox/design-system";
import { BoilerRoomIcon, GatewayIcon, RoomIcon, ValveIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./ItemCard.module.scss";

interface ItemProps {
  ressource: "valve" | "gateway" | "room" | "boilerroom";
  title: string;
  subtitle: string;
  selected?: boolean;
  isCard?: boolean;
  onClick?: VoidFunction;
  onMouseEnter?: VoidFunction;
  onMouseLeave?: VoidFunction;
}

export const Item: React.FC<ItemProps> = ({
  ressource,
  title,
  subtitle,
  selected = false,
  isCard = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const bem = useBem(styles);
  const itemStyle = bem("item");

  const logo = {
    valve: <ValveIcon />,
    gateway: <GatewayIcon />,
    room: <RoomIcon />,
    boilerroom: <BoilerRoomIcon />,
  };

  return (
    <div
      className={itemStyle({ card: isCard })}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Circle className={itemStyle("circle", { card: isCard })} size={40} selected={selected}>
        {cloneElement(logo[ressource], { className: itemStyle("logo", { card: isCard }) })}
      </Circle>
      <div>
        <p className={itemStyle("title", { selected: selected })}>{title}</p>
        <p className={itemStyle("title", { subtitle: true })}>{subtitle}</p>
      </div>
    </div>
  );
};

interface ItemCardProps extends PropsWithChildren {
  ressource: "valve" | "gateway" | "room" | "boilerroom";
  title?: string;
  subtitle?: string;
}

export const ItemCard: React.FC<ItemCardProps> = ({ ressource, title, subtitle, children }) => {
  const bem = useBem(styles);
  const itemCardStyle = bem("item-card");
  const headerStyle = bem("header");

  return (
    <Card className={itemCardStyle()}>
      <div className={headerStyle()}>
        <Item ressource={ressource} title={title ?? "-"} subtitle={subtitle ?? "-"} isCard />
      </div>
      <div className={itemCardStyle("selects")}>{children}</div>
    </Card>
  );
};
