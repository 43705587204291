import { HouseMessageFrostFreePeriodsInner } from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";

import { RoomsWithProblem } from "~/UI/screens/House";
import { Period } from "~/UI/screens/Settings/layouts";

const generateKey = (periods: HouseMessageFrostFreePeriodsInner[]): string =>
  periods
    .map(period => `${period.begin}-${period.end}`)
    .sort((a, b) => {
      const [beginA, endA] = a.split("-");
      const [beginB, endB] = b.split("-");
      if (beginA === beginB) {
        return dayjs(endA).diff(dayjs(endB));
      }
      return dayjs(beginA).diff(dayjs(beginB));
    })
    .join("|");

export const getFrostFreePeriods = (rooms: RoomsWithProblem[]) => {
  const periodMap: {
    [key: string]: {
      roomIds: string[];
      frostFreePeriods: Period[];
    };
  } = {};

  rooms.forEach(room => {
    const key = generateKey(room.frostFreePeriods ?? []);
    if (!periodMap[key]) {
      periodMap[key] = {
        roomIds: [],
        frostFreePeriods:
          room.frostFreePeriods?.map(ffp => ({ begin: ffp.begin!, end: ffp.end!, removed: false, added: false })) ?? [],
      };
    }
    periodMap[key].roomIds.push(room.id!);
  });

  const result = Object.values(periodMap).map(item => ({
    roomIds: item.roomIds,
    frostFreePeriods: item.frostFreePeriods,
  }));

  return result;
};

export const getRoomPatchFromRooms = (rooms: RoomsWithProblem[]) => {
  const periods = getFrostFreePeriods(rooms);
  return periods.map(p => ({
    ...p,
    frostFreePeriods: p.frostFreePeriods.map(ffp => ({ begin: ffp.begin, end: ffp.end })),
  }));
};

export const mergePeriods = (periods: HouseMessageFrostFreePeriodsInner[]): HouseMessageFrostFreePeriodsInner[] => {
  if (periods.length === 0) return [];

  //sort periods by begin date
  periods.sort((a, b) => dayjs(a.begin).diff(dayjs(b.begin)));

  const mergedPeriods: HouseMessageFrostFreePeriodsInner[] = [];
  let currentPeriod = periods[0];

  periods.forEach((nextPeriod, index) => {
    if (index === 0) return; // Skip the first period, already assigned to currentPeriod

    if (dayjs(currentPeriod.end).isBefore(dayjs(nextPeriod.begin).subtract(1, "day"))) {
      // No overlap, add the current period to merged periods
      mergedPeriods.push(currentPeriod);
      currentPeriod = nextPeriod;
    } else {
      // Overlap or touch, merge periods
      currentPeriod.end = dayjs(currentPeriod.end).isAfter(dayjs(nextPeriod.end)) ? currentPeriod.end : nextPeriod.end;
    }
  });

  // Add the last period to merged periods
  mergedPeriods.push(currentPeriod);

  return mergedPeriods;
};

export const mergeFrostFreePeriods = (
  rooms: RoomsWithProblem[],
  periods: HouseMessageFrostFreePeriodsInner[],
): RoomsWithProblem[] => {
  const updatedRooms: RoomsWithProblem[] = [];

  rooms.forEach(room => {
    const combinePeriods = [...(room.frostFreePeriods ?? []), ...periods];
    const mergedPeriods = mergePeriods(combinePeriods);
    updatedRooms.push({ ...room, frostFreePeriods: mergedPeriods });
  });

  return updatedRooms;
};
