import { z } from "@eisox/zod";

const SetpointTemperatureECSSchema = z.object({
  setpointTemperatureEcs: z.number(),
});

type SetpointTemperatureECSSchemaType = z.infer<typeof SetpointTemperatureECSSchema>;

export { SetpointTemperatureECSSchema };
export type { SetpointTemperatureECSSchemaType };
