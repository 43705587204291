import * as React from "react";

import { IconProps } from "./types";

export const GatewayIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M6.14329 10.4098L5.21476 9.47195C5.57869 9.1028 6.01237 8.80968 6.49057 8.60961C6.96877 8.40954 7.48197 8.30651 8.00033 8.30651C8.51871 8.30651 9.03192 8.40954 9.51011 8.60961C9.9883 8.80968 10.422 9.1028 10.7859 9.47195L9.85804 10.4098C9.61536 10.1636 9.32619 9.96818 9.00738 9.83481C8.6885 9.70143 8.34633 9.63272 8.00066 9.63272C7.65503 9.63272 7.31285 9.70143 6.99399 9.83481C6.67513 9.96818 6.38597 10.1636 6.14329 10.4098Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M4.28624 8.53279L3.35772 7.59498C3.96431 6.97984 4.68711 6.49138 5.48411 6.15799C6.28111 5.82458 7.13642 5.6529 8.00033 5.6529C8.86425 5.6529 9.71956 5.82458 10.5166 6.15799C11.3136 6.49138 12.0364 6.97984 12.643 7.59498L11.7138 8.53279C11.2285 8.04072 10.6503 7.64997 10.0128 7.38327C9.37527 7.11657 8.69108 6.97924 8.00001 6.97924C7.30893 6.97924 6.62474 7.11657 5.9872 7.38327C5.34965 7.64997 4.77147 8.04072 4.28624 8.53279Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M2.42853 6.65717L1.5 5.71936C2.34923 4.85803 3.36118 4.17407 4.47704 3.70724C5.59291 3.2404 6.79042 3 8.00001 3C9.2096 3 10.4071 3.2404 11.523 3.70724C12.6388 4.17407 13.6508 4.85803 14.5 5.71936L13.5715 6.65717C12.8436 5.91888 11.9762 5.33263 11.0197 4.93249C10.0632 4.53234 9.03682 4.32629 8.00001 4.32629C6.96322 4.32629 5.93676 4.53234 4.9803 4.93249C4.02384 5.33263 3.15644 5.91888 2.42853 6.65717Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M8.00019 13.2801C8.54963 13.2801 8.99504 12.8347 8.99504 12.2852C8.99504 11.7358 8.54963 11.2904 8.00019 11.2904C7.45075 11.2904 7.00534 11.7358 7.00534 12.2852C7.00534 12.8347 7.45075 13.2801 8.00019 13.2801Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default GatewayIcon;
