import { useNavigate, useRevalidator, useRouteLoaderData } from "react-router-dom";

import { Page } from "~/UI";
import type { houseLoader } from "~/UI/screens";
import { Page as AdvancedMaintenancePage } from "~/features/AdvancedMaintenance";
import { usePermissionsContext } from "~/providers";
import { idLoaderHouse, routeToPlan, routeToRoom } from "~/routes/utils";

const AdvancedMaintenance: React.FC = () => {
  const { house, gateways, rooms, valves, date } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    ReturnType<typeof houseLoader>
  >;
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const { role, permissions } = usePermissionsContext("advancedMaintenance");

  const handleRefresh = () => revalidate();

  const handleClickOnPlan = (planId: string) => navigate(routeToPlan(house._id, planId));

  const handleClickOnAssociatedRoom = (planId: string, roomId: string) =>
    navigate(routeToRoom(house._id, planId, roomId));

  if (!permissions.page?.maintenance) throw new Error("Unauthorized");

  return (
    <Page.Content>
      <AdvancedMaintenancePage
        role={role}
        house={house}
        gateways={gateways}
        valves={valves}
        rooms={rooms}
        udpatedAt={date}
        onRefresh={handleRefresh}
        onClickOnPlan={handleClickOnPlan}
        onClickOnRoom={handleClickOnAssociatedRoom}
      />
    </Page.Content>
  );
};

export default AdvancedMaintenance;
