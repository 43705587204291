import { useMemo, useState } from "react";

import { useIntl } from "react-intl";
import { useParams, useRevalidator, useRouteLoaderData } from "react-router-dom";

import { Circle, Divider, Dropdown, PlanV2 as Plan, Radio, RoundIcon, SearchInput } from "@eisox/design-system";
import { GatewayIcon, OptionIcon, ValveIcon } from "@eisox/icons";
import { useBem, useScroll } from "@eisox/tools";
import { useQuery } from "@tanstack/react-query";

import { RadialMenu, Refresh } from "~/UI/components";
import type { houseLoader } from "~/UI/screens";
import type { GatewaysWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { queries } from "~/apiV2";
import type { Plan as PlanType } from "~/apiV2";
import { useFilteredItems } from "~/hooks/useFilteredItems";
import { idLoaderHouse } from "~/routes/utils";
import { getGatewayProblemTranslation, getValveProblemTranslation } from "~/utils";
import { upperCaseToCamelCase, upperCaseToKebabCase } from "~/utils/stringUtils";

import type { GatewayInterventionTools, ValveInterventionTools } from "../../../../providers";
import {
  GatewayProblemType,
  InterventionPlanToolStatus,
  ValveProblemType,
  useInterventionPlanTool,
} from "../../../../providers";
import { idCopyButton, idToPdfButton } from "../Dialog/Dialog";
import { ItemPreviewProblem } from "./ItemPreviewProblem/ItemPreviewProblem";
import { Label } from "./Label";

import styles from "./DialogPlanContent.module.scss";

interface DialogPlanContentProps {
  planIndex: number;
}

type SortFilterBy = ("gatewayName" | "uid" | "mac") & keyof (GatewaysWithProblem & ValvesWithProblem);

export const DialogPlanContent: React.FC<DialogPlanContentProps> = ({ planIndex }) => {
  const { plans } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;
  const {
    state: { valves, gateways },
    setProblemGateway,
    setProblemValve,
    status,
    setStatus,
    step,
    setStep,
  } = useInterventionPlanTool();
  const { houseId } = useParams() as { houseId: string };
  const { formatMessage } = useIntl();
  const { revalidate, state } = useRevalidator();
  const { handleScrollToElement, scrollableRef, targetRef } = useScroll();

  const bem = useBem(styles);
  const dropdownStyle = bem("dropdown");
  const dialogPlanStyle = bem("dialog-plan");
  const listItemStyle = bem("list-item");
  const planStyle = bem("plan");
  const lexiqueStyle = bem("lexique");
  const itemStyle = bem("item");

  const [sortBy, setSortBy] = useState<SortFilterBy>("uid");
  const [search, setSearch] = useState<string>("");
  const [hovered, setHovered] = useState<string | undefined>();

  const gatewaysToDisplay = useFilteredItems<GatewayInterventionTools>(
    gateways,
    search,
    [sortBy],
    [{ key: "gatewayName" }, { key: "mac" }, { key: "uid" }],
  );

  const valvesToDisplay = useFilteredItems<ValveInterventionTools>(
    valves,
    search,
    [sortBy],
    [{ key: "gatewayName" }, { key: "mac" }, { key: "uid" }],
  );

  const currentPlan = plans[planIndex];
  const sortFilterBy: SortFilterBy[] = ["uid", "mac", "gatewayName"];

  const { data: plan } = useQuery({
    ...queries.plan.plan(houseId, currentPlan.id),
    enabled: planIndex > plans.length,
  });

  if (status === "REFRESH" && state === "idle") setStatus(InterventionPlanToolStatus.IDLE);

  const getType = (problem?: string): string => {
    return problem ? upperCaseToKebabCase(problem) : "no-problem";
  };

  const changeStep = (planId: string) => {
    const planIndex = plans.findIndex(p => p.id === planId);
    planIndex !== -1 && planIndex !== step && setStep(planIndex);
  };

  const valvesOnPlan = useMemo(
    () => valvesToDisplay.filter(v => v.plan.planId === currentPlan.id),
    [valvesToDisplay, currentPlan.id],
  );

  const gatewayOnPlan = useMemo(
    () => gatewaysToDisplay.filter(g => g.plan.planId === currentPlan.id && g.plan.x && g.plan.y),
    [gatewaysToDisplay, currentPlan.id],
  );

  const labelValveOnPlan = useMemo(
    () =>
      valvesOnPlan
        .filter(v => v.problemInstance.type ?? v.unclogging)
        .flatMap(v =>
          v.notes.map(n => ({
            ...n,
            index: valvesOnPlan.findIndex(v2 => v2.id === v.id),
            isSolved: v.problemInstance.isSolved,
          })),
        ),
    [valvesOnPlan],
  );

  const labelGatewayOnPlan = useMemo(
    () =>
      gatewayOnPlan
        .filter(g => g.problemInstance.type)
        .flatMap(g =>
          g.notes.map(n => ({
            ...n,
            isSolved: g.problemInstance.isSolved,
            index: gatewayOnPlan.findIndex(g2 => g2.id === g.id),
          })),
        ),
    [gatewayOnPlan],
  );

  return (
    <div className={dialogPlanStyle()}>
      <div className={listItemStyle()}>
        <div className={listItemStyle("search")}>
          <SearchInput
            className={listItemStyle("input")}
            placeholder={sortFilterBy
              .map(s => formatMessage({ id: `interventionPlanTool.sortFilterBy.${s}` }))
              .join(", ")}
            value={search}
            onChange={value => setSearch(value)}
          />
          <Dropdown.Root>
            <Dropdown.Trigger>
              <OptionIcon />
            </Dropdown.Trigger>
            <Dropdown.Content className={dropdownStyle()}>
              {sortFilterBy.map((s, i) => (
                <div key={i} onClick={() => setSortBy(s)} className={dropdownStyle("item")}>
                  <Radio checked={sortBy === s} />
                  {formatMessage({ id: `interventionPlanTool.sortFilterBy.${s}` })}
                </div>
              ))}
            </Dropdown.Content>
          </Dropdown.Root>
          <Refresh
            onClick={() => {
              setStatus(InterventionPlanToolStatus.REFRESH);
              revalidate();
            }}
          />
        </div>
        <div className={listItemStyle("list")} ref={scrollableRef}>
          {valvesToDisplay.map((v, i) => {
            const { errors, warnings } = getValveProblemTranslation(v);
            return (
              <ItemPreviewProblem
                onMouseEnter={() => setHovered(v.id)}
                onMouseLeave={() => setHovered(undefined)}
                onClickLogo={() => changeStep(v.plan.planId)}
                ref={el => (targetRef[v.id] = el)}
                key={i}
                gatewayName={v.gatewayName || "-"}
                isSolved={v.problemInstance.isSolved}
                isForced={v.problemInstance.isForcing}
                uid={v.uid}
                mac={v.mac ?? "-"}
                type="valve"
                problemType={v.problemInstance.type}
                isUnclogging={v.unclogging}
                errors={errors}
                warnings={warnings}
              />
            );
          })}
          {gatewaysToDisplay.map((g, i) => {
            const { errors, warnings } = getGatewayProblemTranslation(g);
            return (
              <ItemPreviewProblem
                onMouseEnter={() => setHovered(g.id)}
                onMouseLeave={() => setHovered(undefined)}
                onClickLogo={() => changeStep(g.plan.planId)}
                ref={el => (targetRef[g.id] = el)}
                key={i}
                isSolved={g.problemInstance.isSolved}
                isForced={g.problemInstance.isForcing}
                gatewayName={g.gatewayName}
                uid={g.uid}
                mac={g.mac ?? "-"}
                type="gateway"
                problemType={g.problemInstance.type}
                errors={errors}
                warnings={warnings}
              />
            );
          })}
        </div>
      </div>
      <div className={planStyle()} id={idCopyButton}>
        <Plan.Root>
          <div className={planStyle("container")} id={idToPdfButton}>
            <Plan.MiniMap width={200} height={200} />
            <Divider orientation="vertical" className={planStyle("divider")} />
            <Plan.Wrapper className={planStyle("wrapper")} plan={plan}>
              {valvesOnPlan.map(v => (
                <Plan.Item position={{ x: v.plan.x, y: v.plan.y }}>
                  <RadialMenu
                    radius={50}
                    items={[...Object.values(ValveProblemType), undefined].map(problem => (
                      <Circle
                        size={30}
                        className={itemStyle({
                          type: getType(problem),
                          unclogging: problem === ValveProblemType.UNCLOG_CHANGE_BODY ? getType(problem) : false,
                        })}
                        onClick={() => setProblemValve(v.id, problem)}
                      />
                    ))}
                  >
                    <Circle
                      className={`${itemStyle({
                        type: getType(v.problemInstance.type),
                        unclogging: v.unclogging ? getType(v.problemInstance.type) : false,
                        hovered: hovered === v.id,
                      })} ${itemStyle("plan", {
                        hovered: hovered === v.id,
                      })}`}
                      size={25}
                      onClick={() => handleScrollToElement(v.id)}
                    >
                      {v.uid}
                    </Circle>
                  </RadialMenu>
                </Plan.Item>
              ))}

              {gatewayOnPlan.map(g => (
                <Plan.Item position={{ x: g.plan.x, y: g.plan.y }}>
                  <RadialMenu
                    radius={50}
                    items={[...Object.values(GatewayProblemType), undefined].map(problem => (
                      <Circle
                        size={30}
                        onClick={() => setProblemGateway(g.id, problem)}
                        className={itemStyle({
                          type: getType(problem),
                        })}
                      />
                    ))}
                  >
                    <Circle
                      className={`${itemStyle({
                        type: getType(g.problemInstance.type),
                        gateway: true,
                      })} ${itemStyle("plan", {
                        hovered: hovered === g.id,
                      })}`}
                      size={25}
                      onClick={() => handleScrollToElement(g.id)}
                    >
                      {g.uid}
                    </Circle>
                  </RadialMenu>
                </Plan.Item>
              ))}

              {labelValveOnPlan.map(l => (
                <Label type="valves" {...l} />
              ))}

              {labelGatewayOnPlan.map(l => (
                <Label type="gateways" {...l} />
              ))}
            </Plan.Wrapper>
          </div>
        </Plan.Root>

        <div className={lexiqueStyle()}>
          {Object.values(ValveProblemType).map((type, i) => (
            <div className={lexiqueStyle("container-item")}>
              <RoundIcon
                size={40}
                className={itemStyle({
                  type: upperCaseToKebabCase(type),
                  unclogging: type === ValveProblemType.UNCLOG_CHANGE_BODY ? upperCaseToKebabCase(type) : false,
                })}
              >
                <ValveIcon />
              </RoundIcon>
              <span>
                (
                {valves.reduce(
                  (acc, v) =>
                    acc +
                    ((type === ValveProblemType.UNCLOG_CHANGE_BODY ? v.unclogging : v.problemInstance.type === type)
                      ? 1
                      : 0),
                  0,
                )}
                )
              </span>
              <p>{formatMessage({ id: `interventionPlanTool.plan.lexique.valve.${upperCaseToCamelCase(type)}` })}</p>
            </div>
          ))}
          {Object.values(GatewayProblemType).map((type, i) => (
            <div className={lexiqueStyle("container-item")}>
              <RoundIcon
                size={40}
                className={itemStyle({
                  type: upperCaseToKebabCase(type),
                })}
              >
                <GatewayIcon />
              </RoundIcon>
              <span>({gateways.reduce((acc, g) => (g.problemInstance.type === type ? acc + 1 : acc), 0)})</span>
              <p>{formatMessage({ id: `interventionPlanTool.plan.lexique.gateway.${upperCaseToCamelCase(type)}` })} </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
