import { intl } from "~/i18n";
import * as Yup from "yup";
import { RoomsWithProblem } from "~/UI/screens/House";

export const schemaErrors = (rooms: RoomsWithProblem[], hasHeatingNetwork: boolean) =>
  Yup.object({
    gatewayId: Yup.string().required(intl.formatMessage({ id: "error.required" })),
    plan: Yup.object({
      planId: Yup.string().required(intl.formatMessage({ id: "error.required" })),
    }),
    roomId: Yup.string()
      .required(intl.formatMessage({ id: "error.required" }))
      .test(
        "room-same-plan-id-valve",
        intl.formatMessage({ id: "plan.actions.preinstall.valves.errors.roomSamePlanIdValve" }),
        (value, context) => rooms.find(r => r.id === value)?.plan?.planId === context.parent.plan?.planId,
      ),
    heatingNetworkId: hasHeatingNetwork
      ? Yup.string().required(intl.formatMessage({ id: "error.required" }))
      : Yup.string(),
  });
