import type { Planning } from "@eisox/backend_webapp_api";
import type {
  PostBatteryHistoryFields,
  PostHistovalvesStatsFields,
  PostMecaHistoryFields,
  PostStatedataHistoryFields,
} from "@eisox/webapp-api-specification";

import type { BoilerRoom, Gateway, House, Mesh, Module, Plan, Room, User, Valve } from "~/apiV2";
import type { HeatingNetwork, RawDataResV2 } from "~/socketio/types";

export enum UserRole {
  INSTALLER = "installer", //2
  OWNER = "owner", //1
  MANAGER_BOILERROOM = "managerBoilerRoom", //4
  MANAGER = "manager", //5
  VISITOR = "visitor", //6
  API = "api", //3
}

interface maintenance {
  error?: boolean;
  warning?: boolean;
}

export interface Permission {
  valve?: Role<RecursivePartial<Valve>> & maintenance;
  gateway?: Role<RecursivePartial<Gateway>> & maintenance;
  user?: Role<RecursivePartial<User>>;
  room?: Role<RecursivePartial<Room>> & maintenance;
  module?: Role<RecursivePartial<Module>> & {
    boilerroom?: Role<RecursivePartial<BoilerRoom>> & {
      variableData?: Role<RecursivePartial<RawDataResV2>>;
      heatingNetwork?: Role<RecursivePartial<HeatingNetwork>>;
    };
  };
  house?: Role<RecursivePartial<House>>;
  mesh?: Role<RecursivePartial<Mesh>>;
  plan?: Role<RecursivePartial<Plan>>;
  planning?: Role<RecursivePartial<Planning>>;
  history?: {
    valve: {
      battery?: Role<RecursivePartial<typeof PostBatteryHistoryFields>>;
      meca?: Role<RecursivePartial<typeof PostMecaHistoryFields>>;
      stateData?: Role<RecursivePartial<typeof PostStatedataHistoryFields>>;
      histoValve?: Role<RecursivePartial<typeof PostHistovalvesStatsFields>>;
    };
    gateway?: {
      lastGatewayActivity?: Role<boolean>;
    };
  };
  page?: {
    maintenance?: boolean;
    consumption?: boolean;
    users?: boolean;
    ressource?: boolean;
    settings?: boolean;
    boilerroom?: boolean;
    gtb?: boolean;
  };
}

/*
 * The visitor role is the basic role, all other roles inherit automatically,
 * so no specific authorization is required for this role.
 * The api role on the webapp is a visitor
 */
export type WebappRolePermission = Exclude<UserRole, UserRole.API>;

export enum PasswordRules {
  MIN_LENGTH = "minLength",
  UPPER_CASE = "upperCase",
  LOWER_CASE = "lowerCase",
  NUMBER = "number",
  SPECIAL_CHARACTER = "specialCharacter",
}
