import { useState } from "react";

import clsx from "clsx";
import { useIntl } from "react-intl";

import type { SearchInputProps as SearchInputPropsFC } from "@eisox/design-system";
import { DropdownMenu, Radio, SearchInput as SearchInputFC } from "@eisox/design-system";
import { OptionIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./SearchInput.module.scss";

interface SearchInputProps extends Omit<SearchInputPropsFC, "onChange"> {
  sorts: string[];
  placeholder: string;
  onChange: (search: string, sort: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ sorts, placeholder, onChange, ...props }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const searchInputStyle = bem("search-input");

  const [search, setSearch] = useState<string>("");
  const [sortBy, setSortBy] = useState(sorts[0]);

  const handleChangeSearch = (value: string) => {
    setSearch(value);
    onChange(value, sortBy);
  };

  const handleChangeSort = (sort: string) => {
    setSortBy(sort);
    onChange(search, sort);
  };

  const menu = [
    //@ts-ignore
    <p keepopen className={searchInputStyle("dropdown-title")}>
      {formatMessage({ id: "plan.actions.move.sort.title" })}
    </p>,
    ...sorts.map((s, i) => (
      <div
        key={i}
        className={searchInputStyle("dropdown-item", { selected: s === sortBy })}
        onClick={() => handleChangeSort(s)}
      >
        <Radio checked={s === sortBy} />
        <p>{formatMessage({ id: `plan.actions.move.sort.${s}` })}</p>
      </div>
    )),
  ];

  return (
    <div className={searchInputStyle()}>
      <SearchInputFC
        className={clsx(searchInputStyle("input"), props.className)}
        placeholder={placeholder}
        iconPosition="left"
        value={search}
        onChange={handleChangeSearch}
        timeout={0}
        {...props}
      />
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <OptionIcon className={searchInputStyle("three-dot")} />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content align="end" sideOffset={10} style={{ zIndex: 2000 }}>
          <DropdownMenu.RadioGroup value={sortBy} onValueChange={handleChangeSort}>
            <DropdownMenu.Label>{formatMessage({ id: "plan.actions.move.sort.title" })}</DropdownMenu.Label>
            {sorts.map((s, i) => (
              <DropdownMenu.RadioItem key={i} value={s}>
                <DropdownMenu.ItemIndicator asChild forceMount>
                  <Radio checked={s === sortBy} style={{ marginRight: 10 }} />
                </DropdownMenu.ItemIndicator>
                <p>{formatMessage({ id: `plan.actions.move.sort.${s}` })}</p>
              </DropdownMenu.RadioItem>
            ))}
          </DropdownMenu.RadioGroup>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};
