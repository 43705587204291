import { createContext } from "@eisox/tools";

import type { Permission, WebappRolePermission } from "~/utils/User";

interface PermissionContextValue {
  permissions: Permission;
  role?: WebappRolePermission;
}

const [PermissionsContextProvider, usePermissionsContext] = createContext<PermissionContextValue>("Permissions");

export { PermissionsContextProvider, usePermissionsContext };
