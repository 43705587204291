import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import type { ErrorType } from "@eisox/design-system";
import { ActionButton, Dialog, Select } from "@eisox/design-system";
import { CalendarIcon, PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { PlanningPlanningType, RoomRoomType } from "@eisox/webapp-api-specification";

import type { Planning, PlanningType } from "~/utils/planningUtils";
import {
  DAYS,
  convertTimeRangeToPlanningArray,
  convertTimeslotsToTimeRange,
  getHoursEnd,
  getHoursStart,
  getTimeslotIndexToRemove,
  mergeUpdatedPlanningToExistingPlanning,
} from "~/utils/planningUtils";

import { timeslotPopupDtoToTimeslotDtoMapper } from "../../utils";

import styles from "./TimeslotPopup.module.scss";

interface TimeslotPopupProps {
  timeslot?: Planning;
  timeslots: Planning[];
  roomType: RoomRoomType;
  open: boolean;
  replace: (timeslot: Planning[]) => void;
  remove: (timeslotIds: number[]) => void;
  onClose: VoidFunction;
}

export interface TimeslotDto {
  dayStart: DAYS;
  hourStart?: string;
  dayEnd: DAYS;
  hourEnd?: string;
  planningType?: PlanningType;
}

export const TimeslotPopup: React.FC<TimeslotPopupProps> = ({
  timeslot,
  timeslots,
  roomType,
  open,
  replace,
  remove,
  onClose,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const timeslotPopupStyle = bem("timeslot-popup");
  const contentStyle = bem("content");
  const footerStyle = bem("footer");

  const planningToUpdate = timeslot && timeslots ? convertTimeslotsToTimeRange(timeslot, timeslots) : undefined;

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    watch,
  } = useForm<TimeslotDto>({
    values: timeslotPopupDtoToTimeslotDtoMapper(planningToUpdate, timeslot),
  });

  const hourStart = watch("hourStart");
  const dayStart = watch("dayStart");
  const hourEnd = watch("hourEnd");
  const dayEnd = watch("dayEnd");

  const renderValue = (field: "hour" | "day") => (value?: string, _?: ErrorType) => (
    <p>
      {field === "day"
        ? formatMessage({
            id: value ? `plannings.${value}.long` : `settings.content.menu.rooms.planning.popup.day.placeholder`,
          })
        : value
          ? value
          : formatMessage({ id: "settings.content.menu.rooms.planning.popup.hour.placeholder" })}
    </p>
  );

  const onSubmit = (data: TimeslotDto) => {
    const updatedPlannings = convertTimeRangeToPlanningArray(
      data.dayStart,
      data.hourStart!,
      data.dayEnd,
      data.hourEnd!,
      data.planningType!,
    );
    const planning = mergeUpdatedPlanningToExistingPlanning(
      updatedPlannings,
      planningToUpdate?.otherTimeslots ?? timeslots,
    );
    replace(planning);
  };

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose && onClose()}>
      <Dialog.Content
        className={timeslotPopupStyle()}
        title={formatMessage({ id: "settings.content.menu.rooms.planning.popup.title" }, { timeslot: !!timeslot })}
        icon={<CalendarIcon className={timeslotPopupStyle("icon")} />}
      >
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <div className={contentStyle()}>
            <h2 className={contentStyle("text", { type: "label" })}>
              {formatMessage({ id: "settings.content.menu.rooms.planning.popup.start" })}
            </h2>
            <Controller
              control={control}
              name="dayStart"
              rules={{ required: formatMessage({ id: "error.emptyField" }) }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  options={Object.values(DAYS).map(d => ({
                    value: d,
                    name: formatMessage({ id: `plannings.${d}.long` }),
                  }))}
                  renderValue={renderValue("day")}
                  onChange={(value: string) => onChange(value)}
                  error={errors.dayStart}
                />
              )}
            />
            <Controller
              control={control}
              name="hourStart"
              rules={{ required: formatMessage({ id: "error.emptyField" }) }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  options={getHoursStart(hourEnd, dayStart, dayEnd).map(h => ({
                    value: h,
                    name: h.replace(":", "h"),
                  }))}
                  renderValue={renderValue("hour")}
                  onChange={(value: string) => onChange(value)}
                  error={errors.hourStart}
                />
              )}
            />
            <h2 className={contentStyle("text", { type: "label" })}>
              {formatMessage({ id: "settings.content.menu.rooms.planning.popup.end" })}
            </h2>
            <Controller
              control={control}
              name="dayEnd"
              rules={{ required: formatMessage({ id: "error.emptyField" }) }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  options={Object.values(DAYS).map(d => ({
                    value: d,
                    name: formatMessage({ id: `plannings.${d}.long` }),
                  }))}
                  renderValue={renderValue("day")}
                  onChange={(value: string) => onChange(value)}
                  error={errors.dayEnd}
                />
              )}
            />
            <Controller
              control={control}
              name="hourEnd"
              rules={{ required: formatMessage({ id: "error.emptyField" }) }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  options={getHoursEnd(hourStart, dayStart, dayEnd).map(h => ({
                    value: h,
                    name: h.replace(":", "h"),
                  }))}
                  renderValue={renderValue("hour")}
                  onChange={(value: string) => onChange(value)}
                  error={errors.hourEnd}
                />
              )}
            />
            <h2 className={contentStyle("text", { type: "label" })}>
              {formatMessage({ id: "settings.content.menu.rooms.planning.popup.type.label" })}
            </h2>
            <Controller
              control={control}
              name="planningType"
              rules={{ required: formatMessage({ id: "error.emptyField" }) }}
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  options={Object.values(PlanningPlanningType)
                    .filter(
                      t =>
                        t !== PlanningPlanningType.preComfort &&
                        (roomType !== RoomRoomType.Room ? t !== PlanningPlanningType.night : true),
                    )
                    .map(v => ({
                      name: formatMessage({ id: `plannings.${v}` }),
                      value: v,
                    }))}
                  renderValue={(value?: string, _?: ErrorType) => (
                    <p>
                      {value
                        ? formatMessage({ id: `plannings.${value}` })
                        : formatMessage({ id: "settings.content.menu.rooms.planning.popup.type.placeholder" })}
                    </p>
                  )}
                  onChange={(value: string) => onChange(value)}
                  error={errors.planningType}
                />
              )}
            />
          </div>
          <div className={footerStyle()}>
            <ActionButton
              variant="cancel"
              text={formatMessage(
                { id: "settings.content.menu.rooms.planning.popup.cancel" },
                { timeslot: !!timeslot },
              )}
              onClick={() =>
                timeslot
                  ? remove(getTimeslotIndexToRemove(timeslots, planningToUpdate?.otherTimeslots ?? []))
                  : onClose()
              }
            />
            <ActionButton
              type="submit"
              text={formatMessage({ id: "settings.content.menu.rooms.planning.popup.save" }, { timeslot: !!timeslot })}
              icon={!timeslot ? <PlusIcon style={{ width: 10, height: 10 }} /> : undefined}
              disabled={!isDirty}
            />
          </div>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  );
};
