import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Outlet, useParams, useRouteLoaderData } from "react-router-dom";
import type { Socket } from "socket.io-client";

import { createContext } from "@eisox/tools";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useIdle } from "@uidotdev/usehooks";

import type { loader } from "~/UI/screens/House";
import type { HeatingCurveHslopeHistory } from "~/apiV2/types";
import { BOILERROOM_TIMEOUTS } from "~/constants";
import type { EmitEvents, ListenEvents } from "~/hooks";
import { useSocketIO } from "~/hooks";
import { idLoaderHouse } from "~/routes/utils";
import {
  Event,
  getCurrentHeatingCurveHslope,
  getRawDataV2,
  resetSocketRequest,
  updateSocketRequest,
} from "~/socketio/requests";
import type { BoilerRoom, BoilerroomDataRes, ResetDataRes } from "~/socketio/types";
import { useUserStore } from "~/stores/user";

import { ConnectionErrorDialog, EmitDataErrorDialog } from "../components";
import { SuspendedCommunicationDialog } from "../components/SuspendedCommunicationDialog";
import type { DetailsVariablesType } from "../helpers";
import {
  allGatewaysSynced,
  convertPlanningTypeToType,
  getBoilerRoomIdsByGatewayId,
  getGatewaySyncByGatewayIdInitialState,
  handleHeatingNetworkPlanningsUpdate,
  mergeObjects,
  someGatewaysSynced,
  transformRawDataRes,
} from "../helpers";
import { GatewayProvider } from "./BoilerRoomGatewayProvider";

const NAME = "RealTime";

type UseUpdateBoilerRoomType = ({
  onSuccess,
}: {
  onSuccess?: (data: BoilerroomDataRes) => void;
}) => UseMutationResult<BoilerroomDataRes, Error, BoilerRoom[], unknown>;

type UseResetVariablesType = ({
  onSuccess,
}: {
  onSuccess?: (data: ResetDataRes) => void;
}) => UseMutationResult<ResetDataRes, Error, string[], unknown>;

type UseGetRawDataV2Type = ({
  moduleId,
  enabled,
}: {
  moduleId?: string;
  enabled?: boolean;
}) => UseQueryResult<DetailsVariablesType[], Error>;

type UseGetCurrentHeatingCurveHslopeType = ({
  externalTemperature,
  heatingNetworkId,
  enabled,
}: {
  externalTemperature: number;
  heatingNetworkId: string;
  enabled?: boolean;
}) => UseQueryResult<
  {
    data: HeatingCurveHslopeHistory[];
    meta: {
      total: number;
    };
  },
  Error
>;

interface EmitDataError {
  type: Event.UPDATE_DATA | Event.RESET_DATA;
  error: string;
}

interface RealTimeProviderContextValue {
  setData: React.Dispatch<React.SetStateAction<BoilerroomDataRes | undefined>>;
  socket: Socket<ListenEvents, EmitEvents> | null;
  dataReceivedTrigger: (gatewayId: string) => void;
  useUpdateBoilerRoom: UseUpdateBoilerRoomType;
  useGetRawDataV2: UseGetRawDataV2Type;
  useResetVariables: UseResetVariablesType;
  useGetCurrentHeatingCurveHslope: UseGetCurrentHeatingCurveHslopeType;
}

const [BoilerRoomRealTimeProvider, useBoilerRoomRealTimeProviderContext] =
  createContext<RealTimeProviderContextValue>(NAME);

const RealTimeProvider: React.FC = () => {
  const { houseId } = useParams() as { houseId: string };
  const { modules, boilerroomPos } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof loader>>;

  const userId = useUserStore.use.id();
  const token = useUserStore.use.token();

  const idle = useIdle(BOILERROOM_TIMEOUTS.USER_INACTIVITY);

  const { socket, isConnected, connectionError } = useSocketIO({ houseId, userId, token });

  const [emitDataError, setEmitDataError] = useState<EmitDataError>();

  const boilerRoomIdsByGatewayId = useMemo(
    () => getBoilerRoomIdsByGatewayId(modules, boilerroomPos),
    [modules, boilerroomPos],
  );

  const gatewaysSyncInitialState = useMemo(
    () => getGatewaySyncByGatewayIdInitialState([...boilerRoomIdsByGatewayId.keys()]),
    [boilerRoomIdsByGatewayId],
  );

  const initialState = useMemo(
    () => ({
      data: undefined as BoilerroomDataRes | undefined,
      inactive: false,
      gatewaysInitialized: false,
      gatewaysSync: gatewaysSyncInitialState,
      initializedTimeoutReached: false,
      sync: false,
    }),
    [gatewaysSyncInitialState],
  );

  const gatewaysInitialized = useRef<boolean>(initialState.gatewaysInitialized);

  const [data, setData] = useState(initialState.data);
  const [inactive, setInactive] = useState(initialState.inactive);
  const [gatewaysSync, setGatewaysSync] = useState<Record<string, boolean>>(initialState.gatewaysSync);
  const [initializedTimeoutReached, setInitializedTimeoutReached] = useState(initialState.initializedTimeoutReached);
  const [sync, setSync] = useState(initialState.sync);

  const dataReceivedTrigger = (gatewayId: string) => setGatewaysSync(prev => ({ ...prev, [gatewayId]: true }));

  const init = useCallback(() => {
    if (!socket || !isConnected) return;
    socket.emit(Event.GET_DATA, { type: "getDataBoilerRoomInit" });
    const timeout = setTimeout(() => setInitializedTimeoutReached(true), BOILERROOM_TIMEOUTS.FIRST_GET_DATA_RES);
    return () => clearTimeout(timeout);
  }, [socket, isConnected]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!sync && (allGatewaysSynced(gatewaysSync) || (initializedTimeoutReached && someGatewaysSynced(gatewaysSync)))) {
      setSync(true);
    }
    if (!sync && initializedTimeoutReached) {
      timeout = setTimeout(() => {
        setSync(true);
      }, 30000);
    }
    return () => clearTimeout(timeout);
  }, [initializedTimeoutReached, gatewaysSync, socket, gatewaysSyncInitialState, sync]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (sync && !inactive) {
      timeout = setTimeout(() => {
        socket?.emit(Event.GET_DATA, { type: "getDataBoilerRoom" });
        setInitializedTimeoutReached(false);
        setGatewaysSync(gatewaysSyncInitialState);
        setSync(false);
      }, 10000);
    }
    return () => clearTimeout(timeout);
  }, [sync, socket, gatewaysSyncInitialState, inactive]);

  useEffect(() => {
    if (idle) setInactive(true);
  }, [idle]);

  const useUpdateBoilerRoom: UseUpdateBoilerRoomType = ({ onSuccess }) => {
    const mutation = useMutation({
      mutationFn: (boilerRooms: BoilerRoom[]) => updateSocketRequest({ socket: socket!, boilerRooms }),
      onSuccess: response => {
        setData(prev => {
          let updatedData = { ...response };
          if (response.boilerRooms?.some(b => b.heatingNetworks?.some(hn => !!hn.plannings))) {
            updatedData = handleHeatingNetworkPlanningsUpdate(response, prev);
          }
          return mergeObjects(prev, convertPlanningTypeToType(updatedData));
        });
        onSuccess?.(response);
      },
      onError: (error: Error) => setEmitDataError({ type: Event.UPDATE_DATA, error: error.message }),
    });
    return mutation;
  };

  const useResetVariables: UseResetVariablesType = ({ onSuccess }) =>
    useMutation({
      mutationFn: (modules: string[]) => resetSocketRequest({ socket: socket!, modules }),
      onSuccess: response => onSuccess?.(response),
      onError: (error: Error) => setEmitDataError({ type: Event.RESET_DATA, error: error.message }),
    });

  const useGetRawDataV2 = ({ moduleId, enabled }: { moduleId?: string; enabled?: boolean }) =>
    useQuery({
      queryKey: ["rawDataV2", moduleId],
      queryFn: () => getRawDataV2(socket!, moduleId!),
      select: data => (data.rawData ? transformRawDataRes(data.rawData) : []),
      enabled: enabled,
      staleTime: 0,
    });

  const useGetCurrentHeatingCurveHslope = ({
    externalTemperature,
    heatingNetworkId,
    enabled,
  }: {
    externalTemperature: number;
    heatingNetworkId: string;
    enabled?: boolean;
  }) =>
    useQuery({
      queryKey: ["history", "heatingCurveHslopeHistory", "current-period", heatingNetworkId, externalTemperature],
      queryFn: () => getCurrentHeatingCurveHslope(socket!, { externalTemperature, heatingNetworkId }),
      select: data => ({
        data: (data.heatingSlopes ?? []) as HeatingCurveHslopeHistory[],
        meta: { total: data.heatingSlopes ? data.heatingSlopes.length : 0 },
      }),
      enabled: enabled,
      staleTime: 0,
    });

  const resetState = useCallback(() => {
    setData(initialState.data);
    setInactive(initialState.inactive);
    gatewaysInitialized.current = initialState.gatewaysInitialized;
    setGatewaysSync(initialState.gatewaysSync);
    setInitializedTimeoutReached(initialState.initializedTimeoutReached);
    setSync(initialState.sync);
    init(); // Réémettre l'événement et gérer le timeout
  }, [initialState, init]);

  return (
    <BoilerRoomRealTimeProvider
      setData={setData}
      socket={socket}
      dataReceivedTrigger={dataReceivedTrigger}
      useUpdateBoilerRoom={useUpdateBoilerRoom}
      useGetRawDataV2={useGetRawDataV2}
      useResetVariables={useResetVariables}
      useGetCurrentHeatingCurveHslope={useGetCurrentHeatingCurveHslope}
    >
      {Array.from(boilerRoomIdsByGatewayId).map(([gatewayId, boilerRoomIds]) => (
        <GatewayProvider
          key={gatewayId}
          initialized={gatewaysInitialized}
          data={data}
          gatewayId={gatewayId}
          boilerRoomIds={boilerRoomIds}
        >
          <Outlet />
        </GatewayProvider>
      ))}
      {connectionError && <ConnectionErrorDialog houseId={houseId} />}
      {inactive && <SuspendedCommunicationDialog houseId={houseId} onResumeButtonClick={resetState} />}
      {emitDataError && (
        <EmitDataErrorDialog
          open
          onOpenChange={() => setEmitDataError(undefined)}
          type={emitDataError.type}
          error={emitDataError.error}
        />
      )}
    </BoilerRoomRealTimeProvider>
  );
};

export { BoilerRoomRealTimeProvider, RealTimeProvider, useBoilerRoomRealTimeProviderContext };
export type { UseGetRawDataV2Type, UseUpdateBoilerRoomType };
