import { useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import { useMutation } from "@tanstack/react-query";

import { enrollAccount, sendValidationPhoneNumber } from "~/apiV2";
import { PhoneNumberValidationPopup } from "~/features";
import { EnrollAccountPage } from "~/pages";
import { routeToHouses, routeToSignIn } from "~/routes/utils";
import { useUserStore } from "~/stores";

import type { loader } from "../loader";
import { tokenLoaderId } from "../route";

const EnrollAccount = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { token } = useRouteLoaderData(tokenLoaderId) as LoaderData<ReturnType<typeof loader>>;

  const [phoneNumberValidationOpen, setPhoneNumberValidationOpen] = useState(false);

  const setToken = useUserStore.use.setToken();

  const { mutate: sendCode } = useMutation({
    mutationFn: sendValidationPhoneNumber,
    onSuccess: () => {
      toast.success(t("phoneNumberValidation.success.sendCode"));
      setPhoneNumberValidationOpen(true);
    },
    onError: () => toast.error(t("phoneNumberValidation.error.sendCode")),
  });

  const { mutate } = useMutation({
    mutationFn: enrollAccount,
    onSuccess: ({ authToken }, { phoneNumber }) => {
      if (!authToken) return navigate(routeToSignIn());
      setToken(authToken);
      toast.success(t("enRollAccount.success"));
      if (phoneNumber) sendCode();
      else navigate(routeToHouses());
    },
    onError: () => toast.error(t("error.unKnowError.message")),
  });

  return (
    <>
      <EnrollAccountPage token={token} submit={mutate} />

      {phoneNumberValidationOpen && (
        <PhoneNumberValidationPopup
          open={phoneNumberValidationOpen}
          onOpenChange={open => {
            if (!open) navigate(routeToHouses());
          }}
          onSuccess={() => navigate(routeToHouses())}
        />
      )}
    </>
  );
};

export { EnrollAccount };
