import { LeafIcon, SunIcon, TrendingArrowIcon, UserIcon, WindowIcon } from "@eisox/icons";

import { IconRoomDataLayer } from "~/UI/screens/Room/components";
import { intl } from "~/i18n";

import type { RoomsWithProblem, ValvesWithProblem } from "../../House";

enum RoomDataLayerEnum {
  autoConf = "autoConf",
  anticipation = "anticipation",
  openWin = "openWin",
  sun = "sun",
  autoPreConf = "autoPreConf",
}

enum SUN_CONDITIONS {
  TEMP = 26,
  LIGHT = 3000,
}

interface RoomDataLayerType {
  disabled: boolean;
  selected: boolean;
  icon: JSX.Element;
  translation?: string;
}

export type RoomDataType = Record<RoomDataLayerEnum, RoomDataLayerType>;

export const getRoomData = (
  room: RoomsWithProblem,
  valves?: Pick<ValvesWithProblem, "sensors">[],
  selectedValve?: Pick<ValvesWithProblem, "stateData" | "sensors">,
): RoomDataType => {
  // Valve ROOM DATA
  const {
    autoConf = false,
    anticipation = false,
    openWin = false,
    autoPreConf = false,
  } = selectedValve?.stateData ?? {};
  const { temperature, light } = selectedValve?.sensors ?? {};

  // Room ROOM DATA
  const { autoConfStart, anticipationStart, openWinStart, autoPreConfStart, isAutoPrecomf } = room;

  const openWinObject = (): RoomDataLayerType => {
    const selected = selectedValve ? openWin : !!openWinStart;
    return {
      disabled: false,
      selected,
      icon: <IconRoomDataLayer selected={selected} disabled={false} children={<WindowIcon />} />,
      translation: intl.formatMessage({ id: "room.roomData.openWin" }),
    };
  };

  const autoConfObject = (): RoomDataLayerType => {
    const selected = selectedValve ? autoConf : !!autoConfStart;
    return {
      disabled: false,
      selected,
      icon: <IconRoomDataLayer selected={selected} disabled={false} children={<UserIcon />} />,
      translation: intl.formatMessage({ id: "room.roomData.autoConf" }),
    };
  };

  const autoPreConfObject = (): RoomDataLayerType => {
    const selected = selectedValve ? autoPreConf : !!autoPreConfStart;
    const disabled = !isAutoPrecomf;
    return {
      disabled,
      selected,
      icon: <IconRoomDataLayer selected={selected} disabled={disabled} children={<LeafIcon />} />,
      translation: intl.formatMessage({ id: "room.roomData.autoPreConf" }),
    };
  };

  const anticipationObject = (): RoomDataLayerType => {
    const selected = selectedValve ? anticipation : !!anticipationStart;
    return {
      disabled: false,
      selected,
      icon: <IconRoomDataLayer selected={selected} disabled={false} children={<TrendingArrowIcon />} />,
      translation: intl.formatMessage({ id: "room.roomData.anticipation" }),
    };
  };

  const sunObject = () => {
    const selected = selectedValve
      ? temperature! > SUN_CONDITIONS.TEMP && light! > SUN_CONDITIONS.LIGHT
      : (valves?.some(v => v.sensors?.temperature! > SUN_CONDITIONS.TEMP && v.sensors?.light! > SUN_CONDITIONS.LIGHT) ??
        false);
    return {
      disabled: false,
      selected,
      icon: <IconRoomDataLayer selected={selected} disabled={false} children={<SunIcon />} />,
      translation: intl.formatMessage({ id: "room.roomData.sun" }),
    };
  };

  return {
    [RoomDataLayerEnum.openWin]: {
      ...openWinObject(),
    },
    [RoomDataLayerEnum.autoConf]: {
      ...autoConfObject(),
    },
    [RoomDataLayerEnum.autoPreConf]: {
      ...autoPreConfObject(),
    },
    [RoomDataLayerEnum.anticipation]: {
      ...anticipationObject(),
    },
    [RoomDataLayerEnum.sun]: {
      ...sunObject(),
    },
  };
};
