import * as React from "react";

import { IconProps } from "./types";

export const MapIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M14.036 1.5C13.9639 1.49997 13.8927 1.51673 13.8282 1.54897L10.3099 3.30863L6.33138 1.54015C6.32488 1.53738 6.31745 1.53689 6.31049 1.53411C6.29259 1.52742 6.27428 1.52185 6.2557 1.51741C6.23723 1.51243 6.21846 1.50854 6.19952 1.50579C6.16182 1.50269 6.12392 1.50269 6.08624 1.50579C6.06731 1.50854 6.04854 1.51243 6.03007 1.51741C6.01149 1.52185 5.99318 1.52742 5.97528 1.53411C5.96831 1.53689 5.96089 1.53735 5.95438 1.54015L1.7758 3.39731C1.60806 3.4718 1.49997 3.63813 1.5 3.82165V14.036C1.50008 14.1931 1.57955 14.3394 1.71124 14.4251C1.84297 14.5108 2.0091 14.5241 2.15277 14.4603L6.14286 12.6868L10.1329 14.4603C10.1399 14.4631 10.1473 14.4603 10.1543 14.4645C10.2667 14.5148 10.3957 14.5119 10.5058 14.4566C10.5132 14.4534 10.5215 14.4566 10.529 14.4515L14.2432 12.5944C14.4007 12.5157 14.5001 12.3548 14.5 12.1788V1.96451C14.5002 1.70809 14.2924 1.50011 14.036 1.5ZM5.67859 11.877L2.42857 13.3214V4.12345L5.67859 2.67904V11.877ZM9.85717 13.3214L6.60715 11.877V2.67904L9.85717 4.12342V13.3214ZM13.5715 11.8919L10.7857 13.2848V4.10857L13.5715 2.71571V11.8919V11.8919Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default MapIcon;
