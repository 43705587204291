import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { DELETE_METHOD, GET_METHOD, PATCH_METHOD, POST_METHOD } from "../constants/fetchConstants";
import { internalFetchByUrl } from "../helpers/communication/FetchApi";
import { planRoute, routeHouses } from "../routesStatic/routesExpress";

const ServerUrl = env.SERVER_URL;

/**
 * This function creates a plan for a house by sending a POST request with the plan name and image file
 * to a specified server URL.
 * @param idHouse - The ID of the house for which the plan is being created.
 * @param file - The `file` parameter is a file object that contains the image data of the plan to be
 * uploaded. It is being appended to a `FormData` object along with the `name` parameter, which is a
 * string representing the name of the plan. The `FormData` object is then sent as the
 * @param namePlan - The name of the plan that will be created.
 * @returns The `createPlan` function is returning the result of calling the `internalFetchByUrl`
 * function with the `RouteAddPlan`, `POST_METHOD`, `customHeaders`, and `formData` parameters.
 */
export async function createPlan(idHouse, formData) {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteAddPlan = ServerUrl + routeHouses + "/" + idHouse + planRoute;

  return internalFetchByUrl(RouteAddPlan, POST_METHOD, customHeaders, formData);
}

/**
 * This function edits a plan for a house by sending a PATCH request to the server with updated
 * information such as a new image or name.
 * @param idHouse - The ID of the house for which the plan is being edited.
 * @param file - The file parameter is a file object that contains the image file to be uploaded.
 * @param namePlan - The name of the plan that is being edited.
 * @param idPlan - The ID of the plan that needs to be edited.
 * @returns the result of the `internalFetchByUrl` function, which is likely a Promise that resolves to
 * the response of a PATCH request to the specified URL with the provided custom headers and form data.
 */
export async function editPlan(idHouse, planId, formData) {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteAddPlan = ServerUrl + routeHouses + "/" + idHouse + planRoute + planId;

  return internalFetchByUrl(RouteAddPlan, PATCH_METHOD, customHeaders, formData);
}

/**
 * This function retrieves a plan from a specific house using the plan's ID and the house's ID.
 * @param idPlan - The id of the plan that you want to retrieve.
 * @param idHouse - The id of the house for which the plan is being fetched.
 * @returns the result of the `internalFetchByUrl` function, which is the response from making a GET
 * request to the specified URL with the custom headers.
 */
export function getPlan(idPlan, idHouse) {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RoutePlanRemove = ServerUrl + routeHouses + "/" + idHouse + planRoute + idPlan;
  return internalFetchByUrl(RoutePlanRemove, GET_METHOD, customHeaders);
}

/**
 * This function removes a plan from a house using the provided plan and house IDs.
 * @param idPlan - The ID of the plan that needs to be removed.
 * @param idHouse - The id of the house from which the plan needs to be removed.
 * @returns the result of the `internalFetchByUrl` function, which is the response from making a DELETE
 * request to the specified URL with the custom headers.
 */
export function removePlan(idPlan, idHouse) {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RoutePlanRemove = ServerUrl + routeHouses + "/" + idHouse + planRoute + idPlan;
  return internalFetchByUrl(RoutePlanRemove, DELETE_METHOD, customHeaders);
}
