import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { DELETE_METHOD, GET_METHOD, POST_METHOD, PUT_METHOD } from "../constants/fetchConstants";
import { internalFetchByUrl } from "../helpers/communication/FetchApi";
import { planningsRouteConcat, planningsStatRoute, routeHouses } from "../routesStatic/routesExpress";

const ServerUrl = env.SERVER_URL;

export async function postPlanningById(houseId, body) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteEditPlanning = `${ServerUrl + routeHouses}/${houseId}${planningsRouteConcat}`;
  fetchingResponse = internalFetchByUrl(RouteEditPlanning, POST_METHOD, customHeaders, body);
  return fetchingResponse;
}

export async function deletePlanningById(idPlanning, idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteDeletePlanning = ServerUrl + routeHouses + "/" + idHouse + planningsRouteConcat + idPlanning;
  fetchingResponse = internalFetchByUrl(RouteDeletePlanning, DELETE_METHOD, customHeaders);
  return fetchingResponse;
}

export async function getPlanningsOfRoom(idHouse, roomId) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteGetPlannings = ServerUrl + routeHouses + "/" + idHouse + planningsRouteConcat + "rooms/" + roomId;
  fetchingResponse = internalFetchByUrl(RouteGetPlannings, GET_METHOD, customHeaders);
  return fetchingResponse;
}

export function getPlanningsStat(idHouse) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteGetStatsPlanning = ServerUrl + routeHouses + "/" + idHouse + planningsStatRoute;
  fetchingResponse = internalFetchByUrl(RouteGetStatsPlanning, GET_METHOD, customHeaders);
  return fetchingResponse;
}

export function putPlanning(houseId, body) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteEditPlanning = `${ServerUrl + routeHouses}/${houseId}${planningsRouteConcat}`;
  fetchingResponse = internalFetchByUrl(RouteEditPlanning, PUT_METHOD, customHeaders, body);
  return fetchingResponse;
}
