import type { RouteObject } from "react-router-dom";

import type { QueryClient } from "@tanstack/react-query";

import { users } from "~/routes/routes";

import Users from "./index";
import { loader } from "./loader";

export const route = (queryClient: QueryClient): RouteObject => ({
  path: users,
  loader: loader(queryClient),
  element: <Users />,
});
