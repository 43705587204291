import type { ComponentPropsWithRef as Table } from "react";
import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import styles from "./Table.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Container
 * -----------------------------------------------------------------------------------------------*/

type ContainerProps = Table<"div">;

const Container = forwardRef<HTMLDivElement, ContainerProps>(({ className, ...props }, ref) => (
  <div {...props} ref={ref} className={cx(styles.container, className)} />
));

Container.displayName = "TableContainer";

/* -------------------------------------------------------------------------------------------------
 * Table
 * -----------------------------------------------------------------------------------------------*/

type TableProps = Table<"table">;

const Root = forwardRef<HTMLTableElement, TableProps>(({ className, ...props }, ref) => (
  <table {...props} ref={ref} className={cx(styles.table, className)} />
));

Root.displayName = "Table";

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/

type HeaderProps = Table<"thead">;

const Header = forwardRef<HTMLTableSectionElement, HeaderProps>(({ className, ...props }, ref) => (
  <thead {...props} ref={ref} className={cx(styles.header, className)} />
));

Header.displayName = "TableHeader";

/* -------------------------------------------------------------------------------------------------
 * Body
 * -----------------------------------------------------------------------------------------------*/

type BodyProps = Table<"tbody">;

const Body = forwardRef<HTMLTableSectionElement, BodyProps>(({ className, ...props }, ref) => (
  <tbody {...props} ref={ref} className={className} />
));

Body.displayName = "TableBody";

/* -------------------------------------------------------------------------------------------------
 * Footer
 * -----------------------------------------------------------------------------------------------*/

type FooterProps = Table<"tfoot">;

const Footer = forwardRef<HTMLTableSectionElement, FooterProps>(({ className, ...props }, ref) => (
  <tfoot {...props} ref={ref} className={className} />
));

Footer.displayName = "TableFooter";

/* -------------------------------------------------------------------------------------------------
 * Row
 * -----------------------------------------------------------------------------------------------*/

type RowProps = Table<"tr">;

const Row = forwardRef<HTMLTableRowElement, RowProps>(({ className, ...props }, ref) => (
  <tr {...props} ref={ref} className={cx(styles.row, className)} />
));

Row.displayName = "TableRow";

/* -------------------------------------------------------------------------------------------------
 * Head
 * -----------------------------------------------------------------------------------------------*/

type HeadProps = Table<"th">;

const Head = forwardRef<HTMLTableCellElement, HeadProps>(({ className, ...props }, ref) => (
  <th {...props} ref={ref} className={cx(styles.head, className)} />
));

Head.displayName = "TableHead";

/* -------------------------------------------------------------------------------------------------
 * Cell
 * -----------------------------------------------------------------------------------------------*/

type CellProps = Table<"td">;

const Cell = forwardRef<HTMLTableCellElement, CellProps>(({ className, ...props }, ref) => (
  <td {...props} ref={ref} className={cx(styles.cell, className)} />
));

Cell.displayName = "TableCell";

/* -------------------------------------------------------------------------------------------------
 * Caption
 * -----------------------------------------------------------------------------------------------*/

type CaptionProps = Table<"caption">;

const Caption = forwardRef<HTMLTableCaptionElement, CaptionProps>(({ className, ...props }, ref) => (
  <caption {...props} ref={ref} className={className} />
));

Caption.displayName = "TableCaption";

export { Body, Caption, Cell, Container, Footer, Head, Header, Root, Row };
export type {
  BodyProps,
  CaptionProps,
  CellProps,
  ContainerProps,
  FooterProps,
  HeadProps,
  HeaderProps,
  RowProps,
  TableProps,
};
