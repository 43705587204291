import type { GatewayErrors, GatewayWarnings } from "@eisox/problems-handler";

import type { GatewayProblemType } from "~/features/InterventionPlanTool";
import { ProblemGatewayByError } from "~/features/InterventionPlanTool/components/InterventionPlanTool/utils";

const getResolutionFromGatewayProblems = (problem: (GatewayErrors | GatewayWarnings)[]) =>
  (Object.keys(ProblemGatewayByError) as GatewayProblemType[]).find(resolution =>
    ProblemGatewayByError[resolution].some(p => problem.includes(p)),
  );

export { getResolutionFromGatewayProblems };
