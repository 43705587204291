import { client } from "../api";
import type { NotifyRequestBody, UpdateGatewayRequestBody } from "../types";

const updateGateways = async (houseId: string, body: UpdateGatewayRequestBody) => {
  const { data } = await client.PATCH("/houses/{idHouse}/gateways", { params: { path: { idHouse: houseId } }, body });
  return data?.message;
};

const notify = async (idHouse: string, body: NotifyRequestBody) => {
  const { data, error } = await client.POST("/houses/notify/{idHouse}", { params: { path: { idHouse } }, body });
  if (error) throw new Error(error.message);
  return data?.message;
};

export { notify, updateGateways };
