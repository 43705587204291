import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import { useControllableState } from "@eisox/tools";
import * as RadixToggleGroup from "@radix-ui/react-toggle-group";

import styles from "./ToggleGroup.module.scss";

/* -------------------------------------------------------------------------------------------------
 * ToogleGroup
 * -----------------------------------------------------------------------------------------------*/

type ToggleGroupProps = Omit<RadixToggleGroup.ToggleGroupSingleProps, "type">;

const Root = forwardRef<HTMLDivElement, ToggleGroupProps>((props, forwardRef) => {
  const { value: valueProp, onValueChange, defaultValue, className, ...toggleGroupProps } = props;

  const [value, setValue] = useControllableState({
    prop: valueProp,
    onChange: onValueChange,
    defaultProp: defaultValue,
  });

  const handleValueChange = (newValue: string) => {
    if (newValue) setValue(newValue);
  };

  return (
    <RadixToggleGroup.Root
      {...toggleGroupProps}
      ref={forwardRef}
      type="single"
      className={cx(styles.toggleGroup, className)}
      value={value}
      onValueChange={handleValueChange}
    />
  );
});

Root.displayName = "Maintenance.ToggleGroup";

/* -------------------------------------------------------------------------------------------------
 * Item
 * -----------------------------------------------------------------------------------------------*/

type ItemProps = RadixToggleGroup.ToggleGroupItemProps;

const Item = forwardRef<HTMLButtonElement, ItemProps>(({ className, ...props }, forwardRef) => (
  <RadixToggleGroup.Item {...props} ref={forwardRef} className={cx(styles.toggleGroup__item, className)} />
));

Item.displayName = "Maintenance.ToggleGroupItem";

export { Item, Root };
export type { ItemProps, ToggleGroupProps };
