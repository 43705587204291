import type { LoaderFunction } from "react-router-dom";
import { RouterProvider, createMemoryRouter } from "react-router-dom";

import type {
  HousesHouseIdBoilerroomsPosGet200ResponseMessageInner,
  MeshsMessageMeshsInner,
} from "@eisox/backend_webapp_api";
import dayjs from "@eisox/dayjs";
import { MECA_PROBLEMS_ENUM, VALVE_HARDWARE_VERSION_ENUM } from "@eisox/valves";
import { GatewaysMessageGatewaysBrModRf, ValvesMessageValvesStatus } from "@eisox/webapp-api-specification";
import { faker } from "@faker-js/faker";
import type { StoryContext, StoryFn } from "@storybook/react";

import type { GatewaysWithProblem, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import type { BoilerRoomModule, Gateway, HeatingNetwork, House, Mesh, Plan, Room, Valve } from "~/apiV2";
import { GatewayHardwareVersion } from "~/apiV2/types";
import {
  GatewayBuilder,
  HouseBuilder,
  MeshBuilder,
  PlanBuilder,
  RestBoilerRoomModuleBuilder,
  RoomBuilder,
  ValveBuilder,
} from "~/mocks";

import { gatewaysToGatewaysWithProblem } from "./Gateway";
import { roomsToRoomsWithProblem } from "./Room";
import { valvesToValvesWithProblem } from "./Valve";

export const houseMock: House = new HouseBuilder().build();

export const plansMock: Plan[] = Array.from({ length: 10 }, () => new PlanBuilder().build());

export const meshsMock: Mesh[] = Array.from({ length: 3 }, () => new MeshBuilder().build());

export const roomsMock: Room[] = Array.from({ length: 15 }, () => {
  const currentPlan = faker.helpers.arrayElement(plansMock);
  return new RoomBuilder().with("plan", p => ({ ...p, planId: currentPlan.id })).build();
});

export const gatewaysMock: Gateway[] = Array.from({ length: 10 }, () => {
  const currentPlan = faker.helpers.arrayElement(plansMock);
  const currentMesh = faker.helpers.arrayElement(meshsMock);
  const isWifiEnabled = faker.datatype.boolean();
  return new GatewayBuilder()
    .with("softwareVersion", faker.system.semver())
    .with("mac", faker.internet.mac())
    .with("lastGatewayActivity", faker.date.recent().toISOString())
    .with("hardwareVersion", faker.helpers.enumValue(GatewayHardwareVersion))
    .with("brModRf", faker.helpers.enumValue(GatewaysMessageGatewaysBrModRf))
    .with("ip", faker.internet.ip())
    .with("expireAt", faker.date.future().toISOString())
    .with("borderRouterSoftwareVersion", faker.system.semver())
    .with("isEthUp", faker.datatype.boolean())
    .with("plan", p => ({ ...p, planId: currentPlan.id }))
    .with("isWifiEnabled", isWifiEnabled)
    .with("meshs", isWifiEnabled ? [{ id: currentMesh.id, mac: faker.internet.mac() }] : [])
    .build();
});

export const valvesMock: Valve[] = Array.from({ length: 120 }, () => {
  const currentGateway = faker.helpers.arrayElement(gatewaysMock);
  const currentRoom = faker.helpers.arrayElement(roomsMock);

  const openPos = faker.number.int({ min: 0, max: 1630 });
  const closePos = faker.number.int({ min: openPos, max: 1630 });

  return new ValveBuilder()
    .with("gatewayId", currentGateway.id)
    .with("roomId", currentRoom.id)
    .with("plan", p => ({ ...p, planId: currentRoom.plan.planId }))
    .with("hardwareVersion", faker.helpers.enumValue(VALVE_HARDWARE_VERSION_ENUM))
    .with("mac", faker.internet.mac())
    .with(
      "mecaProblems",
      faker.helpers
        .arrayElements(
          Object.values(MECA_PROBLEMS_ENUM),
          faker.number.int({ min: 0, max: Object.keys(MECA_PROBLEMS_ENUM).length / 7 }),
        )
        .map(problem => ({
          name: problem,
          updatedAt: faker.date.recent().toISOString(),
        })),
    )
    .with("status", ValvesMessageValvesStatus.testPluggedOK)
    .with("softwareVersion", "4.52.2")
    .with("lqi", faker.number.int({ min: 0, max: 255 }))
    .with("rssi", faker.number.int({ min: -100, max: 0 }))
    .with("closing", faker.number.int({ min: 0, max: 100 }))
    .with("correction", faker.number.float({ min: 0, max: 1 }))
    .with("correctedTemp", faker.number.int({ min: 0, max: 20 }))
    .with("updatedAt", dayjs().toISOString())
    .with("sensors", {
      IRCounter: faker.number.int({ min: 0, max: 10 }),
      humidity: faker.number.int({ min: 0, max: 100 }),
      indoorAirQuality: faker.number.int({ min: 0, max: 350 }),
      light: faker.number.int({ min: 0, max: 1000 }),
      pressure: faker.number.int({ min: 0, max: 1000 }),
      soundCounter: faker.number.int({ min: 0, max: 10 }),
      temperature: faker.number.int({ min: 15, max: 25 }),
    })
    .with("stateData", {
      openPos,
      closePos,
      posUpdatedAt: faker.date.recent().toISOString(),
      batZ: faker.number.int({ min: 0, max: 7000 }),
      batZUpdatedAt: faker.date.recent().toISOString(),
      dateBat: faker.date.recent().toISOString(),
      batLowDate: faker.date.recent().toISOString(),
      swOffset: faker.number.int({ min: -2, max: 2 }),
    })
    .build();
});

const modulesMock: BoilerRoomModule[] = [
  new RestBoilerRoomModuleBuilder().build(),
  new RestBoilerRoomModuleBuilder().build(),
];

const houseLoader = () => {
  const date = dayjs();
  const house: House = houseMock;
  const plans: Plan[] = plansMock;
  const gateways: GatewaysWithProblem[] = gatewaysToGatewaysWithProblem(gatewaysMock, plansMock, meshsMock, date);
  const valves: ValvesWithProblem[] = valvesToValvesWithProblem(valvesMock, plansMock, gatewaysMock, roomsMock, date);
  const rooms: RoomsWithProblem[] = roomsToRoomsWithProblem(roomsMock, plansMock, valves, gatewaysMock, house, date);
  const modules: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[] = modulesMock;
  const meshs: MeshsMessageMeshsInner[] = meshsMock;
  const heatingNetworks: HeatingNetwork[] = [];
  return { house, plans, rooms, gateways, valves, modules, meshs, heatingNetworks };
};

export const withHouseLoader = (
  storyFn: StoryFn,
  context: StoryContext,
  pathname: string,
  initialEntry: string,
  loader?: LoaderFunction,
) => {
  const Element: React.FC = () => <> {storyFn({ ...context.args }, context)} </>;

  const router = createMemoryRouter(
    [
      {
        id: "house",
        path: "/houses/:houseId",
        loader: houseLoader,
        children: [
          {
            path: `/houses/:houseId/${pathname}`,
            loader: loader,
            element: <Element />,
          },
        ],
      },
    ],
    { initialEntries: [`/houses/${houseMock._id}/${initialEntry}`] },
  );
  return <RouterProvider router={router} />;
};
