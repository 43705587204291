import type { HousesHouseIdBoilerroomsPosGet200ResponseMessageInner } from "@eisox/backend_webapp_api";

import type { BoilerRoom } from "~/socketio/types";

import type { Parent } from "../components";

const checkInconsistencies = <T extends keyof BoilerRoom>(
  boilerrooms: BoilerRoom[],
  property: T,
): {
  property: T;
  value: BoilerRoom[T];
  inconsistency: Map<string, BoilerRoom[T]> | null;
} | null => {
  if (boilerrooms.length === 0) return null;

  const referenceValue = boilerrooms[0][property];

  const inconsistencies = new Map();
  if (!boilerrooms.every(b => b[property] === referenceValue)) {
    boilerrooms.forEach(b => inconsistencies.set(b.name, b[property]));
  }

  return {
    property: property,
    value: referenceValue,
    inconsistency: inconsistencies.size === 0 ? null : inconsistencies,
  };
};

const getBoilerRoomsFromModules = (modules: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner[]) =>
  modules.flatMap(bp => bp.boilerRooms ?? []);

function findNearestAncestor(boilerRooms: BoilerRoom[], currentBoilerRoom: BoilerRoom): BoilerRoom | null {
  if (!currentBoilerRoom.objectNameParent) {
    return currentBoilerRoom; // Reached the topmost ancestor
  }
  const parentBoilerRoom = boilerRooms.find(room =>
    room.heatingNetworks?.some(network => network.objectName === currentBoilerRoom.objectNameParent),
  );
  if (!parentBoilerRoom) {
    return currentBoilerRoom; // If parent BoilerRoom not found, return current BoilerRoom
  }
  return parentBoilerRoom;
}

const findFarthestAncestor = (boilerRoomId: string, boilerRooms: BoilerRoom[]): BoilerRoom | null => {
  const currentBoilerRoom = boilerRooms.find(b => b.id === boilerRoomId);
  if (!currentBoilerRoom) return null;

  if (!currentBoilerRoom.objectNameParent) return null;

  const parentBoilerRoom = boilerRooms.find(b =>
    b.heatingNetworks?.some(hn => hn.objectName === currentBoilerRoom.objectNameParent),
  );
  if (!parentBoilerRoom) return null;

  return findFarthestAncestor(parentBoilerRoom.id, boilerRooms) ?? parentBoilerRoom;
};

const getParentBoilerRoom = (boilerroom: BoilerRoom, boilerrooms: BoilerRoom[]): Parent | undefined => {
  const parentHeatingNetwork = boilerrooms
    .flatMap(b => b.heatingNetworks)
    .find(hn => hn?.objectName === boilerroom.objectNameParent);
  const parentBoilerroom =
    parentHeatingNetwork && boilerrooms.find(b => b.heatingNetworks?.includes(parentHeatingNetwork));
  if (!parentHeatingNetwork || !parentBoilerroom) return;
  return {
    id: parentBoilerroom.id,
    name: parentHeatingNetwork.name ?? "-",
    boilerroomName: parentBoilerroom.name ?? "-",
  };
};

export {
  checkInconsistencies,
  findFarthestAncestor,
  findNearestAncestor,
  getBoilerRoomsFromModules,
  getParentBoilerRoom,
};
