import * as React from "react";

import { IconProps } from "./types";

export const BinIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M9.79529 6.20994C9.62712 6.20994 9.49083 6.34627 9.49083 6.5144V12.2685C9.49083 12.4366 9.62712 12.573 9.79529 12.573C9.96347 12.573 10.0998 12.4366 10.0998 12.2685V6.5144C10.0998 6.34627 9.96347 6.20994 9.79529 6.20994Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M7.9993 6.20994C7.83116 6.20994 7.69488 6.34627 7.69488 6.5144V12.2685C7.69488 12.4366 7.83116 12.573 7.9993 12.573C8.16747 12.573 8.30376 12.4366 8.30376 12.2685V6.5144C8.30376 6.34627 8.16747 6.20994 7.9993 6.20994Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M6.20251 6.20994C6.03435 6.20994 5.89806 6.34627 5.89806 6.5144V12.2685C5.89806 12.4366 6.03435 12.573 6.20251 12.573C6.37067 12.573 6.50696 12.4366 6.50696 12.2685V6.5144C6.50696 6.34627 6.37067 6.20994 6.20251 6.20994Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M3.58469 5.37023V12.8712C3.58469 13.3146 3.74725 13.7309 4.03125 14.0297C4.31393 14.3292 4.70734 14.4993 5.11906 14.5H10.8793C11.2912 14.4993 11.6846 14.3292 11.9672 14.0297C12.2511 13.7309 12.4137 13.3146 12.4137 12.8712V5.37023C12.9783 5.22038 13.3441 4.67499 13.2685 4.0957C13.1929 3.51654 12.6995 3.08329 12.1153 3.08317H10.5566V2.70261C10.5583 2.38258 10.4318 2.07528 10.2053 1.8492C9.97872 1.62325 9.67093 1.49742 9.35091 1.50004H6.64749C6.32746 1.49742 6.01968 1.62325 5.79313 1.8492C5.56657 2.07528 5.44004 2.38258 5.44182 2.70261V3.08317H3.88307C3.29891 3.08329 2.80549 3.51654 2.72985 4.0957C2.65433 4.67499 3.02015 5.22038 3.58469 5.37023ZM10.8793 13.8911H5.11906C4.59852 13.8911 4.19358 13.444 4.19358 12.8712V5.39698H11.8048V12.8712C11.8048 13.444 11.3999 13.8911 10.8793 13.8911ZM6.05072 2.70261C6.0487 2.54409 6.11101 2.39151 6.22352 2.2796C6.3359 2.16769 6.48884 2.1062 6.64749 2.10894H9.35091C9.50955 2.1062 9.66248 2.16769 9.77488 2.2796C9.88738 2.39138 9.94969 2.54409 9.94765 2.70261V3.08317H6.05072V2.70261ZM3.88307 3.69207H12.1153C12.418 3.69207 12.6633 3.93741 12.6633 4.24008C12.6633 4.54275 12.418 4.78809 12.1153 4.78809H3.88307C3.5804 4.78809 3.33506 4.54275 3.33506 4.24008C3.33506 3.93741 3.5804 3.69207 3.88307 3.69207Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default BinIcon;
