import { useIntl } from "react-intl";

import { Circle, TextInput } from "@eisox/design-system";
import { PlusIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./AddInput.module.scss";

interface AddInputProps {
  groupNames: string[];
  onAddGroup: (groupName: string) => void;
  value: string;
  handleChangeValue: (value: string) => void;
  handleFocus: VoidFunction;
}

export const AddInput: React.FC<AddInputProps> = ({
  groupNames,
  onAddGroup,
  value,
  handleChangeValue,
  handleFocus,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const addInputStyle = bem("add-input");

  const disabled: boolean = groupNames.includes(value) || value === "";

  const addGroup = () => {
    if (!disabled) {
      onAddGroup(value);
      handleChangeValue("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addGroup();
    }
  };

  return (
    <div className={addInputStyle()}>
      <TextInput
        className={addInputStyle("text-input")}
        value={value}
        onFocus={handleFocus}
        onChange={e => handleChangeValue(e.target.value)}
        placeholder={formatMessage({ id: "settings.content.menu.rooms.groups.name.placeholder" })}
        onKeyDown={handleKeyDown}
      />
      <Circle
        size={20}
        selected
        disabled={disabled}
        className={addInputStyle("plus", { disabled })}
        onClick={() => addGroup()}
      >
        <PlusIcon />
      </Circle>
    </div>
  );
};
