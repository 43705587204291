import type { RouteObject } from "react-router-dom";

import type { QueryClient } from "@tanstack/react-query";

import { Settings, seasonAction, valvesAction } from "~/UI/screens";
import { Consumption, consumptionLoader } from "~/UI/screens/Consumption";
import { GTB } from "~/UI/screens/GTB";
import { action as updateGatewayAction } from "~/UI/screens/Gateway";
import { action as gatewaysAction } from "~/UI/screens/Gateways";
import { House, action as houseAction, loader as houseLoader } from "~/UI/screens/House";
import { meshsAction } from "~/UI/screens/Meshs";
import { action as moduleAction } from "~/UI/screens/Module";
import { action as modulesAction } from "~/UI/screens/Modules";
import { action as notifyAction } from "~/UI/screens/Notify";
import { Plan, action as planAction, loader as planLoader } from "~/UI/screens/Plan";
import { action as planningAction } from "~/UI/screens/Planning";
import { action as planningsAction } from "~/UI/screens/Plannings";
import { Plans, action as plansAction } from "~/UI/screens/Plans";
import { preinstallGatewaysAction, preinstallValvesAction } from "~/UI/screens/Preinstall";
import { Resources } from "~/UI/screens/Resources";
import { Room, action as roomAction, loader as roomLoader } from "~/UI/screens/Room";
import { Rooms, action as roomsAction } from "~/UI/screens/Rooms";
import {
  FrostFree,
  Function,
  General,
  Groups,
  Index,
  Module as ModuleSettings,
  Modules,
  Planning,
  RemoteAccess,
  RoomFrostFree,
  Season,
  Temperatures,
  frostFreeAction,
} from "~/UI/screens/Settings";
import { action as valveAction } from "~/UI/screens/Valve";
import {
  consumption,
  frostFreeSettings,
  functionSettings,
  gateway,
  gateways,
  generalSettings,
  groupsSettings,
  gtb,
  gtbSettings,
  histovalvesStats,
  house,
  housesSettings,
  meshs,
  module,
  moduleSettings,
  modulesSettings,
  notify,
  plan,
  planning,
  planningSettings,
  plannings,
  plans,
  preinstallGateways,
  preinstallValves,
  remoteAccessSettings,
  resources,
  room,
  roomFrostFreeSettings,
  rooms,
  roomsAction as roomsActionRoute,
  roomsSettings,
  seasonSettings,
  settings,
  temperaturesSettigns,
  valve,
  valves,
} from "~/routes/routes";
import { idLoaderHouse, routeToConsumption } from "~/routes/utils";

import { route as boilerRoomRoute } from "./boilerroom.$boilerroomId";
import { route as dashboardRoute } from "./dashboard";
import { route as maintenanceRoute } from "./maintenance/route";
import { route as usersRoute } from "./users/route";

export const route = (queryClient: QueryClient): RouteObject => ({
  id: idLoaderHouse,
  path: house,
  loader: houseLoader(queryClient),
  action: houseAction(queryClient),
  shouldRevalidate: ({ currentParams, formAction, defaultShouldRevalidate }) =>
    formAction === histovalvesStats ||
    formAction === `/history/houses/${currentParams.houseId}/modules` ||
    (currentParams.houseId && formAction?.split("?")[0] === routeToConsumption(currentParams.houseId))
      ? false
      : defaultShouldRevalidate,
  element: <House />,
  children: [
    dashboardRoute,
    {
      path: plans,
      action: plansAction,
      element: <Plans />,
      children: [
        {
          path: plan,
          action: planAction,
          loader: planLoader,
          shouldRevalidate: ({ formAction, defaultShouldRevalidate }) =>
            formAction === histovalvesStats ? false : defaultShouldRevalidate,
          element: <Plan />,
        },
      ],
    },
    {
      path: rooms,
      element: <Rooms />,
    },
    {
      path: room,
      action: roomAction,
      loader: roomLoader,
      shouldRevalidate: ({ formAction, defaultShouldRevalidate }) =>
        formAction === histovalvesStats ? false : defaultShouldRevalidate,
      element: <Room />,
    },
    { path: consumption, loader: consumptionLoader, element: <Consumption /> },
    boilerRoomRoute,
    { path: gtb, element: <GTB /> },
    {
      path: module,
      action: moduleAction,
    },
    {
      path: plannings,
      action: planningsAction,
      children: [
        {
          path: planning,
          action: planningAction,
        },
      ],
    },
    maintenanceRoute,
    usersRoute(queryClient),
    {
      path: resources + "/*",
      element: <Resources />,
    },
    {
      path: settings,
      element: <Settings />,
      children: [
        {
          index: true,
          element: <Index />,
        },
        {
          path: housesSettings,
          children: [
            { path: generalSettings, element: <General /> },
            {
              path: temperaturesSettigns,
              element: <Temperatures />,
            },
            { path: frostFreeSettings, element: <FrostFree />, action: frostFreeAction },
            { path: seasonSettings, element: <Season />, action: seasonAction },
            { path: remoteAccessSettings, element: <RemoteAccess /> },
          ],
        },
        {
          path: gtbSettings,
          children: [
            {
              path: modulesSettings,
              element: <Modules />,
              children: [
                {
                  path: moduleSettings,
                  element: <ModuleSettings />,
                },
              ],
            },
          ],
        },
        {
          path: roomsSettings,
          children: [
            {
              path: roomFrostFreeSettings,
              element: <RoomFrostFree />,
            },
            {
              path: functionSettings,
              element: <Function />,
            },
            {
              path: planningSettings,
              element: <Planning />,
            },
            {
              path: groupsSettings,
              element: <Groups />,
            },
          ],
        },
      ],
    },
    { path: gateways, action: gatewaysAction },
    { path: gateway, action: updateGatewayAction },
    { path: valves, action: valvesAction },
    { path: valve, action: valveAction },
    { path: roomsActionRoute, action: roomsAction },
    { path: preinstallValves, action: preinstallValvesAction },
    { path: preinstallGateways, action: preinstallGatewaysAction },
    { path: meshs, action: meshsAction },
    { path: "/app/houses/:houseId/modules", action: modulesAction },
    { path: notify, action: notifyAction },
  ],
});
