import { useState } from "react";

import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ActionButton, Modal, TextInput } from "@eisox/design-system";
import { ArrowRightIcon, NetworkIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Chart } from "~/features/HeatingNetworks/HeatingCurve";
import type { HeatingCurve as HeatingCurveType, HeatingNetwork } from "~/socketio/types";

import { useBoilerRoomContextWithCurrentBoilerRoom } from "../../providers";

import styles from "./HeatingCurveDialog.module.scss";

const NAME = "HeatingCurveDialog";

type UpdateType = [
  { id: string; heatingNetworks: [{ id: string; name?: string; heatingCurves?: HeatingCurveType[] }] },
];

interface HeatingCurveDialogProps {
  network: HeatingNetwork;
  children?: React.ReactNode;
}

export const HeatingCurveDialog: React.FC<HeatingCurveDialogProps> = ({ network, children }) => {
  const { t } = useTranslation();

  const bem = useBem(styles);
  const heatingCurveDialogStyle = bem("heating-curve-dialog");
  const contentStyle = bem("content");
  const pointsStyle = bem("points");
  const graphStyle = bem("graph");

  const { currentBoilerRoom, useUpdateBoilerRoom } = useBoilerRoomContextWithCurrentBoilerRoom(NAME);

  const { mutate } = useUpdateBoilerRoom({
    onSuccess: () => handleOpenChange(false),
  });

  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { isDirty, dirtyFields, errors },
    reset,
    watch,
  } = useForm<HeatingNetwork>({
    defaultValues: {
      name: network.name,
      heatingCurves: network.heatingCurves,
    },
  });
  const { fields } = useFieldArray({ control, name: "heatingCurves" });
  const watchHeatingCurves = watch("heatingCurves");
  const legendStyle = bem("legend");

  const onSubmit = (data: HeatingNetwork) => {
    if (!isDirty) return;
    const updates: UpdateType = [{ id: currentBoilerRoom.id, heatingNetworks: [{ id: network.id }] }];
    if (dirtyFields.name) {
      updates[0].heatingNetworks[0].name = data.name;
    }
    if (dirtyFields.heatingCurves) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      updates[0].heatingNetworks[0].heatingCurves = data.heatingCurves?.map(({ offset, ...hc }) => ({ ...hc }));
    }
    mutate(updates);
  };

  const handleOpenChange = (open: boolean) => {
    reset({ name: network.name, heatingCurves: network.heatingCurves });
    setOpen(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={heatingCurveDialogStyle()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            title={t("network.content.heatingCurve.dialog.heatingCurve.title", { hn: network.name })}
            subtitle={t("network.content.heatingCurve.dialog.heatingCurve.subtitle")}
            icon={<NetworkIcon />}
          >
            <Modal.Close asChild>
              <ActionButton variant="cancel" text={t("boilerRoom.dialog.network.header.cancel")} />
            </Modal.Close>
            <ActionButton
              type="submit"
              icon={<ArrowRightIcon />}
              text={t("boilerRoom.dialog.network.header.save")}
              disabled={!isDirty}
              rounded
            />
          </Modal.Header>
          <TextInput
            className={heatingCurveDialogStyle("name")}
            {...register("name", { required: t("error.emptyField") })}
            label={t("network.content.heatingCurve.dialog.heatingCurve.content.name.name")}
          />
          {network.heatingCurves && network.heatingCurves.length > 0 && (
            <div className={contentStyle()}>
              <div className={pointsStyle()}>
                <div className={pointsStyle("legend")}>
                  <p>
                    {t("network.content.heatingCurve.dialog.heatingCurve.content.points.legend.externalTemperature")}
                  </p>
                  <p>
                    {t("network.content.heatingCurve.dialog.heatingCurve.content.points.legend.setpointTemperature")}
                  </p>
                </div>
                {fields.map((_, i) => {
                  const error = errors.heatingCurves?.[i];
                  return (
                    <div key={i} className={pointsStyle("point")}>
                      <p>{t("network.content.heatingCurve.dialog.heatingCurve.content.points.point", { i: i + 1 })}</p>
                      <TextInput
                        {...register(`heatingCurves.${i}.ext`, {
                          valueAsNumber: true,
                          required: true,
                        })}
                        className={pointsStyle("input")}
                        error={error?.ext}
                      />
                      <TextInput
                        {...register(`heatingCurves.${i}.con`, {
                          valueAsNumber: true,
                          required: true,
                        })}
                        className={pointsStyle("input")}
                        error={error?.con}
                      />
                      <p>°C</p>
                    </div>
                  );
                })}
              </div>
              <div className={graphStyle()}>
                <Chart auto={false} points={watchHeatingCurves ?? []} />
              </div>
              <div className={legendStyle()}>
                <p className={legendStyle("curve")}>
                  {t("network.content.heatingCurve.dialog.heatingCurve.content.points.legend.manual")}
                </p>
              </div>
            </div>
          )}
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
