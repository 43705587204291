import { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import { isDesktop } from "react-device-detect";
import { useIntl } from "react-intl";
import { useParams, useRouteLoaderData } from "react-router-dom";

import type { Option } from "@eisox/design-system";
import { SearchInput, Select, Switch, TextInput, Typography } from "@eisox/design-system";
import { ShiftIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import { RoomFC } from "~/UI/components/Room";
import { PlanV2 } from "~/UI/layouts/PlanV2";
import type { ItemType } from "~/UI/layouts/PlanV2/components";
import { Origin } from "~/UI/layouts/PlanV2/components";
import { Valve } from "~/UI/layouts/Valve";
import type { RoomsWithProblem, ValvesWithProblem, loader as houseLoader } from "~/UI/screens/House";
import { getPlan } from "~/api/plan";
import { SUCCESS_FETCH } from "~/constants/fetchConstants";
import { useBoilerRoomContext } from "~/features/BoilerRooms";
import { usePermissionsContext } from "~/providers";
import type { Permission } from "~/utils";
import { flattenItems, selectionItems } from "~/utils/planUtils";

import {
  createValveObjects,
  filterValvesByUidMacOrRoomName,
  isRoomDisabled,
  isRoomSelected,
  isValveDisabled,
  isValveSelected,
} from "../../helpers";
import type { State } from "../../providers";
import { ActionType, useNetworkContext } from "../../providers";
import type { HeatingNetworkWithBoilerroomName } from "../NetworksDialog";

import styles from "./NetworkForm.module.scss";

const getItems = (
  rooms: RoomsWithProblem[],
  valves: ValvesWithProblem[],
  state: State,
  currentNetworkId: string,
  displayRooms: boolean,
  permissions: Permission,
  valveHovered?: string,
): ItemType[] => {
  const valvesByRoom = createValveObjects(valves, rooms);

  return rooms.map(r => {
    const roomDisabled =
      isRoomDisabled(r.id, valvesByRoom, state.initialState, state.transformedState, currentNetworkId) ||
      !permissions.valve?.heatingNetworkId?.update;
    const room = valvesByRoom.find(vbr => vbr.id === r.id);
    return {
      id: r.id,
      x: r.plan.x,
      y: r.plan.y,
      selected: room && isRoomSelected(room, state.initialState, state.transformedState, currentNetworkId),
      disabled: roomDisabled || !displayRooms,
      origin: Origin.BOTTOM_CENTER,
      renderItem: (_, selected) => {
        return <>{displayRooms && <RoomFC name={r.name ?? ""} selected={selected} disabled={roomDisabled} />}</>;
      },
      items: valves
        .filter(v => v.roomId === r.id)
        .map(v => {
          const valveDisabeld =
            !permissions.valve?.heatingNetworkId?.update ||
            isValveDisabled(state.initialState, state.transformedState, v.id, currentNetworkId);
          return {
            id: v.id,
            x: v.plan.x,
            y: v.plan.y,
            selected: isValveSelected(state.initialState, state.transformedState, v.id, currentNetworkId),
            disabled: valveDisabeld,
            renderItem: (valve, selected) => (
              <Tooltip content={`${v.uid} - ${v.mac ?? "-"}`} position={"top"} keepHoverOpen={false}>
                <Valve
                  className={clsx(
                    styles.right__valve,
                    valveHovered === valve.id && styles.right__valve_hovered,
                    !permissions.valve?.heatingNetworkId?.update && selected && styles.right__valve_selected_disabled,
                  )}
                  disabled={valveDisabeld}
                  selected={selected}
                  displayUid
                  {...v}
                />
              </Tooltip>
            ),
          };
        }),
    };
  });
};

interface FormPlanProps {
  currentNetworkId: string;
  valveHovered?: string;
}

const FormPlan: React.FC<FormPlanProps> = ({ currentNetworkId, valveHovered }) => {
  const { formatMessage } = useIntl();
  const { permissions } = usePermissionsContext("FormPlan");

  const bem = useBem(styles);
  const rightStyle = bem("right");
  const optionsStyle = bem("options");

  const { houseId } = useParams();
  const { plans, rooms, valves } = useRouteLoaderData("house") as LoaderData<ReturnType<typeof houseLoader>>;

  const { state, dispatch } = useNetworkContext();
  const currentNetwork = state.initialState.flatMap(b => b.heatingNetworks).find(hn => hn?.id === currentNetworkId);
  const transformedNetwork = state.transformedState
    .flatMap(b => b.heatingNetworks)
    .find(hn => hn?.id === currentNetworkId);

  const [displayRooms, setDisplayRooms] = useState(true);
  const [plan, setPlan] = useState(plans[0]);
  const [imgUrl, setImgUrl] = useState<string>("");

  const planItems = getItems(
    rooms.filter(r => r.plan.planId === plan.id),
    valves.filter(v => v.plan.planId === plan.id),
    state,
    currentNetworkId,
    displayRooms,
    permissions,
    valveHovered,
  );

  useEffect(() => {
    // update plan image when plan change
    getPlan(plan.id, houseId).then(response => response.type === SUCCESS_FETCH && setImgUrl(response.result.message));
  }, [plan.id]);

  const handleChangePlan = (value: string) => {
    const newPlan = plans.find(p => p.id === value);
    newPlan && setPlan(newPlan);
  };

  const handleChangeItems = (items: ItemType[]) => {
    const flattentI = flattenItems(items);
    const otherSelectedValvesFromOtherPlans = transformedNetwork
      ? transformedNetwork.idValves?.filter(v => !flattentI.map(f => f.id).includes(v))
      : currentNetwork?.idValves?.filter(v => !flattentI.map(f => f.id).includes(v));
    const idValves = flattentI
      .filter(i => i.selected)
      .filter(v => valves.map(valves => valves.id).includes(v.id))
      .map(i => i.id);
    const idValvesToDispatch = otherSelectedValvesFromOtherPlans
      ? [...otherSelectedValvesFromOtherPlans, ...idValves]
      : idValves;
    dispatch({
      type: ActionType.UPDATE_ID_VALVES,
      payload: { networkId: currentNetworkId, ids: idValvesToDispatch },
    });
    dispatch({
      type: ActionType.UPDATE_AMBIENT_SENSOR_IDS,
      payload: {
        networkId: currentNetworkId,
        ids: (transformedNetwork?.ambientSensorIds
          ? transformedNetwork.ambientSensorIds
          : (currentNetwork?.ambientSensorIds ?? [])
        ).filter(aS => idValvesToDispatch.includes(aS)),
      },
    });
  };

  return (
    <div className={rightStyle()}>
      <div className={rightStyle("header")}>
        <Select
          label={formatMessage({ id: "boilerRoom.network.form.level" })}
          triggerClassName={rightStyle("select")}
          value={plan.id}
          options={plans.map(p => {
            return {
              name: p.name ?? "--",
              value: p.id,
            };
          })}
          renderValue={(value?: string) => <p>{plans.find(p => p.id === value)?.name}</p>}
          onChange={handleChangePlan}
        />
        <div className={optionsStyle()}>
          <label className={optionsStyle("label")}>
            {formatMessage({ id: "boilerRoom.network.form.displayRooms" })}
            <Switch
              className={optionsStyle("switch")}
              checked={displayRooms}
              onCheckedChange={() => setDisplayRooms(!displayRooms)}
            />
          </label>
          {isDesktop && (
            <p className={rightStyle("text")}>
              {formatMessage(
                { id: "boilerRoom.network.form.instructions" },
                {
                  i: (
                    <span style={{ display: "inline-flex", alignItems: "center", gap: 5 }}>
                      <ShiftIcon style={{ width: 12, height: 12 }} />
                      MAJ
                    </span>
                  ),
                },
              )}
            </p>
          )}
        </div>
      </div>
      <PlanV2
        className={rightStyle("plan")}
        planUrl={imgUrl}
        items={planItems}
        rectangleSelection
        selectionFunction={selectionItems}
        onChange={handleChangeItems}
      />
    </div>
  );
};

const NAME = "NetworkForm";

interface FormProps {
  heatingNetwork: HeatingNetworkWithBoilerroomName;
  className?: string;
}

export const NetworkForm: React.FC<FormProps> = ({ heatingNetwork, className }) => {
  const { formatMessage } = useIntl();

  const { permissions } = usePermissionsContext("NetworkForm");
  const { history } = useBoilerRoomContext(NAME);

  const bem = useBem(styles);
  const networkFormStyle = bem("network-form");
  const leftStyle = bem("left");
  const selectStyle = bem("select");

  const { valves, rooms } = useRouteLoaderData("house") as LoaderData<ReturnType<typeof houseLoader>>;

  const { state, dispatch } = useNetworkContext();

  const currentNetwork = state.initialState.flatMap(b => b.heatingNetworks).find(hn => hn?.id === heatingNetwork.id);
  const transformedNetwork = state.transformedState
    .flatMap(b => b.heatingNetworks)
    .find(hn => hn?.id === heatingNetwork.id);

  const valvesByRoom = useMemo(() => createValveObjects(valves, rooms), []);

  const [associatedValvesSearch, setAssociatedValvesSearch] = useState("");
  const [ambientSensorValveSearch, setAmbientSensorValveSearch] = useState("");
  const [valveHovered, setValveHovered] = useState<string | undefined>(undefined);

  const associatedValves = filterValvesByUidMacOrRoomName(valves, valvesByRoom, associatedValvesSearch);
  const ambientSensorValves = filterValvesByUidMacOrRoomName(
    valves.filter(v =>
      transformedNetwork?.idValves
        ? transformedNetwork.idValves.includes(v.id)
        : currentNetwork?.idValves?.includes(v.id),
    ),
    valvesByRoom,
    ambientSensorValveSearch,
  );

  const valvesRenderValue = (value?: string[]) => (
    <p>
      {value && value.length > 0
        ? valves
            .filter(v => value.includes(v.id))
            .map(v => (v.mac ? v.mac : "-"))
            .join(", ")
        : formatMessage({ id: "boilerRoom.network.form.select.placeholder" })}
    </p>
  );

  const valvesRenderOption = (option: Option) => {
    return (
      <div
        className={selectStyle("option", { disabled: !!option.disabled })}
        onMouseEnter={() => setValveHovered(option.value)}
        onMouseLeave={() => setValveHovered(undefined)}
      >
        <Typography keepHoverOpen={false}>
          {option.name} <span>{valvesByRoom.find(r => r.valvesIds.includes(option.value))?.name}</span>
        </Typography>
      </div>
    );
  };

  const handleChangeValves = (value: string[]) => {
    dispatch({ type: ActionType.UPDATE_ID_VALVES, payload: { networkId: currentNetwork?.id!, ids: value } });
    dispatch({
      type: ActionType.UPDATE_AMBIENT_SENSOR_IDS,
      payload: {
        networkId: currentNetwork?.id!,
        ids: value,
      },
    });
  };

  const handleChangeAmbiantSensor = (value: string[]) => {
    dispatch({
      type: ActionType.UPDATE_AMBIENT_SENSOR_IDS,
      payload: { networkId: currentNetwork?.id!, ids: value },
    });
  };

  return (
    <div className={clsx(networkFormStyle(), className)}>
      <div className={leftStyle()}>
        <TextInput
          value={transformedNetwork?.name ? transformedNetwork.name : currentNetwork?.name}
          label={formatMessage({ id: "boilerRoom.network.form.name" })}
          onChange={e =>
            dispatch({
              type: ActionType.UPDATE_NAME,
              payload: { networkId: heatingNetwork.id, name: e.currentTarget.value },
            })
          }
          disabled={!permissions.valve?.heatingNetworkId?.update || history}
        />
        <TextInput
          label={formatMessage({ id: "boilerRoom.network.form.location" })}
          value={heatingNetwork.boilerroomName}
          disabled
        />
        <Select
          label={formatMessage({ id: "boilerRoom.network.form.associatedValves" })}
          options={associatedValves.map(v => {
            return {
              name: `${v.uid} -${v.mac ? ` ${v.mac}` : ""}`,
              value: v.id,
              disabled:
                isValveDisabled(state.initialState, state.transformedState, v.id, currentNetwork?.id!) ||
                !permissions.valve?.heatingNetworkId?.update,
            };
          })}
          renderValue={valvesRenderValue}
          renderOption={valvesRenderOption}
          multiple
          value={transformedNetwork?.idValves ? transformedNetwork.idValves : (currentNetwork?.idValves ?? [])}
          onChange={handleChangeValves}
          shouldNotDisableIfNoOptions
          unscrollableContent={
            <SearchInput
              className={selectStyle("search")}
              placeholder={formatMessage({ id: "boilerRoom.network.form.select.search.placeholder" })}
              value={associatedValvesSearch}
              onChange={value => setAssociatedValvesSearch(value)}
            />
          }
          onClose={() => setAssociatedValvesSearch("")}
          disabled={history}
        />
        <Select
          label={formatMessage({ id: "boilerRoom.network.form.ambiantTemperature" })}
          options={ambientSensorValves.map(v => {
            return {
              name: `${v.uid} -${v.mac ? ` ${v.mac}` : ""}`,
              value: v.id,
              disabled: !permissions.valve?.heatingNetworkId?.update,
            };
          })}
          renderValue={valvesRenderValue}
          renderOption={valvesRenderOption}
          multiple
          value={
            transformedNetwork?.ambientSensorIds
              ? transformedNetwork.ambientSensorIds
              : (currentNetwork?.ambientSensorIds ?? [])
          }
          onChange={handleChangeAmbiantSensor}
          shouldNotDisableIfNoOptions
          unscrollableContent={
            <SearchInput
              className={selectStyle("search")}
              placeholder={formatMessage({ id: "boilerRoom.network.form.select.search.placeholder" })}
              value={ambientSensorValveSearch}
              onChange={value => setAmbientSensorValveSearch(value)}
            />
          }
          onClose={() => setAmbientSensorValveSearch("")}
          disabled={history}
        />
        <TextInput
          value={formatMessage({ id: `boilerRoom.network.form.type.${heatingNetwork.type}` })}
          label={formatMessage({ id: "boilerRoom.network.form.type.type" })}
          disabled
        />
      </div>
      <FormPlan currentNetworkId={currentNetwork?.id!} valveHovered={valveHovered} />
    </div>
  );
};

NetworkForm.displayName = NAME;
