import type { QueryClient } from "@tanstack/react-query";

import { queries } from "~/apiV2";

export const loader = (queryClient: QueryClient) => () => {
  void Promise.all([queryClient.ensureQueryData(queries.user.me), queryClient.ensureQueryData(queries.user.roles)]);

  void queryClient.prefetchQuery({ ...queries.news.all, staleTime: Infinity });

  return null;
};
