import type { ConfigType } from "@eisox/dayjs";
import { getGatewayProblem } from "@eisox/problems-handler";

import type { GatewaysWithProblem } from "~/UI/screens/House";
import type { Gateway, Mesh, Plan } from "~/apiV2";

/**
 * Toggles the suffix of a gateway name based on whether it is an internet gateway or not.
 * If the gateway is an internet gateway, it adds the suffix "_M" if it doesn't already have it,
 * otherwise it removes the suffix "_M" if it exists.
 * If the gateway is not an internet gateway, it removes the suffix "_M" if it exists,
 * otherwise it returns the original gateway name.
 *
 * @param gatewayName - The name of the gateway.
 * @param isInternetGateway - A boolean indicating whether the gateway is an internet gateway.
 * @returns The modified gateway name.
 */
export const toggleGatewaySuffix = (gatewayName: string, isInternetGateway: boolean) => {
  return isInternetGateway
    ? gatewayName.endsWith("_M")
      ? gatewayName
      : gatewayName.concat("_M")
    : gatewayName.endsWith("_M")
      ? gatewayName.slice(0, -2)
      : gatewayName;
};

export const gatewaysToGatewaysWithProblem = (
  gateways: Gateway[],
  plans: Plan[],
  meshs: Mesh[],
  date?: ConfigType,
): GatewaysWithProblem[] =>
  gateways.map(g => {
    const planName = plans.find(p => p.id === g.plan.planId)!.name;
    const { errors, warnings, problemStatus } = getGatewayProblem(g, date);
    return {
      ...g,
      problemStatus,
      errors,
      warnings,
      plan: { ...g.plan, planName },
      meshs: g.meshs.map(m => {
        const mesh = meshs.find(mesh => mesh.id === m.id);
        return { ...m, id: m.id!, name: mesh?.name ?? "-" };
      }),
    };
  });
