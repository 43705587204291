import clsx from "clsx";

import { useBem } from "@eisox/tools";

import styles from "./styles.module.scss";

export interface CopyrightProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
  version: string;
}

export const Copyright: React.FC<CopyrightProps> = ({ version, className, ...props }) => {
  const bem = useBem(styles);
  const copyRightStyle = bem("copy-right");

  const year = new Date().getFullYear();

  return (
    <p {...props} className={clsx(copyRightStyle(), className)}>
      Eisox © {year} • {version}
    </p>
  );
};
