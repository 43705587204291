import { Fragment, useState } from "react";

import { useTransformEffect } from "react-zoom-pan-pinch";

import { useBem } from "@eisox/tools";

import { getMaxDepth } from "~/utils";

import styles from "./Items.module.scss";

export enum Origin {
  CENTER_CENTER,
  BOTTOM_CENTER,
}

export interface ItemType {
  id: string;
  x: number;
  y: number;
  renderItem: (item: ItemType, selected: boolean) => JSX.Element;
  items?: ItemType[];
  onClick?: (item: ItemType) => void;
  selected?: boolean;
  disabled?: boolean;
  draggable?: boolean;
  origin?: Origin;
}

interface ItemsProps {
  refs: Record<string, HTMLDivElement | null>;
  items: ItemType[];
  imageScale: number;
  onMouseDown: (e: React.MouseEvent<Element, MouseEvent>, item: ItemType) => void;
  onChange?: (items: ItemType[]) => void;
  selectionFunction?: (items: ItemType[], selectedItems: ItemType[]) => ItemType[];
}

export const Items: React.FC<ItemsProps> = ({ refs, items, imageScale, onMouseDown, onChange, selectionFunction }) => {
  const [scale, setScale] = useState(imageScale);

  useTransformEffect(({ state }) => {
    const timeout = setTimeout(() => setScale(state.scale), 100);
    return () => clearTimeout(timeout);
  });

  const onClick = (item: ItemType) => {
    const selectedItems = selectionFunction?.(items, [item]);
    selectedItems && onChange && onChange(selectedItems);
  };

  return <Item refs={refs} items={items} scale={scale} onClick={onClick} onMouseDown={onMouseDown} />;
};

interface ItemProps {
  refs: Record<string, HTMLDivElement | null>;
  items: ItemType[];
  scale: number;
  onMouseDown: (e: React.MouseEvent<Element, MouseEvent>, item: ItemType) => void;
  onClick?: (item: ItemType) => void;
}

const Item: React.FC<ItemProps> = ({ refs, items, scale, onMouseDown, onClick }) => {
  const bem = useBem(styles);
  const itemStyles = bem("item");

  const maxItemDepth = getMaxDepth(items);

  return (
    <>
      {items.map(i => (
        <Fragment key={i.id}>
          <div
            id={i.id}
            ref={el => (refs[i.id] = el)}
            style={{
              cursor: i.disabled ? "default" : "pointer",
              left: `${i.x}%`,
              top: `${i.y}%`,
              zIndex: maxItemDepth,
              transform: `translate(-50%, -${i.origin === Origin.BOTTOM_CENTER ? "100" : "50"}%) scale(${1 / scale})`,
              transformOrigin: i.origin === Origin.BOTTOM_CENTER ? "bottom center" : "center",
            }}
            className={itemStyles()}
            onClick={() => onClick && onClick(i)}
            onMouseDown={e => i.draggable && onMouseDown(e, i)}
          >
            {i.renderItem(i, i.selected ?? false)}
          </div>
          {i.items && <Item refs={refs} items={i.items} scale={scale} onClick={onClick} onMouseDown={onMouseDown} />}
        </Fragment>
      ))}
    </>
  );
};

Item.displayName = "PlanV2Item";
