import { HouseMessageType } from "@eisox/webapp-api-specification";
import { faker } from "@faker-js/faker";

import type { House } from "~/apiV2";

import { Builder } from "../utils";

export class HouseBuilder extends Builder<House> {
  constructor() {
    const absenceTemperature = faker.number.int({ min: 10, max: 16 });
    const preComfortTemperature = faker.number.int({ min: absenceTemperature, max: 18 });

    super({
      _id: faker.database.mongodbObjectId(),
      absenceTemperature,
      preComfortTemperature,
      frostFreeTemperature: faker.number.int({ min: 5, max: 8 }),
      houseName: faker.lorem.words(),
      isFrostFree: faker.datatype.boolean(),
      latitude: faker.location.latitude().toString(),
      longitude: faker.location.longitude().toString(),
      type: HouseMessageType.professional,
    });
  }
}
