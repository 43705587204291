import * as React from "react";

import { IconProps } from "./types";

export const HistoryIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M7.95832 1.5L5.25 3.125L7.95832 4.75V3.66667C10.6423 3.66667 12.8333 5.8577 12.8333 8.54166C12.8333 11.2256 10.6423 13.4166 7.95832 13.4166C5.27437 13.4166 3.08333 11.2256 3.08333 8.54166C3.08333 7.21294 3.56162 6.06625 4.4375 5.18983L3.67591 4.42879C2.60125 5.50291 2 6.94536 2 8.54166C2 11.816 4.68396 14.5 7.95832 14.5C11.2327 14.5 13.9167 11.816 13.9167 8.54166C13.9167 5.26729 11.2327 2.58333 7.95832 2.58333V1.5ZM6.82409 5.05495L5.84257 5.52891L7.21354 8.23724C7.17204 8.33353 7.14906 8.43682 7.14585 8.54166V8.57577L5.4022 10.3183L6.18167 11.0983L7.92527 9.35471H7.95832C8.1738 9.35471 8.38047 9.2691 8.53284 9.11674C8.68524 8.96433 8.77084 8.7577 8.77084 8.54219C8.77084 8.16845 8.52219 7.85809 8.17824 7.76329L6.82409 5.05495Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default HistoryIcon;
