import { FieldNamesMarkedBoolean } from "react-hook-form";

import { PatchModule } from "@eisox/backend_webapp_api";
import dayjs, { ManipulateType } from "@eisox/dayjs";

import { Module } from "~/apiV2";
import { addTime } from "~/utils/timeUtils";

import { ModuleDto } from "../pages/Module";

export const isPLCMaintenanceEnabled = (module: Module): boolean => {
  if (module.sshOperatorExpireAt && dayjs(module.sshOperatorExpireAt).isAfter(dayjs())) {
    return true;
  }
  return false;
};

export const moduleToModuleDtoMapper = (module: Module): ModuleDto => {
  return {
    gatewayId: module.gatewayId!,
    eclypseIP: module.url!,
    user: "",
    password: "",
    gtbEnabled: !!module.isProxyEnabled,
    eclypseOperatorUser: "",
    eclypseOperatorPassword: "",
    PLCMaintenanceEnabled: isPLCMaintenanceEnabled(module),
    PLCMaintenanceAccessDuration: "",
  };
};

export const moduleDtoToMatchModuleMapper = (
  moduleDto: ModuleDto,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<ModuleDto>>>,
): PatchModule => {
  let patchModule: PatchModule = {};
  if (dirtyFields.gatewayId) patchModule.gatewayId = moduleDto.gatewayId;
  if (dirtyFields.eclypseIP) patchModule.url = moduleDto.eclypseIP;
  if (dirtyFields.user) patchModule.userName = moduleDto.user;
  if (dirtyFields.password) patchModule.password = moduleDto.password;
  if (dirtyFields.gtbEnabled && !moduleDto.gtbEnabled) patchModule.disableProxy = true;
  if (moduleDto.gtbEnabled && dirtyFields.eclypseOperatorUser)
    patchModule.proxy = { ...patchModule.proxy, userName: moduleDto.eclypseOperatorUser };
  if (moduleDto.gtbEnabled && dirtyFields.eclypseOperatorPassword)
    patchModule.proxy = { ...patchModule.proxy, password: moduleDto.eclypseOperatorPassword };
  if (dirtyFields.PLCMaintenanceEnabled && !moduleDto.PLCMaintenanceEnabled) patchModule.disableOperatorSsh = true;
  if (dirtyFields.PLCMaintenanceAccessDuration) {
    patchModule.sshOperatorExpireAt = addTime(
      parseInt(moduleDto.PLCMaintenanceAccessDuration!.split(" ")[0]),
      moduleDto.PLCMaintenanceAccessDuration!.split(" ")[1] as ManipulateType,
    ).toISOString();
  }
  return patchModule;
};
