import { http } from "msw";

import type {
  HeatingCurveHistoryPath,
  HeatingCurveHistoryPathParams,
  HeatingCurveHistoryRequestBody,
  HeatingCurveHistoryResponseBody,
  HeatingCurveHslopeHistoryPath,
  HeatingCurveHslopeHistoryPathParams,
  HeatingCurveHslopeHistoryRequestBody,
  HeatingCurveHslopeHistoryResponseBody,
} from "~/apiV2";
import type { VersionedPath } from "~/mocks/utils";

import { heatingCurveHistoryResolver, heatingCurveHslopeHistory } from "./resolvers";

export const handlers = [
  http.post<
    HeatingCurveHistoryPathParams,
    HeatingCurveHistoryRequestBody,
    HeatingCurveHistoryResponseBody,
    VersionedPath<HeatingCurveHistoryPath>
  >(
    "/api/v3/history/houses/:houseId/modules/:moduleId/heatingNetworks/:heatingNetworkId/heatingCurves",
    heatingCurveHistoryResolver,
  ),
  http.post<
    HeatingCurveHslopeHistoryPathParams,
    HeatingCurveHslopeHistoryRequestBody,
    HeatingCurveHslopeHistoryResponseBody,
    VersionedPath<HeatingCurveHslopeHistoryPath>
  >(
    "/api/v3/history/houses/:houseId/modules/:moduleId/heatingNetworks/:heatingNetworkId/hslopes",
    heatingCurveHslopeHistory,
  ),
];
