import * as React from "react";

import { IconProps } from "./types";

export const PlanIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M14.1579 1.5L9.4744 1.5C9.28549 1.5 8.68421 1.64371 8.68421 2.18421C8.68421 2.72471 9.28549 2.86842 9.4744 2.86842H13.1316L14.4132 1.61433C14.3505 1.54417 14.2594 1.5 14.1579 1.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.5 1.8421V6.52557C14.5 6.71451 14.3563 7.31579 13.8158 7.31579C13.2753 7.31579 13.1316 6.71451 13.1316 6.52557V2.86842L14.3857 1.58685C14.4558 1.6495 14.5 1.74064 14.5 1.8421Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1.5 1.8421L1.5 6.52557C1.5 6.71451 1.64371 7.31579 2.18421 7.31579C2.72471 7.31579 2.86842 6.71451 2.86842 6.52557V2.86842L1.61433 1.58685C1.54417 1.6495 1.5 1.74064 1.5 1.8421Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1.8421 1.5L6.52557 1.5C6.7145 1.5 7.31578 1.64371 7.31578 2.18421C7.31578 2.72471 6.7145 2.86842 6.52557 2.86842H2.86841L1.58684 1.61433C1.64949 1.54417 1.74064 1.5 1.8421 1.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.5 14.1579V9.4744C14.5 9.28549 14.3563 8.68421 13.8158 8.68421C13.2753 8.68421 13.1316 9.28549 13.1316 9.4744V13.1316L14.3857 14.4132C14.4558 14.3505 14.5 14.2594 14.5 14.1579Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M14.1579 14.5H9.4744C9.28549 14.5 8.68421 14.3563 8.68421 13.8158C8.68421 13.2753 9.28549 13.1316 9.4744 13.1316H13.1316L14.4132 14.3857C14.3505 14.4558 14.2594 14.5 14.1579 14.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1.8421 14.5H6.52557C6.7145 14.5 7.31578 14.3563 7.31578 13.8158C7.31578 13.2753 6.7145 13.1316 6.52557 13.1316H2.86841L1.58684 14.3857C1.64949 14.4558 1.74064 14.5 1.8421 14.5Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M1.5 14.1579L1.5 9.4744C1.5 9.28549 1.64371 8.68421 2.18421 8.68421C2.72471 8.68421 2.86842 9.28549 2.86842 9.4744V13.1316L1.61433 14.4132C1.54417 14.3505 1.5 14.2594 1.5 14.1579Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default PlanIcon;
