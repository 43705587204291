import { Navigate, useParams, useRouteLoaderData } from "react-router-dom";

import { idLoaderHouse, routeToPlan, routeToRoom } from "~/routes/utils";

import type { loader as houseLoader } from "../House";

export const Rooms: React.FC = () => {
  const { houseId, planId } = useParams();
  const { rooms } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;

  const room = rooms.find(r => r.id);

  return <Navigate to={room ? routeToRoom(houseId!, planId!, room.id!) : routeToPlan(houseId!, planId!)} replace />;
};
