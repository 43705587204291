import { useIntl } from "react-intl";

import { ActionButton, Dialog, Switch } from "@eisox/design-system";
import { ArrowDownIcon, ArrowRightIcon, CalendarIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { PlanningType } from "~/UI/components";
import { PlanningTypeKey } from "~/UI/components";

import styles from "./PlanningsGenerationPopup.module.scss";

interface TimeslostProps {
  text: string;
  planningType: PlanningType;
  temperature?: number;
}

const Timeslot: React.FC<TimeslostProps> = ({ text, planningType, temperature }) => {
  const bem = useBem(styles);
  const timeslotStyle = bem("timeslot");

  return (
    <div className={timeslotStyle()}>
      <p className={timeslotStyle("text")}>{text}</p>
      <ArrowDownIcon />
      <PlanningTypeKey
        className={timeslotStyle("planning-key")}
        planningType={planningType}
        temperature={{ show: true, value: temperature }}
      />
    </div>
  );
};

interface PreComfortTimeslotGenerationProps {
  activate?: boolean;
  absenceTemperature: number;
  preComfortTemperature?: number;
  comfortTemperature?: number;
}

const PreComfortTimeslotGeneration: React.FC<PreComfortTimeslotGenerationProps> = ({
  activate = false,
  absenceTemperature,
  preComfortTemperature,
  comfortTemperature,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const preComfortTimeslotGenerationStyle = bem("pre-comfort-timeslot-generation");

  return (
    <div className={preComfortTimeslotGenerationStyle()}>
      <label className={preComfortTimeslotGenerationStyle("label")}>
        <Switch className={preComfortTimeslotGenerationStyle("switch")} checked={activate} onCheckedChange={() => {}} />
        {formatMessage(
          {
            id: "settings.content.menu.rooms.function.parameters.precomfortTimeslotGeneration.popup.preComfortTimeslotGeneration",
          },
          { activate: activate },
        )}
      </label>
      <div className={preComfortTimeslotGenerationStyle("timeslots")}>
        <Timeslot
          text={formatMessage({
            id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.popup.absence",
          })}
          planningType="absence"
          temperature={absenceTemperature}
        />
        {activate && (
          <Timeslot
            text={formatMessage({
              id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.popup.preComfort",
            })}
            planningType="preComfort"
            temperature={preComfortTemperature}
          />
        )}
        <Timeslot
          text={formatMessage({
            id: "settings.content.menu.rooms.function.parameters.unusualAbsenceDetection.popup.comfort",
          })}
          planningType="comfort"
          temperature={comfortTemperature}
        />
      </div>
    </div>
  );
};

interface PlanningsGenerationPopupProps {
  open: boolean;
  onClose?: VoidFunction;
  absenceTemperature: number;
  preComfortTemperature: number;
  comfortTemperature?: number;
}

export const PlanningsGenerationPopup: React.FC<PlanningsGenerationPopupProps> = ({
  open,
  onClose,
  absenceTemperature,
  preComfortTemperature,
  comfortTemperature,
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const planningsGenerationPopupStyles = bem("plannings-generation-popup");

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose && onClose()}>
      <Dialog.Content
        className={planningsGenerationPopupStyles()}
        title={formatMessage({
          id: "settings.content.menu.rooms.function.parameters.precomfortTimeslotGeneration.popup.title",
        })}
        icon={<CalendarIcon />}
      >
        <p className={planningsGenerationPopupStyles("text")}>
          {formatMessage({
            id: "settings.content.menu.rooms.function.parameters.precomfortTimeslotGeneration.popup.text",
          })}
        </p>
        <PreComfortTimeslotGeneration absenceTemperature={absenceTemperature} comfortTemperature={comfortTemperature} />
        <PreComfortTimeslotGeneration
          activate
          absenceTemperature={absenceTemperature}
          preComfortTemperature={preComfortTemperature}
          comfortTemperature={comfortTemperature}
        />
        <ActionButton
          className={planningsGenerationPopupStyles("button")}
          text={formatMessage({
            id: "settings.content.menu.rooms.function.parameters.precomfortTimeslotGeneration.popup.close",
          })}
          icon={<ArrowRightIcon />}
          onClick={onClose}
          rounded
        />
      </Dialog.Content>
    </Dialog.Root>
  );
};
