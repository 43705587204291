import type { ComponentPropsWithRef } from "react";
import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import type { primary } from "@eisox/colors";

import styles from "./DataList.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/

interface DataListProps extends ComponentPropsWithRef<"dl"> {
  orientation?: "horizontal" | "vertical";
  trim?: "normal" | "start" | "end" | "both";
}

const Root = forwardRef<HTMLDListElement, DataListProps>((props, ref) => {
  const { orientation = "horizontal", trim, className, ...rootProps } = props;
  return (
    <dl
      {...rootProps}
      ref={ref}
      className={cx(
        styles.dataList,
        styles[`dataList_orientation_${orientation}`],
        trim && styles[`dataList_trim_${trim}`],
        className,
      )}
    />
  );
});

Root.displayName = "DataList";

/* -------------------------------------------------------------------------------------------------
 * Item
 * -----------------------------------------------------------------------------------------------*/

interface ItemProps extends ComponentPropsWithRef<"div"> {
  align?: "start" | "center" | "end" | "baseline" | "stretch";
}

const Item = forwardRef<HTMLDivElement, ItemProps>((props, ref) => {
  const { align, className, ...itemProps } = props;
  return (
    <div {...itemProps} ref={ref} className={cx(styles.item, align && styles[`item_align_${align}`], className)} />
  );
});

Item.displayName = "Item";

/* -------------------------------------------------------------------------------------------------
 * Label
 * -----------------------------------------------------------------------------------------------*/

interface LabelProps extends Omit<ComponentPropsWithRef<"dt">, "color"> {
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  color?: keyof typeof primary;
}

const Label = forwardRef<HTMLDivElement, LabelProps>((props, ref) => {
  const { className, ...labelProps } = props;
  return <dt {...labelProps} ref={ref} className={cx(styles.label, className)} />;
});

Label.displayName = "Label";

/* -------------------------------------------------------------------------------------------------
 * Value
 * -----------------------------------------------------------------------------------------------*/

interface ValueProps extends Omit<ComponentPropsWithRef<"dd">, "color"> {
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  color?: keyof typeof primary;
}

const Value = forwardRef<HTMLDivElement, ValueProps>((props, ref) => {
  const { className, ...valueProps } = props;
  return <dd {...valueProps} ref={ref} className={cx(styles.value, className)} />;
});

Value.displayName = "Value";

export { Item, Label, Root, Value };
export type { DataListProps, ItemProps, LabelProps, ValueProps };
