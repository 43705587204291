import { forwardRef } from "react";

import clsx from "clsx";
import { useIntl } from "react-intl";

import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";
import { NetworkDialog, Pumps } from "~/features/BoilerRooms";
import { NetworksDialog } from "~/features/HeatingNetworkConfiguration";
import type { HeatingNetwork } from "~/socketio/types";

import { SetpointTemperatureECSDialog } from "../../../components/SetpointTemperatureECSDialog";
import { ECS } from "../ECS";
import { TwoThreeWayValve } from "../TwoThreeWayValve";
import { Valve } from "../Valve";
import { WaterlineTemperature } from "../WaterlineTemperature";

import styles from "./Scheme.module.scss";

interface SetpointTemperatureProps extends React.HTMLAttributes<HTMLDivElement> {
  temperature: number;
  className?: string;
}

const SetpointTemperature = forwardRef<HTMLDivElement, SetpointTemperatureProps>(
  ({ temperature, className, ...props }, forwardedRef) => {
    const { formatMessage } = useIntl();

    const bem = useBem(styles);
    const setpointTemperature = bem("setpoint-temperature");

    return (
      <Tooltip content={formatMessage({ id: "network.content.globalView.scheme.setpointTemperature" })}>
        <div {...props} ref={forwardedRef} className={clsx(setpointTemperature(), className)}>
          <p className={setpointTemperature("temperature")}>{`${temperature.toFixed(1)}°C`}</p>
        </div>
      </Tooltip>
    );
  },
);

interface SchemeProps {
  boilerRoomId: string;
  network: HeatingNetwork;
  className?: string;
}

export const Scheme: React.FC<SchemeProps> = ({ boilerRoomId, network, className }) => {
  const bem = useBem(styles);
  const schemeStyle = bem("scheme");

  const isECS = network.type === "sanitaryHotWater";

  return (
    <div className={clsx(schemeStyle(), className)}>
      <div className={schemeStyle("network", { type: isECS ? "ecs" : "radiator" })}>
        {network.threeWaysValve && (
          <NetworkDialog dialogTitle={network.name ?? "-"} networkId={network.id} {...network}>
            <TwoThreeWayValve {...network.threeWaysValve} className={schemeStyle("three-way-valve")} />
          </NetworkDialog>
        )}
        {network.pumps && (
          <NetworkDialog dialogTitle={network.name ?? "-"} networkId={network.id} {...network}>
            <Pumps {...network.pumps} vertical className={schemeStyle("pumps")} />
          </NetworkDialog>
        )}
        {!isECS && network.setpointTemperature !== undefined && (
          <SetpointTemperature
            temperature={network.setpointTemperature}
            className={schemeStyle("setpoint-temperature")}
          />
        )}
        {isECS && network.setpointTemperatureEcs !== undefined && (
          <SetpointTemperatureECSDialog boilerRoomId={boilerRoomId} network={network}>
            <SetpointTemperature
              temperature={network.setpointTemperatureEcs}
              className={schemeStyle("setpoint-temperature", { ecs: isECS })}
            />
          </SetpointTemperatureECSDialog>
        )}
        {network.returnTemperature !== undefined && (
          <WaterlineTemperature
            temperatures={[network.returnTemperature]}
            className={schemeStyle("waterline-temperature", { type: "return", ecs: isECS })}
          />
        )}
        {network.startingTemperature !== undefined && (
          <WaterlineTemperature
            temperatures={[network.startingTemperature]}
            className={schemeStyle("waterline-temperature", { type: "starting", ecs: isECS })}
          />
        )}
        {!isECS && network.idValves && (
          <NetworksDialog currentHeatingNetworkId={network.id}>
            <Valve nbValve={network.idValves.length} className={schemeStyle("valve")} />
          </NetworksDialog>
        )}
      </div>
      {isECS && <ECS network={network} className={schemeStyle("ecs")} />}
    </div>
  );
};
