import { useIntl } from "react-intl";

import { primary } from "@eisox/colors";
import { ActionButton, Dialog } from "@eisox/design-system";
import { ArrowRightIcon, WarningIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./ModificationPopup.module.scss";

interface ModificationPopupProps {
  onClickContinue: VoidFunction;
  open: boolean;
  onClose: VoidFunction;
}

export const ModificationPopup: React.FC<ModificationPopupProps> = ({ onClickContinue, open, onClose }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const modificationPopup = bem("modification-popup");

  return (
    <Dialog.Root open={open} onOpenChange={open => !open && onClose()}>
      <Dialog.Content
        icon={<WarningIcon color={primary.red} className={modificationPopup("icon")} />}
        title={formatMessage({ id: "modificationPopup.title" })}
      >
        <div className={modificationPopup("buttons")}>
          <ActionButton
            variant="cancel"
            text={formatMessage({ id: "modificationPopup.cancel" })}
            onClick={() => onClose()}
          />
          <ActionButton
            variant="danger"
            text={formatMessage({ id: "modificationPopup.continue" })}
            rounded
            onClick={() => onClickContinue()}
            icon={<ArrowRightIcon />}
          />
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
