import { useMemo, useRef } from "react";

import { useTransformContext, useTransformEffect } from "react-zoom-pan-pinch";

import { useBem } from "@eisox/tools";

import styles from "./Minimap.module.scss";

interface MiniMapProps {
  imageUrl: string;
  initScale: number;
  displayedImageWidth: number;
  displayedImageHeight: number;
}

const MiniMap: React.FC<MiniMapProps> = ({ imageUrl, initScale, displayedImageWidth, displayedImageHeight }) => {
  const bem = useBem(styles);
  const miniMapStyle = bem("minimap");

  const previewRef = useRef<HTMLDivElement>(null);

  const { wrapperComponent } = useTransformContext();

  const { minimapWidth, minimapHeight, minimapScale } = useMemo(() => {
    let minimapWidth = 0;
    let minimapHeight = 0;
    let minimapScale = 0;
    if (wrapperComponent) {
      const wrapperWidth = wrapperComponent.offsetWidth;
      const wrapperHeight = wrapperComponent.offsetHeight;
      const aspectRatio = wrapperWidth / wrapperHeight;
      if (wrapperWidth > wrapperHeight) {
        minimapWidth = 200;
        minimapHeight = 200 / aspectRatio;
      } else {
        minimapHeight = 200;
        minimapWidth = 200 * aspectRatio;
      }
      minimapScale = minimapWidth / wrapperWidth;
    }
    return {
      minimapWidth,
      minimapHeight,
      minimapScale,
    };
  }, [wrapperComponent]);

  useTransformEffect(({ state: { positionX, positionY, scale } }) => {
    if (previewRef.current && wrapperComponent) {
      const previewWidth =
        (initScale * (displayedImageWidth + (wrapperComponent.offsetWidth - displayedImageWidth)) * minimapScale) /
        scale;
      const previewHeight =
        (initScale * (displayedImageHeight + (wrapperComponent.offsetHeight - displayedImageHeight)) * minimapScale) /
        scale;

      const x = (positionX * initScale) / scale - (wrapperComponent.offsetWidth - displayedImageWidth) / 2;
      const y = (positionY * initScale) / scale - (wrapperComponent.offsetHeight - displayedImageHeight) / 2;

      const previewLeft = -x * minimapScale;
      const previewTop = -y * minimapScale;

      previewRef.current.style.width = `${previewWidth}px`;
      previewRef.current.style.height = `${previewHeight}px`;
      previewRef.current.style.transform = `translate(${previewLeft}px, ${previewTop}px)`;
    }
  });

  return (
    <div
      style={{
        width: minimapWidth,
        height: minimapHeight,
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
      className={miniMapStyle()}
    >
      <div ref={previewRef} style={{ position: "absolute", border: "1px solid red" }} />
    </div>
  );
};

export default MiniMap;
