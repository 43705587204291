import * as yup from "yup";

enum Mode {
  SUMMER = "summer",
  WINTER = "winter",
  SUMMER_WINTER = "summerWinter",
  SUMMER_WINTER_WITH_HALF_SEASON = "summerWinterWithHalfSeason",
}

const seasonSchema = yup.object({
  mode: yup.string().oneOf(Object.values(Mode)).required(),
  // summerPeriods is required if mode is summerWinter or summerWinterWithHalfSeason
  summerPeriods: yup
    .object({
      begin: yup.string().required(),
      end: yup.string().required(),
    })
    .when("mode", ([mode], schema) => {
      if (mode === Mode.WINTER) {
        return schema.test("is-undefined", "This field must be undefined", value => value === undefined);
      } else {
        return schema.required();
      }
    }),
  // winterPeriods is required only if mode is summerWinterWithHalfSeason
  winterPeriods: yup
    .object({
      begin: yup.string().required(),
      end: yup.string().required(),
    })
    .when("mode", ([mode], schema) => {
      if (mode === Mode.SUMMER_WINTER_WITH_HALF_SEASON) {
        return schema.required();
      } else {
        return schema.test("is-undefined", "This field must be undefined", value => value === undefined);
      }
    }),
});

interface SummerSeasonType {
  mode: Mode.SUMMER;
}

interface WinterSeasonType {
  mode: Mode.WINTER;
}
interface SummerWinterSeasonType {
  mode: Mode.SUMMER_WINTER;
  summerPeriods: {
    begin: string;
    end: string;
  };
  winterPeriods: {
    begin: string;
    end: string;
  };
}
interface SummerWinterWithHalfSeasonType {
  mode: Mode.SUMMER_WINTER_WITH_HALF_SEASON;
  summerPeriods: {
    begin: string;
    end: string;
  };
  winterPeriods: {
    begin: string;
    end: string;
  };
  midSeason: {
    heatingTemperature: number;
    noHeatingTemperature: number;
    delay: number;
  };
}
type SeasonType = SummerSeasonType | WinterSeasonType | SummerWinterSeasonType | SummerWinterWithHalfSeasonType;

export { Mode, seasonSchema };
export type { SeasonType, SummerSeasonType, WinterSeasonType, SummerWinterSeasonType, SummerWinterWithHalfSeasonType };
