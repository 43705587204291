import React, { useMemo, useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import dayjs from "@eisox/dayjs";
import { TableV2 as Table } from "@eisox/design-system";
import { ChevronDownIcon, ChevronUpIcon } from "@eisox/icons";
import type { PathsHistoryHousesHouseIdModulesModuleIdHeatingNetworksHeatingNetworkIdHslopesPostParametersQueryDesc as HeatingCurveHslopeHistoryDesc } from "@eisox/webapp-api-specification";
import {
  PathsHistoryHousesHouseIdModulesModuleIdHeatingNetworksHeatingNetworkIdHslopesPostParametersQuerySort as HeatingCurveHslopeHistorySort,
  PostHslopeHistoryFields,
} from "@eisox/webapp-api-specification";
import { keepPreviousData, useInfiniteQuery } from "@tanstack/react-query";
import type { ColumnDef, OnChangeFn, SortingState } from "@tanstack/react-table";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";

import type { HeatingCurveHslopeHistory, HeatingCurveHslopeHistoryRequestBody } from "~/apiV2";
import { queries } from "~/apiV2";
import { useBoilerRoomRealTimeProviderContext } from "~/features/BoilerRooms";

import styles from "./HslopeHistoryTable.module.scss";

const NAME = "HslopeHistoryTable";

interface HslopeHistoryTableProps {
  houseId: string;
  moduleId: string;
  heatingNetworkId: string;
  heatingCurveId?: string;
  externalTemperature: number;
}

const HslopeHistoryTable: React.FC<HslopeHistoryTableProps> = ({
  houseId,
  moduleId,
  heatingNetworkId,
  heatingCurveId,
  externalTemperature,
}) => {
  const { t } = useTranslation();

  const { useGetCurrentHeatingCurveHslope } = useBoilerRoomRealTimeProviderContext(NAME);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [sorting, setSorting] = useState<SortingState>([{ id: HeatingCurveHslopeHistorySort.createdAt, desc: true }]);

  const columns = useMemo<ColumnDef<HeatingCurveHslopeHistory>[]>(
    () => [
      {
        accessorKey: "dateGateway",
        header: t("network.content.heatingCurve.dialog.heatingCurveHistory.table.date"),
        cell: info => dayjs(info.getValue() as string).format("L LTS"),
      },
      {
        accessorKey: "macValve",
        header: t("network.content.heatingCurve.dialog.heatingCurveHistory.table.mac"),
      },
      {
        accessorKey: "hslopeMinC",
        header: t("network.content.heatingCurve.dialog.heatingCurveHistory.table.hslope"),
        cell: info => (info.getValue() as number).toFixed(1),
      },
      {
        accessorKey: "heatingNetworkOpeningAverage",
        header: t("network.content.heatingCurve.dialog.heatingCurveHistory.table.networkDemand"),
        cell: info => (info.getValue() as number).toFixed(1),
      },
      {
        accessorKey: "realExtTemp",
        header: t("network.content.heatingCurve.dialog.heatingCurveHistory.table.externalTemperature"),
        cell: info => (info.getValue() as number).toFixed(1),
      },
      {
        accessorKey: "heatingNetworkStartingTemperature",
        header: t("network.content.heatingCurve.dialog.heatingCurveHistory.table.startingTemperature"),
        cell: info => (info.getValue() as number).toFixed(1),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const body = useMemo(
    () => ({
      fields: [
        PostHslopeHistoryFields.dateGateway,
        PostHslopeHistoryFields.macValve,
        PostHslopeHistoryFields.hslopeMinC,
        PostHslopeHistoryFields.heatingNetworkOpeningAverage,
        PostHslopeHistoryFields.realExtTemp,
        PostHslopeHistoryFields.heatingNetworkStartingTemperature,
      ],
      heatingCurveId: heatingCurveId ?? "",
    }),
    [heatingCurveId],
  );

  const sort = sorting.map(sort => sort.id as HeatingCurveHslopeHistorySort);
  const desc = sorting.filter(sort => sort.desc).map(sort => sort.id as HeatingCurveHslopeHistoryDesc);

  const { data, fetchNextPage, isFetching } = useInfiniteQuery<
    { data: HeatingCurveHslopeHistory[]; meta: { total: number; nextPage?: number } },
    Error
  >({
    ...queries.history.heatingCurveHslopeHistory(houseId, moduleId, heatingNetworkId, sort, desc, body),
    initialPageParam: 1,
    getNextPageParam: lastPage => lastPage.meta.nextPage,
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    retry: false,
    enabled: !!heatingCurveId && heatingCurveId !== "current-period",
  });

  const { data: currentData } = useGetCurrentHeatingCurveHslope({
    heatingNetworkId,
    externalTemperature,
    enabled: !!heatingCurveId && heatingCurveId === "current-period",
  });

  const flatData = useMemo(
    () =>
      heatingCurveId === "current-period" ? (currentData?.data ?? []) : (data?.pages.flatMap(page => page.data) ?? []),
    [currentData?.data, data?.pages, heatingCurveId],
  );
  const totalDBRowCount = data?.pages[0]?.meta?.total ?? flatData.length;
  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < 500 && !isFetching && totalFetched < totalDBRowCount) {
          void fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
  );

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  React.useEffect(() => {
    fetchMoreOnBottomReached(tableContainerRef.current);
  }, [fetchMoreOnBottomReached]);

  const table = useReactTable({
    data: flatData,
    columns,
    state: { sorting },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
  });

  const handleSortingChange: OnChangeFn<SortingState> = updater => {
    setSorting(updater);
    if (table.getRowModel().rows.length) {
      rowVirtualizer.scrollToIndex(0);
    }
  };

  table.setOptions(prev => ({
    ...prev,
    onSortingChange: handleSortingChange,
  }));

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" && !navigator.userAgent.includes("Firefox")
        ? element => element.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  return (
    <Table.Container
      className={styles.hslopeHistoryTable}
      onScroll={e => fetchMoreOnBottomReached(e.currentTarget)}
      ref={tableContainerRef}
    >
      {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
      <Table.Root className={styles.table} style={{ display: "grid" }}>
        <Table.Header className={styles.header}>
          {table.getHeaderGroups().map(headerGroup => (
            <Table.Row key={headerGroup.id} className={styles.header__row}>
              {headerGroup.headers.map(header => {
                return (
                  <Table.Head
                    key={header.id}
                    className={styles.header__head}
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    <div
                      className={styles.hslopeHistoryTable__header}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <ChevronUpIcon />,
                        desc: <ChevronDownIcon />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  </Table.Head>
                );
              })}
            </Table.Row>
          ))}
        </Table.Header>
        {!!heatingCurveId && (
          <Table.Body
            className={styles.body}
            style={{
              height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
            }}
          >
            {rowVirtualizer.getVirtualItems().map(virtualRow => {
              const row = rows[virtualRow.index];
              return (
                <Table.Row
                  data-index={virtualRow.index} //needed for dynamic row height measurement
                  ref={node => rowVirtualizer.measureElement(node)} //measure dynamic row height
                  key={row.id}
                  className={styles.body__row}
                  style={{
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <Table.Cell
                        key={cell.id}
                        className={styles.body__cell}
                        style={{
                          width: cell.column.getSize(),
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
        )}
      </Table.Root>
    </Table.Container>
  );
};

HslopeHistoryTable.displayName = NAME;

export { HslopeHistoryTable };
export type { HslopeHistoryTableProps };
