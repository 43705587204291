// fetching Actions
export const PUT_METHOD = "PUT";
export const POST_METHOD = "POST";
export const GET_METHOD = "GET";
export const PATCH_METHOD = "PATCH";
export const DELETE_METHOD = "DELETE";

// fetching Responses

export const SUCCESS_FETCH = "Success";
export const USER_NOT_LOGGED_FETCH = "User not logged";
export const FORBIDDEN = "Forbidden";
export const NOT_FOUND = "not found";
export const INTERNAL_ERROR = "Internal server error";
export const FETCH_ERROR = "fetch_error";
export const BAD_REQUEST = "Bad request";
export const FETCH_TIMOUT = "fetch_timout";
export const MULTIPLE_RESPONSES = "Multiple responses";

export const HTTPS_STATUS_CODES = {
  200: SUCCESS_FETCH,
  202: SUCCESS_FETCH,
  207: MULTIPLE_RESPONSES,
  400: BAD_REQUEST,
  401: USER_NOT_LOGGED_FETCH,
  403: FORBIDDEN,
  404: NOT_FOUND,
  500: INTERNAL_ERROR,
  timeOut: FETCH_TIMOUT,
  error: FETCH_ERROR,
};

export const getHttpStatusByFetchStatus = fetchStatus => {
  for (let key in HTTPS_STATUS_CODES) {
    if (HTTPS_STATUS_CODES[key] === fetchStatus) {
      return parseInt(key);
    }
  }
};
