import React from "react";

import { useIntl } from "react-intl";
import type { TooltipProps } from "recharts";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import type { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import type { AxisDomain } from "recharts/types/util/types";

import { chart } from "@eisox/colors";
import dayjs from "@eisox/dayjs";
import { Card, Typography } from "@eisox/design-system";
import { CrossIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import styles from "./Chart.module.scss";

const CustomTooltip = ({
  payload,
  label,
  translationKey,
}: TooltipProps<ValueType, NameType> & { translationKey: string }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const tooltipStyle = bem("tooltip");
  const valueStyle = bem("value");

  if (!payload || payload.length <= 0) return null;

  const date = dayjs(label);
  const hour = date.format("HH[h]mm");
  let displayedDate: string;
  if (date.isToday()) {
    displayedDate = `${formatMessage({ id: "room.historyDialog.tooltip.date.today" }, { h: hour })}`;
  } else if (date.isYesterday()) {
    displayedDate = `${formatMessage({ id: "room.historyDialog.tooltip.date.yesterday" }, { h: hour })}`;
  } else {
    displayedDate = `${formatMessage({ id: "room.historyDialog.tooltip.date.other" }, { d: date.format("DD/MM/YYYY"), h: hour })}`;
  }

  return (
    <Card className={tooltipStyle()}>
      <Typography className={tooltipStyle("date")}>{displayedDate}</Typography>
      {payload.map(p => (
        <div className={valueStyle()}>
          <div className={valueStyle("legend")}>
            <span className={valueStyle("color")} style={{ backgroundColor: p.color }} />
            <Typography className={valueStyle("label")}>
              {formatMessage({ id: translationKey ? `${translationKey}.${p.dataKey}` : `${p.dataKey}` })}
            </Typography>
          </div>
          <Typography className={valueStyle("value")}>{`${p.value} ${p.unit}`}</Typography>
        </div>
      ))}
    </Card>
  );
};

const CustomLegend = ({
  payload,
  translationKey,
  layout,
  onRemoveData,
}: {
  payload: Payload<ValueType, NameType>[];
  translationKey: string;
  layout: "vertical" | "horizontal";
  onRemoveData?: (dataKey: string | number | undefined) => void;
}) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const legendStyle = bem("legend");
  const labelStyle = bem("label");

  return (
    <div className={legendStyle({ layout })}>
      {payload.map(p => (
        <div className={labelStyle()}>
          <span className={labelStyle("color")} style={{ backgroundColor: p.color }} />
          <Typography className={labelStyle("text")}>
            {`${formatMessage({ id: translationKey ? `${translationKey}.${p.dataKey}` : `${p.dataKey}` })} ${p.payload.unit ? `(${p.payload.unit})` : ""}`}
          </Typography>
          {onRemoveData && <CrossIcon className={legendStyle("cross")} onClick={() => onRemoveData(p.dataKey)} />}
        </div>
      ))}
    </div>
  );
};

export interface ChartProps {
  data?: (Record<string, number> & { date: number })[];
  unitAxesMapping: Record<string, string[]>;
  layout?: "vertical" | "horizontal";
  yAxisDomain?: AxisDomain;
  translationKey: string;
  onRemoveData?: (dataKey: string | number | undefined) => void;
}

export const Chart: React.FC<ChartProps> = ({
  data,
  unitAxesMapping,
  layout = "horizontal",
  yAxisDomain = ["dataMin", "dataMax"],
  translationKey,
  onRemoveData,
}) => {
  const gray = "#f3f3f9";
  const darkBlueColor = "#122138";

  const axisStyle = {
    fontWeight: 400,
    fill: darkBlueColor,
  };

  const dateDomain: [number, number] = [
    data && data[0] ? dayjs(data[0].date).startOf("day").valueOf() : dayjs().startOf("day").valueOf(),
    data && data[0] ? dayjs(data[0].date).endOf("day").valueOf() : dayjs().endOf("day").valueOf(),
  ];

  const keys = Object.values(unitAxesMapping).flat();

  const defaultColors = Object.values(chart);
  const colors: Record<string, string> = {};
  keys.forEach((k, i) => (colors[k] = defaultColors[i]));

  const getXAxisTicks = (): number[] => {
    const timestamps: number[] = [];
    let currentDay = dayjs(dateDomain[0]);
    const nextDay = currentDay.add(1, "day");
    while (currentDay.isBefore(nextDay)) {
      timestamps.push(currentDay.valueOf());
      currentDay = currentDay.add(3, "hour");
    }
    timestamps.push(nextDay.valueOf());
    return timestamps;
  };

  return (
    <ResponsiveContainer>
      <LineChart key={`${keys.length}-${dateDomain[0]}-${dateDomain[1]}`} data={data}>
        <Tooltip content={<CustomTooltip translationKey={translationKey} />} />
        <Legend
          /* @ts-expect-error : Payload is absent from type definition */
          content={<CustomLegend translationKey={translationKey} onRemoveData={onRemoveData} />}
          layout={layout}
        />
        <CartesianGrid vertical={false} horizontal stroke={gray} />
        <XAxis
          dataKey="date"
          type="number"
          axisLine={false}
          tickLine={false}
          tickMargin={16}
          tickFormatter={value => dayjs(value).format("HH:mm")}
          ticks={getXAxisTicks()}
          style={axisStyle}
          padding={{ left: 10, right: 10 }}
          domain={dateDomain}
        />
        {Object.entries(unitAxesMapping).map(([unit, value], index) => (
          <>
            <YAxis
              key={index}
              yAxisId={unit}
              orientation={index % 2 === 0 ? "left" : "right"}
              axisLine={false}
              tickLine={false}
              tick={{ fill: colors[value[0]] }}
              domain={yAxisDomain}
            />
            {value.map(v => (
              <Line
                key={v}
                unit={unit}
                yAxisId={unit}
                type="linear"
                dataKey={v}
                stroke={colors[v]}
                strokeWidth={2}
                dot={false}
              />
            ))}
          </>
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
