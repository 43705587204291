import { useCallback, useState } from "react";

import { useIntl } from "react-intl";

import { primary } from "@eisox/colors";
import { Card, Dialog } from "@eisox/design-system";
import { ArrowRightIcon, WarningIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { env } from "~/configuration/envConfig";
import type { RestError } from "~/constants/appConstantV2";
import { REST_ERROR_MESSAGE } from "~/constants/appConstantV2";
import type { API } from "~/types/API";

import styles from "./ErrorPopup.module.scss";

interface ErrorPopupProps {
  open: boolean;
  restError?: RestError;
  requestUrl?: string;
  requestMethod?: string;
  body?: string;
  message?: string | { message?: string; status: API.HTTP_STATUS }[];
}

export let showError = (
  restError?: RestError,
  requestUrl?: string,
  requestMethod?: string,
  body?: string,
  message?: string | { message?: string; status: API.HTTP_STATUS; [key: string]: any }[],
) => {};

export const ErrorPopup: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const errorPopupStyle = bem("error-popup");
  const contentStyle = bem("content");
  const iconStyle = bem("icon");

  const [state, setState] = useState<ErrorPopupProps>({
    open: false,
  });

  const emailBody: string = formatMessage(
    { id: "errorPopup.mail.body" },
    {
      url: state.requestUrl,
      method: state.requestMethod,
      body: state.body,
      error: state.restError,
      message: Array.isArray(state.message)
        ? state.message.map(m => Object.entries(m).map(([key, value]) => ` ${key} : ${value}`)).join(", \n")
        : state.message,
    },
  );

  showError = useCallback(
    function (
      restError?: RestError,
      requestUrl?: string,
      requestMethod?: string,
      body?: string,
      message?: string | { message?: string; status: API.HTTP_STATUS }[],
    ) {
      setState({
        open: true,
        restError: restError,
        requestUrl: requestUrl,
        requestMethod: requestMethod,
        body: body,
        message: message,
      });
    },
    [setState],
  );

  return (
    <Dialog.Root open={state.open} onOpenChange={open => setState({ ...state, open })}>
      <Dialog.Content
        className={errorPopupStyle()}
        title={state.restError ? formatMessage({ id: REST_ERROR_MESSAGE[state.restError].title }) : ""}
        icon={<WarningIcon color={primary.red} className={iconStyle()} />}
      >
        <div className={contentStyle()}>
          <h2 className={contentStyle("subtitle")}>
            {state.restError && formatMessage({ id: REST_ERROR_MESSAGE[state.restError].message })}
          </h2>
          {state.message && (
            <Card className={contentStyle("message")}>
              {Array.isArray(state.message) ? (
                state.message.map((m, i) => (
                  <p key={i}>
                    {Object.entries(m).map(([key, value]) => (
                      <>
                        {key} : {Array.isArray(value) ? value.join(", ") : value}&nbsp;
                      </>
                    ))}
                  </p>
                ))
              ) : (
                <p>{state.message}</p>
              )}
            </Card>
          )}
          <a
            className={contentStyle("button")}
            href={`mailto:${env.SUPPORT_MAIL}?subject=${formatMessage({
              id: "errorPopup.mail.subject",
            })}&body=${encodeURIComponent(emailBody)}`}
          >
            {formatMessage({ id: "errorPopup.sendDetails" }, { a: env.SUPPORT_MAIL })}
            <ArrowRightIcon />
          </a>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
};
