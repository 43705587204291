import { cx } from "class-variance-authority";

import { CrossIcon } from "@eisox/icons";
import { Slot } from "@radix-ui/react-slot";

import { RoundIcon } from "../RoundIcon";
import * as Sheet from "../Sheet";

import styles from "./Drawer.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Drawer
 * -----------------------------------------------------------------------------------------------*/

type DrawerProps = Sheet.SheetProps;

const Root = Sheet.Root;

Root.displayName = "Drawer";

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/

type TriggerProps = Sheet.TriggerProps;

const Trigger = Sheet.Trigger;

Trigger.displayName = "DrawerTrigger";

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/

type ContentProps = Sheet.ContentProps;

const Content: React.FC<ContentProps> = ({ className, ...props }) => (
  <Sheet.Portal>
    <Sheet.Overlay />
    <Sheet.Content {...props} className={cx(styles.content, className)} />
  </Sheet.Portal>
);

Content.displayName = "DrawerContent";

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/

interface HeaderProps {
  classNames?: {
    root?: string;
    title?: string;
    close?: string;
  };
  children?: string;
}

const Header: React.FC<HeaderProps> = ({ classNames, children }) => (
  <div className={cx(styles.header, classNames?.root)}>
    <h2 className={cx(styles.header__title, classNames?.title)}>{children}</h2>
    <Sheet.Close asChild>
      <RoundIcon iconColor="blue" style={{ cursor: "pointer" }}>
        <CrossIcon />
      </RoundIcon>
    </Sheet.Close>
  </div>
);

/* -------------------------------------------------------------------------------------------------
 * Group
 * -----------------------------------------------------------------------------------------------*/

type GroupProps = React.ComponentPropsWithRef<"div">;

const Group: React.FC<GroupProps> = ({ className, ...props }) => (
  <div {...props} className={cx(styles.group, className)} role="group" />
);

Group.displayName = "DrawerGroup";

/* -------------------------------------------------------------------------------------------------
 * Label
 * -----------------------------------------------------------------------------------------------*/

interface LabelProps extends React.ComponentPropsWithRef<"div"> {
  asChild?: boolean;
}

const Label: React.FC<LabelProps> = ({ asChild, className, ...props }) => {
  const Comp = asChild ? Slot : "div";
  return <Comp {...props} className={cx(styles.label, className)} />;
};

Label.displayName = "DrawerLabel";

/* -------------------------------------------------------------------------------------------------
 * Close
 * -----------------------------------------------------------------------------------------------*/

type CloseProps = Sheet.CloseProps;

const Close = Sheet.Close;

Close.displayName = "DrawerClose";

export { Close, Content, Group, Header, Label, Root, Trigger };
export type { CloseProps, ContentProps, DrawerProps, GroupProps, HeaderProps, LabelProps, TriggerProps };
