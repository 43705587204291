import { forwardRef } from "react";

import clsx from "clsx";

import { useBem } from "@eisox/tools";

import styles from "./styles.module.scss";

export interface RadioProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  multiple?: boolean;
}

export const Radio = forwardRef<HTMLInputElement, RadioProps>(({ multiple = false, className, ...props }, ref) => {
  const bem = useBem(styles);
  const radioStyle = bem("radio");

  return (
    <input ref={ref} {...props} className={clsx(radioStyle({ multiple: multiple }), className)} type="radio" readOnly />
  );
});
