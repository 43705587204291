import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useParams, useRevalidator, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import type { PatchValvesValvesInner } from "@eisox/backend_webapp_api";
import { HeatingNetwork1TypeEnum } from "@eisox/backend_webapp_api";
import { primary } from "@eisox/colors";
import { ActionButton, Drawer, Select, TextInput, Typography } from "@eisox/design-system";
import { BinIcon, PencilIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";

import { DeleteDialog } from "~/UI";
import type { ValvesWithProblem, loader } from "~/UI/screens/House";
import { deleteValve as deleteValveById } from "~/apiV2";
import { useAction } from "~/hooks";
import { usePermissionsContext } from "~/providers";
import { idLoaderHouse, routeToValves } from "~/routes/utils";
import { API } from "~/types/API";

import styles from "./EditValveDrawer.module.scss";

interface EditValveDrawerProps {
  valve: ValvesWithProblem;
  open: boolean;
  onClose: VoidFunction;
}

/**
 * @todo: Migrate, delete useRouteLoaderData => recreate stories
 */
export const EditValveDrawer: React.FC<EditValveDrawerProps> = ({ valve, open, onClose }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const editValveDrawerStyle = bem("edit-valve");

  const { permissions } = usePermissionsContext("EditValveDrawer");

  const { houseId } = useParams() as { houseId: string };
  const { gateways, heatingNetworks, valves } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    ReturnType<typeof loader>
  >;

  const { submit } = useAction({
    onSuccess: () => onClose(),
  });

  const schema = yup.object({
    gateway: yup.string().required(),
    heatingNetworks: yup.string(),
    uid: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .test(
        "unique uid",
        formatMessage({ id: "room.valve.edit.error.uniqueUid" }),
        uid => !valves.some(v => v.uid === uid && v.id !== valve.id),
      )
      .required(formatMessage({ id: "error.required" })),
  });

  const {
    control,
    handleSubmit,
    register,
    formState: { isDirty, dirtyFields, errors },
  } = useForm<yup.InferType<typeof schema>>({
    resolver: yupResolver(schema),
    values: {
      gateway: valve.gatewayId,
      heatingNetworks: valve.heatingNetworkId,
      uid: valve.uid,
    },
  });

  const { revalidate } = useRevalidator();

  const { mutate: deleteValve } = useMutation({
    mutationFn: () => deleteValveById(houseId, valve.id),
    onSuccess: () => {
      revalidate();
      toggleDeleteDialog();
    },
    onError: () => toast.error(formatMessage({ id: "error.unKnowError.message" })),
  });

  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  const toggleDeleteDialog = () => setDeletePopupOpen(prev => !prev);

  const onSubmit = (data: yup.InferType<typeof schema>) => {
    const updatedValve: PatchValvesValvesInner = { id: valve.id };
    if (dirtyFields.gateway) updatedValve.gatewayId = data.gateway;
    if (dirtyFields.heatingNetworks) updatedValve.heatingNetworkId = data.heatingNetworks;
    if (dirtyFields.uid) updatedValve.uid = data.uid;
    submit([updatedValve], API.HTTP_METHOD.PATCH, routeToValves(houseId));
  };

  return (
    <Drawer title={formatMessage({ id: "room.valve.edit.title" })} open={open} onOpenChange={onClose}>
      <>
        <form className={editValveDrawerStyle()} onSubmit={handleSubmit(onSubmit)}>
          <label className={editValveDrawerStyle("label")}>
            {formatMessage({ id: "room.valve.edit.mac" })}
            <input className={editValveDrawerStyle("input")} type="text" disabled value={valve.mac ?? "-"} />
          </label>

          <TextInput
            {...register("uid")}
            type="number"
            label={formatMessage({ id: "room.valve.edit.uid" })}
            error={errors.uid}
          />

          <Controller
            control={control}
            name={"gateway"}
            render={({ field: { value, onChange } }) => (
              <Select
                label={formatMessage({ id: "room.valve.edit.gateway.label" })}
                value={value}
                options={gateways.map(g => ({ value: g.id, name: g.gatewayName }))}
                renderValue={(value?: string) => (
                  <Typography>
                    {value
                      ? gateways.find(g => g.id === value)?.gatewayName
                      : formatMessage({ id: "room.valve.edit.gateway.placeholder" })}
                  </Typography>
                )}
                onChange={onChange}
                disabled={!permissions.valve?.gatewayId?.update}
              />
            )}
          />
          {permissions.valve?.heatingNetworkId?.read && (
            <Controller
              control={control}
              name={"heatingNetworks"}
              render={({ field: { value, onChange } }) => (
                <Select
                  label={formatMessage({ id: "room.valve.edit.heatingNetwork.label" })}
                  value={value}
                  options={heatingNetworks
                    .filter(hn => hn.type === HeatingNetwork1TypeEnum.Radiator)
                    .map(hn => ({ value: hn.id, name: `${hn.parentName} / ${hn.name}` }))}
                  renderValue={(value?: string) => {
                    const hn = heatingNetworks.find(hn => hn.id === value);
                    return (
                      <Typography>
                        {hn
                          ? `${hn.parentName} / ${hn.name}`
                          : formatMessage({ id: "room.valve.edit.heatingNetwork.placeholder" })}
                      </Typography>
                    );
                  }}
                  onChange={onChange}
                  disabled={!permissions.valve?.heatingNetworkId?.update}
                />
              )}
            />
          )}

          <div className={editValveDrawerStyle("buttons")}>
            <ActionButton
              type="submit"
              rounded
              text={formatMessage({ id: "room.valve.edit.save" })}
              icon={<PencilIcon />}
              disabled={!isDirty}
            />
            {permissions.valve?.delete && (
              <ActionButton
                className={editValveDrawerStyle("delete")}
                variant="cancel"
                text={formatMessage({ id: "room.valve.edit.delete" })}
                onClick={() => setDeletePopupOpen(true)}
              />
            )}
          </div>
        </form>

        {deletePopupOpen && (
          <DeleteDialog.Root open onOpenChange={toggleDeleteDialog}>
            <DeleteDialog.Content
              icon={<BinIcon style={{ color: primary.red }} />}
              title={formatMessage({ id: "room.valve.edit.deleteDialog.title" })}
              onDelete={deleteValve}
            >
              <Typography className={editValveDrawerStyle("deleteDialog")}>
                {formatMessage({ id: "room.valve.edit.deleteDialog.description" })}
                <span>{valve.mac ?? valve.uid}</span>
              </Typography>
              <DeleteDialog.Validator />
            </DeleteDialog.Content>
          </DeleteDialog.Root>
        )}
      </>
    </Drawer>
  );
};
