import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { ActionButton, Drawer } from "@eisox/design-system";
import { ArrowRightIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { AddPlan } from "~/UI/layouts";

import styles from "./Levels.module.scss";

interface LevelsProps {
  open: boolean;
  onClose: VoidFunction;
}

export const Levels: React.FC<LevelsProps> = ({ open, onClose }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const levelsStyle = bem("levels");

  const { houseId } = useParams() as { houseId: string };

  return (
    <Drawer title={formatMessage({ id: "addPlanDrawer.title" })} open={open} onOpenChange={onClose}>
      <div className={levelsStyle()}>
        <AddPlan houseId={houseId} />
        <ActionButton
          rounded
          text={formatMessage({ id: "plans.add.levels.finsh" })}
          icon={<ArrowRightIcon />}
          onClick={() => onClose()}
        />
      </div>
    </Drawer>
  );
};
