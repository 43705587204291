import { useEffect, useState } from "react";
import type { PropsWithChildren } from "react";

import { t } from "i18next";
import type { TFunction } from "i18next";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { TooltipProps } from "recharts";
import { Bar, BarChart, Tooltip as RTooltip, ResponsiveContainer, XAxis, YAxis } from "recharts";
import type { CategoricalChartState } from "recharts/types/chart/types";
import type { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { utils, writeFile } from "xlsx";

import { PostHistoBoxIntervalEnum, PostHistoBoxModeEnum } from "@eisox/backend_webapp_api";
import type { LastGatewayActivity200ResponseMessageInner } from "@eisox/backend_webapp_api";
import { chart, primary } from "@eisox/colors";
import type { ConfigType } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import {
  ActionButtonV2,
  ButtonV2,
  Card,
  DatePicker,
  Loader,
  Modal,
  TextInput,
  ToggleGroup,
} from "@eisox/design-system";
import { ArrowRightIcon, CrossIcon, PointChartIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { lastGatewayActivityHistory } from "~/api/gatewayV2";
import { SUCCESS_FETCH } from "~/constants";
import { useUserPreferenceStore } from "~/stores";

import { Tooltip } from "../Tooltip";
import { MAX_COUNT, MAX_INTERVAL_DATE } from "./constants";
import { fillMissingDates } from "./utils";

import styles from "./ConnectionGatewayHistory.module.scss";

const CustomTooltip = ({
  payload,
  label,
  interval,
}: TooltipProps<ValueType, NameType> & {
  interval: PostHistoBoxIntervalEnum;
}) => {
  const bem = useBem(styles);
  const tooltipStyle = bem("tooltip");

  if (!payload || payload.length <= 0) return null;

  return (
    <Card className={tooltipStyle()}>
      <p className={tooltipStyle("date")}>
        {dayjs(label as string).format(`DD/MM/YYYY`)}{" "}
        {interval === PostHistoBoxIntervalEnum.Hour &&
          `${dayjs(label as string).format("HH:mm")} -
            ${dayjs(label as string)
              .add(1, "hour")
              .format("HH:mm")}`}
      </p>

      <p className={tooltipStyle("value")}>
        {payload[0].value} / {MAX_COUNT[interval]}
      </p>
    </Card>
  );
};

export type DateCountEntry = Required<Omit<LastGatewayActivity200ResponseMessageInner, "mac">>;

interface ConnectionGatewayHistoryProps extends PropsWithChildren, Modal.RootProps {
  uid: number;
  gatewayName: string;
  mac: string;
}

const schema = z
  .object({
    interval: z.nativeEnum(PostHistoBoxIntervalEnum),
    date: z.object(
      {
        from: z.date(),
        to: z.date(),
      },
      { required_error: t("error.required") },
    ),
  })
  .refine(
    data => dayjs(data.date.to).diff(dayjs(data.date.from), "days") < MAX_INTERVAL_DATE[data.interval],
    data => ({
      message: t("connectionGatewayHistory.error.date", { x: MAX_INTERVAL_DATE[data.interval] }),
      path: ["date"],
    }),
  );

export const ConnectionGatewayHistory: React.FC<ConnectionGatewayHistoryProps> = ({
  children,
  uid,
  gatewayName,
  mac,
  open,
  ...props
}) => {
  const { t } = useTranslation();

  const bem = useBem(styles);
  const connectionGatewayHistoryStyle = bem("connection-gateway-history");
  const calendarStyle = bem("calendar");

  const { mode } = useUserPreferenceStore.use.connectionGatewayHistory();
  const setConnectionGatewayHistoryMode = useUserPreferenceStore.use.setConnectionGatewayHistoryMode();

  const [loading, setLoading] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [data, setData] = useState<DateCountEntry[]>([]);
  const [dataHour, setDataHour] = useState<
    | {
        from: Date;
        to: Date;
        data: DateCountEntry[];
      }
    | undefined
  >(undefined);

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      interval: mode,
      date: {
        from: dayjs()
          .subtract(MAX_INTERVAL_DATE.hour - 1, "day")
          .toDate(),
        to: dayjs().toDate(),
      },
    },
  });

  const onClickOnBar = async (data: CategoricalChartState, interval: PostHistoBoxIntervalEnum) => {
    const date = data.activePayload ? (data.activePayload[0] as { payload: DateCountEntry }).payload.date : undefined;
    // Change interval to hour if the user click on a day bar
    if (interval === PostHistoBoxIntervalEnum.Day) {
      setValue("interval", PostHistoBoxIntervalEnum.Hour, { shouldDirty: true });
      setValue("date", {
        from: dayjs(date).toDate(),
        to: dayjs(date).toDate(),
      });
      await onSubmit();
    } else {
      await onSubmitHour(dayjs(date).toDate(), dayjs(date).toDate());
    }
  };

  const exportToCSV = (
    data: DateCountEntry[],
    from: ConfigType,
    to: ConfigType,
    interval: PostHistoBoxIntervalEnum,
  ) => {
    const worksheet = utils.json_to_sheet(
      data.map(d => ({
        date: dayjs(d.date).format(`DD/MM/YYYY ${interval === PostHistoBoxIntervalEnum.Hour ? "HH:mm:ss" : ""}`),
        value: d.value,
      })),
    );
    const wb = utils.book_new();
    utils.book_append_sheet(wb, worksheet, "Data");
    writeFile(wb, `box_activity_${dayjs(from).format("DD-MM-YYYY")}_${dayjs(to).format("DD-MM-YYYY")}.xlsx`);
  };

  const onSubmit = handleSubmit(async data => {
    setLoading(true);
    const start = dayjs(data.date.from).startOf("day").toISOString();
    const end = dayjs(data.date.to).endOf("day").toISOString();
    const request = await lastGatewayActivityHistory({
      startDate: start,
      endDate: end,
      gateways: [mac],
      mode: PostHistoBoxModeEnum.Count,
      interval: data.interval,
    });
    setData(
      request.type === SUCCESS_FETCH ? fillMissingDates(request.result.message ?? [], data.interval, start, end) : [],
    );
    setLoading(false);
  });

  const onSubmitHour = async (from: Date, to: Date) => {
    const start = dayjs(from).startOf("hour");
    const end = dayjs(to).endOf("hour");

    const request = await lastGatewayActivityHistory({
      startDate: start.toISOString(),
      endDate: end.toISOString(),
      gateways: [mac],
      mode: PostHistoBoxModeEnum.Point,
    });

    setDataHour(
      request.type === SUCCESS_FETCH
        ? {
            from: start.toDate(),
            to: end.toDate(),
            data: (request.result.message ?? []).map(d => ({
              ...d,
              value: d.value ?? 0,
            })),
          }
        : undefined,
    );
  };

  useEffect(() => {
    if (open) void onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const interval = watch("interval");
  const dates = watch("date");

  const axisStyle = { fontWeight: 400, fill: primary.darkBlue };

  const disabledHour = dayjs(dates.to).diff(dayjs(dates.from), "days") >= MAX_INTERVAL_DATE.hour;

  return (
    <Modal.Root {...props} open={open}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={connectionGatewayHistoryStyle()}>
        <Modal.Header
          title={t("connectionGatewayHistory.title")}
          subtitle={t("connectionGatewayHistory.subtitle")}
          icon={<PointChartIcon />}
        >
          <Modal.Close>
            <CrossIcon className={connectionGatewayHistoryStyle("close")} />
          </Modal.Close>
        </Modal.Header>
        <div className={connectionGatewayHistoryStyle("content")}>
          <form>
            <p>
              {uid} - {gatewayName} <span>({mac})</span>
            </p>
            <div>
              <Controller
                name="interval"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Tooltip
                    openingCondition={disabledHour}
                    content={t("connectionGatewayHistory.error.date", { x: MAX_INTERVAL_DATE.hour })}
                  >
                    <ToggleGroup.Root
                      value={value}
                      onValueChange={async v => {
                        onChange(v);
                        setConnectionGatewayHistoryMode(v as PostHistoBoxIntervalEnum);
                        setDataHour(undefined);
                        await onSubmit();
                      }}
                    >
                      <ToggleGroup.Item value="day">{t("connectionGatewayHistory.toogleGroup.day")}</ToggleGroup.Item>
                      <ToggleGroup.Item value="hour" disabled={disabledHour}>
                        {t("connectionGatewayHistory.toogleGroup.hour")}
                      </ToggleGroup.Item>
                    </ToggleGroup.Root>
                  </Tooltip>
                )}
              />
              <Controller
                name="date"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DatePicker.Root
                    value={value}
                    onChange={v => {
                      if (v) onChange(v);
                    }}
                    open={calendarOpen}
                    onOpenChange={setCalendarOpen}
                    mode="range"
                    format="DD/MM/YYYY"
                    disabled={{
                      before: dayjs().subtract(MAX_INTERVAL_DATE.day, "day").toDate(),
                      after: dayjs().toDate(),
                    }}
                  >
                    <DatePicker.Trigger asChild className={calendarStyle("trigger")}>
                      {value => <TextInput value={value} error={!!errors.date} />}
                    </DatePicker.Trigger>
                    <DatePicker.Content className={calendarStyle("content")} asChild>
                      <DatePicker.Calendar />
                      {errors.date && <p className={calendarStyle("error")}>{errors.date.message}</p>}
                      <ActionButtonV2
                        variant="primary"
                        className={calendarStyle("submit")}
                        onClick={async e => {
                          e.stopPropagation();
                          setCalendarOpen(false);
                          await onSubmit();
                        }}
                      >
                        {t("connectionGatewayHistory.submit")}
                        <ArrowRightIcon />
                      </ActionButtonV2>
                    </DatePicker.Content>
                  </DatePicker.Root>
                )}
              />
              <ButtonV2 disabled={data.length === 0} onClick={() => exportToCSV(data, dates.from, dates.to, interval)}>
                {t("connectionGatewayHistory.export")}
                <ArrowRightIcon />
              </ButtonV2>
            </div>
          </form>
          <div className={connectionGatewayHistoryStyle("graph")}>
            {data.length > 0 ? (
              <ResponsiveContainer key={JSON.stringify(dataHour)}>
                <BarChart data={data} onClick={data => onClickOnBar(data, interval)}>
                  <XAxis
                    dataKey="date"
                    tickFormatter={date =>
                      dayjs(date as string).format(
                        `DD/MM/YYYY ${interval === PostHistoBoxIntervalEnum.Hour ? "HH:mm" : ""} `,
                      )
                    }
                    axisLine={false}
                    tickLine={false}
                    tickMargin={16}
                    style={axisStyle}
                    padding={{ left: 40, right: 10 }}
                  />
                  <YAxis dataKey="value" axisLine={false} tickLine={false} domain={[0, MAX_COUNT[interval]]} />
                  <RTooltip content={<CustomTooltip interval={interval} />} cursor={{ fill: "transparent" }} />
                  <Bar dataKey="value" fill={chart.blue} background={{ fill: primary.gray }} />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              t("connectionGatewayHistory.error.noData")
            )}

            {loading && (
              <div className={connectionGatewayHistoryStyle("loader")}>
                <Loader />
              </div>
            )}
          </div>
          {dataHour && (
            <ul className={connectionGatewayHistoryStyle("hour-list")}>
              <li>{`${dayjs(dataHour.from).format("DD/MM/YYYY")} - ${dayjs(dataHour.from).format("HH[h]")} <-> ${dayjs(dataHour.to).add(1, "hour").format("HH[h]")}`}</li>
              {dataHour.data.map((d, i) => (
                <li key={i}>{`${dayjs(d.date).format("DD/MM/YYYY HH:mm:ss")}`}</li>
              ))}
            </ul>
          )}
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
