import { forwardRef } from "react";

import clsx from "clsx";
import { NavLink, NavLinkProps } from "react-router-dom";

import styles from "./Navlink.module.scss";

interface NavlinkProps extends NavLinkProps {
  children: React.ReactNode;
  className?: string;
}

export const Navlink = forwardRef<HTMLAnchorElement, NavlinkProps>(
  ({ to, end, children, className, ...props }, ref) => (
    <NavLink
      {...props}
      ref={ref}
      className={({ isActive }) => clsx(styles.sidebarItem, isActive && styles.sidebarItem_active, className)}
      to={to}
      end={end}
      onClick={props.onClick}
    >
      {children}
    </NavLink>
  ),
);
