import * as React from "react";

import { IconProps } from "./types";

export const FileIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M3.99792 1.99792C4.31673 1.67911 4.74913 1.5 5.2 1.5H9.73603C10.1802 1.50006 10.6067 1.67396 10.9244 1.9845L13.5883 4.58964C13.5884 4.58967 13.5883 4.58961 13.5883 4.58964C13.7502 4.74793 13.879 4.93706 13.9668 5.14575C14.0547 5.35448 14.1 5.57866 14.1 5.80514V12.8C14.1 13.2508 13.9209 13.6832 13.6021 14.0021C13.2833 14.3209 12.8509 14.5 12.4 14.5H5.2C4.74913 14.5 4.31673 14.3209 3.99792 14.0021C3.6791 13.6832 3.5 13.2508 3.5 12.8V3.2C3.5 2.74913 3.6791 2.31673 3.99792 1.99792ZM5.2 2.5C5.01435 2.5 4.8363 2.57375 4.70503 2.70503C4.57375 2.8363 4.5 3.01435 4.5 3.2V12.8C4.5 12.9856 4.57375 13.1637 4.70503 13.295C4.8363 13.4263 5.01435 13.5 5.2 13.5H12.4C12.5856 13.5 12.7637 13.4263 12.895 13.295C13.0263 13.1637 13.1 12.9856 13.1 12.8V6.1H11.2C10.7492 6.1 10.3167 5.9209 9.99791 5.60208C9.67912 5.28327 9.50001 4.85087 9.50001 4.4V2.5H5.2ZM10.5 2.9682V4.4C10.5 4.58565 10.5737 4.7637 10.705 4.89497C10.8363 5.02625 11.0144 5.1 11.2 5.1H12.6799L10.5 2.9682ZM6.5 8.6C6.5 8.32386 6.72386 8.1 7 8.1H10.6C10.8761 8.1 11.1 8.32386 11.1 8.6C11.1 8.87613 10.8761 9.10002 10.6 9.10002H7C6.72386 9.10002 6.5 8.87613 6.5 8.6ZM6.5 11C6.5 10.7239 6.72386 10.5 7 10.5H8.8C9.07614 10.5 9.30002 10.7239 9.30002 11C9.30002 11.2761 9.07614 11.5 8.8 11.5H7C6.72386 11.5 6.5 11.2761 6.5 11Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default FileIcon;
