import * as React from "react";

import { IconProps } from "./types";

export const SwapIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M10.9447 2.55687L12.8313 4.58863H5.38414V5.52742H12.8313L10.9447 7.55918L11.5611 8.223L14.5 5.05803L11.5611 1.89308L10.9447 2.55687Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
      <path
        d="M5.0553 8.44075L4.4389 7.77693L1.5 10.9419L4.4389 14.1069L5.0553 13.4431L3.16867 11.4113H10.6158V10.4725H3.16867L5.0553 8.44075V8.44075Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default SwapIcon;
