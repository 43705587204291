import { useTranslation } from "react-i18next";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import { useMutation } from "@tanstack/react-query";

import { resetPassword } from "~/apiV2";
import { ResetPasswordPage } from "~/pages";
import { routeToSignIn } from "~/routes/utils";

import type { loader } from "../loader";
import { tokenLoaderId } from "../route";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { token } = useRouteLoaderData(tokenLoaderId) as LoaderData<ReturnType<typeof loader>>;
  const { t } = useTranslation();

  const { mutate } = useMutation({
    mutationFn: resetPassword,
    onSuccess: _ => {
      toast.success(t("resetPassword.success"));
      navigate(routeToSignIn());
    },
  });

  return <ResetPasswordPage token={token} submit={mutate} />;
};

export { ResetPassword };
