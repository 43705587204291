import type { HousesHouseIdPatch200Response, PatchHouse } from "@eisox/backend_webapp_api";

import { env } from "~/configuration";
import { PATCH_METHOD } from "~/constants";
import { internalFetchByUrl } from "~/helpers/communication/FetchApi";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { routeHouses } from "~/routesStatic/routesExpress";
import { useUserStore } from "~/stores";

const SERVER_URL = env.SERVER_URL;

export const updateHouse = async (
  houseId: string,
  body: PatchHouse,
): Promise<FetchResponse<HousesHouseIdPatch200Response>> => {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const routeToPatchHouse = SERVER_URL + routeHouses + "/" + houseId;
  return internalFetchByUrl(routeToPatchHouse, PATCH_METHOD, customHeaders, body);
};
