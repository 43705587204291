import { forwardRef } from "react";

import clsx from "clsx";

import type { BoilerRoomsPosInner } from "@eisox/backend_webapp_api";
import { Card, Circle } from "@eisox/design-system";
import { BoilerRoomIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { Tooltip } from "~/UI/components";

import styles from "./Boilerroom.module.scss";

interface BoilerroomLogoProps {
  name?: string;
  selected?: boolean;
  disabled?: boolean;
  error?: boolean;
  size?: number;
  onClick?: VoidFunction;
  circleClassName?: string;
  logoClassName?: string;
}

const BoilerroomLogo = forwardRef<HTMLDivElement, BoilerroomLogoProps>(
  ({ name, selected, disabled, error, size = 40, onClick, circleClassName, logoClassName, ...props }, forwardedRef) => {
    return (
      <Tooltip content={name}>
        <Circle
          {...props}
          ref={forwardedRef}
          className={circleClassName}
          size={size}
          selected={selected}
          disabled={disabled}
          error={error ? "error" : undefined}
          onClick={onClick}
        >
          <BoilerRoomIcon className={logoClassName} width={size / 2} height={size / 2} />
        </Circle>
      </Tooltip>
    );
  },
);

interface BoilerroomProps extends BoilerRoomsPosInner {
  expanded?: boolean;
  selected?: boolean;
  disabled?: boolean;
  error?: boolean;
  size?: number;
  onClick?: VoidFunction;
  className?: string;
}

export const Boilerroom = forwardRef<HTMLDivElement, BoilerroomProps>(
  (
    { name, expanded, selected = false, disabled = false, error = false, size = 40, onClick, className, ...props },
    forwardedRef,
  ) => {
    const bem = useBem(styles);
    const boilerroomStyle = bem("boilerroom");

    return expanded ? (
      <Card
        ref={forwardedRef}
        className={clsx(boilerroomStyle({ error: error, disabled: disabled, selected: selected }), className)}
        onClick={onClick}
        {...props}
      >
        <BoilerroomLogo
          circleClassName={boilerroomStyle("circle")}
          logoClassName={boilerroomStyle("logo", { color: "darkBlue" })}
          error={error}
          selected={selected}
          disabled={disabled}
          size={size}
        />
        <p>{name ?? "-"}</p>
      </Card>
    ) : (
      <BoilerroomLogo
        ref={forwardedRef}
        logoClassName={boilerroomStyle("logo", { color: "white" })}
        name={name}
        error={error}
        selected={selected}
        disabled={disabled}
        size={size}
        onClick={onClick}
        {...props}
      />
    );
  },
);
