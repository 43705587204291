import type { GatewaysWithProblem, HouseType, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import { getRoomProblemTranslation } from "~/utils";
import { getGatewayProblemTranslation } from "~/utils/Gateway";
import { getValveProblemTranslation } from "~/utils/Valve";

import type { ItemProps } from "../components/Item";

const getItems = (
  items: GatewaysWithProblem[] | ValvesWithProblem[] | RoomsWithProblem[],
  displayeItems: "gateways" | "valves" | "rooms",
  house: HouseType,
): Omit<ItemProps, "houseId" | "gateways" | "rooms" | "onClickOnPlan" | "onClickOnRoom">[] => {
  if (displayeItems === "gateways") {
    const gateways = items as GatewaysWithProblem[];
    return gateways.map(g => ({
      type: "gateways",
      name: [g.uid, g.gatewayName, g.mac].join(" - "),
      ...getGatewayProblemTranslation(g),
      item: g,
    }));
  }
  if (displayeItems === "valves") {
    const valves = items as ValvesWithProblem[];
    return valves.map(v => ({
      type: "valves",
      name: [v.uid, v.mac, v.roomName].join(" - "),
      ...getValveProblemTranslation(v),
      item: v,
    }));
  }
  const rooms = items as RoomsWithProblem[];
  return rooms.map(r => ({
    type: "rooms",
    name: r.name!,
    ...getRoomProblemTranslation(r, house),
    item: r,
  }));
};

export { getItems };
