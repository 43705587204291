import { withNaming } from "@bem-react/classname";
import clsx from "clsx";

// block-name__elem-name_mod-name_mod-val
const cn = withNaming({ e: "__", m: "_", v: "_" });

export const useBem = (styles: { [key: string]: string }) => {
  return (blockName: string) => {
    const bem = cn(blockName);
    return (
      elementOrModifiers?: string | Record<string, boolean | string>,
      modifiers?: Record<string, boolean | string>,
    ) => {
      if (typeof elementOrModifiers === "string") {
        const element = elementOrModifiers;
        const classNames = modifiers ? bem(element, modifiers).split(" ") : [bem(element)];
        return clsx(classNames.map(c => styles[c]));
      } else if (typeof elementOrModifiers === "object") {
        const classNames = bem(elementOrModifiers).split(" ");
        return clsx(classNames.map(c => styles[c]));
      } else {
        return styles[blockName];
      }
    };
  };
};
