import { cx } from "class-variance-authority";

import { Typography } from "@eisox/design-system";
import { ChevronDownIcon, ChevronUpIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";
import type { TableOptions } from "@tanstack/react-table";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";

import styles from "./Table.module.scss";

interface TableProps<T extends Record<string, any>> extends Pick<TableOptions<T>, "data" | "columns" | "initialState"> {
  className?: string;
}

export const Table = <T extends Record<string, any>>({ className, ...options }: TableProps<T>) => {
  const bem = useBem(styles);
  const tableStyle = bem("table");
  const headerStyle = bem("header");
  const bodyStyle = bem("body");

  const table = useReactTable<T>({
    ...options,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className={cx(tableStyle(), className)}>
      {table.getHeaderGroups().map(headerGroup => (
        <div className={headerStyle()} key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <div
              className={headerStyle("cell", { sortable: header.column.getCanSort() })}
              key={header.id}
              onClick={header.column.getToggleSortingHandler()}
            >
              <Typography keepHoverOpen>{flexRender(header.column.columnDef.header, header.getContext())}</Typography>
              {{
                asc: <ChevronDownIcon className={headerStyle("arrow")} />,
                desc: <ChevronUpIcon className={headerStyle("arrow")} />,
              }[header.column.getIsSorted() as string] ?? null}
            </div>
          ))}
        </div>
      ))}
      <div className={bodyStyle()}>
        <div className={bodyStyle("wrapper")}>
          {table.getRowModel().rows.map(row => {
            return (
              <div className={bodyStyle("row")} key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <div className={bodyStyle("cell")} key={cell.id}>
                      <Typography keepHoverOpen>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Typography>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
