import { cx } from "class-variance-authority";
import { useTranslation } from "react-i18next";

import { ButtonV2, RoundIcon, ToggleGroup } from "@eisox/design-system";
import { ArrowRightIcon, UpdateIcon } from "@eisox/icons";

import type { GatewaysWithProblem, HouseType, RoomsWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import type { WebappRolePermission } from "~/utils";
import { UserRole } from "~/utils";

import { exportToCSV, getGatewayRows, getRoomRows, getValveRows } from "../../helpers/export";

import styles from "./Header.module.scss";

interface HeaderProps {
  gatewaysErrors: number;
  gatewaysWarning: number;
  valvesErrors: number;
  valvesWarning: number;
  filters: string[];
  roomsErrors: number;
  roomsWarning: number;
  updatedAt: string;
  role?: WebappRolePermission;
  displayedItems: "gateways" | "valves" | "rooms";
  onDisplayedItemsChange: (value: "gateways" | "valves" | "rooms") => void;
  onRefresh: VoidFunction;
  preReplace?: boolean;
  items: GatewaysWithProblem[] | ValvesWithProblem[] | RoomsWithProblem[];
}

const Header: React.FC<HeaderProps> = ({
  gatewaysErrors,
  gatewaysWarning,
  valvesErrors,
  valvesWarning,
  filters,
  roomsErrors,
  roomsWarning,
  updatedAt,
  displayedItems,
  role,
  onDisplayedItemsChange,
  onRefresh,
  preReplace = false,
  items,
}) => {
  const { t } = useTranslation();

  const exportData = () => {
    const includeProblems = filters.length > 0;
    const dataFetchers = {
      valves: () => getValveRows(items as ValvesWithProblem[], includeProblems),
      gateways: () => getGatewayRows(items as GatewaysWithProblem[], includeProblems),
      rooms: () => getRoomRows(items as RoomsWithProblem[], includeProblems),
    };

    const { header, rows } = dataFetchers[displayedItems]();

    exportToCSV({ header, rows });
  };

  return (
    <div className={styles.header}>
      <ToggleGroup.Root value={displayedItems} onValueChange={onDisplayedItemsChange}>
        <ToggleGroup.Item value="gateways">
          <Indicator error={gatewaysErrors} warning={gatewaysWarning} />
          {t("advancedMaintenance.header.gateways")}
        </ToggleGroup.Item>
        <ToggleGroup.Item value="valves">
          <Indicator error={valvesErrors} warning={valvesWarning} />
          {t("advancedMaintenance.header.valves")}
        </ToggleGroup.Item>
        {!preReplace && (
          <ToggleGroup.Item value="rooms">
            <Indicator error={roomsErrors} warning={roomsWarning} />
            {t("advancedMaintenance.header.rooms")}
          </ToggleGroup.Item>
        )}
      </ToggleGroup.Root>
      <div className={styles.header__refresh}>
        <ButtonV2 onClick={() => exportData()}>
          {t("advancedMaintenance.header.export")} <ArrowRightIcon />
        </ButtonV2>
        <span>{updatedAt}</span>
        <RoundIcon
          size={44}
          backgroundColor="gray"
          iconColor={"darkGray"}
          style={{ cursor: "pointer" }}
          onClick={onRefresh}
        >
          <UpdateIcon />
        </RoundIcon>
      </div>
    </div>
  );
};

const Indicator: React.FC<{ error: number; warning: number }> = ({ error, warning }) => (
  <div className={cx(styles.indicator, error > 0 ? styles.indicator_error : warning > 0 && styles.indicator_warning)} />
);

export { Header };
export type { HeaderProps };
