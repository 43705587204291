import type { PropsWithChildren } from "react";
import { useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import { ActionButton, Modal, Select } from "@eisox/design-system";
import { PencilIcon, PumpVerticalIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import { PermutationCommand } from "~/socketio/types";

import { useBoilerRoomContextWithCurrentBoilerRoom } from "../../providers";
import { CascadeDialog } from "../CascadeDialog";

import styles from "./PumpsDialog.module.scss";

const NAME = "PumpsDialog";

type PumpsDialogProps = PropsWithChildren;

export const PumpsDialog: React.FC<PumpsDialogProps> = ({ children }) => {
  const { formatMessage } = useIntl();
  const bem = useBem(styles);

  const pumpsDialogStyle = bem("pumps-dialog");
  const headerStyle = bem("header");
  const contentStyle = bem("content");
  const permutationStyle = bem("permutation");

  const { currentBoilerRoom, boilerRooms, useUpdateBoilerRoom, history } =
    useBoilerRoomContextWithCurrentBoilerRoom(NAME);
  const boilerRoomId = currentBoilerRoom.id;

  const parentBoilerrooms = boilerRooms?.filter(b => !b.objectNameParent);

  const [open, setOpen] = useState(false);
  const [currentBoilerroom, setCurrentBoilerroom] = useState(parentBoilerrooms?.find(b => b.id === boilerRoomId));

  const { mutate } = useUpdateBoilerRoom({ onSuccess: () => handleOpenChange(false) });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    watch,
    reset,
  } = useForm<{ permutationDay: number; permutationHour: number }>({
    values: {
      permutationDay: currentBoilerroom?.permutationDay!,
      permutationHour: currentBoilerroom?.permutationHour!,
    },
  });

  const values = watch();

  const onSubmit = (data: { permutationDay: number; permutationHour: number }) => {
    mutate([{ id: boilerRoomId, ...data }]);
  };

  const handleOpenChange = (open: boolean) => {
    reset();
    setOpen(open);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={pumpsDialogStyle()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            title={formatMessage({ id: "boilerRoom.header.actions.pumps.title" })}
            subtitle={formatMessage({ id: "boilerRoom.header.actions.pumps.subtitle" })}
            icon={<PumpVerticalIcon />}
          >
            <Modal.Close>
              <ActionButton
                variant="cancel"
                className={headerStyle("button", { type: "cancel" })}
                text={formatMessage({ id: "boilerRoom.dialog.network.header.cancel" })}
              />
            </Modal.Close>
            <ActionButton
              rounded
              icon={<PencilIcon />}
              type="submit"
              text={formatMessage({ id: "boilerRoom.dialog.network.header.save" })}
              disabled={!isDirty}
            />
          </Modal.Header>
          <div className={contentStyle()}>
            {parentBoilerrooms && parentBoilerrooms.length > 1 && (
              <Select
                className={contentStyle("boilerroom")}
                options={parentBoilerrooms.flatMap(p => ({
                  name: p.name ?? formatMessage({ id: "boilerroom.defaultTitle" }),
                  value: p.id,
                }))}
                value={currentBoilerroom?.id}
                renderValue={(value?: string) => <p>{parentBoilerrooms.find(p => p.id === value)?.name}</p>}
                onChange={(value: string) => setCurrentBoilerroom(parentBoilerrooms.find(c => c.id === value))}
              />
            )}
            <p className={contentStyle("text")}>
              {formatMessage(
                { id: "boilerRoom.header.actions.pumps.text" },
                {
                  d: formatMessage({ id: `boilerRoomDay.${values.permutationDay}` }).toLowerCase(),
                  h: `${values.permutationHour}h`,
                },
              )}
            </p>
            <div className={permutationStyle()}>
              <div className={permutationStyle("column")}>
                <label>{formatMessage({ id: "boilerRoom.header.actions.pumps.permutationDay" })}</label>
                <Controller
                  control={control}
                  name="permutationDay"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      triggerClassName={permutationStyle("trigger", { style: "long" })}
                      options={[...Array(7)].map((_, index) => {
                        return {
                          name: formatMessage({
                            id: `boilerRoomDay.${index + 1}`,
                          }),
                          value: (index + 1).toString(),
                        };
                      })}
                      value={value.toString()}
                      renderValue={(value?: string) => (
                        <p>
                          {formatMessage({
                            id: `boilerRoomDay.${value}`,
                          })}
                        </p>
                      )}
                      onChange={(value: string) => onChange(parseInt(value))}
                      disabled={currentBoilerroom?.permutationCmd === PermutationCommand.AUTO || history}
                    />
                  )}
                />
              </div>
              <div className={permutationStyle("column")}>
                <label>{formatMessage({ id: "boilerRoom.header.actions.pumps.permutationHour" })}</label>
                <Controller
                  control={control}
                  name="permutationHour"
                  render={({ field: { value, onChange } }) => (
                    <Select
                      triggerClassName={permutationStyle("trigger", { style: "short" })}
                      options={[...Array(24)].map((_, index) => {
                        return {
                          name: `${index}h`,
                          value: index.toString(),
                        };
                      })}
                      value={value.toString()}
                      renderValue={(value?: string) => <p>{value}h</p>}
                      onChange={(value: string) => onChange(parseInt(value))}
                      disabled={currentBoilerroom?.permutationCmd === PermutationCommand.AUTO || history}
                    />
                  )}
                />
              </div>
            </div>
            <p className={contentStyle("text", { note: true, style: "italic" })}>
              {formatMessage(
                { id: "boilerRoom.header.actions.pumps.note" },
                {
                  link: (
                    <CascadeDialog>
                      <span className={contentStyle("text", { type: "link" })}>
                        {formatMessage({ id: "buttons.handlePrimary" })}
                      </span>
                    </CascadeDialog>
                  ),
                },
              )}
            </p>
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
