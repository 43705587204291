import { env } from "~/configuration";
import { useUserStore } from "~/stores";

import { DELETE_METHOD, GET_METHOD, PATCH_METHOD, POST_METHOD } from "../constants/fetchConstants";
import { internalFetchByUrl } from "../helpers/communication/FetchApi";
import { modulesRouteConcat, routeHouses, routeModuleGatewayConcat } from "../routesStatic/routesExpress";

const ServerUrl = env.SERVER_URL;

export function createModule(idHouse, body) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  //POST /houses/{houseId}/modules
  const RouteCreateModule = ServerUrl + routeHouses + "/" + idHouse + modulesRouteConcat;
  if (body.length !== 0) {
    fetchingResponse = internalFetchByUrl(RouteCreateModule, POST_METHOD, customHeaders, body);
  }
  return fetchingResponse;
}

export function updateModule(idHouse, idModule, body) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteCreateModuleContent = ServerUrl + routeHouses + "/" + idHouse + routeModuleGatewayConcat + idModule;
  fetchingResponse = internalFetchByUrl(RouteCreateModuleContent, PATCH_METHOD, customHeaders, body);
  return fetchingResponse;
}

export function deleteModule(idHouse, idModule) {
  let fetchingResponse = {};
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteCreateModuleContent = ServerUrl + routeHouses + "/" + idHouse + routeModuleGatewayConcat + idModule;
  fetchingResponse = internalFetchByUrl(RouteCreateModuleContent, DELETE_METHOD, customHeaders);
  return fetchingResponse;
}

export async function getProxy(moduleId) {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const RouteCreateModuleContent = ServerUrl + "/api/v3" + modulesRouteConcat + moduleId + "/proxy";
  return await internalFetchByUrl(RouteCreateModuleContent, GET_METHOD, customHeaders);
}
