import clsx from "clsx";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import dayjs from "@eisox/dayjs";
import { DateInput } from "@eisox/design-system";
import { useBem } from "@eisox/tools";

import { SummerWinterScheme } from "../../components";
import type { SummerWinterSeasonType } from "../../schemas";

import styles from "./SummerWinter.module.scss";

interface SummerWinterProps {
  disabled?: boolean;
}

export const SummerWinter: React.FC<SummerWinterProps> = ({ disabled = false }) => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const summerWinterStyle = bem("summer-winter");

  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext<SummerWinterSeasonType>();

  const summerStart = watch("summerPeriods.begin");
  const summerEnd = watch("summerPeriods.end");
  const winterStart = watch("winterPeriods.begin");
  const winterEnd = watch("winterPeriods.end");

  return (
    <div className={clsx(summerWinterStyle())}>
      <div className={summerWinterStyle("timeslots")}>
        <label className={summerWinterStyle("season")}>
          {formatMessage({ id: "settings.content.menu.houses.season.summerWinter.sheme.winter" })}
          <Controller
            control={control}
            name="winterPeriods.begin"
            render={({ field: { value, onChange } }) => (
              <DateInput
                value={value ? dayjs(value, "DD/MM") : null}
                onChange={value => {
                  if (value?.isValid()) {
                    onChange(value.format("DD-MM"));
                    setValue("summerPeriods.end", value.subtract(1, "day").format("DD-MM"));
                  }
                }}
                disabled={disabled}
              />
            )}
          />
          <span className={summerWinterStyle("separator")}>-</span>
          <Controller
            control={control}
            name="winterPeriods.end"
            render={({ field: { value, onChange } }) => (
              <DateInput
                value={value ? dayjs(value, "DD/MM") : null}
                onChange={value => {
                  if (value?.isValid()) {
                    onChange(value.format("DD-MM"));
                    setValue("summerPeriods.begin", value.add(1, "day").format("DD-MM"));
                  }
                }}
                disabled={disabled}
              />
            )}
          />
        </label>
        <label className={summerWinterStyle("season")}>
          {formatMessage({ id: "settings.content.menu.houses.season.summerWinter.sheme.summer" })}
          <Controller
            name="summerPeriods.begin"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                value={value ? dayjs(value, "DD/MM") : null}
                onChange={value => {
                  if (value?.isValid()) {
                    onChange(value.format("DD-MM"));
                    setValue("winterPeriods.end", value.subtract(1, "day").format("DD-MM"));
                  }
                }}
                disabled={disabled}
              />
            )}
          />
          <span className={summerWinterStyle("separator")}>-</span>
          <Controller
            name="summerPeriods.end"
            control={control}
            render={({ field: { value, onChange } }) => (
              <DateInput
                value={value ? dayjs(value, "DD/MM") : null}
                onChange={value => {
                  if (value?.isValid()) {
                    onChange(value.format("DD-MM"));
                    setValue("winterPeriods.begin", value.add(1, "day").format("DD-MM"));
                  }
                }}
                disabled={disabled}
              />
            )}
          />
        </label>
        {(!!errors.summerPeriods || !!errors.winterPeriods) && (
          <p className={summerWinterStyle("error")}>
            {formatMessage({ id: "settings.content.menu.houses.season.required" })}
          </p>
        )}
      </div>
      <SummerWinterScheme
        className={summerWinterStyle("scheme")}
        summerStart={summerStart}
        summerEnd={summerEnd}
        winterStart={winterStart}
        winterEnd={winterEnd}
      />
    </div>
  );
};
