import { useState } from "react";

import { cx } from "class-variance-authority";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";
import { toast } from "react-toastify";
import { $enum } from "ts-enum-util";

import { ActionButtonV2 as ActionButton, Divider, Modal, SelectV2 as Select } from "@eisox/design-system";
import { ArrowRightIcon, LocationIcon } from "@eisox/icons";
import { z } from "@eisox/zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";

import { FieldContainer, Tooltip } from "~/UI/components";
import type {
  Gateway as ApiGateway,
  Plan as ApiPlan,
  Room as ApiRoom,
  Valve as ApiValve,
  UpdateValveRequestBody,
} from "~/apiV2";
import { updateValves } from "~/apiV2";

import {
  apiGatewaysToGatewaysMapper,
  apiPlansToPlansMapper,
  apiRoomsToRoomsMapper,
  apiValvesToValvesMapper,
  getDisplayedValves,
  getHslopeAverage,
} from "../../helpers";
import { Data, GroupField, Item, SortField, SortOrder } from "../../types";
import { Display } from "../Display";
import { Distribution } from "../Distribution";
import { List } from "../List";
import { Plan } from "../Plan";

import styles from "./Modal.module.scss";

const NAME = "ManualBalancing";
interface ManualBalancingModalProps {
  houseId: string;
  networkName: string;
  valves: ApiValve[];
  rooms: ApiRoom[];
  gateways: ApiGateway[];
  plans: ApiPlan[];
  children: React.ReactNode;
}

const ManualBalancingModal: React.FC<ManualBalancingModalProps> = props => {
  const { t } = useTranslation();

  const {
    houseId,
    networkName,
    valves: apiValves,
    gateways: apiGateways,
    rooms: apiRooms,
    plans: apiPlans,
    children,
  } = props;

  const valves = apiValvesToValvesMapper(apiValves, apiGateways);
  const gateways = apiGatewaysToGatewaysMapper(apiGateways);
  const rooms = apiRoomsToRoomsMapper(apiRooms);
  const plans = apiPlansToPlansMapper(apiPlans);

  const [open, setOpen] = useState(false);

  const {
    register,
    setFocus,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
  } = useForm<Record<string, number>>({
    resolver: zodResolver(
      z.record(
        z.string(),
        z.preprocess(args => (args === "" ? undefined : args), z.coerce.number().min(50).max(100).optional()),
      ),
    ),
    defaultValues: valves.reduce((acc, valve) => ({ ...acc, [valve.id]: valve.maxOpening?.value ?? 100 }), {}),
  });

  const { revalidate } = useRevalidator();

  const { mutate } = useMutation({
    mutationFn: (body: UpdateValveRequestBody) => updateValves(houseId, body),
    onSuccess: () => {
      handleOpenChange(false);
      revalidate();
    },
    onError: () => toast.error(t("error.unKnowError.message")),
  });

  const firstPlanIdSortedByName = plans.sort((a, b) => a.name.localeCompare(b.name))[0].id;
  const defaultPlanId = valves.find(v => v.plan.id === firstPlanIdSortedByName)?.plan.id ?? plans[0].id;

  const [sortBy, setSortBy] = useState<SortField>(SortField.hslope);
  const [groupedBy, setGroupedBy] = useState<GroupField>(GroupField.plan);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.asc);
  const [selectedValves, setSelectedValves] = useState<string[]>([]);
  const [planId, setPlanId] = useState<string>(defaultPlanId);
  const [data, setData] = useState<Data>(Data.hslope);
  const [itemsOnPlan, setItemsOnPlan] = useState<Item[]>([Item.valves]);
  const [selectedItemId, setSelectedItemId] = useState<string>();
  const [selectedValveId, setSelectedValveId] = useState<string>();

  const handleChangeGroup = (group: GroupField) => setGroupedBy(group);
  const handleSortByChange = (sort: SortField) => setSortBy(sort);
  const handleSortOrderChange = (sort: SortOrder) => setSortOrder(sort);
  const handleChangePlan = (plan: string | string[]) => setPlanId(plan as string);
  const handleChangeData = (data: string | string[]) => setData(data as Data);
  const handleClickOnPoint = (ids: string[]) => {
    if (selectedValves.length === ids.length && selectedValves.every(id => ids.includes(id))) {
      setSelectedValves([]);
    } else {
      setSelectedValves(ids);
    }
  };
  const handleClickOnValve = (id: string) => {
    setSelectedItemId(id);
    setFocus(id);
  };
  const handleClickOnItem = (valveId?: string) => {
    if (valveId) {
      const selectedItemPlanId = valves.find(v => v.id === valveId)?.plan.id;
      if (selectedItemPlanId && selectedItemPlanId !== planId) setPlanId(selectedItemPlanId);
    }
    setSelectedValveId(valveId);
  };

  const handleOpenChange = (open: boolean) => {
    reset(valves.reduce((acc, valve) => ({ ...acc, [valve.id]: valve.maxOpening?.value ?? 100 }), {}));
    setOpen(open);
  };

  const onSubmit = (data: Record<string, number>) => {
    mutate({ valves: [...Object.entries(data).map(([key, value]) => ({ id: key, maxOpening: { value } }))] });
  };

  const valvesInList = getDisplayedValves({
    valves,
    plans,
    rooms,
    sortBy,
    sortOrder,
    groupedBy,
    selectedValves,
  });
  const valvesOnPlan = Object.values(valvesInList).flat();
  const displayValves = itemsOnPlan.includes(Item.valves);

  const roomsOnPlan = rooms.filter(room => valvesOnPlan.some(valve => valve.room === room.id));
  const displayRooms = itemsOnPlan.includes(Item.rooms);

  const gatewaysOnPlan = gateways.filter(gateway => valvesOnPlan.some(valve => valve.gateway === gateway.id));
  const displayGateways = itemsOnPlan.includes(Item.gateways);

  const average = getHslopeAverage(valves);

  return (
    <Modal.Root open={open} onOpenChange={handleOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Content className={styles.modal}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            className={styles.header}
            icon={<LocationIcon />}
            title={t("manualBalancing.modal.title", { n: networkName })}
            subtitle={t("manualBalancing.modal.subtitle")}
          >
            <Modal.Close>
              <ActionButton variant="cancel">{t("manualBalancing.modal.cancel")}</ActionButton>
            </Modal.Close>
            <ActionButton type="submit" disabled={!isDirty}>
              {t("manualBalancing.modal.save")} <ArrowRightIcon />
            </ActionButton>
          </Modal.Header>
          <div className={styles.distribution}>
            <Distribution valves={valves} selectedPoints={selectedValves} onClickOnPoint={handleClickOnPoint} />
            <Tooltip content={t("manualBalancing.modal.average.tooltip")}>
              <p className={styles.distribution__average}>
                <span>{t("manualBalancing.modal.average.label")}</span> {average} min/°C
              </p>
            </Tooltip>
          </div>
          <div className={styles.content}>
            <div className={styles.content__left}>
              <Display
                groupedBy={groupedBy}
                onGroupChange={handleChangeGroup}
                sortBy={sortBy}
                onSortByChange={handleSortByChange}
                sortOrder={sortOrder}
                onSortOrderChange={handleSortOrderChange}
              />
              <List
                groupedValves={valvesInList}
                register={register}
                errors={errors}
                selectedItemId={selectedItemId}
                onItemClick={handleClickOnItem}
                itemSelectionCallback={() => setSelectedItemId(undefined)}
              />
            </div>
            <Divider orientation="vertical" />
            <div className={styles.content__right}>
              <div className={styles.filters}>
                <FieldContainer label={t("manualBalancing.modal.plan")}>
                  <Select
                    classNames={{ trigger: cx(styles.filters__select, styles.filters__select_plan) }}
                    options={plans.map(p => ({ value: p.id, name: p.name }))}
                    value={planId}
                    onChange={handleChangePlan}
                  />
                </FieldContainer>
                <FieldContainer label={t("manualBalancing.modal.data.label")}>
                  <Select
                    classNames={{ trigger: cx(styles.filters__select, styles.filters__select_data) }}
                    options={$enum(Data)
                      .getValues()
                      .map(v => ({ value: v, name: t(`manualBalancing.modal.data.${v}`) }))}
                    value={data}
                    onChange={handleChangeData}
                  />
                </FieldContainer>
                <FieldContainer label={t("manualBalancing.modal.objects.label")}>
                  <Select
                    classNames={{ trigger: cx(styles.filters__select, styles.filters__select_objects) }}
                    placeholder={t("manualBalancing.modal.objects.placeholder")}
                    value={itemsOnPlan}
                    onChange={value => setItemsOnPlan(value as Item[])}
                    options={$enum(Item)
                      .getValues()
                      .map(v => ({
                        value: v,
                        name: t(`manualBalancing.modal.objects.${v}`),
                      }))}
                  />
                </FieldContainer>
              </div>
              <Plan
                houseId={houseId}
                id={planId}
                valves={valvesOnPlan}
                displayValves={displayValves}
                onClickOnValve={handleClickOnValve}
                rooms={roomsOnPlan}
                displayRooms={displayRooms}
                gateways={gatewaysOnPlan}
                displayGateways={displayGateways}
                data={data}
                selectedValveId={selectedValveId}
                itemSelectionCallback={() => handleClickOnItem(undefined)}
              />
            </div>
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};

ManualBalancingModal.displayName = NAME;

export { ManualBalancingModal };
export type { ManualBalancingModalProps };
