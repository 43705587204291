import { useRef } from "react";

import type { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

import type { Box, Point } from "../utils";
import { calculateBoxArea, calculateSelectionBox } from "../utils";

interface UseClickOnPlanProps {
  onClickOnPlan?: (point: Point, e?: TouchEvent | MouseEvent) => void;
}

interface UseClickOnPlanType {
  handleMouseDown: (ref: ReactZoomPanPinchRef, event: TouchEvent | MouseEvent) => void;
  handleMouseUp: (ref: ReactZoomPanPinchRef, event: TouchEvent | MouseEvent) => void;
}

const useClickOnPlan = (props: UseClickOnPlanProps): UseClickOnPlanType => {
  const { onClickOnPlan } = props;

  const startPoint = useRef<Point | null>(null);
  const clientStartPoint = useRef<Point | null>(null);
  const endPoint = useRef<Point | null>(null);
  const clientEndPoint = useRef<Point | null>(null);

  const getClientPointFromEvent = (e: TouchEvent | MouseEvent): Point => {
    let x = 0;
    let y = 0;
    if (e instanceof MouseEvent) {
      x = e.clientX;
      y = e.clientY;
    } else if (e instanceof TouchEvent && e.touches.length > 0) {
      x = e.touches[0]?.clientX ?? 0;
      y = e.touches[0]?.clientY ?? 0;
    }
    return { x, y };
  };

  const getPointFromEvent = (ref: ReactZoomPanPinchRef, e: TouchEvent | MouseEvent): Point => {
    const rect = ref.instance.contentComponent?.getBoundingClientRect();

    const { x, y } = getClientPointFromEvent(e);

    return {
      x: x - (typeof rect?.left === "number" ? rect.left : 0),
      y: y - (typeof rect?.top === "number" ? rect.top : 0),
    };
  };

  const cancelCurrentClick = () => {
    startPoint.current = null;
    endPoint.current = null;
  };

  const handleMouseUp = (ref: ReactZoomPanPinchRef, e: TouchEvent | MouseEvent) => {
    endPoint.current = getPointFromEvent(ref, e);
    clientEndPoint.current = getClientPointFromEvent(e);
    if (startPoint.current && clientStartPoint.current) {
      const rect = ref.instance.contentComponent?.getBoundingClientRect();
      const box = calculateSelectionBox({ startPoint: clientStartPoint.current, endPoint: clientEndPoint.current });
      // 10 is the minimum area of the box to consider it as a click
      if (isMaximumBoxArea(box) && rect) {
        const x = Math.round(Math.min(100, Math.max(0, (endPoint.current.x / rect.width) * 100)));
        const y = Math.round(Math.min(100, Math.max(0, (endPoint.current.y / rect.height) * 100)));
        if (!e.defaultPrevented) {
          onClickOnPlan?.({ x, y }, e);
        }
      }
      cancelCurrentClick();
    }
  };

  const handleMouseDown = (ref: ReactZoomPanPinchRef, e: TouchEvent | MouseEvent) => {
    startPoint.current = getPointFromEvent(ref, e);
    clientStartPoint.current = getClientPointFromEvent(e);
  };

  return {
    handleMouseDown,
    handleMouseUp,
  };
};

const isMaximumBoxArea = (box: Box): boolean => calculateBoxArea(box) < 10;

export { useClickOnPlan };
