import type { RouteObject } from "react-router-dom";

import { route as expiredTokenRoute } from "./expiredToken/route";
import { loader } from "./loader";
import { route as usedTokenRoute } from "./usedToken/route";

const idInvalidTokenLoader = "idInvalidTokenLoader";

const route: RouteObject = {
  id: idInvalidTokenLoader,
  loader,
  children: [{ ...expiredTokenRoute }, { ...usedTokenRoute }],
};

export { route, idInvalidTokenLoader };
