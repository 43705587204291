import { useCallback, useState } from "react";

type ContentType = "text" | "image" | null;

interface ClipboardOptions {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

interface ClipboardResult {
  copyToClipboard: (content: string | HTMLImageElement) => Promise<void>;
  error: string | null;
  success: boolean;
}

/**
 * Detects the content type of the given content.
 *
 * @param content - The content to detect the type of.
 * @returns The detected content type.
 */
const detectContentType = (content: string | HTMLImageElement): ContentType => {
  if (typeof content === "string") {
    if (content.startsWith("data:image/")) {
      return "image";
    }
    return "text";
  } else if (content instanceof HTMLImageElement) {
    return "image";
  }
  return null;
};

/**
 * Checks if the browser has permission to write to the clipboard.
 * @returns A promise that resolves to a boolean indicating whether the permission is granted or not.
 */
const checkClipboardPermission = async (): Promise<boolean> => {
  try {
    const permissionStatus = await navigator.permissions.query({ name: "clipboard-write" as PermissionName });
    return permissionStatus.state === "granted" || permissionStatus.state === "prompt";
  } catch (error) {
    return false;
  }
};

const useClipboard = ({ onSuccess, onError }: ClipboardOptions = {}): ClipboardResult => {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const copyToClipboard = useCallback(
    async (content: string | HTMLImageElement) => {
      setError(null);
      setSuccess(false);

      // CHECK PERMISSION
      const hasPermission = await checkClipboardPermission();
      if (!hasPermission) {
        const errorMessage = "Permission denied: Unable to access the clipboard";
        setError(errorMessage);
        return onError && onError(errorMessage);
      }

      // DETECT CONTENT TYPE
      const contentType = detectContentType(content);
      if (!contentType) {
        const errorMessage = "Unsupported content type";
        setError(errorMessage);
        return onError && onError(errorMessage);
      }

      // COPY TO CLIPBOARD
      try {
        if (contentType === "text") await navigator.clipboard.writeText(content as string);
        else if (contentType === "image") {
          const url = typeof content === "string" ? content : content.src;
          const blob = await (await fetch(url)).blob();
          const item = new ClipboardItem({ [blob.type]: blob });
          await navigator.clipboard.write([item]);
        }
        setSuccess(true);
        onSuccess && onSuccess();
      } catch (err) {
        const errorMessage = `Copy failed: ${err instanceof DOMException ? err.message : err}`;
        setError(errorMessage);
        onError && onError(errorMessage);
      }
    },
    [onError, onSuccess],
  );

  return { copyToClipboard, error, success };
};

export default useClipboard;
