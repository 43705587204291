import { VALVE_ERRORS_ENUM, VALVE_WARNINGS_ENUM } from "@eisox/problems-handler";

import type { ValvesWithProblem } from "~/UI/screens/House";

const getNetworkValves = (valves: ValvesWithProblem[], networkId: string): ValvesWithProblem[] =>
  valves.filter(v => v.heatingNetworkId === networkId);

const getConnectedValves = (valves: ValvesWithProblem[]): ValvesWithProblem[] =>
  valves.filter(
    v =>
      !v.errors.includes(VALVE_ERRORS_ENUM.DISCONNECTED) &&
      !v.warnings.includes(VALVE_WARNINGS_ENUM.DISCONNECTED_WARNING),
  );

const closingDefinedValve = (valve: ValvesWithProblem): valve is ValvesWithProblem & { closing: number } =>
  valve.closing !== undefined;

const getValvesWithClosing = (valves: ValvesWithProblem[]): (ValvesWithProblem & { closing: number })[] =>
  valves.filter(closingDefinedValve);

const getNetworkDemand = (valves: ValvesWithProblem[], networkId: string): number | undefined => {
  const networkValves = getNetworkValves(valves, networkId);
  const connectedValves = getConnectedValves(networkValves);
  const valvesWithClosing = getValvesWithClosing(connectedValves);

  const openningAverage =
    valvesWithClosing.filter(v => v.closing).reduce((acc, cur) => (acc += 100 - cur.closing), 0) /
    valvesWithClosing.length;

  return Number.isNaN(openningAverage) ? undefined : Math.round(openningAverage);
};

/**
 * Number of valves with opening > 0% and connected
 * @param valves valves
 * @param networkId heating network id
 */
const getValvesDemand = (valves: ValvesWithProblem[], networkId: string) => {
  const networkValves = getNetworkValves(valves, networkId);
  const connectedValves = getConnectedValves(networkValves);
  const valvesWithClosing = getValvesWithClosing(connectedValves);

  return valvesWithClosing.filter(v => 100 - v.closing > 0).length;
};

export { getNetworkDemand, getValvesDemand };
