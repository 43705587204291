import * as React from "react";

import { IconProps } from "./types";

export const GaugeIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M8 9.33334L10.6667 6.66667"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.22667 12.6667C1.64148 11.6532 1.33338 10.5036 1.33333 9.33329C1.33329 8.16301 1.6413 7.01335 2.22641 5.99985C2.81152 4.98635 3.65311 4.14472 4.66659 3.55957C5.68007 2.97442 6.82973 2.66637 8 2.66637C9.17027 2.66637 10.3199 2.97442 11.3334 3.55957C12.3469 4.14472 13.1885 4.98635 13.7736 5.99985C14.3587 7.01335 14.6667 8.16301 14.6667 9.33329C14.6666 10.5036 14.3585 11.6532 13.7733 12.6667"
        stroke={color}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
);

export default GaugeIcon;
