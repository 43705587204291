import type { ConfigType, ManipulateType } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";

const isInTheFutureOfTheDay = (hours: number, selectedDay: number) => {
  const today = new Date();
  const dateFuture = new Date();
  dateFuture.setHours(hours, 0, 0, 0);
  const todayDay = today.getDay();
  return todayDay === selectedDay && dateFuture > today;
};

const isInTheFutureOfTheDay2 = (hours: number, selectedDay: number) => {
  const today = new Date();
  const dateFuture = new Date();
  dateFuture.setHours(hours, 0, 0, 0);
  const todayDay = today.getDay();
  return todayDay === selectedDay && dateFuture < today;
};

export const getPreviousDay = (dayNumber?: number, hours?: number) => {
  const currentDateObj = new Date();
  if (dayNumber && hours) {
    if (isInTheFutureOfTheDay(hours, dayNumber)) {
      currentDateObj.setDate(currentDateObj.getDate() - ((currentDateObj.getDay() + (7 - dayNumber)) % 7) - 6);
    } else {
      currentDateObj.setDate(currentDateObj.getDate() - ((currentDateObj.getDay() + (7 - dayNumber)) % 7));
    }
    return currentDateObj;
  }
  return undefined;
};

export const getNextDay = (dayNumber?: number, hours?: number) => {
  const now = new Date();
  if (dayNumber && hours) {
    if (isInTheFutureOfTheDay2(hours, dayNumber)) {
      now.setDate(now.getDate() + ((dayNumber + (7 - now.getDay())) % 7) + 6);
    }
    now.setDate(now.getDate() + ((dayNumber + (7 - now.getDay())) % 7));
    return now;
  }
  return undefined;
};

/**
 * It takes a date format and an unformated date and returns a formated date
 * @param formatDate - The format you want to return the date in.
 * YYYY - 2022 / YY - 22 /
 * MMMM - November / MMM - Nov / MM - 11
 * D - 4 / DD - 04
 * d - 5 / dd - Fr / ddd - Fri / dddd - Friday
 * H - 1 / HH - 15
 * h - 3 / hh - 03
 * mm - 20 / ma- 21pm
 * ss - 15
 * more in https://day.js.org/docs/en/display/format
 * @param unformatedDate - The date you want to format.
 * @returns the current date in the format of the formatDate parameter.
 */
export function formatedDate(formatDate: string, unformatedDate?: ConfigType): string {
  return dayjs(unformatedDate).format(formatDate);
}

/**
 * Checks if a given date is expired.
 * @param date - The date to check.
 * @param currentDate - The current date. If not provided, the current system date will be used.
 * @returns A boolean indicating whether the date is expired or not.
 */
export function isExpiredDate(date: ConfigType, currentDate?: ConfigType): boolean {
  return dayjs(date).isBefore(currentDate);
}

/**
 * Adds a specified amount of time to a given date.
 * @param unit - The unit of time to add (e.g. 'year', 'month', 'day', etc.).
 * @param number - The number of units to add.
 * @param date - Optional. The date to which the time should be added. If not provided, the current date and time will be used.
 * @returns The new date and time after adding the specified amount of time.
 */
export const addTime = (number: number, unit: ManipulateType, date?: ConfigType) => {
  return dayjs(date).add(number, unit);
};

export const isOfflineOnPlan = (date?: string, dateToCompare?: ConfigType): boolean => {
  return date ? dayjs(dateToCompare).diff(date, "hour") >= 1 : true;
};
