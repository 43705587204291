import { useState } from "react";

import { useIntl } from "react-intl";
import { useNavigate, useParams, useRevalidator, useRouteLoaderData } from "react-router-dom";

import { Radio, RoundIcon, Select } from "@eisox/design-system";
import { DataIcon, EyeIcon, UpdateIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { houseLoader } from "~/UI/screens";
import { FORMAT_DATE } from "~/constants/timeConstants";
import { usePermissionsContext } from "~/providers";
import { usePlansContext } from "~/providers/Plans";
import { idLoaderHouse, routeToPlan } from "~/routes/utils";
import { DataType, DisplaySection } from "~/stores";
import type { Permission } from "~/utils";

import styles from "./RightColumn.module.scss";

export const RightColumn: React.FC = () => {
  const { formatMessage } = useIntl();

  const bem = useBem(styles);
  const rightColumnStyle = bem("rightColumn");
  const dataContainerStyle = bem("dataContainer");
  const listStyle = bem("list");
  const dateStyle = bem("date");

  const { permissions } = usePermissionsContext("RightColumn");
  const { selectedDataSelector, selectedDisplaySelector, handleChangeDataSelector, handleChangeDisplaySelector } =
    usePlansContext("RightColumn");

  const { plans, date, boilerroomPos } = useRouteLoaderData(idLoaderHouse) as LoaderData<
    ReturnType<typeof houseLoader>
  >;
  const { houseId, planId } = useParams();
  const navigate = useNavigate();
  const { revalidate } = useRevalidator();

  const [isListDataSelectorOpen, setIsListDataSelectorOpen] = useState<boolean>(true);

  const [isListDisplayOpen, setIsListDisplayOpen] = useState<boolean>(true);

  function handleListSelectors(permissions: Permission): DataType[] {
    const list = [
      {
        name: DataType.TEMPERATURE,
        auth: true,
      },
      {
        name: DataType.NAME,
        auth: true,
      },
      {
        name: DataType.HUMIDITY,
        auth: permissions.valve?.sensors?.humidity?.read,
      },
      {
        name: DataType.AIR_QUALITY,
        auth: permissions.valve?.sensors?.indoorAirQuality?.read,
      },
      {
        name: DataType.LIGHT,
        auth: permissions.valve?.sensors?.light?.read,
      },
    ];
    return list.reduce<DataType[]>((acc, item) => (item.auth ? [...acc, item.name] : acc), []);
  }

  function handleListDisplay(permissions: Permission): DisplaySection[] {
    const list = [
      {
        name: DisplaySection.VALVES,
        auth: permissions.valve?.read,
      },
      {
        name: DisplaySection.GATEWAYS,
        auth: permissions.gateway?.read,
      },
      {
        name: DisplaySection.ROOMS,
        auth: true,
      },
      {
        name: DisplaySection.BOILERS,
        auth: permissions.module?.boilerroom?.read && boilerroomPos.length > 0,
      },
      {
        name: DisplaySection.HISTORY,
        auth: permissions.history?.valve.histoValve?.read,
      },
    ];

    return list.reduce<DisplaySection[]>((acc, item) => (item.auth ? [...acc, item.name] : acc), []);
  }

  function toggleListData() {
    setIsListDataSelectorOpen(!isListDataSelectorOpen);
  }

  function toggleListDisplay() {
    setIsListDisplayOpen(!isListDisplayOpen);
  }

  const listSelectorsData = handleListSelectors(permissions);
  const listSelectorsDisplay = handleListDisplay(permissions);

  return (
    <div className={rightColumnStyle()}>
      <div className={rightColumnStyle("content")}>
        {plans.length > 0 && (
          <Select
            multiple={false}
            options={plans.map(pl => ({ value: pl.id, name: pl.name || "-" }))}
            value={planId}
            renderValue={(value?: string) => <p>{plans.find(p => p.id === value)?.name ?? "--"}</p>}
            onChange={v => navigate(routeToPlan(houseId!, v))}
          />
        )}

        <div className={dataContainerStyle()}>
          <div onClick={toggleListData} className={dataContainerStyle("titleSelector")}>
            <div>
              <DataIcon />
              <p>{formatMessage({ id: "plan.data" })}</p>
            </div>
            <span>{isListDataSelectorOpen ? "-" : "+"}</span>
          </div>

          {isListDataSelectorOpen ? (
            <div className={listStyle()}>
              {listSelectorsData.map((s, i) => {
                const isCheckedCondition = selectedDataSelector === s;
                return (
                  <div key={i} onClick={() => handleChangeDataSelector(s)} className={listStyle("itemContainer")}>
                    <Radio checked={isCheckedCondition} />
                    <p className={listStyle("itemContainer", { selected: isCheckedCondition })}>
                      {formatMessage({ id: `plan.${s}` })}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>

        <div className={dataContainerStyle()}>
          <div onClick={toggleListDisplay} className={dataContainerStyle("titleSelector")}>
            <div>
              <EyeIcon style={{ width: 18, height: 18 }} />
              <p>{formatMessage({ id: "plan.display" })}</p>
            </div>
            <span>{isListDisplayOpen ? "-" : "+"}</span>
          </div>

          {isListDisplayOpen ? (
            <div className={listStyle()}>
              {listSelectorsDisplay.map((s, i) => {
                const isCheckedCondition = selectedDisplaySelector.includes(s);
                return (
                  <div key={i} onClick={() => handleChangeDisplaySelector(s)} className={listStyle("itemContainer")}>
                    <Radio checked={isCheckedCondition} multiple />
                    <p className={listStyle("itemContainer", { selected: isCheckedCondition })}>
                      {formatMessage({ id: `plan.${s}` })}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>

      {!selectedDisplaySelector.includes(DisplaySection.HISTORY) && (
        <div className={dateStyle()}>
          <p>{date.format(FORMAT_DATE.planData)}</p>
          <RoundIcon
            size={30}
            onClick={revalidate}
            backgroundColor="gray"
            iconColor={"darkGray"}
            style={{ cursor: "pointer" }}
          >
            <UpdateIcon />
          </RoundIcon>
        </div>
      )}
    </div>
  );
};
