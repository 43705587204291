import { useState } from "react";

import i18next from "i18next";

import { PasswordRules, passwordSchema } from "~/utils";

type PasswordErrors = {
  [key in PasswordRules]?: { isValid: boolean; translation: string };
};

const usePasswordConditions = () => {
  const initialPasswordErrors = Object.values(PasswordRules).reduce<PasswordErrors>(
    (acc, rule) => ({
      ...acc,
      [rule]: { isValid: false, translation: i18next.t(`myAccount.password.rules.${rule}`) },
    }),
    {},
  );

  const [passwordErrors, setPasswordErrors] = useState<PasswordErrors>(initialPasswordErrors);

  const validatePassword = (password: string) => {
    const result = passwordSchema.safeParse(password);

    setPasswordErrors(prevErrors =>
      Object.fromEntries(
        Object.keys(initialPasswordErrors).map(key => [
          key,
          {
            ...prevErrors[key as PasswordRules],
            isValid: result.success ? true : !result.error.errors.some(err => err.message === key),
          },
        ]),
      ),
    );
  };

  return { validatePassword, passwordErrors };
};

export { usePasswordConditions };
export type { PasswordErrors };
