import type { HTMLAttributes } from "react";
import { forwardRef, useEffect, useMemo, useState } from "react";

import { cx } from "class-variance-authority";
import clsx from "clsx";

import { CrossIcon } from "@eisox/icons";
import { createContext, useBem } from "@eisox/tools";
import * as RadixAlertDialog from "@radix-ui/react-alert-dialog";

import { Circle } from "../Circle";
import { TextField } from "../TextField";

import styles from "./styles.module.scss";

const generateRandomCaptcha = (length: number): string => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join("");
};

/* -------------------------------------------------------------------------------------------------
 * Context
 * -----------------------------------------------------------------------------------------------*/
const AlertDialogContext = "AlertDialogContext";
interface AlertDialogContextValue {
  isCaptchaValid?: boolean;
  setCaptchaValid: (value: boolean) => void;
}
const [AlertDialogProvider, useRootAlertDialogContext] = createContext<AlertDialogContextValue>(AlertDialogContext, {
  isCaptchaValid: undefined,
  setCaptchaValid: _ => {},
});

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
const ROOT_NAME = "AlertDialog";
type RootProps = RadixAlertDialog.AlertDialogProps;
const Root: React.FC<RootProps> = ({ children, ...props }) => {
  const [isCaptchaValid, setCaptchaValid] = useState<boolean>();
  return (
    <AlertDialogProvider {...{ isCaptchaValid, setCaptchaValid }}>
      <RadixAlertDialog.Root {...props}>{children}</RadixAlertDialog.Root>
    </AlertDialogProvider>
  );
};
Root.displayName = ROOT_NAME;

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/
const TRIGGER_NAME = "AlertDialogTrigger";
type TriggerProps = RadixAlertDialog.AlertDialogTriggerProps;
const Trigger = RadixAlertDialog.Trigger;
Trigger.displayName = TRIGGER_NAME;

/* -------------------------------------------------------------------------------------------------
 * Cancel
 * -----------------------------------------------------------------------------------------------*/
const CANCEL_NAME = "AlertDialogCancel";
type CancelProps = RadixAlertDialog.AlertDialogCancelProps;
const Cancel = RadixAlertDialog.Cancel;
Cancel.displayName = CANCEL_NAME;

/* -------------------------------------------------------------------------------------------------
 * Action
 * -----------------------------------------------------------------------------------------------*/
const ACTION_NAME = "AlertDialogAction";
type ActionProps = RadixAlertDialog.AlertDialogActionProps;
const Action: React.FC<ActionProps> = ({ children, ...props }) => {
  const { isCaptchaValid } = useRootAlertDialogContext(ACTION_NAME);

  return (
    <RadixAlertDialog.Action {...props} disabled={isCaptchaValid === false}>
      {children}
    </RadixAlertDialog.Action>
  );
};
Action.displayName = ACTION_NAME;

/* -------------------------------------------------------------------------------------------------
 * Captcha
 * -----------------------------------------------------------------------------------------------*/
const CAPTCHA_NAME = "AlertDialogCaptcha";
type CommonCaptchaProps = Omit<TextField.TextFieldProps, "value" | "onChange">;

type CaptchaProps = CommonCaptchaProps &
  (
    | { captcha: string; captchaLength?: never }
    | { captchaLength: number; captcha?: never }
    | { captcha?: never; captchaLength?: never }
  );

const Captcha = forwardRef<HTMLDivElement, CaptchaProps>(
  ({ captcha: captchaProp, captchaLength = 4, ...props }, forwardRef) => {
    const bem = useBem(styles);
    const captchaStyle = bem("captcha");

    const { setCaptchaValid } = useRootAlertDialogContext(CAPTCHA_NAME);

    const captcha = useMemo(() => captchaProp ?? generateRandomCaptcha(captchaLength), [captchaLength, captchaProp]);
    const [value, setValue] = useState("");

    useEffect(() => {
      // Define the captcha as invalid when the component is mounted, the default value is undefined, which means that the captcha does not exist
      setCaptchaValid(false);
    }, []);

    return (
      <div ref={forwardRef} className={captchaStyle()}>
        <p className={captchaStyle("code")}>{captcha}</p>
        <TextField.Root
          {...props}
          value={value}
          onChange={e => {
            setCaptchaValid(e.target.value === captcha);
            setValue(e.target.value);
          }}
          className={cx(captchaStyle("input"), props.className)}
        />
      </div>
    );
  },
);
Captcha.displayName = CAPTCHA_NAME;

/* -------------------------------------------------------------------------------------------------
 * Footer
 * -----------------------------------------------------------------------------------------------*/
const FOOTER_NAME = "AlertDialogFooter";
type FooterProps = HTMLAttributes<HTMLDivElement>;
const Footer: React.FC<FooterProps> = ({ className, children, ...props }) => {
  const bem = useBem(styles);
  const footerStyle = bem("footer");

  return (
    <div {...props} className={clsx(footerStyle(), className)}>
      {children}
    </div>
  );
};
Footer.displayName = FOOTER_NAME;

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
const CONTENT_NAME = "AlertDialogContent";
interface ContentProps extends RadixAlertDialog.AlertDialogContentProps {
  title: string;
  icon: React.ReactNode;
}

const Content = forwardRef<HTMLDivElement, ContentProps>(
  ({ title, children, icon, className, ...props }, forwardedRef) => {
    const bem = useBem(styles);
    const overlayStyle = bem("overlay");
    const contentStyle = bem("content");

    return (
      <RadixAlertDialog.Portal>
        <RadixAlertDialog.Overlay className={overlayStyle()} />
        <RadixAlertDialog.Content {...props} ref={forwardedRef} className={clsx(contentStyle(), className)}>
          <RadixAlertDialog.Cancel className={contentStyle("close-icon")}>
            <CrossIcon className={contentStyle("close-icon", { icon: true })} />
          </RadixAlertDialog.Cancel>
          <Circle className={contentStyle("circle")} size={70}>
            {icon}
          </Circle>
          <RadixAlertDialog.Title className={contentStyle("title")}>{title}</RadixAlertDialog.Title>
          {children}
        </RadixAlertDialog.Content>
      </RadixAlertDialog.Portal>
    );
  },
);
Content.displayName = CONTENT_NAME;

export { Root, Trigger, Content, Cancel, Action, Captcha, Footer };
export type { RootProps, TriggerProps, ContentProps, ActionProps, CancelProps, CaptchaProps, FooterProps };
