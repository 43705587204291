/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { StatusCodes } from "http-status-codes";

import { BORDER_ROUTER_STATUS } from "@eisox/gateways";
import { GatewaysMessageGatewaysBrModRf, GatewaysMessageGatewaysStatus } from "@eisox/webapp-api-specification";
import { createQueryKeys } from "@lukemorales/query-key-factory";

import { client } from "../api";
import type { Gateway, GatewayHardwareVersion } from "../types";

const getGateways = async (houseId: string): Promise<Gateway[]> => {
  const { data, error, response } = await client.GET("/houses/{houseId}/gateways", {
    params: { path: { houseId } },
  });

  if ((response.status as StatusCodes) === StatusCodes.NOT_FOUND) return [];
  else if (error || !data?.message?.gateways) throw new Error(error ?? "Error fetching gateways");

  return data.message.gateways.map(g => ({
    ...g,
    id: g.id!,
    uid: g.uid!,
    gatewayName: g.gatewayName!,
    plan: g.plan as Gateway["plan"],
    isWifiEnabled: !!g.isWifiEnabled,
    status: g.status ?? GatewaysMessageGatewaysStatus.idle,
    meshs: (g.meshs as { id: string }[] | undefined) ?? [],
    hardwareVersion: g.hardwareVersion as keyof typeof GatewayHardwareVersion | undefined,
    brModRf: g.brModRf ?? GatewaysMessageGatewaysBrModRf.OQPSK_RC_200,
    isInternetGateway: !!g.isInternetGateway,
    borderRouterStatus: (g.borderRouterStatus as BORDER_ROUTER_STATUS[] | undefined) ?? [BORDER_ROUTER_STATUS.OK],
  }));
};

export const gateway = createQueryKeys("gateway", {
  house: (houseId: string) => ({
    queryKey: [houseId],
    queryFn: () => getGateways(houseId),
  }),
});
