import type { HousesHouseIdBoilerroomsPosGet200ResponseMessageInner } from "@eisox/backend_webapp_api";

const concatBoilerroomNamesFromModule = (module: HousesHouseIdBoilerroomsPosGet200ResponseMessageInner) =>
  module.boilerRooms
    ?.map(br => br.name)
    .filter(name => name !== undefined)
    .sort((a, b) => a.localeCompare(b))
    .join(", ") ?? "";

export { concatBoilerroomNamesFromModule };
