import { startTransition, useMemo, useState } from "react";

import { matchSorter } from "match-sorter";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { Combobox as DSCombobox } from "@eisox/design-system";
import { useIsMobile } from "@eisox/tools";

import { routeToDashboard, routeToMaintenance } from "~/routes/utils";

import type { HousePosition } from "../../helpers";

import styles from "./Combobox.module.scss";

interface ComboboxProps {
  housePositions: HousePosition[];
  onChange: (housePositions: HousePosition[]) => void;
}

export const Combobox: React.FC<ComboboxProps> = ({ housePositions, onChange }) => {
  const { formatMessage } = useIntl();

  const isMobile = useIsMobile();
  const [searchValue, setSearchValue] = useState("");

  const matches = useMemo(() => {
    const result = matchSorter(housePositions, searchValue, { keys: ["houseName"] });
    onChange(result);
    return result;
  }, [housePositions, onChange, searchValue]);

  return (
    <DSCombobox.Root setValue={value => startTransition(() => setSearchValue(value))}>
      <DSCombobox.Input
        className={styles.combobox}
        placeholder={formatMessage({ id: "buildings.placeholder" })}
        autoSelect
      />
      <DSCombobox.Popover className={styles.combobox__popover} sameWidth unmountOnHide>
        {matches.length ? (
          matches.map(({ id, houseName }) => (
            <DSCombobox.Item
              key={id}
              value={houseName}
              render={
                <Link className={styles.combobox__link} to={isMobile ? routeToMaintenance(id) : routeToDashboard(id)} />
              }
            >
              {houseName}
            </DSCombobox.Item>
          ))
        ) : (
          <div>{formatMessage({ id: "buildings.noResult" })}</div>
        )}
      </DSCombobox.Popover>
    </DSCombobox.Root>
  );
};
