import * as React from "react";

import { IconProps } from "./types";

export const SpannerIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M13.9246 3.54934C13.9164 3.53469 13.905 3.52004 13.892 3.50865C13.8106 3.42726 13.6788 3.42726 13.5974 3.50865L10.7928 6.31329L9.68751 5.20804L12.4938 2.40177C12.5068 2.38875 12.5166 2.37572 12.5263 2.36107C12.5849 2.26178 12.5524 2.13482 12.4531 2.07622C10.8546 1.13211 8.76132 1.3486 7.38586 2.72244C6.29526 3.81304 5.93389 5.35942 6.30339 6.74953L1.53405 11.5189C1.48521 11.5677 1.48847 11.6475 1.53893 11.6979L4.30125 14.4603C4.35171 14.5107 4.43309 14.514 4.4803 14.4651L9.24802 9.69742C10.6398 10.0685 12.1861 9.70881 13.2784 8.61658C14.6522 7.24112 14.8687 5.14781 13.9246 3.54934ZM12.4498 7.78642C11.5041 8.73215 10.0847 8.91446 8.95665 8.33335L8.81341 8.47659L8.81178 8.47496L4.35171 12.9367L3.06252 11.6475L6.808 7.90199C6.808 7.90199 6.808 7.90362 6.80963 7.90362L7.66909 7.04416C7.08798 5.91612 7.27029 4.4967 8.21602 3.55097C8.52103 3.24568 8.88836 3.00983 9.29289 2.85953C9.69743 2.70924 10.1296 2.64804 10.56 2.68012L8.3967 4.84179C8.29899 4.93961 8.2441 5.07222 8.2441 5.21048C8.2441 5.34874 8.29899 5.48135 8.3967 5.57917L10.4233 7.60574C10.5211 7.70345 10.6537 7.75833 10.792 7.75833C10.9302 7.75833 11.0628 7.70345 11.1606 7.60574L13.3223 5.44406C13.3825 6.28725 13.0879 7.14996 12.4498 7.78642Z"
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  ),
);

export default SpannerIcon;
