import type { LoaderFunctionArgs } from "react-router-dom";

import { getPlan } from "../../../api/plan";
import { WHITE_PLAN_EISOX } from "../../../constants";
import { SUCCESS_FETCH } from "../../../constants/fetchConstants";

export const loader = async ({ params }: LoaderFunctionArgs) => {
  const { houseId, planId } = params;

  // plan image
  const planResponse = await getPlan(planId, houseId);
  const planImage = planResponse.type === SUCCESS_FETCH ? planResponse.result.message : WHITE_PLAN_EISOX;

  return { planImage };
};
