import { client } from "../api";

const removeRoom = async (houseId: string, roomId: string) => {
  await client.DELETE("/houses/{houseId}/rooms/{roomId}", {
    params: { path: { houseId, roomId } },
  });
  return;
};

export { removeRoom };
