import { cx } from "class-variance-authority";

import { RoundIcon } from "@eisox/design-system";
import { UpdateIcon } from "@eisox/icons";

import styles from "./Refresh.module.scss";

interface RefreshProps extends React.ComponentProps<"div"> {
  updatedAt: string;
}

const Refresh: React.FC<RefreshProps> = ({ updatedAt, className, ...props }) => {
  return (
    <div {...props} className={cx(styles.refresh, className)}>
      {updatedAt}
      <RoundIcon size={25} backgroundColor="white" iconColor="darkerGray">
        <UpdateIcon />
      </RoundIcon>
    </div>
  );
};

Refresh.displayName = "Maintenance.Refresh";

export { Refresh };
export type { RefreshProps };
