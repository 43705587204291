import { useState } from "react";

import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";

import { ButtonV2, Sidebar as DSSidebar, Dialog, Dropdown } from "@eisox/design-system";
import { RefreshIcon } from "@eisox/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { News } from "~/api/news";
import { queries, updateUser } from "~/apiV2";
import { env } from "~/configuration";
import { MODE_ENV } from "~/constants/appConstantV2";
import { routeToSignIn } from "~/routes/utils";
import { useUserStore } from "~/stores";

import { MyAccount } from "../MyAccount";
import { NewsChip } from "../NewsChip";
import { NewsPopup } from "../NewsPopup";

import styles from "./Sidebar.module.scss";

export interface SidebarFooterProps {
  firstName?: string;
  lastName?: string;
  company?: string;
  phone?: {
    number?: string;
    verified?: boolean;
  };
  email: string;
  role?: string;
  news: News[];
  isLoading: boolean;
  lastNewsId?: string;
  canAccessPreprod?: boolean;
}

export const SidebarFooter: React.FC<SidebarFooterProps> = ({
  firstName,
  lastName,
  company,
  phone,
  email,
  role,
  news,
  isLoading,
  lastNewsId,
  canAccessPreprod = false,
}) => {
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const { houseId } = useParams<{ houseId?: string }>();

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: updateUser,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: queries.user.me.queryKey }),
  });

  const { MODE } = env;

  const [myAccountDropdowOpen, setMyAccountDropdowOpen] = useState(false);

  const user = {
    firstName,
    lastName,
    company,
    phone,
    email,
    role,
  };

  const showNewsChip = lastNewsId === undefined || (news.length > 0 && news[0].id !== parseInt(lastNewsId));

  const handleRedirect = () => {
    if (location.pathname) {
      window.location.assign(
        (MODE === MODE_ENV.PREPROD ? env.PROD_WEBAPP_URL : env.PREPROD_WEBAPP_URL) + location.pathname,
      );
    }
  };

  const handleClickNews = () => {
    if (showNewsChip) {
      mutate({ lastNewsId: news[0].id.toString() });
    }
  };

  const handleLogout = () => {
    useUserStore.getState().logout(queryClient);
    navigate(routeToSignIn());
  };

  return (
    <DSSidebar.Footer {...user} version={env.VERSION} chip={showNewsChip && <NewsChip />}>
      <div className={styles.sidebarFooter}>
        {(MODE === MODE_ENV.PREPROD || MODE === MODE_ENV.PROD) && canAccessPreprod && (
          <ButtonV2 onClick={handleRedirect} className={styles.sidebarFooter__button}>
            {MODE.toUpperCase()}
            <RefreshIcon />
          </ButtonV2>
        )}
        <Dialog.Root>
          <Dialog.Trigger asChild onClick={handleClickNews}>
            <div className={styles.sidebarFooter__news}>
              <ButtonV2 className={styles.sidebarFooter__button}>
                {formatMessage({ id: "sidebarFooter.news" })}
              </ButtonV2>
              {showNewsChip && <NewsChip />}
            </div>
          </Dialog.Trigger>
          <NewsPopup news={news} isLoading={isLoading} houseId={houseId} />
        </Dialog.Root>
        <Dropdown.Root open={myAccountDropdowOpen} onOpenChange={setMyAccountDropdowOpen}>
          <Dropdown.Trigger asChild>
            <ButtonV2 className={styles.sidebarFooter__button}>
              {formatMessage({ id: "sidebarFooter.myAcount" })}
            </ButtonV2>
          </Dropdown.Trigger>
          <MyAccount {...user} />
        </Dropdown.Root>
        <ButtonV2 className={styles.sidebarFooter__button} onClick={handleLogout}>
          {formatMessage({ id: "sidebarFooter.logout" })}
        </ButtonV2>
      </div>
    </DSSidebar.Footer>
  );
};
