import { useTranslation } from "react-i18next";

import { ButtonV2 as Button, Dropdown, Switch } from "@eisox/design-system";
import { ChevronDownIcon } from "@eisox/icons";

import styles from "./DisplayDropdown.module.scss";

interface DisplayDropdownProps {
  advancedChecked: boolean;
  onAdvancedCheckedChange: (checked: boolean | null) => void;
}

const DisplayDropdown: React.FC<DisplayDropdownProps> = ({ advancedChecked = false, onAdvancedCheckedChange }) => {
  const { t } = useTranslation();

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Button className={styles.displayDropdown__trigger}>
          {t("maintenance.display.button")}
          <ChevronDownIcon />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content className={styles.displayDropdown} align="end">
        <div className={styles.displayDropdown__section}>
          <h3>{t("maintenance.display.display.title")}</h3>
          <label className={styles.displayDropdown__label}>
            {t("maintenance.display.display.advanced")}
            <Switch checked={advancedChecked} onCheckedChange={onAdvancedCheckedChange} />
          </label>
        </div>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export { DisplayDropdown };
export type { DisplayDropdownProps };
