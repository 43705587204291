export type KeyFunctionToCompare<T> = { key: keyof T; function?: (v: T, filterValue: string) => boolean };

/**
 * Filters an array of objects based on a search filter string and an array of keys and functions to compare.
 * @template T - The type of the objects in the array.
 * @param {T[]} array - The array of objects to filter.
 * @param {string} filter - The search filter string.
 * @param {Array<KeyFunctionToCompare<T>>} keysFunctionToCompare - The array of keys and functions to compare.
 * @returns {T[]} - The filtered array of objects.
 */
export const filterArrayObj = <T extends Object>(
  array: T[],
  filter: string,
  keysFunctionToCompare: Array<KeyFunctionToCompare<T>>,
) => {
  const filterValue = filter.toUpperCase();
  const filterByUid = keysFunctionToCompare.map(({ key }) => key).includes("uid" as keyof T);
  return array.filter(el => {
    if (el.hasOwnProperty("uid") && filterByUid && /^\d{1,4}$/.test(filterValue))
      //@ts-ignore
      return el.uid === parseInt(filterValue);
    return keysFunctionToCompare.some(({ key, function: functionToCompare }) => {
      const value = el[key];
      if (functionToCompare) return functionToCompare(el, filterValue);
      else if (typeof value === "string") return value.toUpperCase().includes(filterValue);
      else if (typeof value === "number") return value.toString().includes(filterValue);
      else if (Array.isArray(value)) return value.some(v => v.toString().toUpperCase().includes(filterValue));
      return false;
    });
  });
};
