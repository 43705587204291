import { View } from "~/UI/screens/Consumption/types";
import { env } from "~/configuration";
import { POST_METHOD } from "~/constants/fetchConstants";
import { internalFetchByUrl } from "~/helpers/communication/FetchApi";
import type { FetchResponse } from "~/helpers/communication/fetchType";
import { version } from "~/routesStatic/routesExpress";
import type { BoilerRoom } from "~/socketio/types";
import { useUserStore } from "~/stores";

const ServerUrl = env.SERVER_URL;

const getPathByView = (view: View) => {
  switch (view) {
    case View.DAY:
      return "daily";
    case View.MONTH:
    case View.YEAR:
      return "monthly";
    default:
      return "";
  }
};

export interface BoilerRoomDailyConsumption {
  ranges: { startDate: string; endDate: string }[];
  fields?: string[];
  moduleIds: string[];
  boilerRooms?: Record<string, any>;
}

export interface BoilerRoomConsumptionType {
  message: {
    moduleIds?: string[];
    boilerRooms?: (BoilerRoom & { createdAt: string })[];
    createdAt?: string;
    updatedAt?: string;
  }[];
}

export const getBoilerRoomConsumption = async (
  houseId: string,
  view: View,
  body: BoilerRoomDailyConsumption,
): Promise<FetchResponse<BoilerRoomConsumptionType>> => {
  const customHeaders = {
    "X-User-Id": useUserStore.getState().id,
    "X-Auth-Token": useUserStore.getState().token,
  };
  const routeBoilerRoomConsumption = ServerUrl + version + `history/houses/${houseId}/gtb/conso/${getPathByView(view)}`;
  return internalFetchByUrl(routeBoilerRoomConsumption, POST_METHOD, customHeaders, body);
};
