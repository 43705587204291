import { useCallback, useEffect, useRef, useState } from "react";

import { orderBy } from "lodash";
import { useIntl } from "react-intl";
import { useRouteLoaderData } from "react-router-dom";
import { toast } from "react-toastify";

import { PostModuleTypeEnum } from "@eisox/backend_webapp_api";
import type { Option } from "@eisox/design-system";
import { Button, RoundIcon, SearchInput, SelectV2 as Select, Switch, Typography } from "@eisox/design-system";
import { CopyIcon, UpdateIcon } from "@eisox/icons";
import { useBem } from "@eisox/tools";

import type { loader as houseLoader } from "~/UI/screens/House";
import useClipboard from "~/hooks/useClipboard";
import { idLoaderHouse } from "~/routes/utils";

import { concatBoilerroomNamesFromModule } from "../../helpers";
import type { DetailsVariablesData } from "../../helpers";
import { useBoilerRoomRealTimeProviderContext } from "../../providers";

import styles from "./VariableDetails.module.scss";

const NAME = "DetailsVariables";

const VariableDetails: React.FC = () => {
  const { formatMessage } = useIntl();
  const gridRef = useRef<HTMLDivElement>(null);

  const bem = useBem(styles);
  const contentStyle = bem("content");
  const controlsStyle = bem("controls");
  const variablesTableStyle = bem("variables-table");

  const { useGetRawDataV2 } = useBoilerRoomRealTimeProviderContext(NAME);
  const { boilerroomPos, modules } = useRouteLoaderData(idLoaderHouse) as LoaderData<ReturnType<typeof houseLoader>>;
  const { copyToClipboard } = useClipboard({
    onSuccess: () => toast.success(formatMessage({ id: "boilerRoom.header.actions.variablesDetails.copy.onSuccess" })),
    onError: (error: string) =>
      toast.error(formatMessage({ id: "boilerRoom.header.actions.variablesDetails.copy.onError" }, { e: error })),
  });

  const boilerRoomModules = modules.filter(m =>
    [PostModuleTypeEnum.EclypseBoilerRoom as string, PostModuleTypeEnum.NiagaraBoilerRoom as string].includes(m.type!),
  );
  const boilerRoomModuleIds = boilerRoomModules.map(b => b.id!);
  const boilerRooms = boilerroomPos.filter(bp => boilerRoomModuleIds.includes(bp.moduleId!));

  const [date, setDate] = useState<string>();
  const [currentModule, setCurrentModule] = useState(boilerRooms[0]);
  const [orderedByName, setOrderedByName] = useState(true);
  const [search, setSearch] = useState<string>("");
  const [numberCols, setNumberCols] = useState(1);

  const { data, isLoading, refetch } = useGetRawDataV2({
    moduleId: currentModule._id,
    enabled: !!currentModule._id,
  });

  const currentData = data?.find(d => d.moduleId === currentModule._id)?.data;
  const filteredData = currentData
    ? currentData.filter(d => !search || d.name?.toString().toLowerCase().includes(search.toLowerCase()))
    : [];
  const displayedVariables = orderedByName
    ? orderBy(filteredData, "name", "asc")
    : orderBy(filteredData, ["type", "instance"], ["asc", "asc"]);

  const calcNumberCols = useCallback(() => {
    if (!gridRef.current) return 1;
    const containerStyle = window.getComputedStyle(gridRef.current);
    const gap = parseFloat(containerStyle.getPropertyValue("grid-column-gap"));
    const minW = parseFloat(containerStyle.getPropertyValue("grid-template-columns"));
    setNumberCols(Math.floor((gridRef.current.offsetWidth + gap) / (minW + gap)));
  }, []);

  const handleRefresh = () => {
    setDate(undefined);
    void refetch();
  };

  const options: Option[] = boilerRooms.map(module => {
    const boilerRoomNames = concatBoilerroomNamesFromModule(module);
    return {
      name:
        boilerRoomNames !== "" ? boilerRoomNames : (boilerRoomModules.find(m => m.id === module.moduleId)?.url ?? ""),
      value: module._id!,
    };
  });

  const handleDisplayVariables = (variable: DetailsVariablesData) => {
    const { name = "-", type = "-", instance = "-", value = "-" } = variable;
    const typeInstance = `${type.charAt(0)} ${instance}`;
    return {
      name: orderedByName ? `${name} (${typeInstance})` : `${typeInstance} : ${name}`,
      value,
    };
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(calcNumberCols);
    if (gridRef.current) resizeObserver.observe(gridRef.current);
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcNumberCols, gridRef.current]);

  return (
    <div className={contentStyle()}>
      {boilerRooms.length > 1 && (
        <Select
          label={formatMessage({ id: "boilerRoom.header.actions.variablesDetails.select.label" })}
          value={currentModule._id}
          options={options}
          disabled={isLoading}
          onChange={value => {
            const module = boilerRooms.find(module => module._id === value);
            module && setCurrentModule(module);
          }}
        />
      )}
      <div className={controlsStyle()}>
        <div className={controlsStyle("switch")}>
          <label>{formatMessage({ id: "boilerRoom.header.actions.variablesDetails.label" })}</label>
          <Switch
            checked={orderedByName}
            onCheckedChange={() => setOrderedByName(!orderedByName)}
            disabled={isLoading}
          />
        </div>
        <div className={controlsStyle("action")}>
          <Button
            text={formatMessage({ id: "boilerRoom.header.actions.variablesDetails.copy.button" })}
            icon={<CopyIcon />}
            className={controlsStyle("copy", { disabled: displayedVariables.length === 0 })}
            onClick={() => {
              const dataToCopy = displayedVariables
                .map(d => {
                  const { name, value } = handleDisplayVariables(d);
                  return `${name}  ${value}`;
                })
                .join("\n");
              copyToClipboard(dataToCopy);
            }}
            disabled={displayedVariables.length === 0}
          />
          <div>
            <p>{date}</p>
            <RoundIcon
              size={22}
              backgroundColor="gray"
              iconColor="darkGray"
              onClick={handleRefresh}
              className={controlsStyle("refresh", { disabled: isLoading })}
            >
              <UpdateIcon />
            </RoundIcon>
          </div>
        </div>
      </div>
      <SearchInput
        placeholder={formatMessage({ id: "boilerRoom.popup.error.variables.search.placeholder" })}
        value={search}
        onChange={value => setSearch(value)}
        disabled={isLoading}
      />
      <div
        className={variablesTableStyle()}
        ref={gridRef}
        style={{
          gridTemplateRows: `repeat(${Math.ceil(displayedVariables.length / numberCols)}, min-content)`,
        }}
      >
        {displayedVariables.length ? (
          displayedVariables.map((variable, index) => {
            const { name, value } = handleDisplayVariables(variable);
            return (
              <div key={index} className={variablesTableStyle("row")}>
                <Typography>{name}</Typography>
                <p>{value}</p>
              </div>
            );
          })
        ) : (
          <div className={variablesTableStyle("no-data")}>{formatMessage({ id: "noData" })}</div>
        )}
      </div>
    </div>
  );
};

VariableDetails.displayName = NAME;

export { VariableDetails };

