import { useCallback, useEffect, useState } from "react";

import type { ConfigType, Dayjs } from "@eisox/dayjs";
import dayjs from "@eisox/dayjs";
import { PlanV2 } from "@eisox/design-system";
import { useIsMobile } from "@eisox/tools";
import { useQuery } from "@tanstack/react-query";

import type { GatewaysWithProblem, ValvesWithProblem } from "~/UI/screens/House";
import type { Plan as PlanType } from "~/apiV2";
import { queries } from "~/apiV2";

import { getItems } from "../../helpers";
import type { ItemProps } from "../../types";
import { Footer } from "../Footer";
import { List } from "../List";
import { Plan } from "../Plan";
import { Resume } from "../Resume";

import styles from "./Page.module.scss";

interface PageProps {
  valves: ValvesWithProblem[];
  gateways: GatewaysWithProblem[];
  houseId: string;
  plans: PlanType[];
  updatedAt: ConfigType;
  onRefresh: VoidFunction;
}

const Page: React.FC<PageProps> = PlanV2.withReactZoomPanPinchContentRef(
  ({ valves: valvesProp, gateways: gatewaysProp, houseId, plans, updatedAt: updatedAtProp, onRefresh, rzppRef }) => {
    const isMobile = useIsMobile();

    const updatedAt = dayjs(updatedAtProp).format("LT");
    const items = getItems(valvesProp, gatewaysProp);

    const [view, setView] = useState<"list" | "plan">("plan");
    const [slideDirection, setSlideDirection] = useState<"left" | "right">("right");
    const [planId, setPlanId] = useState<string>(Object.keys(items)[0]);
    const [selectedItemOnPlan, setSelectedItemOnPlan] = useState<string>();
    const [selectedItemInList, setSelectedItemInList] = useState<string>();

    const { data: plan } = useQuery({ ...queries.plan.plan(houseId, planId) });

    const itemsOnPlan = items[planId] ?? [];
    const allItems = Object.values(items).flat();

    const valves = allItems.filter(i => i.type === "valve").length;
    const valveErrors = allItems.reduce(
      (acc, item) => (item.type === "valve" && item.errors.length > 0 ? acc + 1 : acc),
      0,
    );

    const gateways = allItems.filter(i => i.type === "gateway").length;
    const gatewayErrors = allItems.reduce(
      (acc, item) => (item.type === "gateway" && item.errors.length > 0 ? acc + 1 : acc),
      0,
    );

    const handleItemInListClick = useCallback(
      (item: ItemProps<"valve" | "gateway">) => {
        if (isMobile) setView("plan");
        setPlanId(item.plan.id);
        rzppRef.centerView(1);
        setSelectedItemOnPlan(item.id);
      },
      [isMobile, rzppRef],
    );

    const handleItemOnPlanClick = useCallback(
      (item: ItemProps<"valve" | "gateway">) => {
        if (isMobile) setView("list");
        setSelectedItemInList(item.id);
      },
      [isMobile],
    );

    const handleChangePlan = (planId: string) => setPlanId(planId);

    useEffect(() => {
      setSlideDirection(view === "plan" ? "right" : "left");
    }, [view]);

    if (isMobile) {
      return (
        <div className={styles.page}>
          <Resume
            valves={valves}
            valveErrors={valveErrors}
            gateways={gateways}
            gatewayErrors={gatewayErrors}
            updatedAt={updatedAt}
            onRefresh={onRefresh}
          />
          <div className={styles.page__wrapper} data-slide-direction={slideDirection}>
            <Plan
              plan={plan}
              planId={planId}
              plans={plans}
              items={itemsOnPlan}
              selectedItem={selectedItemOnPlan}
              setSelectedItem={setSelectedItemOnPlan}
              onItemClick={handleItemOnPlanClick}
              onPlanChange={handleChangePlan}
            />
            <List
              items={items}
              plans={plans}
              selectedItem={selectedItemInList}
              setSelectedItem={setSelectedItemInList}
              onItemClick={handleItemInListClick}
            />
          </div>
          <Footer view={view} onViewChange={setView} />
        </div>
      );
    }

    return (
      <div className={styles.page}>
        <Plan
          plan={plan}
          planId={planId}
          plans={plans}
          items={itemsOnPlan}
          selectedItem={selectedItemOnPlan}
          setSelectedItem={setSelectedItemOnPlan}
          onItemClick={handleItemOnPlanClick}
          onPlanChange={handleChangePlan}
        />
        <div className={styles.page__list}>
          <Resume
            valves={valves}
            valveErrors={valveErrors}
            gateways={gateways}
            gatewayErrors={gatewayErrors}
            updatedAt={updatedAt}
            onRefresh={onRefresh}
          />
          <List
            items={items}
            plans={plans}
            selectedItem={selectedItemInList}
            setSelectedItem={setSelectedItemInList}
            onItemClick={handleItemInListClick}
          />
        </div>
      </div>
    );
  },
);

export { Page };
export type { PageProps };
