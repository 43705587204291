import type { ComponentPropsWithRef } from "react";
import { forwardRef } from "react";

import { cx } from "class-variance-authority";

import styles from "./Card.module.scss";

/* -------------------------------------------------------------------------------------------------
 * Card
 * -----------------------------------------------------------------------------------------------*/

type CardProps = ComponentPropsWithRef<"div">;

const Root = forwardRef<HTMLDivElement, CardProps>(({ className, ...props }, forwardedRef) => (
  <div {...props} ref={forwardedRef} className={cx(styles.card, className)} />
));

Root.displayName = "Card";

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/

interface HeaderProps extends ComponentPropsWithRef<"div"> {
  title: string;
}

const Header = forwardRef<HTMLDivElement, HeaderProps>(({ title, className, children, ...props }, forwardedRef) => (
  <div {...props} ref={forwardedRef} className={styles.header}>
    <h2 className={styles.header__title}>{title}</h2>
    <div className={cx(styles.header__actions, className)}>{children}</div>
  </div>
));

Header.displayName = "CardHeader";

export { Root, Header };
export type { CardProps, HeaderProps };
