import { Document, Font, StyleSheet } from "@react-pdf/renderer";

import type { InterventionPlanState } from "~/features/InterventionPlanTool";
import { i18next } from "~/i18n";

import { Page2, Page3, Page4, PageContact, PagePlan, PagePresentation } from "./pages";

interface PdfProps {
  state: InterventionPlanState;
}

Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "/fonts/poppins/Poppins-Light.ttf",
      fontWeight: 300,
    },
    {
      src: "/fonts/poppins/Poppins-LightItalic.ttf",
      fontWeight: 300,
      fontStyle: "italic",
    },
    {
      src: "/fonts/poppins/Poppins-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "/fonts/poppins/Poppins-Italic.ttf",
      fontWeight: 400,
      fontStyle: "italic",
    },
    {
      src: "/fonts/poppins/Poppins-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "/fonts/poppins/Poppins-SemiBoldItalic.ttf",
      fontWeight: 600,
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  container: {
    fontFamily: "Poppins",
  },
});

export const Pdf: React.FC<PdfProps> = ({ state }) => {
  return (
    <Document
      style={styles.container}
      creationDate={state.planifiedAt.start ? new Date(state.planifiedAt.start) : new Date()}
      modificationDate={new Date(state.editAt)}
      author={`${state.contact.firstName} ${state.contact.lastName.toUpperCase()}`}
      language={i18next.language}
      title={`Plan d'intervention_Maintenance_${state.houseName.replace(/\s+/g, "").toUpperCase()}`}
      subject="Plan d'intervention Maintenance"
    >
      <PagePresentation {...state} />
      <Page2 {...state} />
      <Page3 {...state} />
      <Page4 {...state} />
      {state.plans.map(plan => (
        <PagePlan key={plan.id} {...plan} />
      ))}
      <PageContact {...state} />
    </Document>
  );
};
