import { useState } from "react";

import { useIntl } from "react-intl";

import { Button, Drawer } from "@eisox/design-system";
import { NutIcon } from "@eisox/icons";

import { Breadcrumbs } from "./Breadcrumbs";

import styles from "./Header.module.scss";

interface HeaderProps {
  title: string | React.ReactNode;
  children?: React.ReactNode;
  canAccessParameters?: boolean;
  parameters?: {
    title: string;
    controlParameters?: {
      openDrawer: boolean;
      handleDrawer: (open: boolean) => void;
    };
    content: React.ReactNode;
    onClose?: VoidFunction;
  };
}

export const Header: React.FC<HeaderProps> = ({ title, children, canAccessParameters = false, parameters }) => {
  const { formatMessage } = useIntl();

  const [parametersOpen, setParametersOpen] = useState(false);

  return (
    <header className={styles.header}>
      <div className={styles.header__content}>
        <Breadcrumbs />
        <div className={styles.header__containerTitle}>
          <h1 className={styles.header__containerTitle__title}>{title}</h1>
        </div>
        {canAccessParameters && parameters && (
          <Button
            text={formatMessage({ id: "button.settings" })}
            icon={<NutIcon style={{ width: 20, height: 20 }} />}
            onClick={() =>
              parameters.controlParameters ? parameters.controlParameters.handleDrawer(true) : setParametersOpen(true)
            }
            className={styles.header__settings}
          />
        )}
        <div className={styles.header__content__actions}>{children}</div>
      </div>
      {canAccessParameters && parameters && (
        <Drawer
          title={parameters.title}
          open={parameters.controlParameters ? parameters.controlParameters.openDrawer : parametersOpen}
          onOpenChange={() =>
            parameters.controlParameters ? parameters.controlParameters.handleDrawer(false) : setParametersOpen(false)
          }
          children={parameters.content}
        />
      )}
    </header>
  );
};
